import { Analytics } from '@services/analytics/analytics';
import { LegacyEvents } from '@services/analytics/events';
import { StatementInfo } from '@services/flexbase/statements.model';
import { platformClient } from '@services/platform/platform-client';

export const getYears = (statements: StatementInfo[]) => {
  if (!statements.length) {
    return [];
  }
  const extractYears = statements.map((s) => {
    const [year] = s.period.split('-');
    return year;
  });

  return [...new Set(extractYears)].reverse();
};

export const getStatement = async (
  statementId: string,
  statementName: string,
  accountId: string,
) => {
  try {
    const statement = await platformClient.getMonthlyBankingStatementPdf(
      accountId,
      statementId,
    );
    Analytics.track(LegacyEvents.BANKING_STATEMENT_REQUESTED, {
      success: true,
    });
    const arr = new Uint8Array(statement);
    const blobURL = URL.createObjectURL(
      new Blob([arr], { type: 'application/pdf' }),
    );
    const link = document.createElement('a');
    link.href = blobURL;
    link.download = `${statementName}`;
    link.click();
  } catch (error) {
    Analytics.track(LegacyEvents.BANKING_STATEMENT_REQUESTED, {
      success: false,
    });
    console.error(error);
  }
};

export const filterStatementsByYear = (
  year: string,
  _statements: StatementInfo[],
): StatementInfo[] => {
  const filteredStatements = _statements.filter((statement) => {
    const [statementYear] = statement.period.split('-');
    return statementYear === year;
  });
  return filteredStatements;
};
