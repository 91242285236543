import { atom } from 'recoil';
import {
  ActiveFiltersReturnType,
  createUseFiltersHook,
  FilterFnMap,
} from '@common/utilities/filter/filters';

const DetailSummaryDateFilterState = atom<FilterFnMap<any>>({
  key: 'detail_summary_date_filter_state',
  default: {},
});

export function useDetailSummaryDateFilters(): ActiveFiltersReturnType<any> {
  return createUseFiltersHook(DetailSummaryDateFilterState);
}
