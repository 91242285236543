import {
  ChargeCardsFilterState,
  ChargeCardsFilterStateModal,
  CreditCardsFilterState,
  CreditCardsFilterStateModal,
  DebitCardsFilterState,
  DebitCardsFilterStateModal,
} from 'recoil-state/filters/cards-filters/cards-filters.state';
import { createUseFiltersHook, ActiveFiltersReturnType } from '../filters';
import { CreditCardTableRow, DebitCardTableRow } from './card-table-row.model';

export function useCreditCardsFilters(): ActiveFiltersReturnType<CreditCardTableRow> {
  return createUseFiltersHook(CreditCardsFilterState);
}

export function useCreditCardsModalFilters(): ActiveFiltersReturnType<CreditCardTableRow> {
  return createUseFiltersHook<CreditCardTableRow>(CreditCardsFilterStateModal);
}

export function useChargeCardsFilters(): ActiveFiltersReturnType<CreditCardTableRow> {
  return createUseFiltersHook(ChargeCardsFilterState);
}

export function useChargeCardsModalFilters(): ActiveFiltersReturnType<CreditCardTableRow> {
  return createUseFiltersHook(ChargeCardsFilterStateModal);
}

export function useDebitCardsFilters(): ActiveFiltersReturnType<DebitCardTableRow> {
  return createUseFiltersHook(DebitCardsFilterState);
}

export function useDebitCardsModalFilters(): ActiveFiltersReturnType<DebitCardTableRow> {
  return createUseFiltersHook(DebitCardsFilterStateModal);
}
