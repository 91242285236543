import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableColumn } from 'react-data-table-component';
import { useClipboard, useMediaQuery } from '@mantine/hooks';
import {
  Flex,
  Text,
  Badge,
  Group,
  Avatar,
  Tooltip,
  UnstyledButton,
  useMantineTheme,
  rem,
} from '@mantine/core';
import { FlexbaseTable } from '../../../components/table';
import { AccountTableData } from './banking-accounts';
import { downloadWireInstructions } from './utilities';
import { FlexIconShort } from '../../../assets/svg';
import { formatCurrency } from '@utilities/formatters';
import getPaddedAccountMask from '../../../utilities/formatters/get-padded-account-mask';
import {
  PiDownloadSimpleBold,
  PiEyeBold,
  PiEyeSlashBold,
} from 'react-icons/pi';

const AccountSection = ({ nickName }: { nickName: string }) => (
  <Flex align="center">
    <Avatar radius="xl" mr={rem(15)} w={rem(45)} h={rem(45)}>
      <FlexIconShort />
    </Avatar>
    <Text fz="sm">{nickName}</Text>
  </Flex>
);

const RoutingNumberSection = ({ routingNumber }: { routingNumber: string }) => {
  const clipboard = useClipboard();
  const handleCopyRoutingNumber = () => clipboard.copy(routingNumber);

  return (
    <Tooltip withArrow label={clipboard.copied ? 'Copied' : 'Click to copy'}>
      <UnstyledButton
        c="neutral.8"
        sx={{ fontSize: 'inherit' }}
        onClick={handleCopyRoutingNumber}
      >
        {routingNumber}
      </UnstyledButton>
    </Tooltip>
  );
};

const AccountNumberSection = ({ accountNumber }: { accountNumber: string }) => {
  const clipboard = useClipboard();
  const handleCopyAccountNumber = () => clipboard.copy(accountNumber);
  const [displayAccountNumber, setDisplayAccountNumber] = useState(false);
  const handleShowAccountNumber = () =>
    setDisplayAccountNumber(!displayAccountNumber);

  return (
    <Tooltip
      withArrow
      disabled={!displayAccountNumber}
      data-testid="account-tooltip-icon"
      label={clipboard.copied ? 'Copied' : 'Click to copy'}
    >
      <Flex align="center" gap="xs">
        {displayAccountNumber ? (
          <UnstyledButton
            c="neutral.8"
            // sx={{ fontSize: 'inherit' }}
            fz="sm"
            onClick={handleCopyAccountNumber}
          >
            {accountNumber}
          </UnstyledButton>
        ) : (
          <Text fz="sm">{getPaddedAccountMask(accountNumber ?? '', 5)}</Text>
        )}
        <UnstyledButton c="neutral.5" onClick={handleShowAccountNumber}>
          {displayAccountNumber ? (
            <PiEyeSlashBold size={'1rem'} />
          ) : (
            <PiEyeBold size={'1rem'} />
          )}
        </UnstyledButton>
      </Flex>
    </Tooltip>
  );
};

const AutopaySection = ({
  instantPay,
  earlyPay,
}: {
  instantPay?: boolean;
  earlyPay?: boolean;
}) => {
  const theme = useMantineTheme();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  const repaymentSources: string[] = [];
  if (instantPay) {
    repaymentSources.push('Instant pay');
  }
  if (earlyPay) {
    repaymentSources.push('Early pay');
  }

  return (
    <Flex gap="xxs" wrap={useMobileView ? 'wrap' : 'unset'}>
      {repaymentSources.map((s) => (
        <Badge
          key={s}
          sx={{
            border: `1px solid ${theme.colors.neutral[3]}`,
          }}
        >
          {s}
        </Badge>
      ))}
    </Flex>
  );
};

const WireDetailsSection = ({ accountId }: { accountId: string }) => {
  return (
    <Group gap="xxs" onClick={() => downloadWireInstructions(accountId)}>
      <PiDownloadSimpleBold size={'1rem'} />
      Download
    </Group>
  );
};

const BankingAccountsTable = ({
  accounts,
}: {
  accounts: AccountTableData[];
}) => {
  const navigate = useNavigate();
  const hasRepaymentConfig = accounts.find(
    (acc) => acc.earlyPay || acc.instantPay,
  );

  const conditionalRowStyles = [
    {
      when: (row: AccountTableData) => row.isReserveAccount,
      style: {
        '&:hover': {
          cursor: 'auto',
        },
      },
    },
  ];

  const columns: TableColumn<AccountTableData>[] = [
    {
      name: 'Account',
      grow: 2,
      cell: (row) => <AccountSection nickName={row.nickName} />,
      sortable: true,
      selector: (row) => row.nickName,
    },
    {
      name: 'ACH/Wire Routing',
      cell: (row) =>
        !row.isReserveAccount &&
        row.routingNumber && (
          <RoutingNumberSection routingNumber={row.routingNumber} />
        ),
    },
    {
      name: 'Account number',
      cell: (row) =>
        !row.isReserveAccount &&
        row.accountNumber && (
          <AccountNumberSection accountNumber={row.accountNumber} />
        ),
    },
    {
      name: 'Balance',
      format: (row) => formatCurrency(row.balance),
      selector: (row) => row.balance,
      sortable: true,
    },
    {
      name: 'Autopay source',
      cell: (row) => (
        <AutopaySection earlyPay={row.earlyPay} instantPay={row.instantPay} />
      ),
      omit: !hasRepaymentConfig,
    },
    {
      name: 'Wire details',
      cell: (row) =>
        !row.isReserveAccount && <WireDetailsSection accountId={row.id} />,
    },
    {
      name: 'Status',
      sortable: true,
      selector: (row) => row.status,
      cell: (row) => <Badge>{row.status}</Badge>,
      compact: true,
    },
  ];

  const handleRowClick = (row: AccountTableData) => {
    if (!row.isReserveAccount) {
      navigate(`/banking/account-details/${row.id}/transactions`, {
        state: { earlyPay: row.earlyPay, instantPay: row.instantPay },
      });
    }
  };

  return (
    <FlexbaseTable
      data={accounts}
      columns={columns}
      onRowClicked={handleRowClick}
      conditionalRowStyles={conditionalRowStyles}
    />
  );
};

export default BankingAccountsTable;
