import { createStyles } from '@mantine/emotion';
import { rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  header: {
    display: 'flex',
    padding: rem(45),
    justifyContent: 'space-between',
    color: theme.colors.neutral[0],
    backgroundColor: theme.colors.primarySecondarySuccess[8],
    '@media(max-width: 767px)': {
      padding: rem(20),
    },
  },

  transactionDetails: {
    paddingBottom: rem(50),
    minHeight: '100vh',
    backgroundColor: theme.colors.neutral[2],
    '@media(max-width: 767px)': {
      paddingBottom: rem(20),
    },
  },

  twoFactorContainer: {
    margin: `${rem(20)} ${rem(50)}`,
  },

  containerCardNumber: {
    display: 'flex',
    alignItems: 'center',
  },
}));
