import { createStyles } from '@mantine/emotion';

export const useFormStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
  },
  updateButton: {
    backgroundColor: theme.primaryColor,
    height: '50px',
    width: '115px',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: theme.primaryColor,
    },
  },
  cancelButton: {
    height: '50px',
    width: '115px',
    borderRadius: '8px',
    backgroundColor: '#FCFCFC',
    border: '1px solid #979797',
    '&:hover': {
      backgroundColor: '#FCFCFC',
    },
  },
  buttonText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '16px',
  },
  updateButtonText: {
    color: '#FFFFFF',
  },
  cancelButtonText: {
    color: '#5F5F5F',
  },
  input: {
    border: '1px solid #D1D5D8',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
    borderRadius: '6px',
    height: '50px',
    backgroundColor: '#FFFFFF',
  },
  inputLabel: {
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#374151',
    marginBottom: '10px',
  },
  buttonContainer: {
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '14px',
  },
  clickable: {
    cursor: 'pointer',
    '&:active': {
      transform: 'scale(0.9)',
    },
  },
  icon: {
    fill: theme.colors.blackish[0],
    height: '15px',
    width: '15px',
  },
}));
