import { useState } from 'react';
import { Grid, Progress, rem, UnstyledButton } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import SetPin from './set-pin';
import { CloseIcon } from 'assets/svg';
import { useStyles } from './activate-debit-card-styles';
import InputDebitCardData from './input-debit-card-data';
import Stepper from '@common/composites/stepper';
import useModal from '@common/composites/modal/modal-hook';
import ActivateDebitCardError from './activate-debit-card-error';
import { CardByUser } from 'services/flexbase/banking.model';
import ModalSuccess from 'areas/banking/components/modal-success/modal-success';
import FlexIconLink from '@common/composites/flex-icon-link';
import TwoFactorAuthSection from '../two-factor/two-factor-auth-section';
import { useGetDebitCards } from '@queries/use-debit-cards';
import { useCreditCards } from '@queries/use-credit-cards';

type Props = {
  card: CardByUser;
  isChargeCard: boolean;
};

enum ActiveCardSteps {
  GET_TOKEN,
  INPUT_SENSITIVE_DATA,
  SET_PIN,
  ERROR,
  SUCCESS,
}

const ActivateDebitCard = ({ card, isChargeCard }: Props) => {
  const { classes } = useStyles();
  const { closeAllModals } = useModal();
  const { refetch } = useGetDebitCards('', !isChargeCard);
  const unitcoCardId = card.ucCardId.substring(5);
  const [bearerToken, setBearerToken] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [loadingAction, setLoadingAction] = useState(false);
  const [step, setStep] = useState(ActiveCardSteps.GET_TOKEN);
  const { refetch: refetchChargeCards } = useCreditCards({
    enabled: isChargeCard,
  });

  const activateCard = async (form: any) => {
    setErrorMessage('');
    setLoadingAction(true);
    await form.submit(
      '/cards/' + unitcoCardId + '/activate',
      {
        // This converts the dot-separated field name strings into a JSON object
        mapDotToObject: 'true',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Authorization: 'Bearer ' + bearerToken,
        },
      },
      (status: any, data: any) => {
        setLoadingAction(false);
        if (status === 200) {
          return setStep(ActiveCardSteps.SET_PIN);
        } else {
          return setErrorMessage(data.errors[0].title);
        }
      },
    );
  };

  const setPin = async (form: any) => {
    setErrorMessage('');
    setLoadingAction(true);
    await form.submit(
      '/cards/' + unitcoCardId + '/secure-data/pin',
      {
        // This converts the dot-separated field name strings into a JSON object
        mapDotToObject: 'true',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Authorization: 'Bearer ' + bearerToken,
        },
      },
      (status: any, data: any) => {
        setLoadingAction(false);
        if (status === 200) {
          setStep(ActiveCardSteps.SUCCESS);
        } else {
          setErrorMessage(data.errors[0].title);
        }
      },
    );
  };

  const handleCloseModal = () => {
    // as activation flow is part of SVG flow, we need to refetch our query
    if (isChargeCard) {
      refetchChargeCards();
    } else {
      refetch();
    }
    closeAllModals();
  };

  let currentStep;
  switch (step) {
    case ActiveCardSteps.GET_TOKEN:
      currentStep = (
        <div style={{ width: 420 }}>
          <TwoFactorAuthSection
            isVGSflow
            onSuccess={(accessToken) => {
              if (accessToken) {
                setBearerToken(accessToken);
                setStep(ActiveCardSteps.INPUT_SENSITIVE_DATA);
              }
            }}
          />
        </div>
      );
      break;

    case ActiveCardSteps.INPUT_SENSITIVE_DATA:
      currentStep = (
        <InputDebitCardData
          loading={loadingAction}
          {...{ card, errorMessage }}
          isChargeCard={isChargeCard}
          onNextClick={(f) => activateCard(f)}
        />
      );
      break;

    case ActiveCardSteps.SET_PIN:
      currentStep = (
        <SetPin
          loading={loadingAction}
          cardStatus="issued"
          {...{ card, errorMessage }}
          onNextClick={(f) => setPin(f)}
        />
      );
      break;

    case ActiveCardSteps.SUCCESS:
      currentStep = (
        <ModalSuccess
          backTo={isChargeCard ? 'charge cards' : 'debit cards'}
          closeModal={handleCloseModal}
          title="Your card has been activated successfully"
        />
      );
      break;

    case ActiveCardSteps.ERROR:
      currentStep = <ActivateDebitCardError onClick={closeAllModals} />;
      break;
  }

  if (useMobileView) {
    return (
      <div className={classes.activateDebitCard}>
        <UnstyledButton className="close-button" onClick={closeAllModals}>
          <CloseIcon color="#979797" />
        </UnstyledButton>
        {step < ActiveCardSteps.ERROR && (
          <Progress radius="sm" value={((step + 1) * 100) / 3} />
        )}
        <div className="content">{currentStep}</div>
      </div>
    );
  }

  return (
    <Grid className={classes.activateDebitCard}>
      <Grid.Col span={3}>
        <FlexIconLink width={90} />
        {step < ActiveCardSteps.ERROR && (
          <Stepper
            activeStep={step}
            stepsData={[
              'Verification required',
              'Input CVV and expiration date',
              'Set pin',
            ]}
          />
        )}
      </Grid.Col>

      <Grid.Col className="content" span={6}>
        <div className={classes.card}>{currentStep}</div>
      </Grid.Col>

      <Grid.Col span={3} className="close">
        <UnstyledButton onClick={closeAllModals}>
          <CloseIcon width={rem(20)} height={rem(20)} color="#979797" />
        </UnstyledButton>
      </Grid.Col>
    </Grid>
  );
};

export default ActivateDebitCard;
