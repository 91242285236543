import { createStyles } from '@mantine/emotion';
import { rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  title: {
    fontSize: rem(20),
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: rem(19),
    color: theme.primaryColor,
    fontFamily: 'PP Neue Montreal',
  },
  container: {
    padding: rem(12),
    backgroundColor: 'white',
    borderRadius: theme.defaultRadius,
    maxWidth: rem(1365),
    margin: 'auto',
  },
  header: {
    borderBottom: 'solid',
    borderBottomColor: '#E6E7E9',
    borderBottomWidth: rem(0.5),
    paddingBottom: rem(16),
    paddingTop: rem(12),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media(max-width: 767px)': {
      gap: rem(15),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  statusColumn: {
    textAlign: 'center',
    borderRadius: rem(100),
    fontWeight: 500,
    color: 'black',
    padding: rem(1),
    '@media(max-width: 767px)': {
      fontSize: rem(12),
      margin: `${rem(10)} 0px`,
    },
    minWidth: rem(100),
  },
  errorMessage: {
    height: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    span: {
      display: 'flex',
      fontWeight: 600,
      marginTop: rem(32),
      justifyContent: 'center',
    },

    p: {
      color: '#6F7181',
      fontSize: rem(14),
      textAlign: 'center',
    },
  },

  emptyState: {
    height: '60vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',

    '.image-container': {
      display: 'flex',
      marginTop: rem(50),
      justifyContent: 'center',
    },

    p: {
      margin: 0,
      fontSize: rem(14),
      textAlign: 'center',
      color: theme.colors.neutral[6],
    },
  },

  tooltipInfo: {
    fontSize: rem(13),
    textAlign: 'center',
    padding: rem(10),
  },
}));
