import { DetailSummaryDateRangeFilter } from '@common/composites/details-page/filters/detail-summary-date-range-filter';
import DetailSummaryLayout from '@common/layouts/detail-page/detail-summary-layout';
import { Divider, Flex } from '@mantine/core';
import { sortDate } from '@utilities/dates';
import MoneyMovementSummary from '../../account-details/money-movement-summary';
import TransactionsOverviewBadges from '../../account-details/transactions-overview-badges';

export type AccountTransaction = {
  id: string;
  createdAt: string;
  summary: string;
  direction: string;
  amount: string | number;
};

const filterAndSortTransactionsByDate = (
  date: string,
  isReserveAccount: boolean,
  transactions?: AccountTransaction[],
) => {
  const filterByDate = (txns: AccountTransaction[], d: string) => {
    if (!d) {
      return txns;
    }
    return txns.filter((t) => t.createdAt.includes(d));
  };

  const allTransactions = transactions ?? [];
  const filteredTransactions = date
    ? filterByDate(allTransactions, date)
    : allTransactions;

  return filteredTransactions
    .map((transaction) => ({
      ...transaction,
      amount: isReserveAccount
        ? transaction.amount
        : `${Number(transaction.amount) / 100}`,
    }))
    .sort((a, b) => sortDate(a, b, false));
};

type Props = {
  chartFilterValue: string;
  accountDetailsComponent: JSX.Element;
  chart: JSX.Element;
  modalContent: JSX.Element;
  transactions: AccountTransaction[];
  isReserveAccount: boolean;
};

const TransactionsSummary = ({
  chartFilterValue,
  accountDetailsComponent,
  chart,
  modalContent,
  transactions,
  isReserveAccount,
}: Props) => {
  const sortedTransactions = filterAndSortTransactionsByDate(
    chartFilterValue,
    isReserveAccount,
    transactions,
  );

  return (
    <DetailSummaryLayout
      summaryDetails={accountDetailsComponent}
      chart={chart}
      summaryActions={
        <Flex align={'center'} mt="md">
          <DetailSummaryDateRangeFilter />
          <Divider orientation="vertical" mx="sm" size="sm" />
          <TransactionsOverviewBadges
            isReserveAccount={isReserveAccount}
            transactions={sortedTransactions}
          />
        </Flex>
      }
      summary={
        <MoneyMovementSummary
          isReserveAccount={isReserveAccount}
          transactions={sortedTransactions}
          ModalContent={modalContent}
        />
      }
    />
  );
};

export default TransactionsSummary;
