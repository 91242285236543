import { rem, Stack } from '@mantine/core';
import { IAddress } from 'types/user-info';
import { useStyles } from '../cancel-debit-card-styles';
import AddressForm from '@common/utilities/address-form';
import { CardByUser } from 'services/flexbase/banking.model';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import TwoFactorAuthSection from '../../../two-factor/two-factor-auth-section';

type Props = {
  card: CardByUser;
  loading: boolean;
  needsAuth: boolean;
  isChargeCard: boolean;
  onBack: () => void;
  onConfirmAddress: (x: IAddress) => void;
};

const ConfirmShippingAddress = ({
  card,
  loading,
  needsAuth,
  isChargeCard,
  onBack,
  onConfirmAddress,
}: Props) => {
  const { classes } = useStyles();
  let address: IAddress;

  const cardLabel = isChargeCard ? 'CHARGE CARD' : 'DEBIT CARD';

  return (
    <>
      <p className={classes.subtitle}>
        {cardLabel} {getPaddedAccountMask(card.cardNumber, 4)}
      </p>
      <span className={classes.title}>Confirm Shipping Address</span>

      <Stack mt={rem(20)}>
        <AddressForm
          currentAddress={{
            ...card.shipTo,
            line1: card.shipTo.street ?? card.shipTo.address,
            line2: card.shipTo.street2 ?? card.shipTo.addressLine2,
          }}
          onSubmit={(add: IAddress) => {
            onConfirmAddress(add);
            address = add;
          }}
          onClose={onBack}
          cancelText="Back"
          submitText="Confirm"
          loading={loading}
        />

        {needsAuth && (
          <TwoFactorAuthSection onSuccess={() => onConfirmAddress(address)} />
        )}
      </Stack>
    </>
  );
};

export default ConfirmShippingAddress;
