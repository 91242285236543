import { Anchor, Box, Button, Image, rem, Text } from '@mantine/core';
import {
  useConfirmPayment,
  useSendCodeConfirmPayment,
} from '../../../../queries/use-payments';
import TwoFactorAuth from '@common/composites/two-factor-auth';
import { useEffect, useState } from 'react';
import {
  Counterparty,
  MoneyMovement,
} from '../../../../services/flexbase/banking.model';
import { usePaymentStatus } from './hooks/use-payment-status';
import { useStyles } from './payment-confirmation.styles';
import IntlPaymentCard from './international-payments/intl-payment-card/intl-payment-card';
import { getInternationalWireCurrency } from './international-payments/util';
import { InternationalRecipient } from './international-payments/util/types';
import { MoneyMovementDetails, PayMethod, Recipient } from 'types/payments';
import { isInternationalWire } from '@utilities/international-payments';
import { PiDownloadSimpleBold } from 'react-icons/pi';

export type PaymentStatusDetails = {
  moneyMovementDetails: MoneyMovementDetails;
  recipientName: string;
  method: PayMethod;
  counterparty: Counterparty;
  recipient: Recipient | InternationalRecipient;
};

type Props = {
  onCloseClick: () => void;
  onMakeAnotherPaymentClick: () => void;
  error?: boolean;
  payment: MoneyMovement | null;
  paymentStatusDetails: PaymentStatusDetails;
  isComptroller: boolean;
};

const PaymentConfirmation = ({
  onCloseClick,
  onMakeAnotherPaymentClick,
  error = false,
  payment,
  paymentStatusDetails,
  isComptroller,
}: Props) => {
  const { classes } = useStyles();

  const [rejectedReason, setRejectedReason] = useState<string>();
  const [confirmationStatus, setConfirmationStatus] = useState<string | null>(
    null,
  );

  const {
    mutate: sendCodeMutate,
    isPending: isSendCodePending,
    error: sendCodeError,
  } = useSendCodeConfirmPayment();

  const {
    mutateAsync: confirmMutateAsync,
    isPending: isConfirmPending,
    error: confirmError,
  } = useConfirmPayment();

  const paymentStatus = usePaymentStatus(
    payment,
    confirmationStatus,
    rejectedReason,
    paymentStatusDetails,
  );

  // 2FA codes are not sent automatically on Payment creation,
  // so we need to send a code to start if the Payment needs confirmation
  useEffect(() => {
    const needsCode =
      !confirmationStatus &&
      payment?.status === 'AwaitingConfirmation' &&
      isComptroller;

    if (needsCode) {
      handleSendCode();
    }
  }, [confirmationStatus, payment?.status, isComptroller]);

  const handle2faInputChange = async (code: string) => {
    if (payment?.id && code.length === 6) {
      const confirmed = await confirmMutateAsync({
        id: payment?.id,
        code,
      });
      setConfirmationStatus(confirmed?.status);
      setRejectedReason(confirmed?.reason);
    }
  };

  const handleSendCode = async () => {
    if (payment?.id) {
      sendCodeMutate(payment.id);
    }
  };

  const { method } = paymentStatusDetails;

  const needsTwoFactor =
    isComptroller &&
    ((!confirmationStatus && payment?.status === 'AwaitingConfirmation') ||
      confirmationStatus === 'AwaitingConfirmation');

  return (
    <div className={classes.successContainer}>
      <Image src={paymentStatus.sketch} width={rem(260)} />
      <Text className={classes.infoText} data-testid={'message'}>
        {paymentStatus.title}
      </Text>

      {paymentStatus.downloadWire && !error && method === 'wire' && (
        <Anchor
          fw={400}
          fz={14}
          color="primarySecondarySuccess.2"
          className={classes.downloadReceipt}
          onClick={() => window.open(`/payment/receipt/${payment?.id}`)}
        >
          Download wire receipt <PiDownloadSimpleBold size={'0.938rem'} />
        </Anchor>
      )}

      <div className={classes.flavorText}>
        {paymentStatus.description || 'Something went wrong'}
      </div>

      {isInternationalWire(method) &&
        !needsTwoFactor &&
        !error &&
        payment?.payAmount && (
          <Box
            mt={rem(16)}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <IntlPaymentCard
              currencyAmount={paymentStatusDetails.moneyMovementDetails.amount}
              currency={getInternationalWireCurrency(paymentStatusDetails)}
              recipientName={paymentStatusDetails.recipientName}
            >
              <IntlPaymentCard.Summary
                amount={payment?.payAmount}
                downloadButton={
                  <Anchor
                    mt={0}
                    fw={400}
                    fz={rem(14)}
                    c="primarySecondarySuccess.2"
                    className={classes.downloadReceipt}
                    onClick={() => {
                      console.error('Download wire receipt');
                    }}
                  >
                    Download wire receipt{' '}
                    <PiDownloadSimpleBold size={'0.938rem'} />
                  </Anchor>
                }
              />
            </IntlPaymentCard>
          </Box>
        )}

      {needsTwoFactor ? (
        <div className={classes.TwoFactorContainer}>
          <TwoFactorAuth
            hasRetry={confirmError instanceof Error}
            errorMsg={confirmError?.message ?? sendCodeError?.message ?? ''}
            onChange={handle2faInputChange}
            onResendCode={handleSendCode}
            loading={isConfirmPending || isSendCodePending}
          />
        </div>
      ) : (
        <div className={classes.buttons}>
          <Button
            variant="outline"
            onClick={onMakeAnotherPaymentClick}
            data-testid="go-back-to-payments-button"
          >
            {error ? 'Try again' : 'Make another payment'}
          </Button>
          <Button
            variant="light"
            data-testid="make-another-payment-button"
            onClick={onCloseClick}
          >
            View payments
          </Button>
        </div>
      )}
    </div>
  );
};

export default PaymentConfirmation;
