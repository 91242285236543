import { Group, Switch, Text } from '@mantine/core';

type BinaryListFilterOption = { label: string; key: string };
type SelectedListOptionMap = Record<string, boolean>;

type Props = {
  options: BinaryListFilterOption[];
  value: SelectedListOptionMap;
  onOptionChange: (optionKey: string, optionValue: boolean) => void;
};

export function SwitchFilter({ options, value, onOptionChange }: Props) {
  const mapToSwitchValue = (key: string) => {
    const filterValue = value[key];
    return filterValue || false;
  };

  return (
    <Group gap={40}>
      {options.map((o) => (
        <Group key={o.key} align="center">
          <Text fz={16}>{o.label}</Text>
          <Switch
            color="primarySecondarySuccess.2"
            size="md"
            checked={mapToSwitchValue(o.key)}
            onChange={(val) => onOptionChange(o.key, val.currentTarget.checked)}
          />
        </Group>
      ))}
    </Group>
  );
}
