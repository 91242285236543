import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import FlexbaseInput from '@common/composites/input/flexbase-input';
import { AlertParam } from 'types/alert-params';
import {
  NotificationChannels,
  UserInfoState,
  UserPreferences,
} from 'types/user-info';
import { capitalizeOnlyFirstLetter } from 'utilities/formatters/format-strings';
import { useStyles } from './notifications.styles';
import {
  Box,
  Button,
  Checkbox,
  NumberInput,
  useMantineTheme,
} from '@mantine/core';
import NotificationsTab from './tabs/tabs';
import TabInfo from './tabs/tab-info';
import { useGetCompanyData, useUpdateCompany } from '@queries/use-company';
import {
  CompanySelector,
  IsAdmin,
} from 'recoil-state/application/product-onboarding';
import { useCreditBalance } from '@queries/use-credit-balance';
import { formatCurrency } from '@utilities/formatters';
import { showNotification } from '@mantine/notifications';
import FlexAlert from '@common/utilities/flex-alert';
import {
  useUpdateUserPreferences,
  useUserPreferences,
} from '@queries/use-user-preferences';
import { useAlertParams } from '@queries/use-alert-params';
import { PiPercentBold } from 'react-icons/pi';

export type Notification = {
  groupName: string;
  eventName: string;
  description: string | ReactNode;
  threshold?: number;
  channels?: ('sms' | 'email' | 'push')[];
  subNotifications?: Omit<Notification, 'subNotifications'>[];
};

export type NotificationCategory = {
  name: string;
  scope?: string;
  defaultOn?: boolean;
  notifications: Notification[];
};

/**
 * array of events to group to a category. To do this, transformNotificationData
 * maps the events, and if an event is found, it is grouped to a new groupName value.
 * This will override the original value in groupName for the reducer grouping, and then utilize for the actual work to be done.
 * to make this more scalable, be able to rely on groupName as is.
 * all remaining eventNames without an explicit mapping will be reduced to an array of events with a key of groupName.
 *
 * example for the TeamAccountEvents const, which groups to "Team" in the Accounts tab:
 * {
    name: "Team",
    notifications: [
      {
        eventName: "userInvitation",
        groupName: "COMPANY",
        description: "This will alert you when an invitation has been sent to a new User to join Flex.",
        channels: [
        ],
      },
      {
        eventName: "userOnboarded",
        groupName: "COMPANY",
        description: "This will alert you when a new User has completed the Onboarding process.",
        channels: [
        ],
      },
    ],
  },
 */
const creditTransactionsEvents = [
  'uploadReceipt',
  'cardSwipe',
  'declined',
  'purchaseLimit',
  'delinquent',
];
const creditCardsEvents = [
  'cardStatus',
  'suspectedFraud',
  'suspectedFraudAdmin',
  'cardIssuingVirtual',
  'cardIssuingPhysical',
];
const creditPaymentsEvents = [
  'paymentDebited',
  'paymentPosted',
  'upcomingDebits',
  'delinquencyReminder',
];

const generalAccountEvents = ['companyCreditLimitUp', 'plaidReauth', 'frozen'];
const teamAccountEvents = ['userOnboarded', 'userInvitation'];
const bankingPaymentEvents = [
  'yourPaymentRequestApproved',
  'yourPaymentRequestDenied',
  'paymentRequested',
  'paymentRequestDenied',
  'paymentRequestCanceled',
  'paymentProcessing',
  'paymentFailed',
  'paymentReturned',
  'paymentCanceled',
  'paymentReceived',
  'paymentReceivedReturned',
];
const purchasesEvents = ['availableBalance'];

/**
 *
 * @param givenAlertParams the raw alert params array returned from servicing/alertParams
 * @returns
 */
const transformNotificationData = (
  alertParams: AlertParam[],
  userPreferences: UserPreferences,
  isAdmin: boolean,
): NotificationCategory[] => {
  const userNotificationPrefs = userPreferences.notifications;
  const groupedData = alertParams.reduce<Record<string, Notification[]>>(
    (acc, a) => {
      const userPref =
        userNotificationPrefs[a.groupName]?.[a.eventName] ||
        userNotificationPrefs[a.groupName]?.default;

      let pushGroup = a.groupName;

      // TODO: Remove logic to group temporary Personal group
      if (creditTransactionsEvents.includes(a.eventName)) {
        pushGroup = 'Credit Transactions';
      }
      if (creditCardsEvents.includes(a.eventName)) {
        pushGroup = 'Credit Cards';
      }
      if (creditPaymentsEvents.includes(a.eventName)) {
        pushGroup = 'Credit Payments';
      }
      if (generalAccountEvents.includes(a.eventName)) {
        pushGroup = 'General';
      }
      if (teamAccountEvents.includes(a.eventName)) {
        pushGroup = 'Team';
      }
      if (bankingPaymentEvents.includes(a.eventName)) {
        pushGroup = 'Banking Payments';
      }
      if (purchasesEvents.includes(a.eventName) && isAdmin) {
        pushGroup = 'Credit utilization';
      }
      acc[pushGroup] = acc[pushGroup] || [];
      acc[pushGroup].push({
        eventName: a.eventName,
        groupName: a.groupName,
        description: a.description,
        channels: userPref || [],
      });
      return acc;
    },
    {},
  );

  const transformedData: NotificationCategory[] = [];

  for (const [key, value] of Object.entries(groupedData)) {
    transformedData.push({
      name: key,
      notifications: value,
    });
  }
  return transformedData;
};

const Notifications = () => {
  const { classes } = useStyles();
  const company = useRecoilValue(CompanySelector);
  const isAdmin = useRecoilValue(IsAdmin);
  const { id: userId, roles } = useRecoilValue(UserInfoState);
  const { data: alertParams } = useAlertParams();

  const { data: userPreferences } = useUserPreferences();
  const { mutate: updateUserPreferences, isPending: isPendingUpdatePrefs } =
    useUpdateUserPreferences();

  const { data: companyData } = useGetCompanyData(company.id);
  const { mutate: updateCompany, isPending: isPendingUpdateCompany } =
    useUpdateCompany();

  const { data: companyCreditData } = useCreditBalance(company.id);
  const [creditUsedPct, setCreditUsedPct] = useState(80);

  const notificationData = useMemo<NotificationCategory[]>(() => {
    if (!userPreferences || !alertParams) {
      return [];
    }

    return transformNotificationData(alertParams, userPreferences, isAdmin);
  }, [userPreferences, alertParams, isAdmin]);

  useEffect(() => {
    if (companyData && companyData.additionalInfo?.creditUsedPct) {
      setCreditUsedPct(companyData.additionalInfo?.creditUsedPct);
    }
  }, [companyData]);

  const handleOnBlur = (newPrct: number) => {
    if (!newPrct && companyData && companyData.additionalInfo?.creditUsedPct) {
      setCreditUsedPct(companyData.additionalInfo?.creditUsedPct);
    }
  };

  const handleUpdateBalanceThreshold = () => {
    updateCompany(
      { id: company.id, creditUsedPct },
      {
        onSuccess: () => {
          showNotification({
            color: 'flexbase-teal',
            title: 'Success',
            message: 'Company details have been successfully updated.',
          });
        },
      },
    );
  };

  const updateNotificationsPreferences = async (
    checked: boolean,
    eventName: string,
    groupName: string,
    channel: NotificationChannels,
  ) => {
    if (!userPreferences) {
      return;
    }

    const channelPref: NotificationChannels[] =
      userPreferences.notifications[groupName] &&
      userPreferences.notifications[groupName][eventName]
        ? [...userPreferences.notifications[groupName][eventName]]
        : [];
    if (checked && !channelPref.includes(channel)) {
      channelPref.push(channel);
    } else if (!checked && channelPref.includes(channel)) {
      channelPref.splice(
        channelPref.findIndex((element) => element === channel),
        1,
      );
    }
    const preferences = {
      preferences: JSON.parse(JSON.stringify(userPreferences)),
    };
    if (!preferences.preferences.notifications[groupName]) {
      preferences.preferences.notifications[groupName] = {};
    }
    preferences.preferences.notifications[groupName][eventName] = channelPref;

    updateUserPreferences(
      {
        userId,
        payload: preferences,
      },
      {
        onError: () => {
          console.error('Failed updating notification preferences');
        },
      },
    );
  };

  const generateNotificationControl = (notification: Notification) => {
    const isChecked = notification.channels?.includes('sms');
    const theme = useMantineTheme();
    return (
      <div className={classes.notification} key={notification.eventName}>
        <div className={classes.notificationName}>
          {notification.eventName === 'availableBalance' ? (
            <Box className={classes.alertDescriptionContainer}>
              This will alert you when you have used
              <NumberInput
                classNames={{ input: classes.inputPercent }}
                ml={'0.2rem'}
                mr={'0.2rem'}
                rightSection={<PiPercentBold color={theme.colors.neutral[5]} />}
                min={0}
                max={100}
                disabled={isPendingUpdateCompany || !isChecked}
                value={creditUsedPct}
                onBlur={(e) => handleOnBlur(parseInt(e.target.value))}
                onChange={(e: number | string) => {
                  setCreditUsedPct(Number(e));
                }}
              />{' '}
              ({' '}
              {formatCurrency(
                ((companyCreditData?.creditLimit ?? 0) * creditUsedPct) / 100,
              )}
              ) of your {formatCurrency(companyCreditData?.creditLimit ?? 0)}{' '}
              credit line.
              <Button
                loading={isPendingUpdateCompany}
                disabled={isPendingUpdateCompany || !isChecked}
                ml={'1rem'}
                size={'xs'}
                onClick={handleUpdateBalanceThreshold}
              >
                Update percent
              </Button>
            </Box>
          ) : (
            notification.description
          )}

          {notification.threshold !== undefined && (
            <FlexbaseInput
              type="number"
              size="sm"
              disabled={isPendingUpdatePrefs}
            />
          )}
        </div>
        <div className={classes.notificationCheckboxes}>
          <div>
            <Checkbox
              checked={isChecked}
              onChange={(event) =>
                updateNotificationsPreferences(
                  event.currentTarget.checked,
                  notification.eventName,
                  notification.groupName,
                  'sms',
                )
              }
              disabled={isPendingUpdatePrefs}
            />
          </div>
        </div>
      </div>
    );
  };

  const Tabs = [
    {
      name: 'Account',
      value: 'account',
      content: (
        <TabInfo
          notificationData={notificationData}
          generateNotificationControl={generateNotificationControl}
          filteredCategories={['General', 'Team']}
        />
      ),
    },
    {
      name: 'Credit',
      value: 'credit',
      content: (
        <TabInfo
          notificationData={notificationData}
          generateNotificationControl={generateNotificationControl}
          filteredCategories={['Credit']}
        />
      ),
    },
    {
      name: 'Banking',
      value: 'banking',
      content: (
        <TabInfo
          notificationData={notificationData}
          generateNotificationControl={generateNotificationControl}
          filteredCategories={['Banking Payments']}
        />
      ),
    },
  ];

  return (
    <div>
      <FlexAlert
        type="info"
        withCloseButton={false}
        message="Email and push notifications coming soon."
        style={{ marginTop: '16px', marginBottom: 0 }}
      />
      {roles.includes('ADMIN') ? (
        <NotificationsTab tabs={Tabs} />
      ) : (
        <>
          {notificationData
            .filter(
              (category) =>
                category.name.includes('Credit Transactions') ||
                category.name.includes('Credit Cards'),
            )
            .map((category) => (
              <div key={category.name} className={classes.category}>
                <div className={classes.categoryHeader}>
                  <div className={classes.categoryName}>
                    {capitalizeOnlyFirstLetter(category.name)}
                  </div>
                  <div className={classes.categoryScope}>{category.scope}</div>
                </div>
                <div>
                  <div>
                    {category.notifications.map((notification) => (
                      <div key={notification.eventName}>
                        {generateNotificationControl(notification)}
                        {notification.subNotifications &&
                          notification.subNotifications.length > 0 && (
                            <div>
                              {notification.subNotifications?.map(
                                (subNotification) =>
                                  generateNotificationControl(subNotification),
                              )}
                            </div>
                          )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
        </>
      )}
    </div>
  );
};

export default Notifications;
