import { ReactElement } from 'react';
import { ActionIcon, Image, Text } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { formatCurrency } from 'utilities/formatters';
import { IoClose } from 'react-icons/io5';
import FlexIconLink from '@common/composites/flex-icon-link';

const useStyles = createStyles(() => ({
  flexbaseHeader: {
    '@media(max-width: 767px)': {
      height: '50px',
      padding: '37px 0px 37px 0px',
    },
    height: '95px',
    borderBottom: '2px solid #F2F2F2',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 20px',
  },
  flexbaseHeaderCenter: {
    '@media(max-width: 767px)': {
      height: '50px',
    },
    minWidth: '100%',
    height: '95px',
    borderBottom: '2px solid #F2F2F2',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexbaseLogo: {
    '@media(max-width: 767px)': {
      marginLeft: '20px',
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
  },
  flexbaseLogoCenter: {
    '@media(max-width: 767px)': {
      marginLeft: '20px',
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
  },
  flexbaseLogoImage: {
    '@media(max-width: 767px)': {
      width: '80px',
    },
    width: '180px',
  },
  merchantLogoAmount: {
    '@media(max-width: 767px)': {
      fontSize: '1.05em',
      margin: '20px',
    },
    flexBasis: 'auto',
    margin: '30px',
    fontSize: '1.3em',
  },
  merchantLogoImage: {
    '@media(max-width: 767px)': {
      width: '22.5px !important',
    },
    width: '60px !important',
    flexBasis: 'auto',
  },
  xClose: {
    marginRight: '15px',
  },
}));

type BnplProps = {
  amount: string;
  merchantLogoUrl: string;
  merchantName: string;
};

type Props = {
  centerLogo?: boolean;
  bnplProps?: BnplProps;
  onCloseButtonClick?: () => void;
};

const FlexbaseHeader = ({
  centerLogo,
  bnplProps,
  onCloseButtonClick,
}: Props): ReactElement => {
  const { classes } = useStyles();
  const { amount, merchantLogoUrl, merchantName } = { ...bnplProps };
  return (
    <div
      className={
        centerLogo ? classes.flexbaseHeaderCenter : classes.flexbaseHeader
      }
    >
      <div
        id="flexbase-logo"
        className={
          centerLogo ? classes.flexbaseLogoCenter : classes.flexbaseLogo
        }
      >
        <FlexIconLink className={classes.flexbaseLogoImage} />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        {merchantLogoUrl && (
          <Image
            alt={merchantName}
            src={merchantLogoUrl}
            id="merchant-logo"
            classNames={{
              root: classes.merchantLogoImage,
            }}
          ></Image>
        )}
        {amount && (
          <Text
            size="lg"
            fw="bold"
            ta="right"
            className={classes.merchantLogoAmount}
          >
            {formatCurrency(amount)}
          </Text>
        )}
        {onCloseButtonClick && (
          <ActionIcon
            className={classes.xClose}
            size="xl"
            onClick={onCloseButtonClick}
            variant="transparent"
          >
            <IoClose />
          </ActionIcon>
        )}
      </div>
    </div>
  );
};

export default FlexbaseHeader;
