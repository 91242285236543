import { Box, MantineSpacing, Text } from '@mantine/core';
import { ReactNode } from 'react';
import { useSalesStyles } from 'areas/credit/sales/styles';

type SalesPageProps = {
  title: ReactNode;
  subtitle: ReactNode;
  actionButton?: ReactNode;
  salesCards: ReactNode[];
  topGraphic: ReactNode;
  titleMaxWidth?: MantineSpacing; // This is not optimal but I'm so over this stuff, lets get it merged already
};

/**
 * This component encapsulates the common sales page displayed to users without a product.
 * @param title
 * @param subtitle
 * @param actionButton
 * @param salesCards
 * @param topGraphic
 * @param titleMaxWidth
 * @constructor
 */
export const SalesPageContent = ({
  title,
  subtitle,
  actionButton,
  salesCards,
  topGraphic,
  titleMaxWidth,
}: SalesPageProps) => {
  const { classes } = useSalesStyles();

  return (
    <Box className={classes.noAccountDesktopContainer}>
      <Box className={classes.topSectionContainer} c="neutral.0">
        {topGraphic}
        <Text
          ff="redaction"
          maw={titleMaxWidth ?? 670}
          fz={56}
          lts={-1.12}
          mt="6.5rem"
          lh="100%"
          ta="center"
        >
          {title}
        </Text>
        <Text maw={653} ta="center" mt="xl">
          {subtitle}
        </Text>
        <Box mt="xxxl">{actionButton}</Box>
      </Box>
      <Box className={classes.bottomSectionDesktop}>{salesCards}</Box>
    </Box>
  );
};
