import { Button, LoadingOverlay, Text } from '@mantine/core';
import {
  Dropzone,
  IMAGE_MIME_TYPE,
  MIME_TYPES,
  MS_EXCEL_MIME_TYPE,
  MS_WORD_MIME_TYPE,
  PDF_MIME_TYPE,
} from '@mantine/dropzone';
import { useState } from 'react';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import FlexbaseInput from '@common/composites/input/flexbase-input';
import FullScreenModalContainer from '@common/composites/modal/full-screen-modal-container';
import { useCustomInputStyles, useStyles } from './styles';
import { useConfirmAndReviewStyles } from '@utilities/custom-hooks/use-confirm-and-review.styles';
import { showNotification } from '@mantine/notifications';
import { DateInput } from '@mantine/dates';
import { DateTime } from 'luxon';
import FlexbaseSelect from '@common/composites/flexbase-select';
import { Analytics } from 'services/analytics/analytics';
import {
  PiCalendarBlank,
  PiCheckBold,
  PiCloudArrowUpLight,
  PiTrash,
  PiWarningCircleFill,
} from 'react-icons/pi';
import { LegacyEvents } from '@services/analytics/events';

type Props = {
  types: string[];
  closeModal: (uploaded?: boolean) => void;
};

const DocumentUploadModal = ({ types, closeModal }: Props) => {
  const { classes, theme } = useStyles();
  const { classes: customInputStyles } = useCustomInputStyles();
  const { classes: repaymentClasses } = useConfirmAndReviewStyles();

  const [updatedTypes, setUpdatedTypes] = useState<string[]>(types);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [docDate, setDocDate] = useState('');

  const addFile = async (fileList: File[]) => {
    setFiles([fileList[0]]);
  };

  const formatDate = (d: Date | null) =>
    d ? DateTime.fromJSDate(d).toISO()! : '';

  const uploadFile = async () => {
    setLoading(true);
    try {
      const response = await flexbaseOnboardingClient.uploadDocument({
        file: files[0],
        description: description || undefined,
        type: type || undefined,
        docDate: docDate || undefined,
      });
      if (response.success) {
        Analytics.track(LegacyEvents.DOCUMENT_UPLOAD, {
          description,
          type,
          docDate,
        });
        showNotification({
          title: 'Uploaded',
          message: `${response.docMetadata.sourceName} successfully uploaded!`,
          color: 'flexbase-teal',
          icon: <PiCheckBold />,
        });
        closeModal(true);
      } else {
        throw 'Unsuccessful';
      }
    } catch (e) {
      console.error('Failed to upload document', e);
      showNotification({
        title: 'Upload failed',
        message: 'There was an error uploading the selected file',
        color: 'red',
        icon: <PiWarningCircleFill size={'1.2rem'} />,
      });
    } finally {
      setLoading(false);
    }
  };

  const DropzoneChildren = () => (
    <div className={classes.dropZonePlaceholderContainer}>
      <PiCloudArrowUpLight
        size={'2rem'}
        color={theme.colors.blackish[0]}
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginRight: '0.938rem',
        }}
      />
      <Text className={classes.dropZonePlaceholder}>
        Drag and drop or&nbsp;<span>browse</span>&nbsp;to add document
      </Text>
    </div>
  );

  return (
    <FullScreenModalContainer closeModal={closeModal}>
      <LoadingOverlay visible={loading} />
      <div className={classes.modalContainer}>
        <Text className={repaymentClasses.title}>Upload Document</Text>
        <Dropzone
          onDrop={(file) => addFile(file)}
          maxSize={3 * 1024 ** 2}
          accept={[
            ...IMAGE_MIME_TYPE,
            ...PDF_MIME_TYPE,
            ...MS_WORD_MIME_TYPE,
            ...MS_EXCEL_MIME_TYPE,
            MIME_TYPES.csv,
            'text/plain',
            'application/rtf',
          ]}
          multiple={false}
          classNames={{
            root: classes.dropZone,
          }}
        >
          <DropzoneChildren />
        </Dropzone>
        <div className={classes.filesContainer}>
          {files.length > 0 ? (
            files.map((file, i) => (
              <div key={file.name + i} className={classes.file}>
                {file.name}
                <button
                  className={classes.deleteFile}
                  onClick={() => {
                    setFiles(files.filter((f) => f !== file));
                  }}
                >
                  <PiTrash size={'1.125rem'} color={theme.colors.failure[1]} />
                </button>
              </div>
            ))
          ) : (
            <div className={repaymentClasses.subtitle}>
              Add a document to continue
            </div>
          )}
        </div>
        <div>
          <Text className={repaymentClasses.inputTitle}>Description</Text>
          <FlexbaseInput
            size="lg"
            placeholder="(optional)"
            value={description}
            onChange={(e) => setDescription(e.currentTarget.value)}
            disabled={files.length < 1}
          />
        </div>
        <div>
          <FlexbaseSelect
            label="Type"
            placeholder="(optional)"
            inputProps={{
              disabled: files.length < 1,
              size: 'lg',
              classNames: customInputStyles,
            }}
            data={updatedTypes.map((t) => ({ label: t, value: t }))}
            searchable
            nothingFound={({ value }) => (
              <Text
                size="sm"
                c="gray"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setUpdatedTypes([...updatedTypes, value]);
                  setType(value);
                }}
              >
                Use new type: {value}
              </Text>
            )}
            clearable
            comboboxProps={{ position: 'bottom' }}
            maxDropdownHeight={400}
            value={type}
            onChange={(e) => setType(e || '')}
          />
        </div>
        <DateInput
          disabled={files.length < 1}
          className={customInputStyles.datePicker}
          rightSection={
            <PiCalendarBlank
              color={theme.colors.blackish[0]}
              size={'1.25rem'}
            />
          }
          valueFormat="MM/DD/YYYY"
          label="Document date"
          placeholder="MM/DD/YYYY"
          value={docDate ? DateTime.fromISO(docDate).toJSDate() : null} // Fix
          onChange={(d) => setDocDate(formatDate(d))}
        />
        <Button
          variant="filled"
          className={repaymentClasses.confirm}
          onClick={uploadFile}
          size="lg"
          disabled={files.length < 1 || loading}
        >
          Upload
        </Button>
        <Button
          variant="outline"
          className={repaymentClasses.goBack}
          onClick={() => closeModal()}
          size="lg"
        >
          Cancel
        </Button>
      </div>
    </FullScreenModalContainer>
  );
};

export default DocumentUploadModal;
