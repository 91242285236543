import { useDetailSummaryDateFilters } from '@common/composites/details-page/filters/use-detail-summary-date-filters';
import { useSideModalFilters } from '@common/composites/details-page/filters/use-side-modal-filters';
import { formatFilterDate } from '../formatters/format-datetime';
import { DateTime } from 'luxon';
import { useEffect } from 'react';

export const useFilteredDates = () => {
  const { getFilterByKey, addFilter, removeFilter } =
    useDetailSummaryDateFilters();
  const sideModalFilters = useSideModalFilters();
  const currentDateFilter = getFilterByKey('date')?.filterValue;
  const before =
    currentDateFilter && formatFilterDate(currentDateFilter?.endDate?.c);
  const after =
    currentDateFilter && formatFilterDate(currentDateFilter?.startDate?.c);
  const option = currentDateFilter?.option;

  // set 'last 30 days' as default filter
  useEffect(() => {
    const endDate = DateTime.now();
    const startDate = endDate.minus({ months: 1 });

    const options = {
      key: 'date',
      fn: () => true,
      label: 'Date',
      filterValue: { startDate, endDate, option: 'Last 30 days' },
      showChip: false,
    };

    addFilter('date', options);
    sideModalFilters.addFilter('date', options);

    return () => {
      removeFilter('date');
      sideModalFilters.removeFilter('date');
    };
  }, []);

  return {
    before,
    after,
    option,
  };
};
