import {
  ActiveFiltersReturnType,
  createUseFiltersHook,
  FilterFnMap,
} from '@common/utilities/filter/filters';
import { Transaction } from '@services/flexbase/banking.model';
import { atom } from 'recoil';

const SideModalFilterState = atom<FilterFnMap<Transaction>>({
  key: 'side_modal_filter_state',
  default: {},
});

export function useSideModalFilters(): ActiveFiltersReturnType<Transaction> {
  return createUseFiltersHook(SideModalFilterState);
}
