import InviteUser from 'areas/invite-user/content/invite-user';
import FullScreenModalContainer from '@common/composites/modal/full-screen-modal-container';

type Props = { closeModal: () => void; refreshTeamMembers?: () => void };

const InviteUserModal = ({ closeModal, refreshTeamMembers }: Props) => {
  return (
    <FullScreenModalContainer closeModal={closeModal}>
      <InviteUser
        closeModal={closeModal}
        refreshTeamMembers={refreshTeamMembers}
      />
    </FullScreenModalContainer>
  );
};

export default InviteUserModal;
