import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Text,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { formatCurrency } from 'utilities/formatters';
import { CheckmarkIcon, DownloadIcon } from 'assets/svg';
import { DepositAccount, PlaidAccount } from 'types/move-funds.model';
import { DateTime } from 'luxon';

type Props = {
  paymentId: string;
  isChargePay: boolean;
  paymentAmount: number;
  paymentStatus: string;
  paymentAccount?: PlaidAccount | DepositAccount;
  onCloseClick: () => void;
  setStep: React.Dispatch<React.SetStateAction<'review' | 'success' | 'error'>>;
};

const PaymentSuccess = ({
  paymentId,
  onCloseClick,
  paymentAmount,
  paymentAccount,
  paymentStatus,
  isChargePay,
  setStep,
}: Props) => {
  const theme = useMantineTheme();
  const message =
    paymentAccount?.plaidOrDeposit === 'deposit'
      ? 'Your payment is being processed!'
      : 'Processing of your payment may take up to 4 business days. Upon successful settlement, your credit balance will be updated.';

  const accountName =
    paymentAccount?.plaidOrDeposit === 'deposit'
      ? paymentAccount.nickName || paymentAccount.name
      : paymentAccount?.accountName || paymentAccount?.bankName;
  const paymentDate = DateTime.now().toFormat('LLL dd, yyyy');

  const accountInfo = [
    { label: 'Pay from', value: accountName },
    { label: 'Payment date', value: paymentDate },
    { label: 'Status', value: paymentStatus },
    { label: 'Download receipt' },
  ];

  const pdfLink = isChargePay
    ? `/charge-card/receipt/${paymentId}`
    : `/payment/card-receipt/${paymentId}`;

  return (
    <Box>
      <Box bg="neutral.0" p="md">
        <Flex gap="sm" align="center">
          <Flex
            p="md"
            align="center"
            justify="center"
            bg="primarySecondarySuccess.0"
            sx={{ borderRadius: '100%' }}
          >
            <CheckmarkIcon />
          </Flex>
          <Box>
            <Text fw={500} size="lg">
              You’ve made a {formatCurrency(paymentAmount)} payment.
            </Text>
            <Text c="neutral.7" size="sm">
              We’ll update your available credit immediately.
            </Text>
          </Box>
        </Flex>
        <Divider my="md" />

        <Grid>
          {accountInfo.map((item) => (
            <Grid.Col key={item.label} span={6}>
              {item.label === 'Download receipt' ? (
                <UnstyledButton
                  variant="subtle"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => window.open(pdfLink, '_blank')}
                >
                  <Flex gap="xs">
                    <Text color="primarySecondarySuccess.2">
                      Download receipt
                    </Text>
                    <DownloadIcon
                      width={20}
                      height={20}
                      color={theme.colors.primarySecondarySuccess[2]}
                    />
                  </Flex>
                </UnstyledButton>
              ) : (
                <>
                  <Text>{item.label}</Text>
                  <Text tt="capitalize">{item.value}</Text>
                </>
              )}
            </Grid.Col>
          ))}
        </Grid>
      </Box>
      <Text mt="sm" size="xs" c="neutral.7">
        {message}
      </Text>

      <Flex gap="md" justify="flex-end" mt="xxxl">
        <Button
          variant="outline"
          onClick={() => setStep('review')}
          data-testid="make-another-payment-button"
        >
          Make another payment
        </Button>
        <Button
          onClick={() => onCloseClick()}
          data-testid="go-back-to-credit-button"
        >
          Done
        </Button>
      </Flex>
    </Box>
  );
};

export default PaymentSuccess;
