import { rem, Tooltip } from '@mantine/core';
import { TablePayment } from '@utilities/payments-rows';
import { tooltipPaymentInfo } from './tooltip-payment-info';
import { domesticPaymentTypes } from '@services/flexbase/banking.model';
import { PiInfoBold } from 'react-icons/pi';

const DisplayingPayAmount = (payment: TablePayment) => {
  const isUSDPayment = payment.currency === 'USD';
  const isDomesticType = domesticPaymentTypes.has(payment.type);
  const statusNotValidForTooltip = new Set(['Canceled', 'Completed', 'Failed']);
  const displayTooltip =
    !isUSDPayment &&
    payment.type === 'International outgoing wire' &&
    !statusNotValidForTooltip.has(payment.status);

  if (displayTooltip) {
    return (
      <Tooltip
        sx={{
          padding: rem(10),
          fontSize: rem(13),
          textAlign: 'center',
        }}
        label={tooltipPaymentInfo(payment.currency || 'USD')}
      >
        <div>
          {payment.estimatedAmount} <PiInfoBold size={'1rem'} /> &rarr;{' '}
          {payment.fxAmount} {payment.currency}
        </div>
      </Tooltip>
    );
  } else if (!['Failed', 'Canceled'].includes(payment.status) && isUSDPayment) {
    return (
      <>
        {payment.amount} &rarr; {payment.amount} USD
      </>
    );
  } else if (payment.status.includes('Completed') && !isDomesticType) {
    return (
      <>
        {payment.amount} &rarr; {payment.fxAmount} {payment.currency}
      </>
    );
  } else {
    return payment.amount;
  }
};

export default DisplayingPayAmount;
