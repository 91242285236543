import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useSecurityCenterStyles = createStyles((theme) => ({
  sectionBox: {
    padding: theme.spacing.xl,
    backgroundColor: theme.colors.neutral[0],
    border: `1px solid #C5C2BE`, // This color is not in theme...
    borderRadius: theme.defaultRadius,
  },
  factorSelectBox: {
    border: `1px solid ${theme.colors.neutral[3]}`,
    borderRadius: '0.5rem',
    cursor: 'pointer',
    backgroundColor: theme.colors.neutral[0],
    width: '100%',
    height: '5.5rem',
    padding: '0 1.25rem',
  },
  factorSelectBoxDisabled: {
    opacity: 0.5,
    cursor: 'inherit',
    pointerEvents: 'none',
  },
  infoTag: {
    backgroundColor: theme.colors.promote[1],
    padding: '0 6px',
    border: `1px solid ${theme.colors.promote[3]}`,
    borderRadius: '0.25rem',
    color: theme.colors.promote[7],
    fontSize: rem(12),
  },
  formSectionBox: {
    padding: theme.spacing.xl,
    backgroundColor: theme.colors.neutral[0],
    border: `1px solid #C5C2BE`, // This color is not in theme...
    borderRadius: theme.defaultRadius,
  },
}));
