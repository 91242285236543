import type { CreateTokenRequest } from 'flexbase-client';
import { FlexbaseClient } from 'flexbase-client';
import { Wretcher } from 'wretch';
import { Analytics } from '../analytics/analytics';

import {
  BankingParameters,
  CardStatus,
  ChargeAccountsResponse,
  CloseDepositAccountResponse,
  CloseDepositStatusRequest,
  CounterpartyInfoResponse,
  CounterpartyRequest,
  CounterpartyResponse,
  CounterpartyResponseList,
  CreateApplicationStatusResponse,
  CreateBeneficiary,
  CreateDepositAccountRequest,
  CreateDepositAccountResponse,
  CreateMoneyMovementRequest,
  CreateUnitcoTokenResponse,
  CustTokenVGS,
  DebitCardPinStatusResponse,
  DebitCardResponse,
  DebitCardsResponse,
  DepositAccInfoResponse,
  DepositAccountHistoryParams,
  DepositAccountHistoryResponse,
  DepositListResponse,
  DomesticWireInstructionsModel,
  DomesticWireInstructionsResponse,
  FullCreateBeneficiaryResponse,
  FullGetBeneficiaryResponse,
  GetApplicationStatusResponse,
  GetBeneficiaryParameters,
  GetBeneficiaryRequirementsResponse,
  getCheckDepositDataResponse,
  getCheckDepositHistoryResponse,
  getCheckDepositsListResponse,
  GetCompanyBeneficiariesParameters,
  GetMoneyMovementsParams,
  GetUnitcoTokenResponse,
  InstitutionResponse,
  InternationalPaymentsCreateResponse,
  InternationalPaymentsGetResponse,
  InternationalWireInstructionsModel,
  IntlPaymentInfoResponse,
  IntlPaymentsResponse,
  IssueDebitCard,
  MoneyMovement,
  MoneyMovementListReponse,
  MoneyMovementResponse,
  PaymentDocsResponse,
  PaymentInfoReponse,
  PlaidBalances,
  PlaidRelinkRequest,
  PlaidRelinkResponse,
  ReserveAccountResponse,
  ReserveAccountsListResponse,
  ReserveTransactionsResponse,
  Statement,
  StatementsResponse,
  TransactionParams,
  TransactionResponse,
  UpdateCardRequest,
  UpdateChargeAccount,
  UpdateChargeAccountResponse,
  UpdateDepositAccountNicknameResponse,
  UpdatePaymentDocResponse,
} from './banking.model';
import { DateTime } from 'luxon';
import { AvailableCurrencies } from '../../areas/payments/components/send-payment/international-payments/util/types';
import { LegacyEvents } from '@services/analytics/events';

export class FlexbaseBankingClient {
  private readonly client: Wretcher;

  // This is a dirty, dirty hack until the entire onboarding client and all models are in flexbase-client
  constructor(flexbaseClient: FlexbaseClient) {
    this.client = flexbaseClient['_client'] as Wretcher;
  }

  private bankingParams(options?: BankingParameters) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any = {};

    let property: keyof BankingParameters;
    for (property in options) {
      if (options && Object.hasOwn(options, property)) {
        if (typeof options[property] === 'object') {
          const newDate = options[property] as DateTime;
          params[property] = newDate.toISO();
        } else if (typeof options[property] === 'boolean') {
          params[property] = true;
        } else {
          params[property] = options[property];
        }
      }
    }

    return params;
  }

  async marketingSegment(): Promise<void> {
    return this.client.url(`/marketing/segment`).post();
  }

  async createApplication(): Promise<CreateApplicationStatusResponse> {
    return this.client
      .url(`/banking/application`)
      .post()
      .json<CreateApplicationStatusResponse>();
  }

  async getApplicationStatus(): Promise<GetApplicationStatusResponse> {
    return this.client
      .url(`/banking/application`)
      .get()
      .json<GetApplicationStatusResponse>();
  }

  async getDepositList(): Promise<DepositListResponse> {
    return this.client
      .url(`/banking/deposits`)
      .get()
      .json<DepositListResponse>();
  }

  async getDepositAccount(id: string): Promise<DepositAccInfoResponse> {
    return this.client
      .url(`/banking/deposits/${id}`)
      .get()
      .json<DepositAccInfoResponse>();
  }

  async getDepositAccountHistory(
    id: string,
    params: DepositAccountHistoryParams,
  ): Promise<DepositAccountHistoryResponse> {
    // If query params are omitted, the history for the last 60 days will be returned
    return this.client
      .url(`/banking/deposits/${id}/history`)
      .query(params)
      .get()
      .json();
  }

  async createDepositAccount(
    request: CreateDepositAccountRequest,
  ): Promise<CreateDepositAccountResponse> {
    return this.client
      .url(`/banking/deposits`)
      .post(request)
      .json<CreateDepositAccountResponse>();
  }

  async editDepositNickname(accountId: string, nickname: string) {
    return this.client
      .url(`/banking/deposits/${accountId}/nickname`)
      .put({ nickName: nickname })
      .json<UpdateDepositAccountNicknameResponse>();
  }

  async getPlaidBalances(): Promise<PlaidBalances> {
    return this.client.url('/plaid/balances').get().json();
  }

  async relinkPlaidAccount(
    request: PlaidRelinkRequest,
  ): Promise<PlaidRelinkResponse> {
    return this.client
      .url('/plaid/linkToken/update')
      .post(request)
      .json<PlaidRelinkResponse>();
  }

  async getCounterPartyList(): Promise<CounterpartyResponseList> {
    return this.client
      .url(`/banking/counterparties`)
      .get()
      .json<CounterpartyResponseList>();
  }

  async getCounterParty(id: string): Promise<CounterpartyInfoResponse> {
    return this.client
      .url(`/banking/counterparties/${id}`)
      .get()
      .json<CounterpartyInfoResponse>();
  }

  async updateCounterpartyNickname(
    id: string,
    nickName: string,
  ): Promise<CounterpartyResponse> {
    return this.client
      .url(`/banking/counterparties/${id}/nickname`)
      .put({ nickName })
      .json<CounterpartyResponse>();
  }

  async createCounterparty(
    request: CounterpartyRequest,
  ): Promise<CounterpartyResponse> {
    return this.client
      .url(`/banking/counterparties`)
      .post(request)
      .json<CounterpartyResponse>();
  }

  async removeCounterparty(id: string): Promise<CounterpartyResponse> {
    return this.client
      .url(`/banking/counterparties/${id}/status`)
      .put({ status: 'inactive' })
      .json<CounterpartyResponse>();
  }

  async getMoneyMovements(
    options: GetMoneyMovementsParams,
  ): Promise<MoneyMovementListReponse> {
    const params = this.bankingParams(options);
    return this.client
      .url(`/banking/moneymovements`)
      .query(params)
      .get()
      .json<MoneyMovementListReponse>();
  }

  async getMoneyMovement(id: string): Promise<PaymentInfoReponse> {
    return this.client
      .url(`/banking/moneymovements/${id}`)
      .get()
      .json<PaymentInfoReponse>();
  }

  async makePayment(
    request: CreateMoneyMovementRequest,
  ): Promise<MoneyMovement> {
    const response = await this.client
      .url(`/banking/moneymovements`)
      .post(request)
      .json<MoneyMovementResponse>();

    if (response.success) {
      Analytics.track(LegacyEvents.BANKING_PAYMENT_SUBMITTED, {
        amount: response.payment.payAmountCents,
        status: response.payment.status,
        direction: response.payment.payDirection,
        type: response.payment.type,
        id: response.payment.id,
      });
    } else {
      Analytics.track(LegacyEvents.BANKING_PAYMENT_ERROR, {
        amount: request.amount,
        direction: request.direction,
        type: request.type,
      });
    }

    return response.payment;
  }

  async approvePayment(id: string): Promise<MoneyMovement> {
    const { payment } = await this.client
      .url(`/banking/moneymovements/${id}/approval`)
      .put()
      .json();

    return payment;
  }

  async confirmPayment(
    id: string,
    verificationCode?: string, // if the verificationCode is missing, then a new SMS will be sent
  ): Promise<MoneyMovement> {
    const { payment } = await this.client
      .url(`/banking/moneymovements/${id}/confirmation`)
      .put({ verificationCode })
      .json();

    return payment;
  }

  async cancelPayment(id: string): Promise<MoneyMovement> {
    const { payment } = await this.client
      .url(`/banking/moneymovements/${id}`)
      .delete()
      .json();

    return payment;
  }

  async getPaymentDocs(paymentId: string): Promise<PaymentDocsResponse> {
    return await this.client
      .url(`/banking/moneymovements/${paymentId}/docs`)
      .get()
      .json();
  }

  async uploadPaymentDoc(
    paymentId: string,
    request: { file: string | Blob; description?: string },
  ): Promise<UpdatePaymentDocResponse> {
    const body = JSON.stringify({
      description: request.description,
    });
    return await this.client
      .url(`/banking/moneymovements/${paymentId}/docs`)
      .formData({
        file: request.file,
        body,
      })
      .post()
      .json();
  }

  async updatePaymentDocs(
    paymentId: string,
    docId: string,
    request: {
      description?: string;
      docDate: Date | string;
      expiresAt: Date | string;
    },
  ): Promise<UpdatePaymentDocResponse> {
    return await this.client
      .url(`/banking/moneymovements/${paymentId}/docs/${docId}`)
      .put(request)
      .json();
  }

  async deletePaymentDocs(
    paymentId: string,
    docId: string,
  ): Promise<UpdatePaymentDocResponse> {
    return await this.client
      .url(`/banking/moneymovements/${paymentId}/docs/${docId}`)
      .delete()
      .json();
  }

  async createVGSCustToken(request: CustTokenVGS) {
    return await this.client.url('/unitco/custToken/vgs').post(request).json();
  }

  async getTransactions(params: TransactionParams) {
    return await this.client
      .url(`/banking/transactions`)
      .query(params)
      .get()
      .json<TransactionResponse>();
  }

  async checkRoutingNumber(
    routingNumber: string,
  ): Promise<{ banks: string[]; success: boolean }> {
    return await this.client
      .url(`/plaid/institution/${routingNumber}`)
      .get()
      .json();
  }

  async checkRoutingNumberUnit(
    routingNumber: string,
  ): Promise<InstitutionResponse> {
    return await this.client
      .url(`/banking/institutions/${routingNumber}`)
      .get()
      .json();
  }

  async issueDebitCard(
    debitCardForm: IssueDebitCard,
  ): Promise<DebitCardResponse> {
    return await this.client.url(`/banking/cards`).post(debitCardForm).json();
  }

  async getDebitCards(
    getFullData = false,
    depositAccountId?: string,
  ): Promise<DebitCardsResponse> {
    const request = this.client.url(`/banking/cards`).query({
      full: getFullData,
      ...(depositAccountId && { depositAccountId }),
    });

    return await request.get().json();
  }

  async cardStatus(
    cardId: string,
    status: CardStatus,
  ): Promise<DebitCardResponse> {
    return await this.client
      .url(`/banking/cards/${cardId}/status`)
      .put({ status })
      .json();
  }

  async getPinStatus(cardId: string): Promise<DebitCardPinStatusResponse> {
    return await this.client.url(`/banking/cards/${cardId}/pin`).get().json();
  }

  async updateBankingDebitCard(
    request: UpdateCardRequest,
  ): Promise<DebitCardResponse> {
    return await this.client
      .url(`/banking/cards/${request.id}`)
      .patch(request)
      .json();
  }

  // unit customer token endpoints
  async getUnitcoToken(): Promise<GetUnitcoTokenResponse> {
    return await this.client
      .url('/unitco/verifToken')
      .get()
      .json<GetUnitcoTokenResponse>();
  }

  async createUnitCoToken(
    createToken: CreateTokenRequest,
  ): Promise<CreateUnitcoTokenResponse> {
    return await this.client
      .url('/unitco/custToken')
      .post(createToken)
      .json<CreateUnitcoTokenResponse>();
  }

  // list all of the statements, filtered by parameters
  async getStatements(options?: BankingParameters): Promise<Statement[]> {
    const params = this.bankingParams(options);

    const { statements } = await this.client
      .url('/banking/statements')
      .query(params)
      .get()
      .json<StatementsResponse>();

    return statements;
  }

  // get a single Statement by its ID
  // as either HTML (type "string") or PDF (type "ArrayBuffer")
  async getStatement(
    statementId: string,
    options?: BankingParameters,
  ): Promise<string | ArrayBuffer> {
    const params = this.bankingParams(options);
    const request = this.client
      .url(`/banking/statements/${statementId}`)
      .query(params)
      .get();

    if (options?.isPdf) {
      return request.arrayBuffer();
    } else {
      return request.json();
    }
  }

  async getDomesticWireInstructions(
    accountId: string,
  ): Promise<DomesticWireInstructionsModel> {
    const response = await this.client
      .url(`/banking/deposits/${accountId}/wireInstructions`)
      .get()
      .json<DomesticWireInstructionsResponse>();

    // The next two lines strip "success" out of the return value.
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars*/
    const { success, ...otherProperties } = response;

    return { ...otherProperties };
  }

  async getInternationalWireInstructions(
    accountId: string,
  ): Promise<InternationalWireInstructionsModel> {
    return await this.client
      .url(`/banking/deposits/${accountId}/internationalWireInstructions`)
      .get()
      .json();
  }

  // Get the latest conversion rate between USD and another supported currency,
  // including the fully-calculated amount of USD required to purchase *requestedAmount* of *toCurrency*
  // if an amount is requested
  async getConversionRate(
    toCurrency: Omit<AvailableCurrencies, 'USD'>,
    requestedAmount?: number,
  ) {
    const params: {
      toCurrency: Omit<AvailableCurrencies, 'USD'>;
      amount?: string;
    } = { toCurrency };
    if (requestedAmount) {
      params.amount = requestedAmount.toFixed(2);
    }
    const { rate, amount } = await this.client
      .url('/internationalPayments/rates')
      .query(params)
      .get()
      .json<{ rate: string; amount?: string }>();

    return {
      rate: Number.parseFloat(rate),
      amount,
    };
  }

  async getBeneficiaryRequirements(params: GetBeneficiaryParameters) {
    return await this.client
      .url('/internationalPayments/beneficiaries/requirements')
      .query(params)
      .get()
      .json<GetBeneficiaryRequirementsResponse>();
  }

  async createBeneficiary(params: CreateBeneficiary) {
    return await this.client
      .url('/internationalPayments/beneficiaries')
      .post(params)
      .json<FullCreateBeneficiaryResponse>();
  }

  async getBeneficiaries(params: GetCompanyBeneficiariesParameters) {
    return await this.client
      .url('/internationalPayments/beneficiaries')
      .query(params)
      .get()
      .json<FullGetBeneficiaryResponse>();
  }

  async getInternationalPaymentsApplication(): Promise<InternationalPaymentsGetResponse> {
    return await this.client
      .url('/internationalPayments/application')
      .get()
      .json();
  }

  async createInternationalPaymentsApplication(): Promise<InternationalPaymentsCreateResponse> {
    return await this.client
      .url('/internationalPayments/application')
      .post()
      .json();
  }

  async getInternationalPaymens(): Promise<IntlPaymentsResponse> {
    return await this.client
      .url(`/internationalPayments/payments`)
      .get()
      .json();
  }

  async closeDepositAccount(
    companyId: string,
    depositId: string,
    request: CloseDepositStatusRequest,
  ): Promise<CloseDepositAccountResponse> {
    return await this.client
      .url(`/banking/deposits/${depositId}/status?companyId=${companyId}`)
      .put(request)
      .json<CloseDepositAccountResponse>();
  }

  async getInternationalPaymentInfo(
    paymentId: string,
  ): Promise<IntlPaymentInfoResponse> {
    return await this.client
      .url(`/internationalPayments/payments/${paymentId}`)
      .get()
      .json();
  }

  async getCheckDepositsList(
    options?: BankingParameters,
  ): Promise<getCheckDepositsListResponse> {
    const params = this.bankingParams(options);
    return await this.client
      .url('/banking/check-deposits')
      .query(params)
      .get()
      .json();
  }

  async getCheckDepositData(id: string): Promise<getCheckDepositDataResponse> {
    return await this.client.url(`/banking/check-deposits/${id}`).get().json();
  }

  async getCheckDepositHistory(
    id: string,
  ): Promise<getCheckDepositHistoryResponse> {
    return await this.client
      .url(`/banking/check-deposits/${id}/history`)
      .get()
      .json();
  }

  async getCheckDepositImage(
    id: string,
    side: 'front' | 'back',
  ): Promise<ArrayBuffer | null> {
    return await this.client
      .url(`/banking/check-deposits/${id}/images/${side}`)
      .get()
      .arrayBuffer();
  }

  async getReserveAccounts(): Promise<ReserveAccountsListResponse> {
    return await this.client.url(`/reserveAccounts`).get().json();
  }

  async getReserveAccountInfo(
    accountId: string,
  ): Promise<ReserveAccountResponse> {
    return await this.client.url(`/reserveAccounts/${accountId}`).get().json();
  }

  async getReserveAccountTransactions(
    accountId: string,
  ): Promise<ReserveTransactionsResponse> {
    return await this.client
      .url(`/reserveAccounts/${accountId}/transactions`)
      .get()
      .json();
  }

  async getChargeCardAccounts(): Promise<ChargeAccountsResponse> {
    return await this.client.url(`/chargeCard/accounts`).get().json();
  }

  async updateChargeAccount(
    accId: string,
    request: Partial<UpdateChargeAccount>,
  ): Promise<UpdateChargeAccountResponse> {
    return await this.client
      .url(`/chargeCard/accounts/${accId}`)
      .patch(request)
      .json();
  }
}
