import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useSalesStyles = createStyles((theme, _, helpers) => ({
  noAccountDesktopContainer: {
    marginTop: theme.spacing.xl,
    maxWidth: rem(1300),
    [helpers.smallerThan(767)]: {
      marginTop: 'unset',
    },
  },
  topSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  bottomSectionDesktop: {
    display: 'flex',
    gap: theme.spacing.md,
    margin: `${rem(120)} 0 ${theme.spacing.xl} 0`,
    maxWidth: rem(1300),
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  noAccountSalesBox: {
    width: rem(310),
    minWidth: rem(310),
    height: rem(413),
    backgroundColor: '#20332C', // Not a theme color :(
    color: theme.colors.neutral[0],
    padding: theme.spacing.xl,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    pointerEvents: 'none',
  },
  flavorTextContainer: { display: 'flex', alignItems: 'center', gap: '4px' },
  flavorTextBullet: {
    width: rem(5),
    height: rem(5),
    backgroundColor: theme.colors.tertiary[2],
    transform: 'rotate(45deg)',
  },
  fakeNotificationsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  topNotification: {
    height: rem(64),
    width: '100%',
    backgroundColor: theme.colors.neutral[2],
    borderRadius: rem(8),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  topNotificationContentContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing.sm,
  },
  topNotificationIconContainer: {
    width: 32,
    height: 32,
    borderRadius: '100%',
    backgroundColor: theme.colors.primarySecondarySuccess[8],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  secondNotification: {
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    height: rem(14),
    width: '95%',
    backgroundColor: '#798580',
  },
  thirdNotification: {
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    height: rem(16),
    width: '85%',
    backgroundColor: '#44544E',
  },
  callToActionContainer: {
    backgroundColor: theme.colors.primarySecondarySuccess[8],
    border: `1px solid ${theme.colors.primarySecondarySuccess[2]}`,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: rem(867),
    padding: '0 2.5rem',
    height: rem(114),
    [helpers.smallerThan('md')]: {
      width: '100%',
      height: 'fit-content',
      flexDirection: 'column',
      padding: theme.spacing.md,
      textAlign: 'center',
      gap: theme.spacing.md,
    },
  },
  creditInfoCta: {
    marginTop: rem(104),
    [helpers.smallerThan('md')]: {
      marginTop: '1.5rem',
    },
  },
}));
