import { Button, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { PiPaperPlaneTiltBold } from 'react-icons/pi';
import {
  createSearchParams,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';
import { Recipient } from 'types/recipient';

type AccountInfoContext = {
  recipient: Recipient;
};

const PaymentRightHeader = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const context = useOutletContext<AccountInfoContext>();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const isRecipientDetailsPage =
    window.location.pathname.includes('/recipient-details');

  const handleClickNavigate = () => {
    if (isRecipientDetailsPage) {
      navigate({
        pathname: '/payments/outgoing/recipient',
        search: createSearchParams({
          recipient: context.recipient.name,
          recipientId: context.recipient.id,
        }).toString(),
      });
    } else {
      navigate('recipient');
    }
  };

  return (
    <Button
      fullWidth={useMobileView}
      leftSection={<PiPaperPlaneTiltBold />}
      onClick={handleClickNavigate}
      data-testid={'send-payment'}
    >
      Send a payment
    </Button>
  );
};

export default PaymentRightHeader;
