import { MultiSelect } from '@mantine/core';
import { uniq } from 'underscore';
import { Transaction } from '@services/flexbase/banking.model';
import { useBankingTransactionsModalFilters } from '../use-banking-transactions-filters';

export const BankingTransactionsAccountFilter = ({
  transactions,
}: {
  transactions: Transaction[];
}) => {
  const { addFilter, removeFilter, getFilterByKey } =
    useBankingTransactionsModalFilters();

  const depositAccounts = uniq(
    transactions.map((transaction) => transaction.depositAccount),
  );

  const handleChange = (val: string[]) => {
    if (val.length > 0) {
      addFilter('accountName', {
        key: 'accountName',
        filterValue: val,
        fn: (row) => val.includes(row.depositAccount),
        label: `Name: ${val.join(', ')}`,
        showChip: true,
      });
    } else {
      removeFilter('accountName');
    }
  };

  return (
    <MultiSelect
      data={depositAccounts}
      placeholder="Account name"
      searchable
      value={getFilterByKey('accountName')?.filterValue || []}
      onChange={handleChange}
    />
  );
};
