import { SummaryEditBox, SummaryEditBoxClassnames } from '../summary-edit-box';
import FlexbaseInput from '@common/composites/input/flexbase-input';
import { useStyles } from '../summary.styles';
import { useOnboardingStyles } from '../../../onboarding.styles';
import { useForm } from '@mantine/form';
import { validateRequired } from '../../../../../utilities/validators/validate-required';
import { useRecoilState } from 'recoil';
import { ApplicationState } from '../../../../../recoil-state/application/product-onboarding';
import { flexbaseOnboardingClient } from '../../../../../services/flexbase-client';

type Props = { classNames?: SummaryEditBoxClassnames };

const SummaryNameForm = ({ classNames }: Props) => {
  const { classes } = useStyles();
  const { classes: onboardingClasses } = useOnboardingStyles();
  const [{ user }, setStatus] = useRecoilState(ApplicationState);
  const personalInfoForm = useForm({
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
    },
    validate: {
      firstName: (val) =>
        validateRequired(val) ? null : 'First name is required',
      lastName: (val) =>
        validateRequired(val) ? null : 'Last name is required',
    },
  });

  const onSave = async () => {
    const validationResult = personalInfoForm.validate();
    if (validationResult.hasErrors) {
      throw 'Please correct all errors before saving';
    }
    try {
      const { firstName, lastName } = personalInfoForm.values;
      await flexbaseOnboardingClient.updateUser({
        id: user.id,
        firstName,
        lastName,
      });
      setStatus((prev) => ({
        ...prev,
        user: { ...user, firstName, lastName },
      }));
    } catch (e) {
      throw 'Could not update name';
    }
  };

  return (
    <SummaryEditBox
      editInputs={
        <div className={classes.inputContainer}>
          <FlexbaseInput
            placeholder="First name"
            label="First name"
            classNames={{ label: onboardingClasses.inputLabel }}
            className={classes.input}
            {...personalInfoForm.getInputProps('firstName')}
            data-sardine-id="input-summary-first-name"
            id="summary-first-name"
          />
          <FlexbaseInput
            placeholder="Last name"
            label="Last name"
            classNames={{ label: onboardingClasses.inputLabel }}
            className={classes.input}
            {...personalInfoForm.getInputProps('lastName')}
            data-sardine-id="input-summary-last-name"
            id="summary-last-name"
          />
        </div>
      }
      savePromise={onSave}
      label={'Full name'}
      value={`${user.firstName} ${user.lastName}`}
      onCancelClick={() => personalInfoForm.reset()}
      classNames={classNames}
    />
  );
};

export default SummaryNameForm;
