import { Box, Button, Text } from '@mantine/core';
import { ArrowRight } from '../../../assets/svg';
import { useSalesStyles } from 'areas/credit/sales/styles';
import { ReactNode } from 'react';

type Props = {
  title: ReactNode;
  subtitle: ReactNode;
  onApplyAgainClick: () => void;
  containerClassname?: string;
};

export const CreditApplyAgainCta = ({
  title,
  subtitle,
  onApplyAgainClick,
  containerClassname,
}: Props) => {
  const { classes, cx } = useSalesStyles();

  return (
    <Box className={cx(classes.callToActionContainer, containerClassname)}>
      <Box c="neutral.0">
        <Text fz={20}>{title}</Text>
        <Text fz={14}>{subtitle}</Text>
      </Box>
      <Button
        onClick={onApplyAgainClick}
        variant="outline"
        rightSection={<ArrowRight />}
        c="primarySecondarySuccess.8"
        sx={(theme) => ({
          backgroundColor: theme.colors.neutral[0],
          '&:not([data-disabled])&:hover': {
            backgroundColor: theme.colors.neutral[0],
          },
        })}
      >
        Apply Again
      </Button>
    </Box>
  );
};
