import { SimpleSegmentSelectedFilter } from '@common/utilities/filter/simple-segmented.filter';
import { ActiveFiltersReturnType } from '@common/utilities/filter/filters';
import { CreditTransactionsTableRow } from '../../credit-transactions-table-helpers';
import { useCreditTransactionModalFilters } from '../use-credit-transaction-filters';
import { LineOfCredit } from '@services/flexbase/credit.model';
import { useChargeCardTransactionModalFilters } from 'recoil-state/filters/use-charge-cards-filters';

const getFilterTypeOptions = (lineOfCredit: LineOfCredit) => {
  const FILTER_TYPE_OPTIONS = [
    {
      label: 'Card purchase',
      filterCriteria: 'Card Purchase',
      key: 'purchase',
      visible: true,
    },
    {
      label: 'Payment',
      filterCriteria: 'Payment',
      key: 'payment',
      visible: true,
    },

    {
      label: 'Interest',
      filterCriteria: 'Interest',
      key: 'interest',
      visible: lineOfCredit === 'lithic',
    },
    {
      label: 'Fee',
      filterCriteria: 'Fee',
      key: 'fee',
      visible: lineOfCredit === 'lithic',
    },
    {
      label: 'Refunds/Credits',
      filterCriteria: 'Credit',
      key: 'credit',
      visible: true,
    },
  ];

  const availableFilters = FILTER_TYPE_OPTIONS.filter((opt) => opt.visible);

  return availableFilters;
};

type CreditTypeFilterProps = {
  lineOfCredit: LineOfCredit;
  filterHook: ActiveFiltersReturnType<CreditTransactionsTableRow>;
};

const CreditTypeFilter = ({
  filterHook,
  lineOfCredit,
}: CreditTypeFilterProps) => {
  const FILTER_TYPE_OPTIONS = getFilterTypeOptions(lineOfCredit);
  return (
    <SimpleSegmentSelectedFilter
      filterHook={filterHook}
      filterKey="type"
      options={FILTER_TYPE_OPTIONS}
      label="Type"
      includesValue={(row) => {
        if (row.status === 'settled' && parseInt(row.amount) < 0) {
          return 'Credit';
        }
        return row.type;
      }}
    />
  );
};

export const CreditTransactionsTypeFilter = () => {
  const filterHook = useCreditTransactionModalFilters();

  return <CreditTypeFilter filterHook={filterHook} lineOfCredit="lithic" />;
};

export const ChargeCardTransactionsTypeFilter = () => {
  const filterHook = useChargeCardTransactionModalFilters();

  return <CreditTypeFilter filterHook={filterHook} lineOfCredit="unit" />;
};
