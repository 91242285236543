import { createStyles } from '@mantine/emotion';
import { rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  container: {
    background: '#ffffff',
    borderRadius: theme.defaultRadius,
    padding: rem(24),
    marginTop: rem(16),
  },
  containerAlert: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  containerWarnMessage: {
    display: 'flex',
    alignItems: 'center',
  },
  btnReconnect: {
    borderColor: theme.colors.tertiary[4],
    color: 'black',
    fontWeight: 500,
    borderRadius: rem(4),
  },
  titleText: {
    fontFamily: 'PP Neue Montreal',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: rem(16),
    lineHeight: rem(19),
    color: theme.colors.blackish[0],
  },
  tableContainer: {
    marginTop: rem(24),
  },
  tableCell: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentText: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: rem(14),
    lineHeight: rem(21),
    color: theme.colors.blackish[0],
    '@media (max-width: 767px)': {
      fontSize: rem(12),
    },
  },
  smallContentText: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: rem(12),
    lineHeight: rem(21),
    color: theme.colors.blackish[0],
  },
  menuEllipsisContainer: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    height: 'fit-content',
  },
}));
