import { uniq } from 'underscore';
import { MultiSelect } from '@mantine/core';
import { TablePayment } from '@utilities/payments-rows';
import { usePaymentModalFilters } from '../use-payment-filters';

export const PaymentRecipientFilter = ({
  paymentsData,
}: {
  paymentsData: TablePayment[];
}) => {
  const recipient = uniq(
    paymentsData.map((transaction) => transaction.recipient),
  );

  const { addFilter, removeFilter, getFilterByKey } = usePaymentModalFilters();

  const handleChange = (val: string[]) => {
    if (val.length > 0) {
      addFilter('recipient', {
        key: 'recipient',
        filterValue: val,
        fn: (row) => val.includes(row.recipient),
        label: `Name: ${val.join(', ')}`,
        showChip: true,
      });
    } else {
      removeFilter('recipient');
    }
  };

  return (
    <MultiSelect
      searchable
      data={recipient}
      placeholder="Name"
      onChange={handleChange}
      nothingFoundMessage="Nothing found"
      value={getFilterByKey('recipient')?.filterValue || []}
    />
  );
};
