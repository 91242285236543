import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  agreementTypeText: { margin: '20px 0px', fontFamily: 'PP Neue Montreal' },
  agreementsContainer: { display: 'flex', flexWrap: 'wrap' },
  container: { width: 434, marginRight: 22, marginBottom: 20 },
  a: { textDecoration: 'none', color: theme.colors.blackish[0] },
  documentsContainer: {
    border: 'solid 1px #D9D9D9',
    padding: '40px 20px',
    borderRadius: 8,
    position: 'relative',
    '&:hover': {
      backgroundColor: 'rgba(255, 87, 69, 0.08);',
      border: 'solid 1px #FF5745',
    },
  },
  pdfIcon: { position: 'absolute', top: 20, right: 20 },
}));
