import { useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { Center, LoadingOverlay, Stack } from '@mantine/core';
import { Analytics } from 'services/analytics/analytics';
import {
  useMakePaymentPlaid,
  useMakePaymentUnit,
} from '@queries/use-credit-payment';
import { formatCurrency } from '@utilities/formatters';
import { useGetDepositAccounts } from '@queries/use-deposit-accounts';
import CardPaymentReview from '@common/charge-and-credit-cards/card-payment-review';
import CardPaymentAmount from '@common/card-payment-amount';
import { useGetChargeCardAccounts } from '@queries/use-charge-card-accounts';
import type { DepositAccount, PlaidAccount } from 'types/move-funds.model';
import { paymentAccountQuery } from 'recoil-state/payment.states';
import { getInitialChipValue } from '@common/review-and-pay';
import { LegacyEvents } from '@services/analytics/events';
import { useExternalAccounts } from '@utilities/custom-hooks/use-external-accounts';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
  closeModal: () => void;
  onSuccess: (
    paymentId: string,
    paymentAmount: number,
    paymentAccount: DepositAccount | PlaidAccount,
    paymentStatus: string,
  ) => void;
  onError: () => void;
  isBillPayCredit?: boolean;
};

const ChargeReviewAndPay = ({
  closeModal,
  onSuccess,
  onError,
  isBillPayCredit,
}: Props) => {
  const [reviewing, setReviewing] = useState(false);
  const [paymentAccount, setPaymentAccount] =
    useRecoilState(paymentAccountQuery);

  const queryClient = useQueryClient();
  const { mutate: makePaymentUnit, isPending: isPendingPaymentUnit } =
    useMakePaymentUnit();
  const { mutate: makePaymentPlaid, isPending: isPendingPaymentPlaid } =
    useMakePaymentPlaid();

  const isPending = isPendingPaymentUnit || isPendingPaymentPlaid;

  const {
    data: chargeAccountsData,
    isLoading: isChargeAccountsLoading,
    isError: isChargeAccountsError,
  } = useGetChargeCardAccounts();

  const {
    data: depositAccountsData,
    isLoading: isDepositAccountsLoading,
    isError: isDepositAccountsError,
  } = useGetDepositAccounts();

  const {
    data: plaidAccounts,
    isLoading: isPlaidAccountsLoading,
    isError: isPlaidAccountsError,
  } = useExternalAccounts();

  const depositAccounts = depositAccountsData?.accounts ?? [];

  const chargeAccounts =
    chargeAccountsData?.accounts.filter((acc) => acc.status !== 'Closed') ?? [];

  const isLoading =
    isChargeAccountsLoading ||
    isDepositAccountsLoading ||
    isPlaidAccountsLoading;
  const isError =
    isChargeAccountsError || isDepositAccountsError || isPlaidAccountsError;

  // for v1 the company will have one charge account
  const currentChargeAccount = chargeAccounts[0];
  const currentBalance = Number(currentChargeAccount.balance) / 100;
  const overdueAmount =
    Number(currentChargeAccount.remainingAmountOverdue) / 100;
  const minimumDueAmount =
    Number(currentChargeAccount.remainingAmountDue) / 100;
  const currentChargePaymentAmount =
    minimumDueAmount > 0 ? minimumDueAmount : currentBalance;
  const currentPaymentAmount = currentChargePaymentAmount;
  const maxPaymentAmount = currentBalance;

  const [chip, setChip] = useState<'min' | 'max' | 'custom'>(
    getInitialChipValue(currentPaymentAmount, currentBalance, minimumDueAmount),
  );
  const [paymentAmount, setPaymentAmount] = useState(currentPaymentAmount);
  const initialCustomValue = chip === 'custom' ? paymentAmount : 0;
  const [customAmount, setCustomAmount] = useState(initialCustomValue);

  const handleReview = (data: {
    chip: 'min' | 'max' | 'custom';
    customAmount: number;
  }) => {
    setChip(data.chip);
    setReviewing(true);
    setCustomAmount(data.customAmount);
  };

  const onSuccessPayment = (cardPayment: { id: string; status: string }) => {
    if (!['failed', 'Rejected'].includes(cardPayment.status)) {
      Analytics.track(LegacyEvents.CREDIT_MAKE_PAYMENT, {
        paymentAmount,
      });
      queryClient.invalidateQueries({
        queryKey: ['credit_transactions', 'charge_cards'],
      });
      onSuccess(
        cardPayment.id,
        paymentAmount,
        paymentAccount!,
        cardPayment.status,
      );
    }
  };

  const onConfirmClick = () => {
    const paymentAccountId = paymentAccount!.id;
    if (isBillPayCredit) {
      // here we would run the endpoint for bill pay credit
    } else {
      const isPlaidPayment = paymentAccount?.plaidOrDeposit === 'plaid';
      if (isPlaidPayment) {
        makePaymentPlaid(
          {
            lineOfCredit: 'unit',
            amount: paymentAmount,
            plaidTokenId: paymentAccountId,
            chargeCardAccountId: currentChargeAccount.id,
          },
          {
            onSuccess(data) {
              onSuccessPayment(data.creditPayment);
            },
            onError() {
              onError();
            },
          },
        );
      } else {
        makePaymentUnit(
          {
            lineOfCredit: 'unit',
            amount: paymentAmount,
            depositAccountId: paymentAccountId,
            chargeCardAccountId: currentChargeAccount.id,
          },
          {
            onSuccess(data) {
              onSuccessPayment(data.creditPayment);
            },
            onError() {
              onError();
            },
          },
        );
      }
    }
  };

  useEffect(() => {
    if (isError) {
      onError();
    }
  }, [isError]);

  const paymentAccounts = [
    ...depositAccounts
      .filter((acc) => acc.status === 'Open')
      .map((acc) => ({ ...acc, plaidOrDeposit: 'deposit' }) as DepositAccount)
      .sort((a, b) => b.balance - a.balance),
    ...plaidAccounts
      .filter((acc) => !acc.unlinked && acc.active)
      .map((acc) => ({ ...acc, plaidOrDeposit: 'plaid' }) as PlaidAccount),
  ];

  const stack = reviewing ? (
    <CardPaymentReview
      loadingRequest={isPending}
      onConfirmClick={onConfirmClick}
      paymentAccount={paymentAccount!}
      onGoBackClick={() => setReviewing(false)}
      paymentAmount={formatCurrency(paymentAmount)}
    />
  ) : (
    <CardPaymentAmount
      closeModal={closeModal}
      onReviewClick={handleReview}
      paymentAmount={paymentAmount}
      minimumDue={minimumDueAmount}
      currentBalance={currentBalance}
      minPaymentAmount={overdueAmount}
      paymentAccounts={paymentAccounts}
      maxPaymentAmount={maxPaymentAmount}
      initialData={{ chip, customAmount }}
      onPaymentAmountChange={setPaymentAmount}
      onPaymentAccountChange={setPaymentAccount}
      currentAccount={paymentAccount as DepositAccount}
    />
  );

  if (isLoading) {
    return (
      <Center>
        <LoadingOverlay visible={isLoading} />
      </Center>
    );
  }

  return <Stack>{stack}</Stack>;
};

export default ChargeReviewAndPay;
