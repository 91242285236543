import {
  ActiveFiltersReturnType,
  DateRangeFilterType,
} from '@common/utilities/filter/filters';
import { DateTime } from 'luxon';
import { DateRangeSelection } from '../../areas/credit/components/credit-transactions/credit-transactions-header/date-range-selection';

type DateRangeFilterFn<T> = (
  startDate: DateTime,
  endDate: DateTime,
  item: T,
) => boolean;

type Props<T> = {
  filterHook: ActiveFiltersReturnType<T>;
  filterFn: DateRangeFilterFn<T>;
  customConfiguration?: Partial<{
    onDateRangeChange: (dateRange: DateRangeFilterType) => void;
    dateRange: DateRangeFilterType;
  }>;
  customKey?: string;
  label?: string;
};

export function DateRangeFilter<T>({
  filterHook,
  filterFn,
  customConfiguration,
  customKey = 'date',
  label = 'Date',
}: Props<T>) {
  const { addFilter, getFilterByKey, removeFilter } = filterHook;

  const onDateRangeChange = (dateRange: DateRangeFilterType) => {
    const option = dateRange.option;
    const start = dateRange.startDate;
    const end = dateRange.endDate;

    if (start && end) {
      addFilter(customKey, {
        key: customKey,
        fn: (item) => filterFn(start, end, item),
        label: `${label}: ${start.toLocaleString(
          DateTime.DATE_SHORT,
        )} - ${end.toLocaleString(DateTime.DATE_SHORT)}`,
        filterValue: { startDate: start, endDate: end, option },
        showChip: true,
      });
    } else if (!start && !end) {
      removeFilter(customKey);
    }
  };

  const onSetDateRange =
    customConfiguration?.onDateRangeChange ?? onDateRangeChange;

  const providedDateRange =
    customConfiguration?.dateRange ??
    (getFilterByKey(customKey)?.filterValue || {
      option: 'All time',
      startDate: null,
      endDate: null,
    });

  return (
    <DateRangeSelection
      dateRange={providedDateRange}
      setDateRange={onSetDateRange}
    />
  );
}
