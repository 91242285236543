import type { PlaidAccount, DepositAccount } from './move-funds.model';
import type { DepositAccount as BaseDepositAccount } from 'services/flexbase/banking.model';
import { IndividualOrCompany } from 'areas/payments/components/send-payment/international-payments/util/types';

export type PaymentForm = {
  id?: string;
  amount: number;
  status?: string;
  reason?: string;
  fromAccount?: DepositAccount | PlaidAccount;
  toAccount?: DepositAccount | PlaidAccount;
};

export const paymentFormInitial = {
  id: '',
  amount: 0,
  fromAccount: undefined,
  toAccount: undefined,
} as PaymentForm;

export type MoneyMovementDetails = {
  amount: number;
  sourceAccount: BaseDepositAccount;
  internalNotes?: string;
  memo: string;
  files?: File[];
  scheduledFor: string | null;
};

export type PayMethod =
  | 'wire'
  | 'ach'
  | 'book'
  | 'internationalWire'
  | 'internationalWireUSD';

// i dont know why i dont like it, it's just countarperty flattened.
// i cannot figure out how to use mantine form with typescript + deeply nested interface requirements (address)
export type Recipient = {
  name: string;
  routingNumber: string;
  accountNumber: string;
  accountType: string;
  type: PayMethod;
  dob?: string;
  recipientType: IndividualOrCompany;
  nickName: string;
  address: string;
  addressLine2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  firstName?: string;
  lastName?: string;
};
