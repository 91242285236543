import { CriteriaMultiSelectOption } from '@common/utilities/filter/multi-select-filter';
import { ActiveFiltersReturnType } from '@common/utilities/filter/filters';
import { SegmentedFilter } from './segmented.filter';

type Props<T> = {
  label: string;
  options: CriteriaMultiSelectOption[];
  filterKey: string;
  filterHook: ActiveFiltersReturnType<T>;
  includesValue: (item: T) => string;
};

/**
 * Used when you simply want to provide options and match against a string. Example usage
 * in spend-sync-status-filter.tsx
 * @param options List of multiselect options and match criteria
 * @param filterKey The key for this filter
 * @param filterHook The filter hook for this filter
 * @param includesValue A function to unwrap an item and get a string value to do `includes` with
 * @param label Label
 * @constructor
 */
export function SimpleSegmentSelectedFilter<T>({
  options,
  filterKey,
  filterHook,
  includesValue,
  label,
}: Props<T>) {
  const { getFilterByKey, addFilter, removeFilter } = filterHook;

  return (
    <SegmentedFilter
      options={options}
      selectedOption={getFilterByKey(filterKey)?.filterValue || null}
      onChange={(selectedOption) => {
        if (selectedOption) {
          addFilter(filterKey, {
            label: `${label}: ${selectedOption.label}`,
            filterValue: selectedOption,
            key: filterKey,
            fn: (row) =>
              selectedOption.filterCriteria.includes(includesValue(row)),
            showChip: true,
          });
        } else {
          removeFilter(filterKey);
        }
      }}
    />
  );
}
