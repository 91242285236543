import TwoFactorAuth from '@common/composites/two-factor-auth';
import { Flex, Stack, Text, Title } from '@mantine/core';
import {
  useConfirmPayment,
  useSendCodeConfirmPayment,
} from '@queries/use-payments';
import { MoneyMovement } from '@services/flexbase/banking.model';
import { useEffect, useState } from 'react';

type Props = {
  paymentId: string;
  onConfirmSuccess: (details: MoneyMovement) => void;
};

const TwoFactorConfirmation = ({ paymentId, onConfirmSuccess }: Props) => {
  const {
    mutate: confirmSms,
    isPending: isConfirmSmsPending,
    error: confirmSmsError,
  } = useConfirmPayment();
  const {
    mutate: resendSmsCode,
    isPending: resendSmsCodePending,
    error: resendSmsCodeError,
  } = useSendCodeConfirmPayment();
  const [isRetryingSmsConfirmation, setIsRetryingSmsConfirmation] =
    useState(false);

  const confirmationSmsError = isRetryingSmsConfirmation
    ? ''
    : confirmSmsError?.message;
  const errorMsg = confirmationSmsError ?? resendSmsCodeError?.message ?? '';
  const isPending = isConfirmSmsPending || resendSmsCodePending;
  const hasRetry = confirmSmsError instanceof Error;

  useEffect(() => {
    handleSendCode();
  }, []);

  const handleChange = (code: string) => {
    if (paymentId && code.length === 6) {
      confirmSms(
        { id: paymentId, code },
        {
          onSuccess: (data) => {
            onConfirmSuccess(data);
          },
          onError: () => {
            setIsRetryingSmsConfirmation(false);
          },
        },
      );
    }
  };

  const handleSendCode = () => {
    if (paymentId) {
      resendSmsCode(paymentId, {
        onSuccess: () => {
          setIsRetryingSmsConfirmation(true);
        },
      });
    }
  };

  return (
    <Flex justify="center" align="center" bg="neutral.2">
      <Stack gap="md" align="center">
        <Title>Two-factor authentication</Title>
        <Text size="sm">
          To ensure the safety of this payment, we are requesting you
          reauthorize with the six-digit code sent via SMS.
        </Text>
        <TwoFactorAuth
          hasRetry={hasRetry}
          errorMsg={errorMsg}
          onChange={handleChange}
          onResendCode={handleSendCode}
          loading={isPending}
        />
      </Stack>
    </Flex>
  );
};

export default TwoFactorConfirmation;
