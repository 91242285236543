import { useOnboardingStyles } from '../../onboarding.styles';
import { useForm } from '@mantine/form';
import { useRecoilState, useRecoilValue } from 'recoil';
import OnboardingStep from '../../components/onboarding-step';
import { useState } from 'react';
import FlexbaseSelect from '../../../../components/composites/flexbase-select';
import { Analytics } from '../../../../services/analytics/analytics';
import TagManager from 'react-gtm-module';
import {
  ApplicationState,
  ProductOnboardingBtnLoaderState,
} from '../../../../recoil-state/application/product-onboarding';
import { Input } from '@mantine/core';
import {
  RequiredFieldValidator,
  validateRequired,
} from '@utilities/validators';
import { RadioSelectList } from '@common/composites/radio/radio-select-list';
import { useApplicationFlowContext } from '../../onboarding-hooks';
import { useUpdatePlatformBusinessMutation } from '../../../../queries/use-platform-business';

const legalStrucs = [
  { label: 'LLC', value: 'LLC' },
  { label: 'C-Corp', value: 'C-Corp' },
  { label: 'S-Corp', value: 'S-Corp' },
  { label: 'Limited Partnership', value: 'LP' },
  { label: 'Sole Proprietor', value: 'S-Prop' },
  { label: 'Trust', value: 'Trust' },
  { label: 'Non-Profit', value: 'Non-Profit' },
];

const businessStageOptions = [
  {
    value: 'small-business',
    title: 'Small business',
    description:
      'You are a small business with less than $5M in annual revenue.',
  },
  {
    value: 'mid-size',
    title: 'Mid-size company',
    description: 'You have over $5M in annual revenue.',
  },
  {
    value: 'startup',
    title: 'Startup with investors',
    description: 'You have raised money from accredited investors and funds.',
  },
  {
    value: 'family-office',
    title: 'Family office',
    description: 'You are an owner in a family office with over $5M in assets.',
  },
];

const BusinessType = () => {
  const { classes: onboardingClasses } = useOnboardingStyles();
  const { navigateToNextProductStep, goBack, createOrUpdateCompany } =
    useApplicationFlowContext();
  const { mutate } = useUpdatePlatformBusinessMutation();
  const [companyOnboarding, setCompanyOnboarding] =
    useRecoilState(ApplicationState);
  const [loading, setLoading] = useRecoilState(ProductOnboardingBtnLoaderState);
  const [error, setError] = useState('');
  const { user } = useRecoilValue(ApplicationState);

  const businessTypeForm = useForm({
    initialValues: {
      legalStructure: companyOnboarding.company.legalStructure || '',
      businessStage: companyOnboarding.company.businessStage,
    },
    validate: {
      legalStructure: (val) =>
        validateRequired(val) ? null : 'Please select a business type',
      businessStage: (val, formValues) => {
        if (formValues.legalStructure !== 'S-Prop') {
          return RequiredFieldValidator(
            'Choose an option that best represents your company',
          )(val);
        }

        return null;
      },
    },
  });

  const onSubmit = async () => {
    const validationResult = businessTypeForm.validate();
    if (!validationResult.hasErrors) {
      setError('');
      setLoading(true);
      const formValues = businessTypeForm.values;
      if (user) {
        Analytics.people.set(user.id, {
          businessType: formValues.legalStructure,
        });
      }
      setCompanyOnboarding((prev) => ({
        ...prev,
        ...formValues,
      }));
      let canNavigateToNextStep = true;
      if (companyOnboarding.company.id) {
        const result = await createOrUpdateCompany(formValues);
        mutate({
          accountId: companyOnboarding.accountId,
          businessId: companyOnboarding.company.id,
          request: {
            type: formValues.legalStructure,
            name: companyOnboarding.company.companyName || 'My Company',
          },
        });
        canNavigateToNextStep = result.success;
      }
      if (canNavigateToNextStep) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'businessTypeSubmitted',
          },
        });
        navigateToNextProductStep();
      } else {
        setError('Unable to update business type');
        setLoading(false);
      }
    }
  };

  const onBack = () => {
    goBack();
  };

  const setLegalStructure = (value: string | null) => {
    setError('');
    businessTypeForm.setFieldValue('legalStructure', value || '');
    if (value === 'S-Prop') {
      businessTypeForm.setFieldValue('businessStage', '');
    }
  };

  return (
    <OnboardingStep
      title="About your company"
      onBackClick={onBack}
      onNextClick={onSubmit}
      stepId="Business-type"
      showContinueSpinner={loading}
      error={error}
      width={696}
    >
      <FlexbaseSelect
        placeholder="Select a legal structure"
        data={legalStrucs}
        {...businessTypeForm.getInputProps('legalStructure')}
        value={businessTypeForm.values.legalStructure}
        onChange={setLegalStructure}
        data-sardine-id="input-select-business-type"
        id="input-select-business-type"
        inputProps={{
          classNames: { label: onboardingClasses.inputLabel },
        }}
        label="Legal structure"
      />
      <Input.Wrapper
        label={`Select your business stage${
          businessTypeForm.values.legalStructure === 'S-Prop'
            ? ' (Not Applicable)'
            : ''
        }`}
        mt="xl"
        sx={{
          ...(businessTypeForm.values.legalStructure === 'S-Prop' && {
            opacity: 0.5,
            pointerEvents: 'none',
          }),
        }}
        {...businessTypeForm.getInputProps('businessStage')}
      >
        <RadioSelectList
          selected={businessTypeForm.values.businessStage}
          onRadioCardClick={(val) =>
            businessTypeForm.setFieldValue('businessStage', val)
          }
          radioOptions={businessStageOptions}
        />
      </Input.Wrapper>
    </OnboardingStep>
  );
};

export default BusinessType;
