import { Modal, rem } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';
import { TablePayment } from '@utilities/payments-rows';
import PaymentDetails from 'areas/payments/components/payment-details-modal/payment-details';

const PaymentDetailsModal = ({
  openPayment,
  onClose,
}: {
  openPayment: TablePayment;
  onClose?: () => void;
}) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 767px)');

  const handleClose = () => {
    onClose ? onClose() : navigate('..', { relative: 'path' });
  };

  return (
    <Modal
      opened={!!openPayment.id}
      onClose={handleClose}
      styles={{
        header: { display: 'none' },
        inner: { width: isMobile ? '100vw' : rem(642), right: '0px' },
        body: { padding: 0 },
      }}
      transitionProps={{ transition: 'fade' }}
      closeOnClickOutside
      fullScreen
    >
      <PaymentDetails row={openPayment} onClose={handleClose} />
    </Modal>
  );
};

export default PaymentDetailsModal;
