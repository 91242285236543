import { useStyles } from '../styles';
import useModal from '@common/composites/modal/modal-hook';
import { useDeleteExpenseLink } from '@queries/use-integrations';
import { notifications } from '@mantine/notifications';

export const useDisconnectIntegration = () => {
  const { classes } = useStyles();
  const { openConfirmationModal } = useModal();
  const { mutate: deleteConnection, isPending } = useDeleteExpenseLink();

  const disconnectIntegration = ({
    connectionId,
    platformLabel,
    onSuccess,
    onConfirmed,
  }: {
    connectionId: string;
    platformLabel: string;
    onSuccess?: () => void | Promise<void>;
    onConfirmed?: () => void | Promise<void>;
  }) => {
    openConfirmationModal({
      title: `Are you sure?`,
      confirmText: 'Disconnect',
      cancelText: 'Stay connected',
      content: `This will prevent you from syncing data with ${platformLabel}.`,
      onConfirm: () => {
        deleteConnection(
          { connectionId },
          {
            onSuccess: () => {
              notifications.show({
                color: 'primarySecondarySuccess.2',
                message: 'Disconnected successfully.',
              }),
                onSuccess?.();
            },
            onError: () =>
              notifications.show({
                color: 'critical.2',
                message:
                  'An unexpected error occurred while disconnecting. Please try again later.',
              }),
          },
        );

        onConfirmed?.();
      },
      closeOnClickOutside: true,
      classNames: {
        confirmButton: classes.confirmDisconnect,
      },
    });
  };

  return {
    disconnectIntegration,
    isPending,
  };
};
