import FlexNumberInput, {
  FlexNumberInputProps,
} from '@common/flex-number-input';

export const SpendPlanCurrencyInput = (props: FlexNumberInputProps) => {
  return (
    <FlexNumberInput
      variant="default"
      radius={8}
      leftSection="$"
      leftSectionWidth={24}
      pattern="[0-9]*"
      decimalScale={2}
      thousandSeparator=","
      placeholder="0.00"
      {...props}
      styles={{
        icon: {
          paddingLeft: 8,
        },
        input: {
          '&[data-with-icon]': {
            paddingLeft: 12 + 10,
          },
        },
        ...props.styles,
      }}
    />
  );
};
