import { MultiSelect } from '@mantine/core';
import { useMemo } from 'react';
import { uniq } from 'underscore';
import { TablePayment } from '@utilities/payments-rows';
import { useActionRequiredModalFilters } from '../use-action-required-filters';

type Props = {
  payments: TablePayment[];
};

export const PaymentRequestedByFilter = ({ payments }: Props) => {
  const who = useMemo<string[]>(() => {
    if (payments) {
      return uniq(
        payments
          .map((t) => t.requestedBy)
          .filter((t) => t !== undefined && t !== null),
      );
    }
    return [];
  }, [payments]);

  const { addFilter, removeFilter, getFilterByKey } =
    useActionRequiredModalFilters();

  return (
    <MultiSelect
      data={who}
      placeholder="Requested by"
      searchable
      value={getFilterByKey('requestedBy')?.filterValue || []}
      onChange={(val) => {
        if (val.length > 0) {
          addFilter('requestedBy', {
            key: 'requestedBy',
            filterValue: val,
            fn: (row: TablePayment) => val.includes(row.requestedBy || ''),
            label: `Requested by: ${val.join(', ')}`,
            showChip: true,
          });
        } else {
          removeFilter('requestedBy');
        }
      }}
    />
  );
};
