import { Box, Button, rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { ReactNode, useState } from 'react';
import { PiCaretDown, PiCaretUp } from 'react-icons/pi';

type LineItemsContainerProps = {
  children: ReactNode;
};

const LineItemsContainer = ({ children }: LineItemsContainerProps) => {
  const { classes, cx } = useStyles();
  const [showLineItems, setShowLineItems] = useState(false);
  const toggleLineItems = () => setShowLineItems((prev) => !prev);
  return (
    <Box
      className={cx(
        classes.lineItemsContainer,
        showLineItems ? classes.expanded : classes.collapsed,
      )}
    >
      {children}
      {!showLineItems && <Box className={classes.containerOpacity} />}

      <Box style={{ marginTop: showLineItems ? rem(16) : 0 }}>
        <Button
          className={classes.toggleButton}
          variant="subtle"
          onClick={toggleLineItems}
          rightSection={
            showLineItems ? <PiCaretUp size={16} /> : <PiCaretDown size={16} />
          }
        >
          {showLineItems ? 'Hide Line Items' : 'Show Line Items'}
        </Button>
      </Box>
    </Box>
  );
};

export default LineItemsContainer;

const useStyles = createStyles((theme) => ({
  lineItemsContainer: {
    border: `1px solid ${theme.colors.neutral[3]}`,
    borderRadius: theme.spacing.xs,
    padding: rem(16),
    position: 'relative',
    overflow: 'hidden',
  },

  containerOpacity: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: rem(200),
    background: `linear-gradient(transparent, ${theme.colors.contentBackground[2]})`,
    pointerEvents: 'none',
  },

  toggleButton: {
    position: 'absolute',
    bottom: 0,
    left: '30%',
    zIndex: 2,
  },

  expanded: {
    maxHeight: 'auto',
  },
  collapsed: {
    maxHeight: rem(220),
  },
}));
