import { FileWithPath } from '@mantine/dropzone';
import { platformClient } from '../services/platform/platform-client';
import { useMutation } from '@tanstack/react-query';

type CreateInvoiceDocumentArgs = {
  type: string;
  file: FileWithPath;
  runOcr: boolean;
  formatType: string;
};

export function useCreateInvoiceDocument() {
  return useMutation({
    mutationFn: (args: CreateInvoiceDocumentArgs) => {
      return platformClient.createInvoiceDocument(args);
    },
  });
}
