import { Box, Button, Group, Stack, Title } from '@mantine/core';
import { IntegrationLink } from '..';
import { PropsWithChildren } from 'react';
import { IntegrationCardItem } from '../list-integrations';
import { useStyles } from '../styles';
import { FiChevronLeft } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { isIntegrationLinkConnected } from '@utilities/integrations';

type IntegrationCardLayoutProps = PropsWithChildren<{
  integration: IntegrationCardItem;
  link?: IntegrationLink;
  withNavigation?: boolean;
  backTo?: string;
}>;

export const IntegrationCardLayout = ({
  children,
  integration,
  link,
  withNavigation = false,
  backTo = '..',
}: IntegrationCardLayoutProps) => {
  const { classes, cx } = useStyles();

  return (
    <Stack
      className={classes.card}
      sx={{
        flexDirection: 'column',
      }}
    >
      {withNavigation && (
        <Box>
          <Button
            component={Link}
            variant="subtle"
            leftSection={<FiChevronLeft />}
            to={backTo}
          >
            Back
          </Button>
        </Box>
      )}

      <Group className={classes.cardBody}>
        {integration.iconComponent}

        <Box className={classes.cardContent}>
          <Group className={classes.cardTitleRow}>
            <Title className={classes.cardTitle}>
              {integration.title}

              {integration.tags.map((tag) => (
                <Box
                  key={`tag_${tag}`}
                  className={cx(classes.cardTag, classes.cardTagNeutral)}
                  sx={{ display: 'inline-block', verticalAlign: 'middle' }}
                  ml="xs"
                >
                  {tag}
                </Box>
              ))}
            </Title>

            {isIntegrationLinkConnected(link) ? (
              link.isReady ? (
                <Box className={cx(classes.cardTag, classes.cardTagSuccess)}>
                  Connected
                </Box>
              ) : (
                <Box className={cx(classes.cardTag, classes.cardTagNeutral)}>
                  Connecting...
                </Box>
              )
            ) : null}
          </Group>

          {children}
        </Box>
      </Group>
    </Stack>
  );
};
