import { DateTime } from 'luxon';
import { useMediaQuery } from '@mantine/hooks';
import { Box, Button, Flex, Group, rem, Text } from '@mantine/core';
import useModal from '@common/composites/modal/modal-hook';
import { ChargeCardAccount } from '@services/flexbase/banking.model';
import { formatCurrency } from '@utilities/formatters';
import CardRepaymentModal from '@common/card-repayment-modal';
import { useStyles } from 'areas/credit/components/credit-payment/credit-payment.styles';
import { PiArrowClockwiseBold, PiArrowUDownRightLight } from 'react-icons/pi';

type Props = {
  isError: boolean;
  chargeBalance: ChargeCardAccount;
  refetchChargeAccounts: () => void;
  isBillPayCredit?: boolean;
};

const ChargePaymentCard = ({
  isError,
  chargeBalance,
  refetchChargeAccounts,
  isBillPayCredit,
}: Props) => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const { classes, theme } = useStyles({ credit: false });
  const { openRightModal, openFullModal, closeAllModals } = useModal();

  const closeModal = () => {
    refetchChargeAccounts();
    closeAllModals();
  };

  const openPaymentModal = () => {
    if (isMobile) {
      openFullModal(
        <CardRepaymentModal
          isChargePay
          closeModal={closeModal}
          isBillPayCredit={isBillPayCredit}
        />,
      );
    } else {
      openRightModal(
        <CardRepaymentModal
          isChargePay
          closeModal={closeModal}
          isBillPayCredit={isBillPayCredit}
        />,
      );
    }
  };

  const handlePayNowButton = () => openPaymentModal();

  const amountOverdue =
    Number(chargeBalance?.remainingAmountOverdue ?? 0) / 100;
  const currentBalance = Number(chargeBalance?.balance ?? 0) / 100;
  const dueAmount = Number(chargeBalance?.remainingAmountDue ?? 0) / 100;

  const nextRepaymentDueDate = DateTime.fromISO(
    chargeBalance?.nextRepaymentDueDate,
  ).toFormat('MMM dd');

  const currentBalanceString = `current balance : ${formatCurrency(
    currentBalance,
  )}`;

  const getDaysTilDue = () => {
    if (!chargeBalance?.nextRepaymentDueDate) {
      return 'N/A';
    }
    const diff = DateTime.fromFormat(
      chargeBalance.nextRepaymentDueDate,
      'yyyy-MM-dd',
    ).diffNow();

    const diffDays = Math.floor(diff.as('days'));
    if (dueAmount <= 0) {
      return 'Nothing due';
    }

    return diffDays < 0
      ? 'Due today'
      : `Due in ${diffDays} ${diffDays === 1 ? 'day' : 'days'}`;
  };

  if (isError) {
    return (
      <div className={classes.creditPaymentContainer}>
        <Group onClick={refetchChargeAccounts} sx={{ cursor: 'pointer' }}>
          <PiArrowClockwiseBold />
          <Text fw={500} c="neutral.6">
            Error. Reload data.
          </Text>
        </Group>
      </div>
    );
  }

  return (
    <Box
      p="xl"
      h="100%"
      mih={rem(200)}
      pos="relative"
      bg="neutral.0"
      sx={{
        borderRadius: theme.defaultRadius,
        border: `1px solid ${theme.colors.neutral[1]}`,
      }}
    >
      <Flex justify="space-between">
        <Text c="neutral.7" fw={500}>
          Next payment
        </Text>
        <Flex
          h={rem(28)}
          w={rem(119)}
          align="center"
          bg="tertiary.1"
          justify="center"
          sx={(t) => ({ borderRadius: t.defaultRadius })}
        >
          <Text size="sm" fw={500} c="primarySecondarySuccess.8">
            {amountOverdue > 0 ? 'OVERDUE' : getDaysTilDue()}
          </Text>
        </Flex>
      </Flex>
      <Flex direction="column" justify="space-between" h="85%">
        <Box mb="xl">
          <Text c="primarySecondarySuccess.8" fz={26} fw={500}>
            {formatCurrency(dueAmount)}
          </Text>
          {dueAmount > 0 && (
            <Flex align="center">
              <PiArrowUDownRightLight stroke={theme.colors.neutral[6]} />
              <Text c="neutral.6" size="xs">
                {chargeBalance?.repaymentConfigurations?.autoPay
                  ? 'Auto pay on '
                  : 'Payment due on '}{' '}
                {nextRepaymentDueDate}
              </Text>
            </Flex>
          )}
        </Box>

        <Flex align="end" justify="space-between">
          <Button onClick={handlePayNowButton}>Pay now</Button>

          <Text c="neutral.6" size="sm">
            {currentBalanceString}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ChargePaymentCard;
