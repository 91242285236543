import { atom } from 'recoil';
import { FilterFnMap } from '@common/utilities/filter/filters';
import { TablePayment } from '@utilities/payments-rows';

export const PaymentFilterState = atom<FilterFnMap<TablePayment>>({
  key: 'payment_filter_state',
  default: {},
});

export const PaymentFilterModalState = atom<FilterFnMap<TablePayment>>({
  key: 'payment_filter_modal_state',
  default: {},
});
