import { PiWarningCircle } from 'react-icons/pi';
import { Box, Button, Flex, Stack, Text, useMantineTheme } from '@mantine/core';
import { setPaymentRejectedError } from '@utilities/formatters';

type TransferErrorProps = {
  reason?: string;
  onRetry: () => void;
  onClose: () => void;
};

const TransferError = ({ reason, onRetry, onClose }: TransferErrorProps) => {
  const theme = useMantineTheme();

  return (
    <Box>
      <Stack
        p="md"
        bg="neutral.0"
        align="center"
        justify="center"
      >
        <PiWarningCircle size={40} fill={theme.colors.critical[3]} />
        <Text size="lg" fw={500}>
          Your transaction has failed
        </Text>
        {reason ? (
          <Text size="sm">{setPaymentRejectedError(reason)}</Text>
        ) : (
          <Text size="sm">
            Unable to make the payment. Please, try it again
          </Text>
        )}
      </Stack>
      <Flex mt="xxxl" justify="flex-end" gap="md">
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onRetry}>Try again</Button>
      </Flex>
    </Box>
  );
};

export default TransferError;
