import { MultiSelect } from '@mantine/core';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import { useNet60CreditTransactions } from '@queries/use-credit-transactions';
import { useMemo } from 'react';
import { uniq } from 'underscore';
import { CreditTransactionsTableRow } from '../../credit-transactions-table-helpers';
import { ActiveFiltersReturnType } from '@common/utilities/filter/filters';
import { useCreditTransactionModalFilters } from '../use-credit-transaction-filters';
import { useChargeCardTransactionModalFilters } from 'recoil-state/filters/use-charge-cards-filters';

type CreditTransactionsNameFilterProps = {
  filterHook: ActiveFiltersReturnType<CreditTransactionsTableRow>;
};

const CreditNameFilter = ({
  filterHook,
}: CreditTransactionsNameFilterProps) => {
  const { accountId } = useRecoilValue(ApplicationState);
  const { data } = useNet60CreditTransactions(accountId);

  const who = useMemo(() => {
    if (data) {
      return uniq(data.transactions.map((t) => t.who).filter((t) => !!t));
    }
    return [];
  }, [data]);

  const { addFilter, removeFilter, getFilterByKey } = filterHook;

  return (
    <MultiSelect
      data={who}
      placeholder="Name"
      searchable
      value={getFilterByKey('name')?.filterValue || []}
      onChange={(val) => {
        if (val.length > 0) {
          addFilter('name', {
            key: 'name',
            filterValue: val,
            fn: (row) => val.includes(row.name),
            label: `Name: ${val.join(', ')}`,
            showChip: true,
          });
        } else {
          removeFilter('name');
        }
      }}
    />
  );
};

export const CreditTransactionsNameFilter = () => {
  const filterHook = useCreditTransactionModalFilters();

  return <CreditNameFilter filterHook={filterHook} />;
};

export const ChargeCardTransactionsNameFilter = () => {
  const filterHook = useChargeCardTransactionModalFilters();

  return <CreditNameFilter filterHook={filterHook} />;
};
