import { Box, Button, Divider, Text } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { RECIPIENTS, useDeleteRecipient } from '@queries/use-recipients';
import { useQueryClient } from '@tanstack/react-query';
import { createStyles } from '@mantine/emotion';
import { PiXBold } from 'react-icons/pi';

type Props = {
  closeModal: () => void;
  recipientId: string;
  recipientName: string;
};

const DeleteVendor = ({ closeModal, recipientId, recipientName }: Props) => {
  const { classes, theme } = useStyles();
  const { mutate: deleteRecipient, isPending } = useDeleteRecipient();
  const queryClient = useQueryClient();

  const handleRemoveRecipient = () => {
    deleteRecipient(recipientId, {
      onSuccess: () => {
        showNotification({
          color: theme.colors.flexbaseTeal[6],
          message: `Recipient removed`,
        });
        queryClient.invalidateQueries({
          queryKey: [RECIPIENTS],
        });
        closeModal();
      },
      onError: () => {
        showNotification({
          color: theme.colors.red[6],
          message: `Unable to remove recipient`,
        });
      },
    });
  };

  return (
    <>
      <Box p="md" className={classes.containerHeader}>
        <Text size="md" fw={'400'}>
          Delete recipient
        </Text>
        <PiXBold onClick={() => closeModal()} />
      </Box>
      <Divider />
      <Box p="md">
        Are you sure you want to delete {recipientName}? All bills associated
        with this recipient will remain.
        <Box className={classes.containerButtons}>
          <Button variant="outline" onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button
            loading={isPending}
            onClick={handleRemoveRecipient}
            ml="xs"
            bg={theme.colors.critical[6]}
          >
            Delete recipient
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default DeleteVendor;

const useStyles = createStyles(() => ({
  containerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  containerButtons: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
