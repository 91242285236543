import FlexbaseDefaultHeader from '@common/composites/header/flexbase-header';
import {
  Anchor,
  Box,
  Group,
  Image,
  rem,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { Link, Navigate } from 'react-router-dom';
import HotelImage from 'assets/images/hotel-concierge.svg';
import ConciergeBanner from './concierge-banner';
import { useMatchMedia } from '@utilities/url/window-helpers';
import FAQBox from './faq-box';
import ConciergeRequestsBox from './concierge-requests';
import { useEliteFeatureFlag } from '@utilities/feature-flags';

const Concierge = () => {
  const mantineTheme = useMantineTheme();
  const isMobileScreenSize = useMatchMedia('(max-width: 767px)');
  const isEliteFeatureFlag = useEliteFeatureFlag();
  if (!isEliteFeatureFlag) {
    return <Navigate to="/" />;
  }
  return (
    <div>
      <Anchor
        mt="2rem"
        c="neutral.7"
        display="inline-block"
        component={Link}
        to={'/benefits-and-rewards'}
      >
        <Group gap="xxs">
          <MdOutlineKeyboardArrowLeft />
          <Text fz={rem(14)} color={mantineTheme.colors.neutral[7]}>
            Membership Benefits
          </Text>
        </Group>
      </Anchor>
      <FlexbaseDefaultHeader title="Concierge" />
      <div>
        <Box
          sx={() => ({ position: isMobileScreenSize ? 'unset' : 'relative' })}
        >
          {!isMobileScreenSize && <Image src={HotelImage} mt={rem(11)} />}
          <ConciergeBanner />
        </Box>
        <ConciergeRequestsBox />
        <FAQBox />
      </div>
    </div>
  );
};

export default Concierge;
