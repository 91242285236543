import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useTeamMemberStepStyles = createStyles((theme) => ({
  mobileMembersPanel: {
    backgroundColor: '#fff',
    borderColor: theme.colors.neutral[3],
    borderRadius: theme.radius.sm,
    borderStyle: 'solid',
    borderWidth: 1,
    padding: rem(16),
  },
  mobileMember: {
    '&+&': {
      borderTopColor: theme.colors.neutral[3],
      borderTopStyle: 'solid',
      borderTopWidth: 1,
      marginTop: rem(24),
    },
  },
  mobileNoMembers: {
    backgroundColor: 'white',
    borderTop: `1px solid ${theme.colors.neutral[1]}`,
    fontSize: rem(14),
    padding: rem(24),
    textAlign: 'center',
  },
}));
