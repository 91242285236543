import FlexbaseDefaultHeader from '@common/composites/header/flexbase-header';
import TitleBarActions from './title-bar-actions';
import { TitleFormButtons, TitleFormInput } from './title-form';
import { TitleFormProvider } from './title-form-provider';

type Props = {
  currentTitle: string;
  primaryButton: JSX.Element;
  menuItems: JSX.Element;
  isShowingForm: boolean;
  onHideForm: () => void;
  onSubmit: (title: string) => void;
  isSubmitting: boolean;
};

const TitleBar = ({
  currentTitle,
  primaryButton,
  menuItems,
  isShowingForm,
  onHideForm,
  onSubmit,
  isSubmitting,
}: Props) => {
  const { primaryAction, title } = isShowingForm
    ? {
        primaryAction: (
          <TitleFormButtons
            onClick={onHideForm}
            currentTitle={currentTitle}
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
          />
        ),
        title: <TitleFormInput />,
      }
    : {
        primaryAction: (
          <TitleBarActions
            primaryButton={primaryButton}
            menuItems={menuItems}
          />
        ),
        title: currentTitle,
      };

  return (
    <TitleFormProvider currentTitle={currentTitle}>
      <FlexbaseDefaultHeader primaryAction={primaryAction} title={title} />
    </TitleFormProvider>
  );
};

export default TitleBar;
