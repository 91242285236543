import { atom } from 'recoil';
import { FilterFnMap } from '@common/utilities/filter/filters';
import { Transaction } from '@services/flexbase/banking.model';

export const BankingTransactionsFilterState = atom<FilterFnMap<Transaction>>({
  key: 'banking_transactions_filter_state',
  default: {},
});

export const BankingTransactionsFilterStateModal = atom<
  FilterFnMap<Transaction>
>({
  key: 'banking_transactions_filter_state_modal',
  default: {},
});
