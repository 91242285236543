import { Box, Flex, Text, TextInput } from '@mantine/core';
import {
  AddRecipientWizard,
  useAddRecipientWizard,
} from '../../../../add-recipient-wizard';
import FlexbaseInput from '@common/composites/input/flexbase-input';
import FlexbaseSelect from '@common/composites/flexbase-select';
import GooglePlacesSuggest from '@common/composites/input/google-places-suggest-input';
import { US_STATES } from 'constants/constants';
import { useDomesticWireRecipientForm } from './hooks/use-domestic-wire-recipient-form';
import {
  CounterpartyBase,
  createCounterpartyMutationParams,
} from 'areas/payments/components/send-payment/send-payment';
import { useAddRecipientSubmit } from 'areas/payments/pages/add-recipient/use-add-recipient-submit';
import { useCreateCounterparty } from '@queries/use-counterparties';
import RoutingNumberInput from './routing-number-input';
import { submitStepLabels } from '../../../util';
import PaymentDetailsStepError from '../../payment-details-step-error';
import { createStyles } from '@mantine/emotion';

const DomesticWireForm = () => {
  const { setState, state } = useAddRecipientWizard();
  const { classes, theme } = useStyles();
  const { recipientDetailsFormValues, domesticWireRecipientFormValues } = state;

  const {
    mutate: createCounterparty,
    isPending: isPendingCounterpartyCreation,
  } = useCreateCounterparty();

  const {
    submitRecipient,
    isPending: isPendingRecipientCreation,
    submissionError,
    handleClearSubmissionError,
  } = useAddRecipientSubmit(recipientDetailsFormValues, createCounterparty);

  const domesticWirePaymentForm = useDomesticWireRecipientForm(
    domesticWireRecipientFormValues,
    recipientDetailsFormValues.type,
  );
  const form = domesticWirePaymentForm;

  const selectAddress = (item: {
    address: string;
    country: string;
    state: string;
    city: string;
    postalCode: string;
  }) => {
    form.setValues({
      address: item.address.trim(),
      city: item.city,
      state: item.state,
      postalCode: item.postalCode,
      country: item.country,
    });
  };

  const handleNext = () => {
    form.validate();

    if (form.isValid()) {
      setState((prev) => ({
        ...prev,
        domesticWireRecipientFormValues: form.values,
      }));

      const params = createCounterpartyMutationParams(
        form.values as CounterpartyBase,
        'wire',
        recipientDetailsFormValues.name,
      );

      submitRecipient(params, recipientDetailsFormValues.dob);
    }
  };

  const isPending = isPendingRecipientCreation || isPendingCounterpartyCreation;

  if (submissionError && submissionError.message.length > 0) {
    return (
      <PaymentDetailsStepError
        submissionError={submissionError}
        onClear={handleClearSubmissionError}
      />
    );
  }

  return (
    <AddRecipientWizard.Step
      onNext={handleNext}
      nextLoading={isPending}
      labels={submitStepLabels}
    >
      <TextInput
        label={
          <Text>
            Account nickname{' '}
            <Text color={theme.colors.neutral[6]} span>
              (Optional)
            </Text>
          </Text>
        }
        placeholder="Enter nickname (optional)"
        {...form.getInputProps('nickName')}
        data-testid="nickName"
      />
      <Flex justify="space-between">
        <Box w={'48%'}>
          <RoutingNumberInput paymentMethod="wire" form={form} />
        </Box>
        <TextInput
          className={classes.inputContainer}
          label="Account number"
          placeholder="Enter account number"
          {...form.getInputProps('accountNumber')}
          data-testid="accountNumber"
        />
      </Flex>
      <Text mt="lg" size="xl">
        Recipient’s legal address
      </Text>
      <GooglePlacesSuggest
        label="Recipient legal address"
        value={form.values.address}
        {...form.getInputProps('address')}
        onOptionSubmit={selectAddress}
        placeholder="Enter address"
        className={classes.addressInput}
        id="input-search-address"
      />
      <FlexbaseInput
        mt="md"
        label="Apartment, suite, floor (optional)"
        data-testid="address2"
        placeholder="Enter apt, suite, etc. (optional)"
        {...form.getInputProps('addressLine2')}
      />
      <Flex justify="space-between">
        <FlexbaseInput
          mt="md"
          label="City"
          data-testid="city"
          placeholder="Enter city"
          w="60%"
          {...form.getInputProps('city')}
        />
        <FlexbaseSelect
          inputProps={{
            mt: 'md',
            w: '40%',
            ml: 'md',
          }}
          label="State or U.S. territory"
          placeholder="Enter state"
          data-testid="state"
          data={US_STATES}
          searchable
          maxDropdownHeight={400}
          {...form.getInputProps('state')}
        />
        <FlexbaseInput
          mt="md"
          w="24%"
          ml="md"
          label="ZIP Code"
          data-testid="zip"
          placeholder="Enter zip"
          {...form.getInputProps('postalCode')}
        />
      </Flex>
    </AddRecipientWizard.Step>
  );
};

export default DomesticWireForm;

const useStyles = createStyles((theme) => ({
  inputContainer: { marginTop: theme.spacing.md, width: '48%' },
  addressInput: { marginTop: theme.spacing.md },
}));
