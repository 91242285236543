import { ActiveFiltersReturnType } from '@common/utilities/filter/filters';
import { Flex, Select, Text } from '@mantine/core';
import { isTruthyString } from '@utilities/validators/validate-string';
import { getBillPayStatusDisplay } from '@utilities/get-bill-pay-status-display';
import { useBillpayInvoicesWithRecipientsAndPayments } from 'areas/billpay/use-bill-pay';
import { useMemo } from 'react';
import { BillpayInvoice, BillPayStatus } from 'types/bill-pay';

type ItemProps = {
  label: string;
  value: string;
  count?: number;
} & React.ComponentPropsWithoutRef<'div'>;

const Status = ({ label, value, count, ...others }: ItemProps) => {
  const { displayStatus, textColor, backgroundColor } =
    value === 'all'
      ? {
          displayStatus: 'All',
        }
      : getBillPayStatusDisplay(value as BillPayStatus);

  return (
    <Flex w="100%" justify="space-between" align="center" {...others}>
      <div style={{ backgroundColor, padding: 4, borderRadius: 6 }}>
        <Text size="sm" color={textColor}>
          {displayStatus}
        </Text>
      </div>
      <Text>{count}</Text>
    </Flex>
  );
};
Status.displayName = 'BillsStatus';

type Props = {
  useFilters: () => ActiveFiltersReturnType<BillpayInvoice>;
  useModalFilters: () => ActiveFiltersReturnType<BillpayInvoice>;
};

const StatusSelect = ({ useFilters, useModalFilters }: Props) => {
  const { removeFilter, addFilter, getFilterByKey } = useFilters();
  const { removeFilter: removeFilterModal, addFilter: addFilterModal } =
    useModalFilters();
  const { data, isSuccess } = useBillpayInvoicesWithRecipientsAndPayments();

  const value = getFilterByKey('select')?.filterValue ?? null;

  const invoices: BillpayInvoice[] | undefined = useMemo(() => {
    if (isSuccess) {
      return data.invoices;
    } else {
      return [];
    }
  }, [data]);

  const handleSelectChange = (val: string | null) => {
    if (!val) {
      removeFilter('select');
      removeFilterModal('select');
      return;
    }

    if (val === 'all') {
      removeFilter('select');
      removeFilterModal('select');
      return;
    }
    addFilter('select', {
      key: 'select',
      filterValue: val,
      label: `Includes ${val}`,
      showChip: false,
      fn: (item) => {
        const normalizedFilterText = val.toLowerCase();
        const strValues = Object.values(item).filter(isTruthyString);
        return strValues
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
    addFilterModal('select', {
      key: 'select',
      filterValue: val,
      label: `Includes ${val}`,
      showChip: false,
      fn: (item) => {
        const normalizedFilterText = val.toLowerCase();
        const strValues = Object.values(item).filter(isTruthyString);
        return strValues
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  const selectData = useMemo(() => {
    const countAll = invoices?.length;
    const draftedCount = invoices?.filter(
      (invoice) => invoice.status === 'drafted',
    ).length;
    const needsApprovalCount = invoices?.filter(
      (invoice) => invoice.status === 'requested',
    ).length;
    const scheduledCount = invoices?.filter(
      (invoice) => invoice.status === 'scheduled',
    ).length;
    const pendingCount = invoices?.filter(
      (invoice) => invoice.status === 'pending',
    ).length;
    const succeededCount = invoices?.filter(
      (invoice) => invoice.status === 'succeeded',
    ).length;
    const failedCount = invoices?.filter(
      (invoice) => invoice.status === 'failed',
    ).length;

    return [
      { value: 'all', label: 'All', count: countAll },
      { value: 'drafted', label: 'Drafted', count: draftedCount },
      {
        value: 'requested',
        label: 'Needs approval',
        count: needsApprovalCount,
      },
      { value: 'scheduled', label: 'Scheduled', count: scheduledCount },
      { value: 'pending', label: 'Pending', count: pendingCount },
      { value: 'succeeded', label: 'Succeeded', count: succeededCount },
      { value: 'failed', label: 'Failed', count: failedCount },
    ];
  }, [invoices]);

  return (
    <Select
      data={selectData}
      placeholder="Status"
      value={value}
      renderOption={({ option }) => <Status {...option} />}
      onChange={(val: string | null) => handleSelectChange(val)}
    />
  );
};

export default StatusSelect;
