import { Avatar, Flex, Text } from '@mantine/core';
import { useGetUsers } from '../../../../../../queries/use-users';
import { getInitialsOfNames } from '@utilities/object';
import { formatCurrency } from '@utilities/formatters';
import { UserIdState } from '../../../../../../recoil-state/application/onboarding-form.state';
import { Request } from '../../../../spend-plans.types';
import { ArrowRightIconGreen } from '../../../../../../assets/svg';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

type Props = {
  request: Request;
  onClick: () => void;
};

const AvatarLabel = ({ request, onClick }: Props) => {
  const { data: users } = useGetUsers();
  const userId = useRecoilValue(UserIdState);

  const decisionLabel = useMemo(() => {
    const manager = users?.find((user) => user.id === request.decisionedBy);
    return `${
      request.decisionedBy === userId
        ? 'You'
        : manager
          ? `${manager?.firstName} ${manager?.lastName}`
          : 'Manager'
    }
        ${request.status} ${request.name}’s request for ${formatCurrency(
          request.amount,
        )}`;
  }, [request, users]);

  return (
    <Flex
      key={request.requestId}
      sx={() => ({ cursor: 'pointer' })}
      align="center"
      justify="space-between"
      p={8}
      onClick={onClick}
    >
      <Flex align="center">
        <Avatar color="green" radius="xl" size="sm">
          {getInitialsOfNames(request.name)}
        </Avatar>
        {request.status === 'pending' ? (
          <Text fz="14px" ml={8}>
            {`${request.name} is requesting ${formatCurrency(request.amount)}`}
          </Text>
        ) : (
          <Text fz="14px" ml={8}>
            {decisionLabel}
          </Text>
        )}
      </Flex>
      <ArrowRightIconGreen height={10} width={10} />
    </Flex>
  );
};

export default AvatarLabel;
