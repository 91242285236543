import FlagIcon from 'areas/payments/components/send-payment/flag-icon';
import { Box } from '@mantine/core';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { getCurrencyFlag } from '../international-payments/util';
import {
  AvailableCurrencies,
  CurrencySelectProps,
} from '../international-payments/util/types';
import FlexbaseSelect, {
  FlexbaseSelectProps,
} from '@common/composites/flexbase-select';
import { supportedCurrenciesData } from '@utilities/international-payments';

type SelectItemProps = ComponentPropsWithoutRef<'div'> & CurrencySelectProps;

const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ label, value, code, ...others }: SelectItemProps, innerRef) => (
    <div ref={innerRef} {...others}>
      <Box
        sx={() => ({
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        })}
      >
        <Box>{label}</Box>
        <Box>
          <FlagIcon flagNationCode={code || ''} />
        </Box>
      </Box>
    </div>
  ),
);

SelectItem.displayName = 'SelectItem';

type Props = {
  value: AvailableCurrencies;
  onChange: (value: AvailableCurrencies) => void;
} & Partial<FlexbaseSelectProps>;

const CurrencySelect = ({ ...props }: Partial<Props>) => {
  return (
    <FlexbaseSelect
      {...props}
      data={supportedCurrenciesData}
      onChange={props.onChange!}
      label="Currency"
      placeholder="Choose a currency"
      searchable
      inputProps={{
        id: 'currency-dropdown',
        rightSection: (
          <FlagIcon
            width="1rem"
            height="1rem"
            flagNationCode={getCurrencyFlag(props.value!)}
          />
        ),
      }}
      itemComponent={SelectItem}
    />
  );
};

export default CurrencySelect;
