import { MantineTheme, rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  title: {
    fontSize: rem(16),
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: rem(19),
    color: theme.colors.blackish[1],
    fontFamily: 'PP Neue Montreal',
  },
  container: {
    padding: 12,
    backgroundColor: 'white',
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.colors.neutral[1]}`,
    maxWidth: rem(1365),
    margin: 'auto',
    marginTop: rem(20),
  },
  header: {
    borderBottom: 'solid',
    borderBottomColor: theme.colors.flexbaseGrey[0],
    borderBottomWidth: rem(0.5),
    paddingBottom: rem(16),
    paddingTop: rem(12),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      flexDirection: 'column',
      gap: 8,
      display: 'flex',
      alignItems: 'flex-start',
    },
  },
  statusColumn: {
    padding: rem(5),
    textAlign: 'center',
    borderRadius: rem(25),
    '@media(max-width: 767px)': {
      width: '100%',
    },
    minWidth: '100px',
  },
  errorMessage: {
    height: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    span: {
      display: 'flex',
      fontWeight: 600,
      marginTop: '32px',
      justifyContent: 'center',
    },

    p: {
      color: '#6F7181',
      fontSize: rem(14),
      textAlign: 'center',
    },
  },
}));

export const CustomMantineStyles = (theme: MantineTheme) => {
  return {
    searchPaymentHistory: {
      input: {
        color: '#5F5F5F',
        height: rem(40),
        opacity: 0.8,
        border: `1px solid ${theme.colors.neutral[1]}`,
        borderRadius: theme.defaultRadius,
        fontSize: rem(14),
        lineHeight: rem(16),
        fontWeight: 500,
        background: 'white',
        fontStyle: 'normal',
        '&:focus': { borderColor: theme.primaryColor },
      },
    },
  };
};
