import {
  ActionIcon,
  Checkbox,
  Grid,
  Paper,
  Select,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useGetPlatformBusinessAccountsPayable } from '@queries/use-platform-business';
import { PiCaretDown, PiX } from 'react-icons/pi';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import { BillpayLineItemAccountingInfo } from 'types/bill-pay';
import { isEmpty } from 'underscore';

type Props = {
  index: number;
  value?: BillpayLineItemAccountingInfo;
  onValueChange: (
    field: keyof BillpayLineItemAccountingInfo,
    value: string | null,
  ) => void;
  onApplyAllChange: (value: boolean) => void;
  isApplyAllChecked: boolean;
};

const getSelectOptions = (
  list: { name: string; id: string; status: string }[],
) => {
  return list
    .filter((item) => item.status === 'active')
    .map((item) => ({
      label: item.name,
      value: item.id,
    }));
};

const LineItemAccountingInfo = ({
  index,
  value,
  onValueChange,
  onApplyAllChange,
  isApplyAllChecked,
}: Props) => {
  const theme = useMantineTheme();
  const { businessId } = useRecoilValue(ApplicationState);
  const { data: accountsPayable } =
    useGetPlatformBusinessAccountsPayable(businessId);
  const { accounts, classes, customers, locations, departments } =
    accountsPayable?.link ?? {};
  const hasAccountsPayable = !isEmpty(accountsPayable);
  const isFirstItem = index === 0;

  if (!hasAccountsPayable) return null;

  return (
    <Paper
      radius="md"
      p="md"
      sx={{
        border: `1px solid ${theme.colors.neutral[3]}`,
      }}
    >
      <Grid grow>
        {!!accounts?.length && (
          <Grid.Col span={6}>
            {!isFirstItem && isApplyAllChecked ? (
              <Stack gap="xxs">
                <Text size="sm" color="neutral.7" fw="500">
                  Account
                </Text>
                <Text size="sm">
                  {accounts.find((v) => v?.id === value?.accounts)?.name ??
                    'Not selected'}
                </Text>
              </Stack>
            ) : (
              <Select
                rightSection={
                  value?.accounts ? (
                    <ClearButton
                      color={theme.colors.neutral[8]}
                      onClick={() => onValueChange('accounts', null)}
                    />
                  ) : (
                    <PiCaretDown color={theme.colors.neutral[8]} />
                  )
                }
                rightSectionProps={{
                  style: { pointerEvents: value?.accounts ? 'all' : 'none' },
                }}
                clearable
                searchable
                label="Account"
                placeholder="Select account"
                data={getSelectOptions(accounts)}
                onChange={(v) => onValueChange('accounts', v)}
                value={value?.accounts || null}
              />
            )}
          </Grid.Col>
        )}
        {!!classes?.length && (
          <Grid.Col span={6}>
            {!isFirstItem && isApplyAllChecked ? (
              <Stack gap="xxs">
                <Text size="sm" color="neutral.7" fw="500">
                  Class
                </Text>
                <Text size="sm">
                  {classes.find((v) => v?.id === value?.classes)?.name ??
                    'Not selected'}
                </Text>
              </Stack>
            ) : (
              <Select
                rightSection={
                  value?.classes ? (
                    <ClearButton
                      color={theme.colors.neutral[8]}
                      onClick={() => onValueChange('classes', null)}
                    />
                  ) : (
                    <PiCaretDown color={theme.colors.neutral[8]} />
                  )
                }
                rightSectionProps={{
                  style: { pointerEvents: value?.classes ? 'all' : 'none' },
                }}
                clearable
                searchable
                label="Class"
                placeholder="Select class"
                data={getSelectOptions(classes)}
                onChange={(v) => onValueChange('classes', v)}
                value={value?.classes || null}
              />
            )}
          </Grid.Col>
        )}
        {!!customers?.length && (
          <Grid.Col span={6}>
            {!isFirstItem && isApplyAllChecked ? (
              <Stack gap="xxs">
                <Text size="sm" color="neutral.7" fw="500">
                  Customer
                </Text>
                <Text size="sm">
                  {customers.find((v) => v?.id === value?.customers)?.name ??
                    'Not selected'}
                </Text>
              </Stack>
            ) : (
              <Select
                rightSection={
                  value?.customers ? (
                    <ClearButton
                      color={theme.colors.neutral[8]}
                      onClick={() => onValueChange('customers', null)}
                    />
                  ) : (
                    <PiCaretDown color={theme.colors.neutral[8]} />
                  )
                }
                rightSectionProps={{
                  style: { pointerEvents: value?.customers ? 'all' : 'none' },
                }}
                clearable
                searchable
                label="Customer"
                placeholder="Select customer"
                data={getSelectOptions(customers)}
                onChange={(v) => onValueChange('customers', v)}
                value={value?.customers || null}
              />
            )}
          </Grid.Col>
        )}
        {!!locations?.length && (
          <Grid.Col span={6}>
            {!isFirstItem && isApplyAllChecked ? (
              <Stack gap="xxs">
                <Text size="sm" color="neutral.7" fw="500">
                  Location
                </Text>
                <Text size="sm">
                  {locations.find((v) => v?.id === value?.locations)?.name ??
                    'Not selected'}
                </Text>
              </Stack>
            ) : (
              <Select
                rightSection={
                  value?.locations ? (
                    <ClearButton
                      color={theme.colors.neutral[8]}
                      onClick={() => onValueChange('locations', null)}
                    />
                  ) : (
                    <PiCaretDown color={theme.colors.neutral[8]} />
                  )
                }
                rightSectionProps={{
                  style: { pointerEvents: value?.locations ? 'all' : 'none' },
                }}
                clearable
                searchable
                label="Location"
                placeholder="Select locations"
                data={getSelectOptions(locations)}
                onChange={(v) => onValueChange('locations', v)}
                value={value?.locations || null}
              />
            )}
          </Grid.Col>
        )}
        {!!departments?.length && (
          <Grid.Col span={6}>
            {!isFirstItem && isApplyAllChecked ? (
              <Stack gap="xxs">
                <Text size="sm" color="neutral.7" fw="500">
                  Department
                </Text>
                <Text size="sm">
                  {departments.find((v) => v?.id === value?.departments)
                    ?.name ?? 'Not selected'}
                </Text>
              </Stack>
            ) : (
              <Select
                rightSection={
                  value?.departments ? (
                    <ClearButton
                      color={theme.colors.neutral[8]}
                      onClick={() => onValueChange('departments', null)}
                    />
                  ) : (
                    <PiCaretDown color={theme.colors.neutral[8]} />
                  )
                }
                rightSectionProps={{
                  style: { pointerEvents: value?.departments ? 'all' : 'none' },
                }}
                clearable
                searchable
                label="Department"
                placeholder="Select departments"
                data={getSelectOptions(departments)}
                onChange={(v) => onValueChange('departments', v)}
                value={value?.departments || null}
              />
            )}
          </Grid.Col>
        )}
        {isFirstItem && (
          <Grid.Col span={6}>
            <Checkbox
              radius="sm"
              color="primarySecondarySuccess.6"
              label="Apply to all line items"
              onChange={(event) => {
                onApplyAllChange(event.currentTarget.checked);
              }}
              checked={isApplyAllChecked}
            />
          </Grid.Col>
        )}
      </Grid>
    </Paper>
  );
};

const ClearButton = ({
  onClick,
  color,
}: {
  color: string;
  onClick: () => void;
}) => (
  <ActionIcon
    onClick={onClick}
    sx={() => ({
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    })}
  >
    <PiX color={color} />
  </ActionIcon>
);

export default LineItemAccountingInfo;
