import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  title: {
    fontFamily: 'PP Neue Montreal',
    fontSize: '48px',
    color: 'black',
    fontWeight: 300,
    marginBottom: 15,
  },
  subtitle: {
    fontFamily: 'PP Neue Montreal',
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0rem',
    textAlign: 'left',
    color: '#5F5F5F',
    marginBottom: 15,
  },
  btnBack: {
    background: 'white',
    border: '1px solid #5F5F5F',
    height: '50px',
    borderRadius: 8,
    width: 'auto',
    '&:hover': {
      background: 'white',
    },
    '@media(max-width: 767px)': {
      width: 'auto',
    },
  },
  btnSubmit: {
    height: '50px',
    width: 230,
  },
  btntext: {
    fontSize: '16px',
    fontWeight: 500,
  },
  btnContainer: {
    justifyContent: 'space-between',
    display: 'flex',
    marginTop: '2vh',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #E6E7E9',
    borderRadius: theme.defaultRadius,
    alignItems: 'center',
    justifyContent: 'center',
    height: rem(42),
    fontFamily: 'Redaction',
    fontSize: '1rem',
    flex: 1,
  },
  text: {
    fontSize: 14,
    color: '#5F5F5F',
    fontWeight: 400,
    lineHeight: '21px',
    textAlign: 'center',
    fontFamily: 'PP Neue Montreal',
    marginTop: 24,
  },
  inputContainer: {
    display: 'flex',
    marginTop: '16px',
    gap: '24px',
    '@media(max-width: 767px)': {
      flexDirection: 'column',
      gap: '16px',
    },
  },
  accordionContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing.xl,
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl,
  },
}));
