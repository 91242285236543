import { createStyles } from '@mantine/emotion';
import { ReactNode } from 'react';
import OnboardingButtonGroup from '@common/onboarding-button-group';
import {
  isInternationalWireUSD,
  isInternationalWire,
} from '@utilities/international-payments';
import { rem } from '@mantine/core';

type Props = {
  children: ReactNode;
  titleText?: string;
  subtitle?: ReactNode;
  balloonText?: string;
  showBackButton?: boolean;
  showNextButton?: boolean;
  onBackClick?: () => void;
  onNextClick?: () => void;
  nextButtonLabel?: string;
  nextButtonFormId?: string;
  nextButtonType?: 'button' | 'submit';
  backButtonLabel?: string;
  footer?: ReactNode;
  loading?: boolean;
};

const PaymentStep = ({
  children,
  balloonText,
  showBackButton = true,
  showNextButton = true,
  onNextClick,
  onBackClick,
  titleText,
  nextButtonLabel,
  nextButtonFormId,
  nextButtonType,
  backButtonLabel,
  subtitle,
  footer,
  loading,
}: Props) => {
  const { classes } = useStyles();

  const onNext = () => {
    if (onNextClick) {
      onNextClick();
    }
  };

  const onBack = () => {
    if (onBackClick) {
      onBackClick();
    }
  };

  return (
    <div className={classes.container}>
      {balloonText && (
        <div className={classes.recipientBalloon}>{balloonText}</div>
      )}
      {titleText && <div className={classes.title}>{titleText}</div>}
      {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
      <div className={classes.inputContainer}>{children}</div>
      <div className={classes.buttonContainer}>
        <OnboardingButtonGroup
          loading={loading}
          showNextButton={showNextButton}
          showBackButton={showBackButton}
          onNextClick={() => onNext()}
          onBackClick={() => onBack()}
          nextButtonLabel={nextButtonLabel}
          nextButtonFormId={nextButtonFormId}
          nextButtonType={nextButtonType}
          backButtonLabel={backButtonLabel}
        />
        {footer ? footer : null}
      </div>
    </div>
  );
};

export const getPrettyMethod = (method: string) => {
  if (method === 'ach') {
    return 'ACH';
  }
  if (method === 'wire') {
    return 'Domestic Wire';
  }
  if (method === 'book') {
    return 'Internal transfer';
  }
  if (isInternationalWireUSD(method)) {
    return 'International wire - USD';
  }
  if (isInternationalWire(method)) {
    return 'International wire - Foreign exchange';
  }
};

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    minHeight: rem(520),
    display: 'flex',
    flexDirection: 'column',
  },
  recipientBalloon: {
    backgroundColor: theme.colors.neutral[2],
    borderRadius: theme.defaultRadius,
    marginBottom: rem(29),
    height: rem(48),
    minHeight: rem(48),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.primaryColor,
    fontSize: rem(14),
    fontWeight: 500,
  },
  title: {
    fontFamily: 'PP Neue Montreal',
    fontWeight: 400,
    fontSize: rem(20),
    lineHeight: '34px',
    '@media(max-width: 767px)': {
      fontSize: rem(24),
    },
  },
  subtitle: {
    fontFamily: 'PP Neue Montreal',
    fontWeight: 500,
    fontSize: rem(14),
    lineHeight: rem(21),
    marginTop: rem(10),
  },
  inputContainer: {
    marginTop: rem(6.4),
  },
  buttonContainer: {
    marginTop: 'auto',
  },
}));

export default PaymentStep;
