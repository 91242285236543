import { MultiSelect } from '@mantine/core';
import { SpendPlanCardsResponse } from '@services/flexbase/spend-plans.model';
import { useMemo } from 'react';
import { uniq } from 'underscore';
import { useSpendCardModalFilters } from '../use-spend-cards-filter';

type Props = { cards: SpendPlanCardsResponse[] };

export const SpendCardNameFilter = ({ cards }: Props) => {
  const cardName = useMemo(() => {
    if (cards) {
      return uniq(cards.map((t) => t.cardName).filter((t) => !!t));
    }
    return [];
  }, [cards]);

  const { addFilter, removeFilter, getFilterByKey } =
    useSpendCardModalFilters();

  return (
    <MultiSelect
      data={cardName}
      placeholder="Card name"
      searchable
      value={getFilterByKey('cardName')?.filterValue || []}
      onChange={(val) => {
        if (val.length > 0) {
          addFilter('cardName', {
            key: 'cardName',
            filterValue: val,
            fn: (row) => val.includes(row.cardName),
            label: `Card Name: ${val.join(', ')}`,
            showChip: true,
          });
        } else {
          removeFilter('cardName');
        }
      }}
    />
  );
};
