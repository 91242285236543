import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { Box, Text, TextInput, useMantineTheme } from '@mantine/core';
import {
  CustomMantineStyles,
  useStyles,
} from 'areas/credit/components/payments-table/payments-table.styles';
import { SearchIcon } from 'assets/svg';
import FlexbaseTable from 'components/table/flexbase-table';
import { useMediaQuery } from '@mantine/hooks';
import { capitalizeOnlyFirstLetter } from 'utilities/formatters/format-strings';
import { formatCurrency } from '@utilities/formatters';
import { formatApiStrings } from 'utilities/formatters/format-api-strings';
import { useGetRebateHistory } from 'queries/use-rebate-history';
import { CompanyIdState } from 'recoil-state/application/onboarding-form.state';
import { useRecoilValue } from 'recoil';
import { paymentFormatStatus } from 'utilities/formatters/payment-format-status';
import { RebateHistory } from 'services/flexbase/flexbase-onboarding-client';
import { TableColumn } from 'react-data-table-component';
import { Issuer } from '@services/flexbase/credit.model';

type Props = {
  issuer?: Issuer;
};

const RebateHistoryTable = ({ issuer }: Props) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [inputText, setInputText] = useState('');
  const companyId = useRecoilValue(CompanyIdState);
  const { data, isLoading, isError } = useGetRebateHistory(companyId, issuer);

  const sortDate = (a: { rebateDate: string }, b: { rebateDate: string }) => {
    return (
      DateTime.fromFormat(a.rebateDate, 'LLL dd, yyyy').toMillis() -
      DateTime.fromFormat(b.rebateDate, 'LLL dd, yyyy').toMillis()
    );
  };

  const payments = useMemo(
    () => [...(data?.payments || [])].sort((a, b) => sortDate(a, b)),
    [data],
  );

  const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  const columns: TableColumn<RebateHistory>[] = [
    {
      name: 'Rebate date',
      selector: (row) => row.rebateDate,
      sortable: true,
      sortFunction: sortDate,
    },
    {
      name: 'Month of repayments',
      selector: (row) => row.monthOfRepayments,
      sortable: true,
    },
    {
      name: 'Total repayment amount',
      selector: (row) => row.totalRepayments,
    },
    {
      name: 'Rebate earned',
      selector: (row) => row.rebateEarned,
      sortable: true,
    },
    {
      name: 'Redeemed via',
      selector: (row) => row.redeemedVia,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      cell: (row) => {
        let statusColor = '';

        switch (row.status) {
          case 'Posted':
            statusColor = '#E9F9F2';
            break;
          case 'Pending':
          case 'Completed':
            statusColor = '#E6E7E9';
            break;
          case 'Returned':
            statusColor = '#F9D9DF';
            break;
          default:
            statusColor = '#E4D2A2';
        }
        return (
          <Box
            className={classes.statusColumn}
            fw={'bold'}
            bg={statusColor}
            c={'black'}
          >
            {formatApiStrings(row.status)}
          </Box>
        );
      },
      sortable: true,
    },
  ];

  const tableData = payments?.map((payment) => {
    return {
      monthOfRepayments: DateTime.fromFormat(
        payment.monthOfRepayments,
        'yyyy-MM',
      ).monthLong!,
      rebateDate: DateTime.fromSQL(payment.rebateDate).toFormat('LLL dd, yyyy'),
      rebateEarned: payment.rebateEarned
        ? formatCurrency(payment.rebateEarned.replace(/(-)/, ''))
        : 'N/A',
      redeemedVia: capitalizeOnlyFirstLetter(payment.redeemedVia),
      status: paymentFormatStatus(payment.status, payment.rebateDate),
      totalRepayments: payment.totalRepayments
        ? formatCurrency(payment.totalRepayments.replace(/(-)/, ''))
        : 'N/A',
    };
  });

  const filteredData = tableData?.filter((payment) => {
    if (inputText === '') {
      return payment;
    } else {
      return (
        payment?.monthOfRepayments.toLowerCase().includes(inputText) ||
        payment.rebateDate.toLowerCase().includes(inputText) ||
        payment.rebateEarned.toLowerCase().includes(inputText) ||
        payment.status.toLowerCase().includes(inputText) ||
        payment.totalRepayments.toLowerCase().includes(inputText)
      );
    }
  });

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Text className={classes.title}> </Text>
        <TextInput
          w={useMobileView ? '100%' : 368}
          styles={{
            input: CustomMantineStyles(theme).searchPaymentHistory.input,
          }}
          placeholder="Search all fields"
          onChange={inputHandler}
          leftSection={<SearchIcon width={20} height={20} />}
        />
      </div>
      <FlexbaseTable
        columns={columns}
        data={filteredData}
        pagination={filteredData && filteredData?.length > 8}
        isFetchingData={isLoading}
        errorMessage={
          isError
            ? 'We are unable to retrieve the company payments at the moment.'
            : ''
        }
      />
    </div>
  );
};

export default RebateHistoryTable;
