import { MultiSelect } from '@mantine/core';
import { useMemo } from 'react';
import { uniq } from 'underscore';
import { useSpendTransactionModalFilters } from '../use-spend-transactions-filter';
import { TransactionData } from '@services/flexbase/spend-plans.model';

type Props = { transactions: TransactionData[] };

export const SpenMerchantFilter = ({ transactions }: Props) => {
  const transaction = useMemo(() => {
    if (transactions) {
      return uniq(transactions.map((t) => t.transaction).filter((t) => !!t));
    }
    return [];
  }, [transactions]);

  const { addFilter, removeFilter, getFilterByKey } =
    useSpendTransactionModalFilters();

  return (
    <MultiSelect
      data={transaction}
      placeholder="Merchant"
      searchable
      value={getFilterByKey('merchant')?.filterValue || []}
      onChange={(val) => {
        if (val.length > 0) {
          addFilter('merchant', {
            key: 'merchant',
            filterValue: val,
            fn: (row) => val.includes(row.transaction),
            label: `Merchant: ${val.join(', ')}`,
            showChip: true,
          });
        } else {
          removeFilter('merchant');
        }
      }}
    />
  );
};
