import { useState } from 'react';
import { Loader, Text } from '@mantine/core';
import { useStyles } from './summary.styles';
import OnboardingButtonGroup from '@common/onboarding-button-group';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from '../../../../recoil-state/application/product-onboarding';
import { useOnboardingStyles } from '../../onboarding.styles';
import SummaryNameForm from './edit-forms/summary-name-form';
import SummaryBusinessPurposeForm from './edit-forms/summary-business-purpose-form';
import SummaryBirthdayForm from './edit-forms/summary-birthday-form';
import { SummaryEditBox } from './summary-edit-box';
import AddressEditForm from './edit-forms/address-edit-form';
import SummaryBusinessNameForm from './edit-forms/summary-business-name-form';
import SummaryWebsiteForm from './edit-forms/summary-website-form';
import SummaryCategoryForm from './edit-forms/summary-category-form';
import SummaryEstimatedRevenueForm from './edit-forms/summary-estimated-revenue-form';
import { TermsFormProvider, useTermsForm } from './terms-form-context';
import {
  ConditionalFieldValidator,
  RequiredFieldValidator,
} from '@utilities/validators';
import { Termsinator } from './termsinator';
import { OnboardingUser } from '../../../../types/onboarding-info';
import { flexbaseOnboardingClient } from '../../../../services/flexbase-client';
import { UnderlinedTabs } from '@common/composites/tabs/underlined-tabs';
import { useHasTerms } from '../../../../utilities/custom-hooks/use-has-terms';
import { useApplicationFlowContext } from '../../onboarding-hooks';
import { Analytics } from '@services/analytics/analytics';
import { ApplicationEvents } from '@services/analytics/events';

const Summary = () => {
  const { classes, cx } = useStyles();
  const { classes: onboardingClasses } = useOnboardingStyles();
  const { user, company, userIsApplicant } = useRecoilValue(ApplicationState);
  const terms = useHasTerms();
  const { goBack, refreshProductOnboardingStatus, navigateToEndScreen } =
    useApplicationFlowContext();
  const [error, setError] = useState('');

  const [loading, setLoading] = useState(false);
  const formOptions = userIsApplicant
    ? ['Personal Information', 'Business Information']
    : ['Personal Information'];
  const [selectedForm, setSelectedForm] = useState<
    'Personal Information' | 'Business Information' | string
  >('Personal Information');
  const onFormChange = (nextSelectedForm: string) => {
    setSelectedForm(nextSelectedForm);
  };

  const termsForm = useTermsForm({
    initialValues: {
      creditTermsOfServiceSigned: user.creditTermsOfServiceSigned,
      bankingTermsOfServiceSigned: user.bankingTermsOfServiceSigned,
      internationalPaymentsTermsOfServiceSigned:
        user.internationalPaymentsTermsOfServiceSigned,
      treasuryTermsOfServiceSigned: user.treasuryTermsOfServiceSigned,
      termsOfServiceSigned: user.termsOfServiceSigned,
      personalGuarantySigned: user.personalGuarantySigned,
      ficoPullSigned: user.ficoPullSigned,
      patriotActSigned: user.patriotActSigned,
      nyTermsSigned: user.nyTermsSigned,
      flTermsSigned: user.flTermsSigned,
    },
    validate: {
      creditTermsOfServiceSigned: ConditionalFieldValidator(
        terms.hasCreditTerms,
        RequiredFieldValidator('Credit terms of service must be accepted'),
      ),
      bankingTermsOfServiceSigned: ConditionalFieldValidator(
        terms.hasBankingTerms,
        RequiredFieldValidator('Banking terms of service must be accepted'),
      ),
      internationalPaymentsTermsOfServiceSigned: ConditionalFieldValidator(
        terms.hasInternationalPaymentsTerms,
        RequiredFieldValidator(
          'International payments terms of service must be accepted',
        ),
      ),
      termsOfServiceSigned: ConditionalFieldValidator(
        terms.hasFlexbaseTerms,
        RequiredFieldValidator('Flexbase terms of service must be accepted'),
      ),
      treasuryTermsOfServiceSigned: ConditionalFieldValidator(
        terms.hasTreasuryTerms,
        RequiredFieldValidator('Treasury terms of service must be accepted'),
      ),
      personalGuarantySigned: ConditionalFieldValidator(
        terms.hasPersonalGuaranty,
        RequiredFieldValidator('Your personal guaranty must be accepted'),
      ),
    },
  });

  const onSubmitApplication = async () => {
    setError('');
    const validationResult = termsForm.validate();
    Analytics.track(ApplicationEvents.APPLICATION_SUBMIT_CLICKED);
    if (!validationResult.hasErrors) {
      setLoading(true);
      try {
        const request: Partial<OnboardingUser> = { id: user.id };
        Object.entries(termsForm.values).forEach(([key, value]) => {
          if (value) {
            request[key as keyof OnboardingUser] = value as any;
          }
        });
        await flexbaseOnboardingClient.updateUser(request);
        await refreshProductOnboardingStatus();
        navigateToEndScreen();
      } catch (e) {
        setError(
          'An error occurred while submitting your application. Please contact support or try again.',
        );
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className={classes.container}>
      <UnderlinedTabs
        tabs={formOptions.map((val) => ({
          value: val,
          label: val,
          route: val,
        }))}
        selectedTab={selectedForm}
        onTabClick={(val) => onFormChange(val.label)}
        classNames={{ tabsContainer: classes.tabsContainer }}
      />

      {selectedForm === 'Personal Information' && (
        <>
          <SummaryNameForm
            classNames={{
              box: cx(
                onboardingClasses.bottomSpacing,
                onboardingClasses.bankAccountCard,
                onboardingClasses.summaryCard,
              ),
            }}
          />
          <SummaryBirthdayForm
            classNames={{
              box: cx(
                onboardingClasses.bottomSpacing,
                onboardingClasses.bankAccountCard,
                onboardingClasses.summaryCard,
              ),
            }}
          />
          <SummaryEditBox
            label="Email"
            value={user.email}
            classNames={{
              box: cx(
                onboardingClasses.bottomSpacing,
                onboardingClasses.bankAccountCard,
                onboardingClasses.summaryCard,
              ),
            }}
            showEdit={false}
          />
          <AddressEditForm
            entity="user"
            classNames={{
              box: cx(
                onboardingClasses.bankAccountCard,
                onboardingClasses.summaryCard,
              ),
            }}
          />
        </>
      )}

      {selectedForm === 'Business Information' && (
        <>
          <SummaryBusinessNameForm
            classNames={{
              box: cx(
                onboardingClasses.bottomSpacing,
                onboardingClasses.bankAccountCard,
                onboardingClasses.summaryCard,
              ),
            }}
          />
          {company.taxId && (
            <SummaryEditBox
              label="EIN"
              value={company.taxId}
              classNames={{
                box: cx(
                  onboardingClasses.bottomSpacing,
                  onboardingClasses.bankAccountCard,
                  onboardingClasses.summaryCard,
                ),
              }}
              showEdit={false}
            />
          )}
          <SummaryBusinessPurposeForm
            classNames={{
              box: cx(
                onboardingClasses.bottomSpacing,
                onboardingClasses.bankAccountCard,
                onboardingClasses.summaryCard,
              ),
            }}
          />
          <AddressEditForm
            entity="company"
            classNames={{
              box: cx(
                onboardingClasses.bottomSpacing,
                onboardingClasses.bankAccountCard,
                onboardingClasses.summaryCard,
              ),
            }}
          />
          {company.website && (
            <SummaryWebsiteForm
              classNames={{
                box: cx(
                  onboardingClasses.bottomSpacing,
                  onboardingClasses.bankAccountCard,
                  onboardingClasses.summaryCard,
                ),
              }}
            />
          )}
          <SummaryCategoryForm
            classNames={{
              box: cx(
                onboardingClasses.bottomSpacing,
                onboardingClasses.bankAccountCard,
                onboardingClasses.summaryCard,
              ),
            }}
          />
          <SummaryEstimatedRevenueForm
            classNames={{
              box: cx(
                onboardingClasses.bottomSpacing,
                onboardingClasses.bankAccountCard,
                onboardingClasses.summaryCard,
              ),
            }}
          />
        </>
      )}
      <div
        className={cx(classes.termsContainer, onboardingClasses.bottomSpacing)}
      >
        <TermsFormProvider form={termsForm}>
          <Termsinator
            showPreview={
              userIsApplicant ||
              user.roles.includes('OWNER') ||
              user.roles.includes('ADMIN')
            }
            showTextAgreements={true}
            termsConfig={terms}
          />
        </TermsFormProvider>
      </div>
      {error && (
        <div
          className={cx(
            onboardingClasses.stepText,
            onboardingClasses.stepErrorText,
          )}
        >
          {error}
        </div>
      )}
      <OnboardingButtonGroup
        classNames={{
          buttonGroup: classes.buttonContainer,
          nextButton: classes.summaryContinueButton,
        }}
        nextButtonLabel={
          loading ? (
            <Loader size="sm" color="white" />
          ) : (
            `Submit${userIsApplicant ? ' Application' : ''}`
          )
        }
        disableAll={loading}
        onBackClick={() => goBack()}
        onNextClick={() => onSubmitApplication()}
      />
      {userIsApplicant ? (
        false
      ) : (
        <Text fz={'xs'} mt={15}>
          By providing the information above and clicking continue, I hereby
          certify, to the best of my knowledge, that the information provided
          above is complete and correct.
        </Text>
      )}
    </div>
  );
};

export default Summary;
