import { MultiSelect } from '@mantine/core';
import { useMemo } from 'react';
import { useBillpayInvoicesModalFilters } from '../use-billpay-bills-filters';
import { BillpayInvoice } from 'types/bill-pay';
import { uniq } from 'underscore';

type Props = {
  invoices: BillpayInvoice[];
};

export const InvoiceVendorFilter = ({ invoices }: Props) => {
  const who = useMemo(() => {
    if (invoices) {
      return uniq(
        invoices
          .map((t) => ({
            value: t.recipientId,
            label: t.recipientName || '',
          }))
          .filter((t) => !!t.label),
        'value',
      );
    }
    return [];
  }, [invoices]);

  const { addFilter, removeFilter, getFilterByKey } =
    useBillpayInvoicesModalFilters();

  return (
    <MultiSelect
      data={who}
      placeholder="Recipient"
      searchable
      value={getFilterByKey('recipient')?.filterValue || []}
      onChange={(val) => {
        if (val.length > 0) {
          addFilter('recipient', {
            key: 'recipient',
            filterValue: val,
            fn: (row) => val.includes(row.recipientId || ''),
            label: `Recipient: ${val
              .map((item) => who.find((e) => e.value === item)?.label)
              .join(', ')}`,
            showChip: true,
          });
        } else {
          removeFilter('recipient');
        }
      }}
    />
  );
};
