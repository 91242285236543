import { atom } from 'recoil';
import { FilterFnMap } from '@common/utilities/filter/filters';
import {
  CreditCardTableRow,
  DebitCardTableRow,
} from '@common/utilities/filter/cards-filter/card-table-row.model';

export const CreditCardsFilterState = atom<FilterFnMap<CreditCardTableRow>>({
  key: 'credit_cards_filter_state',
  default: {},
});

export const CreditCardsFilterStateModal = atom<
  FilterFnMap<CreditCardTableRow>
>({
  key: 'credit_cards_filter_state_modal',
  default: {},
});

export const ChargeCardsFilterState = atom<FilterFnMap<CreditCardTableRow>>({
  key: 'charge_cards_filter_state',
  default: {},
});

export const ChargeCardsFilterStateModal = atom<
  FilterFnMap<CreditCardTableRow>
>({
  key: 'charge_cards_filter_state_modal',
  default: {},
});

export const DebitCardsFilterState = atom<FilterFnMap<DebitCardTableRow>>({
  key: 'debit_cards_filter_state',
  default: {},
});

export const DebitCardsFilterStateModal = atom<FilterFnMap<DebitCardTableRow>>({
  key: 'debit_cards_filter_state_modal',
  default: {},
});
