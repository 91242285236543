import {
  useBankFeedOtpPolling,
  useCreateIntuitFeedConnection,
} from '@queries/use-integrations';
import { useLoginFormContext } from 'providers/login-form.context';
import { useRecoilState, useRecoilValue } from 'recoil';
import { integrationModalState } from 'recoil-state/integration-modal-state';
import { IsIntegrationsAuthorized } from 'recoil-state/integrations/integrations';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import { useHasTerms } from '@utilities/custom-hooks/use-has-terms';
import { useEffect } from 'react';

export const useIntegrationModal = () => {
  const loginFormContext = useLoginFormContext();
  const [
    {
      redirectUrl,
      shouldRefetchOtp,
      connectionId,
      logedByflow,
      loginDataLoaded,
    },
    setIntegrationModalState,
  ] = useRecoilState(integrationModalState);

  const isAuthorized = useRecoilValue(IsIntegrationsAuthorized);
  const { completedOnboarding } = useRecoilValue(ApplicationState);
  const { hasTermsForActiveProduct } = useHasTerms();

  const callbackFeedConection = (conId: string) => {
    setIntegrationModalState((prev) => ({
      ...prev,
      shouldRefetchOtp: true,
      connectionId: conId,
    }));
  };

  const { mutate: createFeedConnection } = useCreateIntuitFeedConnection(
    callbackFeedConection,
  );

  const redirectUser = (otp: string) => {
    if (otp && redirectUrl) {
      const parsedUrl = new URL(redirectUrl);
      parsedUrl.searchParams.set('otp', otp);
      window.location.replace(parsedUrl.toString());
    }
  };

  useBankFeedOtpPolling(connectionId!, redirectUser, shouldRefetchOtp);

  const handleOtp = async () => {
    try {
      loginFormContext.setState({ loading: true });

      if (!isAuthorized) {
        loginFormContext.setState({
          error: 'An error occurred, please contact your administrator.',
          loading: false,
        });
        return;
      }
      if (!completedOnboarding) {
        loginFormContext.setState({
          error: (
            <>
              Application pending, please{' '}
              <a
                href="https://home.flex.one/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                open the Flex web app
              </a>{' '}
              to continue.
            </>
          ),

          loading: false,
        });
        return;
      }
      if (hasTermsForActiveProduct) {
        loginFormContext.setState({
          error: (
            <>
              Terms of service updated, please{' '}
              <a
                href="https://home.flex.one/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                open the Flex web app
              </a>{' '}
              to accept.
            </>
          ),
          loading: false,
        });
        return;
      }

      createFeedConnection(undefined, {
        onError: (err) => {
          const errorMessage = JSON.parse(err.message).errors.msg;
          loginFormContext.setState({
            error: errorMessage,
            loading: false,
          });
        },
      });
    } catch (error) {
      loginFormContext.setState({
        error: 'Something went wrong. Please try again.',
        loading: false,
      });
    }
  };

  useEffect(() => {
    if (loginDataLoaded) handleOtp();
  }, [loginDataLoaded]);

  return {
    redirectUser,
    handleOtp,
    redirectUrl,
    shouldRefetchOtp,
    connectionId,
    logedByflow,
    loginDataLoaded,
  };
};
