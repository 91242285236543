import {
  Box,
  Button,
  Combobox,
  Input,
  InputBase,
  Text,
  TextInput,
  useCombobox,
} from '@mantine/core';
import { useMemo, useState } from 'react';
import { useStyles } from './team-member-header.styles';
import { useMediaQuery } from '@mantine/hooks';
import InviteUserModal from '@common/invite-user-modal';
import useModal from '@common/composites/modal/modal-hook';
import { IsAdmin } from '../../../recoil-state/application/product-onboarding';
import { useRecoilValue } from 'recoil';
import { useAllowUserInvite } from '@utilities/feature-flags';
import { PiMagnifyingGlass, PiPaperPlaneTiltBold } from 'react-icons/pi';

export type Props = {
  onFilter: (e: any) => void;
  filterText: string;
  pendingInvitationsClicked?: boolean;
  updatePendingInvitationClicked?: () => void;
  updateCheckingPending?: () => void;
  getAllUsers?: () => void;
};

const TeamMembersHeader = ({
  onFilter,
  filterText,
  pendingInvitationsClicked,
  updatePendingInvitationClicked,
  updateCheckingPending,
  getAllUsers,
}: Props) => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const [searchFocused, setSearchFocused] = useState(false);
  const { classes } = useStyles({ searchFocused });
  const { openTransparentModal, closeAllModals } = useModal();
  const isAdmin = useRecoilValue(IsAdmin);
  const [options, setOptions] = useState(true);
  const allowInvite = useAllowUserInvite();
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const ActionsSelect = ({
    label,
    onClick,
  }: {
    label: string;
    onClick: () => void;
  }) => (
    <Button className={classes.actions} onClick={onClick}>
      <Text size={'sm'}>{label}</Text>
    </Button>
  );

  ActionsSelect.displayName = 'ActionsSelect';

  const comboboxOptions = useMemo(() => {
    if (
      !options ||
      !updatePendingInvitationClicked ||
      !updateCheckingPending ||
      !getAllUsers
    )
      return [];
    return [
      <ActionsSelect
        key="view"
        label={
          pendingInvitationsClicked ? 'View All Members' : 'Pending invites'
        }
        onClick={() => {
          updatePendingInvitationClicked();
          updateCheckingPending();
        }}
      />,
      <ActionsSelect
        key="invite"
        label="Invite Team Members"
        onClick={() => {
          setOptions(false);
          openTransparentModal(
            <InviteUserModal
              closeModal={() => {
                closeAllModals();
                setOptions(true);
              }}
              refreshTeamMembers={async () => {
                await getAllUsers();
              }}
            />,
          );
        }}
      />,
    ];
  }, []);

  return (
    <div className={isMobile ? classes.baseContainerSm : classes.baseContainer}>
      <div
        style={{
          display: isMobile ? '' : 'flex',
          gap: '1rem',
        }}
      >
        <TextInput
          leftSection={
            <PiMagnifyingGlass
              size={'1.25rem'}
              className={classes.searchIcon}
              style={{ marginLeft: '0.375rem' }}
            />
          }
          w={isMobile ? '100%' : '18rem'}
          placeholder={'Search team members'}
          value={filterText}
          onChange={onFilter}
          onFocus={() => setSearchFocused(true)}
          onBlur={() => setSearchFocused(false)}
        ></TextInput>
        {isAdmin &&
          updatePendingInvitationClicked &&
          updateCheckingPending &&
          getAllUsers &&
          (isMobile ? (
            <Combobox
              store={combobox}
              withinPortal={false}
              onOptionSubmit={() => {
                combobox.closeDropdown();
              }}
            >
              <Combobox.Target>
                <InputBase
                  component="button"
                  type="button"
                  pointer
                  mt="md"
                  rightSection={<Combobox.Chevron />}
                  onClick={() => combobox.toggleDropdown()}
                  rightSectionPointerEvents="none"
                >
                  {<Input.Placeholder>Actions</Input.Placeholder>}
                </InputBase>
              </Combobox.Target>

              <Combobox.Dropdown>
                <Combobox.Options>{comboboxOptions}</Combobox.Options>
              </Combobox.Dropdown>
            </Combobox>
          ) : (
            <Box>
              {allowInvite && (
                <Button
                  variant="light"
                  leftSection={<PiPaperPlaneTiltBold />}
                  onClick={() => {
                    openTransparentModal(
                      <InviteUserModal
                        closeModal={closeAllModals}
                        refreshTeamMembers={async () => {
                          await getAllUsers();
                        }}
                      />,
                    );
                  }}
                >
                  Invite Team Members
                </Button>
              )}
            </Box>
          ))}
      </div>
    </div>
  );
};

export default TeamMembersHeader;
