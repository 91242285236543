import { useState } from 'react';
import { createStyles } from '@mantine/emotion';
import { Menu, rem } from '@mantine/core';
import { PiArrowSquareOutBold, PiQuestionBold } from 'react-icons/pi';

const useStyles = createStyles((theme) => ({
  item: {
    '&[data-hovered]': {
      background: 'rgba(255, 87, 69, 0.08);',
    },
  },
  menuTarget: {
    display: 'flex',
    fontSize: rem(14),
    color: theme.colors.blackish[0],
    cursor: 'pointer',
    alignItems: 'center',
    svg: {
      marginRight: rem(4),
    },
  },
}));

const HelpActionButtons = () => {
  const { classes, theme } = useStyles();
  const [opened, setOpened] = useState(false);

  return (
    <Menu
      withArrow
      offset={4}
      shadow="sm"
      opened={opened}
      classNames={classes}
      position="bottom-end"
      arrowPosition="center"
      onChange={(open) => setOpened(open)}
    >
      <Menu.Target>
        <div className={classes.menuTarget}>
          <PiQuestionBold size={'1rem'} color={theme.colors.neutral[5]} />
          Help
        </div>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Resources</Menu.Label>
        <Menu.Item
          leftSection={<PiArrowSquareOutBold size={'0.875rem'} />}
          component="a"
          href="https://flexbase.zendesk.com/hc/en-us"
          target="_blank"
        >
          Support articles
        </Menu.Item>
        <Menu.Divider />

        <Menu.Label>Get in touch</Menu.Label>
        <Menu.Item
          component="a"
          href="https://flexbase.zendesk.com/hc/en-us/requests/new"
          target="_blank"
        >
          Share feedback
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default HelpActionButtons;
