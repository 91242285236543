import FlexIconLink from '@common/composites/flex-icon-link';
import {
  alpha,
  Box,
  Divider,
  Flex,
  MantineStyleProps,
  Progress,
  rem,
  Stack,
} from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { PropsWithChildren } from 'react';

type FlexWizardHeaderProps = {
  actions?: React.ReactNode;
};

const FlexWizardHeader = ({ actions }: FlexWizardHeaderProps) => {
  const { classes } = useFlexWizardStyles();

  return (
    <Flex className={classes.wizardHeader} data-testid="flex-wizard-header">
      <Box className={classes.wizardHeaderTitle}>
        <FlexIconLink height={30} />
      </Box>

      {!!actions && (
        <Flex className={classes.wizardHeaderActions}>{actions}</Flex>
      )}
    </Flex>
  );
};

type FlexWizardProgressProps = {
  value: number;
};

const FlexWizardProgress = ({ value }: FlexWizardProgressProps) => {
  const { classes } = useFlexWizardStyles();

  return (
    <Progress
      value={value}
      size={1}
      radius={0}
      className={classes.wizardProgress}
      data-testid="flex-wizard-progress"
    />
  );
};

const FlexWizardDivider = () => {
  return (
    <Divider
      size={1}
      variant="solid"
      sx={(t) => ({
        borderColor: alpha(t.colors.neutral[9], 0.12),
      })}
      data-testid="flex-wizard-divider"
    />
  );
};

type FlexWizardBodyProps = PropsWithChildren<
  Pick<MantineStyleProps, 'p' | 'px' | 'py' | 'pt' | 'pr' | 'pb' | 'pl'>
>;

const FlexWizardBody = ({ children, ...rest }: FlexWizardBodyProps) => {
  const { classes } = useFlexWizardStyles();

  return (
    <Box
      className={classes.wizardBody}
      data-testid="flex-wizard-body"
      {...rest}
    >
      {children}
    </Box>
  );
};

type FlexWizardFooterProps = PropsWithChildren;

const FlexWizardFooter = ({ children }: FlexWizardFooterProps) => {
  const { classes } = useFlexWizardStyles();

  return (
    <Box className={classes.wizardFooter} data-testid="flex-wizard-footer">
      {children}
    </Box>
  );
};

type FlexWizardProps = PropsWithChildren;

/**
 * UI component to create the basic layout for a full screen wizard.
 *
 * @example
 * <FlexWizard>
 *   <FlexWizard.Header />
 *
 *   <FlexWizard.Progress value={93} />
 *
 *   <FlexWizard.Body>
 *     Hello world
 *   </FlexWizard.Body>
 * </FlexWizard>
 */
export function FlexWizard({ children }: FlexWizardProps) {
  const { classes } = useFlexWizardStyles();

  return (
    <Stack gap={0} className={classes.wizard} data-testid="flex-wizard">
      {children}
    </Stack>
  );
}

/**
 * UI component styled for the FlexWizard to provide a header with optional actions.
 *
 * @example
 * <FlexWizard.Header actions={
 *   <>
 *     <Button>Save</Button>
 *     <Button>Cancel</Button>
 *   </>
 * } />
 */
FlexWizard.Header = FlexWizardHeader;

/**
 * UI component styled for the FlexWizard to provide a progress bar.
 */
FlexWizard.Progress = FlexWizardProgress;

/**
 * UI component styled for the FlexWizard to provide a horizontal divider.
 */
FlexWizard.Divider = FlexWizardDivider;

/**
 * UI component styled for the FlexWizard to wrap your body content.
 */
FlexWizard.Body = FlexWizardBody;

/**
 * UI component styled for the FlexWizard to wrap your footer content.
 */
FlexWizard.Footer = FlexWizardFooter;

const useFlexWizardStyles = createStyles((t) => ({
  wizard: {
    backgroundColor: t.colors.neutral[2],
    minHeight: '100vh',
    maxHeight: '100vh',
  },

  wizardHeader: {
    flex: 0,
    flexDirection: 'row',
    padding: t.spacing.xxxl,
  },

  wizardHeaderTitle: {
    flex: 1,
  },

  wizardHeaderActions: {
    flexDirection: 'row',
    flex: '0 0 auto',
    gap: rem(24),
  },

  wizardProgress: {
    backgroundColor: `${alpha(t.colors.neutral[9], 0.12)} !important`,
  },

  wizardBody: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0, // if this is auto, child percentage heights won't work
    overflow: 'auto',
  },

  wizardFooter: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 0,
  },
}));
