import { Loader, rem, Stack, Text, UnstyledButton } from '@mantine/core';
import { useGetAllRecipients } from '@queries/use-recipients';
import { useNavigate } from 'react-router-dom';
import { Recipient } from 'types/recipient';
import { InvoiceWizard, useInvoiceWizard } from '../../invoice-wizard';
import { useEffect, useState } from 'react';
import RecipientAccountsSelect from '../amount-source-step/recipient-account-select';
import FlexbaseSelect, { SelectItem } from '@common/composites/flexbase-select';
import { parseAccountsInfo } from '@utilities/payments-rows';
import { ParsedAccount } from 'types/parsed-account';
import { SelectItemComponent } from './select-item';

const getSelectOptions = (recipientList: Recipient[]) => {
  const mostFrequentlyPaid = recipientList
    .sort((a, b) => b.paymentsCount - a.paymentsCount)
    .slice(0, 3);

  const mostFrequentlyPaidIds = new Set(
    mostFrequentlyPaid.map((item) => item.id),
  );

  const arrayFrequentlyPaid = mostFrequentlyPaid.map((item) => {
    return {
      group: 'Most frequently paid',
      value: item.id,
      label: item.name,
    };
  });

  const otherArray = recipientList
    .filter((r) => !mostFrequentlyPaidIds.has(r.id))
    .map((item) => {
      return {
        group: 'Other',
        value: item.id,
        label: item.name,
      };
    });

  return [...arrayFrequentlyPaid, ...otherArray];
};

export const useSelectOptions = () => {
  const { data, isPending } = useGetAllRecipients();
  const { setState, state } = useInvoiceWizard();
  const [selectedRecipientId, setSelectedRecipientId] = useState(
    state?.recipient?.id ?? '',
  );

  const activeRecipients =
    data?.recipients.filter((r) => r.status === 'active') ?? [];

  const finalOptions = [
    { group: '', label: 'Add new recipient', value: 'create-recipient' },
    ...getSelectOptions(activeRecipients),
  ];

  const handleChange = (val: string) => {
    setSelectedRecipientId(val);

    const foundRecipient = activeRecipients.find((r) => r.id === val);
    setState({ recipient: foundRecipient, recipientAccount: undefined });
  };

  const recipientAccounts = state.recipient
    ? parseAccountsInfo([
        ...(state.recipient.ach ?? []),
        ...(state.recipient.wire ?? []),
      ])
    : [];

  useEffect(() => {
    if (state?.recipient?.id && state.recipientAccount?.id) {
      setState({ isNextEnabled: true });
    } else {
      setState({ isNextEnabled: false });
    }

    if (recipientAccounts.length > 0 && !state.recipientAccount) {
      setState({ recipientAccount: recipientAccounts[0] });
    }
  }, [
    state?.recipient?.id,
    state.recipientAccount?.id,
    recipientAccounts.length,
  ]);

  const handleSelectAccount = (account: ParsedAccount) => {
    setState((prev) => ({
      ...prev,
      recipientAccount: account,
    }));
  };

  return {
    selectOptions: finalOptions,
    isPending,
    selectedRecipientId,
    handleChange,
    recipientAccounts,
    handleSelectAccount,
  };
};

const SelectRecipientStep = () => {
  const navigate = useNavigate();
  const { state } = useInvoiceWizard();
  const { isInvoiceDraft, isActionDisabled = false } = state;
  const {
    selectOptions,
    isPending,
    handleChange,
    selectedRecipientId,
    recipientAccounts,
    handleSelectAccount,
  } = useSelectOptions();

  const handleNavigateToAddRecipientFlow = () => {
    navigate('/recipients/new');
  };

  const handleAddNewPaymentMethod = () => {
    navigate(`/recipients/new?recipientId=${selectedRecipientId}`);
  };

  const onChangeSelect = (val: string) => {
    if (val === 'create-recipient') {
      handleNavigateToAddRecipientFlow();
    } else {
      handleChange(val);
    }
  };

  const handleFilter = (item: SelectItem, search: string) => {
    if (item && item.label && item.value === 'create-recipient') {
      return true;
    }
    return item.label?.toLowerCase().includes(search.toLowerCase()) ?? false;
  };

  return (
    <InvoiceWizard.Step hideNext>
      <FlexbaseSelect
        inputProps={{
          required: true,
          disabled: !isInvoiceDraft || isActionDisabled,
          autoFocus: !selectedRecipientId,
          rightSection: isPending ? <Loader size="xs" /> : null,
          rightSectionWidth: rem(30),
        }}
        searchable
        disabled={!isInvoiceDraft || isActionDisabled}
        autoFocus={!selectedRecipientId}
        initiallyOpened={!selectedRecipientId}
        label="Name"
        placeholder="Search for a recipient"
        data={selectOptions}
        itemComponent={SelectItemComponent}
        onChange={(val: string | null) => onChangeSelect(val || '')}
        value={selectedRecipientId}
        data-testid={'name'}
        maxDropdownHeight={440}
        filter={(item: SelectItem, search: string) =>
          handleFilter(item, search)
        }
      />

      {selectedRecipientId && (
        <Stack gap={0} mt="md">
          <Text size="sm">Send to</Text>
          {recipientAccounts.length > 0 && (
            <RecipientAccountsSelect
              disabled={isActionDisabled}
              accounts={recipientAccounts}
              onSelectAccount={handleSelectAccount}
              currentRecipientAccount={state.recipientAccount}
              country={state.recipientAccount?.country ?? ''}
            />
          )}
          <UnstyledButton mt="sm" onClick={handleAddNewPaymentMethod}>
            <Text color="primarySecondarySuccess.6" size="sm">
              {`Add${recipientAccounts.length > 0 ? ' new' : ''} payment method`}
            </Text>
          </UnstyledButton>
        </Stack>
      )}
    </InvoiceWizard.Step>
  );
};

SelectRecipientStep.stepId = 'select-recipient-step';

export default SelectRecipientStep;
