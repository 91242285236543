import {
  Employees,
  OnboardingCompany,
  OnboardingOverviewResponse,
  OnboardingUser,
  ShipCardRequest,
  SubmitUnderwritingResponse,
  UnderwritingResponse,
} from '../../types/onboarding-info';
import { StripeDetails } from '../../types/stripe-details';
import { FlexbaseClient } from 'flexbase-client';
import { Wretcher } from 'wretch';
import { Analytics } from '../analytics/analytics';
import { AlertParam } from '../../types/alert-params';
import { UserPreferences } from '../../types/user-info';
import { CompanyCreditBalance } from '../../types/company';
import { FlexbaseResponse } from 'flexbase-client/dist/models/FlexbaseResponse';
import { ProductOnboardingStatusResponse } from '../../recoil-state/application/product-onboarding.models';
import {
  MoneyMovementListReponse,
  MoneyMovementResponse,
  PlaidAccounts,
} from './banking.model';
import { MccCode } from './client.model';
import { FicoCreditModel } from './fico.model';
import { GetPaymentByIdResponse } from './payment.model';
import {
  AgreementResponse,
  GeneratedTermsKey,
  PersonalGuarantyResponse,
} from './agreements.model';
import { CardHiddenInfo } from 'flexbase-client/dist/models/Card/Card';
import { CardDetails } from '../../areas/invite-user/content/invite-user';
import {
  EmbedUrlResponse,
  GetCompanyCardsQuery,
  GetCompanyCardsResponse,
  IssueCardRequest,
  IssueCardResponse,
  UpdateCardRequest,
  UpdateCardResponse,
  UpdateCardStatusRequest,
  UpdateCardStatusResponse,
} from './card.model';
import { TransactionData } from './spend-plans.model';
import {
  BaseRecipient,
  CreateRecipientParams,
  RecipientResponse,
  RecipientsResponse,
} from '../../types/recipient';
import { Issuer, LineOfCredit } from './credit.model';
import {
  BillpayBalanceResponse,
  BillpayInvoiceFilters,
  BillpayInvoiceResponse,
  BillpayInvoicesResponse,
  CreateBillpayInvoiceRequest,
  UpdateBillpayInvoiceRequest,
} from '../../types/bill-pay';
import { LegacyEvents } from '@services/analytics/events';

export type PlaidAccount = {
  accountId: string;
  available: string;
  last4: string;
  accountName: string;
  officialName: string;
  accountSubType: string;
  accountType: string;
  current: string;
  accessToken: string;
};
type AccountResponse = {
  success: boolean;
  cashBalance: string;
  balances: PlaidAccount[];
};
type AccountStatusResponse = {
  success: boolean;
  status: string;
};
type NewUser = {
  id: string;
  username: string;
  email: string;
  companyId: string;
  tempPassword: string;
  roles: string[];
};
type UserInviteResponse = {
  newUser: NewUser;
  status: string;
  emailStatus: string;
  success: boolean;
};
type ResendUserInviteResponse = {
  success: boolean;
  email: string;
};

export type MinimumDue = {
  success: boolean;
  totalInvoices: number;
  totalPayments: number;
  fraudChargeOff: number;
  noPayChargeOff: number;
  currentBalance: number;
  creditLimit: number;
  availableLimit: number;
  billDate: string;
  graceDate: string;
  minimumDue: number;
  delinquentAmount: number;
  delinquentDays?: number;
  aprPct: number;
  interestDue: number;
  frozen: boolean;
  active: boolean;
  minimumAllowedPayment: number;
  maximumAllowedPayment: number;
  paymentMadeThisCycle: boolean;
  comeCurrentPayment: number;
};

export type InvoicesByMccCode = {
  allInvoices?: string;
  categories?: Category[];
  fromDate?: string;
  success: boolean;
  toDate?: string;
};

export type Category = {
  category?: string;
  description?: string;
  count?: number;
  total?: string;
};

export type ComingDueResponse = {
  success: boolean;
  starting: string;
  comingDue: ComingDue[];
};

export type Invoice = {
  cardholder: string;
  city: string;
  date: string;
  last4: string;
  name: string;
  origin: string;
  postalCode?: string;
  project: any;
  state: string;
  total: string;
};

export type ComingDue = {
  billDate: string;
  invoices: string | Invoice;
};

export type EsBillResponse = {
  success: boolean;
  estBills: EstBill[];
};

export type EstBill = {
  billDate: string;
  comeCurrentPayment: string;
  maximumAllowedPayment: string;
  minimumAllowedPayment: string;
  minimumDue: string;
  pending: string;
};

export type TermsOfServiceType =
  | 'flexbase'
  | 'banking'
  | 'internationalPayments'
  | 'treasury'
  | 'credit'
  | 'patriotAct'
  | 'ficoPull';

export type TermsOfService = {
  contents: string;
  createdAt: string;
  id: string;
  type: TermsOfServiceType;
  validFrom: string;
  validUntil?: string;
};

export type TermsOfServiceDoc = {
  type: TermsOfServiceType;
  url: string;
  label: string;
  tosId: string;
  tosCreatedAt: string;
};

export type ShortCompanyDocument = {
  companyId: string;
  description: string;
  docDate: string;
  docId: string;
  droppedAt: string;
  expiresAt: string;
  id: string;
  tenantId: string;
  type: string;
  uploadedAt: string;
  userId: string;
};

export type CompanyDocument = {
  metadata: DocumentMetadata;
  status?: string;
} & ShortCompanyDocument;

export type DocumentMetadata = {
  createdAt: string;
  docType: string;
  extension: string;
  id: string;
  path: string;
  sourceName: string;
  validFrom: string;
  validUntil: string;
};

export type CompanyDocumentsResponse = {
  companyDocs: CompanyDocument[];
} & FlexbaseResponse;

export type CompanyDocumentUploadResponse = {
  company: OnboardingCompany;
  companyDoc: ShortCompanyDocument;
  docMetadata: DocumentMetadata;
} & FlexbaseResponse;
export type CompanyDocumentResponse = {
  companyDoc: CompanyDocument;
} & FlexbaseResponse;
export type UploadProps = {
  file: string | Blob;
  description?: string;
  type?: string;
  docDate?: string;
  expiresAt?: string;
  status?: string;
};

type AnotherAddressModel = {
  address: string;
  errors: Record<string, any>;
  city: string;
  state: string;
  postalCode: string;
  status: 'verified' | 'corrected' | 'failed' | string;
};

type VerifyAddressResponse = {
  address: AnotherAddressModel;
  verified: boolean;
};

export type TermsOfServiceAcceptance = {
  type: TermsOfServiceType;
  acceptedAt: string;
  ipAddr?: string;
  userId: string;
  contents: string;
  tosCreatedAt: string;
  tosId: string;
};

export type CreditAuthInfo = {
  authorize: boolean;
  reason: string;
  success: boolean;
};

export type Transactions = {
  id: string;
  date: string;
  purchaseDate?: string;
  settledAt?: string;
  type: string;
  who: string;
  transaction: string;
  amount: string;
  status: string;
  description: string;
  docId: string;
  logoUrl: string;
  qbSynced?: boolean;
  cardName?: string;
  storeCity?: string;
  storeState?: string;
  storePostalCode?: string;
  storeCategory?: string;
  storeId?: string;
  sourceLast4?: string;
  sourceAccountName?: string;
  sourceType?: string;
  last4?: string;
  authInfo?: CreditAuthInfo;
  spendName?: string;
};

// This was originally in credit-transactions but moved it here with the Transaction def
export function mapTransactionStatusToBusinessStatus({
  status,
  amount,
}: Pick<Transactions | TransactionData, 'status' | 'amount'>) {
  switch (status) {
    case 'initiated':
    case 'pending':
      return 'Pending';
    case 'declined':
      return 'Declined';
    case 'reversed':
    case 'closed':
      return 'Voided';
    case 'succeeded':
    case 'Sent':
      return 'Settled';
    case 'settled':
      return parseInt(amount.toString()) < 0 ? 'Credit' : 'Settled';
    case 'canceled':
      return 'Canceled';
    case 'failed':
      return 'Failed';
    case 'chargeback':
      return 'Chargeback';
    case 'disputed':
      return 'Disputed';
  }

  return '';
}

export type ApiSort = 'asc' | 'desc';
export type ApiTrueFalse = 'true' | 'false';

type ServicingParameters = {
  target?: string;
  after?: string;
  before?: string;
  inclDisputed?: ApiTrueFalse;
  inclExpired?: ApiTrueFalse;
  inclReversed?: ApiTrueFalse;
  chargeOffs?: string;
  noInterest?: string;
  sort?: ApiSort;
  startDate?: string;
  endDate?: string;
  justSettledPays?: ApiTrueFalse;
  justSettledInvs?: ApiTrueFalse;
  useBillScheme?: ApiTrueFalse;
};

export type RebateHistory = {
  monthOfRepayments: string;
  rebateDate: string;
  rebateEarned: string;
  redeemedVia: string;
  status: string;
  totalRepayments: string;
};

export type RebateHistoryResponse = {
  success: boolean;
  payments: RebateHistory[];
};

type BasePayment = {
  amount: number | string;
  lineOfCredit?: LineOfCredit;
  chargeCardAccountId?: string;
};

export type MakePaymentPlaid = {
  plaidTokenId: string;
} & BasePayment;

export type MakePaymentUnit = {
  depositAccountId: string;
} & BasePayment;

export class FlexbaseOnboardingClient {
  private readonly client: Wretcher;

  private servicingParams(options?: ServicingParameters) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any = {};

    let property: keyof ServicingParameters;
    for (property in options) {
      if (options && Object.hasOwn(options, property)) {
        if (typeof options[property] === 'boolean') {
          params[property] = true;
        } else {
          params[property] = options[property];
        }
      }
    }

    return params;
  }

  // This is a dirty, dirty hack until the entire onboarding client and all models are in flexbase-client
  constructor(flexbaseClient: FlexbaseClient) {
    this.client = flexbaseClient['_client'] as Wretcher;
  }

  async getOnboardingOverview(): Promise<OnboardingOverviewResponse> {
    return await this.client
      .url('/onboarding/overview')
      .get()
      .json<OnboardingOverviewResponse>();
  }

  async getProductOnboardingStatus(
    getFullData = false,
  ): Promise<ProductOnboardingStatusResponse> {
    const response = await this.client
      .url('/onboarding')
      .query({ full: getFullData })
      .get()
      .json<ProductOnboardingStatusResponse>();

    if (response.success) {
      Analytics.people.set(response.user.id, {
        ...response.user,
        ...response.company,
        // coerce output to string for ease of using in ActiveCampaign
        creditApplicant: response.company.optedProducts?.includes('CREDIT')
          ? 'TRUE'
          : 'FALSE',
        treasuryApplicant: response.company.optedProducts?.includes('TREASURY')
          ? 'TRUE'
          : 'FALSE',
        bankingApplicant: response.company.optedProducts?.includes('BANKING')
          ? 'TRUE'
          : 'FALSE',
        plaidLinked: response.user?.plaidLinked ? 'true' : 'false',
        documents: undefined,
      });
      Analytics.checkSetReservedProperty.set({ ...response.user });
    }
    return response;
  }

  async getCompanyInfo(companyId: string): Promise<OnboardingCompany> {
    return await this.client
      .url(`/tenant/${companyId}`)
      .get()
      .json<OnboardingCompany>();
  }

  async updateCompany(
    company: Partial<OnboardingCompany>,
  ): Promise<OnboardingCompany> {
    const result = await this.client
      .url('/onboarding/company')
      .put(company)
      .json<{ success: boolean; company: OnboardingCompany }>();
    if (result.success) {
      Analytics.group.set(result.company.id, {
        ...result.company,
      });
      Analytics.track(LegacyEvents.COMPANY_UPDATED, { ...result.company });
    }
    return result.company;
  }

  async createCompany(
    company: Partial<OnboardingCompany>,
    userIsOwner = false,
  ): Promise<OnboardingCompany> {
    const result = await this.client
      .url('/onboarding/company')
      .query({ userIsOwner })
      .post(company)
      .json<{ success: boolean; company: OnboardingCompany }>();
    if (result.success) {
      Analytics.group.set(result.company.id, {
        ...result.company,
      });
      Analytics.track(LegacyEvents.COMPANY_CREATED, { ...result.company });
    }
    return result.company;
  }

  async addOwner(
    user: Partial<
      Omit<OnboardingUser, 'promoCode'> & {
        ownershipPct: number;
        promoCode: string;
      }
    >,
    sendEmail = false,
  ): Promise<OnboardingUser> {
    const result = await this.client
      .url('/onboarding/owner')
      .query({ inviteEmail: sendEmail })
      .put(user)
      .json<{ success: boolean; user: OnboardingUser }>();
    Analytics.track(LegacyEvents.COMPANY_OWNER_ADDED, {
      companyOwner: result.user,
    });
    return result.user;
  }

  // Please note: The model returned by this is a company and not a user
  async addOfficer(userId: string): Promise<{ success: boolean; id: string }> {
    const result = await this.client
      .url('/tenant/officers')
      .put({ id: userId })
      .json<{ success: boolean; id: string }>();
    Analytics.track(LegacyEvents.COMPANY_OFFICER_ADDED, {
      companyOfficer: userId,
    });
    return result;
  }

  async updateUser(user: Partial<OnboardingUser>): Promise<OnboardingUser> {
    const result = await this.client
      .url('/onboarding/user')
      .put(user)
      .json<{ success: boolean; user: OnboardingUser }>();
    if (result.user.id && result.success) {
      Analytics.people.set(result.user.id, {
        ...user,
        documents: undefined,
      });
    }
    if (result.success) {
      Analytics.checkSetReservedProperty.set({ ...user });
      Analytics.track(LegacyEvents.PROFILE_UPDATED);
    }
    return result.user;
  }

  async createUser(user: Partial<OnboardingUser>): Promise<OnboardingUser> {
    const result = await this.client
      .url('/onboarding/user')
      .post(user)
      .json<{ success: boolean; newUser: OnboardingUser }>();
    return result.newUser;
  }

  async getTermsOfService(
    type_: TermsOfServiceType = 'flexbase',
    useAuth = true,
  ): Promise<TermsOfService> {
    const result = await this.client
      .url(`/onboarding/tos/${type_}`)
      .options({ authContext: { isAnonymousRoute: !useAuth } }, true)
      .get()
      .json<{ success: boolean; termsOfService: TermsOfService }>();

    return result.termsOfService;
  }

  async getTermsOfServiceDocs(
    type_?: TermsOfServiceType,
    useAuth = true,
  ): Promise<TermsOfServiceDoc[]> {
    const queryParams: Partial<Record<'type', TermsOfServiceType>> = {};

    if (type_) {
      queryParams.type = type_;
    }

    const result = await this.client
      .url('/onboarding/tos/documents')
      .query(queryParams)
      .options({ authContext: { isAnonymousRoute: !useAuth } }, true)
      .get()
      .json<{ success: boolean; documents: TermsOfServiceDoc[] }>();

    return result.documents;
  }

  async updateStripe(stripeInformation: StripeDetails): Promise<boolean> {
    const result = await this.client
      .url('/onboarding/stripe')
      .put(stripeInformation)
      .json<{ success: true }>();

    return result.success;
  }

  async uploadStripeVerificationFile(formData: FormData): Promise<any> {
    return await this.client
      .url('/stripe/person/uploadFiles')
      .body(formData)
      .post()
      .json<StripeDetails>();
  }

  // This function does not belong here, but it is not in flexbase-client underwriting
  async runUnderwriting(
    userId: string,
    level: number,
    isBnpl = false,
  ): Promise<UnderwritingResponse> {
    const result = await this.client
      .url(`/underwriting/updateLevel/${userId}`)
      .query({ level })
      .headers({ 'X-FLEXBASE-SOURCE': isBnpl ? 'BNPL' : 'WEB' })
      .put()
      .json<UnderwritingResponse>();
    Analytics.track(LegacyEvents.CREDIT_UNDERWRITING, {
      approved: result.approved,
      success: result.success,
      company: result.company,
      denial_reason: result.checks?.[result.checks?.length - 1]?.reason || null,
    });
    return result;
  }

  async checkUnderwriting(
    userId: string,
    level = 1,
    isBnpl = false,
  ): Promise<UnderwritingResponse> {
    const result = await this.client
      .url(`/underwriting/checkLevel/${userId}`)
      .query({ level })
      .headers({ 'X-FLEXBASE-SOURCE': isBnpl ? 'BNPL' : 'WEB' })
      .get()
      .json<UnderwritingResponse>();
    Analytics.track(LegacyEvents.CREDIT_UNDERWRITING, {
      approved: result.approved,
      success: result.success,
      company: result.company,
      denial_reason: result.checks?.[result.checks?.length - 1]?.reason || null,
    });
    return result;
  }

  async runUnderwritingForTarget(
    userId: string,
    target: string | number,
    isBnpl = false,
  ): Promise<UnderwritingResponse> {
    const result = await this.client
      .url(`/underwriting/updateLevel/${userId}`)
      .headers({ 'X-FLEXBASE-SOURCE': isBnpl ? 'BNPL' : 'WEB' })
      .query({ target })
      .put()
      .json<UnderwritingResponse>();
    Analytics.track(LegacyEvents.BNPL_CREDIT_UNDERWRITING, {
      approved: result.approved,
      success: result.success,
      company: result.company,
      denial_reason: result.checks?.[result.checks?.length - 1]?.reason || null,
    });
    return result;
  }

  async underwritingSubmit(): Promise<SubmitUnderwritingResponse> {
    const result = await this.client
      .url('/underwriting/submit')
      .post()
      .json<SubmitUnderwritingResponse>();
    Analytics.track(LegacyEvents.CREDIT_UNDERWRITING, {
      approved: result.approved,
      success: result.success,
      company: result.companyId,
      level: result.level,
    });
    return result;
  }

  // This function does not belong here, but it was removed from the repo and not added to flexbase-client. It will eventually be removed.
  async shipCard(
    userId: string,
    shippingDetails: ShipCardRequest,
  ): Promise<boolean> {
    const result = await this.client
      .url(`/card/${userId}/issue`)
      .post(shippingDetails)
      .json<{ success: boolean }>(); // This actually returns much more information, but we don't currently need it.
    Analytics.track(LegacyEvents.CARD_SHIPPED, { ...shippingDetails });
    return result.success;
  }

  async getUserCompany(): Promise<any> {
    return await this.client.url(`/tenant`).get().json();
  }

  async getUserCards(): Promise<any> {
    return await this.client.url('/card/mine').get().json();
  }

  // TODO: Add this to Flexbase Client. Need to get it out ASAP and client modifications will take longer.
  async verifyBnplTransaction(
    session: string,
    apiKey: string,
    amount: string | number,
  ): Promise<boolean> {
    try {
      const result = await this.client
        .url('/credit/buyNow/verify')
        .post({ apiKey, session, amount })
        .json<{ success: boolean; errors?: string[] }>();
      return result.success;
    } catch (error) {
      return false;
    }
  }

  /**
   * NOTE: This endpoint will not currently work in prod.
   */
  async getPromissoryNote(): Promise<{
    documentUuid: string;
    documentUrl: string;
    signed: boolean;
  }> {
    const result = await this.client
      .url('/underwriting/promissoryNote')
      .get()
      .json<{
        promissoryNote: {
          documentUuid: string;
          documentUrl: string;
          signed: boolean;
        };
      }>();

    return result.promissoryNote;
  }

  /**
   * NOTE: This endpoint DOES NOT WORK! It was always return an error. Also, this endpoint will not currently work in prod.
   */
  async acceptCredit(): Promise<boolean> {
    try {
      const result = await this.client
        .url('/underwriting/activateLOC')
        .put()
        .json<{ success: boolean; status: string }>();

      return result.success && result.status === 'ACTIVE';
    } catch (error) {
      return false;
    }
  }

  async getPlaidCompanyAccounts(): Promise<PlaidAccounts> {
    return this.client
      .url(`/plaid/companyAccounts`)
      .get()
      .json<PlaidAccounts>();
  }

  /**
   * If you add the query param asis=true, then you will get the best data we have in the system without calling Plaid.
   * This will likely be the previous evening, but it could be several days old if the Plaid Accounts have been
   * unlinked for a few days.
   */
  async getLinkedAccountBalances(asis: boolean): Promise<AccountResponse> {
    return this.client
      .url('/plaid/balances')
      .query({ asis })
      .get()
      .json<AccountResponse>();
  }

  async makePaymentPlaid(request: MakePaymentPlaid): Promise<{
    success: boolean;
    creditPayment: { id: string; status: string };
  }> {
    const response = await this.client
      .url('/servicing/payments/plaid')
      .post({
        ...request,
        currency: 'usd',
      })
      .json();

    return {
      success: response.success,
      creditPayment: response.cardPayment || response.repayment,
    };
  }

  async makePaymentUnit(request: MakePaymentUnit): Promise<{
    success: boolean;
    creditPayment: { id: string; status: string };
  }> {
    const response = await this.client
      .url('/servicing/payments/unit')
      .post({
        ...request,
        currency: 'usd',
      })
      .json();

    return {
      success: response.success,
      creditPayment: response.cardPayment || response.repayment,
    };
  }

  async getPaymentById(id: string): Promise<GetPaymentByIdResponse> {
    return await this.client
      .url(`/card/payments/${id}`)
      .get()
      .json<GetPaymentByIdResponse>();
  }

  /**
   * Use this endpoint to show balance data for a billing view.
   * @param companyId
   * @param targetDate
   */
  async getMinimumDue(
    companyId: string,
    targetDate?: string,
  ): Promise<MinimumDue> {
    let minimumDueRequest = this.client.url(
      `/servicing/minimumDue/${companyId}`,
    );

    if (targetDate) {
      minimumDueRequest = minimumDueRequest.query({ target: targetDate });
    }

    return await minimumDueRequest.get().json<MinimumDue>();
  }

  async getComingDue(
    companyId: string,
    params: {
      justTotals?: boolean;
      count?: number;
      starting?: string;
      justSettledInvs?: boolean;
      justSettledPays?: boolean;
    },
  ): Promise<ComingDueResponse> {
    return await this.client
      .url(`/invoice/comingDue/${companyId}`)
      .query(params)
      .get()
      .json<ComingDueResponse>();
  }

  async getEstBillings(
    companyId: string,
    params?: {
      target?: string;
      count?: number;
    },
  ): Promise<EsBillResponse> {
    return await this.client
      .url(`/servicing/estBillings/${companyId}`)
      .query(params || {})
      .get()
      .json<EsBillResponse>();
  }

  async getInvoicesByMccCategory(
    companyId: string,
    params: {
      limit?: string;
      after?: string;
      before?: string;
    },
  ): Promise<InvoicesByMccCode> {
    return await this.client
      .url(`/servicing/invoices/byCategory/${companyId}`)
      .query(params)
      .get()
      .json<InvoicesByMccCode>();
  }

  /**
   * @deprecated Use Platform
   *
   * Dev note: Did not delete but marked deprecated to raise awareness of the Platform endpoint
   *
   * @param firstName
   * @param lastName
   * @param email
   * @param roles
   * @param cardDetails
   * @param companyId
   * @param promoCode
   */
  async inviteUser(
    firstName: string,
    lastName: string,
    email: string,
    roles: string[],
    cardDetails?: CardDetails[],
    companyId?: string,
    promoCode?: string,
  ): Promise<UserInviteResponse> {
    return await this.client
      .url('/user')
      .post({
        firstName,
        lastName,
        email,
        companyId,
        roles,
        ...(cardDetails && { autoIssue: cardDetails }),
        ...(promoCode && { promoCode: promoCode }),
      })
      .json<UserInviteResponse>();
  }

  async updateUserRole(
    id: string,
    roles: string[],
  ): Promise<UserInviteResponse> {
    return await this.client
      .url(`/user/${id}`)
      .put({ roles })
      .json<UserInviteResponse>();
  }

  async deleteUser(userId: string): Promise<any> {
    return await this.client.url(`/user/${userId}`).delete().json();
  }

  async getUserPreference(): Promise<{
    preferences: UserPreferences;
    profileDocId?: string;
  }> {
    return await this.client.url(`/user/self`).get().json();
  }

  async updateUserPreference(
    userId: string,
    preference: any,
  ): Promise<{
    preferences: UserPreferences;
  }> {
    return await this.client.url(`/user/${userId}`).put(preference).json();
  }

  async getAlertParams(): Promise<AlertParam[]> {
    return await this.client.url(`/servicing/alertParams`).get().json();
  }

  /**
   * Use this endpoint to show company balance data for a credit view. DO NOT USE FOR BILLING!
   * @param companyId
   */
  async getCreditBalance(companyId: string): Promise<CompanyCreditBalance> {
    return await this.client
      .url(`/servicing/balance/${companyId}`)
      .get()
      .json<CompanyCreditBalance>();
  }

  /**
   * Endpoint to upload a document of any kind to keep in the
   * @param {Object} prop
   * @param {strong | Blob} prop.file
   * @param {string} prop.description
   * @param {string} prop.type
   * @param {string} prop.docDate
   * @param {string} prop.expiresAt
   *
   */
  async uploadDocument({
    file,
    description,
    type,
    docDate,
    expiresAt,
    status,
  }: UploadProps): Promise<CompanyDocumentUploadResponse> {
    const body = JSON.stringify({
      description,
      type,
      docDate,
      expiresAt,
      ...(status && { status: status }),
    });
    return await this.client
      .url(`/tenant/doc`)
      .formData({
        file,
        body,
      })
      .post()
      .json<CompanyDocumentUploadResponse>();
  }

  /**
   * Endpoint to get list of documents for the tenant
   */
  async getDocuments(): Promise<CompanyDocumentsResponse> {
    return await this.client
      .url(`/tenant/doc`)
      .get()
      .json<CompanyDocumentsResponse>();
  }

  /**
   * Endpoint to soft delete document as an ADMIN or SERVICE
   * @param docId
   */
  async deleteDocument(docId: string): Promise<CompanyDocumentResponse> {
    return this.client
      .url(`/tenant/doc/${docId}`)
      .delete()
      .json<CompanyDocumentResponse>();
  }

  async downloadDocument(docId: string): Promise<Blob> {
    return await this.client
      .url(`/doc/${docId}`)
      .query({ tofile: true })
      .get()
      .blob();
  }

  async getFicoBucket(): Promise<{
    bucket: string;
    success: boolean;
    userId: string;
  }> {
    return await this.client.url('/servicing/ecredit').get().json();
  }

  async getFicoScore(userId: string): Promise<FicoCreditModel> {
    return await this.client
      .url(`/servicing/users/runCredit/${userId}`)
      .get()
      .json();
  }

  async verifyAddress(
    addressToVerify: Partial<AnotherAddressModel>,
  ): Promise<VerifyAddressResponse> {
    return await this.client
      .url('/address/verify')
      .post(addressToVerify)
      .json<VerifyAddressResponse>();
  }

  async getTosHistory(): Promise<TermsOfServiceAcceptance[]> {
    const { history } = await this.client
      .url(`/onboarding/tos/history`)
      .get()
      .json();

    return history;
  }

  async getCompanyTransactionsCsv(
    companyId: string,
    options?: ServicingParameters,
  ): Promise<string> {
    const params = this.servicingParams(options);
    return await this.client
      .url(`/servicing/transactions/${companyId}/csv`)
      .query(params)
      .get()
      .text();
  }

  async getMccCodes(): Promise<MccCode[]> {
    return await this.client.url('/servicing/mccGroups').get().json();
  }

  async getPersonalGuaranty(): Promise<PersonalGuarantyResponse> {
    return await this.client.url('/onboarding/personalGuaranty').get().json();
  }

  async getTermsAgreement(key: GeneratedTermsKey): Promise<AgreementResponse> {
    return await this.client.url(`/onboarding/agreements/${key}`).get().json();
  }

  async getCardHiddenInfo(
    cardId: string,
    forceEmbedUrl = true,
  ): Promise<CardHiddenInfo | EmbedUrlResponse> {
    return await this.client
      .url(`/card/${cardId}/hiddenInfo`)
      .query({ forceURL: forceEmbedUrl })
      .get()
      .json<CardHiddenInfo | EmbedUrlResponse>();
  }

  async getRebateHistory(
    companyId: string,
    issuer?: Issuer,
  ): Promise<RebateHistoryResponse> {
    const query = issuer ? { issuer } : {};
    return await this.client
      .url(`/servicing/earlyPays/${companyId}`)
      .query(query)
      .get()
      .json();
  }

  async getCompanyCards(
    query: Partial<GetCompanyCardsQuery>,
  ): Promise<GetCompanyCardsResponse> {
    const safeQuery = {
      full: query.full ? 'true' : 'false',
      ...(query.searchTerm && { searchTerm: query.searchTerm }),
      ...(query.status && { status: query.status }),
    };
    return await this.client.url('/card/company').query(safeQuery).get().json();
  }

  async updateCreditCard(
    id: string,
    card: Partial<UpdateCardRequest>,
  ): Promise<UpdateCardResponse> {
    return await this.client.url(`/card/${id}`).put(card).json();
  }

  async updateCreditCardStatus(
    request: UpdateCardStatusRequest,
  ): Promise<UpdateCardStatusResponse> {
    return await this.client.url('/card/status').put(request).json();
  }

  async issueCard(
    userId: string,
    request: IssueCardRequest,
  ): Promise<IssueCardResponse> {
    return await this.client.url(`/card/${userId}/issue`).post(request).json();
  }

  async addAdmin(
    user: Partial<OnboardingUser & { ownershipPct: number }>,
    sendEmail = false,
  ): Promise<OnboardingUser> {
    const result = await this.client
      .url('/onboarding/admin')
      .query({ inviteEmail: sendEmail })
      .put(user)
      .json<{ success: boolean; user: OnboardingUser }>();
    return result.user;
  }

  async updateUserStatus(
    id: string,
    status: string,
  ): Promise<AccountStatusResponse> {
    return await this.client
      .url(`/user/${id}`)
      .put({ status })
      .json<AccountStatusResponse>();
  }

  async resendNewUserInvite(id: string): Promise<ResendUserInviteResponse> {
    return await this.client
      .url(`/user/${id}/resendNewUser`)
      .post()
      .json<ResendUserInviteResponse>();
  }

  async getEmployees(): Promise<Employees[]> {
    return await this.client.url('/user').get().json();
  }

  async sardineCustomer(sessionId: string, userId?: string): Promise<any> {
    return await this.client
      .url('/sardine/customer')
      .query({ sessionId, userId })
      .get()
      .json();
  }

  async getRecipient(recipientId: string) {
    return await this.client
      .url(`/recipients/${recipientId}`)
      .get()
      .json<RecipientResponse>();
  }

  async getAllRecipients() {
    return await this.client
      .url('/recipients')
      .get()
      .json<RecipientsResponse>();
  }

  async updateRecipient(recipientId: string, body: Partial<BaseRecipient>) {
    return await this.client
      .url(`/recipients/${recipientId}`)
      .patch(body)
      .json<{
        success: boolean;
        recipient: BaseRecipient;
      }>();
  }

  async deleteRecipient(recipientId: string) {
    return await this.client.url(`/recipients/${recipientId}`).delete().json<{
      success: boolean;
    }>();
  }

  async getProjectedEarlyPayDue(
    companyId: string,
    issuer?: Issuer,
  ): Promise<{
    asOf: string;
    earlyPayProjectedDueAmounts: {
      earlyPayDate: string;
      projectedDue: number;
    }[];
  }> {
    const query = issuer ? { issuer } : {};
    return await this.client
      .url(`/servicing/earlyPays/${companyId}/projectedDue`)
      .query(query)
      .get()
      .json();
  }

  async createRecipient(
    params: CreateRecipientParams,
  ): Promise<RecipientResponse> {
    return await this.client
      .url('/recipients')
      .post(params)
      .json<RecipientResponse>();
  }

  async getBillpayBalances(): Promise<BillpayBalanceResponse> {
    return await this.client
      .url('/billpay/balances')
      .get()
      .json<BillpayBalanceResponse>();
  }

  async getBillpayInvoices(
    filters: Partial<BillpayInvoiceFilters> = {},
  ): Promise<BillpayInvoicesResponse> {
    return await this.client
      .url('/billpay/invoices')
      .query(filters)
      .get()
      .json();
  }

  async getBillpayInvoice(
    invoiceId: string,
    companyId: string,
  ): Promise<BillpayInvoiceResponse> {
    return await this.client
      .url(`/billpay/invoices/${invoiceId}`)
      .query({ companyId })
      .get()
      .json();
  }

  async createBillpayInvoice(
    request: CreateBillpayInvoiceRequest,
  ): Promise<BillpayInvoiceResponse> {
    return await this.client.url(`/billpay/invoices`).post(request).json();
  }

  async updateBillpayInvoice(
    request: UpdateBillpayInvoiceRequest,
  ): Promise<BillpayInvoiceResponse> {
    return await this.client
      .url(`/billpay/invoices/${request.id}`)
      .patch(request)
      .json();
  }

  async deleteBillpayInvoice(
    invoiceId: string,
  ): Promise<BillpayInvoiceResponse> {
    return await this.client
      .url(`/billpay/invoices/${invoiceId}`)
      .delete()
      .json();
  }

  async getCreditPayments(
    origin: 'billpay' | 'banking' | 'credit',
  ): Promise<MoneyMovementListReponse> {
    return await this.client.url(`/payments`).query({ origin }).get().json();
  }

  async getCreditPayment(id: string): Promise<MoneyMovementResponse> {
    return await this.client.url(`/payments/${id}`).get().json();
  }
}
