import {
  CardHiddenInfo,
  EmbedUrlHiddenInfo,
} from 'flexbase-client/dist/models/Card/Card';
import { Group } from '../../constants/card-info';
import { Address, ChargeCardType } from './banking.model';

export type EmbedUrlResponse = {
  success: boolean;
  embedUrl: string;
  last4: string;
};

export function IsEmbedUrlResponse(
  testValue: CardHiddenInfo | EmbedUrlResponse,
): testValue is EmbedUrlResponse {
  return !!(testValue as EmbedUrlHiddenInfo).embedUrl;
}

export type CardType = 'PHYSICAL' | 'VIRTUAL';

/**
 * active = active
 *
 * requested = pending
 *
 * issued = unactivated
 *
 * suspended = frozen
 *
 * terminated = canceled
 */
export type CardStatus =
  | 'active'
  | 'requested'
  | 'issued'
  | 'suspended'
  | 'terminated';

type Card = {
  id: string;
  companyId: string;
  userId: string;
  cardType: CardType;
  cardName: string;
  holder: string;
  cardNumber: string;
  expirationDate: string;
  creditLimit?: string;
  notifyUse?: boolean;
  paymentDay: string;
  expensesTypes?: CardExpensesTypes;
  stpCardId?: string;
  version: number;
  asOf: string;
  byUser: string;
  shipTo: CardShipTo;
  status: CardStatus;
  currency: string;
  ucCardId?: string;
  cardSubtype?: ChargeCardType;
  depositAccountId?: string;
  terminateAt: string | null;
  createdAt: string;
  // Returned in the full=true response
  monthToDateSpends?: CardMonthToDateSpends;
  toDateSpend?: ToDateSpend;
  last4: string;
  userEmail?: string;
  address?: Address;
};

export type CreditCard = Card & {
  liCardToken: string;
  terminateAt: string | null;
};
export type DebitCard = Card & { ucCardId: string; depositAccountId: string };
export type FlexCard = CreditCard | DebitCard;

export function cardIsCreditCard(card: FlexCard): card is CreditCard {
  return cardIsLithicCard(card) || cardIsChargeCard(card);
}

export function cardIsLithicCard(card: FlexCard): card is CreditCard {
  return !!(card as CreditCard)?.liCardToken;
}

export function cardIsChargeCard(card: FlexCard): card is CreditCard {
  return (
    card.cardSubtype === 'businessVirtualCreditCard' ||
    card.cardSubtype === 'businessCreditCard' ||
    card.cardSubtype === 'individualCreditCard' ||
    card.cardSubtype === 'individualVirtualCreditCard'
  );
}

export type GetCompanyCardsResponse = { success: boolean; cards: FlexCard[] };
export type GetCompanyCardsQuery = {
  searchTerm: string;
  status: string;
  full: boolean;
};
export type UpdateCardRequest = Pick<
  CreditCard,
  'cardName' | 'expensesTypes' | 'notifyUse' | 'shipTo' | 'terminateAt'
>;
export type UpdateCardStatusRequest = {
  cardId: string;
  status: CardStatus;
  last4?: string;
};

export type UpdateCardStatusResponse = {
  success: boolean;
  card: FlexCard;
};

export type UpdateCardResponse = {
  success: boolean;
  card: CreditCard;
};

/**
 * If issuing a debit card, supply issuer: unit-co and chargeCardAccountId. If a credit card,
 * issuer: lithic.
 */
export type IssueCardRequest = {
  cardType: 'physical' | 'virtual';
} & Partial<{
  cardName: string;
  shipTo: CardShipTo;
  service: 'priority' | 'standard';
  limits: Partial<{ interval: string; amount: number; groups: string[] }>;
  issuer: 'lithic' | 'unit-co';
  chargeCardAccountId?: string;
  terminateAt: string | null;
  cardSubtype?: ChargeCardType;
}>;

export type IssueCardResponse = {
  success: boolean;
  issuedCard: unknown; // This appears to be the raw unit or lithic response. Leaving as unknown for now cause lazy
  card: FlexCard;
};

export type CardExpensesTypes = {
  amount: number;
  interval: string | null;
  groups: Group[];
};

export type CardMonthToDateSpends = {
  success: boolean;
  startOfMonth: string;
  mtdSpend: number;
};

export type ToDateSpend = {
  interval: string;
  startOfInterval: string;
  success: boolean;
  toDateSpend: number;
};

export type CardShipTo = {
  city: string;
  state: string;
  street: string;
  country: string;
  street2: string;
  postalCode: string;
};
