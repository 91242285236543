import {
  useBillPayFeatureFlag,
  // useBillPayV2FeatureFlag,
} from '@utilities/feature-flags';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Recipient } from 'types/recipient';
import {
  InvoiceWizard,
  LineItemInput,
  PaymentInfo,
  PaymentSource,
} from './invoice-wizard';
import { useBillpayInvoiceById } from '../use-bill-pay';
import {
  // Flex,
  LoadingOverlay,
  // Menu,
  // rem,
  // Text,
  // useMantineTheme,
} from '@mantine/core';
import ReviewPayStep from './steps/review-pay-step/review-pay-step';
import SelectRecipientStep from './steps/select-recipient/select-recipient-step';
import UploadInvoiceStep from './steps/upload-invoice/upload-invoice-step';
import UploadInvoicePreview from './steps/upload-invoice/upload-invoice-preview';
import AddLineItemsStep from './steps/add-line-items/add-line-items-step';
import InvoiceWizardFooter from './invoice-wizard-footer';
import AmountSourceStep from './steps/amount-source-step/amount-source-step';
import InvoiceWizardBody from './invoice-wizard-body';
import AdditionalInformationStep from './steps/additional-information-step';
import PaymentSubmittedStep from './steps/payment-submitted-step/payment-submitted-step';
import { useMemo } from 'react';
import { LineItemStatus } from 'types/bill-pay';
import { MoneyMovement } from '@services/flexbase/banking.model';
import { UserInfoState } from 'types/user-info';
import { useRecoilValue } from 'recoil';
import InvoiceWizardHeader from './invoice-wizard-header';
import { useGetDepositAccount } from '@queries/use-deposit-account';
import { DateTime } from 'luxon';
import { useIsLimitedAdmin } from '@utilities/custom-hooks/use-is-limited-admin';
import { formatCents, formatCurrency } from '@utilities/formatters';
import { parseAccountsInfo } from '@utilities/payments-rows';
import ConfirmTotalStep from './steps/confirm-total/confirm-total-step';
// import { PiDotsThreeVerticalBold } from 'react-icons/pi';

// const AddLineItemsTitle = () => {
//   const theme = useMantineTheme();
//   return (
//     <Flex align="center" justify="space-between" mb={rem(40)}>
//       <Text fz={rem(32)} lh={rem(48)}>
//         Add line items
//       </Text>
//       <Menu>
//         <Menu.Target>
//           <Flex align="center" style={{ cursor: 'pointer' }}>
//             <Text mr={rem(10)}>Actions</Text>
//             <PiDotsThreeVerticalBold />
//           </Flex>
//         </Menu.Target>
//         <Menu.Dropdown style={{ borderRadius: theme.radius.sm }}>
//           <Menu.Item>Undo last action</Menu.Item>
//         </Menu.Dropdown>
//       </Menu>
//     </Flex>
//   );
// };

const useInvoiceSteps = (approving?: boolean) => {
  const isLimitedAdmin = useIsLimitedAdmin();
  // const isBillPayV2Enabled = useBillPayV2FeatureFlag();
  // const thirdStep = isBillPayV2Enabled
  //   ? {
  // title: <AddLineItemsTitle />,
  //       element: <AddLineItemsStep />,
  //     }
  //   : {
  //       title: 'Confirm total',
  //       element: <ConfirmTotalStep />,
  //     };
  const thirdStep = {
    title: 'Confirm total and due date',
    element: <ConfirmTotalStep />,
  };
  const steps = useMemo(() => {
    if (approving) {
      return [
        {
          id: ReviewPayStep.stepId,
          title: 'Review and Pay',
          element: <ReviewPayStep />,
          previewElement: <UploadInvoicePreview hideIfNoImage />,
        },
        {
          id: PaymentSubmittedStep.stepId,
          title: '',
          element: <PaymentSubmittedStep />,
        },
      ];
    }
    return [
      {
        id: SelectRecipientStep.stepId,
        title: 'Who are you paying?',
        element: <SelectRecipientStep />,
        previewElement: <UploadInvoicePreview hideIfNoImage />,
      },
      {
        id: UploadInvoiceStep.stepId,
        title: 'Upload your bill to Flex',
        element: <UploadInvoiceStep />,
        previewElement: <UploadInvoicePreview />,
      },
      {
        id: AddLineItemsStep.stepId,
        title: thirdStep.title,
        element: thirdStep.element,
        previewElement: <UploadInvoicePreview hideIfNoImage />,
      },
      {
        id: AmountSourceStep.stepId,
        title: 'Amount and source',
        element: <AmountSourceStep />,
        previewElement: <UploadInvoicePreview hideIfNoImage />,
      },
      {
        id: AdditionalInformationStep.stepId,
        title: 'Additional information',
        element: <AdditionalInformationStep />,
        previewElement: <UploadInvoicePreview hideIfNoImage />,
      },
      {
        id: ReviewPayStep.stepId,
        title: isLimitedAdmin
          ? 'Review and Request Approval'
          : 'Review and Pay',
        element: <ReviewPayStep />,
        previewElement: <UploadInvoicePreview hideIfNoImage />,
      },
      {
        id: PaymentSubmittedStep.stepId,
        title: '',
        element: <PaymentSubmittedStep />,
      },
    ];
  }, [approving, isLimitedAdmin]);

  return steps;
};

const CreateInvoice = ({ approving }: { approving?: boolean }) => {
  const isFeatureFlagEnabled = useBillPayFeatureFlag();
  const { id: invoiceId } = useParams();
  const navigate = useNavigate();
  const user = useRecoilValue(UserInfoState);
  const steps = useInvoiceSteps(approving);
  const isBookkeeper = user.roles.includes('ACCOUNTANT');
  const { data: invoice, isLoading: isInvoiceLoading } = useBillpayInvoiceById(
    invoiceId || '',
  );
  const {
    data: sourceDepositAccount,
    isLoading: isSourceDepositAccountLoading,
  } = useGetDepositAccount(invoice?.payments?.[0]?.depositId || '');
  const isLoading = isInvoiceLoading || isSourceDepositAccountLoading;
  const initialLineItems = invoice?.lineItems?.length
    ? (invoice?.lineItems as LineItemInput[])
    : [
        {
          id: undefined,
          description: undefined,
          quantity: undefined,
          total: undefined,
          glCode: undefined,
          timestamp: Date.now(),
          status: 'active' as LineItemStatus,
          accountingInfo: {},
        },
      ];

  const recipientAccount = useMemo(() => {
    const recipientAccounts = parseAccountsInfo([
      ...(invoice?.recipient?.ach || []),
      ...(invoice?.recipient?.wire || []),
    ]);
    return recipientAccounts.find(
      (account) => account.id === invoice?.payments?.[0]?.counterpartyId,
    );
  }, [invoice?.id]);

  const initialPaymentInfo: PaymentInfo | undefined = useMemo(() => {
    if (invoice?.payments?.[0] && sourceDepositAccount?.account) {
      return {
        sendFrom: {
          ...sourceDepositAccount.account,
          plaidOrDeposit: 'deposit',
        },
        sendOn: DateTime.now().toJSDate(),
        paymentSource: PaymentSource.ACCOUNT,
        creditProgramId: undefined,
      };
    }
    return undefined;
  }, [invoice?.id, sourceDepositAccount?.account?.id]);

  const initialState = {
    existingInvoiceId: invoiceId,
    isInvoiceDraft:
      invoice?.status === 'drafted' || invoice?.status === undefined,
    recipient: invoice?.recipient || ({} as Recipient),
    recipientAccount,
    existingDocument: invoice?.document as File,
    existingDocumentId: invoice?.documentId || undefined,
    invoiceDetails: {
      lineItems: initialLineItems,
      credits: invoice?.credits ? formatCents(invoice?.credits) : undefined,
      tax: invoice?.tax ? formatCents(invoice?.tax) : undefined,
      dueDate: invoice?.dueDate,
      createdAt: invoice?.createdAt,
    },
    invoicePayments: invoice?.payments || [],
    isNextEnabled: false,
    invoiceNumber: invoice?.invoiceNumber || undefined,
    description: invoice?.description || '',
    memo: invoice?.payments?.[0]?.payDescription || '',
    notes: invoice?.payments?.[0]?.notes || '',
    paymentSubmissionDetails: {} as MoneyMovement,
    invoiceTotal: {
      cents: invoice?.total ? invoice.total : 0,
      formatted: invoice?.total
        ? formatCurrency(formatCents(invoice.total))
        : '',
    },
    isActionDisabled:
      invoice?.status === 'pending' ||
      invoice?.status === 'scheduled' ||
      invoice?.status === 'failed' ||
      invoice?.status === 'succeeded' ||
      isBookkeeper,
    isBookkeeper,
    paymentInfo: initialPaymentInfo,
  };

  if (!isFeatureFlagEnabled) {
    return <Navigate to="/" />;
  }

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  return (
    <InvoiceWizard
      steps={steps}
      initialState={initialState}
      onCancel={() => navigate('/bill-pay')}
      footer={<InvoiceWizardFooter approving={approving} />}
      body={<InvoiceWizardBody />}
      header={<InvoiceWizardHeader approving={approving} />}
    />
  );
};

export default CreateInvoice;
