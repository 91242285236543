import { ReactNode } from 'react';
import { Badge, BadgeProps } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

type Props = {
  children: ReactNode;
  variant?: 'default' | 'warning' | 'success' | 'error' | 'secondary' | string;
} & Omit<BadgeProps, 'variant'>;

export const FilledBadge = ({
  children,
  variant = 'default',
  classNames,
  ...badgeProps
}: Props) => {
  const { classes, cx } = useStyles();
  return (
    <Badge
      classNames={{
        ...classNames,
        root: cx(
          variant === 'success' && classes.successRoot,
          variant === 'warning' && classes.warningRoot,
          variant === 'secondary' && classes.secondaryRoot,
          variant === 'error' && classes.errorRoot,
        ),
        label: cx(
          variant === 'success' && classes.successInner,
          variant === 'warning' && classes.warningInner,
          variant === 'secondary' && classes.secondaryInner,
          variant === 'error' && classes.errorInner,
        ),
      }}
      {...badgeProps}
    >
      {children}
    </Badge>
  );
};

const useStyles = createStyles((theme) => ({
  root: {
    borderRadius: 4,
    backgroundColor: theme.colors.neutral[1],
  },
  inner: {
    color: theme.colors.neutral[7],
    fontWeight: 400,
    fontSize: 12,
    textTransform: 'none',
  },
  successRoot: {
    backgroundColor: theme.colors.primarySecondarySuccess[0],
  },
  successInner: {
    color: theme.colors.primarySecondarySuccess[6],
  },
  warningRoot: {
    backgroundColor: theme.colors.tertiary[0],
  },
  warningInner: {
    color: theme.colors.tertiary[6],
  },
  errorRoot: { backgroundColor: theme.colors.critical[0] },
  errorInner: { color: theme.colors.critical[6] },
  secondaryRoot: {
    backgroundColor: theme.colors.promote[1],
  },
  secondaryInner: {
    color: theme.colors.promote[7],
  },
}));
