import parse from 'html-react-parser';
import { isValidElement, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import type { TermsOfServiceType } from 'services/flexbase/flexbase-onboarding-client';
import { ApplicationState } from '../../recoil-state/application/product-onboarding';

export const useTermsOfService = (type?: TermsOfServiceType) => {
  const [termsContent, setTermsContent] = useState<JSX.Element[]>([]);

  const [loading, setLoading] = useState(true);

  const getTerms = async () => {
    try {
      const terms = await flexbaseOnboardingClient.getTermsOfService(
        type,
        false,
      );
      const content = parse(terms.contents);
      if (Array.isArray(content)) {
        setTermsContent(
          content.filter((element) => element.toString() !== '\n'),
        );
      } else if (isValidElement(content)) {
        setTermsContent([content]);
      }
      setLoading(false);
    } catch (e) {
      console.error('An error occurred when retrieving terms of service', e);
    }
  };

  useEffect(() => {
    getTerms();
  }, []);

  return { loading, termsContent };
};

export type UseHasTermsReturnType = {
  hasBankingTerms: boolean;
  hasInternationalPaymentsTerms: boolean;
  hasTreasuryTerms: boolean;
  hasCreditTerms: boolean;
  hasFlexbaseTerms: boolean;
  hasPersonalGuaranty: boolean;
  hasFicoPull: boolean;
  hasPatriotAct: boolean;
  hasActiveBanking: boolean;
  hasActiveTreasury: boolean;
  hasActiveCredit: boolean;
  // Does the user have any required terms for an active product?
  hasTermsForActiveProduct: boolean;
  hasActiveIntnlPayments: boolean;
  hasNyTermsAgreement: boolean;
  hasFlTermsAgreement: boolean;
};

/**
 * A hook to tell us what terms a user needs to agree to, their active products, and if they have required terms for any active product
 * @returns {UseHasTermsReturnType}
 */
export const useHasTerms = (): UseHasTermsReturnType => {
  const { requiredProperties, productStatus } =
    useRecoilValue(ApplicationState);

  return useMemo(() => {
    const hasFlexbaseTerms = requiredProperties.includes(
      'user.termsOfServiceSigned',
    );
    const hasBankingTerms = requiredProperties.includes(
      'user.bankingTermsOfServiceSigned',
    );
    const hasInternationalPaymentsTerms = requiredProperties.includes(
      'user.internationalPaymentsTermsOfServiceSigned',
    );
    const hasTreasuryTerms = requiredProperties.includes(
      'user.treasuryTermsOfServiceSigned',
    );
    const hasCreditTerms = requiredProperties.includes(
      'user.creditTermsOfServiceSigned',
    );
    const hasPersonalGuaranty = requiredProperties.includes(
      'user.personalGuarantySigned',
    );
    const hasFicoPull = requiredProperties.includes('user.ficoPullSigned');
    const hasPatriotAct = requiredProperties.includes('user.patriotActSigned');
    const hasFlTermsAgreement =
      requiredProperties.includes('user.flTermsSigned');
    const hasNyTermsAgreement =
      requiredProperties.includes('user.nyTermsSigned');

    // Consider splitting these out into a separate state since they may be useful elsewhere
    const hasActiveBanking = productStatus.banking.appStatus === 'approved';
    const hasActiveTreasury = !!productStatus.treasury.admNumber;
    const hasActiveCredit =
      !!productStatus.credit.creditLimit &&
      parseFloat(productStatus.credit.creditLimit) > 0;
    const hasActiveIntnlPayments =
      productStatus.internationalPayments.status === 'approved';

    const hasTermsForActiveProduct =
      hasFlexbaseTerms ||
      (hasActiveBanking && hasBankingTerms) ||
      (hasActiveIntnlPayments && hasInternationalPaymentsTerms) ||
      (hasActiveCredit &&
        (hasCreditTerms ||
          hasPersonalGuaranty ||
          hasFicoPull ||
          hasPatriotAct ||
          hasFlTermsAgreement ||
          hasNyTermsAgreement)) ||
      (hasActiveTreasury && hasTreasuryTerms);

    return {
      hasBankingTerms,
      hasInternationalPaymentsTerms,
      hasFlexbaseTerms,
      hasTreasuryTerms,
      hasCreditTerms,
      hasPersonalGuaranty,
      hasPatriotAct,
      hasFicoPull,
      hasActiveBanking,
      hasActiveCredit,
      hasActiveTreasury,
      hasTermsForActiveProduct,
      hasActiveIntnlPayments,
      hasFlTermsAgreement,
      hasNyTermsAgreement,
    };
  }, [requiredProperties]);
};
