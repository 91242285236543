import { useForm } from '@mantine/form';
import { useIssueDebitCardWizard } from '../create-wizard';
import {
  Alert,
  Button,
  Center,
  Group,
  Loader,
  Radio,
  rem,
  Select,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { DebitCardWizardStepLayout } from '../wizard-step-layout';
import TwoFactorAuthSection from '../../two-factor/two-factor-auth-section';
import AccountSelection from '@common/account-selection';
import { DepositAccount } from 'types/move-funds.model';
import { PiXCircle } from 'react-icons/pi';
import { useEffect, useState } from 'react';
import { useGetDepositAccounts } from '@queries/use-deposit-accounts';
import GenericError from '@common/generic-error';
import {
  CardType as CardSubType,
  IssueDebitCard as IssueDebitCardType,
} from '@services/flexbase/banking.model';
import { useIssueDebitCard } from '@queries/use-debit-cards';
import { useGetUsers } from '@queries/use-users';
import { RequiredFieldValidator } from '@utilities/validators';
import FlexNumberInput from '@common/flex-number-input';
import { checkSpendingLimits } from '../../util';
import { useGetApplicationStatus } from '@queries/use-application';
import FlexbaseSelect from '@common/composites/flexbase-select';

type DebitCardFormStepFormValues = {
  userId: string;
  cardName: string;
  cardSubType: CardSubType | null;
  limitType: 'daily' | 'monthly' | 'unlimited';
  purchaseLimit?: number | null;
};

const getErrorMessageIssuingCard = (error: string) => {
  let errorMessage = '';
  switch (true) {
    case error.includes('limit reached for Company'):
      errorMessage =
        'Your company has reached the maximum limit for virtual/physical cards.';
      break;

    case error.includes('Terms of Service'):
      errorMessage =
        'To issue the card, the user must have accepted the Terms of Service.';
      break;
    case error.includes('a Customer Token is required'):
      errorMessage = 'Please enter the 6 digit SMS sent to verify your request';
      break;
    case error.includes('Bearer token is invalid or expired'):
      errorMessage = 'Bearer token is invalid or expired.';
      break;

    default:
      errorMessage =
        'Unable to issue card. Please contact support or try again later.';
      break;
  }
  return errorMessage;
};

export const IssueDebitCardStep = () => {
  const theme = useMantineTheme();
  const { goToNextStep, state, setState } = useIssueDebitCardWizard();
  const {
    data: depositAccountsData,
    isLoading: isLoadingDepositAccounts,
    isError: isErrorAccounts,
    refetch: refetchAccounts,
  } = useGetDepositAccounts();
  const {
    data: employeesData,
    isLoading: isLoadingEmployees,
    isError: isErrorEmployees,
    refetch: refetchEmployees,
  } = useGetUsers();
  const {
    mutate: issueCard,
    isPending: issueCardPending,
    error: issueCardError,
  } = useIssueDebitCard();

  const {
    isError: isErrorApplicationStatus,
    isLoading: isLoadingApplicationStatus,
    data: applicationStatusData,
    refetch: refetchApplicationStatus,
  } = useGetApplicationStatus();
  const appType = applicationStatusData?.success
    ? applicationStatusData?.type
    : null;

  const errorMessage = issueCardError
    ? JSON.parse(issueCardError.message).error
    : null;
  const [needsAuth, setNeedsAuth] = useState(false);

  const physicalCardValue =
    appType === 'businessApplication'
      ? 'businessDebitCard'
      : 'individualDebitCard';

  const virtualCardValue =
    appType === 'businessApplication'
      ? 'businessVirtualDebitCard'
      : 'individualVirtualDebitCard';

  const form = useForm<DebitCardFormStepFormValues>({
    initialValues: {
      userId: state.debitCardFormValues.userId ?? '',
      cardName: state.debitCardFormValues.cardName ?? '',
      purchaseLimit: state.debitCardFormValues.purchaseLimit ?? null,
      limitType: state.debitCardFormValues.limitType ?? 'unlimited',
      cardSubType: state.debitCardFormValues.cardSubType ?? null,
    },
    validate: {
      userId: RequiredFieldValidator('Choose who this card is for'),
      purchaseLimit: (value, values) => {
        if (values.limitType !== 'unlimited' && (!value || value <= 0)) {
          return 'Spending limit is required when not unlimited.';
        }

        if (form.values.limitType !== 'unlimited') {
          const tier = selectedAccount.tier;
          const spendingLimitError = checkSpendingLimits(
            value ?? 0,
            values.limitType as 'monthly' | 'daily',
            tier,
          );

          if (spendingLimitError) {
            return spendingLimitError;
          }
        }
        return null;
      },
      cardSubType: (value) => (!value ? 'Card subtype is required.' : null),
    },
  });

  const activeAdminEmployeeSelectOptions =
    employeesData
      ?.filter(
        (user) =>
          user.roles?.includes('ADMIN') &&
          user.completedOnboarding &&
          user.status === 'active',
      )
      .map((employee) => ({
        value: employee.id!,
        label: `${employee.firstName} ${employee.lastName}`,
      })) || [];

  const employeesSortedByLastName = [...activeAdminEmployeeSelectOptions].sort(
    (a, b) => {
      const aName = a.label.split(' ');
      const bName = b.label.split(' ');
      return aName[aName.length - 1].localeCompare(bName[bName.length - 1]);
    },
  );

  const bankingAccounts =
    (depositAccountsData?.accounts
      .filter((a) => a.status === 'Open')
      .sort((a, b) => b.balance - a.balance)
      .map((a) => ({ ...a, plaidOrDeposit: 'deposit' })) as DepositAccount[]) ||
    [];

  const [selectedAccount, setSelectedAccount] = useState<DepositAccount>(
    bankingAccounts[0] ?? {},
  );

  const handleSelectEmployee = (userId: string) => {
    const selectedEmployee = employeesSortedByLastName.find(
      (employee) => employee.value === userId,
    );

    form.setFieldValue('userId', userId);
    setState((prev) => ({
      ...prev,
      debitCardFormValues: {
        ...prev.debitCardFormValues,
        cardUser: selectedEmployee?.label ?? '',
      },
    }));
  };

  const handleSelectAccount = (acct: DepositAccount) => {
    setSelectedAccount(acct);
    form.setFieldValue('accountId', acct.id);
  };

  const handlePurchaseLimitChange = (value: number) => {
    form.setFieldValue('purchaseLimit', value);
  };

  const handleRefetch = () => {
    refetchApplicationStatus();
    refetchAccounts();
    refetchEmployees();
  };

  const getCardLimits = (
    limitType: 'daily' | 'monthly' | 'unlimited',
    purchaseLimit: number | null,
  ) => {
    switch (limitType) {
      case 'daily':
        return { dailyPurchase: purchaseLimit ?? undefined };
      case 'monthly':
        return { monthlyPurchase: purchaseLimit ?? undefined };
      default:
        return { monthlyPurchase: purchaseLimit ?? undefined };
    }
  };

  const issueDebitCard = () => {
    if (
      form.values.limitType !== 'unlimited' &&
      [null, 0].includes(form.values.purchaseLimit ?? 0)
    ) {
      return;
    }

    const validationResult = form.validate();
    if (!validationResult.hasErrors) {
      const userId = form.values.userId;

      const cardForm: IssueDebitCardType = {
        toUser: { id: userId },
        cardSubtype: form.values.cardSubType!,
        depositAccountId: selectedAccount.id,
        cardName: form.values.cardName !== '' ? form.values.cardName : null,
        issuer: 'unit-co',
        type: form.values.cardSubType!.includes('Virtual')
          ? 'virtual'
          : 'physical',
        ...(form.values.limitType !== 'unlimited' && {
          limits: getCardLimits(
            form.values.limitType,
            form.values.purchaseLimit ?? null,
          ),
        }),
      };

      issueCard(cardForm, {
        onSuccess: () => {
          setState((prev) => ({
            ...prev,
            debitCardFormValues: {
              ...prev.debitCardFormValues,
              ...form.values,
            },
          }));
          goToNextStep();
        },
        onError: (err) => {
          const tokenErrorMessages = [
            'a Customer Token is required',
            'Bearer token is invalid or expired',
          ];

          const { error: errorMsg = '' } = JSON.parse(err.message);
          if (
            tokenErrorMessages.some((message) => errorMsg.includes(message))
          ) {
            setNeedsAuth(true);
          } else {
            setState((prev) => ({
              ...prev,
              debitCardFormValues: {
                ...prev.debitCardFormValues,
                ...form.values,
              },
              error: true,
            }));
            goToNextStep();
          }
        },
      });
    }
  };

  const handleNext = () => {
    issueDebitCard();
  };

  useEffect(() => {
    if (
      bankingAccounts.length > 0 &&
      Object.keys(selectedAccount).length === 0
    ) {
      setSelectedAccount(bankingAccounts[0]);
    }
  }, [bankingAccounts]);

  const isLoading =
    isLoadingApplicationStatus ||
    isLoadingDepositAccounts ||
    isLoadingEmployees;

  const isError =
    isErrorApplicationStatus || isErrorAccounts || isErrorEmployees;

  if (isLoading) {
    return (
      <Center style={{ height: '100vh' }}>
        <Loader
          color={theme.colors.primarySecondarySuccess[8]}
          size={rem(50)}
        />
      </Center>
    );
  }

  if (isError) {
    return (
      <GenericError>
        <Text sx={{ textAlign: 'center' }}>An error occurred.</Text>
        <Button variant="light" onClick={handleRefetch} mt={rem(20)}>
          Please try again
        </Button>
      </GenericError>
    );
  }

  return (
    <DebitCardWizardStepLayout
      handleNext={handleNext}
      nextLoading={issueCardPending}
      hideBack
      labels={{
        next: 'Issue card',
      }}
    >
      <Stack>
        <FlexbaseSelect
          id="team-member"
          data={employeesSortedByLastName}
          placeholder="Select or search"
          label="Team member"
          inputProps={{ id: 'team-member' }}
          {...form.getInputProps('userId')}
          onChange={(value) => handleSelectEmployee(value ?? '')}
          searchable
          data-testid="team-member"
        />

        <AccountSelection
          label="Flex account"
          accounts={bankingAccounts}
          currentAccount={selectedAccount}
          data-testid="deposit-account"
          onAccountChange={(c) => handleSelectAccount(c as DepositAccount)}
        />

        <TextInput
          label="Card nickname"
          {...form.getInputProps('cardName')}
          placeholder="e.g. nickname lunch card"
          data-testid="nickname"
        />

        <Radio.Group
          label="Card type"
          {...form.getInputProps('cardSubType')}
          value={form.values.cardSubType}
          styles={{
            label: {
              fontWeight: 500,
              fontSize: rem(14),
            },
          }}
          color={theme.colors.primarySecondarySuccess[2]}
          style={{ gap: rem(25) }}
        >
          <Group>
            <Radio
              value={physicalCardValue}
              label="Physical"
              data-testid="physical-card"
            />
            <Radio
              value={virtualCardValue}
              label="Virtual"
              data-testid="virtual-card"
            />
          </Group>
        </Radio.Group>

        <Select
          label="Spending limit type"
          {...form.getInputProps('limitType')}
          data={[
            { value: 'daily', label: 'Daily' },
            { value: 'monthly', label: 'Monthly' },
            { value: 'unlimited', label: 'Unlimited' },
          ]}
        />

        {form.values.limitType !== 'unlimited' && (
          <FlexNumberInput
            prefix="$"
            label={`${
              form.values.limitType === 'daily' ? 'Daily' : 'Monthly'
            } spending limit`}
            thousandSeparator
            decimalScale={2}
            allowNegative={false}
            placeholder="$0.00"
            variant="default"
            value={form.values.purchaseLimit}
            error={form.errors.purchaseLimit}
            onValueChange={(value) => {
              handlePurchaseLimitChange(value?.floatValue ?? 0);
            }}
            styles={{
              icon: {
                paddingLeft: 13,
              },
              input: {
                '&[data-with-icon]': {
                  paddingLeft: 20,
                },
              },
            }}
          />
        )}

        {needsAuth && <TwoFactorAuthSection onSuccess={issueDebitCard} />}
        {/* do not display error message if we ask for 2FA */}
        {errorMessage &&
          !errorMessage.includes('expired') &&
          !errorMessage.includes('Customer Token is required') && (
            <Alert
              my={rem(15)}
              color="red"
              icon={
                <PiXCircle size={'1.438rem'} color={theme.colors.critical[2]} />
              }
            >
              {getErrorMessageIssuingCard(errorMessage)}
            </Alert>
          )}
      </Stack>
    </DebitCardWizardStepLayout>
  );
};

IssueDebitCardStep.stepId = 'issue-new-card';
