import AuthPageContainer from 'areas/login/login/auth-page';
import { LoginFormProvider } from 'providers/login-form.context';
import { LoginFormContainer } from './login-form-container';
import { PasswordLoginForm } from './password-login-form';
import AuthVerifyCode from './verify-code';
import { ChooseFactor } from './choose-factor';
import { LoginContextProvider } from 'providers/login-provider';
import {
  KEY_USER_EMAIL_STORAGE,
  KEY_REMEMBER_USER,
} from 'providers/auth.provider';

export const LoginPage = () => {
  return (
    <LoginFormProvider
      initialState={{
        error: null,
        loading: false,
        otpStatus: 'sent',
        email: localStorage?.getItem(KEY_USER_EMAIL_STORAGE) ?? '',
        password: '',
        factorList: [],
        selectedFactor: null,
        rememberUser: localStorage?.getItem(KEY_REMEMBER_USER) === 'true',
        pkce: null,
      }}
      steps={[
        {
          element: <PasswordLoginForm />,
          metadata: {
            title: 'Log in to your Flex account',
            id: 'password-login',
            nextButtonText: 'Log in',
            testId: 'button-auth-submit',
          },
        },
        {
          element: <ChooseFactor />,
          metadata: {
            title: 'Choose 2FA factor',
            id: 'choose-factor',
            nextButtonText: 'Continue',
            testId: 'button-choose-factor-submit',
          },
          condition: (state) => state.factorList.length > 0,
        },
        {
          element: <AuthVerifyCode />,
          metadata: {
            title: 'Enter your verification code',
            id: '2FA',
            nextButtonText: 'Continue',
            testId: 'button-auth-submit',
          },
        },
      ]}
    >
      <LoginContextProvider>
        <AuthPageContainer>
          <LoginFormContainer />
        </AuthPageContainer>
      </LoginContextProvider>
    </LoginFormProvider>
  );
};
