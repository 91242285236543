import { useWizardMobile } from '@common/emerald/wizard/utils/use-wizard-mobile';
import { useInvoiceWizard } from './invoice-wizard';
import { useWizardStyles } from '@common/emerald/wizard/wizard.styles';
import { Box, Group, rem } from '@mantine/core';
import { memo, useEffect } from 'react';
import InvoiceWizardStepError from './steps/invoice-wizard-step-error';
import { useSearchParams } from 'react-router-dom';

function InvoiceWizardBody() {
  const isMobile = useWizardMobile();
  const { currentStep, state, setState, isFinalStep, goToStep } =
    useInvoiceWizard();
  const { classes } = useWizardStyles();
  const { error } = state;
  const hasPreviewStep = !!currentStep?.previewElement;
  const [searchParams] = useSearchParams();
  const stepQueryParam = searchParams.get('step');

  useEffect(() => {
    if (stepQueryParam) {
      goToStep(stepQueryParam);
    }
  }, [stepQueryParam]);

  const onClear = () => {
    setState({ error: undefined });
  };

  return (
    <Group
      className={isFinalStep ? classes.finalStepBody : classes.body}
      gap={rem(100)}
    >
      <Box className={classes.contentPanel} maw={hasPreviewStep ? 400 : '100%'}>
        {error ? (
          <InvoiceWizardStepError submissionError={error} onClear={onClear} />
        ) : (
          currentStep?.element
        )}
      </Box>
      {isMobile || !hasPreviewStep ? null : currentStep.previewElement}
    </Group>
  );
}

export default memo(InvoiceWizardBody);
