import { createStyles } from '@mantine/emotion';
import { Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EmailValidator } from 'utilities/validators/validate-email';
import AuthContainer from 'areas/login/login/auth-page';
import AuthContent from 'areas/login/login/auth-content';
import AuthLoader from '@common/auth-loader';
import { platformAuthClient } from '@services/platform/platform-auth-client';
import { IsSuccessResponse } from '@services/platform/models/authorize.models';
import { MAGIC_LINK_CODE_VERIFIER_KEY } from './use-magic-link';

/**
 * If anyone is confused like me, this page is just for initiating a password reset (which uses a magic link) and has
 * nothing else to do with magic links.
 */
const MagicLinkPage = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');

  const forgotPasswordForm = useForm({
    initialValues: {
      email: '',
    },
    validate: {
      email: EmailValidator(
        <div data-testid="error-message">Invalid email address</div>,
      ),
    },
  });

  const errorSupportMessage =
    'We are unable to locate your account, please check your email for typos and try again or contact support at support@flex.one';

  const sendMagicLink = async () => {
    setErrorMsg('');
    setSuccessMsg('');
    const validate = forgotPasswordForm.validate();
    if (!validate.hasErrors) {
      try {
        setLoading(true);
        const pkce = await platformAuthClient.generatePKCE();
        const response = await platformAuthClient.initiatePasswordReset(
          forgotPasswordForm.values.email,
          pkce.codeChallenge,
        );
        setLoading(false);
        if (!IsSuccessResponse(response)) {
          setErrorMsg(errorSupportMessage);
        } else {
          localStorage?.setItem(
            MAGIC_LINK_CODE_VERIFIER_KEY,
            pkce.codeVerifier,
          );
          setSuccessMsg(
            'A magic link has been sent to your email! Please use this link to reset your password.',
          );
        }
      } catch (error) {
        setErrorMsg(errorSupportMessage);
      }
    }
  };

  const onSubmit = () => {
    if (successMsg) {
      navigate('/login');
    } else {
      sendMagicLink();
    }
  };

  return (
    <AuthContainer>
      {loading && <AuthLoader />}

      <form onSubmit={forgotPasswordForm.onSubmit(() => onSubmit())}>
        <AuthContent
          screen="magic-link"
          errorMsg={errorMsg}
          onSubmit={onSubmit}
          onGoback={() => navigate('/login')}
          title={successMsg ? 'Password reset link sent!' : 'Password reset'}
          submitButtonLabel={successMsg ? 'Back to log in' : 'Send magic link'}
        >
          {successMsg ? (
            <Text size="sm" color="neutral.0" data-testid={'success-message'}>
              {successMsg}
            </Text>
          ) : (
            <TextInput
              label="Email"
              classNames={classes}
              placeholder="youremail@gmail.com"
              {...forgotPasswordForm.getInputProps('email')}
              data-testid={'email'}
            />
          )}
        </AuthContent>
      </form>
    </AuthContainer>
  );
};

export default MagicLinkPage;

const useStyles = createStyles((theme) => ({
  label: {
    color: theme.colors.neutral[0],
  },
  error: {
    color: theme.colors.critical[2],
  },
}));
