import {
  AmountFilterValue,
  filterDirectionToLabel,
} from '@common/utilities/filter/amount-filter';
import { formatCurrency } from '@utilities/formatters';
import { QuantityFilter } from '@common/utilities/filter/quantity-filter';
import { useBankingTransactionsModalFilters } from '../use-banking-transactions-filters';

export const BankingTransactionsAmountFilter = () => {
  const { addFilter, getFilterByKey } = useBankingTransactionsModalFilters();

  const handleChange = (val: AmountFilterValue) => {
    addFilter('amount', {
      label: `Amount - ${filterDirectionToLabel(
        val.direction,
      )} ${formatCurrency(val.amount)}`,
      key: 'amount',
      fn: (row) => {
        const amountNum = Number(row.amount) / 100;
        const filterNum = +val.amount;
        switch (val.direction) {
          case 'greaterThan':
            return amountNum > filterNum;
          case 'lessThan':
            return amountNum < filterNum;
          case 'equal':
          default:
            return amountNum === filterNum;
        }
      },
      filterValue: val,
      showChip: true,
    });
  };

  return (
    <QuantityFilter
      value={
        getFilterByKey('amount')?.filterValue || {
          amount: '',
          direction: 'equal',
        }
      }
      onChange={handleChange}
    />
  );
};
