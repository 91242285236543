import { WizardStepConfig } from '@common/emerald/wizard/wizard.types';
import { usePrefetchUsers } from '@queries/use-users';
import { useSpendPlansFeatureFlag } from '@utilities/feature-flags';
import { Navigate, useNavigate } from 'react-router-dom';
import { SpendPlanDetailsStep } from './steps/details/details-step';
import { SpendPlanSpendControlsStep } from './steps/spend-controls/spend-controls-step';
import { SpendPlanDetailsStepPreview } from './steps/details/details-step.preview';
import { SpendPlanSpendControlsStepPreview } from './steps/spend-controls/spend-controls-step.preview';
import { DetailsStepFormInitialValues } from './steps/details/details-step.context';
import { SpendControlsFormInitialValues } from './steps/spend-controls/spend-controls-step.context';
import { CreateSpendPlanProvider } from './create-spend-plan.provider';
import {
  CreateSpendPlanWizard,
  SpendPlanFormState,
} from './create-spend-plan.wizard';
import { SpendPlansTeamMembersStep } from './steps/team-members/team-members-step';
import { SpendPlanTeamMembersStepPreview } from './steps/team-members/team-members-step.preview';
import { TeamMembersFormInitialValues } from './steps/team-members/team-members-step.context';
import { RestrictionsStepFormInitialValues } from './steps/restrictions/restrictions-step.context';
import { SpendPlanRestrictionsStep } from './steps/restrictions/restrictions-step';
import { SpendPlanRestrictionsStepPreview } from './steps/restrictions/restrictions-step.preview';
import { SpendPlanReviewStep } from './steps/review/review-step';

const steps: WizardStepConfig<SpendPlanFormState>[] = [
  {
    id: SpendPlanDetailsStep.stepId,
    title: 'Spend plan details',
    element: <SpendPlanDetailsStep />,
    previewElement: <SpendPlanDetailsStepPreview />,
  },

  {
    id: SpendPlanSpendControlsStep.stepId,
    title: 'Spend plan limit',
    element: <SpendPlanSpendControlsStep />,
    previewElement: <SpendPlanSpendControlsStepPreview />,
  },

  {
    id: SpendPlansTeamMembersStep.stepId,
    title: 'Add team members to this spend plan',
    element: <SpendPlansTeamMembersStep />,
    previewElement: <SpendPlanTeamMembersStepPreview />,
  },

  {
    id: SpendPlanRestrictionsStep.stepId,
    title: 'Define restrictions',
    element: <SpendPlanRestrictionsStep />,
    previewElement: <SpendPlanRestrictionsStepPreview />,
  },

  {
    id: SpendPlanReviewStep.stepId,
    title: 'Review spend plan details',
    element: <SpendPlanReviewStep />,
    options: {
      disableScrollToPreview: true,
    },
  },
];

const initialState: SpendPlanFormState = {
  ...DetailsStepFormInitialValues,
  ...SpendControlsFormInitialValues,
  ...TeamMembersFormInitialValues,
  ...RestrictionsStepFormInitialValues,
};

export const CreateSpendPlan = () => {
  const isFeatureFlagEnabled = useSpendPlansFeatureFlag();
  const navigate = useNavigate();
  usePrefetchUsers();

  if (!isFeatureFlagEnabled) {
    return <Navigate to="/" />;
  }

  const handleCancelClick = () => {
    navigate('..');
  };

  return (
    <CreateSpendPlanWizard
      steps={steps}
      initialState={initialState}
      onCancel={handleCancelClick}
      wrapper={({ children }) => (
        <CreateSpendPlanProvider>{children}</CreateSpendPlanProvider>
      )}
    />
  );
};
