import { _ApplicationState } from 'recoil-state/application/product-onboarding.models';
import { OnboardingOverviewResponse } from 'types/onboarding-info';
import { FicoCreditModel } from 'services/flexbase/fico.model';
import { CompanyDocument } from 'services/flexbase/flexbase-onboarding-client';
import { DateTime } from 'luxon';

export type ActionItemNames = 'pendingUsers' | 'plaid' | 'docs' | 'frozen';

// Higher weights = should display first in the list. Numbers are made up
const ActionItemOrder: Record<ActionItemNames, number> = {
  docs: 1,
  pendingUsers: 2,
  frozen: 3,
  plaid: 4,
};

/**
 * This function returns a sorted list of actions items that a given user needs to
 * complete to have their application processed. The items are sorted in the order that we want
 * them to appear in the UI.
 *
 * WARNING: This will return 1 item for a user with no "actual" action items!
 */
export function calculateCreditActionItems(
  status: _ApplicationState,
  overview: OnboardingOverviewResponse,
  fico: FicoCreditModel,
  uploadedDocs: CompanyDocument[],
): ActionItemNames[] {
  const actionItems: ActionItemNames[] = [];

  // allPlaidAccountsLinked === false = servicer requested additional links
  // allPlaidAccountsLinked === undefined = default value
  if (
    status.user.allPlaidAccountsLinked === false ||
    status.requiredCredit.some(
      (r) =>
        r === 'user.plaidConnection' || r === 'company.financialInstitutions',
    )
  ) {
    actionItems.push('plaid');
  }

  if (
    hasRequestedDocsActionItem(status.company.requestedDocuments, uploadedDocs)
  ) {
    actionItems.push('docs');
  }

  if (hasOutstandingUsersActionItem(status, overview)) {
    actionItems.push('pendingUsers');
  }

  if (
    fico.issues?.some(
      (issue) =>
        issue.toLowerCase().includes('freeze') ||
        issue.toLowerCase().includes('frozen'),
    )
  ) {
    actionItems.push('frozen');
  }

  actionItems.sort((item, compareItem) => {
    const itemOrder = ActionItemOrder[item];
    const compareItemOrder = ActionItemOrder[compareItem];

    return itemOrder - compareItemOrder;
  });

  return actionItems;
}

export function calculateIntlPaymentsActionItems(
  status: _ApplicationState,
  overview: OnboardingOverviewResponse,
  uploadedDocs: CompanyDocument[],
): ActionItemNames[] {
  const actionItems: ActionItemNames[] = [];

  if (hasOutstandingUsersActionItem(status, overview)) {
    actionItems.push('pendingUsers');
  }

  if (
    hasRequestedDocsActionItem(status.company.requestedDocuments, uploadedDocs)
  ) {
    actionItems.push('docs');
  }

  actionItems.sort((item, compareItem) => {
    const itemOrder = ActionItemOrder[item];
    const compareItemOrder = ActionItemOrder[compareItem];

    return itemOrder - compareItemOrder;
  });

  return actionItems;
}

function hasOutstandingUsersActionItem(
  status: _ApplicationState,
  overview: OnboardingOverviewResponse,
): boolean {
  const owners = status.company.owners.map((o) => o.id!);
  const officers = status.company.officers.map((o) => o.id!);
  const users = [...owners, ...officers];

  return overview.users
    .filter((u) => u.id !== status.user.id && users.includes(u.id))
    .some((u) => !u.isComplete);
}

function hasRequestedDocsActionItem(
  requestedDocuments: string[],
  uploadedDocs: CompanyDocument[],
): boolean {
  if (!requestedDocuments.length) {
    return false;
  }

  const relatedDocs = uploadedDocs.filter((d) =>
    requestedDocuments.includes(d.description),
  );

  if (!relatedDocs.length) {
    return true;
  }

  // This checks the status of the most recent document for each requested document.
  // If all of them are approved, the 'docs' action item is not added to the list.
  const mostRecentDocumentMap = relatedDocs
    .toSorted((a, b) => {
      const aDate = DateTime.fromSQL(a.uploadedAt);
      const bDate = DateTime.fromSQL(b.uploadedAt);
      return bDate.diff(aDate).milliseconds;
    })
    .reduce<Record<string, CompanyDocument>>((prev, curr) => {
      const previousDocument = prev[curr.description];

      if (!previousDocument) {
        prev[curr.description] = curr;
        return prev;
      }

      const prevDate = DateTime.fromSQL(previousDocument.uploadedAt);
      const currDate = DateTime.fromSQL(curr.uploadedAt);

      if (currDate > prevDate) {
        prev[curr.description] = curr;
      }

      return prev;
    }, {});

  // This says: If any of the documents have a status other than approved, return true
  return requestedDocuments.some(
    (r) => mostRecentDocumentMap[r]?.status !== 'Approved',
  );
}
