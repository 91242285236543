import { Text } from '@mantine/core';

type LineItemsTotalProps = {
  title: string;

  invoiceTotal: string;
};

const LineItemsTotal = ({ title, invoiceTotal }: LineItemsTotalProps) => {
  return (
    <>
      <Text fw={500} size="sm">
        {title}
      </Text>
      <Text fz={40} ta="center" lh={1}>
        {invoiceTotal}
      </Text>
    </>
  );
};

export default LineItemsTotal;
