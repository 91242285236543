import { PropsWithChildren } from 'react';
import { IssueDebitCardWizard } from './create-wizard';

type Props = PropsWithChildren<{
  hideBack?: boolean;
  hideNext?: boolean;
  nextLoading?: boolean;
  handleNext?: () => void;
  labels?: {
    next?: string;
    back?: string;
  };
}>;

export const DebitCardWizardStepLayout = ({
  children,
  hideBack = false,
  hideNext = false,
  nextLoading = false,
  handleNext,
  labels,
}: Props) => {
  return (
    <IssueDebitCardWizard.Step
      onNext={handleNext}
      nextLoading={nextLoading}
      hideBack={hideBack}
      hideNext={hideNext}
      labels={labels}
    >
      {children}
    </IssueDebitCardWizard.Step>
  );
};
