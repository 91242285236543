import { TRANSACTION_GROUPS } from 'constants/constants';
import { usePaymentModalFilters } from '../use-payment-filters';
import { SimpleSegmentSelectedFilter } from '@common/utilities/filter/simple-segmented.filter';

const FILTER_MULTISELECT_OPTIONS = [
  {
    label: 'Domestic ACH',
    filterCriteria: TRANSACTION_GROUPS.ach,
    key: 'ach',
  },
  {
    label: 'Domestic wire',
    filterCriteria: TRANSACTION_GROUPS.wire,
    key: 'wire',
  },
  {
    label: 'International wire',
    filterCriteria: TRANSACTION_GROUPS.internationalWire,
    key: 'outgoingACHinternationalWire',
  },
  {
    label: 'Book',
    filterCriteria: TRANSACTION_GROUPS.book,
    key: 'book',
  },
];

export const PaymentTypeFilter = () => {
  const paymentFilters = usePaymentModalFilters();

  return (
    <SimpleSegmentSelectedFilter
      filterHook={paymentFilters}
      filterKey="type"
      options={FILTER_MULTISELECT_OPTIONS}
      label="Type"
      includesValue={(row) => row.type}
    />
  );
};
