import { createStyles } from '@mantine/emotion';
import { rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  tabs: {
    display: 'flex',
    marginBottom: rem(40),
  },

  card: {
    padding: rem(24),
    borderRadius: theme.defaultRadius,
    backgroundColor: '#fff',
    table: {
      thead: {
        borderTop: `${rem(1)} solid #E6E7E9`,
      },
      tbody: {
        'tr:nth-of-type(even)': {
          backgroundColor: 'rgba(230, 231, 233, 0.12)',
        },
      },
    },
  },
}));
