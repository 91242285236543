import {
  Box,
  Checkbox,
  Group,
  SimpleGrid,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useGetUsers } from '@queries/use-users';
import { SpendPlanMemberFields } from '../team-members-step.context';
import { useCreateSpendPlanContext } from 'areas/spend-plans/create/create-spend-plan.context';
import { PreviewLabel } from '../../../components/preview-label';
import { AvatarLogoCell, FlexbaseTable } from '@common/table';
import { TableColumn } from 'react-data-table-component';
import { CloseIcon, CrownIcon } from 'assets/svg';
import { useCreateSpendPlanWizard } from '../../../create-spend-plan.wizard';
import { useTeamMemberStepStyles } from '../team-members-step.style';
import { SpendPlanCurrencyInput } from '../../../components/spend-plan-currency-input';
import { TeamMembersTableHeader } from './team-members-table-header';
import {
  ActionTableCell,
  MemberDetailsTableCell,
} from '../../../../components/spend-plan-table-cells';
import {
  deriveCurrentAssigned,
  deriveCurrentAssignedError,
  deriveFutureAssigned,
  deriveFutureAssignedError,
} from 'areas/spend-plans/utils/derive-member-limits';

export const useTeamMembersTable = () => {
  const { teamMembersForm: form } = useCreateSpendPlanContext();
  const { state } = useCreateSpendPlanWizard();
  const { data: allUsers } = useGetUsers();

  const isOneTimePlan = state.recurring === 'onetime';

  const members = state.members
    .toSorted((a, b) => {
      const aVal = a.isManager ? 1 : 0;
      const bVal = b.isManager ? 1 : 0;

      return bVal - aVal;
    })
    .map<SpendPlanMemberFields>((m) => {
      const user = allUsers?.find((u) => u.id === m.id);

      if (!user) {
        return m;
      }

      return {
        ...m,
        firstName: user.firstName || m.firstName,
        lastName: user.lastName || m.lastName,
        email: user.email || m.email,
      };
    });

  const handleUpdateMember = (
    id: string,
    updater: (member: SpendPlanMemberFields) => void,
  ) => {
    const target = state.members.find((member) => member.id === id);

    if (target) {
      updater(target);
      form.setFieldValue('members', [...state.members]);
    }
  };

  const handleFullFundsChange = (id: string, checked: boolean) => {
    handleUpdateMember(id, (m) => (m.fullFundsAccess = checked));
  };

  const handleCurrentAssignedChange = (
    id: string,
    value: number | undefined,
  ) => {
    handleUpdateMember(id, (m) => (m.currentAssigned = value || 0));
  };

  const handleRepeatFundsChange = (id: string, checked: boolean) => {
    handleUpdateMember(id, (m) => (m.repeatFundsAccess = checked));
  };

  const handleFutureAssignedChange = (
    id: string,
    value: number | undefined,
  ) => {
    handleUpdateMember(id, (m) => (m.assigned = value || 0));
  };

  const handleRemove = (id: string) => {
    form.setFieldValue(
      'members',
      state.members.filter((member) => member.id !== id),
    );
  };

  return {
    members,
    isOneTimePlan,
    getCurrentAssignedValue: (member: SpendPlanMemberFields) =>
      deriveCurrentAssigned({
        member,
        planLimit: state.currentLimit || 0,
      }),
    getCurrentAssignedError: (member: SpendPlanMemberFields) =>
      deriveCurrentAssignedError({
        member,
        planLimit: state.currentLimit || 0,
      }),
    getFutureAssignedValue: (member: SpendPlanMemberFields) =>
      deriveFutureAssigned({
        member,
        planLimit: state.currentLimit || 0,
      }),
    getFutureAssignedError: (member: SpendPlanMemberFields) =>
      deriveFutureAssignedError({
        member,
        planLimit: state.currentLimit || 0,
        planFutureLimit:
          (state.repeatCurrentLimit ? state.currentLimit : state.futureLimit) ||
          0,
      }),
    handleFullFundsChange,
    handleCurrentAssignedChange,
    handleRepeatFundsChange,
    handleFutureAssignedChange,
    handleRemove,
  };
};

export const TeamMembersTableFull = () => {
  const {
    members,
    isOneTimePlan,
    getCurrentAssignedValue,
    getCurrentAssignedError,
    getFutureAssignedValue,
    getFutureAssignedError,
    handleFullFundsChange,
    handleCurrentAssignedChange,
    handleRepeatFundsChange,
    handleFutureAssignedChange,
    handleRemove,
  } = useTeamMembersTable();

  const columns: TableColumn<SpendPlanMemberFields>[] = [
    {
      id: 'memberInfo',
      name: <TeamMembersTableHeader>Name</TeamMembersTableHeader>,
      selector: (member) => `${member.firstName} ${member.lastName}`.trim(),
      cell: (member) => {
        return (
          <MemberDetailsTableCell
            name={`${member.firstName} ${member.lastName}`}
            email={member.email}
            isManager={member.isManager}
          />
        );
      },
      compact: true,
      grow: 2,
    },
    {
      id: 'fullFundsAccess',
      name: <TeamMembersTableHeader>Full funds access</TeamMembersTableHeader>,
      selector: (member) => (member.fullFundsAccess ? 1 : 0),
      cell: (member) => (
        <Checkbox
          checked={member.fullFundsAccess}
          onChange={(e) => handleFullFundsChange(member.id, e.target.checked)}
        />
      ),
      center: true,
      compact: true,
      grow: 0,
    },
    {
      id: 'currentAssigned',
      name: <TeamMembersTableHeader>Assigned</TeamMembersTableHeader>,
      selector: (member) => member.currentAssigned,
      cell: (member) => {
        const value = getCurrentAssignedValue(member);

        return (
          <SpendPlanCurrencyInput
            value={value}
            disabled={member.fullFundsAccess}
            onValueChange={(val) =>
              handleCurrentAssignedChange(member.id, val.floatValue)
            }
            error={getCurrentAssignedError(member)}
          />
        );
      },
      compact: true,
      grow: 0,
    },
    {
      id: 'repeat',
      omit: isOneTimePlan,
      name: <TeamMembersTableHeader>Repeat funds</TeamMembersTableHeader>,
      selector: (member) => member.repeatFundsAccess,
      cell: (member) => (
        <Checkbox
          checked={member.repeatFundsAccess}
          onChange={(e) => handleRepeatFundsChange(member.id, e.target.checked)}
        />
      ),
      center: true,
      compact: true,
      grow: 0,
    },
    {
      id: 'futureAssigned',
      omit: isOneTimePlan,
      name: <TeamMembersTableHeader>Future assigned</TeamMembersTableHeader>,
      selector: (member) => member.assigned,
      cell: (member) => {
        const value = getFutureAssignedValue(member);

        return (
          <SpendPlanCurrencyInput
            value={value}
            error={getFutureAssignedError(member)}
            disabled={member.repeatFundsAccess}
            onValueChange={(val) =>
              handleFutureAssignedChange(member.id, val.floatValue)
            }
          />
        );
      },
      compact: true,
      grow: 0,
    },
    {
      id: 'actions',
      cell: (member) => (
        <ActionTableCell onClick={() => handleRemove(member.id)} />
      ),
      width: '50px',
      grow: 0,
      center: true,
      compact: true,
    },
  ];

  return (
    <>
      <PreviewLabel
        fz={20}
        fw={500}
        lh="32px"
        color="primarySecondarySuccess.8"
      >
        Team members
      </PreviewLabel>

      <FlexbaseTable
        data={members}
        columns={columns}
        noDataComponent={<Box p={24}>No team members assigned.</Box>}
      />
    </>
  );
};

export const TeamMembersTableCompact = () => {
  const theme = useMantineTheme();
  const { classes } = useTeamMemberStepStyles();
  const {
    members,
    isOneTimePlan,
    getCurrentAssignedValue,
    getCurrentAssignedError,
    getFutureAssignedValue,
    getFutureAssignedError,
    handleFullFundsChange,
    handleCurrentAssignedChange,
    handleRepeatFundsChange,
    handleFutureAssignedChange,
    handleRemove,
  } = useTeamMembersTable();

  const getAssignedFundsElement = (member: SpendPlanMemberFields) => {
    const value = getCurrentAssignedValue(member);

    return (
      <SpendPlanCurrencyInput
        label="Assigned funds"
        value={value}
        error={getCurrentAssignedError(member)}
        disabled={member.fullFundsAccess}
        onValueChange={(val) =>
          handleCurrentAssignedChange(member.id, val.floatValue)
        }
        styles={{
          root: {
            flexShrink: 1,
            flexGrow: 1,
          },
        }}
      />
    );
  };

  const getFutureAssignedElement = (member: SpendPlanMemberFields) => {
    const value = getFutureAssignedValue(member);

    return (
      <SpendPlanCurrencyInput
        label="Future periods"
        value={value}
        error={getFutureAssignedError(member)}
        disabled={member.repeatFundsAccess}
        onValueChange={(val) =>
          handleFutureAssignedChange(member.id, val.floatValue)
        }
        styles={{
          root: {
            flexShrink: 1,
            flexGrow: 1,
          },
        }}
      />
    );
  };

  return (
    <>
      <PreviewLabel
        fz={20}
        fw={500}
        lh="32px"
        color="primarySecondarySuccess.8"
      >
        Team members
      </PreviewLabel>

      {members.length ? (
        <Stack gap={0}>
          {members.map((member) => (
            <Stack key={member.id} gap={20} className={classes.mobileMember}>
              <Group align="center" w="100%" py={8} wrap="nowrap">
                <Box h={24} w={24}>
                  <AvatarLogoCell
                    placeholderName={`${member.firstName} ${member.lastName}`}
                    withLabel={false}
                  />
                </Box>

                <Stack gap={0} sx={{ overflow: 'hidden', flexGrow: 1 }}>
                  <Group gap={theme.spacing.xxs} align="start" wrap="nowrap">
                    <Text
                      fz={14}
                      lh="19px"
                      color={theme.colors.neutral[9]}
                      style={{ wordBreak: 'break-word' }}
                    >
                      {`${member.firstName} ${member.lastName}`.trim()}
                    </Text>

                    {member.isManager ? (
                      <CrownIcon
                        height={20}
                        width={20}
                        style={{ flexShrink: 0 }}
                      />
                    ) : null}
                  </Group>

                  {member.email ? (
                    <Text
                      fz={12}
                      color={theme.colors.neutral[6]}
                      sx={{ wordBreak: 'break-word' }}
                    >
                      {member.email}
                    </Text>
                  ) : null}
                </Stack>

                <Box
                  style={{
                    alignSelf: 'start',
                    flexShrink: 0,
                    cursor: 'pointer',
                  }}
                >
                  <CloseIcon
                    onClick={() => handleRemove(member.id)}
                    height={20}
                    width={20}
                  />
                </Box>
              </Group>

              <SimpleGrid cols={isOneTimePlan ? 1 : 2}>
                <Checkbox
                  label="Full funds access"
                  checked={member.fullFundsAccess}
                  onChange={(e) =>
                    handleFullFundsChange(member.id, e.target.checked)
                  }
                />

                {!isOneTimePlan ? (
                  <Checkbox
                    label="Repeat funds"
                    checked={member.repeatFundsAccess}
                    onChange={(e) =>
                      handleRepeatFundsChange(member.id, e.target.checked)
                    }
                  />
                ) : null}

                {getAssignedFundsElement(member)}

                {!isOneTimePlan ? getFutureAssignedElement(member) : null}
              </SimpleGrid>
            </Stack>
          ))}
        </Stack>
      ) : (
        <Box className={classes.mobileNoMembers}>No team members assigned.</Box>
      )}
    </>
  );
};
