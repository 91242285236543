import { Grid, Loader, LoaderProps, Overlay } from '@mantine/core';
import { ReactNode } from 'react';

type Props = { visible: boolean; bottomContent?: ReactNode };

/**
 * Note: Must set parent position to relative
 * @param visible sets the loader to visible or not visible
 * @param bottomContent this is any react node content you would like to add. center the content with ta="center" for mantine components
 * @constructor
 */
export const TransparentCenteredLoader = ({
  visible,
  bottomContent,
  ...loaderProps
}: Props & LoaderProps) => {
  return (
    <Overlay
      color="#FFFFFF"
      opacity={0.9}
      radius={8}
      style={{
        display: visible ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Grid>
        <Grid.Col span={12} ta="center">
          <Loader ta="center" {...loaderProps} />
        </Grid.Col>
        <Grid.Col span={12}>{bottomContent}</Grid.Col>
      </Grid>
    </Overlay>
  );
};

export default TransparentCenteredLoader;
