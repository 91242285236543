import {
  Button,
  Divider,
  Flex,
  rem,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import FlexNumberInput from '@common/flex-number-input';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  InvoiceDetails,
  LineItemInput,
  useInvoiceWizard,
} from '../../invoice-wizard';
import { useForm } from '@mantine/form';
import { useParams } from 'react-router-dom';
import { DateInput } from '@mantine/dates';
import WizardErrorBanner from 'areas/billpay/wizard-error-banner';
import {
  useCreateBillpayInvoice,
  useUpdateBillpayInvoice,
} from '@queries/use-bill-pay';
import {
  currencyToCents,
  formatCents,
  formatCurrency,
} from '@utilities/formatters/format-currency';
import {
  BillpayLineItemAccountingInfo,
  CreateBillpayInvoiceLineItemRequest,
} from 'types/bill-pay';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import { useRecoilValue } from 'recoil';
import { useGetPlatformBusinessAccountsPayable } from '@queries/use-platform-business';
import { isEmpty } from 'underscore';
import LineItemAccountingInfo from './line-item-accounting-info';
import { PiCalendarLight, PiPlus, PiTrash } from 'react-icons/pi';
import LineItemsContainer from './line-items-container';

/**
 * The api deactivates all existing lineItems, so we want to make sure it treats
 * these as new lineItems so we omit the ID
 */
const mapLineItemInputsToApiFormat = (
  lineItems: LineItemInput[],
  removeId?: boolean,
) =>
  lineItems.map(
    (item) =>
      ({
        ...item,
        id: removeId ? undefined : item.id,
        total: currencyToCents(item.total || 0),
      }) as CreateBillpayInvoiceLineItemRequest,
  );

const invoiceCreationError =
  'There was an error saving the invoice, please try again.';

const validateActiveLineItemsOnly = (
  vals: InvoiceDetails,
  errorMessage: string,
  fieldVal: string,
  val?: string | number,
) => {
  /*
  (DM)
  The following block is meant to get the associated formValue index
  to ensure that we always check the correct line item, derived from
  the field's Mantine 'fieldValue', which is a string in this format:

  'lineItems.0.description.'
  */
  const splitFieldVal = fieldVal.split('.');
  const index = Number(splitFieldVal[splitFieldVal.length - 2]);
  const formVal = vals.lineItems[index];

  if (!formVal || formVal.status !== 'active') {
    return null;
  } else {
    return val === undefined ? errorMessage : null;
  }
};

const AddLineItems = () => {
  const theme = useMantineTheme();
  const [showWireAmountError, setShowWireAmountError] = useState(false);
  const [isAccountingApplyAllChecked, setIsAccountingApplyAllChecked] =
    useState(false);
  const { state, setState, onEvent, onNext, goToNextStep, onBack } =
    useInvoiceWizard();
  const { businessId } = useRecoilValue(ApplicationState);
  const { data: accountsPayableData } =
    useGetPlatformBusinessAccountsPayable(businessId);
  const { isInvoiceDraft, isActionDisabled } = state;
  const { id: existingInvoiceId } = useParams();
  const { mutate: createBillpayInvoice, error: createBillpayInvoiceError } =
    useCreateBillpayInvoice();
  const { mutate: updateBillpayInvoice, error: updateBillpayInvoiceError } =
    useUpdateBillpayInvoice();
  const hasAccountsPayable = !isEmpty(accountsPayableData);

  const topOfPageRef = useRef<HTMLDivElement>(null);

  const form = useForm<InvoiceDetails>({
    validateInputOnBlur: true,
    initialValues: {
      ...state.invoiceDetails,
      lineItems: state.invoiceDetails?.lineItems?.map((item) => ({
        ...item,
        total: item.total ? formatCents(item.total) : undefined,
      })) as LineItemInput[], // map cents from api to dollar amount for input
    },
    validate: {
      lineItems: {
        description: (value, values, fieldVal) =>
          validateActiveLineItemsOnly(
            values,
            'Description is required',
            fieldVal,
            value,
          ),
        total: (value, values, fieldVal) =>
          validateActiveLineItemsOnly(
            values,
            'Amount is required',
            fieldVal,
            value,
          ),
        quantity: (value, values, fieldVal) =>
          validateActiveLineItemsOnly(
            values,
            'Quantity is required',
            fieldVal,
            value,
          ),
      },
      dueDate: (value) => (value ? null : 'Due date is required'),
    },
  });

  const activeLineItems = useMemo(() => {
    return form.values.lineItems?.filter((item) => item.status !== 'inactive');
  }, [form.values.lineItems]);

  const invoiceTotal = useMemo(() => {
    // sum up line items
    const lineItemsTotal = activeLineItems?.reduce(
      (acc, item) =>
        acc + (Number(item.total) || 0) * (Number(item.quantity) || 0),
      0,
    );
    // add credits and tax
    const total =
      (lineItemsTotal ?? 0) +
      (Number(form.values.credits) || 0) * -1 +
      (Number(form.values.tax) || 0);

    if (
      Math.round(total * 100) >= 5000 &&
      state.recipientAccount?.type === 'wire'
    ) {
      setShowWireAmountError(false);
    }

    return {
      formatted: formatCurrency(total),
      cents: Math.round(total * 100),
    };
  }, [activeLineItems, form.values.tax, form.values.credits]);

  const createLineItem = () => {
    form.insertListItem('lineItems', {
      description: undefined,
      quantity: undefined,
      total: undefined,
      timestamp: Date.now(),
      status: 'active',
      accountingInfo: isAccountingApplyAllChecked
        ? form.values.lineItems[0].accountingInfo
        : {},
    });
  };

  onEvent('onSaveEdits', async () => {
    form.validate();
    if (!form.isDirty()) {
      return (
        form.isValid() && {
          invoiceDetails: {
            ...form.values,
            lineItems: mapLineItemInputsToApiFormat(activeLineItems),
          },
          invoiceTotal,
        }
      );
    }
    if (form.isValid()) {
      setState({
        invoiceTotal,
        invoiceDetails: {
          ...form.values,
          lineItems: mapLineItemInputsToApiFormat(activeLineItems),
        },
      });
      return {
        invoiceTotal,
        invoiceDetails: {
          ...form.values,
          lineItems: mapLineItemInputsToApiFormat(activeLineItems),
        },
      };
    }
    return false;
  });

  // save the form values on back
  onBack(() => {
    setState({
      invoiceTotal,
      invoiceDetails: {
        ...form.values,
        lineItems: mapLineItemInputsToApiFormat(activeLineItems),
      },
    });
  });

  onNext(async () => {
    form.validate();
    const invoiceTotalError =
      state.recipientAccount?.type === 'wire' &&
      form.values.lineItems.length > 0 &&
      invoiceTotal.cents < 5000;
    if (!form.isDirty()) {
      setState({
        invoiceTotal,
        invoiceDetails: {
          ...form.values,
          lineItems: mapLineItemInputsToApiFormat(activeLineItems),
        },
      });
      if (invoiceTotalError) {
        setShowWireAmountError(true);
        topOfPageRef.current?.scrollIntoView({
          behavior: 'smooth',
        });
        return;
      }
      goToNextStep();
      return;
    }
    if (form.isValid() && form.values.dueDate && state.existingDocumentId) {
      if (invoiceTotalError) {
        setShowWireAmountError(true);
        topOfPageRef.current?.scrollIntoView({
          behavior: 'smooth',
        });
        return;
      }

      if (existingInvoiceId) {
        try {
          updateBillpayInvoice(
            {
              id: existingInvoiceId!,
              recipientId: state.recipient?.id,
              dueDate: form.values?.dueDate,
              documentId: state.existingDocumentId,
              total: invoiceTotal?.cents,
              tax: currencyToCents(form.values?.tax || 0),
              credits: currencyToCents(form.values?.credits || 0),
              lineItems: mapLineItemInputsToApiFormat(activeLineItems, true),
            },
            {
              onSuccess: () => {
                setState({
                  invoiceTotal,
                  invoiceDetails: {
                    ...form.values,
                    lineItems: mapLineItemInputsToApiFormat(activeLineItems),
                  },
                });
                goToNextStep();
              },
              onError: () => {
                topOfPageRef.current?.scrollIntoView({
                  behavior: 'smooth',
                });
              },
            },
          );
        } catch (err) {
          topOfPageRef.current?.scrollIntoView({
            behavior: 'smooth',
          });
        }
      } else {
        createBillpayInvoice(
          {
            recipientId: state.recipient?.id,
            dueDate: form.values.dueDate,
            total: invoiceTotal.cents,
            tax: currencyToCents(form.values?.tax || 0),
            credits: currencyToCents(form.values?.credits || 0),
            documentId: state.existingDocumentId,
            lineItems: mapLineItemInputsToApiFormat(activeLineItems, true),
          },
          {
            onError: () => {
              topOfPageRef.current?.scrollIntoView({
                behavior: 'smooth',
              });
            },
            onSuccess: (invoiceResponse) => {
              setState({
                invoiceTotal,
                existingInvoiceId: invoiceResponse.invoice.id,
                invoiceDetails: {
                  ...form.values,
                  lineItems: mapLineItemInputsToApiFormat(activeLineItems),
                },
              });
              goToNextStep();
            },
          },
        );
      }
    }
  });

  useEffect(() => {
    setState({ isNextEnabled: true });
  }, []);

  useEffect(() => {
    setState({ invoiceTotal });
  }, [invoiceTotal.cents]);

  const isDisabled = !isInvoiceDraft || isActionDisabled;

  const handleDeleteLineItem = (index: number) => {
    form.setFieldValue(`lineItems.${index}.status`, 'inactive');
  };

  const handleApplyAllChange = (isChecked: boolean) => {
    setIsAccountingApplyAllChecked(isChecked);
    if (isChecked) {
      const firstItemValues = form.values.lineItems[0].accountingInfo;
      form.values.lineItems.forEach((item, index) => {
        if (item.status !== 'inactive') {
          form.setFieldValue(
            `lineItems.${index}.accountingInfo`,
            firstItemValues,
          );
        }
      });
    }
  };

  const handleValueChange =
    (index: number) =>
    (field: keyof BillpayLineItemAccountingInfo, value: string | null) => {
      form.setFieldValue(
        `lineItems.${index}.accountingInfo.${field}`,
        value || undefined,
      );
      if (index === 0) {
        // Update other line items to new value if apply all is checked
        if (isAccountingApplyAllChecked) {
          form.values.lineItems.forEach((item, idx) => {
            if (item.status !== 'inactive') {
              form.setFieldValue(
                `lineItems.${idx}.accountingInfo.${field}`,
                value || undefined,
              );
            }
          });
        }
      }
    };

  const wireAmountError = showWireAmountError
    ? 'To send a wire to this recipient, this payment must meet the minimum wire amount of $50.00.'
    : undefined;

  return (
    <Stack ref={topOfPageRef}>
      {(createBillpayInvoiceError ||
        updateBillpayInvoiceError ||
        wireAmountError) && (
        <WizardErrorBanner message={wireAmountError || invoiceCreationError} />
      )}

      <LineItemsContainer>
        {form.values.lineItems?.map((item, index) =>
          item?.status === 'inactive' ? null : (
            <Stack key={`${index}-${item.timestamp}-${item.id}`} gap="sm">
              <Flex>
                <TextInput
                  disabled={isDisabled}
                  label="Description"
                  placeholder="Description"
                  w="100%"
                  {...form.getInputProps(`lineItems.${index}.description`)}
                />
              </Flex>
              <Flex direction="row" gap="md" justify="space-between">
                <Flex>
                  <FlexNumberInput
                    disabled={isDisabled}
                    leftSection=" "
                    leftSectionWidth={10}
                    label="Qty"
                    thousandSeparator
                    decimalScale={2}
                    allowNegative={false}
                    placeholder="0"
                    variant="unstyled"
                    {...form.getInputProps(`lineItems.${index}.quantity`)}
                    onChange={() => null}
                    onValueChange={(value) => {
                      form.setFieldValue(
                        `lineItems.${index}.quantity`,
                        value?.floatValue,
                      );
                    }}
                  />
                </Flex>
                <Flex sx={{ flexBasis: '35%' }}>
                  <FlexNumberInput
                    disabled={isDisabled}
                    label="Amount"
                    placeholder="0.00"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                    allowNegative
                    leftSection="$"
                    leftSectionWidth={24}
                    variant="unstyled"
                    {...form.getInputProps(`lineItems.${index}.total`)}
                    onChange={() => null}
                    onValueChange={(value) => {
                      form.setFieldValue(
                        `lineItems.${index}.total`,
                        value?.floatValue,
                      );
                    }}
                  />
                </Flex>
              </Flex>
              {hasAccountsPayable && (
                <LineItemAccountingInfo
                  index={index}
                  value={item.accountingInfo}
                  onValueChange={handleValueChange(index)}
                  onApplyAllChange={handleApplyAllChange}
                  isApplyAllChecked={isAccountingApplyAllChecked}
                />
              )}
              {activeLineItems.length > 1 && (
                <Flex justify="flex-end">
                  <Flex
                    onClick={() => handleDeleteLineItem(index)}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: theme.colors.red[1],
                      },
                    }}
                    gap="xxs"
                    align="center"
                    py="xxs"
                    px="xs"
                  >
                    <Text fz={rem(12)} c="red.4">
                      Remove
                    </Text>
                    <PiTrash size={'1rem'} color={theme.colors.red[4]} />
                  </Flex>
                </Flex>
              )}
              <Divider />
            </Stack>
          ),
        )}
        <Flex>
          <Button
            disabled={isDisabled}
            variant="subtle"
            leftSection={
              <PiPlus
                size={'1rem'}
                color={theme.colors.primarySecondarySuccess[4]}
              />
            }
            onClick={createLineItem}
          >
            <Text c="primarySecondarySuccess.4" fz={14}>
              Add Another Line Item
            </Text>
          </Button>
        </Flex>
      </LineItemsContainer>

      <Flex direction="row" gap="md">
        <FlexNumberInput
          disabled={isDisabled}
          label="Credits"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          allowNegative={false}
          leftSection="$"
          leftSectionWidth={24}
          placeholder="0.00"
          variant="unstyled"
          w="100%"
          {...form.getInputProps('credits')}
          onChange={() => null}
          onValueChange={(value) => {
            form.setFieldValue('credits', value?.floatValue);
          }}
        />
        <FlexNumberInput
          disabled={isDisabled}
          label="Tax"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          allowNegative={false}
          leftSection="$"
          leftSectionWidth={24}
          placeholder="0.00"
          variant="unstyled"
          w="100%"
          {...form.getInputProps('tax')}
          onChange={() => null}
          onValueChange={(value) => {
            form.setFieldValue('tax', value?.floatValue);
          }}
        />
      </Flex>
      <Flex direction="row" gap="md" mb={rem(20)}>
        <DateInput
          disabled={isDisabled}
          label="Due Date"
          w="100%"
          leftSection={<PiCalendarLight size={'1.25rem'} />}
          placeholder="Select due date"
          {...form.getInputProps('dueDate')}
          value={form.values.dueDate ? new Date(form.values.dueDate) : null}
          onChange={(date) =>
            form.setFieldValue('dueDate', date?.toISOString())
          }
        />
      </Flex>
      <Text size="xs" c="neutral.7">
        You are responsible for the accuracy of any line items extracted from
        your invoices. You understand and acknowledge that the OCR feature of
        Flex’s bill pay features are for convenience and final accuracy of any
        information, including but not limited to, descriptions, quantities,
        amounts, credits, taxes, and invoice totals lies with you. Flex
        disclaims any liability for any inaccuracies in the data captured via
        its bill pay feature, and shall have no liability to you for any losses
        or damages for inaccurate information.
      </Text>
    </Stack>
  );
};

export default AddLineItems;
