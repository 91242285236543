import { useRecoilValue } from 'recoil';
import { Box, Overlay, rem, Text, useMantineTheme } from '@mantine/core';

import { useEffect, useState } from 'react';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { DateTime } from 'luxon';
import { InvoicesByMccCode } from 'services/flexbase/flexbase-onboarding-client';
import { shortenLabelName } from 'areas/credit/tabs/overview/utilities';
import { MoneySpentPlaceholder } from 'assets/svg';
import TransparentCenteredLoader from '@common/composites/loading/transaparent-centered-loader';
import { CompanyIdState } from 'recoil-state/application/onboarding-form.state';
import SpendRechart from './annual-spend-rechart';

export type AnnualSpendData = {
  data: { name: string; value: number }[];
};
/**
 *
 * @returns an amazing donut chart of the annual spend for a business
 */
const AnnualSpendChart = () => {
  const theme = useMantineTheme();
  const companyId = useRecoilValue(CompanyIdState);
  let mccCodeData: InvoicesByMccCode;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAnnualSpendByMccData();
  }, []);

  const [annualSpendNew, setAnnualSpendNew] = useState<AnnualSpendData>({
    data: [],
  });
  const [totalSpend, setTotalSpend] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState('');

  const getAnnualSpendByMccData = async () => {
    try {
      setLoading(true);
      mccCodeData = await flexbaseOnboardingClient.getInvoicesByMccCategory(
        companyId,
        {
          limit: '4',
          after: DateTime.now().set({ day: 1, month: 1 }).toISODate(),
          before: DateTime.now().toISODate(),
        },
      );
      if (mccCodeData.success && mccCodeData.categories) {
        const number = parseFloat(mccCodeData.allInvoices || '');
        setTotalSpend(number);

        const catLabels: string[] = [];
        const catValues: number[] = [];

        // create an array of labels for the donut chart
        // create an array of values for the donut chart
        mccCodeData.categories?.forEach((c) => {
          catLabels.push(
            shortenLabelName(c.description || c.category || '', 25),
          );
          catValues.push(+Number(c.total).toFixed(2));
        });
        const sumCategories = mccCodeData.categories
          ?.map((c) => {
            return Number(c.total || '0');
          })
          .reduce((x, y) => {
            return x + y;
          });

        catValues.push(Number(mccCodeData.allInvoices) - (sumCategories || 0));
        const chartDataArray = [];

        for (let i = 0; i < catLabels.length; i++) {
          const dataObject = {
            name: catLabels[i],
            value: catValues[i],
          };
          chartDataArray.push(dataObject);
        }

        const remainingValues: number = catValues
          .slice(catLabels.length)
          .reduce((acc, val) => acc + val, 0);
        chartDataArray.push({ name: 'Other', value: remainingValues });

        if (chartDataArray.length > 1) {
          setAnnualSpendNew({ data: chartDataArray });
        }
      } else {
        setErrorMessage(
          'Unable to calculate your annual spend cagetories at this time.',
        );
      }
    } catch {
      setErrorMessage(
        'We are unable to retrieve the account information at the moment.',
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={(currentTheme) => ({
        minWidth: rem(420),
        height: '125%',
        backgroundColor: currentTheme.colors.neutral[0],
      })}
    >
      <TransparentCenteredLoader visible={loading} />
      {!errorMessage ? (
        <SpendRechart dataAnnual={annualSpendNew.data} total={totalSpend} />
      ) : (
        <div
          style={{
            position: 'relative',
            borderRadius: rem(2),
          }}
        >
          <MoneySpentPlaceholder width="100%" height="200px" />
          <Overlay
            color={theme.colors.contentBackground[2]}
            backgroundOpacity={0.9}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                backgroundColor: theme.colors.primarySecondarySuccess[7],
                padding: `${rem(5)} ${rem(15)}`,
                borderRadius: rem(360),
              }}
            >
              <Text
                sx={{
                  color: theme.colors.contentBackground[2],
                  fontSize: rem(14),
                }}
              >
                Start spending to analyze your finances.
              </Text>
            </Box>
          </Overlay>
        </div>
      )}
    </Box>
  );
};

export default AnnualSpendChart;
