import { SearchIcon } from 'assets/svg';
import { Group, TextInput } from '@mantine/core';

type Props = {
  searchValue?: string;
  onSearchChange?: (val: string) => void;
  saveChangesButton?: JSX.Element;
};

export const SpendPlanLimitsTableHeader = ({
  searchValue,
  onSearchChange,
  saveChangesButton,
}: Props) => {
  return (
    <Group mb="md" justify="space-between">
      <TextInput
        value={searchValue}
        placeholder="Search team limits"
        sx={(_, helpers) => ({
          width: 368,
          [helpers.smallerThan('md')]: {
            width: '100%',
          },
        })}
        leftSection={<SearchIcon width={20} height={20} />}
        onChange={(e) => onSearchChange?.(e.target.value)}
      />

      {saveChangesButton}
    </Group>
  );
};
