import { showNotification } from '@mantine/notifications';
import * as Sentry from '@sentry/react';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PropsWithChildren } from 'react';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (err, query) => {
      if (query.meta?.errorMessage) {
        showNotification({
          color: 'red',
          title: 'Error',
          message: query.meta.errorMessage as string,
        });
      }
      Sentry.withScope((scope) => {
        scope.setContext('query', {
          queryHash: query.queryHash,
          queryKey: query.queryKey,
        });
        scope.setFingerprint([query.queryHash.replaceAll(/[0-9]/g, '0')]);
        Sentry.captureException(err);
      });
    },
  }),
  mutationCache: new MutationCache({
    onError: (err, _variables, _context, mutation) => {
      Sentry.withScope((scope) => {
        scope.setContext('mutation', {
          mutationId: mutation.mutationId,
          variables: mutation.state.variables,
        });

        if (mutation.options.mutationKey) {
          scope.setFingerprint(
            // Duplicate to prevent modification
            Array.from(mutation.options.mutationKey) as string[],
          );
        }
        Sentry.captureException(err);
      });
    },
  }),
});

const QueryProvider = ({ children }: PropsWithChildren) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

export default QueryProvider;
