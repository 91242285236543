import {
  Box,
  Text,
  Flex,
  Alert,
  Button,
  Center,
  Loader,
  UnstyledButton,
} from '@mantine/core';
import CheckImages from './check-images';
import CheckTracker from './check-tracker';
import useModal from '@common/composites/modal/modal-hook';
import BadgeStatus from '../check-deposit-status-badge';
import { getMonthDayYear } from '@utilities/dates';
import GenericError from '@common/generic-error';
import { RightContentModal } from '@common/composites/modal/right-content.modal';
import { useGetCheckDepositInfo } from '@queries/use-checks-deposits';
import { formatCheckStatus } from '@utilities/formatters/format-checks-status';
import { useCheckDepositTracker } from '@utilities/custom-hooks/use-check-deposit';
import { formatCurrency, formatCents } from '@utilities/formatters';
import { PiXCircleBold, PiXLight } from 'react-icons/pi';

type CheckDetailsProps = {
  id: string;
  account?: string;
};

const CheckTitle = ({ status }: { status: string }) => (
  <Flex gap="md" align="center">
    <Text fz={40}>Check</Text>
    <BadgeStatus {...{ status }} />
  </Flex>
);

const CheckDepositDetails = ({ id, account }: CheckDetailsProps) => {
  const {
    data: check,
    isPending,
    isError,
    refetch,
  } = useGetCheckDepositInfo(id);
  const { closeAllModals } = useModal();
  const { checkTracker, isLoading } = useCheckDepositTracker(id);

  if (isPending || isLoading) {
    return (
      <Center bg="neutral.2" mih="100vh">
        <Loader />
      </Center>
    );
  }

  if (isError) {
    return (
      <Box bg="neutral.2" mih="100vh">
        <GenericError>
          <Text>
            An error occurred while trying to retrieve the check information.
          </Text>
          <Button onClick={() => refetch()} mt="sm" variant="light">
            Try again
          </Button>
        </GenericError>
      </Box>
    );
  }

  const checkAmount = formatCurrency(formatCents(check.amount));
  const checkStatus = formatCheckStatus(check.status ?? 'Pending');
  const checkInfo = [
    {
      label: 'Amount',
      value: checkAmount,
    },
    {
      label: 'To/From',
      value: check?.counterparty?.name ?? 'N/A',
    },
    {
      label: 'Payment method',
      value: 'Check deposit',
    },
  ];

  const isNegativeStatus = ['Canceled', 'Rejected', 'Returned'].includes(
    checkStatus,
  );

  return (
    <RightContentModal>
      <RightContentModal.Header
        titleSize={40}
        rightTitle={
          <UnstyledButton c="neutral.0" onClick={closeAllModals}>
            <PiXLight size={'2rem'} />
          </UnstyledButton>
        }
        title={<CheckTitle status={checkStatus} />}
        subtitle={getMonthDayYear(check.createdAt)}
      />

      <RightContentModal.Body>
        <Box
          p="md"
          bg="neutral.0"
          sx={(theme) => ({ borderRadius: theme.defaultRadius })}
        >
          {isNegativeStatus && check.reason && (
            <Alert
              mb="sm"
              color="red"
              icon={<PiXCircleBold size={'1rem'} />}
              withCloseButton={false}
              title={`This check was ${checkStatus.toLowerCase()}`}
            >
              {check.reason}
            </Alert>
          )}
          <Flex gap={64}>
            <Box>
              <Text fw={600} c="primarySecondarySuccess.8">
                Status
              </Text>
              <CheckTracker {...{ check, checkTracker }} />
            </Box>
            <Box>
              {checkInfo.map((item) => (
                <Box key={`${item.label}`}>
                  <Text fw={400} size="sm" c="neutral.7" mt="xl">
                    {item.label}
                  </Text>
                  <Text fw={500} c="primarySecondarySuccess.8">
                    {item.value}
                  </Text>
                </Box>
              ))}
            </Box>
          </Flex>
        </Box>

        {account && (
          <Box mt="xxxl">
            <Text size="sm" c="neutral.7">
              Account
            </Text>
            <Text c="primarySecondarySuccess.8">{account}</Text>
          </Box>
        )}

        {checkStatus !== 'Awaiting Images' && <CheckImages check={check} />}

        <Box mt="xxxl">
          <Text size="sm" c="neutral.7">
            Bank description
          </Text>
          <Text c="primarySecondarySuccess.8">{check.description}</Text>
        </Box>
      </RightContentModal.Body>
    </RightContentModal>
  );
};

export default CheckDepositDetails;
