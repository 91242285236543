import { createStyles } from '@mantine/emotion';
import { Button, useMantineTheme, rem } from '@mantine/core';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UnderlinedTabs } from './underlined-tabs';

export type NavTab = {
  label: string;
  route: string;
  disabled?: boolean;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  icon?: ReactNode;
  noHover?: boolean;
  count?: ReactNode;
  stateData?: any;
};
type Props = {
  tabs: NavTab[];
  onTabChange?: (tab: NavTab) => void;
  rightButton?: boolean;
  rightButtonLabel?: string;
  rightButtonFn?: () => void;
  rightButtonIcon?: ReactNode;
};

const useStyles = createStyles((theme) => ({
  activeTabButton: {
    border: 'none',
    backgroundColor: theme.primaryColor,
    borderRadius: rem(100),
  },
  tabContainer: {
    boxSizing: 'border-box',
    display: 'flex',
    backgroundColor: theme.colors.neutral[2],
    gap: rem(32),
    overflow: 'auto',
    scrollbarWidth: 'none',
    width: '100%',
    msOverflowStyle: 'none',
    '&:-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
    padding: rem(5),
    position: 'relative',
  },
  root: {
    backgroundColor: theme.colors.neutral[2],
    padding: `0 ${rem(15)}`,
    borderRadius: rem(100),
    '&[data-disabled]': {
      backgroundColor: '#FAFAFA',
      opacity: 0.5,
    },
  },
  label: {
    fontSize: rem(16),
    fontWeight: 500,
    fontStyle: 'normal',
    color: theme.primaryColor,
    lineHeight: '24px',
  },
  activeTabLabel: {
    lineHeight: '24px',
    color: theme.colors.neutral[2],
  },
  subtle: {
    color: theme.colors.blackish[0],
    border: 'none',
    height: `${rem(32)} !important`,
    alignItems: 'center',
    justifyContent: 'center',
  },
  hover: {
    '&:not([data-disabled])': {
      '&:hover': {
        borderRadius: rem(100),
        backgroundColor: theme.colors.neutral[0],
      },
    },
  },
  activeHover: {
    '&:not([data-disabled])': {
      '&:hover': {
        backgroundColor: theme.colors.primarySecondarySuccess[4],
      },
    },
  },
  noHover: {
    '&:not([data-disabled]):hover': {
      borderRadius: rem(100),
      backgroundColor: 'inherit',
    },
  },
}));

const rightButtonStyle = (primaryColor?: string) => {
  return {
    containerButton: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
    },
    button: {
      root: {
        borderRadius: '8px',
        borderColor: primaryColor,
        height: 40,
        '&:hover': {
          backgroundColor: 'rgba(255, 87, 69, 0.1)',
        },
      },
      inner: { display: 'flex', alignItems: 'center' },
      label: {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: rem(14),
        lineHeight: rem(16),
        color: primaryColor,
      },
    },
  };
};

const NavTabs = ({
  tabs,
  onTabChange,
  rightButtonLabel,
  rightButton = false,
  rightButtonFn,
  rightButtonIcon,
}: Props) => {
  const theme = useMantineTheme();
  const [activeTab, setActiveTab] = useState(tabs[0].route);
  const location = useLocation();
  const navigate = useNavigate();
  const { classes } = useStyles();

  const handleTabChange = (tab: NavTab) => {
    setActiveTab(tab.route);
    if (onTabChange) {
      onTabChange(tab);
    } else {
      navigate(tab.route, { state: tab.stateData });
    }
  };

  useEffect(() => {
    if (!onTabChange) {
      const matchingTab = tabs.find((t) => location.pathname.includes(t.route));
      setActiveTab(matchingTab?.route || '');
    }
  }, [location, onTabChange]);

  return (
    <div className={classes.tabContainer}>
      <UnderlinedTabs
        tabs={tabs}
        selectedTab={activeTab}
        onTabClick={(t) => handleTabChange(t)}
      />

      {rightButton && (
        <div style={rightButtonStyle(theme.primaryColor).containerButton}>
          <Button
            variant="outline"
            size="lg"
            onClick={rightButtonFn}
            leftSection={rightButtonIcon}
            styles={rightButtonStyle(theme.primaryColor).button}
          >
            {rightButtonLabel}
          </Button>
        </div>
      )}
    </div>
  );
};

export default NavTabs;
