import {
  Category,
  ExpenseLink,
  Expenses,
  ExpensesStatusEnum,
  SyncedExpenses,
} from '@flexbase-eng/types/dist/accounting';
import { formatCurrency } from '@utilities/formatters';
import { Avatar, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { TransactionData } from '@services/flexbase/spend-plans.model';
import { formatApiStrings } from '@utilities/formatters/format-api-strings';
import GetPaddedAccountMask from '@utilities/formatters/get-padded-account-mask';
import { SpendTransactionsTableRow } from 'areas/spend-plans/details/tabs/transactions/spend-transaction-table-helpers';
import { FlexIconShort, ReceiptIcon } from 'assets/svg';
import { DateTime } from 'luxon';
import { TableColumn } from 'react-data-table-component';
import { useStyles } from './styles';
import FlexbaseSelect from '@common/composites/flexbase-select';
import { showNotification } from '@mantine/notifications';
import { mapTransactionStatusToBusinessStatus } from '@services/flexbase/flexbase-onboarding-client';
import ExpenseManagement, {
  ActionStatus,
} from '@common/utilities/expense-management/expense-management';
import { getLocaleDate } from '@utilities/formatters/format-date-string';

type Props = {
  syncFeatureEnabled?: boolean;
  onTransactionSync: (txId: string) => void;
  onTransactionAccountChange: (txId: string, accountId: string | null) => void;
  syncedTransactions: SyncedExpenses | undefined;
  expenseLink?: ExpenseLink;
};

export const useSpendPlansTransactionsTableColumns = ({
  syncFeatureEnabled,
  onTransactionSync,
  onTransactionAccountChange,
  syncedTransactions,
  expenseLink,
}: Props) => {
  const { classes, theme } = useStyles();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const connectionId = expenseLink?.connectionId;
  const expenseColumnLabel = syncedTransactions?.accounts?.type;
  const expenseCategories = syncedTransactions?.accounts?.items ?? [];

  const syncedDataMap =
    syncedTransactions?.expenses.reduce<Record<string, Expenses | undefined>>(
      (acc, expense) => {
        acc[expense.transactionId] = expense;
        return acc;
      },
      {},
    ) ?? {};

  const getSyncActionStatus = (row: SpendTransactionsTableRow) => {
    const syncData = syncedDataMap[row.id];

    // no connectionId means there's no integration to sync to
    // no syncData means this row is unsyncable
    if (!connectionId || !syncData) {
      return ActionStatus.disabled;
    }

    // frontend is syncing
    if (row.selectedCategory?.status === 'loading') {
      return ActionStatus.loading;
    }

    const syncedAccountId = syncData.expense?.account?.id;
    const selectedAccountId = row.selectedCategory?.id;

    // user has selected a new category that has not yet synced
    if (selectedAccountId !== syncedAccountId) {
      return ActionStatus.incomplete;
    }

    // check the backend sync state
    if (row.syncedExpense) {
      // queued means things are processing on the backend, waiting for successful status
      if (row.syncedExpense.status === ExpensesStatusEnum.Queued) {
        return ActionStatus.inProgress;
      }

      if (row.syncedExpense.status === ExpensesStatusEnum.Failure) {
        return ActionStatus.failure;
      }
    }

    // no syncedToAccount means user hasn't made any changes
    if (!selectedAccountId) {
      return ActionStatus.disabled;
    }

    return syncData.status === ExpensesStatusEnum.NotSynced
      ? null
      : ActionStatus.complete;
  };

  const getSyncActionTooltip = (row: SpendTransactionsTableRow) => {
    if (!row.syncedExpense) {
      return;
    }

    switch (row.syncedExpense.status) {
      case ExpensesStatusEnum.Failure:
        return `Transaction sync failed. ${row.syncedExpense.description} Please reach out to customer support for assistance.`;
      default:
        return;
    }
  };

  const displayExpenseColumns =
    !!syncFeatureEnabled && !!connectionId && !!expenseCategories.length;

  const columns: TableColumn<TransactionData>[] = [];

  if (displayExpenseColumns) {
    columns.push({
      name: 'Sync',
      cell: (row) => (
        <ExpenseManagement
          type="spend-plans"
          syncActionStatus={getSyncActionStatus(row)}
          syncActionTooltip={getSyncActionTooltip(row)}
          syncAction={() => {
            if (
              !connectionId ||
              !syncFeatureEnabled ||
              !row.selectedCategory?.id
            ) {
              return;
            }

            onTransactionSync(row.id);
          }}
        />
      ),
      center: true,
    });
  }

  columns.push(
    {
      name: 'Date',
      format: (row) =>
        getLocaleDate(DateTime.fromISO(row.date).toFormat('yyyy-MM-dd'), true),
      selector: (row) => row.date,
      sortable: true,
      grow: 12,
    },
    {
      name: 'Amount',
      selector: (row) => formatCurrency(row.amount),
      sortable: true,
    },
    {
      name: 'Payment method',
      selector: (row) => row.paymentMethod,
      sortable: true,
      grow: 10,
    },
    {
      name: 'Card/Account',
      selector: (row) => row.last4.length && GetPaddedAccountMask(row.last4, 4),
      sortable: true,
      compact: true,
      grow: 4,
    },
    {
      name: 'Name',
      selector: (row) => row.who,
      sortable: true,
      grow: 10,
    },
    {
      name: 'Transaction',
      cell: (row) => (
        <div style={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}>
          {!useMobileView && (
            <Avatar mr={15} w={45} h={45}>
              <FlexIconShort />
            </Avatar>
          )}
          <Text truncate="end">{row.transaction}</Text>
        </div>
      ),
      grow: 28,
      sortable: true,
      selector: (row) => row.transaction,
    },
    {
      name: 'Category',
      selector: (row) => row.category,
      sortable: true,
      grow: 18,
    },
    {
      name: 'Invoice',
      cell: (row) => (
        <ReceiptIcon
          color={
            row.docId
              ? theme.colors.primarySecondarySuccess[2]
              : theme.colors.neutral[4]
          }
        />
      ),
      center: true,
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      grow: 15,
      cell: (row) => {
        const status = mapTransactionStatusToBusinessStatus(row);
        let statusColor = '';
        let textColor = '';
        switch (row.status) {
          case 'Incomplete':
            statusColor = theme.colors.critical[0];
            textColor = theme.colors.critical[6];
            break;
          case 'Completed':
            statusColor = theme.colors.primarySecondarySuccess[0];
            textColor = theme.colors.primarySecondarySuccess[6];
            break;
          case 'Needs approval':
            statusColor = theme.colors.tertiary[0];
            textColor = theme.colors.tertiary[6];
            break;
          default:
            statusColor = theme.colors.neutral[1];
            textColor = theme.colors.neutral[7];
        }
        return (
          <div
            className={classes.badge}
            style={{
              backgroundColor: statusColor,
              color: textColor,
            }}
          >
            {status !== 'Settled' ? row.status : formatApiStrings(status)}
          </div>
        );
      },
      sortable: true,
      center: true,
    },
  );

  if (displayExpenseColumns) {
    const categoryLabel = expenseColumnLabel || 'Accounts';
    const data = expenseCategories.map((i) => ({
      value: i.id,
      label: i.name,
    }));

    columns.push({
      name: categoryLabel,
      width: '232px',
      cell: (row) => {
        if (!row.syncedExpense) {
          return null;
        }

        const hasSynced =
          row.syncedExpense.status !== ExpensesStatusEnum.NotSynced;
        const isReadOnly = !expenseLink.modifiable && hasSynced;

        return isReadOnly ? (
          <Text
            onClick={() => {
              showNotification({
                title: `Info`,
                color: 'neutral.1',
                message: `Please visit your integration provider to make changes to the ${categoryLabel}.`,
              });
            }}
          >
            {data.find(
              (account) =>
                row.selectedCategory &&
                account.value === row.selectedCategory.id,
            )?.label || 'Unknown value'}
          </Text>
        ) : (
          <FlexbaseSelect
            data={data}
            searchable
            inputProps={{
              disabled:
                row.syncedExpense.lock ||
                row.selectedCategory?.status === 'loading',
              styles: {
                input: {
                  border: 'none',
                  backgroundColor: 'inherit',
                  color: theme.colors.primarySecondarySuccess[8],
                  '&[data-disabled]': {
                    backgroundColor: 'inherit',
                  },
                  textOverflow: 'ellipsis',
                },
              },
            }}
            placeholder={`Choose ${categoryLabel}`}
            value={
              row.selectedCategory?.id || row.syncedExpense.expense?.account?.id
            }
            onChange={(value) => onTransactionAccountChange(row.id, value)}
          />
        );
      },
    });
  }
  return columns;
};

export const mapTransactionTableRow = (
  transaction: TransactionData,
  index: number,
  {
    syncedExpense,
    expenseCategories,
  }: {
    syncedExpense?: Expenses;
    expenseCategories?: Category[];
  } = {},
  txAccountChange?: any, // This is any because the SyncToAccountChange type is defined as part of an object and is not avail
) => {
  const syncedCategoryId =
    txAccountChange?.data?.accountId || syncedExpense?.expense?.account?.id;
  const syncedCategoryName = expenseCategories?.find(
    (c) => c.id === syncedCategoryId,
  )?.name;
  const selectedCategory = syncedCategoryId
    ? {
        id: syncedCategoryId,
        name: syncedCategoryName,
        status: txAccountChange?.status,
      }
    : undefined;

  const row: SpendTransactionsTableRow = {
    id: transaction.id,
    date: transaction.date,
    amount: transaction.amount,
    paymentMethod: transaction.paymentMethod,
    last4: transaction.last4,
    who: transaction.who,
    transaction: transaction.transaction,
    category: transaction.category,
    status: transaction.status,
    docId: transaction.docId,
    keyField: transaction.id + index,
    syncedExpense: syncedExpense,
    selectedCategory: selectedCategory,
  };

  return row;
};
