import { Box, Group, Text } from '@mantine/core';
import { useGetCreditOrBankingStatements } from '@queries/use-statements';
import { useGetDepositAccounts } from '@queries/use-deposit-accounts';
import { useState } from 'react';
import type { DepositAccount } from '../../../types/move-funds.model';
import { useStyles } from '../styles';
import StatementsAccountSelection from './statements-account-selection';
import StatementsTable from './statements-table';
import StatementsYearSelection from '@common/statements-year-selection';
import SkeletonLoading from '@common/composites/loading/skeleton-loading';
import GenericError from '../../../components/generic-error';
import { useParams } from 'react-router-dom';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import { useRecoilValue } from 'recoil';
import { filterStatementsByYear, getYears } from '@utilities/statements';

const Statements = () => {
  const { classes } = useStyles();
  const { id: depositAccountId } = useParams();
  const { accountId } = useRecoilValue(ApplicationState);
  const {
    data: depositAccountsData,
    isLoading: isLoadingDepositAccounts,
    isError: isErrorDepositAccounts,
  } = useGetDepositAccounts();

  const accounts =
    depositAccountsData?.accounts
      .map((a) => ({
        ...a,
        plaidOrDeposit: 'deposit' as any,
      }))
      .sort((a, b) => b.balance - a.balance) ?? [];

  let initialAccount: DepositAccount;
  if (depositAccountId) {
    initialAccount = accounts.find(
      (account) => account.id === depositAccountId,
    ) as DepositAccount;
  } else {
    initialAccount = accounts[0];
  }

  const [selectedAccount, setSelectedAccount] =
    useState<DepositAccount>(initialAccount);

  const {
    data: statementsList,
    isLoading: isStatementsLoading,
    isError: isStatementsError,
  } = useGetCreditOrBankingStatements(
    accountId,
    'banking',
    selectedAccount?.id,
  );

  const selectedAccountStatements = statementsList ?? [];
  const listOfYears = getYears(selectedAccountStatements);

  const [year, setYear] = useState('');
  const chosenYear = year ? year : listOfYears[0];

  const handleYearChange = (yearChoosen: string | null) => {
    setYear(yearChoosen || '');
  };

  const statementsByYear = filterStatementsByYear(
    chosenYear,
    selectedAccountStatements,
  ).reverse();

  const loading = isLoadingDepositAccounts || isStatementsLoading;
  const hasError = isErrorDepositAccounts || isStatementsError;

  if (hasError) {
    return (
      <GenericError>
        <Text>We&#8217;re unable to get your statements at the moment.</Text>
      </GenericError>
    );
  }

  return (
    <Box mt="md">
      <Box className={classes.card}>
        {loading ? (
          <SkeletonLoading />
        ) : (
          <>
            <Group justify={depositAccountId ? 'right' : 'space-between'}>
              {!depositAccountId && (
                <StatementsAccountSelection
                  onChange={setSelectedAccount}
                  selectedAccount={selectedAccount}
                  accounts={accounts}
                />
              )}
              <StatementsYearSelection
                listOfYears={listOfYears}
                yearSelected={chosenYear}
                onYearChange={handleYearChange}
              />
            </Group>
            <StatementsTable
              statements={statementsByYear}
              selectedAccount={selectedAccount}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default Statements;
