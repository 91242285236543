import { createStyles } from '@mantine/emotion';

export const useModalStyles = createStyles((theme, _, helpers) => ({
  headerBar: {
    backgroundColor: theme.colors.primarySecondarySuccess[8],
    color: theme.colors.neutral[0],
    height: 152,
    padding: 32,
    [helpers.smallerThan('sm')]: {
      paddingTop: 16,
    },
  },
  backButtonBar: {
    width: '100%',
    backgroundColor: theme.colors.primarySecondarySuccess[8],
    paddingTop: 8,
    paddingLeft: 16,
  },
  iconContainer: {
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
  },
  headerTextContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing.md,
  },
}));
