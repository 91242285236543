import { useEffect } from 'react';
import useModal from '@common/composites/modal/modal-hook';
import SendPayment from './send-payment';

const SendPaymentPage = () => {
  const { openTransparentModal } = useModal();

  useEffect(() => {
    openTransparentModal(<SendPayment />);
  }, []);

  return false;
};

export default SendPaymentPage;
