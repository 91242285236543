import { Box, Image, Loader, Text } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE, PDF_MIME_TYPE } from '@mantine/dropzone';
import { useDetailsStyles } from '../details-styles';
import { useState } from 'react';
import { IsAccountant } from '../../../../../../recoil-state/application/product-onboarding';
import { useRecoilValue } from 'recoil';
import { useStyles } from '../transaction-details.styles';
import {
  PiArrowSquareOutLight,
  PiDownloadSimple,
  PiTrash,
  PiX,
} from 'react-icons/pi';

type Props = {
  loadingReceipt?: boolean;
  receiptInfo?: {
    url: string;
    thumbnailUrl: string | null;
    extension: string | undefined;
  };
  disputeOpen?: boolean;
  receiptRef: React.RefObject<HTMLDivElement>;
  unlinkReceiptFile: () => Promise<void>;
  addFile: (files: File[]) => Promise<void>;
};

type PropsChildren = {
  disputeOpen?: boolean;
};

export const DropzoneChildren = ({ disputeOpen }: PropsChildren) => {
  const { classes } = useStyles({
    flagMenuHeight: disputeOpen ? '235px' : '115px',
  });

  return (
    <div data-testid="file-container">
      <div>
        <Image
          width={32}
          height={32}
          alt="no-invoice-image"
          src="/images/upload-cloud.svg"
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '30px',
          }}
        />
        <Text className={classes.dropZonePlaceholder}>
          Drag and drop file, or&nbsp;<span>browse</span>&nbsp;to add your image
        </Text>
      </div>
    </div>
  );
};

export const DropZone = ({
  loadingReceipt,
  receiptInfo,
  disputeOpen,
  receiptRef,
  unlinkReceiptFile,
  addFile,
}: Props) => {
  const { classes: detailsClasses, theme } = useDetailsStyles();
  const { classes } = useStyles({
    flagMenuHeight: disputeOpen ? '235px' : '115px',
  });
  const [receiptOpened, setReceiptOpened] = useState(false);
  const isAccountant = useRecoilValue(IsAccountant);

  const downloadReceipt = (url: string, name: string, extension: string) => {
    const alink = document.createElement('a');
    alink.href = url;
    alink.download = `${name}.${extension}`;
    alink.click();
  };

  return (
    <div>
      <Box mb="xs" ref={receiptRef}>
        <Text className={detailsClasses.infoTitle}>Upload receipt</Text>
      </Box>
      {loadingReceipt ? (
        <div className={classes.dropZone}>
          <Loader color="green" />
        </div>
      ) : receiptInfo ? (
        <div className={classes.dropZone}>
          <div>
            <button
              type="button"
              onClick={() => {
                setReceiptOpened((p) => !p);
              }}
            >
              <PiArrowSquareOutLight
                size={'1.125rem'}
                color={theme.colors.blackish[0]}
              />
            </button>
            <button
              disabled={isAccountant}
              type="button"
              onClick={() => unlinkReceiptFile()}
            >
              <PiTrash size={'1.125rem'} color={theme.colors.failure[1]} />
            </button>
          </div>

          {!receiptOpened && (
            <Image
              width={90}
              height={90}
              src={receiptInfo.thumbnailUrl}
              radius="sm"
              style={{ cursor: 'pointer' }}
            />
          )}

          {receiptOpened ? (
            <div
              style={{
                top: '-18rem',
                zIndex: 1,
                position: 'absolute',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  width: '100%',
                }}
              >
                <div
                  className={classes.roundIcons}
                  onClick={() => {
                    if (receiptInfo.extension) {
                      downloadReceipt(
                        receiptInfo.url,
                        'receipt',
                        receiptInfo.extension,
                      );
                    }
                  }}
                >
                  <PiDownloadSimple
                    size={'1.25rem'}
                    color={theme.colors.contentBackground[2]}
                  />
                </div>
                <div
                  className={classes.roundIcons}
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    setReceiptOpened(false);
                  }}
                >
                  <PiX
                    size={'1.25rem'}
                    color={theme.colors.contentBackground[2]}
                  />
                </div>
              </div>

              <Image
                alt="invoice-image"
                mt="1vh"
                src={receiptInfo.thumbnailUrl}
                style={{ cursor: 'pointer', width: '100%' }}
              />
            </div>
          ) : null}

          <Text className={classes.dropZonePlaceholder}>
            Drag and drop file, or&nbsp;<span>browse</span>
            &nbsp;to add your image or PDF
          </Text>
        </div>
      ) : (
        <Dropzone
          disabled={isAccountant}
          onDrop={(files) => addFile(files)}
          data-testid="upload-image"
          maxSize={3 * 1024 ** 2}
          accept={[...IMAGE_MIME_TYPE, ...PDF_MIME_TYPE]}
          multiple={false}
          classNames={{
            root: classes.dropZone,
          }}
        >
          <DropzoneChildren disputeOpen={disputeOpen} />
        </Dropzone>
      )}
    </div>
  );
};
