import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  webContainer: {
    marginTop: 48,
    paddingBottom: 24,
    marginLeft: 'auto',
    marginRight: 'auto',
    flex: 1,
  },
  mobileContainer: {
    width: '100%',
    padding: '20px 16px 0 16px',
    margin: 'unset',
  },
  header: {
    padding: 32,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '@media(max-width: 767px)': {
      padding: 16,
    },
  },
  footerContainer: {
    backgroundColor: theme.colors.neutral[2],
  },
  footerDisclosure: {
    color: theme.colors.primary,
  },
  scrollContainer: {
    backgroundColor: theme.colors.neutral[2],
  },
  notices: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10rem',
    '@media(max-width: 767px)': {
      marginLeft: 'auto',
    },
  },
  headerSupport: {
    display: 'flex',
    gap: 16,
    cursor: 'pointer',
  },
  headerImage: {},
  headerText: {
    color: theme.colors.neutral[6],
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'PP Neue Montreal',
    alignItems: 'center',
    display: 'flex',
    gap: 8,
  },
  progressSaved: {
    color: theme.colors.primarySecondarySuccess[6],
    '@media(max-width: 550px)': {
      svg: {
        marginRight: 0,
      },
    },
  },
  creditImpactNotice: {
    '@media(max-width: 767px)': {
      justifyContent: 'center',
      svg: {
        marginRight: -7,
      },
    },
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 32,
  },
  headerBnpl: {
    cursor: 'default',
    alignItems: 'center',
  },
  banner: {
    backgroundColor: '#F4F4F4',
    width: '100%',
    height: '217px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 14,
  },
  progressRoot: {
    backgroundColor: theme.colors.neutral[1],
  },
  containerLogout: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    '@media(max-width: 767px)': {
      gap: 10,
    },
  },
  amount: {
    padding: theme.spacing.xl,
    border: `1px solid ${theme.colors.neutral[3]}`,
    background: 'white',
    width: '575px',
  },
  amountMobile: {
    padding: theme.spacing.xl,
    border: `1px solid ${theme.colors.neutral[3]}`,
    background: 'white',
  },
  amountTitle: {
    color: theme.colors.primarySecondarySuccess[8],
    fontFamily: 'Redaction',
    fontSize: theme.spacing.xxxl,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '48px',
  },
  amountSubtitle: {
    color: theme.colors.primarySecondarySuccess[8],
    fontFamily: 'PP Neue Montreal',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  amountRedemptionRadio: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    marginTop: '20px',
  },
  amountRedemptionRadioSelected: {
    border: `2px solid ${theme.colors.primarySecondarySuccess[6]}`,
    borderRadius: '8px',
  },
  amountRedemptionRadioUnselected: {
    border: `1px solid ${theme.colors.neutral[6]}`,
    borderRadius: '8px',
  },
  amountRadioTextBold: {
    fontFamily: 'PP Neue Montreal',
    fontSize: theme.spacing.md,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '150%',
  },
  amountRadioTextLight: {
    color: theme.colors.neutral[6],
    fontFamily: 'PP Neue Montreal',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 200,
    lineHeight: '20px',
  },
  redemptionBackButton: {
    color: theme.colors.primarySecondarySuccess[4],
    borderColor: theme.colors.primarySecondarySuccess[4],
    fontFamily: 'PP Neue Montreal',
    fontSize: theme.spacing.md,
    fontStyle: 'normal',
    fontWeight: 500,
  },
  redemptionForwardButton: {
    fontFamily: 'PP Neue Montreal',
    fontSize: theme.spacing.md,
    fontStyle: 'normal',
    fontWeight: 500,
  },
  redemptionNavigationButtons: {
    marginTop: '2.5rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  successNavigationButtons: {
    marginTop: '2.5rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  redemptionYellowBox: {
    display: 'inline-block',
    width: '50%',
    backgroundColor: theme.colors.neutral[2],
    boxSizing: 'border-box',
    padding: `${theme.spacing.xl} ${theme.spacing.xxxl} ${theme.spacing.xl} ${theme.spacing.xxxl}`,
    marginTop: '20px',
    height: '155px',
  },
  redemptionLeftBox: {
    borderRight: `1px solid ${theme.colors.neutral[3]}`,
    float: 'left',
    borderRadius: `${theme.spacing.sm} 0 0 ${theme.spacing.sm}`,
  },
  redemptionRightBox: {
    borderRadius: `0 ${theme.spacing.sm} ${theme.spacing.sm} 0`,
  },
  redemptionIconDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
  },
  redemptionIcon: {
    height: '36px',
    width: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: theme.spacing.xxxl,
  },
  redemptionInputTitle: {
    fontFamily: 'PP Neue Montreal',
    fontSize: theme.spacing.md,
    fontStyle: 'normal',
    lineHeight: 'normal',
    fontWeight: 400,
    color: theme.colors.neutral[8],
  },
  redemptionBigText: {
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    color: theme.colors.primarySecondarySuccess[8],
  },
  reviewYellowBox: {
    padding: theme.spacing.xxxl,
    width: '100%',
    backgroundColor: theme.colors.neutral[2],
    borderRadius: theme.spacing.sm,
  },
  reviewLine1: {
    fontFamily: 'PP Neue Montreal',
    fontSize: theme.spacing.md,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    color: theme.colors.neutral[8],
    textAlign: 'center',
  },
  reviewLine2: {
    fontFamily: 'PP Neue Montreal',
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    color: theme.colors.primarySecondarySuccess[8],
    textAlign: 'center',
  },
  reviewLine3: {
    fontFamily: 'PP Neue Montreal',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    color: theme.colors.neutral[7],
    textAlign: 'center',
  },
  reviewDepositLine: {
    fontFamily: 'PP Neue Montreal',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    color: theme.colors.neutral[7],
    marginTop: '20px',
  },
  reviewSuccessIconDiv: {
    borderRadius: '50%',
    border: `2px solid ${theme.colors.primarySecondarySuccess[2]}`,
    backgroundColor: theme.colors.primarySecondarySuccess[0],
    width: '80px',
    height: '80px',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  reviewSuccessText: {
    fontFamily: 'Redaction',
    fontSize: theme.spacing.xxxl,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    textAlign: 'center',
    marginTop: theme.spacing.md,
  },
  reviewSuccessSubtext: {
    fontFamily: 'PP Neue Montreal',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    color: theme.colors.neutral[7],
    textAlign: 'center',
    marginTop: theme.spacing.md,
  },
}));
