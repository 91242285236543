import { rem, Text } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { InfoCircle } from '../../../assets/svg';
import { ReactNode } from 'react';

type Props = {
  text: string | ReactNode;
};

const YellowBanner = ({ text }: Props) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <InfoCircle className={classes.icon} />
      <Text className={classes.text}>{text}</Text>
    </div>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    backgroundColor: theme.colors.promote[0],
    border: `1px solid ${theme.colors.promote[2]}`,
    display: 'flex',
    gap: theme.spacing.md,
    alignItems: 'center',
    padding: `${theme.spacing.md} ${theme.spacing.sm}`,
    borderRadius: theme.defaultRadius,
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl,
    width: '100%',
  },
  icon: {
    color: theme.colors.promote[3],
    width: rem(24),
    minWidth: rem(24),
  },
  text: {
    color: theme.colors.primarySecondarySuccess[8],
    fontSize: rem(14),
    '@media(max-width: 767px)': {
      fontSize: rem(12),
    },
  },
}));

export default YellowBanner;
