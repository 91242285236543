import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(() => ({
  closeButton: {
    display: 'flex',
    width: '34px',
    boxSizing: 'content-box',
    flexDirection: 'column',
    color: '#979797',
    cursor: 'pointer',
    borderRadius: '0.5rem',
    alignItems: 'center',
    backgroundColor: 'unset',
    border: '1px solid transparent',
    '&:focus': {
      outline: 'none',
    },
  },
  memoInput: {
    padding: 0,
    paddingLeft: 12,
    paddingTop: 10,
  },
}));
