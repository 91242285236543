import { EliteLogo } from 'assets/svg';
import { ReactElement, useMemo } from 'react';
import { IconType } from 'react-icons';
import { UserRole } from 'types/user-info';
import {
  useBillPayCreditFlag,
  useBillPayFeatureFlag,
  useSpendPlansFeatureFlag,
} from '@utilities/feature-flags';
import {
  ApplicationState,
  OptedProduct,
} from 'recoil-state/application/product-onboarding';
import { useCreditLines } from '@queries/use-credit-lines';
import { useRecoilValue } from 'recoil';
import { Line } from '@services/flexbase/credit.model';
import { ApplicationStatus } from 'recoil-state/application/product-onboarding.models';
import {
  PiBank,
  PiCreditCard,
  PiCurrencyCircleDollar,
  PiFolder,
  PiGear,
  PiShieldCheck,
  PiUsersThree,
  PiVault,
  PiWallet,
} from 'react-icons/pi';
import { useMantineTheme } from '@mantine/core';

/**
 * Hack to allow users with approved credit but limit = 0 to access credit page.
 */
function isLineOfCreditClosed(line: Line, productStatus: ApplicationStatus) {
  let isClosed = line.status === 'Closed';

  const appApproved = productStatus.credit.appStatus === 'approved';
  const creditLimit = parseFloat(productStatus.credit.creditLimit ?? '-1');
  const validLimit = !isNaN(creditLimit) && creditLimit >= 0;

  if (appApproved && validLimit) {
    isClosed = false;
  }

  return isClosed;
}

const useActiveCreditPrograms = (): SubMenuOption[] => {
  const { businessId, productStatus } = useRecoilValue(ApplicationState);
  const { data } = useCreditLines(businessId);
  const billPayCreditEnabled = useBillPayCreditFlag();

  const linesOfCredit = Array.isArray(data) ? data : [];

  const subRouteLabel: Record<Line['issuer'], string> = {
    lithic: 'Net-60 card',
    marqeta: 'Net-60 card (New)',
    unit: 'Charge card',
    billpay: 'Bill pay credit',
  };
  const subRouteLocation: Record<Line['issuer'], string> = {
    lithic: '/cards',
    marqeta: '/cards', // TODO: new route
    unit: '/charge-card',
    billpay: '/bill-pay-credit',
  };

  const activeCreditPrograms = useMemo(() => {
    const filteredPrograms = linesOfCredit
      .filter((item) => !isLineOfCreditClosed(item, productStatus))
      .filter((item) => item.issuer !== 'billpay' || billPayCreditEnabled)
      .reduce<Line[]>((acc, curr, _idx, all) => {
        const allIssuerLines = all.filter((l) => l.issuer === curr.issuer);
        const activeLines = allIssuerLines.filter((l) => l.status !== 'Closed');
        const activeLine = activeLines[0] || allIssuerLines[0] || curr;
        const isActiveLine = curr.id === activeLine.id;

        if (isActiveLine) {
          acc.push(curr);
        }

        return acc;
      }, [])
      .map<SubMenuOption>((item) => ({
        key: item.id,
        label: subRouteLabel[item.issuer] || 'Unknown',
        location: subRouteLocation[item.issuer] || '/',
      }));

    return filteredPrograms;
  }, [linesOfCredit]);

  return activeCreditPrograms;
};

export type SubMenuOption = {
  key: string;
  label: string;
  location: string;
  disabled?: boolean;
};

export type MenuOption = {
  label: string;
  location: string;
  icon: ReactElement<IconType>;
  disabled?: boolean;
  subRoutes?: SubMenuOption[];
  onClick?: () => void;
  unclickable?: boolean;
  devOnly?: boolean;
  adminOnly?: boolean;
  rolesAllowed?: UserRole[];
  activeIcon: ReactElement<IconType>;
  product: OptedProduct | null;
};

const getNavbarOptions = (
  featureFlags: Record<string, boolean>,
  linesOfCreditSubRoutes: SubMenuOption[],
): MenuOption[] => {
  const theme = useMantineTheme();
  return [
    {
      label: 'Treasury Management',
      location: '/accounts',
      icon: <PiVault />,
      activeIcon: <PiVault color={theme.colors.critical[2]} />,
      product: 'TREASURY',
    },
    {
      label: 'Credit',
      location: '/cards',
      icon: <PiCreditCard />,
      activeIcon: <PiCreditCard color={theme.colors.critical[2]} />,
      product: 'CREDIT',
      subRoutes:
        linesOfCreditSubRoutes.length > 0 ? linesOfCreditSubRoutes : undefined,
    },
    {
      label: 'Banking',
      location: '/banking',
      icon: <PiBank />,
      rolesAllowed: ['ADMIN', 'ACCOUNTANT'],
      activeIcon: <PiBank color={theme.colors.critical[2]} />,
      product: 'BANKING',
    },
    {
      label: 'Spend plans',
      location: '/spend-plans',
      icon: <PiCurrencyCircleDollar />,
      activeIcon: <PiCurrencyCircleDollar color={theme.colors.critical[2]} />,
      product: null,
      disabled: !featureFlags.spendPlansEnabled,
    },
    {
      label: 'Payments & Bill Pay',
      location: '',
      icon: <PiWallet />,
      rolesAllowed: ['ADMIN', 'ACCOUNTANT', 'COMPTROLLER'],
      activeIcon: <PiWallet color={theme.colors.critical[2]} />,
      product: 'BANKING',
      subRoutes: [
        {
          key: 'payments',
          label: 'Payments',
          location: '/payments',
        },
        {
          key: 'billpay',
          label: 'Bill pay',
          location: '/bill-pay',
          disabled: !featureFlags.billPayEnabled,
        },
      ],
    },
    {
      label: 'Insurance',
      location: '/insurance',
      icon: <PiShieldCheck />,
      activeIcon: <PiShieldCheck color={theme.colors.critical[2]} />,
      disabled: true,
      unclickable: true,
      product: null, // We don't yet have a specific insurance product.
    },
    {
      label: 'Points and Rewards',
      location: '/benefits-and-rewards',
      disabled: true,
      icon: <EliteLogo />,
      rolesAllowed: ['ADMIN'],
      activeIcon: <EliteLogo />,
      product: null,
    },
  ];
};

/**
 * Modify this to add feature-flagged menu options
 */
export const useTopNavbarOptions = () => {
  const spendPlansEnabled = useSpendPlansFeatureFlag();
  const billPayEnabled = useBillPayFeatureFlag();
  const billPayCreditEnabled = useBillPayCreditFlag();

  const activeCreditPrograms = useActiveCreditPrograms();

  const NAVBAR_OPTIONS = getNavbarOptions(
    {
      spendPlansEnabled,
      billPayEnabled,
      billPayCreditEnabled,
    },
    activeCreditPrograms,
  );

  return NAVBAR_OPTIONS;
};

export const getBottomNavbarOptions = (): MenuOption[] => {
  const theme = useMantineTheme();
  return [
    {
      label: 'Documents',
      location: '/documents',
      icon: <PiFolder />,
      activeIcon: <PiFolder color={theme.colors.critical[2]} />,
      product: null,
      adminOnly: true,
      devOnly: true,
    },
    {
      label: 'Team',
      location: '/team',
      icon: <PiUsersThree />,
      activeIcon: <PiUsersThree color={theme.colors.critical[2]} />,
      adminOnly: true,
      product: null,
    },
    {
      label: 'Settings',
      location: '/settings',
      icon: <PiGear />,
      activeIcon: <PiGear color={theme.colors.critical[2]} />,
      product: null,
    },
  ];
};
