import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  card: {
    padding: theme.spacing.xl,
    color: theme.colors.neutral[1],
    borderRadius: theme.defaultRadius,
    position: 'relative',
    backgroundColor: '#fff',
    border: `1px solid ${theme.colors.neutral[1]}`,
    marginTop: theme.spacing.lg,
  },
  containerVendor: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
  },
  rowActionIcon: {
    fill: '#979797',
    cursor: 'pointer',
  },
  deleteRecipientOption: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.critical[6],
  },
  viewRecipientOption: {
    display: 'flex',
    alignItems: 'center',
  },
}));
