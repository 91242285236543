import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

type NavProps = {
  navbarOpen?: boolean;
};

const useStyles = createStyles((theme, { navbarOpen }: NavProps, helpers) => ({
  fullScreen: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'start',
    '@media(max-width: 767px)': {
      width: '100%',
    },
  },
  navbar: {
    position: 'fixed',
    flexBasis: '75px',
    width: '75px',
    overflowX: 'hidden',
    backgroundColor: theme.colors.primarySecondarySuccess[8],
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    padding: '0 0 20px 0',
    zIndex: 199, //Needs to be under 200 or else Mantine modals will appear over it
    transition: 'width 200ms ease',
    [`& .${helpers.ref('option')}`]: {
      transition: 'background-color 200ms ease',
    },
    '@media(max-width: 767px)': {
      height: 'calc(100vh - 60px)',
      width: navbarOpen ? '100%' : 0,
      overflowX: 'hidden',
      [`& .${helpers.ref('option')}`]: {
        width: '100%',
      },
    },
    '@media(min-width: 768px)': {
      '&:hover': {
        width: '312px',
        flexBasis: '312px',
        [`& .${helpers.ref('option')}`]: {
          width: '273px',
        },
        [`& .${helpers.ref('subRoute')}`]: {
          marginLeft: rem(62),
          paddingLeft: theme.spacing.xs,
          display: 'flow !important',
          borderLeft: `1px solid ${theme.colors.primarySecondarySuccess[5]}`,
        },
      },
    },
  },
  icon: {
    width: rem(60),
    color: theme.colors.primarySecondarySuccess[1],
  },
  logoSection: {
    width: '273px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    color: theme.colors.neutral[0],
    marginTop: 12,
  },
  nameSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: '12px',
    width: '100%',
    color: theme.colors.neutral[0],
  },
  nameText: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16px',
    color: theme.colors.primarySecondarySuccess[1],
    wordWrap: 'break-word',
    letterSpacing: '0.01em',
    fontFamily: 'PP Neue Montreal',
    '@media(max-width: 767px)': {
      color: theme.primaryColor,
    },
  },
  // Note I did not extract all common styles yet
  menuOptionCommon: {
    ref: helpers.ref('option'),
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    borderRadius: theme.defaultRadius,
    whiteSpace: 'nowrap',
    marginTop: 20,
  },
  menuOption: {
    ref: helpers.ref('option'),
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    backgroundColor: 'none',
    color: theme.colors.neutral[0],
    '&:hover': {
      backgroundColor: theme.colors.primarySecondarySuccess[4],
    },
  },
  highlightedMenuOption: {
    ref: helpers.ref('option'),
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    backgroundColor: theme.colors.primarySecondarySuccess[4],
    whiteSpace: 'nowrap',
    color: theme.colors.neutral[0],
  },
  notClickableMenuOption: {
    ref: helpers.ref('option'),
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    backgroundColor: 'none',
    opacity: 0.5,
    pointerEvents: 'none',
  },
  notClickableHighlightedMenuOption: {
    ref: helpers.ref('option'),
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    backgroundColor: '#FFE6E3',
    whiteSpace: 'nowrap',
    opacity: 0.5,
    pointerEvents: 'none',
  },
  subroute: {
    ref: helpers.ref('subRoute'),
    display: 'none !important',
  },
  menuSuboption: {
    fontFamily: 'PP Neue Montreal',
    fontSize: rem(16),
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: rem(20),
    cursor: 'pointer',
    color: theme.colors.neutral[0],
    '&:hover': {
      borderRadius: theme.defaultRadius,
      backgroundColor: theme.colors.primarySecondarySuccess[4],
    },
    transition: 'background-color 200ms ease',
  },
  highlightedMenuSuboption: {
    backgroundColor: theme.colors.primarySecondarySuccess[4],
  },
  disabledMenuOption: {
    display: 'none',
  },
  menuOptionIcon: {
    alignSelf: 'end',
    marginLeft: 8,
    color: theme.colors.neutral[2],
  },
  navbarOptions: {
    marginLeft: '17.5px',
    marginRight: '17.5px',
    marginBottom: 'auto',
    '&:last-of-type': {
      marginBottom: 0,
    },
    '@media(max-width: 767px)': {
      width: 'calc(100vw - 100px)',
    },
  },
  pageDiv: {
    height: '100%',
    overflow: 'auto',
    flexBasis: '100vw',
    marginLeft: '75px',
    scrollbarGutter: 'stable both-edges',
    '> div:first-of-type': {
      margin: 'auto',
      maxWidth: '1368px',
    },
  },
  avatar: {
    height: '40px',
    width: '100px',
    color: theme.primaryColor,
  },
  txtComingSoon: {
    background: theme.colors.tertiary[0],
    borderRadius: '9px',
    padding: `0 ${theme.spacing.xs}`,
    marginLeft: '1rem',
    fontSize: '10px',
    color: theme.primaryColor,
    '@media(max-width: 767px)': {
      marginLeft: '5px',
    },
  },
  companyContainer: {
    height: '65px',
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    color: theme.colors.neutral[0],
    '@media(max-width: 767px)': {
      width: 'calc(100vw - 100px)',
    },
  },
  optionAccountName: {
    fontFamily: theme.fontFamily,
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.5rem',
    alignItems: 'center',
    color: theme.colors.neutral[0],
  },
  selectedCompanyName: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'PP Neue Montreal',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.5rem',
    color: theme.colors.neutral[0],
    width: '100%',
    '@media(max-width: 767px)': {
      width: '60%',
    },
  },
  dropdownOptionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
  accountContainer: {
    backgroundColor: theme.colors.primarySecondarySuccess[7],
    borderBottom: `1px solid ${theme.colors.primarySecondarySuccess[4]}`,
    width: '100%',
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.colors.neutral[3]}`,
    padding: theme.spacing.md,
  },
  currentAccountContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  optionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 15,
    cursor: 'pointer',
  },
  row: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
}));

export default useStyles;
