import { keyframes } from '@mantine/emotion';

export const spinCounterClockwise = keyframes({
  from: {
    transform: 'rotate(0deg)',
  },
  to: {
    transform: 'rotate(-179deg)',
  },
});
