import {
  Context,
  createContext,
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { isFunction } from 'underscore';

/**
 * Record<string, any> allows you to set arbitrary data at a step level.
 * For example you could write a wrapper component to display conditional elements without worrying about implementation.
 *
 * @example
 * const steps = [
 *   {
 *     metadata: {
 *       description: 'Enter the code',
 *       buttonFollower: <ResendCode />,
 *     },
 *   },
 *   {
 *     metadata: {
 *       description: 'This is step one',
 *       footer: <Footer />,
 *     },
 *   },
 * ];
 *
 * <Box>
 *   <Button>Submit</Button>
 *
 *   // instead of
 *   {step.id === 'one' && <ResendCode />}
 *   {step.id === 'two' && <Footer />}
 *
 *   // can do this
 *   {step.metadata.buttonFollower}
 *   {step.metadata.footer}
 * </Box>
 */
type StepMetaData = { title: ReactNode; id: string } & Record<string, any>;

type BaseStep<TState = any> = {
  metadata: StepMetaData;
  condition?: (state: TState) => boolean;
};

export type RouteStep<TState = any> = BaseStep<TState> & { route: string };

export type ComponentStep<TState = any> = BaseStep<TState> & {
  element: ReactNode;
};

type StateUpdateFn<T> = (previousValue: T) => T;

export type StateUpdater<T> = Partial<T> | StateUpdateFn<T>;

export type MultiStepFormContextReturnType<TState, TStep> = {
  state: TState;

  /**
   * Gets the current state even within the same render cycle.
   *
   * Useful if you make changes to the state and need to access the updated values within the same render cycle.
   */
  getState: () => TState;
  resetState: () => void;
  stepList: TStep[];
  stepConfig: TStep[];
  currentStepIndex: number;
  currentStep: TStep | null;
  hasPreviousStep: boolean;
  hasNextStep: boolean;
  progress: number;
  visitedSteps: Record<string, boolean>;
  navigationContext: Record<string, any>;
  hasVisitedStep: (id: string) => boolean;
  getLastVisitedStep: () => TStep | undefined;
  setState: (updater: StateUpdater<TState>) => void;
  goToNextStep: (
    updater?: StateUpdater<TState>,
    extras?: Record<string, any>,
  ) => void;
  goToPreviousStep: (
    updater?: StateUpdater<TState>,
    extras?: Record<string, any>,
  ) => void;
  goToStep: (
    id: string,
    updater?: StateUpdater<TState>,
    extras?: Record<string, any>,
  ) => void;
};

type MultiStepFormProviderProps<TState, TStep> = {
  children: ReactNode;
  initialState: TState;
  steps: TStep[];
};

function isRouteStep<TState>(step: any): step is RouteStep<TState> {
  return !!step && typeof step['route'] === 'string';
}

export function computeProgress(idx: number, count: number) {
  if (count === 0) {
    return 0;
  }

  const progressFloat = (100 * (idx + 1)) / count;

  if (progressFloat < 0) {
    return 0;
  } else if (progressFloat > 0 && progressFloat < 1) {
    return 1;
  } else if (progressFloat > 99 && progressFloat < 100) {
    return 99;
  } else if (progressFloat > 100) {
    return 100;
  } else {
    return Math.round(progressFloat);
  }
}

export function computeStepList<TState, TStep extends BaseStep<TState>>(
  stepConfig: TStep[],
  state: TState,
) {
  return stepConfig.filter((step) => {
    return isFunction(step.condition) ? step.condition(state) : true;
  });
}

/**
 * Based on the given state, compute the step after traversing the given number of steps or based on the given step ID.
 *
 * @param originStep If traverse count is passed, this is used as the starting point before traversal.
 * @param state The state used to compute the steps.
 * @param stepConfig The config used to compute the steps.
 * @param {number | string} target Either a step ID or a number of steps to traverse eg. 'abc' computes a step with ID: 'abc', 1 computes 1 step forward, -2 computes 2 steps back
 */
export function computeStep<TState, TStep extends BaseStep<TState>>(
  originStep: TStep | null,
  state: TState,
  stepConfig: TStep[],
  target: number | string,
) {
  const originStepId = originStep?.metadata.id;
  const stepList = computeStepList(stepConfig, state);

  // if target is an ID
  if (typeof target === 'string') {
    return stepList.find((step) => step.metadata.id === target);
  }

  // if target doesn't traverse
  // note: this could be undefined if the origin step was filtered out
  if (target === 0) {
    return stepList.find((step) => step.metadata.id === originStepId);
  }

  const originIdx = stepConfig.findIndex(
    (config) => config.metadata.id === originStepId,
  );

  if (target > 0) {
    // get all unfiltered steps after the origin step, then filter those
    const unfilteredSteps = stepConfig.slice(originIdx + 1);
    const filteredSteps = computeStepList(unfilteredSteps, state);

    // apply the traversal count
    return filteredSteps[target - 1];
  } else {
    // get all unfiltered steps before the origin step, then filter those
    const unfilteredSteps = stepConfig.slice(0, originIdx);
    const filteredSteps = computeStepList(unfilteredSteps, state);

    // apply the traversal count in reverse
    target = Math.abs(target);
    return filteredSteps.reverse()[target - 1];
  }
}

function buildContextProvider<TState, TStep extends BaseStep<TState>>(
  MultiStepFormContext: Context<MultiStepFormContextReturnType<
    TState,
    TStep
  > | null>,
) {
  return function MultiStepFormProvider({
    initialState,
    children,
    steps: stepConfig,
  }: MultiStepFormProviderProps<TState, TStep>) {
    const navigate = useNavigate();
    const visitedRef = useRef<Record<string, boolean>>({});

    const internalState: MutableRefObject<TState> = useRef(initialState);
    const [state, setState] = useState<TState>(initialState);

    const [currentStep, setCurrentStep] = useState<TStep | null>(
      stepConfig[0] || null,
    );
    const [stepHistory, setStepHistory] = useState<TStep[]>(
      currentStep ? [currentStep] : [],
    );
    const [navigationContext, setNavigationContext] = useState<
      Record<string, any>
    >({});

    if (currentStep) {
      visitedRef.current[currentStep.metadata.id] = true;
    }

    const getState = () => internalState.current;

    const updateState = (updater: StateUpdater<TState>) => {
      internalState.current = isFunction(updater)
        ? updater(internalState.current)
        : { ...internalState.current, ...updater };
      setState(internalState.current);
      return internalState.current;
    };

    const resetState = () => {
      internalState.current = initialState;
      setState(initialState);
    };

    const stepList = useMemo(() => {
      return computeStepList(stepConfig, internalState.current);
    }, [stepConfig, internalState, state]);

    const currentStepIndex = currentStep
      ? stepList.findIndex((s) => s.metadata.id === currentStep.metadata.id)
      : -1;

    const hasPreviousStep = currentStepIndex > 0;

    const hasNextStep = currentStepIndex + 1 < stepList.length;

    const goToStep = useCallback(
      (
        id: string,
        updater?: StateUpdater<TState>,
        extras?: Record<string, any>,
      ) => {
        const nextState = updater
          ? updateState(updater)
          : internalState.current;
        const destStep = computeStep(currentStep, nextState, stepConfig, id);

        if (!destStep) {
          throw new Error(
            `Step ${id} not found in current step list. It may not exist, or may not meet its condition.`,
          );
        }

        setNavigationContext((prev) => ({ ...prev, [id]: { ...extras } }));
        setStepHistory((prev) => [...prev, destStep]);
        setCurrentStep(destStep);
      },
      [state, currentStep, stepConfig, internalState],
    );

    const goToNextStep = useCallback(
      (updater?: StateUpdater<TState>, extras?: Record<string, any>) => {
        const nextState = updater
          ? updateState(updater)
          : internalState.current;
        const nextStep = computeStep(currentStep, nextState, stepConfig, 1);

        if (nextStep) {
          setNavigationContext((prev) => ({
            ...prev,
            [nextStep.metadata.id]: { ...extras },
          }));
          setStepHistory((prev) => [...prev, nextStep]);
          setCurrentStep(nextStep);
        }
      },
      [state, currentStep, stepConfig, internalState],
    );

    const goToPreviousStep = useCallback(
      (updater?: StateUpdater<TState>, extras?: Record<string, any>) => {
        const nextState = updater
          ? updateState(updater)
          : internalState.current;
        const previousStep = computeStep(
          currentStep,
          nextState,
          stepConfig,
          -1,
        );

        if (previousStep) {
          setNavigationContext((prev) => ({
            ...prev,
            [previousStep.metadata.id]: { ...extras },
          }));
          setStepHistory((prev) => [...prev, previousStep]);
          setCurrentStep(previousStep);
        }
      },
      [state, currentStep, stepConfig, internalState],
    );

    const hasVisitedStep = (id: string) => {
      return !!visitedRef.current[id];
    };

    const getLastVisitedStep = () => {
      if (stepHistory.length < 2) {
        return undefined;
      }

      return stepHistory[stepHistory.length - 2];
    };

    useEffect(() => {
      if (isRouteStep(currentStep)) {
        navigate(currentStep.route);
      }
    }, [currentStep]);

    return (
      <MultiStepFormContext.Provider
        value={{
          state: internalState.current,
          getState,
          stepList,
          stepConfig,
          currentStep,
          currentStepIndex,
          hasNextStep,
          hasPreviousStep,
          progress: computeProgress(currentStepIndex, stepList.length),
          visitedSteps: visitedRef.current,
          navigationContext: navigationContext,
          hasVisitedStep,
          getLastVisitedStep,
          setState: updateState,
          goToNextStep,
          goToPreviousStep,
          goToStep,
          resetState,
        }}
      >
        {children}
      </MultiStepFormContext.Provider>
    );
  };
}

function buildContextHook<TState, TStep>(
  MultiStepFormContext: Context<MultiStepFormContextReturnType<
    TState,
    TStep
  > | null>,
) {
  return () => {
    const ctx = useContext(MultiStepFormContext);

    if (!ctx) {
      throw new Error(
        'useMultiStepFormContext was called outside of MultiStepFormProvider context',
      );
    }

    return ctx;
  };
}

function buildContextHelpers<TState, TStep extends BaseStep<TState>>() {
  const MsfContext = createContext<MultiStepFormContextReturnType<
    TState,
    TStep
  > | null>(null);

  return [
    buildContextProvider(MsfContext),
    buildContextHook(MsfContext),
  ] as const;
}

export function createMultiStepFormDynamicContext<
  TState,
  TStep extends ComponentStep<TState> = ComponentStep<TState>,
>() {
  return buildContextHelpers<TState, TStep>();
}

export function createMultiStepFormRouteContext<
  TState,
  TStep extends RouteStep<TState> = RouteStep<TState>,
>() {
  return buildContextHelpers<TState, TStep>();
}
