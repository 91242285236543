import { DateTime } from 'luxon';
import {
  Box,
  Grid,
  Flex,
  Text,
  LoadingOverlay,
  Avatar,
  Button,
} from '@mantine/core';
import { useGetUsers } from '@queries/use-users';
import BadgeStatus from '../../components/badge-status';
import { MoneyMovement } from '@services/flexbase/banking.model';
import { getInitialsOfNames } from '@utilities/object';
import { RightContentModal } from '@common/composites/modal/right-content.modal';
import { useGetDepositAccounts } from '@queries/use-deposit-accounts';
import { formatApiStrings } from '@utilities/formatters/format-api-strings';
import { PiBank } from 'react-icons/pi';

const BillpayPaymentDetails = ({ payment }: { payment: MoneyMovement }) => {
  const { data: depositAccountsData, isLoading: isLoadingDepositAccounts } =
    useGetDepositAccounts();

  const { data: users, isLoading: isLoadingUsers } = useGetUsers();

  const isLoading = isLoadingDepositAccounts || isLoadingUsers;
  const depositAccounts = depositAccountsData?.accounts ?? [];
  const formatedDate = DateTime.fromSQL(payment.createdAt).toFormat('DDD');

  const paidBy = users?.find((user) => user.id === payment.userId);
  const userName = paidBy ? `${paidBy?.firstName} ${paidBy?.lastName}` : 'N/A';

  const paidFrom = depositAccounts.find((acc) => acc.id === payment.depositId);
  const depositAccountName = paidFrom?.nickName ?? paidFrom?.name ?? 'N/A';

  const paymentInfo = [
    {
      label: 'Created date',
      value: formatedDate,
    },
    {
      label: 'Type',
      value: formatApiStrings(payment.type),
    },
    {
      label: 'Paid by',
      value: userName,
      avatarContent: (
        <Text fz={10} c="neutral.6" fw={600}>
          {getInitialsOfNames(userName)}
        </Text>
      ),
    },
    {
      label: 'Paid from',
      value: depositAccountName,
      avatarContent: <PiBank size={'1rem'} />,
    },
  ];

  return (
    <RightContentModal>
      {isLoading ? (
        <LoadingOverlay visible />
      ) : (
        <>
          <RightContentModal.Header
            titleSize={40}
            title={
              <Flex gap="xs" align="center">
                <Text>{payment.payAmount}</Text>
                <BadgeStatus status={payment.status} />
              </Flex>
            }
          />
          <RightContentModal.Body>
            <Grid>
              {paymentInfo.map((item) => (
                <Grid.Col key={item.label} span={6}>
                  <Text size="xs" c="neutral.7">
                    {item.label}
                  </Text>
                  <Flex align="center" gap="xs">
                    {item.label.includes('Paid') && (
                      <Avatar size={24}>{item.avatarContent}</Avatar>
                    )}
                    <Text fw={500}>{item.value}</Text>
                  </Flex>
                </Grid.Col>
              ))}
            </Grid>
            <Box mt={48}>
              <Text size="xs" c="neutral.7">
                Payment receipt
              </Text>
              <Button
                onClick={() =>
                  window.open(`/bill-pay/receipt/${payment.id}`, '_blank')
                }
              >
                Download receipt
              </Button>
            </Box>
          </RightContentModal.Body>
        </>
      )}
    </RightContentModal>
  );
};

export default BillpayPaymentDetails;
