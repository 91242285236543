import { Group, Radio, rem, TextInput } from '@mantine/core';
import { useState } from 'react';
import { formatNumberInputString } from '@utilities/formatters/format-strings';

type FilterDirection = 'equal' | 'lessThan' | 'greaterThan' | string;
export type AmountFilterValue = {
  amount: string;
  direction: FilterDirection;
};
type Props = {
  value: AmountFilterValue;
  onChange: (val: AmountFilterValue) => void;
};

export function QuantityFilter({ onChange, value }: Props) {
  const [direction, setDirection] = useState<FilterDirection>(value.direction);
  const [amount, setAmount] = useState(value.amount);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value);
  };

  const handleDirectionChange = (newDirection: FilterDirection) => {
    setDirection(newDirection);
    onChange({
      amount: formatNumberInputString(amount),
      direction: newDirection,
    });
  };

  const handleBlur = () => {
    const formattedAmount = formatNumberInputString(amount);
    onChange({ amount: formattedAmount, direction });
  };

  return (
    <Group>
      <Radio.Group value={direction} onChange={handleDirectionChange}>
        <Group>
          <Radio value="equal" label="Exactly" />
          <Radio value="lessThan" label="Less than" />
          <Radio value="greaterThan" label="Greater than" />
        </Group>
      </Radio.Group>
      <TextInput
        leftSection="$"
        w={rem(200)}
        value={amount}
        onChange={handleAmountChange}
        onBlur={handleBlur}
      />
    </Group>
  );
}
