import { createWizard } from '@common/emerald/wizard/create-wizard';
import { WizardStepConfig } from '@common/emerald/wizard/wizard.types';
import { CardType } from '@services/flexbase/banking.model';

export type IssueDebitCardWizardState = {
  error: boolean;
  debitCardFormValues: {
    purchaseLimit?: number | null;
    limitType: 'daily' | 'monthly' | 'unlimited';
    cardName?: string;
    cardSubType: CardType | null;
    userId: string;
    cardUser: string;
  };
};

type WizardStepMeta = {
  someHint: React.ReactNode;
};

export type IssueDebitCardWizardStep = WizardStepConfig<
  IssueDebitCardWizardState,
  WizardStepMeta
>;

export const [IssueDebitCardWizard, useIssueDebitCardWizard] = createWizard<
  IssueDebitCardWizardState,
  WizardStepMeta
>();
