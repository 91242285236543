import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  box: {
    minHeight: '69px',
    border: '1px solid #D1D5D8',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
    borderRadius: '6px',
    padding: '18px 10px',
    backgroundColor: theme.colors.neutral[0],
  },
  text: {
    fontFamily: 'Inter',
    fontWeight: 500,
    lineHeight: '20px',
  },
  placeholderText: {
    fontSize: '14px',
    color: '#919191',
  },
  valueText: {
    fontSize: '18px',
    color: '#000000',
  },
  summaryActionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  placeholderMargin: {
    marginBottom: '8px',
  },
  editIcon: {
    cursor: 'pointer',
    fill: 'rgba(95, 95, 95, 0.5)',
    alignSelf: 'flex-end',
    '&:active': {
      transform: 'scale(0.85)',
    },
  },
  questionIcon: {
    cursor: 'pointer',
    color: 'rgba(95, 95, 95, 0.5)',
    width: '20px',
    height: '20px',
    textAlign: 'center',
    alignSelf: 'flex-end',
    fontSize: '20px',
  },
  summaryBoxBackButton: {
    width: '100px',
  },
  alignEnd: {
    alignSelf: 'flex-end',
  },
  summaryBoxSaveButton: {
    width: 'auto',
  },
  summaryBoxButtonGroup: {
    justifyContent: 'space-between',
    width: '100%',
  },
  editModeBox: {
    padding: '32px',
  },
  errorText: {
    color: '#F03E3E',
    fontSize: '14px',
  },
  errorBox: {
    border: '1px solid #F03E3E',
  },
  editModeActionContainer: {
    marginTop: '48px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
  },
  input: {
    width: '346px',
  },
  inputContainer: {
    display: 'flex',
    width: '100%',
    gap: '16px',

    flexWrap: 'wrap',
  },
  addressInputContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    '@media(max-width: 767px)': {
      gridTemplateColumns: '1fr',
    },
  },
  inputWithError: {
    border: '1px solid #F03E3E',
  },
  label: {
    position: 'absolute',
    pointerEvents: 'none',
    fontSize: '14px',
    color: '#919191',
    paddingTop: 10,
    zIndex: 1,
    paddingLeft: 19,
  },
  error: {
    marginLeft: '8px',
  },
  title: {
    fontFamily: 'PP Neue Montreal',
    fontSize: '48px',
    marginBottom: '10px',
    color: 'black',
    fontWeight: 300,
  },

  subtitle: {
    fontFamily: 'PP Neue Montreal',
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '22px',
    letterSpacing: '0rem',
    textAlign: 'left',
    color: '#5F5F5F',
    marginBottom: 20,
  },
  container: {
    width: rem(525),
    '@media(max-width:767px)': {
      maxWidth: '100%',
      padding: 0,
    },
  },
  tabsContainer: {
    marginBottom: theme.spacing.xl,
    width: 'fit-content',
  },
  btnBack: {
    background: 'white',
    border: '1px solid #5F5F5F',
    height: '50px',
    borderRadius: 8,
    width: 'auto',
    '&:hover': {
      background: 'white',
    },
  },
  btnSubmit: {
    height: '50px',
    width: 'auto',
  },
  btntext: {
    fontSize: '16px',
    fontWeight: 500,
  },
  autocomoletInput: {
    marginBottom: 20,
    '.mantine-Autocomplete-input': {
      ':focus': {
        border: `1px solid ${theme.primaryColor}`,
      },
    },
  },
  onboardingSelect: {
    '.mantine-Select-input': {
      background: 'white',
      ':focus': {
        border: `1px solid ${theme.primaryColor}`,
      },
    },
    '.mantine-Select-defaultVariant': {
      background: 'white',
    },
  },
  summaryContinueButton: {
    maxWidth: '230px',
  },
  buttonContainer: {
    marginTop: '32px',
    justifyContent: 'space-between',
  },
  termsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginTop: '48px',
  },
}));
