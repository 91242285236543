import {
  Text,
  Badge,
  Tooltip,
  Skeleton,
  useMantineTheme,
  rem,
} from '@mantine/core';
import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { CardByUser } from 'services/flexbase/banking.model';
import { UserIdState } from 'recoil-state/application/onboarding-form.state';
import TwoFactorAuthSection from '../two-factor/two-factor-auth-section';
import { createStyles } from '@mantine/emotion';

type Props = {
  card: CardByUser;
  cardStatus: string;
};

declare const VGSShow: any;

const DebitCard = ({ card, cardStatus }: Props) => {
  const theme = useMantineTheme();
  const userId = useRecoilValue(UserIdState);
  const { classes } = useStyles({ cardStatus });
  const [displayData, setDisplayData] = useState(false);
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [needsTwoFactor, setNeedsTwoFactor] = useState(false);
  const [bearerToken, setBearerToken] = useState<string | null>(null);

  const formatCardStatus: any = {
    active: 'Active',
    suspended: 'Frozen',
    issued: 'Unactivated',
    terminated: 'Terminated',
  };

  // Create iframe for CVV2 and Card Number
  const show = VGSShow.create(import.meta.env.VITE_APP_UNIT_VAULT_ID);
  const customerToken = bearerToken;
  const cardId = card.ucCardId.substring(5);

  const cvv2iframe = show.request({
    name: 'data-text',
    method: 'GET',
    path: '/cards/' + cardId + '/secure-data/cvv2',
    headers: {
      Authorization: 'Bearer ' + customerToken,
    },
    htmlWrapper: 'text',
    jsonPathSelector: 'data.attributes.cvv2',
  });

  const cardNumberIframe = show.request({
    name: 'data-text',
    method: 'GET',
    path: '/cards/' + cardId + '/secure-data/pan',
    headers: {
      Authorization: 'Bearer ' + customerToken,
    },
    htmlWrapper: 'text',
    jsonPathSelector: 'data.attributes.pan',
    serializers: [
      show.SERIALIZERS.replace(
        '(\\d{4})(\\d{4})(\\d{4})(\\d{4})',
        '$1 $2 $3 $4',
      ),
    ],
  });

  const displayCardData = () => {
    if (setDisplayData) {
      setNeedsTwoFactor(true);
      setDisplayData(true);
    }
  };

  let badgeColor = '';
  switch (cardStatus) {
    case 'active':
      badgeColor = theme.colors.primarySecondarySuccess[0];
      break;

    case 'suspended':
      badgeColor = theme.colors.promote[1];
      break;
    case 'issued':
      badgeColor = theme.colors.neutral[2];
      break;
    case 'terminated':
      badgeColor = theme.colors.neutral[2];
      break;
  }

  useEffect(() => {
    if (bearerToken) {
      const commonStyles = {
        fontFamily: '"Inter", sans-serif',
        color: theme.colors.neutral[0],
        fontWeight: '200',
      };

      cvv2iframe.render('#cvv2', {
        ...commonStyles,
        fontSize: rem(12),
      });
      cardNumberIframe.render('#cardNumber', {
        ...commonStyles,
        fontSize: useMobileView ? rem(12) : rem(20),
      });
    }
  }, [bearerToken]);

  return (
    <>
      <div className={classes.debitCardContainer}>
        <div className={classes.headerContainer}>
          <Badge
            sx={{
              backgroundColor: badgeColor,
              border: 'none',
            }}
            styles={{
              inner: {
                color:
                  cardStatus === 'active'
                    ? theme.colors.primarySecondarySuccess[2]
                    : theme.primaryColor,
              },
            }}
          >
            {formatCardStatus[cardStatus]}
          </Badge>
        </div>
        <div className={classes.cardInfo}>
          <div>
            {displayData ? (
              <div>
                <Skeleton
                  radius={rem(3)}
                  h={bearerToken ? 'auto' : rem(10)}
                  my={bearerToken ? 'auto' : rem(6)}
                  visible={bearerToken ? false : true}
                >
                  <div id="cardNumber" />
                </Skeleton>
                <div
                  className={classes.dateAndCodeContainer}
                  style={{ marginTop: rem(12) }}
                >
                  <Text fz={rem(12)}>EXP</Text>
                  <Skeleton
                    radius="xl"
                    h={bearerToken ? 'auto' : rem(10)}
                    width={bearerToken ? 'auto' : rem(42)}
                    visible={bearerToken ? false : true}
                  >
                    <Text fz={rem(12)}>{card.expirationDate}</Text>
                  </Skeleton>
                  <Text ml={rem(18)} fz={rem(12)}>
                    CVV{' '}
                  </Text>
                  <Skeleton
                    radius="xl"
                    h={bearerToken ? 'auto' : rem(10)}
                    width={bearerToken ? 'auto' : rem(25)}
                    visible={bearerToken ? false : true}
                  >
                    <div id="cvv2" />
                  </Skeleton>
                </div>
              </div>
            ) : (
              <Tooltip
                label="Click to reveal"
                disabled={userId !== card.userId || cardStatus !== 'active'}
                withArrow
              >
                <div
                  onClick={() => {
                    if (cardStatus === 'active' && userId === card.userId) {
                      displayCardData();
                    }
                  }}
                  style={{
                    cursor:
                      cardStatus === 'active' && userId === card.userId
                        ? 'pointer'
                        : 'auto',
                  }}
                >
                  <Text fz={useMobileView ? rem(14) : rem(20)} mb={rem(12)}>
                    •••• •••• •••• {card.cardNumber.slice(-4)}
                  </Text>
                  <div className={classes.dateAndCodeContainer}>
                    <Text fz={rem(12)}>EXP ••/••</Text>
                    <Text ml={rem(18)} fz={rem(12)}>
                      CVV •••
                    </Text>
                  </div>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      {needsTwoFactor && (
        <div className={classes.twoFactorContainer}>
          <TwoFactorAuthSection
            isVGSflow
            onSuccess={(accessToken) => {
              if (accessToken) {
                setBearerToken(accessToken);
                setNeedsTwoFactor(false);
              }
            }}
          />
        </div>
      )}
    </>
  );
};

export default DebitCard;

const useStyles = createStyles((theme, { cardStatus }: Partial<Props>) => ({
  debitCardContainer: {
    margin: rem(48),
    padding: rem(43.2),
    backgroundImage: 'url(/svg/flex-card.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    minHeight: rem(260),
    color: theme.colors.neutral[0],
    opacity: cardStatus === 'active' ? 1 : 0.5,
    iframe: {
      width: '100%',
      height: rem(22.4),
      display: 'flex',
      alignItems: 'center',
      color: theme.colors.neutral[0],
    },
    '@media(max-width: 767px)': {
      margin: rem(16),
    },
  },
  headerContainer: {
    display: 'flex',
    marginTop: rem(24),
    marginBottom: rem(44),
    alignItems: 'center',
    justifyContent: 'flex-end',
    '@media(max-width: 767px)': {
      marginBottom: rem(10),
    },
  },
  cardInfo: {
    display: 'flex',
    marginTop: rem(12),
    color: theme.colors.neutral[0],
    justifyContent: 'space-between',
    opacity: cardStatus === 'active' ? 1 : 0.5,
    '@media(max-width: 767px)': {
      marginTop: 0,
    },
  },
  dateAndCodeContainer: {
    gap: 5,
    display: 'flex',
    alignItems: 'center',
  },
  twoFactorContainer: {
    margin: rem(48),
    '@media(max-width: 767px)': {
      margin: rem(20),
    },
  },
}));
