import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  badge: {
    padding: 5,
    textAlign: 'center',
    borderRadius: theme.radius.sm,
    '@media(max-width: 767px)': {
      width: '100%',
    },
    minWidth: '100px',
  },
  card: {
    padding: '24px',
    color: theme.colors.neutral[1],
    borderRadius: theme.defaultRadius,
    position: 'relative',
    backgroundColor: '#fff',
    border: `1px solid ${theme.colors.neutral[1]}`,
    marginTop: theme.spacing.lg,
  },
  dropdown: {
    position: 'fixed',
    '@media(max-width: 767px)': {
      position: 'absolute',
    },
  },
}));
