import {
  useChargeCardsModalFilters,
  useCreditCardsModalFilters,
  useDebitCardsModalFilters,
} from '../use-cards-filters';
import { useMemo } from 'react';
import { uniq } from 'underscore';
import { MultiSelect } from '@mantine/core';
import { CardTableRow } from '../card-table-row.model';
import { ActiveFiltersReturnType } from '@common/utilities/filter/filters';

type Props = {
  cards: CardTableRow[];
};

type CardNameFilterProps<T> = Props & {
  filterHook: ActiveFiltersReturnType<T>;
};

const CardNameFilter = <T extends CardTableRow>({
  cards,
  filterHook,
}: CardNameFilterProps<T>) => {
  const { addFilter, removeFilter, getFilterByKey } = filterHook;

  const cardNames = useMemo(() => {
    return uniq(cards.map((d) => d.cardName).filter((name) => !!name));
  }, [cards]);

  return (
    <MultiSelect
      data={cardNames}
      placeholder="Card name"
      searchable
      value={getFilterByKey('name')?.filterValue || []}
      onChange={(val) => {
        if (val.length > 0) {
          addFilter('name', {
            key: 'name',
            filterValue: val,
            fn: (row) => val.includes(row.cardName || ''),
            label: `Card name: ${val.join(', ')}`,
            showChip: true,
          });
        } else {
          removeFilter('name');
        }
      }}
    />
  );
};

export const CreditCardNameFilter = ({ cards }: Props) => {
  const filterHook = useCreditCardsModalFilters();

  return <CardNameFilter cards={cards} filterHook={filterHook} />;
};

export const ChargeCardNameFilter = ({ cards }: Props) => {
  const filterHook = useChargeCardsModalFilters();

  return <CardNameFilter cards={cards} filterHook={filterHook} />;
};

export const DebitCardNameFilter = ({ cards }: Props) => {
  const filterHook = useDebitCardsModalFilters();

  return <CardNameFilter cards={cards} filterHook={filterHook} />;
};
