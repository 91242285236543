import {
  ActionIcon,
  Flex,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { useState } from 'react';
import { PiEye, PiEyeSlash } from 'react-icons/pi';

const buttonProps = {
  color: '#85868D',
  size: 16,
};

type AccountDetailCellProps = {
  value: string;
};

export const AccountDetailCell = ({ value }: AccountDetailCellProps) => {
  const clipboard = useClipboard();
  const theme = useMantineTheme();
  const [displayValue, setDisplayValue] = useState(false);

  const handleToggle = () => {
    setDisplayValue(!displayValue);
  };

  const { button, displayText } = displayValue
    ? {
        button: <PiEyeSlash {...buttonProps} />,
        displayText: value,
      }
    : {
        button: <PiEye {...buttonProps} />,
        displayText: `•••••${value?.slice(-4)}`,
      };

  return (
    <Flex align="center" gap="xs">
      <Tooltip
        label={clipboard.copied ? 'Copied' : 'Click to copy'}
        withArrow
        data-testid="account-tooltip-icon"
      >
        <Text
          size="sm"
          style={{ cursor: 'pointer', color: theme.colors.blackish[0] }}
          onClick={() => clipboard.copy(value)}
        >
          {displayText}
        </Text>
      </Tooltip>
      <ActionIcon variant="subtle" size="sm" onClick={handleToggle}>
        {button}
      </ActionIcon>
    </Flex>
  );
};
