import { DepositAccount } from 'types/move-funds.model';
import { MoneyMovementDetails, Recipient } from 'types/payments';

export const depositInitial = {
  asOf: '',
  companyId: '',
  createdAt: '',
  id: '',
  isDefault: false,
  name: '',
  nickName: '',
  status: '',
  type: 'depositAccount' as const,
  accountType: 'checking' as const,
  ucCustomerId: '',
  ucDepositId: '',
  userId: '',
  version: 0,
  routingNumber: '',
  accountNumber: '',
  balance: 0,
  available: 0,
  tier: 1 as const,
  tenantId: '',
  plaidOrDeposit: 'deposit',
} as DepositAccount;

export const counterpartyInitial = {
  accountNumber: '',
  routingNumber: '',
  asOf: '',
  companyId: '',
  createdAt: '',
  id: '',
  response: {
    data: {
      attributes: {
        accountNumber: '',
        accountType: '',
        bank: '',
        createdAt: '',
        name: '',
        permissions: '',
        routingNumber: '',
        tags: {
          companyId: '',
          userId: '',
          tenantId: '',
        },
        type: '',
      },
      id: '',
      relationships: {
        customer: {
          data: {
            id: '',
            type: '',
          },
        },
      },
      type: '',
    },
  },
  status: 'active' as const,
  type: 'ach' as const,
  ucCounterpartyId: '',
  nickName: '',
  name: '',
  ucCustomerId: '',
  userId: '',
  version: 0,
  tenantId: '',
  lastUsedAt: '',
  bank: '',
  frecencyScore: 0,
  recipientId: '',
};

export const recipientInitial = {
  name: '',
  accountNumber: '',
  routingNumber: '',
  accountType: '',
  nickName: '',
  type: 'ach',
  recipientType: 'individual',
  address: '',
  addressLine2: '',
  city: '',
  state: '',
  postalCode: '',
  country: '',
} as Recipient;

export const initialMoneyMovementAmountDetails = {
  amount: 0,
  memo: '',
  internalNotes: '',
  sourceAccount: depositInitial,
  files: [],
  scheduledFor: null,
} as MoneyMovementDetails;
