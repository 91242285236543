import { useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { useRecoilValue } from 'recoil';
import { Button, Group, Image, rem, Text } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

import { ApplicationState } from 'recoil-state/application/product-onboarding';
import IssueCards from './info-section/issue-cards';
import MoneyTransfers from './info-section/money-transfers';
import BankingApp from 'assets/images/banking-app-overview.png';
import BusinessChecking from './info-section/business-checking';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { useRouteSectionContext } from 'providers/route-context';
import type { BankingApplicationStatus } from 'services/flexbase/banking.model';
import { ProductApplicationRoutes } from '../../../../constants/onboarding.constants';

type Props = {
  documentsLink: string;
  applicationStatus: BankingApplicationStatus;
};

type AppStatusInfo = {
  description: string;
  redirectTo?: string;
  buttonContent?: string;
};

const bankingInfo = [
  {
    title: 'Money Transfers',
    description: 'Enjoy free domestic ACH & wire transfers',
    image: <MoneyTransfers />,
  },
  {
    title: 'High APY Business Checking',
    description: 'Earn up to 4% cashback on your cash on hand',
    image: <BusinessChecking />,
  },
  {
    title: 'Expense Management',
    description: 'Effortlessly manage all your expenses',
    image: <Image pos="absolute" src={BankingApp} width={rem(240)} />,
  },
  {
    title: 'Issue cards',
    description: 'Individual employee cards, at no extra cost',
    image: <IssueCards />,
  },
];

const handleIncompleteStatus = (
  displayInfo: AppStatusInfo,
  applicationStatus: BankingApplicationStatus,
) => {
  //pending = 'Pending'  on our end from being submitted to Unit
  if (applicationStatus === 'Pending') {
    displayInfo.description =
      'Your application is pending review. Please check back in 24 hours for an update.';
    displayInfo.buttonContent = 'Go Back';
    displayInfo.redirectTo = '/';
  } else if (applicationStatus === 'Ready') {
    displayInfo.description =
      'Your application is ready for submission! Jump back in and submit now.';
    displayInfo.buttonContent = 'Submit Application';
    displayInfo.redirectTo = '/application';
  } else {
    displayInfo.description =
      'Your application is almost complete! Jump back in and complete now.';
    displayInfo.buttonContent = 'Continue Application';
    displayInfo.redirectTo = '/application';
  }
};

const getDisplayInfo = (
  applicationStatus: BankingApplicationStatus | null,
  documentsLink: string,
): AppStatusInfo => {
  let displayInfo: AppStatusInfo = {
    description:
      'Simplified banking, payments, and expense management for the savvy business owner.',
    buttonContent: 'Apply now',
    redirectTo: '/',
  };

  switch (applicationStatus) {
    case 'AwaitingDocuments':
      displayInfo = {
        description:
          'Your application is almost done! Please upload the necessary documents to complete the process',
        buttonContent: 'Upload Documents',
        redirectTo: documentsLink,
      };
      break;
    case 'Denied':
      displayInfo = {
        description:
          "We're sorry – your application has been denied. Please contact us to determine the reason your application was denied.",
        buttonContent: 'Contact Flex Support',
        redirectTo: 'https://support.flex.one/',
      };
      break;
    case 'Canceled':
      displayInfo = {
        description:
          'Your application has been cancelled. If you have any questions, please contact us.',
        buttonContent: 'Contact Flex Support',
        redirectTo: 'https://support.flex.one/',
      };
      break;
    case 'Incomplete':
      handleIncompleteStatus(displayInfo, applicationStatus);
      break;
    case 'Unqualified':
      displayInfo = {
        description:
          'We are unable to offer you a bank account at this time. Please contact us for more information.',
        buttonContent: 'Contact Flex Support',
        redirectTo: 'https://support.flex.one/',
      };
      break;
    case 'PendingReview':
    case 'Pending':
      displayInfo = {
        description:
          'Your application is pending review. Please check back in 24 hours for an update.',
      };
      break;
    default:
      break;
  }
  return displayInfo;
};

const ApplicationStatusPage = ({ applicationStatus, documentsLink }: Props) => {
  const { classes, theme } = useStyles();
  const [loading, setLoading] = useState(false);
  const { setSectionAndNavigate } = useRouteSectionContext();
  const useMobileView = useMediaQuery('(max-width: 768px)');
  const { company, productStatus } = useRecoilValue(ApplicationState);
  const displayInfo = getDisplayInfo(applicationStatus, documentsLink);

  const createBankingApplication = async () => {
    try {
      setLoading(true);
      const isUnqualifiedForCredit =
        productStatus.credit.appStatus === 'unqualified';
      await flexbaseOnboardingClient.updateCompany({
        id: company.id,
        optedProducts: isUnqualifiedForCredit
          ? ['BANKING']
          : [...company.optedProducts, 'BANKING'],
      });
      setSectionAndNavigate(
        'application',
        ProductApplicationRoutes.BANKING_ONLY,
      );
    } catch (error) {
      console.error('Error creating banking application', error);
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Something went wrong. Please try again.',
      });
    } finally {
      setLoading(false);
    }
  };

  const onClickbutton = () => {
    if (displayInfo.redirectTo && displayInfo.redirectTo.includes('https')) {
      window.open(displayInfo.redirectTo);
    } else if (['Unused', null].includes(applicationStatus)) {
      createBankingApplication();
    } else {
      setSectionAndNavigate(
        'application',
        ProductApplicationRoutes.BANKING_ONLY,
      );
    }
  };

  return (
    <div className={classes.backgroundContent}>
      <Group grow>
        <div>
          <Text ff="redaction" fz={rem(56)} fw={400}>
            Flex Banking
          </Text>
          <Text
            fz={rem(28)}
            fw={400}
            mb={rem(20)}
            data-testid="app-state-description"
          >
            {displayInfo.description}
          </Text>
          {!['Pending', 'PendingReview'].includes(applicationStatus) && (
            <Button
              mt="lg"
              variant="light"
              loading={loading}
              onClick={onClickbutton}
              loaderProps={{ color: theme.primaryColor }}
            >
              {displayInfo.buttonContent}
            </Button>
          )}
        </div>
        {!useMobileView && (
          <Image src="/images/front-debit-card.png" width={500} />
        )}
      </Group>

      <Group mt={rem(60)}>
        {bankingInfo.map(({ title, description, image }) => (
          <div key={title} className={classes.card}>
            <div className={classes.alingCenter}>
              <div className={classes.alignCenter}>
                <Text fz={rem(8)} mr={rem(5)} color="tertiary.2">
                  ♦
                </Text>
                <Text fz={rem(12)} fw={400}>
                  {title}
                </Text>
              </div>
              <Text
                lh={1}
                mt="md"
                fw={400}
                fz={rem(30)}
                ff="redaction"
                ta="center"
              >
                {description}
              </Text>
            </div>
            {image}
          </div>
        ))}
      </Group>
    </div>
  );
};

export default ApplicationStatusPage;

const useStyles = createStyles((theme) => ({
  backgroundContent: {
    position: 'relative',
    backgroundColor: theme.colors.neutral[2],
  },
  alingCenter: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: rem(25),
    flexDirection: 'column',
    justifyContent: 'center',
  },
  card: {
    width: rem(310),
    padding: rem(32),
    height: rem(413),
    color: theme.colors.neutral[1],
    backgroundColor: theme.primaryColor,
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
