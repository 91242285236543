import { atom } from 'recoil';
import {
  ActiveFiltersReturnType,
  createUseFiltersHook,
  FilterFnMap,
} from '@common/utilities/filter/filters';
import { SpendPlanCardsResponse } from '../../../../../../services/flexbase/spend-plans.model';

const SpendCardsFilterState = atom<FilterFnMap<SpendPlanCardsResponse>>({
  key: 'spend_cards_filter_state',
  default: {},
});

const SpendCardsFilterStateModal = atom<FilterFnMap<SpendPlanCardsResponse>>({
  key: 'spend_cards_filter_state_modal',
  default: {},
});

export function useSpendCardFilters(): ActiveFiltersReturnType<SpendPlanCardsResponse> {
  return createUseFiltersHook<SpendPlanCardsResponse>(SpendCardsFilterState);
}

export function useSpendCardModalFilters(): ActiveFiltersReturnType<SpendPlanCardsResponse> {
  return createUseFiltersHook<SpendPlanCardsResponse>(
    SpendCardsFilterStateModal,
  );
}
