import { flexbaseOnboardingClient } from '../services/flexbase-client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  BillpayInvoiceFilters,
  CreateBillpayInvoiceRequest,
  UpdateBillpayInvoiceRequest,
} from '../types/bill-pay';

const QUERY_KEY = 'billPay';
const QUERY_BALANCES = 'balances';
const QUERY_INVOICES = 'invoices';
const QUERY_INVOICE = 'invoice';

export const useGetBillpayBalance = () => {
  return useQuery({
    queryKey: [QUERY_KEY, QUERY_BALANCES],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getBillpayBalances();
    },
    meta: {
      errorMessage: 'Unable to get bill pay balance data',
    },
  });
};

export const useGetBillpayInvoices = (
  filters?: Partial<BillpayInvoiceFilters>,
) => {
  return useQuery({
    queryKey: [QUERY_KEY, QUERY_INVOICES, filters],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getBillpayInvoices(filters);
    },
    meta: {
      errorMessage: 'Unable to get bill pay invoices data',
    },
  });
};

export const useGetBillpayInvoice = (invoiceId: string, companyId: string) => {
  return useQuery({
    queryKey: [QUERY_KEY, QUERY_INVOICE, companyId, invoiceId],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getBillpayInvoice(
        invoiceId,
        companyId,
      );
    },
    enabled: !!invoiceId && !!companyId,
    meta: {
      errorMessage: `Unable to get bill pay invoice data for invoice ID: ${invoiceId}`,
    },
  });
};

export const useCreateBillpayInvoice = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (invoice: CreateBillpayInvoiceRequest) => {
      return await flexbaseOnboardingClient.createBillpayInvoice(invoice);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
  });
};

export const useUpdateBillpayInvoice = () => {
  return useMutation({
    mutationFn: async (body: UpdateBillpayInvoiceRequest) => {
      return await flexbaseOnboardingClient.updateBillpayInvoice(body);
    },
  });
};

export const useDeleteBillpayInvoice = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (invoiceId: string) => {
      return await flexbaseOnboardingClient.deleteBillpayInvoice(invoiceId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
  });
};
