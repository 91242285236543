import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Badge, rem, Text, TextInput, useMantineTheme } from '@mantine/core';
import { formatCurrency } from '@utilities/formatters';
import flexbaseClient from 'services/flexbase-client';
import { CustomMantineStyles, useStyles } from './payments-table.styles';
import FlexbaseTable from 'components/table/flexbase-table';
import { useMediaQuery } from '@mantine/hooks';
import { paymentFormatStatus } from 'utilities/formatters/payment-format-status';
import { formatApiStrings } from 'utilities/formatters/format-api-strings';
import { getMonthDayYear, sortDate } from '@utilities/dates';
import { PiMagnifyingGlass } from 'react-icons/pi';

export const sortList = (
  arrayList: Record<string, any>[],
  { sortBy = '', sortType = 'asc' } = {},
) => {
  if (Array.isArray(arrayList)) {
    arrayList = arrayList.sort((a, b) => {
      let resp = 0;
      const prev = a !== null && typeof a === 'object' ? a[sortBy] : a;
      const curr = b !== null && typeof b === 'object' ? b[sortBy] : b;
      if (
        typeof prev === 'string' &&
        /^[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(prev)
      ) {
        resp = new Date(prev).getTime() - new Date(curr).getTime();
      } else if (Object.prototype.toString.call(prev) === '[object Date]') {
        resp = prev - curr;
      } else if (typeof prev === 'string' || typeof prev === 'number') {
        resp = prev > curr ? 1 : -1;
      }
      return sortType === 'desc' ? -1 * resp : resp;
    });
  }
  return arrayList;
};

type Payment = {
  amount: string;
  status: string;
  datePosted: string;
  createdAt: string;
  origin: string;
  id: string;
};

const PaymentsTable = () => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [inputText, setInputText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState<Payment[]>();

  const inputHandler = (e: any) => {
    const lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  const getCompanyPayments = async () => {
    try {
      setLoading(true);
      const {
        success,
        payments: coPayments,
        error,
      } = await flexbaseClient.getCompanyPayments();
      // Make sure the latest invoices come first
      sortList(coPayments!, {
        sortType: 'desc',
        sortBy: 'createdAt',
      });
      if (success) {
        setPayments(coPayments);
      } else if (error) {
        setErrorMessage(error as string);
      } else {
        setErrorMessage(
          'We are unable to retrieve the company payments at the moment.',
        );
      }
    } catch (error) {
      console.error('Unable to get the company payments', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCompanyPayments();
  }, []);

  const columns = [
    {
      name: 'Date created',
      selector: (row: { createdAt: string }) => row.createdAt,
      format: (row: { createdAt: string }) => getMonthDayYear(row.createdAt),
      sortable: true,
      sortFunction: sortDate,
    },
    {
      name: 'Date posted',
      selector: (row: { datePosted: string }) => {
        const parsedDate = new Date(row.datePosted).toISOString();
        return parsedDate;
      },
      format: (row: { datePosted: string }) => row.datePosted,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: (row: { amount: string }) => Number(row.amount),
      sortable: true,
      format: (row: { amount: string }) => formatCurrency(row.amount),
    },
    {
      name: 'Payment type',
      selector: (row: { type: string }) => row.type,
      format: (row: { type: string }) => formatApiStrings(row.type),
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row: { status: string }) => row.status,
      cell: (row: { status: any }) => {
        let statusColor, labelColor;

        switch (row.status) {
          case 'Posted':
            statusColor = theme.colors.primarySecondarySuccess[0];
            labelColor = theme.colors.primarySecondarySuccess[6];
            break;
          case 'Pending':
          case 'Created':
            statusColor = theme.colors.tertiary[0];
            labelColor = theme.colors.tertiary[7];
            break;
          case 'Chargeback':
          case 'Canceled':
          case 'Failed':
            statusColor = theme.colors.critical[0];
            labelColor = theme.colors.critical[6];
            break;
          default:
            statusColor = theme.colors.neutral[1];
            labelColor = theme.colors.neutral[7];
        }
        return (
          <Badge bg={statusColor}>
            <Text c={labelColor}>{formatApiStrings(row.status)}</Text>
          </Badge>
        );
      },
      sortable: true,
    },
  ];

  const paymentsArray = payments?.filter(
    (item) => item.origin === 'manual' || item.origin === 'autopay',
  );

  const tableData = paymentsArray?.map((payment) => {
    return {
      id: payment.id,
      datePosted: DateTime.fromSQL(payment.datePosted).toFormat('LLL d, yyyy'),
      dateSubmitted: DateTime.fromSQL(payment.createdAt).toFormat(
        'LLL dd, yyyy',
      ),
      createdAt: payment.createdAt,
      amount: payment.amount.replace(/(-)/, ''),
      type: formatApiStrings(payment.origin),
      status: paymentFormatStatus(payment.status, payment.datePosted),
    };
  });

  const filteredData =
    tableData?.filter((payment) => {
      if (inputText === '') {
        return payment;
      } else {
        return (
          payment.datePosted.toLowerCase().includes(inputText) ||
          payment.dateSubmitted.toLowerCase().includes(inputText) ||
          payment.amount.toLowerCase().includes(inputText) ||
          payment.status.toLowerCase().includes(inputText) ||
          payment.type.toLowerCase().includes(inputText)
        );
      }
    }) ?? [];

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Text className={classes.title}> </Text>
        <TextInput
          w={useMobileView ? '100%' : rem(368)}
          styles={{
            input: CustomMantineStyles(theme).searchPaymentHistory.input,
          }}
          placeholder="Search payment history"
          onChange={inputHandler}
          leftSection={
            <PiMagnifyingGlass
              size={'1.25rem'}
              color={theme.colors.blackish[0]}
            />
          }
        />
      </div>
      <FlexbaseTable
        columns={columns}
        data={filteredData}
        pagination={filteredData && filteredData?.length > 8}
        isFetchingData={loading}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default PaymentsTable;
