import { Text, Badge, useMantineTheme } from '@mantine/core';

const CheckDepositStatusBadge = ({ status }: { status: string }) => {
  const theme = useMantineTheme();
  let backgroundColor, textColor;

  switch (status) {
    case 'Deposited':
      textColor = theme.colors.primarySecondarySuccess[6];
      backgroundColor = theme.colors.primarySecondarySuccess[0];
      break;
    case 'Canceled':
    case 'Rejected':
    case 'Returned':
      textColor = theme.colors.critical[6];
      backgroundColor = theme.colors.critical[0];
      break;
    default:
      textColor = theme.colors.neutral[7];
      backgroundColor = theme.colors.neutral[1];
  }

  return (
    <Badge bg={backgroundColor}>
      <Text color={textColor}>{status} </Text>
    </Badge>
  );
};

export default CheckDepositStatusBadge;
