import { Button, Group, Radio, TextInput } from '@mantine/core';
import { useState } from 'react';
import { formatNumberInputString } from '@utilities/formatters/format-strings';

type FilterDirection = 'equal' | 'lessThan' | 'greaterThan' | string;
export type AmountFilterValue = {
  amount: string;
  direction: FilterDirection;
};
type Props = {
  value: AmountFilterValue;
  onChange: (val: AmountFilterValue) => void;
};

export function filterDirectionToLabel(direction: FilterDirection) {
  switch (direction) {
    case 'equal':
      return 'Equal to';
    case 'lessThan':
      return 'Less than';
    case 'greaterThan':
      return 'Greater than';
    default:
      return '';
  }
}

/**
 * This filter provides two inputs: A text input for a numeric number, and radio buttons to select a direction.
 * It outputs the result after the user clicks an "Apply" button. This result then can be used to filter a list
 * or table.
 * @param onChange
 * @param value
 * @constructor
 */
export function AmountFilter({ onChange, value }: Props) {
  const [direction, setDirection] = useState<FilterDirection>(value.direction);
  const [amount, setAmount] = useState(value.amount);

  return (
    <Group>
      <Radio.Group value={direction} onChange={setDirection}>
        <Group>
          <Radio value="equal" label="Exactly" />
          <Radio value="lessThan" label="Less than" />
          <Radio value="greaterThan" label="Greater than" />
        </Group>
      </Radio.Group>
      <TextInput
        leftSection="$"
        w={200}
        value={amount}
        onChange={(e) => {
          setAmount(formatNumberInputString(e.target.value));
        }}
      />
      <Button
        size="xs"
        variant="light"
        onClick={() => onChange({ amount, direction })}
      >
        Apply
      </Button>
    </Group>
  );
}
