import { Flex, Text } from '@mantine/core';
import { forwardRef } from 'react';
import { PiPlusCircle } from 'react-icons/pi';

type ItemProps = {
  label: string;
  value: string;
} & React.ComponentPropsWithoutRef<'div'>;

export const SelectItemComponent = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, value, ...others }: ItemProps, ref) => {
    return (
      <div ref={ref} {...others}>
        {value === 'create-recipient' ? (
          <Flex align="center" gap="xs" py={'xs'}>
            <PiPlusCircle size={'1.25rem'} />
            <Text>{label}</Text>
          </Flex>
        ) : (
          <Text>{label}</Text>
        )}
      </div>
    );
  },
);

SelectItemComponent.displayName = 'SelectItem';
