import { useMantineTheme } from '@mantine/core';
import { BillPayStatus } from 'types/bill-pay';

export const getBillPayStatusDisplay = (status?: BillPayStatus) => {
  const theme = useMantineTheme();
  let backgroundColor: string, textColor: string, displayStatus: string;

  switch (status) {
    case 'drafted':
      displayStatus = 'Draft';
      backgroundColor = theme.colors.flexbaseGrey[0];
      textColor = theme.colors.neutral[7];
      break;

    case 'requested':
      displayStatus = 'Needs approval';
      backgroundColor = theme.colors.tertiary[0];
      textColor = theme.colors.tertiary[5];
      break;

    case 'pending':
      displayStatus = 'Pending';
      backgroundColor = theme.colors.tertiary[0];
      textColor = theme.colors.tertiary[5];
      break;

    case 'scheduled':
      displayStatus = 'Scheduled';
      backgroundColor = theme.colors.neutral[1];
      textColor = theme.colors.neutral[7];
      break;

    case 'succeeded':
      displayStatus = 'Paid';
      backgroundColor = theme.colors.positive[0];
      textColor = theme.colors.primarySecondarySuccess[7];
      break;

    case 'failed':
      displayStatus = 'Failed';
      backgroundColor = theme.colors.critical[0];
      textColor = theme.colors.critical[6];
      break;

    default:
      displayStatus = 'Unknown';
      backgroundColor = theme.colors.gray[0];
      textColor = theme.colors.gray[7];
  }

  return { displayStatus, backgroundColor, textColor };
};
