import {
  hideNotification,
  notifications,
  showNotification,
} from '@mantine/notifications';
import {
  adsLinksQueryOptions,
  commerceLinksQueryOptions,
  expenseLinksQueryOptions,
  useAdsLinks,
  useCommerceLinks,
  useExchangeIntegrationTokenMutation,
  useExpenseLinks,
} from '@queries/use-integrations';
import { useQueryClient } from '@tanstack/react-query';
import { usePolling } from '@utilities/polling';
import {
  INTEGRATION_PLATFORM,
  IntegrationCategory,
  IntegrationLink,
  IntegrationPlatform,
} from 'areas/company-settings/integrations';
import {
  getIntegrationCards,
  IntegrationCardItem,
} from 'areas/company-settings/integrations/list-integrations';
import { CheckCircleGreen } from 'assets/svg';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRutterLink } from 'react-rutter-link';
import { useRecoilValue } from 'recoil';
import { IsIntegrationsAuthorized } from 'recoil-state/integrations/integrations';
import { flatten } from 'underscore';
import { Alert, AlertProps, Button, Group, Text } from '@mantine/core';

type NotificationAlertProps = AlertProps & {
  action?: {
    label: string;
    onClick: (...args: unknown[]) => void;
  };
};

const NotificationAlert = ({
  children,
  action,
  ...alertProps
}: NotificationAlertProps) => {
  return (
    <Alert {...alertProps}>
      <Group wrap="nowrap">
        <Text>{children}</Text>

        {action ? (
          <Button onClick={action.onClick}>{action.label}</Button>
        ) : null}
      </Group>
    </Alert>
  );
};

type ShowNotificationAlertOptions = Pick<
  NotificationAlertProps,
  'title' | 'color' | 'icon' | 'action'
> & {
  id: string;
  message: NotificationAlertProps['children'];
  autoClose?: boolean;
};

/**
 * Hook with methods to show a notification styled as an Alert, with an optional action button.
 */
const useNotificationAlert = () => {
  const showNotificationAlert = (options: ShowNotificationAlertOptions) => {
    showNotification({
      id: options.id,
      message: (
        <NotificationAlert
          title={options.title}
          color={options.color}
          icon={options.icon}
          action={options.action}
          onClose={() => hideNotification(options.id)}
        >
          {options.message}
        </NotificationAlert>
      ),
      autoClose: options.autoClose,
      withBorder: false,
      withCloseButton: false,
      styles: {
        root: {
          '&::before': {
            display: 'none',
          },
          padding: 0,
        },
        body: {
          margin: 0,
        },
        title: {
          display: 'none',
        },
      },
    });

    return {
      id: options.id,
      close: () => hideNotification(options.id),
    };
  };

  return {
    showNotificationAlert,
  };
};

export function useIntegrationsList() {
  const all = getIntegrationCards().filter((i) => i.visible);

  const accountingIntegrations = all.filter((i) => i.category === 'accounting');
  const adsIntegrations = all.filter((i) => i.category === 'ads');
  const commerceIntegrations = all.filter((i) => i.category === 'commerce');

  return {
    all,
    accountingIntegrations,
    adsIntegrations,
    commerceIntegrations,
  };
}

export function useIntegrationLinks() {
  const isAuthorized = useRecoilValue(IsIntegrationsAuthorized);
  const { data: accountingLinksData, isPending: isAccountingLinksPending } =
    useExpenseLinks(isAuthorized);
  const { data: adsLinksData, isPending: isAdsLinksPending } =
    useAdsLinks(isAuthorized);
  const { data: commerceLinksData, isPending: isCommerceLinksPending } =
    useCommerceLinks(isAuthorized);

  const isPending =
    isAccountingLinksPending || isAdsLinksPending || isCommerceLinksPending;

  const accountingLinks =
    accountingLinksData?.filter(
      (link) =>
        link.platform !== INTEGRATION_PLATFORM.INTUIT_BANK_FEEDS &&
        link.status === 'active',
    ) ?? [];
  const adsLinks =
    adsLinksData?.filter((link) => link.status === 'active') ?? [];
  const commerceLinks =
    commerceLinksData?.filter((link) => link.status === 'active') ?? [];
  const intuitFeedsLink = accountingLinksData?.find(
    (link) => link.platform === INTEGRATION_PLATFORM.INTUIT_BANK_FEEDS,
  );

  return {
    isPending,
    accountingLinks,
    adsLinks,
    commerceLinks,
    intuitFeedsLink,
  };
}

type UseRutterLinkReturnType = ReturnType<typeof useRutterLink>;
type RutterLinkOpenOptions = {
  /**
   * Supply a platform enum to direct to a specific platform.
   * @see {@link https://docs.rutter.com/supported-platforms}
   */
  platform?: IntegrationPlatform;
};

/**
 * Extend Rutter's type declaration to be a little more specific. If they ever update it we can remove this.
 */
type ExtendedUseRutterLinkReturnType = UseRutterLinkReturnType & {
  open: (opts?: RutterLinkOpenOptions) => void;
};

const pendingConnectionIds: Record<IntegrationCategory, Set<string>> = {
  accounting: new Set<string>(),
  ads: new Set<string>(),
  commerce: new Set<string>(),
  payments: new Set<string>(),
};

export const useCreateRutterConnection = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { startPolling } = usePolling();
  const { showNotificationAlert } = useNotificationAlert();
  const { mutate: exchangeToken, isPending: isExchangingToken } =
    useExchangeIntegrationTokenMutation();
  const [isRutterConnecting, setIsRutterConnecting] = useState(false);
  const selectedIntegrationRef = useRef<IntegrationCardItem>();

  const { open } = useRutterLink({
    onSuccess: async (publicToken: string) => {
      exchangeToken(publicToken, {
        onSuccess: async (response) => {
          if (!response.connectionId) {
            notifications.show({
              color: 'critical.2',
              title: 'Error',
              message: `An error occurred while connecting. Please try again later.`,
            });

            return;
          }

          try {
            let initialLink: IntegrationLink | undefined;

            switch (selectedIntegrationRef.current?.category) {
              case 'accounting':
                initialLink = await queryClient
                  .fetchQuery(expenseLinksQueryOptions(true))
                  .then((res) =>
                    res.find(
                      (link) => link.connectionId === response.connectionId,
                    ),
                  );
                break;
              case 'ads':
                initialLink = await queryClient
                  .fetchQuery(adsLinksQueryOptions(true))
                  .then((res) =>
                    res.find(
                      (link) => link.connectionId === response.connectionId,
                    ),
                  );
                break;
              case 'commerce':
                initialLink = await queryClient
                  .fetchQuery(commerceLinksQueryOptions(true))
                  .then((res) =>
                    res.find(
                      (link) => link.connectionId === response.connectionId,
                    ),
                  );
                break;
            }

            // if we've connected to this integration before, this could already be ready
            // if so, no need to poll
            if (initialLink?.isReady) {
              const platformName =
                getIntegrationCards().find(
                  (c) => c.platform === initialLink.platform,
                )?.title ?? initialLink.platform;

              notifications.show({
                title: 'Integration Successful',
                color: 'green',
                icon: <CheckCircleGreen />,
                message: `Connected to ${platformName}`,
              });

              return;
            }

            // initial check was successful, but no ready links yet
            // display a success toast and start polling
            notifications.show({
              color: 'primarySecondarySuccess.2',
              title: 'Success',
              message:
                'Successfully integrated! This process may take up to an hour to fully complete.',
            });
          } catch (e) {
            console.error(
              `An error occurred while making the initial check for ready expense links:`,
              e,
            );

            // rutter token exchange was successful, but failed to perform the initial link fetch
            // display a success toast but don't poll
            notifications.show({
              color: 'primarySecondarySuccess.2',
              title: 'Success',
              message:
                'Successfully integrated! This process may take up to an hour to fully complete.',
            });
            return;
          } finally {
            setIsRutterConnecting(false);
          }

          const interval = 1000 * 10;
          const retryMax = 3;
          let retryCount = 0;

          if (selectedIntegrationRef.current?.category) {
            pendingConnectionIds[selectedIntegrationRef.current.category].add(
              response.connectionId,
            );
          }

          startPolling(
            'GET_INTEGRATION_LINKS',
            async ({ stop }) => {
              try {
                const linkQueries: Promise<IntegrationLink[]>[] = [];

                // make different queries depending on the integration category
                if (pendingConnectionIds.accounting.size) {
                  const query = queryClient
                    .fetchQuery(expenseLinksQueryOptions(true))
                    .then((links) => {
                      return links.filter((link) => {
                        // delete from the pending list
                        const wasDeleted =
                          pendingConnectionIds.accounting.delete(
                            link.connectionId,
                          );
                        return link.isReady && wasDeleted;
                      });
                    });

                  linkQueries.push(query);
                }

                if (pendingConnectionIds.ads.size) {
                  const query = queryClient
                    .fetchQuery(adsLinksQueryOptions(true))
                    .then((links) => {
                      return links.filter((link) => {
                        const wasDeleted = pendingConnectionIds.ads.delete(
                          link.connectionId,
                        );
                        return link.isReady && wasDeleted;
                      });
                    });

                  linkQueries.push(query);
                }

                if (pendingConnectionIds.commerce.size) {
                  const query = queryClient
                    .fetchQuery(commerceLinksQueryOptions(true))
                    .then((links) => {
                      return links.filter((link) => {
                        const wasDeleted = pendingConnectionIds.commerce.delete(
                          link.connectionId,
                        );
                        return link.isReady && wasDeleted;
                      });
                    });

                  linkQueries.push(query);
                }

                // flatten the results
                const newReadyLinks: IntegrationLink[] = await Promise.all(
                  linkQueries,
                ).then((res) => flatten(res).filter(Boolean));

                // no more pending connections for any category, stop polling
                if (
                  Object.values(pendingConnectionIds).every(
                    (ids) => ids.size === 0,
                  )
                ) {
                  stop();
                }

                if (newReadyLinks.length) {
                  let toastMsg = `Your integrations are now ready to use.`;
                  let actionLabel = 'Integrations';

                  if (newReadyLinks.length === 1) {
                    const [link] = newReadyLinks;
                    const integration = getIntegrationCards().find(
                      (c) => c.platform === link.platform,
                    );
                    const platformName = integration?.title ?? link.platform;
                    const platformCategory = integration?.category;

                    if (platformCategory === 'accounting') {
                      toastMsg = `You may now sync transaction data to ${platformName}. Configure your integration in Settings.`;
                      actionLabel = 'Settings';
                    } else {
                      toastMsg = `Your ${
                        platformName
                          ? `${platformName} integration`
                          : 'integration'
                      } is now ready to use.`;
                      actionLabel = 'Integrations';
                    }
                  }

                  const notificationAlert = showNotificationAlert({
                    id: 'integration_successful',
                    title: 'Integration Successful',
                    color: 'green',
                    icon: <CheckCircleGreen />,
                    action: {
                      label: actionLabel,
                      onClick: () => {
                        navigate('/settings/integrations');
                        notificationAlert.close();
                      },
                    },
                    message: toastMsg,
                    autoClose: false,
                  });
                }
              } catch (err) {
                console.error(
                  'An error occurred while checking for ready expense links:',
                  err,
                );

                retryCount++;

                if (retryCount >= retryMax) {
                  stop();
                }
              }
            },
            interval,
          );
        },
        onError: () => {
          setIsRutterConnecting(false);
          selectedIntegrationRef.current = undefined;
          notifications.show({
            color: 'critical.2',
            title: 'Failure',
            message:
              'We were unable to complete the integration. Please try again.',
          });
        },
      });
    },
    onExit: () => {
      setIsRutterConnecting(false);
      selectedIntegrationRef.current = undefined;
    },
    publicKey: import.meta.env.VITE_APP_RUTTER_KEY,
  }) as ExtendedUseRutterLinkReturnType;

  const openRutterLink = (platform: IntegrationPlatform) => {
    const integration = getIntegrationCards().find(
      (i) => i.platform === platform,
    );

    selectedIntegrationRef.current = integration;
    setIsRutterConnecting(true);
    open({ platform });
  };

  return {
    openRutterLink,
    isExchangingToken,
    isRutterConnecting,
  };
};

export function isQuickbooksExpenses({
  platform,
}: IntegrationCardItem | IntegrationLink) {
  return platform === INTEGRATION_PLATFORM.QUICKBOOKS;
}

export function isQuickbooksBankFeeds({
  platform,
}: IntegrationCardItem | IntegrationLink) {
  return platform === INTEGRATION_PLATFORM.INTUIT_BANK_FEEDS;
}

export function isQuickbooks(args: IntegrationCardItem | IntegrationLink) {
  return isQuickbooksExpenses(args) || isQuickbooksBankFeeds(args);
}

export function isIntegrationLinkConnected(
  link?: IntegrationLink,
): link is IntegrationLink {
  if (!link || link.status !== 'active') {
    return false;
  }

  // INTUIT_BANK_FEEDS is treated as connected if the link exists and enabledBankFeeds is true
  if (isQuickbooksBankFeeds(link) && 'enabledBankFeeds' in link) {
    return !!link.enabledBankFeeds;
  }

  return true;
}
