import { useWizardMobile } from '@common/emerald/wizard/utils/use-wizard-mobile';
import { useWizardStyles } from '@common/emerald/wizard/wizard.styles';
import { Box, Group, rem } from '@mantine/core';
import { memo } from 'react';
import { useIssueDebitCardWizard } from './create-wizard';

function IssueDebitCardWizardBody() {
  const isMobile = useWizardMobile();
  const { currentStep, isFinalStep } = useIssueDebitCardWizard();
  const { classes } = useWizardStyles();
  const hasPreviewStep = !!currentStep?.previewElement;

  return (
    <Group
      className={isFinalStep ? classes.finalStepBody : classes.body}
      gap={rem(100)}
    >
      <Box className={classes.contentPanel} maw={hasPreviewStep ? 400 : '100%'}>
        {currentStep?.element}
      </Box>
      {isMobile || !hasPreviewStep ? null : currentStep.previewElement}
    </Group>
  );
}

export default memo(IssueDebitCardWizardBody);
