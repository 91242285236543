import { Suspense, useState } from 'react';
import ReviewAndPay from './review-and-pay';
import PaymentSuccess from 'areas/credit/repayment/payment-success';
import PaymentError from 'areas/credit/repayment/payment-error';
import { DepositAccount, PlaidAccount } from 'types/move-funds.model';
import { RightContentModal } from '@common/composites/modal/right-content.modal';
import ChargeReviewAndPay from 'areas/charge-account/repayment/charge-repayment';
import { rem } from '@mantine/core';

type Props = {
  isChargePay?: boolean;
  closeModal: () => void;
  isBillPayCredit?: boolean;
};
const CardRepaymentModal = ({
  isChargePay,
  closeModal,
  isBillPayCredit,
}: Props) => {
  const [step, setStep] = useState<'review' | 'success' | 'error'>('review');
  const [amountPaid, setAmountPaid] = useState(0);
  const [paymentId, setPaymentId] = useState('');
  const [paymentAccount, setPaymentAccount] = useState<
    PlaidAccount | DepositAccount | undefined
  >();
  const [paymentStatus, setPaymentStatus] = useState('');

  const getContent = () => {
    switch (step) {
      case 'review':
        return (
          // make the data of each credit program independent
          isChargePay ? (
            <ChargeReviewAndPay
              closeModal={closeModal}
              onSuccess={(id, paymentAmount, paymentAcct, status) => {
                setAmountPaid(paymentAmount);
                setPaymentId(id);
                setPaymentAccount(paymentAcct);
                setStep('success');
                setPaymentStatus(status);
              }}
              onError={() => setStep('error')}
              isBillPayCredit={isBillPayCredit}
            />
          ) : (
            <ReviewAndPay
              closeModal={closeModal}
              onSuccess={(id, paymentAmount, paymentAcct, status) => {
                setAmountPaid(paymentAmount);
                setPaymentId(id);
                setPaymentAccount(paymentAcct);
                setStep('success');
                setPaymentStatus(status);
              }}
              onError={() => setStep('error')}
            />
          )
        );
      case 'success':
        return (
          <PaymentSuccess
            paymentAccount={paymentAccount}
            paymentAmount={amountPaid}
            paymentId={paymentId}
            onCloseClick={closeModal}
            setStep={setStep}
            paymentStatus={paymentStatus}
            isChargePay={isChargePay ?? false}
          />
        );
      case 'error':
        return (
          <PaymentError
            onClose={closeModal}
            onTryAgain={() => {
              setPaymentId('');
              setAmountPaid(0);
              setPaymentAccount(undefined);
              setStep('review');
            }}
          />
        );
    }
  };

  return (
    <Suspense fallback={null}>
      <RightContentModal>
        <RightContentModal.Header
          titleSize={rem(30)}
          title={`Make a ${isChargePay ? 'charge' : 'credit'} payment`}
        />
        <RightContentModal.Body>{getContent()}</RightContentModal.Body>
      </RightContentModal>
    </Suspense>
  );
};

export default CardRepaymentModal;
