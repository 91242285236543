import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const detailsCardStyles = createStyles((theme, _, helpers) => ({
  cardDetails: {
    backgroundColor: theme.colors.neutral[2],
    minHeight: '100vh',
    color: theme.colors.primarySecondarySuccess[8],
  },
  contentContainer: {
    padding: `${theme.spacing.xl} ${rem(32)}`,
    [helpers.smallerThan('sm')]: {
      overflowX: 'hidden',
      padding: theme.spacing.sm,
    },
  },
  endContent: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  whiteOutlineButton: {
    backgroundColor: theme.colors.neutral[0],
    '&:not([data-disabled])&:hover': {
      backgroundColor: theme.colors.neutral[0],
    },
  },
  last4Input: {
    fontSize: rem(48),
    color: theme.colors.primarySecondarySuccess[8],
    textAlign: 'center',
    height: 'fit-content',
    borderBottom: `3px solid ${theme.primaryColor}`,
    '&:focus': {
      borderBottom: `3px solid ${theme.primaryColor}`,
    },
    [helpers.smallerThan('sm')]: {
      fontSize: rem(48),
    },
  },
  multiselect: {
    span: {
      fontSize: '12px',
    },
  },
}));
