import { createWizard } from '@common/emerald/wizard/create-wizard';
import {
  DomesticAchRecipient,
  DomesticWireRecipient,
  InternationalRecipient,
} from 'areas/payments/components/send-payment/international-payments/util/types';
import type { PaymentMethodStepValue } from './add-recipient';
import type { RecipientDetailsStepFormValues } from './steps/recipient-details/recipient-details-step';

export type AddRecipientState = {
  paymentMethod: PaymentMethodStepValue;
  recipientDetailsFormValues: RecipientDetailsStepFormValues;
  domesticAchRecipientFormValues: DomesticAchRecipient;
  domesticWireRecipientFormValues: DomesticWireRecipient;
  beneficiaryRequirementsFormValues: Partial<InternationalRecipient>;
  showIntlPaymentStep: boolean;
};

export const [AddRecipientWizard, useAddRecipientWizard] =
  createWizard<AddRecipientState>();
