import { useRecoilValue } from 'recoil';
import { CardByUser } from 'services/flexbase/banking.model';
import { IsComptroller } from 'recoil-state/application/product-onboarding';
import { CardTableRow } from '@common/utilities/filter/cards-filter/card-table-row.model';
import { CardsTableHeader } from '@common/composites/header/cards-table-header/cards-table-header';
import { DebitCardTypeFilter } from '@common/utilities/filter/cards-filter/filters/card-type.filter';
import { DebitCardNameFilter } from '@common/utilities/filter/cards-filter/filters/card-name.filter';
import { DebitCardNumberFilter } from '@common/utilities/filter/cards-filter/filters/card-number.filter';
import { DebitCardStatusFilter } from '@common/utilities/filter/cards-filter/filters/card-status.filter';
import { DebitCardsCardholderFilter } from '@common/utilities/filter/cards-filter/filters/card-holder.filter';
import { DebitCardsDateRangeFilter } from '@common/utilities/filter/cards-filter/filters/cards-date-range.filter';
import { DebitCardsAmountSpentFilter } from '@common/utilities/filter/cards-filter/filters/card-spent-amounts.filter';
import {
  useDebitCardsFilters,
  useDebitCardsModalFilters,
} from '@common/utilities/filter/cards-filter/use-cards-filters';
import { useNavigate } from 'react-router-dom';

type Props = {
  accountId?: string;
  debitCards: CardByUser[];
};

const DebitCardsHeader = ({ debitCards, accountId }: Props) => {
  const isComptroller = useRecoilValue(IsComptroller);
  const filterHook = useDebitCardsFilters();
  const modalFilterHook = useDebitCardsModalFilters();
  const navigate = useNavigate();

  const cardsData = debitCards as CardTableRow[];

  const handleIssueDebitCard = () => {
    navigate('/banking/debit-cards/new');
  };

  const filters = [
    {
      key: 'status',
      header: 'Status',
      component: <DebitCardStatusFilter />,
    },
    {
      key: 'type',
      header: 'Type',
      component: <DebitCardTypeFilter />,
    },
    {
      key: 'cardholder',
      header: 'Cardholder',
      component: <DebitCardsCardholderFilter cards={cardsData} />,
    },
    {
      key: 'number',
      header: 'Number',
      component: <DebitCardNumberFilter cards={cardsData} />,
    },
    {
      key: 'name',
      header: 'Card name',
      component: <DebitCardNameFilter cards={cardsData} />,
    },
    {
      key: 'spent',
      header: 'Monthly usage',
      component: <DebitCardsAmountSpentFilter />,
    },
    {
      key: 'date',
      header: 'Last modified',
      component: <DebitCardsDateRangeFilter />,
    },
  ];

  return (
    <CardsTableHeader
      filters={filters}
      filterHook={filterHook}
      issueCardLabel="New debit card"
      modalFilterHook={modalFilterHook}
      onIssueCard={handleIssueDebitCard}
      userCanIssueCards={isComptroller && !accountId}
    />
  );
};

export default DebitCardsHeader;
