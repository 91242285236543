import { Box, Flex, Radio, Text, TextInput, rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { useForm } from '@mantine/form';
import { validateBirthDateInSpecifiedFormat } from '@utilities/validators/validate-dates';
import {
  ConditionalFieldValidator,
  RequiredFieldValidator,
} from '@utilities/validators/validate-required';
import FlexPatternFormat from '@common/flex-pattern-format';
import { IndividualOrCompany } from 'areas/payments/components/send-payment/international-payments/util/types';
import {
  AddRecipientWizard,
  useAddRecipientWizard,
} from '../../add-recipient-wizard';
import { PiBag, PiUser } from 'react-icons/pi';
import { ElementType } from 'react';

export type RecipientDetailsStepFormValues = {
  name: string;
  firstName?: string;
  lastName?: string;
  dob?: string | undefined;
  type: IndividualOrCompany;
};

export const isIndividual = (type: IndividualOrCompany) =>
  type === 'individual';

type RadioItemProps = {
  value: string;
  label: string;
  Icon: ElementType;
  iconColor: string;
  onChange: (val: IndividualOrCompany) => void;
  isChecked: boolean;
};

const RadioItem = ({
  value,
  label,
  Icon,
  iconColor,
  onChange,
  isChecked,
}: RadioItemProps) => {
  const { classes } = useStyles();
  return (
    <Box
      onClick={() => onChange(value as IndividualOrCompany)}
      mb={rem(16)}
      className={classes.radioContainer}
    >
      <Radio
        value={value}
        label={
          <Flex display="flex" align="center" fw={isChecked ? 700 : 400}>
            <Icon
              color={iconColor}
              size="1.25rem"
              style={{ marginRight: '0.5rem' }}
            />
            {label}
          </Flex>
        }
      />
    </Box>
  );
};

const useRecipientDetailsForm = (
  initialValues: RecipientDetailsStepFormValues,
) => {
  const validateNameFields = (
    recipientType: IndividualOrCompany,
    fieldLabel: string,
    value?: string,
  ) => {
    if (!value) {
      return `${fieldLabel} is required.`;
    }
    const includesNumberValues = (val: string) => {
      return /[0-9]/.test(val);
    };

    if (isIndividual(recipientType)) {
      return !includesNumberValues(value)
        ? null
        : `${fieldLabel} must not contain numbers.`;
    }
  };

  return useForm<RecipientDetailsStepFormValues>({
    initialValues,
    validate: {
      name: (value, formValues) =>
        ConditionalFieldValidator(!isIndividual(formValues.type))(value),
      firstName: (value, formValues) =>
        isIndividual(formValues.type)
          ? validateNameFields(formValues.type, 'First name', value)
          : null,
      lastName: (value, formValues) =>
        isIndividual(formValues.type)
          ? validateNameFields(formValues.type, 'Last name', value)
          : null,
      dob: (value, formValues) => {
        if (isIndividual(formValues.type) && !!value) {
          return validateBirthDateInSpecifiedFormat('yyyy-MM-dd', value)
            ? null
            : 'Please provide a valid date of birth.';
        }
      },
      type: RequiredFieldValidator(),
    },
  });
};

export const RecipientDetailsStep = () => {
  const { goToNextStep, setState, state } = useAddRecipientWizard();
  const form = useRecipientDetailsForm(state.recipientDetailsFormValues);
  const { theme } = useStyles();

  const handleNext = () => {
    const validationResult = form.validate();
    if (validationResult.hasErrors) {
      return;
    } else {
      setState((prev) => ({
        ...prev,
        recipientDetailsFormValues: form.values,
      }));
      goToNextStep();
    }
  };

  const handleRecipientTypeOnchange = (val: IndividualOrCompany) => {
    form.reset();
    form.setFieldValue('type', val);
  };

  const handleFirstNameChange = (firstName: string) => {
    form.setValues({
      ...form.values,
      firstName,
      name: `${firstName} ${form.values.lastName}`,
    });
  };

  const handleLastNameChange = (lastName: string) => {
    form.setValues({
      ...form.values,
      lastName,
      name: `${form.values.firstName} ${lastName}`,
    });
  };

  const handleDobChange = (dob: string) => {
    const isInitialDobValue = (val: string) => {
      return val === '____-__-__';
    };

    const parsedDob = isInitialDobValue(dob) ? '' : dob;
    form.setFieldValue('dob', parsedDob);
  };

  const individualIconColor =
    form.values.type === 'individual'
      ? theme.colors.primarySecondarySuccess[6]
      : theme.colors.neutral[5];
  const companyIconColor =
    form.values.type === 'company'
      ? theme.colors.primarySecondarySuccess[6]
      : theme.colors.neutral[5];

  const radioOptions = [
    {
      value: 'individual',
      label: 'Person',
      Icon: PiUser,
      iconColor: individualIconColor,
    },
    {
      value: 'company',
      label: 'Business',
      Icon: PiBag,
      iconColor: companyIconColor,
    },
  ];

  return (
    <AddRecipientWizard.Step onNext={handleNext} hideBack>
      <Radio.Group
        mt={'md'}
        label="Recipient type"
        {...form.getInputProps('type')}
      >
        {radioOptions.map((option) => (
          <RadioItem
            key={option.value}
            onChange={handleRecipientTypeOnchange}
            value={option.value}
            label={option.label}
            Icon={option.Icon}
            iconColor={option.iconColor}
            isChecked={form.values.type === option.value}
          />
        ))}
      </Radio.Group>

      {isIndividual(form.values.type) ? (
        <>
          <Flex gap="md">
            <TextInput
              mt={'md'}
              w="50%"
              label="First name"
              placeholder="Enter first name"
              {...form.getInputProps('firstName')}
              onChange={(e) => {
                handleFirstNameChange(e.target.value);
              }}
            />
            <TextInput
              mt={'md'}
              w="50%"
              label="Last name"
              placeholder="Enter last name"
              {...form.getInputProps('lastName')}
              onChange={(e) => {
                handleLastNameChange(e.target.value);
              }}
            />
          </Flex>
          <FlexPatternFormat
            mt={'md'}
            label={
              <Text>
                Date of birth{' '}
                <Text color={theme.colors.neutral[6]} span>
                  (Optional)
                </Text>
              </Text>
            }
            placeholder="Date of birth"
            description="Format: YYYY-MM-DD"
            c={'neutral.8'}
            format="####-##-##"
            mask="_"
            allowEmptyFormatting
            {...form.getInputProps('dob')}
            onChange={(e) => {
              handleDobChange(e.target.value);
            }}
          />
        </>
      ) : (
        <TextInput
          mt={'md'}
          label="Business name"
          placeholder="Enter business name"
          {...form.getInputProps('name')}
        />
      )}
    </AddRecipientWizard.Step>
  );
};

RecipientDetailsStep.stepId = 'recipient-details-step';

const useStyles = createStyles((theme) => ({
  radioContainer: {
    border: `1px solid ${theme.colors.primarySecondarySuccess[2]}`,
    padding: rem(20),
    borderRadius: rem(8),
    background: theme.colors.contentBackground[2],
  },
}));
