import { atom, selector } from 'recoil';
import {
  flexbaseBankingClient,
  flexbaseOnboardingClient,
} from 'services/flexbase-client';
import type { DepositAccount, PlaidAccount } from 'types/move-funds.model';
import { ApplicationState } from './application/product-onboarding';

// the Payment Account used for Card Payments
export const paymentAccount = atom<PlaidAccount | DepositAccount | undefined>({
  key: 'card_payment_account',
  default: undefined,
});

// Fetch and cache a User's deposit accounts
export const depositAccountsQuery = selector<DepositAccount[]>({
  key: 'banking/depositAccounts',
  get: async () => {
    try {
      const { accounts } = await flexbaseBankingClient.getDepositList();
      return (accounts ?? []).map((account) => ({
        ...account,
        plaidOrDeposit: 'deposit',
      }));
    } catch (e) {
      return [];
    }
  },
});

// Fetch and cache a Company's Plaid-linked accounts
export const plaidAccountsQuery = selector<PlaidAccount[]>({
  key: 'banking/plaidAccounts',
  get: async () => {
    try {
      const { accounts } =
        await flexbaseOnboardingClient.getPlaidCompanyAccounts();
      return (accounts ?? []).map((account) => ({
        ...account,
        plaidOrDeposit: 'plaid',
      }));
    } catch (e) {
      return [];
    }
  },
});

// handle Payment Account state with a reasonable, derived default
export const paymentAccountQuery = selector<
  DepositAccount | PlaidAccount | undefined
>({
  key: 'banking/defaultPaymentAccount',
  get: ({ get }) => {
    const selection = get(paymentAccount);
    if (!selection) {
      const { company } = get(ApplicationState);
      const depositAccounts = get(depositAccountsQuery).filter(
        (a) => a.status === 'Open',
      );
      const plaidAccounts = get(plaidAccountsQuery).filter(
        (account) => account.active && !account.unlinked,
      );
      return (
        depositAccounts.find((account) => account.isDefault) ??
        depositAccounts[0] ??
        plaidAccounts.find(
          (account) => account.id === company.primaryPlaidAccount,
        ) ??
        plaidAccounts[0]
      );
    }
    return selection;
  },
  set: ({ set }, account) => set(paymentAccount, account),
});
