import { IconType } from 'react-icons';
import { PiBank, PiMapPin } from 'react-icons/pi';
import { Avatar, Box, Flex, rem, Text, useMantineTheme } from '@mantine/core';
import { Recipient } from 'types/recipient';
import { ParsedAccount } from 'types/parsed-account';
import { getMonthDayYear } from '@utilities/dates';
import { parseAccountsInfo } from '@utilities/payments-rows';
import { getInitialsOfNames } from '@utilities/object';
import GetPaddedAccountMask from '@utilities/formatters/get-padded-account-mask';

type RecipientInfoProps = {
  Icon: IconType;
  primaryInfo: string;
  secondaryInfo: string;
};

const RecipientInfo = ({
  Icon,
  primaryInfo,
  secondaryInfo,
}: RecipientInfoProps) => {
  const theme = useMantineTheme();

  return (
    <Flex mt="xs" gap="xs" align="center">
      <Avatar>
        <Icon fill={theme.colors.neutral[5]} size="1.5rem" />
      </Avatar>
      <Box>
        <Text size="sm">{primaryInfo}</Text>
        <Text size="sm">{secondaryInfo}</Text>
      </Box>
    </Flex>
  );
};

type ReviewBodyRecipientProps = {
  recipient: Recipient;
  recipientName: string;
  recipientAccountType: string;
  recipientAccount?: ParsedAccount;
};

const ReviewBodyRecipient = ({
  recipient,
  recipientName,
  recipientAccount,
  recipientAccountType,
}: ReviewBodyRecipientProps) => {
  const theme = useMantineTheme();

  // getting the last payment date for this recipient
  const recipientAccounts = parseAccountsInfo([
    ...(recipient?.ach || []),
    ...(recipient?.wire || []),
  ]);
  const mostRecentPayAcc = recipientAccounts.reduce((latest, current) => {
    if (current.lastUsedAt) {
      if (
        !latest.lastUsedAt ||
        new Date(current.lastUsedAt) > new Date(latest.lastUsedAt)
      ) {
        return current;
      }
    }
    return latest;
  }, recipientAccounts[0] || {});

  const recipientAccountName =
    recipientAccount?.nickName ?? recipientAccount?.name;
  const recipientAddress = recipientAccount?.address;

  return (
    <>
      <Text mt={rem(40)} fw="500" size="xl">
        Recipient details
      </Text>
      <Box
        mt="xl"
        p="xl"
        bg="neutral.0"
        sx={{ border: `1px solid ${theme.colors.neutral[3]}` }}
      >
        <Flex gap="xs" align="center">
          <Avatar>
            <Text c="neutral.6" size="sm" fw={600}>
              {getInitialsOfNames(recipientName)?.toUpperCase()}
            </Text>
          </Avatar>
          <Box>
            <Text fw={500}>{recipientName}</Text>
            {mostRecentPayAcc.lastUsedAt && (
              <Text size="sm">
                Last paid on {getMonthDayYear(mostRecentPayAcc.lastUsedAt)}
              </Text>
            )}
          </Box>
        </Flex>

        {recipientAddress && (
          <Box mt="lg">
            <Text fw={500} c="neutral.7">
              Recipient address
            </Text>
            <RecipientInfo
              Icon={PiMapPin}
              primaryInfo={`${recipientAddress.address} ${recipientAddress.address2}`}
              secondaryInfo={`${recipientAddress.city}, ${recipientAddress.state} ${recipientAddress.postalCode}`}
            />
          </Box>
        )}
        <Box mt="lg">
          <Text fw={500} c="neutral.7">
            Payment method
          </Text>
          <RecipientInfo
            Icon={PiBank}
            primaryInfo={`${recipientAccountName} via ${recipientAccountType}`}
            secondaryInfo={`Account ${GetPaddedAccountMask(recipientAccount?.accountNumber ?? '', 2)} • Routing ${GetPaddedAccountMask(recipientAccount?.routingNumber ?? '', 2)}`}
          />
        </Box>
      </Box>
    </>
  );
};

export default ReviewBodyRecipient;
