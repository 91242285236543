import SkeletonLoading from '@common/composites/loading/skeleton-loading';
import { Box } from '@mantine/core';
import { useActiveSpendPlans } from '@queries/use-spend-plans';
import { SpendPlansTabLayout } from './tab.layout';
import { SpendPlansTabEmptyState } from './empty';
import { usePlatformPersonContext } from 'providers/platform-person.context';
import {
  SpendPlanAdminView,
  SpendPlanView,
} from '@flexbase-eng/types/dist/accounting';

type ResultError = { isPending: boolean; isError: true; spendPlans: undefined };
type ResultPending = {
  isPending: true;
  isError: boolean;
  spendPlans: undefined;
};
type ResultSuccess = {
  isPending: false;
  isError: false;
  spendPlans: (SpendPlanAdminView | SpendPlanView)[];
};

const useGetActiveSpendPlansList = ():
  | ResultError
  | ResultPending
  | ResultSuccess => {
  const { person } = usePlatformPersonContext();

  const {
    data: managedPlans,
    isPending: isAdminPlansPending,
    isError: isAdminPlansError,
  } = useActiveSpendPlans({
    accountId: person.accountId,
    isAdmin: true,
  });

  const {
    data: enrolledPlans,
    isPending: isEnrolledPlansPending,
    isError: isEnrolledPlansError,
  } = useActiveSpendPlans({
    accountId: person.accountId,
    isAdmin: false,
  });

  const isError = isAdminPlansError || isEnrolledPlansError;
  const isPending = isAdminPlansPending || isEnrolledPlansPending;

  if (isError) {
    return {
      spendPlans: undefined,
      isPending,
      isError,
    };
  }

  if (isPending) {
    return {
      spendPlans: undefined,
      isPending,
      isError,
    };
  }

  const spendPlans = [
    ...managedPlans,
    ...enrolledPlans.filter((e) => !managedPlans.some((m) => m.id === e.id)),
  ];

  return {
    spendPlans,
    isPending,
    isError,
  };
};

const SpendPlansActive = () => {
  const { spendPlans, isPending, isError } = useGetActiveSpendPlansList();

  if (isError) {
    return <Box>Something went wrong. Please try again later.</Box>;
  }

  if (isPending) {
    return <SkeletonLoading />;
  }

  if (!spendPlans.length) {
    return <SpendPlansTabEmptyState isActiveTab={true} />;
  }

  return <SpendPlansTabLayout spendPlans={spendPlans} />;
};

export default SpendPlansActive;
