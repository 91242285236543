import { useSpendTransactionModalFilters } from '../use-spend-transactions-filter';
import { SimpleSegmentSelectedFilter } from '@common/utilities/filter/simple-segmented.filter';

const SYNC_STATUS_OPTIONS = [
  {
    label: 'Synced',
    filterCriteria: ['synced'],
    key: 'synced',
  },
  {
    label: 'Not synced',
    filterCriteria: ['notSynced'],
    key: 'notSynced',
  },
  {
    label: 'Sync error',
    filterCriteria: ['failure'],
    key: 'syncError',
  },
];

export const SpendSyncStatusFilter = () => {
  const spendTransactionFilters = useSpendTransactionModalFilters();

  return (
    <SimpleSegmentSelectedFilter
      filterHook={spendTransactionFilters}
      filterKey="sync"
      options={SYNC_STATUS_OPTIONS}
      label="Sync"
      includesValue={(row) => row.syncedExpense?.status || ''}
    />
  );
};
