import { MultiSelect } from '@mantine/core';
import { useMemo } from 'react';
import { uniq } from 'underscore';
import { useSpendTransactionModalFilters } from '../use-spend-transactions-filter';
import { TransactionData } from '@services/flexbase/spend-plans.model';

type Props = { transactions: TransactionData[] };

export const SpendCategoryFilter = ({ transactions }: Props) => {
  const category = useMemo(() => {
    if (transactions) {
      return uniq(transactions.map((t) => t.category).filter((t) => !!t));
    }
    return [];
  }, [transactions]);

  const { addFilter, removeFilter, getFilterByKey } =
    useSpendTransactionModalFilters();

  return (
    <MultiSelect
      data={category}
      placeholder="Category"
      searchable
      value={getFilterByKey('category')?.filterValue || []}
      onChange={(val) => {
        if (val.length > 0) {
          addFilter('category', {
            key: 'category',
            filterValue: val,
            fn: (row) => val.includes(row.category),
            label: `Category: ${val.join(', ')}`,
            showChip: true,
          });
        } else {
          removeFilter('category');
        }
      }}
    />
  );
};
