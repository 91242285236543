import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  actionBtn: {
    backgroundColor: '#fff',
    color: theme.colors[theme.primaryColor][8],
    borderColor: theme.colors.neutral[3],
    display: 'inline-flex',
    gap: theme.spacing.xs,

    '&[data-disabled]': {
      backgroundColor: theme.colors.neutral[0],
      color: theme.colors.neutral[5],
    },
  },
}));
