import Amazon from 'assets/images/amazon.svg';
import GoogleWorkspace from 'assets/images/google-workspace.png';
import Meta from 'assets/images/meta.svg';
import OracleNetsuite from 'assets/images/oracle-netsuite.png';
import PayPal from 'assets/images/paypal.png';
import QuickbooksGreen from 'assets/images/quickbooks.png';
import Sage from 'assets/images/sage.png';
import Shopify from 'assets/images/shopify.svg';
import Stripe from 'assets/images/stripe.png';
import Xero from 'assets/images/xero.png';
import { IntegrationCategory, IntegrationPlatform } from './';
import { ReactNode } from 'react';
import { Image } from '@mantine/core';

export type IntegrationCardItem = {
  /**
   * Card title. Most likely the integration platform name.
   */
  title: string;

  /**
   * Abbreviated name of the platform.
   */
  abbreviation: string;

  /**
   * A way to group integrations.
   */
  category: IntegrationCategory | undefined;

  /**
   * Additional information about this integration.
   */
  tags: string[];

  /**
   * Card description. Describe the integration.
   */
  content: string;

  /**
   * Icon component
   */
  iconComponent: ReactNode;

  /**
   * Whether to show this platform in the list.
   */
  visible: boolean;

  /**
   * The integration platform as an enum.
   */
  platform: IntegrationPlatform | undefined;
};

type IntegrationIconProps = {
  src: string;
  imagePadding?: number;
};

const IntegrationIcon = ({ src, imagePadding = 0 }: IntegrationIconProps) => {
  return (
    <Image
      src={src}
      height={50}
      width={50}
      radius={200}
      styles={(t) => ({
        image: {
          border: `1px solid ${t.colors.neutral[3]}`,
          padding: imagePadding,
        },
      })}
    />
  );
};

export function getIntegrationCards(): IntegrationCardItem[] {
  return [
    {
      title: 'Quickbooks Online',
      abbreviation: 'QBO',
      category: 'accounting',
      tags: ['Bank and credit feeds'],
      visible: true,
      content:
        'Integrate your Flex line of banking and credit accounts to QBO bank feeds',
      iconComponent: <IntegrationIcon src={QuickbooksGreen} />,
      platform: 'INTUIT_BANK_FEEDS',
    },
    {
      title: 'Quickbooks Online',
      abbreviation: 'QBO',
      category: 'accounting',
      tags: ['Expenses and accounts payable'],
      visible: true,
      content:
        'Track income and expenses, manage payroll, invoicing, taxes, and inventory',
      iconComponent: <IntegrationIcon src={QuickbooksGreen} />,
      platform: 'QUICKBOOKS',
    },
    {
      title: 'Oracle NetSuite',
      abbreviation: 'NS',
      category: 'accounting',
      tags: [],
      visible: true,
      content:
        'Manage inventory, track financials, host e-commerce stores, and manage customer relationships',
      iconComponent: <IntegrationIcon src={OracleNetsuite} />,
      platform: 'NETSUITE',
    },
    {
      title: 'Xero',
      abbreviation: 'XR',
      category: 'accounting',
      tags: [],
      visible: true,
      content:
        'Integrates with your bank account to manage expenses, bills, payments, projects and payroll',
      iconComponent: <IntegrationIcon src={Xero} />,
      platform: 'XERO',
    },
    {
      title: 'Sage 300',
      abbreviation: 'Sage 300',
      platform: undefined,
      category: 'accounting',
      tags: [],
      visible: false,
      content:
        'Accounting, billing and time tracking, HR and project management',
      iconComponent: <IntegrationIcon src={Sage} />,
    },
    {
      title: 'Google Workspace',
      abbreviation: 'Google Workspace',
      platform: undefined,
      category: undefined,
      tags: [],
      visible: false,
      content:
        'Manage contacts, emails, calendars, video calls, and cloud storage for your organization',
      iconComponent: <IntegrationIcon src={GoogleWorkspace} />,
    },
    {
      title: 'Stripe',
      abbreviation: 'Stripe',
      platform: undefined,
      category: undefined,
      tags: [],
      visible: false,
      content: 'Manage payments, invoices, risk/fraud, tax and accounting',
      iconComponent: <IntegrationIcon src={Stripe} />,
    },
    {
      title: 'PayPal',
      abbreviation: 'PayPal',
      platform: undefined,
      category: 'payments',
      tags: [],
      visible: false,
      content:
        'Manage your digital wallet, send and receive money globally with alternative payment methods',
      iconComponent: <IntegrationIcon src={PayPal} />,
    },
    {
      title: 'Meta',
      abbreviation: 'Meta',
      platform: 'FACEBOOK',
      category: 'ads',
      tags: [],
      visible: true,
      content: 'Import your Meta data to expedite the application process.',
      iconComponent: <IntegrationIcon src={Meta} imagePadding={4} />,
    },
    {
      title: 'Shopify',
      abbreviation: 'Shopify',
      platform: 'SHOPIFY',
      category: 'commerce',
      tags: [],
      visible: true,
      content:
        'Import your Shopify store data to expedite the application process.',
      iconComponent: <IntegrationIcon src={Shopify} />,
    },
    {
      title: 'Amazon',
      abbreviation: 'Amazon',
      platform: 'AMAZON',
      category: 'commerce',
      tags: [],
      visible: true,
      content:
        'Import your Amazon store data to expedite the application process.',
      iconComponent: <IntegrationIcon src={Amazon} imagePadding={4} />,
    },
  ];
}
