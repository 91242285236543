import { useNavigate } from 'react-router-dom';
import { useCardCTAStyles } from '../accounts/styles';
import { useRecoilValue } from 'recoil';
import { UserInfoState } from '../../../types/user-info';
import { AspectRatio, Button, Image } from '@mantine/core';
import MoneyPile from '../../../assets/images/money-pile.png';

export const MakePaymentWidget = () => {
  const navigate = useNavigate();
  const { classes } = useCardCTAStyles({});
  const user = useRecoilValue(UserInfoState);
  return (
    <div className={classes.card}>
      <div className={classes.contentCard}>
        <div>
          <span className={classes.titleCard}>Send a payment</span>
          <p>A simple and easy-to-use way to send payments.</p>
        </div>
        <Button
          onClick={() => navigate('/payments/outgoing/recipient')}
          disabled={!user.roles.includes('ADMIN')}
        >
          Pay now
        </Button>
      </div>
      <AspectRatio ratio={1} w={240} h={120} mt={20} mr={15}>
        <Image src={MoneyPile} />
      </AspectRatio>
    </div>
  );
};
