import { useState } from 'react';
import { showNotification } from '@mantine/notifications';
import { Box, Button, Flex, rem, Text } from '@mantine/core';
import VGSerror from '../../components/vgs-error/vgs-error';
import { useVGScollect } from '../../../../utilities/custom-hooks/vgs-collect';
import { createStyles } from '@mantine/emotion';

type PINprops = {
  bearerToken: string;
  unitcoCardId: string;
  onCancel: () => void;
  onSuccess: () => void;
};

const SetDebitCardPIN = ({
  unitcoCardId,
  bearerToken,
  onSuccess,
  onCancel,
}: PINprops) => {
  const { classes } = useSetPINstyles();
  const [isLoading, setIsLoading] = useState(false);
  const { form, errors, validateForm, errorOnInitForm } =
    useVGScollect('setPIN');

  let errorMessage = errors.validationPinMessage;

  const handleSubmit = async () => {
    const hasErrors = validateForm();
    if (!hasErrors && form) {
      setIsLoading(true);
      form.submit(
        '/cards/' + unitcoCardId + '/secure-data/pin',
        {
          // This converts the dot-separated field name strings into a JSON object
          mapDotToObject: 'true',
          headers: {
            'Content-Type': 'application/vnd.api+json',
            Authorization: 'Bearer ' + bearerToken,
          },
          method: 'POST',
        },
        (status: any, data: any) => {
          setIsLoading(false);
          if (status === 200) {
            onSuccess();
            showNotification({
              color: 'flexbase-teal',
              message: 'Your PIN has been set successfully!',
            });
          } else {
            errorMessage = data.errors[0].title;
          }
        },
      );
    }
  };

  return (
    <Box className={classes.pinContainer}>
      {errorOnInitForm ? (
        <>
          <VGSerror mx={rem(20)} withCloseButton={false} />
          <Flex justify="flex-end" m="md">
            <Button variant="outline" onClick={onCancel}>
              Cancel
            </Button>
          </Flex>
        </>
      ) : (
        <>
          <Text size="sm">Set your new PIN</Text>
          <Flex gap="md" justify="space-between">
            <Box id="cc-pin" className={classes.frameInput} />
            <Flex justify="flex-end" gap="md">
              <Button loading={isLoading} onClick={handleSubmit}>
                Save PIN
              </Button>
              <Button variant="outline" onClick={onCancel}>
                Cancel
              </Button>
            </Flex>
          </Flex>
          {errorMessage && (
            <Text size="sm" c="critical.2">
              {errorMessage}
            </Text>
          )}
        </>
      )}
    </Box>
  );
};

export default SetDebitCardPIN;

export const useSetPINstyles = createStyles((theme) => ({
  pinContainer: {
    margin: `${rem(20)} ${rem(50)}`,
    iframe: {
      width: '100%',
      height: '100%',
    },
    '@media(max-width: 767px)': {
      marginLeft: rem(20),
      marginRight: rem(20),
    },
  },
  frameInput: {
    height: rem(40),
    fontWeight: 500,
    fontSize: rem(16),
    lineHeight: rem(20),
    paddingLeft: theme.spacing.xs,
    borderRadius: theme.defaultRadius,
    background: theme.colors.neutral[0],
    border: `1px solid ${theme.colors.flexbaseGrey[1]}`,
  },
}));
