import { Stack, StackProps } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { PropsWithChildren } from 'react';

type PreviewRowProps = PropsWithChildren<StackProps>;

export const PreviewRow = ({ children, ...rest }: PreviewRowProps) => {
  const { classes } = useStyles();

  return (
    <Stack px={20} gap={4} className={classes.previewRow} {...rest}>
      {children}
    </Stack>
  );
};

const useStyles = createStyles((theme) => ({
  previewRow: {
    borderRadius: theme.defaultRadius,
    overflow: 'hidden',
    wordBreak: 'break-word',
  },
}));
