import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  baseContainer: {
    display: 'flex',
    flexDirection: 'row',
    '@media(max-width: 767px)': {
      flexDirection: 'column',
    },
  },
  autoPayContainer: {
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.colors.neutral[1]}`,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.colors.neutral[0],
    minHeight: rem(229),
    height: '100%',
    width: '100%',
    maxWidth: rem(620),
    position: 'relative',
  },
  autoPaySettings: {
    borderColor: theme.colors.flexbaseGrey[1],
    width: '100%',
    padding: rem(24),
  },
  headerCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: rem(28),
    marginBottom: rem(5),
  },
  title: {
    fontSize: rem(16),
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: rem(30),
    color: theme.colors.blackish[0],
  },
  grayTitle: {
    fontSize: rem(14),
    fontWeight: 400,
    fontStyle: 'normal',
    color: theme.colors.neutral[6],
  },
  dateAmountDue: {
    fontSize: rem(26),
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: rem(31),
    color: 'black',
    marginBottom: rem(30),
  },
  innerContent: {
    backgroundColor: theme.colors.contentBackground[2],
    minHeight: rem(389),
    width: rem(480),
    margin: 'auto',
    padding: theme.spacing.xl,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'start',
    border: `1px solid ${theme.colors.neutral[1]}`,
  },
  innerContentMobile: {
    backgroundColor: theme.colors.contentBackground[2],
    height: rem(530),
    width: rem(300),
    margin: 'auto',
    padding: theme.spacing.md,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'start',
    border: `1px solid ${theme.colors.neutral[1]}`,
  },
  editAutoPayCalculations: {
    padding: theme.spacing.xl,
    width: '100%',
  },
  borderLine: {
    marginTop: `-${theme.spacing.sm}`,
    marginRight: 'auto',
    marginBottom: 0,
    marginLeft: 'auto',
    marginBlock: '0 !important',
    width: '90%',
  },
  buttonsDiv: {
    width: '100%',
    marginTop: rem(16),
  },
  editAutoPayTitle: {
    fontFamily: 'PP Neue Montreal',
    fontSize: rem(20),
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: rem(32),
    textAlign: 'left',
    '@media(max-width: 767px)': {
      margin: rem(10),
    },
  },
  editAutoPaySubtitle: {
    fontFamily: 'PP Neue Montreal',
    fontSize: rem(16),
    fontStyle: 'normal',
    fontWeight: 400,
    color: theme.colors.neutral[7],
    marginTop: theme.spacing.xl,
  },
  errorText: {
    fontFamily: 'PP Neue Montreal',
    fontSize: rem(14),
    marginTop: theme.spacing.md,
    fontStyle: 'normal',
    fontWeight: 400,
    color: theme.colors.failure[1],
  },
}));
