import React, { forwardRef, useEffect, useState } from 'react';
import ReactGooglePlacesSuggest from 'react-google-places-suggest';
import {
  InputStylesNames,
  InputWrapperStylesNames,
  TextInput,
} from '@mantine/core';
import { createStyles } from '@mantine/emotion';

type ClassNames = Record<InputStylesNames | InputWrapperStylesNames, string> & {
  container: string;
};

type Props = {
  loading?: boolean;
  id: string;
  placeholder: string;
  value: string;
  onChange: (e: string) => void;
  label: string;
  classNames?: Partial<ClassNames>;
  className?: any;
  isRequired?: boolean;
  disabled?: boolean;
  onOptionSubmit: (item: {
    address: string;
    country: string;
    state: string;
    city: string;
    postalCode: string;
  }) => void;
} & React.RefAttributes<HTMLInputElement>;

export const useStyles = createStyles((theme) => ({
  container: {
    img: {
      display: 'none',
    },
  },
  selectList: {
    padding: 'calc(0.75rem / 1.5) 0.75rem',
    background: 'white',
    fontSize: '14px',
    fontWeight: 500,
    '&:hover': {
      background: theme.colors.primarySecondarySuccess[0],
    },
  },
}));

const GooglePlacesSuggest = forwardRef<HTMLInputElement, Props>(
  (
    {
      loading,
      id,
      placeholder,
      value,
      onChange,
      label,
      onOptionSubmit,
      classNames,
      className,
      isRequired,
      disabled,
      ...props
    }: Props,
    ref,
  ) => {
    const [googleScriptLoaded, setGoogleScriptLoaded] = useState(
      !!window.google?.maps,
    );
    const onScriptLoad = () => {
      setGoogleScriptLoaded(true);
    };
    const { classes, cx } = useStyles();
    const [search, setSearch] = useState('');
    const [sessionToken, setSessionToken] = useState<any>(null);

    /*
    This could be a custom hook, but it's not worth it because you need to pass in at least 3 variables :
    url, callback, and the window object that, if present, should prevent script loading (google scripts
    error if you load them multiple times, or so they warn)

    if (!window.google?.maps) {
      customHook();
    }

    ^ That fails because react bitches about hook ordering errors.
     */
    useEffect(() => {
      /*
      We want to load the google maps script when rendering this component but it stays on the window object
      if you go back and forth throughout the application, which could cause bugs. So we only load it if the
      window object does not contain the script already.
       */
      if (!window.google?.maps) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?v=weekly&key=${
          import.meta.env.VITE_APP_GOOGLE_API_KEY
        }&libraries=places,geocoding`;
        script.async = true;
        script.addEventListener('load', () => {
          onScriptLoad();
        });
        document.body.appendChild(script);
        return () => {
          document.body.removeChild(script);
        };
      }
    }, []);

    const handleInputChange = (e: any) => {
      setSearch(e.target.value);
      onChange(e.target.value);
    };

    const handleSelectSuggest = (geocodedPrediction: any) => {
      let address = '';
      let country = '';
      let state = '';
      let city = '';
      let postalCode = '';
      // Iterate through the address components to extract the necessary information.
      geocodedPrediction.address_components.forEach((component: any) => {
        if (component.types.includes('country')) {
          country = component.short_name;
        } else if (component.types.includes('administrative_area_level_1')) {
          state = component.short_name;
        } else if (component.types.includes('locality')) {
          city = component.long_name;
        } else if (component.types.includes('street_number')) {
          address = component.long_name;
        } else if (
          component.types.some((type: string) =>
            ['street_address', 'route', 'premise'].includes(type),
          )
        ) {
          address = address
            ? `${address} ${component.long_name}`
            : component.long_name;
        } else if (component.types.includes('postal_code')) {
          postalCode = component.long_name;
        } else if (component.types.includes('postal_code_suffix')) {
          postalCode = `${postalCode}-${component.long_name}`;
        }
      });
      onOptionSubmit({ address, country, city, postalCode, state });
      onChange(address);
      setSessionToken(new window.google.maps.places.AutocompleteSessionToken());
      setSearch('');
    };

    return (
      <div className={cx(classes.container, classNames?.container)}>
        {/* This breaks if window.google.maps does not exist */}
        {googleScriptLoaded && (
          <ReactGooglePlacesSuggest
            googleMaps={window.google.maps}
            autocompletionRequest={{
              input: search,
              componentRestrictions: { country: 'US' },
              sessionToken,
            }}
            customRender={(prediction) =>
              prediction ? (
                <div className={classes.selectList}>
                  {prediction.description}
                </div>
              ) : (
                <div className={classes.selectList}>No results found</div>
              )
            }
            onSelectSuggest={handleSelectSuggest}
          >
            <TextInput
              label={label}
              ref={ref}
              id={id}
              classNames={classNames}
              className={className}
              type="text"
              value={value}
              placeholder={placeholder}
              onChange={(e) => handleInputChange(e)}
              required={isRequired}
              disabled={disabled}
              {...props}
            />
          </ReactGooglePlacesSuggest>
        )}
      </div>
    );
  },
);

GooglePlacesSuggest.displayName = 'GooglePlacesSuggest';

export default GooglePlacesSuggest;
