import { Container, rem, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { CardByUser } from 'services/flexbase/banking.model';

type Props = {
  card: CardByUser;
};

const BillingAddress = ({ card }: Props) => {
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const { address } = card;
  const { street, street2, city, state, postalCode } = address;

  return (
    <Container p={0} mx={useMobileView ? rem(20) : rem(50)}>
      <Text mb={rem(8)} fz={rem(14)}>
        Billing address
      </Text>
      <Text fz={rem(20)}>{street}</Text>
      <Text fz={rem(20)}>{street2}</Text>
      <Text fz={rem(20)}>
        {city}, {state} {postalCode}
      </Text>
    </Container>
  );
};

export default BillingAddress;
