import { Anchor, Image, Slider, Text, useMantineTheme } from '@mantine/core';
import OpenCard from '../../assets/images/open-card.svg';
import ClosedCard from '../../assets/images/closed-card.svg';
import { Link, useNavigate } from 'react-router-dom';
import {
  ArrowDown,
  ArrowRight,
  ArrowUp,
  RewardsAirplane,
  RewardsCard,
  RewardsCloseIcon,
  RewardsDollarSign,
  RewardsMenu,
} from '../../assets/svg';
import React, { useState } from 'react';
import { useStyles } from './styles';
import { useMediaQuery } from '@mantine/hooks';

const RewardsBanner = () => {
  const theme = useMantineTheme();
  const [expanded, setExpanded] = useState(false);
  const [secondaryView, setSecondaryView] = useState(false);
  const bannerRef = React.createRef<HTMLDivElement>();
  const bottomRowRef = React.createRef<HTMLDivElement>();
  const mainBannerContent = React.createRef<HTMLDivElement>();
  const secondBannerContent = React.createRef<HTMLDivElement>();
  const { classes, cx } = useStyles();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const navigate = useNavigate();

  // TODO - Add redemption url links when mocks/links are available
  const redemptionOptions = [
    {
      label: (
        <>
          Miles <br />
          Transfer
        </>
      ),
      icon: <RewardsAirplane></RewardsAirplane>,
    },
    {
      label: (
        <>
          Order <br />
          Gift Card
        </>
      ),
      icon: <RewardsCard></RewardsCard>,
    },
    {
      label: (
        <>
          Statement <br />
          Credit
        </>
      ),
      icon: <RewardsMenu></RewardsMenu>,
    },
    {
      label: (
        <>
          Get <br />
          Cashback
        </>
      ),
      icon: <RewardsDollarSign></RewardsDollarSign>,
      onClick: () => navigate('/redemption'),
    },
  ];

  const toggleBannerExpansion = () => {
    const div = bannerRef.current;
    const bottomDiv = bottomRowRef.current;
    div?.classList.toggle(classes.collapsed);
    bottomDiv?.classList.toggle(classes.expandedBottomRow);
    setExpanded(!expanded);
  };

  const switchBanners = () => {
    mainBannerContent.current?.classList.toggle(classes.firstBannerOpaque);
    setTimeout(() => {
      mainBannerContent.current?.classList.toggle(classes.hiddenFirstBanner);
    }, 500);
    secondBannerContent.current?.classList.toggle(classes.revealedSecondBanner);
  };

  const handleOnClick = () => {
    toggleBannerExpansion();
  };

  const handleXClick = () => {
    toggleBannerExpansion();
    setSecondaryView(!secondaryView);
    switchBanners();
  };

  const handleBannerTransition = () => {
    setSecondaryView(!secondaryView);
    if (!expanded) {
      toggleBannerExpansion();
    }
    if (!secondaryView) {
      switchBanners();
    }
  };
  return useMobileView ? (
    <div className={classes.mobileBanner}>
      <div className={classes.mobileTextBlock} data-testId="textBlock">
        <Text className={classes.topTextRow} data-testId="topTextRow">
          Flex Points Available
        </Text>
        <Text className={classes.middleTextRow} data-testId="middleTextRow">
          102,200
        </Text>
        <Text className={classes.moneyText} data-testId="moneyText">
          Worth $10,200
        </Text>
      </div>
      <div className={classes.mobileTextBlock} data-testId="textBlock">
        <Text className={classes.topTextRow} data-testId="topTextRow">
          Pending Points
        </Text>
        <Text className={classes.middleTextRow} data-testId="middleTextRow">
          5000
        </Text>
        <Text className={classes.moneyText} data-testId="moneyText">
          Worth $500
        </Text>
      </div>
      <div
        className={classes.mobileRedeemPoints}
        data-testId="mobileRedeemPoints"
      >
        <div className={classes.mobileDollarSignIcon}>
          <RewardsDollarSign height={24} width={24}></RewardsDollarSign>
        </div>
        Redeem Points
        <div className={classes.mobileRedeemPointsArrow}>
          <ArrowRight></ArrowRight>
        </div>
      </div>
    </div>
  ) : (
    <div ref={bannerRef} className={classes.banner} data-testId="banner">
      <div
        ref={mainBannerContent}
        className={classes.firstBanner}
        data-testId="firstBanner"
      >
        <div className={classes.cardImage} data-testId="cardImage">
          <div
            className={expanded ? classes.bigCard : classes.littleCard}
            data-testId="bigCard/littleCard"
          >
            <Image src={expanded ? OpenCard : ClosedCard} width={294} />
            <Text
              className={cx(
                classes.name,
                expanded && classes.expandedCardName,
                !expanded && classes.cardName,
              )}
            >
              Firstname
              <br /> Lastname
            </Text>
          </div>
        </div>
        <div className={classes.bigRow} data-testId="bigRow">
          <div className={classes.topRow} data-testId="topRow">
            <div className={classes.topRowWide} data-testId="topRowWide">
              <div className={classes.textBlock} data-testId="textBlock">
                <Text className={classes.topTextRow} data-testId="topTextRow">
                  Flex Points Available
                </Text>
                <Text
                  className={classes.middleTextRow}
                  data-testId="middleTextRow"
                >
                  102,200
                </Text>
                <Text className={classes.moneyText} data-testId="moneyText">
                  Worth $10,200
                </Text>
              </div>
              <div className={classes.textBlock} data-testId="textBlock">
                <Text className={classes.topTextRow} data-testId="topTextRow">
                  Pending Points
                </Text>
                <Text
                  className={classes.middleTextRow}
                  data-testId="middleTextRow"
                >
                  5000
                </Text>
                <Text className={classes.moneyText} data-testId="moneyText">
                  Worth $500
                </Text>
              </div>
              <div className={classes.textBlock} data-testId="textBlock">
                <Text className={classes.topTextRow} data-testId="topTextRow">
                  Active Promos
                </Text>
                <Text
                  className={classes.middleTextRow}
                  data-testId="middleTextRow"
                >
                  Early Pay Boost
                </Text>
                <div className={classes.flexRow} data-testId="flexRow">
                  <Anchor
                    td="underline"
                    size="sm"
                    component={Link}
                    to=""
                    className={classes.brownTextLink}
                    onClick={handleBannerTransition}
                  >
                    How it works
                  </Anchor>
                  <ArrowRight style={{ marginLeft: '4px' }} />
                </div>
              </div>
            </div>
            <div className={classes.redeemPoints} data-testId="redeemPoints">
              <div
                className={classes.redeemPointsContent}
                data-testId="redeemPointsContent"
              >
                <div className={classes.smallIcon} data-testId="smallIcon">
                  <RewardsDollarSign height={24} width={24}></RewardsDollarSign>
                </div>
                <div
                  className={classes.flexRow}
                  onClick={handleOnClick}
                  data-testId="userSelect"
                >
                  <Text
                    className={classes.redeemPointsLink}
                    data-testId="redeemPointsLink"
                  >
                    Redeem <br />
                    Points
                  </Text>
                  {expanded ? (
                    <ArrowUp
                      height={24}
                      width={24}
                      className={classes.redeemPointsArrow}
                      data-testId="redeemPointsArrow"
                    />
                  ) : (
                    <ArrowDown
                      height={24}
                      width={24}
                      className={classes.redeemPointsArrow}
                      data-testId="redeemPointsArrow"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={classes.bottomRow}
            ref={bottomRowRef}
            data-testId="bottomRow"
          >
            <div className={classes.bottomTopRow} data-testId="bottomTopRow">
              <Text className={classes.waysToRedeem} data-testId="waysToRedeem">
                Ways to Redeem
              </Text>
            </div>
            <div
              className={classes.bottomBottomRow}
              data-testId="bottomBottomRow"
            >
              {redemptionOptions.map((option, index) => {
                return (
                  <div
                    key={index}
                    className={classes.redemptionSquare}
                    data-testId="redemptionSquare"
                    onClick={option.onClick}
                  >
                    <div
                      className={cx(classes.smallIcon, classes.redemptionIcons)}
                      data-testId="smallIcon"
                    >
                      {option.icon}
                    </div>
                    <div
                      className={classes.redemptionSquareBottomRow}
                      data-testId="redemptionSquareBottomRow"
                    >
                      <Text
                        className={classes.redemptionText}
                        data-testId="redemptionText"
                      >
                        {option.label}
                      </Text>
                      <div
                        className={classes.redemptionBottomArrow}
                        data-testId="redemptionBottomArrow"
                      >
                        <ArrowRight />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/*This div is the wrapper for the slider, which is overlayed on top of the original banner contents*/}
      <div
        ref={secondBannerContent}
        className={classes.secondBanner}
        data-testId="secondBanner"
      >
        <div className={classes.howItWorks}>
          <div style={{ margin: '38px 0 0 33px' }}>
            <Text className={classes.smallText}>How it works?</Text>
            <Text className={classes.mediumText}>Early Pay Boost</Text>
          </div>
        </div>
        <div className={classes.sliderDiv} data-testId="sliderDiv">
          <div
            style={{
              gridColumn: '1 / 2',
            }}
          >
            <div className={classes.sliderTextDiv}>
              <Text className={classes.smallishText}>Pay</Text>
              <Text className={classes.largeText}>$100,000</Text>
              <Text className={classes.smallText}>On Aug 1, 2024 (Day 1)</Text>
            </div>
          </div>
          <div
            className={classes.leftBorder}
            style={{
              gridColumn: '2 / 3',
            }}
          >
            <div className={classes.sliderTextDiv}>
              <Text className={classes.smallishText}>Multiplier</Text>
              <Text className={classes.largeText}>2x</Text>
              <Text className={classes.smallText}>Per Dollar Amount</Text>
            </div>
          </div>
          <div
            className={classes.leftBorder}
            style={{
              gridColumn: '3 / 4',
            }}
          >
            <div className={classes.sliderTextDiv}>
              <Text className={classes.smallishText}>Points Earned</Text>
              <Text className={classes.largeText}>100,000</Text>
              <Text className={classes.smallText}>Worth $10,000</Text>
            </div>
          </div>
          <div
            className={classes.leftBorder}
            style={{
              gridColumn: '4 / 5',
              cursor: 'pointer',
            }}
            onClick={handleXClick}
          >
            <RewardsCloseIcon
              height={24}
              width={24}
              style={{ margin: '100% 50%' }}
            />
          </div>
          <div className={classes.slider} onClick={(e) => e.stopPropagation()}>
            <Slider
              w={959}
              mt="115px"
              ml="67.5px"
              min={1}
              max={60}
              label={null}
              styles={{
                bar: { backgroundColor: '#E9ECEF' },
                track: { height: '5px' },
                markLabel: {
                  color: '7B7A78',
                  fontFamily: 'PP Neue Montreal',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: 'normal',
                },
                mark: {
                  height: '1rem',
                  width: '0.05rem',
                  marginTop: '-0.25rem',
                  color: '#E9ECEF',
                  backgroundColor: '#E9ECEF',
                },
                markFilled: { borderColor: '#E9ECEF' },
              }}
              // TODO - Make slider actually do something
              // onChangeEnd={(value) => console.log(value)}
              marks={[
                { value: 1, label: 'Day 1' },
                { value: 15, label: '15' },
                { value: 30, label: '30' },
                { value: 45, label: '45' },
                { value: 60, label: '60' },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardsBanner;
