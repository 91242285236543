import { Box, Grid, rem, Text } from '@mantine/core';
import { PropsWithChildren } from 'react';

type Props = {
  headline: string;
  subtitle: string;
} & PropsWithChildren;

const SummaryDetailLayout = ({ headline, subtitle, children }: Props) => {
  return (
    <Box>
      <Text size="sm" c="neutral.7">
        {subtitle}
      </Text>
      <Grid justify="space-between" align="center" mr="md">
        <Grid.Col span={{ xs: 12, sm: 7, lg: 8, xl: 9 }}>
          <Text fz={rem(32)} fw={500}>
            {headline}
          </Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 5, lg: 4, xl: 3 }}>{children}</Grid.Col>
      </Grid>
    </Box>
  );
};

export default SummaryDetailLayout;
