import { MouseEventHandler } from 'react';
import { Box, Button, Transition, alpha, rem, Flex } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import {
  FlexbaseTableBulkAction,
  FlexbaseTableFilteredSelectedState,
  FlexbaseTableSelectedState,
} from '../flexbase-table.models';
import { isFunction } from 'underscore';
import { PiX } from 'react-icons/pi';

type FlexbaseTableBulkActionsProps<T> = {
  label?: {
    singular: string;
    plural: string;
  };
  selectedState: FlexbaseTableSelectedState<T>;
  actions?: FlexbaseTableBulkAction<T>[];
  onClose: MouseEventHandler;
};

export const FlexbaseTableBulkActions = <T,>({
  label,
  selectedState,
  actions,
  onClose,
}: FlexbaseTableBulkActionsProps<T>) => {
  const { classes } = useStyles();

  function getFilteredSelectedState(
    action: FlexbaseTableBulkAction<T>,
  ): FlexbaseTableFilteredSelectedState<T> {
    const filteredState = {
      ...selectedState,
      unfilteredSelectedCount: selectedState.selectedCount,
      unfilteredSelectedRows: selectedState.selectedRows,
    };

    if (isFunction(action.selectionFilter)) {
      const filteredItems = selectedState.selectedRows.filter(
        action.selectionFilter,
      );

      filteredState.selectedCount = filteredItems.length;
      filteredState.selectedRows = filteredItems;
    }

    return filteredState;
  }

  const visibleActions = actions?.filter((action) => {
    return isFunction(action.hide)
      ? !action.hide(getFilteredSelectedState(action))
      : !action.hide;
  });

  if (!visibleActions?.length) {
    return false;
  }

  const rowOrRowsLabel =
    selectedState.selectedCount === 1
      ? (label?.singular ?? 'row')
      : (label?.plural ?? 'rows');
  const description = `${selectedState.selectedCount} ${rowOrRowsLabel} selected`;

  const getActionDisabled = (action: FlexbaseTableBulkAction<T>) => {
    return isFunction(action.disabled)
      ? action.disabled(getFilteredSelectedState(action))
      : action.disabled;
  };

  const getActionLabel = (action: FlexbaseTableBulkAction<T>) => {
    return isFunction(action.label)
      ? action.label(getFilteredSelectedState(action))
      : action.label;
  };

  const handleActionClick = (action: FlexbaseTableBulkAction<T>) => {
    return () => action.onClick?.(getFilteredSelectedState(action));
  };

  return (
    <Transition
      transition={'slide-up'}
      mounted={selectedState.selectedCount > 0}
      duration={150}
      timingFunction="ease"
    >
      {(styles) => (
        <Box
          className={classes.bulkActionsBar}
          style={{
            ...styles,
          }}
        >
          <Box className={classes.columnLeft}>
            <Box className={classes.columnLeftDescription}>{description}</Box>

            <Box className={classes.columnLeftActions} ta="center">
              {visibleActions.map((action) => {
                return (
                  <Button
                    key={action.key}
                    leftSection={action.icon}
                    onClick={handleActionClick(action)}
                    className={classes.action}
                    loading={action.loading}
                    disabled={getActionDisabled(action)}
                  >
                    {getActionLabel(action)}
                  </Button>
                );
              })}
            </Box>
          </Box>

          <Box className={classes.columnRight} ta="center">
            <Flex
              w={32}
              h={32}
              align="center"
              justify="center"
              onClick={onClose}
              className={classes.closeButton}
            >
              <PiX size={'1.25rem'} strokeWidth={1.5} />
            </Flex>
          </Box>
        </Box>
      )}
    </Transition>
  );
};

const useStyles = createStyles((theme) => ({
  bulkActionsBar: {
    alignItems: 'center',
    backgroundColor: alpha(theme.colors.primarySecondarySuccess[8], 0.85),
    backdropFilter: 'blur(4px)',
    borderRadius: 12,
    color: theme.colors.neutral[0],
    display: 'flex',
    flexWrap: 'nowrap',
    gap: theme.spacing.sm,
    boxShadow: theme.shadows.xl,
    position: 'fixed',
    width: 'calc(66%)',
    bottom: rem(24),
    left: 0,
    right: 0,
    margin: 'auto',
    minHeight: 56,
    padding: `${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.xl}`,
  },
  columnLeft: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
    rowGap: theme.spacing.md,
  },
  columnLeftDescription: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'nowrap',
    lineHeight: rem(24),
  },
  columnLeftActions: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing.sm,
  },
  columnRight: {
    alignItems: 'flex-end',
    display: 'flex',
    flexShrink: 0,
    flexGrow: 0,
    justifyContent: 'flex-end',
    gap: theme.spacing.sm,
  },
  action: {
    backgroundColor: theme.colors.primarySecondarySuccess[2],
    borderRadius: theme.defaultRadius,
    color: theme.colors.neutral[0],
    fontWeight: 400,
    lineHeight: rem(24),
  },
  closeButton: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
    transition: 'opacity 0.2s ease',
  },
}));
