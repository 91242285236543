import { Box, Text, Group, ActionIcon, useMantineTheme } from '@mantine/core';
import GetPaddedAccountMask from '@utilities/formatters/get-padded-account-mask';
import { forwardRef, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { ParsedAccount } from 'types/parsed-account';
import { ChevronDownIcon } from 'assets/svg';
import FlexbaseSelect from '@common/composites/flexbase-select';

type ClearButtonProps = {
  color: string;
  onClick: () => void;
};

// Mantine's clear button isn't working for some reason, so we're rolling our own
const ClearButton = ({ onClick, color }: ClearButtonProps) => (
  <ActionIcon
    onClick={onClick}
    variant="transparent"
    sx={() => ({
      '&:hover': {
        backgroundColor: 'transparent',
      },
    })}
  >
    <IoClose color={color} />
  </ActionIcon>
);

type ItemProps = {
  label: string;
  description?: string;
} & React.ComponentPropsWithoutRef<'div'>;

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, description, ...others }: ItemProps, ref) => (
    <Box ref={ref} {...others}>
      <Group wrap="nowrap">
        <Box>
          <Text size="sm">{label}</Text>
          <Text size="xs" opacity={0.65}>
            {description}
          </Text>
        </Box>
      </Group>
    </Box>
  ),
);
SelectItem.displayName = 'SelectItem';

const getDropdownOptions = (accounts: ParsedAccount[]) => {
  const allOptions = [
    {
      label: 'All accounts',
      value: 'all',
    },
  ];

  return allOptions.concat(
    accounts.map(({ id, nickName, accountNumber }) => ({
      label:
        nickName ??
        `No nickname - ${GetPaddedAccountMask(accountNumber ?? '', 2)}`,
      value: id,
    })),
  );
};

type Props = {
  textColor?: string;
  defaultValue?: string;
  accounts: ParsedAccount[];
  onChange: (value: string) => void;
};

const AccountsDropdown = ({
  accounts,
  textColor,
  defaultValue = 'all',
  onChange,
}: Props) => {
  const theme = useMantineTheme();
  const [dropdownValue, setDropdownValue] = useState(defaultValue);
  const data = getDropdownOptions(accounts);

  const handleChange = (val: string | null) => {
    if (!val) {
      return;
    } else {
      setDropdownValue(val);
      onChange(val);
    }
  };

  return (
    <FlexbaseSelect
      itemComponent={SelectItem}
      data={data}
      maxDropdownHeight={400}
      clearable
      value={dropdownValue}
      onChange={handleChange}
      inputProps={{
        rightSection:
          dropdownValue !== 'all' ? (
            <ClearButton
              color={theme.colors.neutral[4]}
              onClick={() => handleChange('all')}
            />
          ) : (
            <ChevronDownIcon color={theme.colors.neutral[4]} />
          ),
        styles: {
          input: {
            background: 'unset',
            color: textColor ?? 'initial',
          },
        },
        rightSectionProps: { style: { pointerEvents: 'all' } },
      }}
    />
  );
};

export default AccountsDropdown;
