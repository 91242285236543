import WizardErrorBanner from 'areas/billpay/wizard-error-banner';
import { Box, Group, Paper, rem, Stack, Text } from '@mantine/core';
import { AccountProps } from 'types/move-funds.model';
import AccountSelection from '@common/account-selection';
import { formatCents, formatCurrency } from '@utilities/formatters';
import ErrorPill from '@common/composites/error-pill';
import { Line } from '@services/flexbase/credit.model';

export const LineOfCreditSource = ({
  invoiceTotal,
  sendFrom,
  isActionDisabled,
  paymentAccounts,
  onAccountChange,
  notEnoughFundsError,
  creditPrograms,
  creditProgramId,
  // onCreditProgramChange,
  notEnoughCreditError,
}: {
  invoiceTotal: number;
  sendFrom?: AccountProps;
  isActionDisabled: boolean;
  paymentAccounts: AccountProps[];
  onAccountChange: (accountId: string) => void;
  notEnoughFundsError?: string;
  creditPrograms: Line[];
  creditProgramId?: string;
  onCreditProgramChange: (creditProgramId: string) => void;
  notEnoughCreditError?: string;
}) => {
  const creditProgram = creditPrograms.find((cp) => cp.id === creditProgramId);

  const fee = creditProgram?.fee
    ? Math.round(creditProgram.fee * invoiceTotal)
    : 0;
  const feeLabel = formatCurrency(formatCents(fee));
  const discount = creditProgram?.discount
    ? Math.round(creditProgram.discount * invoiceTotal)
    : 0;
  const discountLabel = formatCurrency(formatCents(discount));
  const totalFee = fee - discount;
  const totalFeeLabel = formatCurrency(formatCents(totalFee));

  return (
    <Paper px="xl" py="md" withBorder>
      <Group justify="space-between">
        <Stack gap="none">
          <Text size="sm" color="neutral.7">
            Available credit
          </Text>
          <Text
            size="xl"
            color={notEnoughCreditError ? 'critical.3' : 'neutral.9'}
          >
            {formatCurrency(formatCents(creditProgram?.available || 0))}
          </Text>
        </Stack>
        {notEnoughCreditError && <ErrorPill message={notEnoughCreditError} />}
      </Group>
      <Stack gap="none" mt="md">
        <Text size="sm" color="neutral.9">
          Fees
        </Text>
        <Group gap={rem(64)}>
          <Stack gap="xxs">
            {creditProgram?.fee && (
              <Text size="sm" color="neutral.7" lh={1}>
                {creditProgram.fee * 100}% fee
              </Text>
            )}
            {creditProgram?.discount && (
              <Text size="sm" color="neutral.7" lh={1}>
                {creditProgram.discount * 100}% discount
              </Text>
            )}
            <Text size="sm" color="neutral.7" lh={1}>
              Total fees
            </Text>
          </Stack>
          <Stack gap="xxs">
            {creditProgram?.fee && (
              <Text size="sm" color="neutral.7" lh={1}>
                {feeLabel}
              </Text>
            )}
            {creditProgram?.discount && (
              <Text size="sm" color="neutral.7" lh={1}>
                {discountLabel}
              </Text>
            )}
            <Text size="sm" color="neutral.7" lh={1}>
              {totalFeeLabel}
            </Text>
          </Stack>
        </Group>
      </Stack>
      <Box mt="md">
        <Text size="sm" color="neutral.7">
          Pay fee from
        </Text>
        <Box mt="xxs">
          {sendFrom && (
            <AccountSelection
              disabled={isActionDisabled}
              currentAccount={sendFrom}
              accounts={paymentAccounts}
              onAccountChange={(account) => onAccountChange(account.id)}
            />
          )}
          {notEnoughFundsError && (
            <WizardErrorBanner message={notEnoughFundsError} />
          )}
        </Box>
        <Text size="sm" color="neutral.7" mt="xxs">
          The credit fee will be deducted from this bank account.
        </Text>
      </Box>
    </Paper>
  );
};
