import {
  ActiveFiltersReturnType,
  createUseFiltersHook,
} from '@common/utilities/filter/filters';
import { TablePayment } from '@utilities/payments-rows';
import {
  PaymentFilterModalState,
  PaymentFilterState,
} from 'recoil-state/filters/payments-filters';

export function usePaymentFilters(): ActiveFiltersReturnType<TablePayment> {
  return createUseFiltersHook<TablePayment>(PaymentFilterState);
}

export function usePaymentModalFilters(): ActiveFiltersReturnType<TablePayment> {
  return createUseFiltersHook<TablePayment>(PaymentFilterModalState);
}
