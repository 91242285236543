import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  card: {
    padding: theme.spacing.xl,
    color: theme.colors.neutral[1],
    borderRadius: theme.defaultRadius,
    position: 'relative',
    backgroundColor: theme.colors.neutral[0],
    border: `1px solid ${theme.colors.neutral[1]}`,
    marginTop: theme.spacing.lg,
  },
}));
