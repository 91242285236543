import { createStyles } from '@mantine/emotion';
import { rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  spentWeekContainer: {
    position: 'relative',
    borderRadius: rem(8),
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    height: rem(200),
    width: rem(420),
    '@media(max-width: 767px)': {
      width: '100%',
      minWidth: 'unset',
    },
    border: `1px solid ${theme.colors.neutral[0]}`,
    '&:hover': {
      border: `1px solid ${theme.primaryColor}`,
    },
    cursor: 'pointer',
  },
  spentWeekLabel: {
    fontSize: rem(16),
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: rem(19),
    color: theme.colors.blackish[0],
  },
  totalSpentWeek: {
    marginTop: rem(8),
    fontSize: rem(26),
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: rem(31),
    color: 'black',
    fontFamily: 'PP Neue Montreal',
  },
  totalSpentWeekContainer: {
    marginTop: rem(80),
    borderColor: '#c8d6e5',
    width: rem(420),
    '@media(max-width: 767px)': {
      width: '100%',
      minWidth: 'unset',
    },
    margin: rem(24),
  },
  purchasesText: {
    fontSize: rem(16),
    lineHeight: rem(19),
    color: '#A2A2A2',
    fontWeight: 500,
  },
}));
