import { Box, Flex, Text, useMantineTheme } from '@mantine/core';
import { AssignedIcon, RemainingIcon, SpentIcon } from 'assets/svg';
import TileIndicators from './tile-indicators';
import { formatCurrency } from '@utilities/formatters';
import { capitalizeOnlyFirstLetter } from '@utilities/formatters/format-strings';
import SpendProgressBar from './progress-bar';
import {
  SpendPlanAdminView,
  SpendPlanView,
} from '@flexbase-eng/types/dist/accounting';
import { useMemo } from 'react';
import { isSpendPlanAdminView } from '@utilities/is-spend-plan-admin-view';

type Props = {
  spendPlanId?: string;
  plan: SpendPlanAdminView | SpendPlanView;
  frequency?: string;
  isManaged?: boolean;
};

const LimitTile = ({ spendPlanId, plan, frequency, isManaged }: Props) => {
  const themeColors = useMantineTheme();

  let color = '';
  let letterColor = '';

  switch (plan?.usage) {
    case 'near limit':
      color = themeColors.colors.tertiary[2];
      letterColor = themeColors.colors.tertiary[6];
      break;
    case 'overspent':
    case 'no funds remaining':
      color = themeColors.colors.critical[2];
      letterColor = themeColors.colors.critical[6];
      break;
    default:
      color = themeColors.colors.primarySecondarySuccess[2];
      break;
  }

  const memberCount = useMemo(() => {
    if (isManaged) {
      let sum = 0;

      if (isSpendPlanAdminView(plan)) {
        sum = plan.managers.length + plan.members.length;
      }

      return sum;
    } else {
      return 1;
    }
  }, [plan, isManaged]);

  const indicators = [
    {
      title: 'Spent',
      amount: plan.spent || 0,
      icon: <SpentIcon fill={color} />,
      show: true,
    },
    {
      title: 'Assigned',
      amount: plan.assigned || 0,
      icon: <AssignedIcon stroke={color} />,
      show: isManaged ? true : false,
    },
    {
      title: 'Remaining',
      amount: plan.remaining || 0,
      icon: <RemainingIcon stroke={color} />,
      show: true,
    },
  ];

  return (
    <Flex
      bg="neutral.0"
      p="20px"
      h="100%"
      direction="column"
      justify="space-between"
      sx={(theme) => ({
        border: `1px solid ${theme.colors.neutral[3]}`,
        borderRadius: theme.defaultRadius,
      })}
    >
      <Flex direction="row" align="center" justify="space-between">
        {spendPlanId ? (
          <Text fz={'2rem'} fw={400} lh={1.5}>
            {formatCurrency(plan?.limit || 0)}
          </Text>
        ) : (
          <Text fz={'1.5rem'} fw={500} lh={1.5} lts={'-0.456px'}>
            {plan?.name}
          </Text>
        )}

        {plan?.usage && plan.usage !== 'normal' ? (
          <Box
            p="6px"
            bg={plan.usage === 'near limit' ? 'tertiary.0' : 'critical.0'}
            sx={{ borderRadius: '4px' }}
          >
            <Text fz="12px" color={letterColor}>
              {capitalizeOnlyFirstLetter(plan.usage)}
            </Text>
          </Box>
        ) : (
          spendPlanId && (
            <Text fz={'0.9 rem'} fw={400} lh={'19px'} c="neutral.7">
              {frequency}
            </Text>
          )
        )}
      </Flex>
      {spendPlanId ? (
        <Text fz={'0.9 rem'} fw={400} lh={'19px'} c="neutral.7">
          Left in plan
        </Text>
      ) : (
        <Flex
          direction="row"
          align="center"
          justify="space-between"
          mt="1.5rem"
        >
          <Text fz={'16px'} lh={'1.5rem'} c="neutral.7">
            {capitalizeOnlyFirstLetter(plan?.frequency || '')} |{' '}
            {formatCurrency(plan?.limit || 0)}
          </Text>
          {plan && 'assigned' in plan ? (
            <Text fz={'0.9 rem'} lh={'19px'} c="neutral.7">
              {memberCount} team {memberCount === 1 ? 'member' : 'members'}
            </Text>
          ) : (
            false
          )}
        </Flex>
      )}
      <div>
        <SpendProgressBar
          spentAmount={plan?.spent || 0}
          assignedAmount={
            plan && 'assigned' in plan ? (plan?.assigned ?? 0) : 0
          }
          color={color}
          limit={plan?.limit || 0}
          remainingAmount={plan?.remaining || 0}
        />
      </div>
      <div>
        <Flex
          direction={spendPlanId ? 'row' : 'column'}
          gap="xl"
          wrap="wrap"
          justify={'space-between'}
        >
          {indicators
            .filter((indicator) => indicator.show)
            .map((indicator) => (
              <TileIndicators
                spendPlanId={spendPlanId}
                indicator={indicator}
                key={indicator.title}
              />
            ))}
        </Flex>
      </div>
    </Flex>
  );
};

export default LimitTile;
