import { Button, CheckIcon, Grid, rem } from '@mantine/core';

import { useStyles } from '../cancel-debit-card-styles';
import { CardByUser } from 'services/flexbase/banking.model';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';

type Props = {
  card: CardByUser;
  isSelected: string;
  isChargeCard: boolean;
  onBack: () => void;
  nextStep: () => void;
  setIsSelected: (x: string) => void;
};

const ChooseReason = ({
  card,
  isSelected,
  isChargeCard,
  onBack,
  setIsSelected,
  nextStep,
}: Props) => {
  const { classes, cx, theme } = useStyles();

  const reasonType = ['Lost', 'Stolen', 'Damaged', 'Other'];
  const cardLabel = isChargeCard ? 'CHARGE CARD' : 'DEBIT CARD';

  return (
    <div>
      <p className={classes.subtitle}>
        {cardLabel} {getPaddedAccountMask(card.cardNumber, 4)}
      </p>
      <span className={classes.title}>What happened to your card?</span>
      <Grid gutter={rem(5)} mt={rem(10)}>
        {reasonType.map((reason, index) => (
          <Grid.Col
            key={index}
            className={classes.optionBox}
            style={
              isSelected === reason
                ? { backgroundColor: theme.primaryColor }
                : {}
            }
            onClick={() => setIsSelected(reason)}
          >
            {isSelected === reason ? (
              <div className={classes.circle}>
                <CheckIcon width={rem(16)} />
              </div>
            ) : (
              <div className={classes.circle} />
            )}
            <div
              className={cx(
                classes.optionText,
                isSelected === reason && classes.lightText,
              )}
            >
              {reason}
            </div>
          </Grid.Col>
        ))}
      </Grid>

      <div className={classes.buttonsContainer}>
        <Button mr={rem(12)} variant="outline" onClick={onBack}>
          Cancel
        </Button>
        {isSelected !== '' && (
          <Button variant="light" onClick={nextStep}>
            Next
          </Button>
        )}
      </div>
    </div>
  );
};

export default ChooseReason;
