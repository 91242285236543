import { useSideModalFilters } from '@common/composites/details-page/filters/use-side-modal-filters';
import { DateRangeFilter } from '@common/composites/date-range.filter';
import { RightContentModal } from '@common/composites/modal/right-content.modal';
import { useGetBankingTransactions } from '@queries/use-banking-transactions';
import { sortDate } from '@utilities/dates';
import { filterByDirection } from '@utilities/filter/filter-transactions-by-direction';
import { formatFilterDate } from '@utilities/formatters/format-datetime';
import { DateTime, Interval } from 'luxon';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import MoneyDirection from '../components/money-direction/money-direction';
import { rem } from '@mantine/core';

type MoneyInOutDetailProps = {
  accountId: string;
  accountNumber: string;
};

const MoneyInOutDetails = ({
  accountId,
  accountNumber,
}: MoneyInOutDetailProps) => {
  const transactionFilters = useSideModalFilters();
  const currentDateFilter =
    transactionFilters.getFilterByKey('date')?.filterValue;
  const toDate =
    currentDateFilter && formatFilterDate(currentDateFilter?.endDate?.c);
  const fromDate =
    currentDateFilter && formatFilterDate(currentDateFilter?.startDate?.c);
  const dateParams = toDate &&
    fromDate && {
      fromDate,
      toDate,
    };
  const { data: transactionsData } = useGetBankingTransactions({
    depositAccountId: accountId,
    ...dateParams,
  });
  const transactions =
    transactionsData?.transactions.sort((a, b) => sortDate(a, b, false)) ?? [];

  const moneyInData = filterByDirection(transactions, 'Credit');
  const moneyOutData = filterByDirection(transactions, 'Debit');

  return (
    <RightContentModal>
      <RightContentModal.Header
        titleSize={rem(40)}
        title="Money in/out"
        subtitle={`Business checking | Checking ${getPaddedAccountMask(
          accountNumber,
          2,
        )}`}
      />

      <RightContentModal.Body>
        <DateRangeFilter
          filterHook={transactionFilters}
          filterFn={(startDate, endDate, item) => {
            const dateRange = Interval.fromDateTimes(startDate, endDate);
            return dateRange.contains(DateTime.fromSQL(item.createdAt));
          }}
        />

        <MoneyDirection direction="Credit" transactions={moneyInData} />

        <MoneyDirection direction="Debit" transactions={moneyOutData} />
      </RightContentModal.Body>
    </RightContentModal>
  );
};

export default MoneyInOutDetails;
