import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { Center, Loader } from '@mantine/core';
import { formatCurrency } from '@utilities/formatters/format-currency';
import PDFPaymentReceipt from '@common/composites/pdf-payment-receipt/pdf-payment-receipt';
import { useGetCardPaymentReceiptInfo } from '@utilities/custom-hooks/use-card-payment';

const ConfirmationReceipt = () => {
  const { paymentId } = useParams();
  const {
    data: payment,
    isError,
    isLoading,
  } = useGetCardPaymentReceiptInfo(paymentId ?? '');

  const documentName = `Flex Credit Payment Receipt ${DateTime.fromSQL(
    payment?.createdAt || '',
  ).toFormat('MM-yy')}`;
  const payAmount = formatCurrency(
    payment?.amount ? Math.abs(+payment.amount) : 0,
  );

  if (isLoading) {
    return (
      <Center h="100vh">
        <Loader />
      </Center>
    );
  }

  return (
    <PDFPaymentReceipt
      isError={isError}
      payAmount={payAmount}
      documentName={documentName}
      creditText="Flex Credit Card"
      createdAt={payment?.createdAt}
      paymentAccount={payment?.account}
    />
  );
};

export default ConfirmationReceipt;
