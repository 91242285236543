import { DateTime } from 'luxon';
import { Stepper } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { CheckDeposit } from '@services/flexbase/banking.model';
import { formatCheckStatus } from '@utilities/formatters/format-checks-status';

type CheckTrackerProps = {
  check: CheckDeposit;
  checkTracker: Record<string, string>;
};

const formattedDate = (date: string) => {
  const dateObj = DateTime.fromSQL(date);
  return `${dateObj.toFormat('LLL d')}`;
};

const CheckStatusTracker = ({ check, checkTracker }: CheckTrackerProps) => {
  const { classes } = useStepperStyles();
  const checkStatus = formatCheckStatus(check.status);
  const isNegativeStatus = ['Canceled', 'Rejected', 'Returned'].includes(
    checkStatus,
  );
  const isPendingOrClearing = ['Pending', 'Clearing'].includes(check.status);
  const hasSettlementDate = !!check.settlementDate;
  const hasDepositedDate = !!checkTracker.deposited;

  const processingDescription = checkTracker.processing
    ? formattedDate(checkTracker.processing)
    : 'To be confirmed';
  let lastStepDescription;
  // Unit docs indicate check with 'Pending' or 'Clearing status' shows the date on which the check will be settled.
  // https://www.unit.co/docs/api/resources/#attributes-79
  if (isPendingOrClearing && hasSettlementDate) {
    lastStepDescription = `Could be settled on ${formattedDate(
      check.settlementDate,
    )}`;
  } else if (hasDepositedDate) {
    lastStepDescription = formattedDate(checkTracker.deposited);
  } else if (isNegativeStatus) {
    lastStepDescription = formattedDate(
      checkTracker[checkStatus.toLowerCase()],
    );
  } else {
    lastStepDescription = 'To be confirmed';
  }

  const stepsOrder: Record<string, number> = {
    'Awaiting Images': 0,
    Processing: 1,
    Deposited: 3,
    Canceled: 3,
    Rejected: 3,
    Returned: 3,
  };
  const stepperColor = isNegativeStatus
    ? 'neutral.3'
    : 'primarySecondarySuccess.2';

  return (
    <Stepper
      mt="sm"
      classNames={classes}
      color={stepperColor}
      orientation="vertical"
      active={stepsOrder[checkStatus]}
    >
      <Stepper.Step
        description={formattedDate(checkTracker.initiated)}
        label="Initiated"
      />
      <Stepper.Step description={processingDescription} label="Processing" />
      <Stepper.Step
        description={lastStepDescription}
        label={isNegativeStatus ? checkStatus : 'Deposited'}
      />
    </Stepper>
  );
};

export default CheckStatusTracker;

const useStepperStyles = createStyles((theme) => ({
  stepCompletedIcon: {
    display: 'none',
  },
  verticalSeparator: {
    top: 'unset',
    left: 'calc(1.4rem / 2)',
    borderLeftColor: theme.colors.neutral[5],
  },
  stepIcon: {
    width: 24,
    height: 24,
    minWidth: 24,
    color: theme.colors.neutral[0],
    borderColor: theme.colors.neutral[5],
    backgroundColor: theme.colors.neutral[0],
    '&[data-progress]': {
      color: theme.colors.primarySecondarySuccess[0],
      backgroundColor: theme.colors.primarySecondarySuccess[0],
    },
  },
  step: {
    margin: 0,
    // taking this calc from Mantine's style changing some values
    minHeight: 'calc(1.6rem + 1.5rem + calc(0.5rem / 2))',
  },
  stepLabel: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.colors.neutral[9],
  },
  stepDescription: {
    fontSize: 12,
    color: theme.colors.neutral[9],
  },
}));
