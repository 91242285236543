import TitleBar from '@common/composites/details-page/titlebar/title-bar';
import useModal from '@common/composites/modal/modal-hook';
import { Button, Menu, useMantineTheme } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import {
  useDeleteRecipient,
  useUpdateRecipient,
} from '@queries/use-recipients';
import { Dispatch, SetStateAction, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { ParsedAccount } from 'types/parsed-account';
import EditRecipient from './edit-recipient/edit-recipient';
import { Recipient } from 'types/recipient';
import {
  PiPencilSimpleLineBold,
  PiPlusBold,
  PiTrashBold,
} from 'react-icons/pi';

type MenuItemsProps = {
  onRemoveRecipient: () => void;
  isRemovedRecipient: boolean;
  onShowForm: () => void;
  recipientId: string;
  recipientName: string;
  setAccountsInfo?: Dispatch<SetStateAction<ParsedAccount[]>>;
};

const MenuItems = ({
  onRemoveRecipient,
  isRemovedRecipient,
  onShowForm,
  recipientId,
}: MenuItemsProps) => {
  const theme = useMantineTheme();
  const { openConfirmationModal } = useModal();
  const navigate = useNavigate();

  const handleRemoveRecipient = () => {
    openConfirmationModal({
      title: `Remove recipient`,
      confirmText: 'Remove',
      cancelText: 'Cancel',
      content: `This action cannot be undone. Payments and transactions associated with this account will remain in the payments and transactions tabs for this recipient.`,
      onConfirm: onRemoveRecipient,
      closeOnClickOutside: true,
    });
  };

  const handleAddRecipient = () => {
    navigate(`/recipients/new?recipientId=${recipientId}`);
  };

  return (
    <>
      <Menu.Item
        leftSection={<PiPencilSimpleLineBold color={theme.colors.neutral[5]} />}
        onClick={onShowForm}
      >
        Edit recipient details
      </Menu.Item>
      <Menu.Item
        leftSection={<PiPlusBold color={theme.colors.neutral[5]} />}
        onClick={handleAddRecipient}
      >
        Add recipient account
      </Menu.Item>
      <Menu.Item
        color={theme.colors.critical[6]}
        leftSection={<PiTrashBold />}
        onClick={handleRemoveRecipient}
        disabled={isRemovedRecipient}
      >
        Remove recipient
      </Menu.Item>
    </>
  );
};

const useShowForm = () => {
  const [isShowingForm, setIsShowingForm] = useState(false);

  const onShowForm = () => {
    setIsShowingForm(true);
  };

  const onHideForm = () => {
    setIsShowingForm(false);
  };

  return { isShowingForm, onShowForm, onHideForm };
};

type Props = {
  isRemovedRecipient: boolean;
  title: string;
  recipientId: string;
  setAccountsInfo?: Dispatch<SetStateAction<ParsedAccount[]>>;
  recipient: Recipient;
};

const RecipientDetailsTitleBar = ({
  isRemovedRecipient,
  title,
  recipientId,
  setAccountsInfo,
  recipient,
}: Props) => {
  const navigate = useNavigate();
  const { isShowingForm, onHideForm } = useShowForm();
  const { mutate: updateRecipient, isPending: isPendingUpdate } =
    useUpdateRecipient();
  const { mutate: deleteRecipient } = useDeleteRecipient();
  const { openRightModal, closeAllModals } = useModal();

  const handlePrimaryButtonClick = () => {
    navigate({
      pathname: '/payments/outgoing/recipient',
      search: createSearchParams({
        recipient: title,
        recipientId,
      }).toString(),
    });
  };

  const onRemoveRecipient = () => {
    deleteRecipient(recipientId, {
      onSuccess: () => {
        showNotification({
          color: 'flexbase-teal',
          message: 'Recipient removed',
        });
        navigate('/payments/recipients');
      },
      onError: () => {
        showNotification({
          color: 'red',
          message: 'Unable to remove recipient',
        });
      },
    });
  };

  const openEditRecipientModal = () => {
    openRightModal(
      <EditRecipient closeModal={closeAllModals} recipient={recipient} />,
    );
  };

  const onSubmit = (name: string) => {
    updateRecipient(
      { id: recipientId, params: { name } },
      {
        onSuccess: () => {
          onHideForm();
          showNotification({
            color: 'flexbase-teal',
            message: 'Successfully updated recipient',
          });
        },
        onError: () => {
          showNotification({
            color: 'red',
            message: 'Unable to update recipient',
          });
        },
      },
    );
  };

  return (
    <TitleBar
      currentTitle={title}
      primaryButton={
        <Button onClick={handlePrimaryButtonClick} leftSection="+">
          Send payment
        </Button>
      }
      menuItems={
        <MenuItems
          onRemoveRecipient={onRemoveRecipient}
          isRemovedRecipient={isRemovedRecipient}
          onShowForm={openEditRecipientModal}
          recipientId={recipientId}
          recipientName={title}
          setAccountsInfo={setAccountsInfo}
        />
      }
      isShowingForm={isShowingForm}
      onHideForm={onHideForm}
      onSubmit={onSubmit}
      isSubmitting={isPendingUpdate}
    />
  );
};

export default RecipientDetailsTitleBar;
