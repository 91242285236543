import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme, _, helpers) => ({
  container: {
    padding: '0.75rem',
    backgroundColor: theme.colors.neutral[0],
    borderRadius: theme.defaultRadius,
    maxWidth: '85.3rem',
    margin: 'auto',
  },
  header: {
    paddingBottom: '1rem',
    paddingTop: '0.75rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media(max-width: 767px)': {
      gap: 15,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  filterChipBox: {
    height: 30,
    borderRadius: theme.radius.xs,
    border: `1px solid ${theme.colors.neutral[3]}`,
    backgroundColor: theme.colors.neutral[2],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: `0px ${theme.spacing.md}`,
    gap: 4,
    [helpers.smallerThan('md')]: {
      height: 'auto',
      padding: `0px ${theme.spacing.xl}`,
    },
  },
}));
