import { Box, Grid, LoadingOverlay } from '@mantine/core';
import BillpayCreditAutoPay from './billpay-credit-auto-pay';
import BillpayCreditPaymentsTable from './payments-table/bill-pay-credit-payments-table';
import ChargePaymentCard from '@common/charge-payment';
import { useGetChargeCardAccounts } from '@queries/use-charge-card-accounts';

const BillpayCreditPayments = () => {
  const {
    data: chargeAccountsData,
    isError,
    isLoading,
    refetch: refetchChargeAccounts,
  } = useGetChargeCardAccounts();
  const activeChargeAccounts =
    chargeAccountsData?.accounts.filter((acc) => acc.status !== 'Closed') ?? [];

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  return (
    <>
      <Grid mb="xl">
        <Grid.Col span={{ md: 6 }}>
          <BillpayCreditAutoPay />
        </Grid.Col>
        <Grid.Col span={{ md: 6 }}>
          <ChargePaymentCard
            isError={isError}
            chargeBalance={activeChargeAccounts[0]}
            refetchChargeAccounts={refetchChargeAccounts}
            isBillPayCredit={true}
          />
        </Grid.Col>
      </Grid>

      <Box mt="lg">
        <BillpayCreditPaymentsTable />
      </Box>
    </>
  );
};

export default BillpayCreditPayments;
