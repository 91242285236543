import { createStyles } from '@mantine/emotion';
import { Center, Flex, Grid, Loader, Text, rem } from '@mantine/core';
import TableSummaryCard from './table-summary-card';
import { ReactNode } from 'react';
import { PiWarningCircle } from 'react-icons/pi';

type SummaryCard = {
  title: string;
  balance: number;
  footer?: string | ReactNode;
};

type Props = {
  summaryData: SummaryCard[];
  isLoading: boolean;
  isError: boolean;
};

const TableSummary = ({ summaryData, isLoading, isError }: Props) => {
  const { classes, theme } = useStyles();

  if (isLoading) {
    return (
      <Center mb={rem(16)}>
        <Loader />
      </Center>
    );
  }

  return (
    <>
      <Grid
        grow
        gutter={0}
        mb={isError ? 0 : rem(16)}
        className={isError ? classes.gridError : classes.grid}
      >
        {summaryData.map((item, idx) => (
          <Grid.Col span={4} key={item.title}>
            <TableSummaryCard
              {...item}
              error={isError}
              isLast={idx === summaryData.length - 1}
            />
          </Grid.Col>
        ))}
      </Grid>
      {isError && (
        <Flex px={rem(16)} mb={rem(16)} className={classes.containerError}>
          <Flex align="center">
            <PiWarningCircle color={theme.colors.red[6]} size={'1.5rem'} />
            <Text mt={rem(1.6)} ml={rem(3.2)} fw={500}>
              {' '}
              Error loading data
            </Text>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default TableSummary;

const useStyles = createStyles((theme) => ({
  containerError: {
    backgroundColor: theme.colors.red[0],
    border: `1px solid ${theme.colors.red[5]}`,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    height: '2rem',
  },
  grid: {
    borderRadius: 8,
    overflow: 'hidden',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    backgroundColor: theme.colors.beige[0],
  },
  gridError: {
    borderRadius: 8,
    overflow: 'hidden',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: theme.colors.beige[0],
  },
}));
