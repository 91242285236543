import { PiCaretRightLight, PiCreditCard } from 'react-icons/pi';
import { useStyles } from '@common/charge-and-credit-cards/credit-transactions.styles';
import { rem } from '@mantine/core';

type TransactionYearRowProps = {
  year: number;
  totalTransactions: number;
  onClick: (year: number) => void;
};

const TransactionYearRow = ({
  year,
  totalTransactions,
  onClick,
}: TransactionYearRowProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.transactionYearRow} onClick={() => onClick(year)}>
      <PiCreditCard className={classes.storeIcon} />
      <div>
        <div className={classes.text}>Transactions in {year}</div>
        <div
          className={classes.text}
          style={{ color: '#808080', fontSize: rem(12) }}
        >{`${totalTransactions} total transactions`}</div>
      </div>
      <div
        className={classes.text}
        style={{ fontSize: rem(16), marginLeft: 'auto' }}
      >
        <PiCaretRightLight />
      </div>
    </div>
  );
};

export default TransactionYearRow;
