import { DateRangeFilter } from '@common/composites/date-range.filter';
import { useBillpayPaymentsModalFilters } from 'areas/billpay/payments/use-billpay-payments-filters';

import { DateTime, Interval } from 'luxon';

export const PaymentDateRangeFilter = () => {
  const paymentDateFilters = useBillpayPaymentsModalFilters();
  return (
    <DateRangeFilter
      label="Payment date"
      customKey="paymentDate"
      filterHook={paymentDateFilters}
      filterFn={(startDate, endDate, item) => {
        const dateRange = Interval.fromDateTimes(startDate, endDate);
        return dateRange.contains(DateTime.fromSQL(item.asOf));
      }}
    />
  );
};
