import OnboardingErrorBoundary from '../components/onboarding-error-boundary.component';
import { LoadingOverlay } from '@mantine/core';
import React from 'react';
import { Outlet } from 'react-router-dom';

const ProductApplicationErrorBoundary = () => {
  return (
    <OnboardingErrorBoundary>
      <React.Suspense fallback={<LoadingOverlay visible={true} />}>
        <Outlet />
      </React.Suspense>
    </OnboardingErrorBoundary>
  );
};

export default ProductApplicationErrorBoundary;
