import { Anchor, Loader, rem, TextInput, TextInputProps } from '@mantine/core';
import { PatternFormat } from 'react-number-format';
import { ReactNode, useState } from 'react';

type Props = {
  onResendCodeClick?: () => Promise<void>;
  onCodeSubmit: (code: string) => void;
  codeLength?: number;
  onCodeChange?: (code: string) => void;
  showSpinner?: boolean;
} & Omit<TextInputProps, 'type' | 'value' | 'defaultValue' | 'rightSection'>;

export const VerificationCodeInput = ({
  onResendCodeClick,
  onCodeSubmit,
  codeLength = 6,
  onCodeChange,
  showSpinner,
  ...textInputProps
}: Props) => {
  const [generatingCode, setGeneratingCode] = useState(false);

  const handleCodeChange = async (code: string) => {
    if (onCodeChange) {
      onCodeChange(code);
    }

    if (code.length === codeLength) {
      onCodeSubmit(code);
    }
  };

  const handleResendCodeClick = async () => {
    if (onResendCodeClick) {
      setGeneratingCode(true);
      try {
        await onResendCodeClick();
      } finally {
        setGeneratingCode(false);
      }
    }
  };

  let rightSection: ReactNode;

  if (onResendCodeClick || showSpinner) {
    rightSection =
      generatingCode || showSpinner ? (
        <Loader size="sm" sx={(theme) => ({ color: theme.primaryColor })} />
      ) : (
        <Anchor
          sx={(theme) => ({
            color: theme.colors.primarySecondarySuccess[2],
            marginRight: theme.spacing.sm,
            fontSize: rem(14),
          })}
          onClick={handleResendCodeClick}
        >
          Resend code
        </Anchor>
      );
  } else {
    rightSection = false;
  }

  return (
    <PatternFormat
      {...textInputProps}
      format={''.padEnd(codeLength, '#')}
      inputMode="numeric"
      customInput={TextInput}
      placeholder={`Enter ${codeLength} digit code`}
      data-testid="input-verify-code"
      label="Code"
      onValueChange={(v) => handleCodeChange(v.value)}
      rightSection={rightSection}
      rightSectionWidth={'auto'}
    />
  );
};
