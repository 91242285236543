import { filterDirectionToLabel } from '@common/utilities/filter/amount-filter';
import { formatCurrency } from '@utilities/formatters';
import { useSpendTransactionModalFilters } from '../use-spend-transactions-filter';
import { QuantityFilter } from '@common/utilities/filter/quantity-filter';

export const SpendAmountFilter = () => {
  const { addFilter, getFilterByKey } = useSpendTransactionModalFilters();
  return (
    <QuantityFilter
      value={
        getFilterByKey('amount')?.filterValue || {
          amount: '',
          direction: 'equal',
        }
      }
      onChange={(val) => {
        if (!val.amount) return;
        addFilter('amount', {
          label: `Amount - ${filterDirectionToLabel(
            val.direction,
          )} ${formatCurrency(val.amount)}`,
          key: 'amount',
          fn: (row) => {
            const amountNum = Math.abs(+row.amount);
            const filterNum = +val.amount;
            switch (val.direction) {
              case 'greaterThan':
                return amountNum > filterNum;
              case 'lessThan':
                return amountNum < filterNum;
              case 'equal':
              default:
                return amountNum === filterNum;
            }
          },
          filterValue: val,
          showChip: true,
        });
      }}
    />
  );
};
