import getPaddedAccountMask from '@utilities/formatters/get-padded-account-mask';
import { MultiSelect } from '@mantine/core';
import { useCreditCards } from '@queries/use-credit-cards';
import { ActiveFiltersReturnType } from '@common/utilities/filter/filters';
import { CreditTransactionsTableRow } from '../../credit-transactions-table-helpers';
import { useCreditTransactionModalFilters } from '../use-credit-transaction-filters';
import { useChargeCardTransactionModalFilters } from 'recoil-state/filters/use-charge-cards-filters';

type CreditTransactionsCardsFilterProps = {
  filterHook: ActiveFiltersReturnType<CreditTransactionsTableRow>;
};

const CreditCardsFilter = ({
  filterHook,
}: CreditTransactionsCardsFilterProps) => {
  const { addFilter, getFilterByKey, removeFilter } = filterHook;

  const { data } = useCreditCards();

  const cards =
    data
      ?.filter((c) => !!c.cardNumber)
      .map((c) => {
        const last4 = c.cardNumber.slice(-4);
        return {
          label: `${c.cardName} - ${getPaddedAccountMask(last4, 2)}`,
          value: last4,
        };
      }) || [];

  return (
    <MultiSelect
      data={cards}
      placeholder="Cards"
      searchable
      value={getFilterByKey('cards')?.filterValue || []}
      onChange={(val) => {
        if (val.length > 0) {
          addFilter('cards', {
            key: 'cards',
            filterValue: val,
            fn: (row) => val.includes(row.sourceLast4),
            label: `Cards: ${val
              .map((last4) => getPaddedAccountMask(last4, 2))
              .join(', ')}`,
            showChip: true,
          });
        } else {
          removeFilter('cards');
        }
      }}
    />
  );
};

export const CreditTransactionsCardsFilter = () => {
  const filterHook = useCreditTransactionModalFilters();

  return <CreditCardsFilter filterHook={filterHook} />;
};

export const ChargeCardTransactionsCardsFilter = () => {
  const filterHook = useChargeCardTransactionModalFilters();

  return <CreditCardsFilter filterHook={filterHook} />;
};
