import { Box, Button, rem, TextInput, useMantineTheme } from '@mantine/core';
import {
  FilterConfig,
  useFilterModal,
} from '@common/utilities/filter/filter-modal';
import { isTruthyString } from '@utilities/validators/validate-string';
import {
  ActiveFiltersReturnType,
  FilterData,
} from '@common/utilities/filter/filters';
import { FilterChips } from '@common/composites/filter-chips';
import { CardTableRow } from '@common/utilities/filter/cards-filter/card-table-row.model';
import { PiFunnel, PiMagnifyingGlass, PiPlusBold } from 'react-icons/pi';

type Props<T> = {
  issueCardLabel: string;
  filters: FilterConfig[];
  onIssueCard: () => void;
  userCanIssueCards: boolean;
  filterHook: ActiveFiltersReturnType<T>;
  modalFilterHook: ActiveFiltersReturnType<T>;
};

export const CardsTableHeader = <T extends CardTableRow>({
  filters,
  filterHook,
  onIssueCard,
  issueCardLabel,
  modalFilterHook,
  userCanIssueCards,
}: Props<T>) => {
  const {
    activeFiltersArray,
    removeAllFilters,
    getFilterByKey,
    addFilter,
    removeFilter,
    openResponsiveFilterModal,
  } = useFilterModal({ filterHook, modalFilterHook });

  const searchValue = getFilterByKey('search')?.filterValue || '';

  const handleSearchChange = (value: string) => {
    if (!value) {
      removeFilter('search');
      return;
    }

    addFilter('search', {
      key: 'search',
      filterValue: value,
      label: `Includes ${value}`,
      showChip: false,
      fn: (transaction) => {
        const normalizedFilterText = value.toLowerCase().trim();
        return Object.values(transaction)
          .filter(isTruthyString)
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  const handleOpenFilters = () => {
    openResponsiveFilterModal(filters);
  };

  const handleRemoveFilter = (filterKey: string) => {
    removeFilter(filterKey);
  };

  const handleClearFilters = () => {
    removeAllFilters();
  };

  return (
    <CardsTableHeaderComp
      issueCardLabel={issueCardLabel}
      filterArray={activeFiltersArray}
      searchValue={searchValue}
      onSearchChange={handleSearchChange}
      onOpenFilters={handleOpenFilters}
      onRemoveFilter={handleRemoveFilter}
      onClearFilters={handleClearFilters}
      onIssueCard={onIssueCard}
      userCanIssueCards={userCanIssueCards}
    />
  );
};

type CardsTableHeaderCompProps<T> = {
  filterArray: FilterData<T>[];
  searchValue: string;
  issueCardLabel: string;
  userCanIssueCards: boolean;
  onIssueCard: () => void;
  onSearchChange: (value: string) => void;
  onOpenFilters: () => void;
  onRemoveFilter: (filterKey: string) => void;
  onClearFilters: () => void;
};

export const CardsTableHeaderComp = <T extends CardTableRow>({
  filterArray,
  searchValue,
  issueCardLabel,
  userCanIssueCards,
  onIssueCard,
  onOpenFilters,
  onRemoveFilter,
  onClearFilters,
  onSearchChange,
}: CardsTableHeaderCompProps<T>) => {
  const theme = useMantineTheme();
  const handleSearchChange = (value: string) => {
    onSearchChange(value);
  };

  const handleIssueCard = () => {
    onIssueCard();
  };

  const handleOpenFilters = () => {
    onOpenFilters();
  };

  const handleRemoveFilter = (filterKey: string) => {
    onRemoveFilter(filterKey);
  };

  const handleClearFilters = () => {
    onClearFilters();
  };

  return (
    <>
      <Box
        sx={() => ({
          display: 'flex',
          gap: theme.spacing.md,
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        })}
        mb="md"
      >
        <Box
          sx={(_, helpers) => ({
            display: 'flex',
            gap: theme.spacing.md,
            [helpers.smallerThan('sm')]: {
              flexDirection: 'column',
              width: '100%',
            },
          })}
        >
          <TextInput
            width={rem(368)}
            placeholder="Search cards"
            leftSection={
              <PiMagnifyingGlass
                size={'1.25rem'}
                color={theme.colors.blackish[0]}
              />
            }
            value={searchValue}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
          <Button
            variant="outline"
            rightSection={<PiFunnel />}
            onClick={handleOpenFilters}
          >
            Filters
          </Button>
          {userCanIssueCards && (
            <Button
              variant="outline"
              data-testid="add-new-card"
              leftSection={<PiPlusBold />}
              onClick={handleIssueCard}
            >
              {issueCardLabel || 'New card'}
            </Button>
          )}
        </Box>
      </Box>

      <FilterChips
        filterArray={filterArray}
        onRemoveFilter={handleRemoveFilter}
        onClearFilters={handleClearFilters}
      />
    </>
  );
};
