import { atom } from 'recoil';
import {
  ActiveFiltersReturnType,
  createUseFiltersHook,
  FilterFnMap,
} from '@common/utilities/filter/filters';
import { Recipient } from 'types/recipient';

const BillpayVendorsFilterState = atom<FilterFnMap<Recipient>>({
  key: 'billpay_vendors_filter_state',
  default: {},
});

export function useBillpayVendorsFilters(): ActiveFiltersReturnType<Recipient> {
  return createUseFiltersHook<Recipient>(BillpayVendorsFilterState);
}
