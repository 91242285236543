import { useSpendCardModalFilters } from '../use-spend-cards-filter';
import { SimpleSegmentSelectedFilter } from '@common/utilities/filter/simple-segmented.filter';

const REPEAT_FUNDS_OPTIONS = [
  { label: 'Has repeat funds', filterCriteria: 'true', key: 'repeatFunds' },
  { label: 'No repeat funds', filterCriteria: 'false', key: 'noRepeatFunds' },
];

export const RepeatFundsFilter = () => {
  const spendCardsFilters = useSpendCardModalFilters();

  return (
    <SimpleSegmentSelectedFilter
      filterHook={spendCardsFilters}
      filterKey="repeatFunds"
      options={REPEAT_FUNDS_OPTIONS}
      label="Repeat Funds"
      includesValue={(row) => {
        const filterValueBoolean = row.repeatFundsAccess ? 'true' : 'false';
        return filterValueBoolean;
      }}
    />
  );
};
