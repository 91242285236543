import ErrorIcon from '@common/composites/error-icon';
import WizardErrorCard from '@common/composites/wizard-error-card';
import { Button } from '@mantine/core';
import {
  AddRecipientWizard,
  useAddRecipientWizard,
} from '../../add-recipient-wizard';
import { SubmissionError } from '../../use-add-recipient-submit';

type PaymentDetailsStepErrorProps = {
  submissionError: SubmissionError;
  onClear: () => void;
};

const PaymentDetailsStepError = ({
  submissionError,
  onClear,
}: PaymentDetailsStepErrorProps) => {
  const { errorType } = submissionError;
  const { goToStep } = useAddRecipientWizard();

  const handleRetry = () => {
    if (
      errorType === 'createCounterpartyOrBeneficiary' ||
      errorType === 'updateRecipient'
    ) {
      onClear();
    } else if (errorType === 'createRecipient') {
      goToStep('recipient-details-step');
    }
  };

  return (
    <AddRecipientWizard.Step hideNext={true} hideBack={true}>
      <WizardErrorCard
        icon={<ErrorIcon />}
        title="Sorry about that..."
        subtitle={submissionError.message}
      >
        <Button size="sm" onClick={handleRetry}>
          Try again
        </Button>
      </WizardErrorCard>
    </AddRecipientWizard.Step>
  );
};

export default PaymentDetailsStepError;
