import { Box, TextInput, useMantineTheme } from '@mantine/core';
import { isTruthyString } from 'utilities/validators/validate-string';
import { useBillpayPaymentsFilters } from './use-billpay-payments-filters';
import { PiMagnifyingGlass } from 'react-icons/pi';

const BillpayPaymentsTableHeader = () => {
  const theme = useMantineTheme();
  const { getFilterByKey, removeFilter, addFilter } =
    useBillpayPaymentsFilters();

  const onSearchChange = (value: string) => {
    if (!value) {
      removeFilter('search');
      return;
    }

    addFilter('search', {
      key: 'search',
      filterValue: value,
      label: `Includes ${value}`,
      showChip: false,
      fn: (transaction) => {
        const normalizedFilterText = value.toLowerCase().trim();
        return Object.values(transaction)
          .filter(isTruthyString)
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  return (
    <Box
      sx={() => ({
        display: 'flex',
        gap: theme.spacing.md,
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      })}
      mb="md"
    >
      <Box
        sx={(_, helpers) => ({
          display: 'flex',
          gap: theme.spacing.md,
          [helpers.smallerThan('sm')]: {
            flexDirection: 'column',
            width: '100%',
          },
        })}
      >
        <TextInput
          placeholder="Search bill payments"
          w={368}
          value={getFilterByKey('search')?.filterValue || ''}
          onChange={(e) => onSearchChange(e.target.value)}
          leftSection={
            <PiMagnifyingGlass
              size={'1.25rem'}
              color={theme.colors.neutral[5]}
            />
          }
        />
      </Box>
    </Box>
  );
};

export default BillpayPaymentsTableHeader;
