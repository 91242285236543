import { useSideModalFilters } from '@common/composites/details-page/filters/use-side-modal-filters';
import { DateRangeFilter } from '@common/composites/date-range.filter';
import { RightContentModal } from '@common/composites/modal/right-content.modal';
import { sortDate } from '@utilities/dates';
import { formatFilterDate } from '@utilities/formatters/format-datetime';
import { DateTime, Interval } from 'luxon';
import MoneyDirection from '../components/money-direction/money-direction';
import { getFilteredReserveTransactions } from './get-filtered-transactions';

const MoneyMovementModal = () => {
  const transactionFilters = useSideModalFilters();
  const currentDateFilter =
    transactionFilters.getFilterByKey('date')?.filterValue;
  const toDate =
    currentDateFilter && formatFilterDate(currentDateFilter?.endDate?.c);
  const fromDate =
    currentDateFilter && formatFilterDate(currentDateFilter?.startDate?.c);
  // const { data } = useGetReserveAccountTransactions(accountId ?? '');
  // TODO: use the data from the endpoint where this one is fixed
  const reserveTransactions = [
    {
      id: '0',
      bankingTransactionId: '4740707c-572b-43bd-b80b-2b903edf58c0',
      createdAt: '2024-05-29 16:44:54.239+00',
      type: 'receivedAch',
      amount: 500000,
    },
    {
      id: '1',
      bankingTransactionId: '4740707c-572b',
      createdAt: '2024-06-10 12:44:54.239+00',
      type: 'receivedAch',
      amount: 100000,
    },
  ];

  const transactions = getFilteredReserveTransactions(
    reserveTransactions,
    fromDate,
    toDate,
  ).sort((a, b) => sortDate(a, b, false));

  return (
    <RightContentModal>
      <RightContentModal.Header
        titleSize={40}
        title="Money movement"
        subtitle="Reserve account"
      />

      <RightContentModal.Body>
        <DateRangeFilter
          filterHook={transactionFilters}
          filterFn={(startDate, endDate, item) => {
            const dateRange = Interval.fromDateTimes(startDate, endDate);
            return dateRange.contains(DateTime.fromSQL(item.createdAt));
          }}
        />
        <MoneyDirection direction="Credit" transactions={transactions} />
      </RightContentModal.Body>
    </RightContentModal>
  );
};

export default MoneyMovementModal;
