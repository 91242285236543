import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Text, rem, useMantineTheme } from '@mantine/core';

import { sortDate } from '@utilities/dates';
import FlexbaseInput from '@common/composites/input/flexbase-input';
import GenericError from '@common/generic-error';
import TabTableLayout from '@common/layouts/tab-table-layout';
import CheckDepositsTable from './components/check-deposits-table';
import { useGetCheckDeposits } from '@queries/use-checks-deposits';
import { formatCents } from '@utilities/formatters';
import { isTruthyString } from '@utilities/validators/validate-string';
import CheckDepositsFilters from './components/check-deposits-filters';
import { formatCheckStatus } from '@utilities/formatters/format-checks-status';
import { useCheckDepositsFilters } from './components/filters/use-check-deposits-filters';
import { PiCaretDownBold, PiMagnifyingGlass } from 'react-icons/pi';

export type ChecksTable = {
  id: string;
  status: string;
  amount: number;
  createdAt: string;
  description: string;
  counterparty: string;
};

const CheckDeposits = () => {
  const { id: depositAccountId } = useParams();
  const theme = useMantineTheme();
  const [showFilters, setShowFilters] = useState(true);
  const { activeFiltersArray, removeFilter, addFilter } =
    useCheckDepositsFilters();

  const {
    data: checkDepositsData,
    isLoading,
    isError,
    refetch,
  } = useGetCheckDeposits({ depositAccountId });

  const filteredData = useMemo<ChecksTable[]>(() => {
    return (
      checkDepositsData
        ?.map((check) => ({
          id: check.id,
          createdAt: check.createdAt,
          description: check.description,
          amount: formatCents(check.amount),
          status: formatCheckStatus(check.status),
          counterparty: check.counterparty?.name ?? 'N/A',
        }))
        .filter((t) => activeFiltersArray.every((f) => f.fn(t)))
        .sort((a, b) => sortDate(a, b, false)) ?? []
    );
  }, [checkDepositsData, activeFiltersArray]);

  const handleSearchChange = (value: string) => {
    if (!value) {
      removeFilter('search');
      return;
    }

    addFilter('search', {
      key: 'search',
      filterValue: value,
      label: `Includes ${value}`,
      showChip: false,
      fn: (transaction) => {
        const normalizedFilterText = value.toLowerCase().trim();
        return Object.values(transaction)
          .filter(isTruthyString)
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  if (isError) {
    return (
      <GenericError>
        <Text ta="center">
          An unhandled error occurred getting the checks list.
        </Text>
        <Button variant="light" onClick={() => refetch()} mt={rem(20)}>
          Please try again
        </Button>
      </GenericError>
    );
  }

  return (
    <TabTableLayout
      leftHeaderContent={
        <FlexbaseInput
          placeholder="Search checks"
          leftSection={
            <PiMagnifyingGlass
              size={'1.25rem'}
              color={theme.colors.blackish[0]}
            />
          }
          onChange={(e) => handleSearchChange(e.target.value)}
          sx={(_, helpers) => ({
            width: rem(320),
            [helpers.smallerThan('md')]: {
              width: '100%',
            },
          })}
        />
      }
      borderBottomSolid={false}
      rightHeaderContent={
        <Button
          variant="outline"
          rightSection={<PiCaretDownBold />}
          onClick={() => setShowFilters((prev) => !prev)}
          styles={{
            rightIcon: { rotate: !showFilters ? '180deg' : '' },
          }}
        >
          {`${showFilters ? 'Collapse' : 'Expand'} filters`}
        </Button>
      }
      table={
        <>
          <CheckDepositsFilters {...{ showFilters }} />
          <CheckDepositsTable
            checkDeposits={filteredData}
            isLoading={isLoading}
          />
        </>
      }
    />
  );
};

export default CheckDeposits;
