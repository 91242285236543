import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  successContainer: {
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    '@media(max-width: 767px)': {
      width: '100%',
    },
  },
  checkCircle: {
    width: 72,
    height: 72,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkmark: {
    color: '#27C281',
    fontSize: 40,
  },
  infoText: {
    fontWeight: 400,
    fontSize: 36,
    lineHeight: '40px', // In this case using a number does not set it to px..
    textAlign: 'center',
    marginTop: '44px',
    fontFamily: 'redaction',
    width: 500,
    '@media(max-width: 767px)': {
      fontSize: 24,
    },
  },
  flavorText: {
    fontWeight: 400,
    lineHeight: '21px',
    color: theme.primaryColor,
    textAlign: 'center',
    fontSize: 14,
    marginTop: 20,
  },
  buttons: {
    display: 'flex',
    gap: 30,
    marginTop: '20px',
  },
  backButton: {
    borderRadius: '8px',
    width: '100%',
    backgroundColor: theme.primaryColor,
    ':hover': {
      backgroundColor: theme.primaryColor,
    },
  },
  anotherPaymentTextContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 24,
    cursor: 'pointer',
  },
  anotherText: {
    fontFamily: 'Inter',
    fontWeight: 500,
    lineHeight: '16px',
    fontSize: 14,
    color: '#5F5F5F',
  },
  anotherArrow: {
    marginLeft: 16,
  },
  TwoFactorContainer: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'center',
  },
  downloadReceipt: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    marginTop: '20px',
  },
}));
