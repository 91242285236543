import { useRecoilValue } from 'recoil';
import { Button, LoadingOverlay } from '@mantine/core';
import { Navigate, useNavigate } from 'react-router-dom';
import {
  IsFullAdmin,
  ApplicationState,
} from 'recoil-state/application/product-onboarding';
import GenericError from '@common/generic-error';
import { useCompanyDocuments } from '@queries/use-company-documents';
import { useOnboardingOverview } from '@queries/use-onboarding-overview';
import { SelfServiceDashboard } from '@common/utilities/self-service-dashboard/self-service-dashboard';
import { calculateIntlPaymentsActionItems } from '@common/utilities/self-service-dashboard/calculate-action-items';

const SelfServiceDashboardIntlPayments = () => {
  const navigate = useNavigate();
  const status = useRecoilValue(ApplicationState);
  const isFullAdmin = useRecoilValue(IsFullAdmin);
  const {
    refetch,
    data: overview,
    isError: overviewError,
    isPending: overviewLoading,
  } = useOnboardingOverview();
  const {
    data: uploadedDocs,
    isError: uploadedDocsError,
    isPending: uploadedDocsLoading,
  } = useCompanyDocuments();

  if (overviewError || uploadedDocsError) {
    return (
      <GenericError>
        <p>An unhandled error occurred getting the information.</p>
        <Button variant="light" onClick={() => refetch()} mt={20}>
          Please try again
        </Button>
      </GenericError>
    );
  }

  if (overviewLoading || uploadedDocsLoading) {
    return <LoadingOverlay visible={true} />;
  }

  const currentActionItems = calculateIntlPaymentsActionItems(
    status,
    overview,
    uploadedDocs,
  );

  const returnToBanking = !currentActionItems.length || !isFullAdmin;

  return returnToBanking ? (
    <Navigate to={'/banking'} />
  ) : (
    <>
      <SelfServiceDashboard
        status={status}
        overview={overview}
        actionItems={currentActionItems}
      />
      <Button
        my={50}
        ml={250}
        variant="outline"
        c="primarySecondarySuccess.8"
        onClick={() => navigate('/banking')}
      >
        Back
      </Button>
    </>
  );
};

export default SelfServiceDashboardIntlPayments;
