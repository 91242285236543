import { Button, Text } from '@mantine/core';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from '../../../recoil-state/application/product-onboarding';
import useModal from '@common/composites/modal/modal-hook';
import { useNavigate } from 'react-router-dom';
import {
  onError,
  usePlaidBankingComponent,
} from '@utilities/custom-hooks/plaid-banking-component';
import { flexbaseOnboardingClient } from '@services/flexbase-client';
import { showNotification } from '@mantine/notifications';
import { PlaidAccount } from '../../../types/move-funds.model';

type Props = {
  onPlaidAccountLinked: (accounts: PlaidAccount[]) => void;
  setLoading: (loading: boolean) => void;
};

export const AddAccount = ({ onPlaidAccountLinked, setLoading }: Props) => {
  const { productStatus, excludedProducts } = useRecoilValue(ApplicationState);
  const showBankingButton =
    !excludedProducts.includes('BANKING') &&
    productStatus.banking.status === 'unused';
  const modal = useModal();
  const navigate = useNavigate();

  const handleNewPlaidAccountLinked = async () => {
    setLoading(true);
    try {
      const elPlaid = await flexbaseOnboardingClient.getPlaidCompanyAccounts();
      if (elPlaid.accounts && elPlaid.accounts.length > 0) {
        onPlaidAccountLinked(
          elPlaid.accounts.map((p) => ({
            ...p,
            plaidOrDeposit: 'plaid',
          })),
        );
      } else {
        showNotification({
          color: 'critical',
          message:
            'Error: We were able to link your new account but could not retrieve data for it.',
        });
      }
    } catch {
      showNotification({
        color: 'critical',
        message:
          'Error: We were able to link your new account but could not retrieve data for it.',
      });
    } finally {
      setLoading(false);
    }
  };

  const { open } = usePlaidBankingComponent({
    onSuccess: handleNewPlaidAccountLinked,
    onError: onError,
    setLoading: setLoading,
  });

  const handleOpenFlexAcctClick = () => {
    modal.closeAllModals();
    navigate('/banking');
  };

  return (
    <>
      <Text>You must have at least one linked account to make a payment.</Text>
      <Button onClick={() => open()}>Link an external account now</Button>
      {showBankingButton && (
        <>
          <Text ta="center">- or -</Text>
          <Button onClick={handleOpenFlexAcctClick}>
            Apply for a Flex Banking account
          </Button>
        </>
      )}
    </>
  );
};
