import { atom } from 'recoil';
import {
  ActiveFiltersReturnType,
  createUseFiltersHook,
  FilterFnMap,
} from '@common/utilities/filter/filters';
import { BillpayInvoice } from 'types/bill-pay';

const BillpayInvoicesFilterState = atom<FilterFnMap<BillpayInvoice>>({
  key: 'billpay_invoices_filter_state',
  default: {},
});

const BillpayInvoicesFilterStateModal = atom<FilterFnMap<BillpayInvoice>>({
  key: 'bills_filter_state_modal',
  default: {},
});

export function useBillpayInvoicesFilters(): ActiveFiltersReturnType<BillpayInvoice> {
  return createUseFiltersHook<BillpayInvoice>(BillpayInvoicesFilterState);
}

export function useBillpayInvoicesModalFilters(): ActiveFiltersReturnType<BillpayInvoice> {
  return createUseFiltersHook<BillpayInvoice>(BillpayInvoicesFilterStateModal);
}
