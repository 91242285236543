import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(() => ({
  title: {
    fontSize: '2rem',
    '@media(max-width: 767px)': {
      fontSize: '1.43rem',
    },
  },
  closeButton: {
    display: 'flex',
    alignItems: 'center',
    svg: {
      width: '2.12rem',
      height: '2.12rem',
    },
    '@media(max-width: 767px)': {
      svg: {
        width: '1.62rem',
        height: '1.62rem',
      },
    },
  },
  containerButton: {
    position: 'fixed',
    bottom: '1rem',
    width: '100%',
  },
}));
