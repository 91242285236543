import { Stack, Text } from '@mantine/core';
import { PropsWithChildren } from 'react';

type IntegrationSettingsSectionProps = PropsWithChildren<{
  label: string;
}>;

export const IntegrationSettingsSection = function Section({
  children,
  label,
}: IntegrationSettingsSectionProps) {
  return (
    <Stack gap="1rem">
      <Text color="primarySecondarySuccess.8" fw={600}>
        {label}
      </Text>

      {children}
    </Stack>
  );
};
