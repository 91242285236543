import { Box, UnstyledButton } from '@mantine/core';
import { useStyles } from './credit-limit.styles';
import CardRepaymentModal from '@common/card-repayment-modal';
import { useRecoilValue } from 'recoil';
import {
  CompanySelector,
  IsAccountant,
} from '../../../../recoil-state/application/product-onboarding';
import { useCreditBalance } from 'queries/use-credit-balance';
import useModal from '@common/composites/modal/modal-hook';
import { CompanyCreditBalance } from 'types/company';
import CreditLimitWidget from '@common/utilities/credit-limit-widget';
import { FrozenReason } from 'types/onboarding-info';

type Props = {
  hasPendingPayment: boolean;
};

const FrozenSection = ({
  frozenReason,
  hasPendingPayment,
}: {
  frozenReason: FrozenReason;
  hasPendingPayment: boolean;
}) => {
  const { classes } = useStyles();
  const { openRightModal, closeAllModals } = useModal();
  const isAccountant = useRecoilValue(IsAccountant);

  const openPaymentModal = () => {
    openRightModal(<CardRepaymentModal closeModal={closeAllModals} />);
  };

  return (
    <>
      {frozenReason &&
      frozenReason !== 'accountDelinquent' &&
      frozenReason !== 'plaidUnlinked' ? (
        <Box className={classes.frozen}>
          <Box className={classes.unfreezePlaidBtn}>Frozen</Box>
        </Box>
      ) : null}
      {frozenReason === 'accountDelinquent' && (
        <Box className={classes.frozen}>
          <Box c="promote.8" className={classes.unfreezeButton}>
            Frozen -{' '}
            <UnstyledButton
              fz="sm"
              type="button"
              disabled={isAccountant}
              className={classes.payNow}
              onClick={() => openPaymentModal()}
            >
              {hasPendingPayment ? 'Payment is pending' : 'Pay now to unfreeze'}
            </UnstyledButton>
          </Box>
        </Box>
      )}
    </>
  );
};

/**
 *
 * @param hasDelinquentAmount
 * @param hasPendingPayment
 * @constructor
 */
const CreditLimit = ({ hasPendingPayment }: Props) => {
  const { id, frozenReason } = useRecoilValue(CompanySelector);
  const allowedReasons = [
    'plaidDropped',
    'accountDelinquent',
    'deniedBanking',
    'byServicer',
    'unknown',
  ];

  const hasDelinquentAmount =
    frozenReason && allowedReasons.includes(frozenReason);

  const { data } = useCreditBalance(id);

  const getCompanyCredit = (creditBalanceData?: CompanyCreditBalance) => {
    if (!creditBalanceData) {
      return { utilization: 0, available: 0, total: 0, spent: 0, hold: 0 };
    }

    const total = creditBalanceData.creditLimit;
    const available = creditBalanceData.availableLimit;
    // 'currentBalance' includes pending and settled invoices/transactions
    const balance =
      creditBalanceData.currentBalance - creditBalanceData.pendingInvoices;
    const spent = balance;
    const hold = creditBalanceData.pendingInvoices;

    const utilization =
      total > 0 ? Math.ceil((1 - available / total) * 100) : 0;

    return {
      utilization,
      available,
      total,
      spent,
      hold,
    };
  };

  const companyCredit = getCompanyCredit(data);

  return (
    <CreditLimitWidget
      creditBalance={companyCredit}
      frozenSection={
        frozenReason && (
          <FrozenSection
            frozenReason={frozenReason}
            hasPendingPayment={hasPendingPayment}
          />
        )
      }
      hasDelinquentAmount={hasDelinquentAmount}
    />
  );
};

export default CreditLimit;
