import {
  Box,
  Text,
  Flex,
  Group,
  Radio,
  Stack,
  Button,
  Collapse,
  Container,
  Select,
  rem,
} from '@mantine/core';
import { useMemo } from 'react';
import { useForm } from '@mantine/form';
import { LimitInterval, UNIT_LIMIT_INTERVALS } from 'constants/limit-intervals';
import { useGetUsers } from '@queries/use-users';
import Input from '@common/composites/input/flexbase-input';
import { CustomMantineStyles } from '@common/cards-styles';
import { showNotification } from '@mantine/notifications';
import { Group as CardInfoGroup } from 'constants/card-info';
import { useIssueCreditCardMutation } from '@queries/use-credit-cards';
import { formatCurrency } from '@utilities/formatters';
import { useGetChargeCardAccounts } from '@queries/use-charge-card-accounts';
import { RequiredFieldValidator } from 'utilities/validators/validate-required';
import FlexNumberInput from '../../../components/flex-number-input';
import FullScreenModalContainer from '@common/composites/modal/full-screen-modal-container';
import Multiselect from '@common/custom-multiselect';

type Props = {
  closeModal: () => void;
};

type CardType = 'physical' | 'virtual';
type YesNo = 'yes' | 'no';

type CardForm = {
  userId: string;
  cardName: string;
  cardType: CardType;
  spendingLimit: number;
  groups: CardInfoGroup[];
  limitMerchantCategories: YesNo;
  spendingLimitInterval: LimitInterval | undefined;
};

const IssueChargeCard = ({ closeModal }: Props) => {
  const { data: users = [] } = useGetUsers();
  const { data: chargeAccountsData } = useGetChargeCardAccounts();
  const { mutate: issueCreditCard, isPending } = useIssueCreditCardMutation();
  const chargeAccounts =
    chargeAccountsData?.accounts.filter((acc) => acc.status !== 'Closed') ?? [];
  // for v1 the company will have one account
  const currentChargeAccount = chargeAccounts[0];

  const form = useForm<CardForm>({
    initialValues: {
      groups: [],
      userId: '',
      cardName: '',
      spendingLimit: 0,
      cardType: 'virtual',
      limitMerchantCategories: 'no',
      spendingLimitInterval: undefined,
    },
    validate: {
      spendingLimit: (value, values) => {
        if (values.spendingLimitInterval !== 'unlimited' && value <= 0) {
          return 'Spending limit must be greater than 0 unless limit type is unlimited.';
        }
        if (value > Number(currentChargeAccount.creditLimit)) {
          return `Limit amount cannot exceed credit limit of ${formatCurrency(
            currentChargeAccount.creditLimit,
          )}`;
        }
        return null;
      },
      cardName: RequiredFieldValidator('Card name is required.'),
      userId: RequiredFieldValidator('A user must be selected for this card'),
      spendingLimitInterval: (value) => {
        return RequiredFieldValidator('Select a limit type')(value);
      },
    },
  });

  const filteredUsers = useMemo(() => {
    if (!users) {
      return [];
    }

    const availableUsers = users
      .filter(
        (u) =>
          u.completedOnboarding &&
          !u.completedOffboarding &&
          u.status === 'active',
      )
      .map((u) => ({ value: u.id!, label: `${u.firstName} ${u.lastName}` }));

    return availableUsers;
  }, [users]);

  const isPhysicalCard = form.values.cardType === 'physical';

  const handleSaveClick = form.onSubmit(async (formValues) => {
    const isUnlimited = formValues.spendingLimitInterval === 'unlimited';
    const limitsValue = formValues.spendingLimitInterval;
    // we need to convert these values in cents for Unit
    const spendingLimit = Math.round(formValues.spendingLimit * 100);
    const cardSubtype = isPhysicalCard
      ? 'businessCreditCard'
      : 'businessVirtualCreditCard';

    issueCreditCard(
      {
        userId: formValues.userId,
        card: {
          cardSubtype,
          issuer: 'unit-co',
          cardType: formValues.cardType,
          cardName: formValues.cardName,
          chargeCardAccountId: chargeAccounts[0].id,
          limits: {
            groups: formValues.groups,
            ...(limitsValue &&
              !isUnlimited && {
                [limitsValue]: spendingLimit,
              }),
          },
        },
      },
      {
        onSuccess: () => {
          closeModal();
        },
        onError: () => {
          showNotification({
            title: 'Error',
            message: 'Unable to issue the card. Try it again.',
            color: 'critical.2',
          });
        },
      },
    );
  });

  return (
    <FullScreenModalContainer closeModal={closeModal}>
      <Container>
        <Stack gap="lg">
          <Text fz={rem(28)}>Create a new charge card</Text>

          <Select
            searchable
            radius={rem(8)}
            label="Team member"
            data={filteredUsers}
            maxDropdownHeight={280}
            placeholder="Select employee"
            {...form.getInputProps('userId')}
          />

          <Input
            label="Card Name"
            placeholder="e.g. Advertising expenses"
            {...form.getInputProps('cardName')}
          />

          <Radio.Group
            size="sm"
            label="Card type"
            value={form.values.cardType}
            onChange={(value) => {
              form.setFieldValue('cardType', value as CardType);
            }}
            color="primarySecondarySuccess"
            aria-label="card-type"
          >
            <Group mt="xxs" gap="lg">
              <Radio
                value="virtual"
                label="Virtual"
                aria-label="virtual-card"
                styles={{ body: CustomMantineStyles().issueCard.radio.body }}
              />

              <Radio
                value="physical"
                aria-label="physical-card"
                label="Physical"
                styles={{ body: CustomMantineStyles().issueCard.radio.body }}
              />
            </Group>
          </Radio.Group>

          <Select
            radius={8}
            label="Limit type"
            maxDropdownHeight={280}
            data={UNIT_LIMIT_INTERVALS}
            placeholder="Select limit type"
            data-testid="select-limit-type"
            {...form.getInputProps('spendingLimitInterval')}
            onChange={(e) => {
              form.setFieldValue('spendingLimit', 0);
              if (e) {
                form.setFieldValue('spendingLimitInterval', e as LimitInterval);
              }
            }}
          />

          {form.values.spendingLimitInterval !== 'unlimited' && (
            <FlexNumberInput
              radius={8}
              leftSection={<>$</>}
              variant="default"
              pattern="[0-9]*"
              data-testid="amount"
              thousandSeparator=","
              label="Spending limit"
              value={form.values.spendingLimit}
              error={form.errors.spendingLimit}
              onValueChange={(value) => {
                form.setFieldValue('spendingLimit', value.floatValue ?? 0);
              }}
            />
          )}

          <Box>
            {/** Wrap in <Box /> so the Stack gap is correct when the child <Collapse /> is closed */}
            <Radio.Group
              label="Do you want this card to be limited to specific categories of spend?"
              size="sm"
              color="primarySecondarySuccess"
              {...form.getInputProps('limitMerchantCategories')}
            >
              <Group gap="lg" mt="xxs">
                <Radio
                  styles={{ body: CustomMantineStyles().issueCard.radio.body }}
                  aria-label="categoriesYes"
                  value="yes"
                  label="Yes"
                />
                <Radio
                  styles={{ body: CustomMantineStyles().issueCard.radio.body }}
                  aria-label="categoriesNo"
                  value="no"
                  label="No"
                />
              </Group>
            </Radio.Group>

            <Box>
              {/** Wrap <Collapse/> in a <Box/> because the animation doesn't play well when parent is flex */}
              <Collapse in={form.values.limitMerchantCategories === 'yes'}>
                <Box mt="lg">
                  <Multiselect form={form} />
                </Box>
              </Collapse>
            </Box>
          </Box>
        </Stack>

        <Flex mt="xl" justify="space-between" align="center">
          {!isPhysicalCard && (
            <Text color="neutral.7" size="xs">
              The card will be activated within a few moments.
            </Text>
          )}

          <Button
            radius={rem(8)}
            loading={isPending}
            onClick={() => handleSaveClick()}
            fullWidth={form.values.cardType === 'physical'}
          >
            Create Card
          </Button>
        </Flex>
      </Container>
    </FullScreenModalContainer>
  );
};

export default IssueChargeCard;
