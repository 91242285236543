import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { Alert, Box } from '@mantine/core';

import EditCard from 'areas/cards/credit-cards/card-details/edit-card';
import CreditCardActions from 'areas/cards/credit-cards/card-details/card-actions';

import 'areas/cards/lithic-frame.css';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { CompanySelector } from '../recoil-state/application/product-onboarding';
import { formatCurrency } from '@utilities/formatters';
import { CardFrame } from 'areas/cards/credit-cards/card-details/card-frame';
import { CreditCard, IsEmbedUrlResponse } from '@services/flexbase/card.model';
import { capitalizeOnlyFirstLetter } from '@utilities/formatters/format-strings';
import { CardConfirmCancel } from 'areas/cards/credit-cards/card-details/card-confirm-cancel';
import { CardConfirmStatusUpdate } from 'areas/cards/credit-cards/card-details/card-confirm-status-update';
import { RightContentModal } from '@common/composites/modal/right-content.modal';
import { notifications } from '@mantine/notifications';
import {
  useCardCategory,
  useUpdateCreditCardStatusMutation,
} from '@queries/use-credit-cards';
import { useActiveExpenseLink } from '@utilities/integrations/accounting';
import { useGetMe } from '@queries/use-get-me';
import { useGetSpendPlansByCardId } from '@queries/use-spend-plans';
import { useSpendPlansFeatureFlag } from '@utilities/feature-flags';
import {
  CardUtilization,
  CardUtilizations,
} from 'areas/cards/credit-cards/card-details/card-utilization';

function formatCardInterval(interval: string | null | undefined) {
  let asLowerCase = interval?.toLowerCase() || '';

  if (asLowerCase === 'annually') {
    asLowerCase = 'yearly';
  }

  return capitalizeOnlyFirstLetter(asLowerCase);
}

function formatPlanFrequency(frequency: string) {
  if (!frequency) {
    return '';
  }

  let asLowerCase = frequency.toLowerCase();

  if (asLowerCase === 'onetime') {
    asLowerCase = 'one time';
  }

  return capitalizeOnlyFirstLetter(asLowerCase);
}

type ICardDetails = {
  passedCard: CreditCard;
};

const CardDetails = ({ passedCard }: ICardDetails) => {
  const companyInfo = useRecoilValue(CompanySelector);
  const [errorMessage, setErrorMessage] = useState('');

  const [bottomSection, setBottomSection] = useState<
    'actions' | 'edit' | 'cancel' | 'activate'
  >('actions');

  // This state controls the iframe that we display secure card data in.
  const [embedUrl, setEmbedUrl] = useState('');
  const [embedUrlError, setEmbedUrlError] = useState('');
  const [embedUrlLoading, setEmbedUrlLoading] = useState(true);

  // This makes a local copy of the card in state. After the modal is dispatched it can no longer receive updates
  // to the card prop, so this is necessary to update certain card state variables.
  const [card, setCard] = useState<CreditCard>(passedCard);

  const { mutate, isPending } = useUpdateCreditCardStatusMutation();

  const { expenseLink, connectionId = '' } = useActiveExpenseLink();
  const shouldRenderCategory = !!connectionId && !!expenseLink?.enabledExpenses;
  const { data: cardCategory } = useCardCategory({
    connectionId,
    cardId: passedCard.id,
  });
  const spendPlanFlagEnabled = useSpendPlansFeatureFlag();
  const { data: personInfo } = useGetMe();
  const accountId = personInfo?.accountId || '';
  const { data: spendPlansByCardId } = useGetSpendPlansByCardId({
    enabled: spendPlanFlagEnabled,
    accountId: accountId,
    cardId: card.id,
  });

  const activeSpendPlans = spendPlansByCardId?.filter(
    (plan) => plan.isPlanActive,
  );
  const primarySpendPlan = activeSpendPlans?.at(0);

  const spentThisMonth = primarySpendPlan
    ? primarySpendPlan.spent
    : (card.monthToDateSpends?.mtdSpend ?? 0);

  const utilizations: CardUtilization[] = [
    {
      name: `Default card limit`,
      frequency: formatCardInterval(card.toDateSpend?.interval ?? 'monthly'),
      value: card.toDateSpend?.toDateSpend ?? 0,
      total: card.expensesTypes?.amount || 0,
    },
  ];

  activeSpendPlans?.forEach((plan) => {
    utilizations.push({
      name: `${plan.name || 'Spend plan'}`,
      subtitle: 'Spend plan',
      frequency: formatPlanFrequency(plan.frequency),
      value: plan.spent,
      total: plan.limit,
    });
  });

  const onCardUpdate = (c: CreditCard) => {
    setCard(c);
    setBottomSection('actions');
  };

  const getCardHiddenInfo = async (cardId: string) => {
    setEmbedUrlLoading(true);
    try {
      const response = await flexbaseOnboardingClient.getCardHiddenInfo(
        cardId,
        true,
      );

      if (IsEmbedUrlResponse(response)) {
        setEmbedUrl(response.embedUrl);
      } else {
        setEmbedUrlError('Could not process secure card information response');
      }
    } catch (error) {
      setEmbedUrlError('Unable to load secure card information');
    } finally {
      setEmbedUrlLoading(false);
    }
  };

  const updateCardStatus = async (
    newStatus: 'active' | 'suspended' | 'terminated',
    last4?: string,
  ) => {
    mutate(
      {
        cardId: card.id,
        status: newStatus,
        ...(last4 && { last4 }),
      },
      {
        onSuccess: (updatedCard) => {
          onCardUpdate(updatedCard);
          notifications.show({
            title: 'Success',
            message: `Successfully updated card. ${
              newStatus === 'active'
                ? 'This card is now active and can be used immediately.'
                : 'This card is now  inactive and transactions will be declined.'
            }`,
          });
        },
        onError: () => {
          const prettyAction =
            newStatus === 'active'
              ? 'activate'
              : newStatus === 'suspended'
                ? 'freeze'
                : 'cancel';
          setErrorMessage(
            `An error occurred while updating card status. Unable to ${prettyAction} card.`,
          );
        },
      },
    );
  };

  useEffect(() => {
    getCardHiddenInfo(card.id);
  }, []);

  // TODO: Need to better architect this, as some of the components do updates themselves while others expect props
  const BOTTOM_SECTION = {
    edit: (
      <EditCard
        card={card}
        onCancelClick={() => setBottomSection('actions')}
        shouldRenderCategory={shouldRenderCategory}
        connectionId={connectionId}
        onCardUpdated={setCard}
        spendPlan={primarySpendPlan}
        formatFrequency={formatPlanFrequency}
      />
    ),
    cancel: (
      <CardConfirmCancel
        onConfirmClick={() => updateCardStatus('terminated')}
        onCloseClick={() => setBottomSection('actions')}
      />
    ),
    actions: (
      <>
        {card.status !== 'terminated' && (
          <CreditCardActions
            card={card}
            onCardActionClick={(action) => {
              if (action !== 'freeze' && action !== 'thaw') {
                setBottomSection(action);
              } else {
                updateCardStatus(action === 'freeze' ? 'suspended' : 'active');
              }
            }}
            showLoadingDots={isPending}
          />
        )}

        {utilizations.length > 0 && (
          <>
            <RightContentModal.Divider />
            <CardUtilizations utilizations={utilizations} />
          </>
        )}

        {shouldRenderCategory ? (
          <>
            <RightContentModal.Divider />
            <RightContentModal.TitleValueLine
              title="Accounting category"
              value={
                cardCategory?.card?.category.displayName ??
                cardCategory?.card?.category.name ??
                'No category'
              }
            />
          </>
        ) : null}

        <RightContentModal.Divider />
        <RightContentModal.AddressSection
          address={companyInfo.address}
          title="Billing address"
        />
        <RightContentModal.Divider />
        <RightContentModal.TitleValueLine
          title="Card type"
          value={capitalizeOnlyFirstLetter(card.cardType)}
        />
      </>
    ),
    activate: (
      <CardConfirmStatusUpdate
        card={passedCard}
        onStatusUpdated={onCardUpdate}
        prettyStatusLabel="activate"
        newStatus="active"
        onCancel={() => setBottomSection('actions')}
        connectionId={connectionId}
      />
    ),
  };

  return (
    <RightContentModal>
      <RightContentModal.Header
        title={card.cardName}
        rightTitle={formatCurrency(spentThisMonth)}
        subtitle={card.holder}
        rightSubtitle="Spent this month"
      />

      <RightContentModal.Body>
        <RightContentModal.Card>
          <CardFrame
            iframeUrl={embedUrl}
            status={card.status}
            error={embedUrlError}
            loading={embedUrlLoading}
          />
        </RightContentModal.Card>

        <Box mt={32}>{BOTTOM_SECTION[bottomSection]}</Box>

        {errorMessage && (
          <Alert mt="md" onClose={() => setErrorMessage('')}>
            {errorMessage}
          </Alert>
        )}
      </RightContentModal.Body>
    </RightContentModal>
  );
};

export default CardDetails;
