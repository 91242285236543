import { createWizard } from '@common/emerald/wizard/create-wizard';
import { DetailsStepFormValues } from './steps/details/details-step.context';
import { SpendControlsStepFormValues } from './steps/spend-controls/spend-controls-step.context';
import { TeamMembersStepFormValues } from './steps/team-members/team-members-step.context';
import { RestrictionsStepFormValues } from './steps/restrictions/restrictions-step.context';

export type SpendPlanFormState = DetailsStepFormValues &
  SpendControlsStepFormValues &
  TeamMembersStepFormValues &
  RestrictionsStepFormValues;

export const [CreateSpendPlanWizard, useCreateSpendPlanWizard] =
  createWizard<SpendPlanFormState>();
