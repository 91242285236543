import {
  Alert,
  Anchor,
  Box,
  Button,
  Collapse,
  Group,
  Radio,
  rem,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useStyles } from './invite-user.styles';
import { useForm, UseFormReturnType } from '@mantine/form';
import { validateRequired } from '../../../utilities/validators/validate-required';
import Input from '@common/composites/input/flexbase-input';
import ModalSuccess from '@common/modal-success';
import { CompanyIdState } from '../../../recoil-state/application/onboarding-form.state';
import OptionBox from './option-box';
import {
  ApplicationState,
  IsFullAdmin,
  OptedProduct,
} from '../../../recoil-state/application/product-onboarding';
import { CustomMantineStyles } from '@common/cards-styles';
import { EmailValidator } from '../../../utilities/validators/validate-email';
import { Group as CardInfoGroup } from '../../../constants/card-info';
import { FormValidationResult } from '@mantine/form/lib/types';
import { roleDescriptions } from 'constants/index';
import { useCreditBalance } from '../../../queries/use-credit-balance';
import FlexNumberInput from '@common/flex-number-input';
import { formatCurrency } from '@utilities/formatters';
import { LIMIT_INTERVALS, LimitInterval } from 'constants/limit-intervals';
import { useCreateCardCategory } from '../../../queries/use-credit-cards';
import FlexbaseSelect from '../../../components/composites/flexbase-select';
import Multiselect from '@common/custom-multiselect';
import { DatePickerInput } from '@mantine/dates';
import { DateTime } from 'luxon';
import CardCategorySelect from '@common/composites/card-category-select';
import { useActiveExpenseLink } from '@utilities/integrations/accounting';
import { platformClient } from '../../../services/platform/platform-client';
import { useGetMe } from '../../../queries/use-get-me';
import { PiCalendarBlank } from 'react-icons/pi';

export type UserInviteFormValues = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  roles: string[];
};

type IssueCardFormValues = {
  cardType: 'physical' | 'virtual' | 'both';
  limitInterval: LimitInterval | undefined;
  limitAmount: number;
  cardName: string;
  groups: CardInfoGroup[];
  categoryId?: string;
  terminateAt: string | null;
  issueCard: YesNo;
  autoExpire: YesNo;
};

export type CardDetails = {
  cardType: 'physical' | 'virtual' | 'both';
  limits?: {
    interval?: LimitInterval;
    amount?: number;
  };
  cardName?: string;
};

type Props = { closeModal: () => void; refreshTeamMembers?: () => void };

type YesNo = 'yes' | 'no';

type RolesProps = {
  label: string;
  value: string[];
  description: string;
  products: OptedProduct[];
};

const roles: RolesProps[] = [
  {
    products: ['BANKING', 'CREDIT'],
    label: 'Admin - Full',
    value: ['ADMIN', 'COMPTROLLER'],
    description: roleDescriptions.adminFull,
  },
  {
    products: ['BANKING', 'CREDIT'],
    label: 'Admin - Limited',
    value: ['ADMIN'],
    description: roleDescriptions.adminLimited,
  },
  {
    products: ['CREDIT'],
    label: 'Employee',
    value: ['EMPLOYEE'],
    description: roleDescriptions.employee,
  },
  {
    products: ['BANKING', 'CREDIT'],
    label: 'Bookkeeper',
    value: ['ACCOUNTANT'],
    description: roleDescriptions.bookKeeper,
  },
];

const CARD_FORM_STACK_SPACING = 'lg';
const CARD_FORM_RADIO_SPACING = 'lg';

const InviteUser = ({ closeModal, refreshTeamMembers }: Props) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState<'invite' | 'card' | 'success'>('invite');
  const [limitCategories, setLimitCategories] = useState<YesNo>('no');
  const companyId = useRecoilValue(CompanyIdState);
  const { data } = useCreditBalance(companyId);
  const { data: platformAccount } = useGetMe();
  const isFullAdmin = useRecoilValue(IsFullAdmin);

  const { optedProducts } = useRecoilValue(ApplicationState);

  const availableRoles = roles.filter((role) => {
    if (role.label === 'Admin - Full') {
      if (!isFullAdmin) {
        return false;
      }
    }
    return true;
  });

  const { expenseLink, connectionId = '' } = useActiveExpenseLink();

  const { mutate: mutateCategoryCard } = useCreateCardCategory();

  const userInviteForm = useForm<UserInviteFormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      emailAddress: '',
      roles: [],
    },
    validate: {
      firstName: (v) =>
        validateRequired(v)
          ? null
          : 'Please enter the first name of the user you wish to invite.',
      lastName: (v) =>
        validateRequired(v)
          ? null
          : 'Please enter the last name of the user you wish to invite.',
      emailAddress: (v) =>
        EmailValidator()(v) === null
          ? null
          : 'Please enter an email address for the user you wish to invite.',
      roles: (v) =>
        v && v.length > 0
          ? null
          : 'Please select at least one role for the user you wish to invite.',
    },
  });

  const issueCardForm: UseFormReturnType<IssueCardFormValues> =
    useForm<IssueCardFormValues>({
      initialValues: {
        issueCard: 'no',
        cardType: 'virtual',
        limitInterval: undefined,
        limitAmount: 0,
        cardName: '',
        groups: [],
        categoryId: '',
        terminateAt: null,
        autoExpire: 'no',
      },
      validate: {
        cardName: (v, values) =>
          values.issueCard === 'no' || validateRequired(v)
            ? null
            : 'Card name is required.',
        limitAmount: (v, values) => {
          if (
            values.issueCard === 'no' ||
            values.limitInterval === 'unlimited'
          ) {
            return null;
          }
          if (data?.creditLimit && v > data.creditLimit) {
            return `Limit amount cannot exceed credit limit of ${formatCurrency(
              data.creditLimit,
            )}`;
          }
        },
      },
    });

  const isVirtualCard = issueCardForm?.values?.cardType === 'virtual';
  const hasIntegrationLinks = connectionId ? true : false;
  const shouldRenderCategory =
    isVirtualCard && hasIntegrationLinks && !!expenseLink?.enabledExpenses;

  const setCardLimitCategories = () => {
    if (limitCategories === 'no') {
      setLimitCategories('yes');
    } else {
      setLimitCategories('no');
      issueCardForm.setFieldValue('groups', []);
    }
  };

  const sendInvite = async () => {
    try {
      setLoading(true);
      const formValues = userInviteForm.values;
      const validationResult = userInviteForm.validate();

      const sendInviteApiCall = async (cardDetails?: CardDetails[]) => {
        try {
          const result = await platformClient.inviteUser(
            platformAccount!.accountId,
            formValues.emailAddress,
            'user',
            {
              firstName: formValues.firstName,
              lastName: formValues.lastName,
              roles: formValues.roles,
              cardDetails,
              companyId,
            },
          );

          if (shouldRenderCategory && issueCardForm.values.categoryId) {
            mutateCategoryCard({
              categoryId: issueCardForm.values.categoryId || '',
              cardName: issueCardForm.values.cardName,
              userId: result.person.id,
              connectionId,
            });
          }

          setError('');
          setStep('success');

          if (refreshTeamMembers) {
            refreshTeamMembers();
          }
        } catch {
          // Platform actually returns decent errors so we could better target this error message
          setError('There was an error inviting the new user.');
        }
      };

      const cardFormValues = issueCardForm.values;
      let cardValidationResult: FormValidationResult;
      if (cardFormValues.issueCard === 'yes') {
        cardValidationResult = issueCardForm.validate();
      }

      const cardLimits = {
        ...(cardFormValues.limitInterval && {
          interval: cardFormValues.limitInterval,
        }),
        ...(cardFormValues.limitAmount && {
          amount: cardFormValues.limitAmount,
        }),
        ...(cardFormValues.groups.length && {
          groups: cardFormValues.groups,
        }),
      };

      if (cardFormValues.issueCard === 'yes') {
        // If there's only one card, just validate it alone
        if (!validationResult.hasErrors && !cardValidationResult!.hasErrors) {
          await sendInviteApiCall([
            {
              cardType: cardFormValues.cardType,
              limits: cardLimits,
              ...(cardFormValues.cardName && {
                cardName: cardFormValues.cardName,
              }),
              ...(cardFormValues.autoExpire === 'yes' && {
                terminateAt: cardFormValues.terminateAt,
              }),
            },
          ]);
        }
      } else if (!validationResult.hasErrors) {
        await sendInviteApiCall();
      }
    } catch (err) {
      if (err.message.includes('supplied email is already in use')) {
        setError(
          'An account with this email already exists. Please use different email or contact customer support at 415-840-8721.',
        );
      } else {
        setError('There was an error inviting the new user.');
      }
      console.error('Unable to invite user', err);
    } finally {
      setLoading(false);
    }
  };

  const handleOnchangeCategory = (value: string | null) => {
    issueCardForm.setFieldValue('categoryId', value || '');
  };

  const onNextClick = () => {
    const validationResult = userInviteForm.validate();
    if (validationResult.errors.role) {
      setError('Please select a role');
    } else {
      setError('');
    }
    if (!validationResult.hasErrors && optedProducts.includes('CREDIT')) {
      setStep('card');
    } else {
      sendInvite();
    }
  };

  const contents = {
    success: (
      <ModalSuccess
        title={`Invite sent to ${userInviteForm.values.firstName}!`}
        backTo="team page"
        closeModal={closeModal}
        onClick={() => {
          userInviteForm.reset();
          issueCardForm.reset();
          setStep('invite');
        }}
        textToStart="Invite another person"
      />
    ),
    invite: (
      <Box className={classes.container}>
        <Text className={classes.title}>Invite team members</Text>
        <Box className={classes.inputContainer}>
          <Input
            label="First Name"
            w={'167px'}
            style={{ paddingTop: 20 }}
            placeholder="First Name"
            {...userInviteForm.getInputProps('firstName')}
          />
          <Input
            label="Last Name"
            w={'167px'}
            ml={'1rem'}
            style={{ paddingTop: 20 }}
            placeholder="Last Name"
            {...userInviteForm.getInputProps('lastName')}
          />
        </Box>
        <Input
          label="Email"
          style={{ paddingTop: 20 }}
          placeholder="Email address"
          {...userInviteForm.getInputProps('emailAddress')}
        />

        <Box>
          <Box className={classes.rolesLabel}>Assign role</Box>
          <Box className={classes.rolesDescription}>
            A team member&apos;s role determines what they can see and do on
            your Flex account.{' '}
            <Anchor
              className={classes.link}
              href="https://flexbase.zendesk.com/hc/en-us/articles/9118462437261-What-are-the-types-of-user-roles-on-flexbase-"
              target="_blank"
            >
              Learn more about roles
            </Anchor>
          </Box>
          {availableRoles
            .filter((role) =>
              role.products.some((product) => optedProducts.includes(product)),
            )
            .map((role) => (
              <OptionBox
                key={role.label}
                optionLabel={role.label}
                optionDescription={role.description}
                isSelected={
                  userInviteForm.values.roles &&
                  role.value.length === userInviteForm.values.roles.length &&
                  role.value.every((roleItem) =>
                    userInviteForm.values.roles.includes(roleItem),
                  )
                }
                onSelect={() =>
                  userInviteForm.setFieldValue('roles', role.value)
                }
              />
            ))}
        </Box>

        <Text style={{ paddingBottom: 10, color: theme.colors.critical[3] }}>
          {error}
        </Text>
        <Box style={{ justifyContent: 'space-between', display: 'flex' }}>
          <Button variant="outline" disabled={loading} onClick={closeModal}>
            Cancel
          </Button>
          <Button
            variant="light"
            loading={loading}
            onClick={() => onNextClick()}
          >
            {optedProducts.includes('CREDIT') ? 'Next' : 'Send Invite'}
          </Button>
        </Box>
      </Box>
    ),
    card: (
      <Box className={classes.issueContainer}>
        <Stack gap={CARD_FORM_STACK_SPACING}>
          <Text className={classes.title}>Create a virtual credit card</Text>

          <Radio.Group
            label="Issue virtual credit card?"
            size="sm"
            {...issueCardForm.getInputProps('issueCard')}
            color={theme.primaryColor}
            aria-label="card-type"
          >
            <Group mt="xs" gap={CARD_FORM_RADIO_SPACING}>
              <Radio
                styles={{ body: CustomMantineStyles().issueCard.radio.body }}
                aria-label="yes-card"
                value="yes"
                label="Yes"
              />
              <Radio
                styles={{ body: CustomMantineStyles().issueCard.radio.body }}
                aria-label="no-card"
                value="no"
                label="No"
              />
            </Group>
          </Radio.Group>

          <Alert
            color="info"
            withCloseButton={false}
            styles={{
              root: {
                borderWidth: 0,
              },
              message: {
                fontSize: rem(12),
              },
            }}
          >
            Your team member will be able to start using this card immediately
            after they finish creating an account.
          </Alert>

          <TextInput
            label="Card purpose"
            data-testid="card-name"
            placeholder="e.g. Advertising expenses"
            {...issueCardForm.getInputProps('cardName')}
            disabled={issueCardForm.values.issueCard === 'no'}
          />

          <FlexbaseSelect
            label="Limit frequency"
            placeholder="Select frequency"
            data={LIMIT_INTERVALS}
            maxDropdownHeight={280}
            data-testid="select-limit-type"
            {...issueCardForm.getInputProps('limitInterval')}
            onChange={(e: string | null) => {
              issueCardForm.setFieldValue('limitAmount', 0);
              if (e) {
                issueCardForm.setFieldValue(
                  'limitInterval',
                  e as LimitInterval,
                );
              }
            }}
            inputProps={{
              radius: 8,
              disabled: issueCardForm.values.issueCard === 'no',
            }}
          />

          {issueCardForm.values.limitInterval !== 'unlimited' && (
            <FlexNumberInput
              label="Limit"
              mt="0.4rem"
              variant="default"
              radius={8}
              labelProps={{
                color: '#4B4B4B',
              }}
              leftSection={<>$</>}
              pattern="[0-9]*"
              thousandSeparator=","
              data-testid="amount"
              styles={{
                label: { color: 'black' },
                input: {
                  '&:focus': { borderColor: theme.primaryColor },
                },
              }}
              onValueChange={(value) => {
                issueCardForm.setFieldValue(
                  'limitAmount',
                  value.floatValue as number,
                );
              }}
              value={issueCardForm.values.limitAmount}
              disabled={issueCardForm.values.issueCard === 'no'}
              error={issueCardForm.errors.limitAmount}
            />
          )}

          {shouldRenderCategory && (
            <CardCategorySelect
              value={issueCardForm.values.categoryId}
              onChange={handleOnchangeCategory}
              disabled={issueCardForm.values.issueCard === 'no'}
            />
          )}

          <Box>
            {/** Wrap in <Box /> so the Stack gap is correct when the child <Collapse /> is closed */}
            <Radio.Group
              label="Restrict spending to specific categories?"
              size="sm"
              value={limitCategories}
              onChange={() => setCardLimitCategories()}
              color={theme.primaryColor}
            >
              <Group mt="xs" gap={CARD_FORM_RADIO_SPACING}>
                <Radio
                  style={{ marginTop: '8px' }}
                  styles={{ body: CustomMantineStyles().issueCard.radio.body }}
                  aria-label="categoriesYes"
                  value="yes"
                  label="Yes"
                  disabled={issueCardForm.values.issueCard === 'no'}
                />
                <Radio
                  styles={{ body: CustomMantineStyles().issueCard.radio.body }}
                  aria-label="categoriesNo"
                  value="no"
                  label="No"
                  disabled={issueCardForm.values.issueCard === 'no'}
                />
              </Group>
            </Radio.Group>

            <Box>
              {/** Wrap <Collapse/> in a <Box/> because the animation doesn't play well when parent is flex */}
              <Collapse in={limitCategories === 'yes'}>
                <Box mt={CARD_FORM_STACK_SPACING}>
                  <Multiselect
                    form={issueCardForm}
                    disabled={issueCardForm.values.issueCard === 'no'}
                  />
                </Box>
              </Collapse>
            </Box>
          </Box>

          <Box>
            {/** Wrap in <Box /> so the Stack gap is correct when the child <Collapse /> is closed */}
            <Radio.Group
              label="Set termination date?"
              size="sm"
              color={theme.primaryColor}
              {...issueCardForm.getInputProps('autoExpire')}
            >
              <Group mt="xs" gap={CARD_FORM_RADIO_SPACING}>
                <Radio
                  styles={{ body: CustomMantineStyles().issueCard.radio.body }}
                  aria-label="autoExpireYes"
                  value="yes"
                  label="Yes"
                  disabled={issueCardForm.values.issueCard === 'no'}
                />
                <Radio
                  styles={{ body: CustomMantineStyles().issueCard.radio.body }}
                  aria-label="autoExpireNo"
                  value="no"
                  label="No"
                  disabled={issueCardForm.values.issueCard === 'no'}
                />
              </Group>
            </Radio.Group>

            <Box>
              {/** Wrap <Collapse/> in a <Box/> because the animation doesn't play well when parent is flex */}
              <Collapse in={issueCardForm.values.autoExpire === 'yes'}>
                <DatePickerInput
                  mt={CARD_FORM_STACK_SPACING}
                  leftSection={
                    <PiCalendarBlank
                      size={'1.25rem'}
                      color={theme.colors.blackish[0]}
                    />
                  }
                  minDate={DateTime.fromJSDate(new Date())
                    .plus({ days: 1 })
                    .toJSDate()}
                  clearable
                  valueFormat="YYYY-MM-DD"
                  label="Termination date"
                  disabled={issueCardForm.values.issueCard === 'no'}
                  onChange={(d) => {
                    if (d) {
                      const date = DateTime.fromJSDate(d);
                      issueCardForm.setFieldValue(
                        'terminateAt',
                        date.toFormat('yyyy-MM-dd'),
                      );
                    } else {
                      issueCardForm.setFieldValue('terminateAt', null);
                    }
                  }}
                />
              </Collapse>
            </Box>
          </Box>

          {error && (
            <Text mt="1rem" color={theme.colors.critical[3]}>
              {error}
            </Text>
          )}

          <Box
            mt="1rem"
            style={{
              justifyContent: 'space-between',
              display: 'flex',
            }}
          >
            <Button
              variant="outline"
              disabled={loading}
              onClick={() => {
                setStep('invite');
              }}
            >
              Back
            </Button>
            <Button
              variant="light"
              loading={loading}
              onClick={() => sendInvite()}
            >
              Send Invite
            </Button>
          </Box>
        </Stack>
      </Box>
    ),
  };

  return contents[step];
};

export default InviteUser;
