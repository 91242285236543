import ErrorIcon from '@common/composites/error-icon';
import WizardErrorCard from '@common/composites/wizard-error-card';
import { Box, Button, Group, useMantineTheme } from '@mantine/core';
import {
  MoneyMovement,
  MoneyMovementStatus,
} from '@services/flexbase/banking.model';
import { DateTime } from 'luxon';
import { FiArrowRight } from 'react-icons/fi';
import { PiCheckCircle } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';

const isVowel = (char: string) => {
  return ['a', 'e', 'i', 'o', 'u'].includes(char.toLowerCase());
};

const createSuccessMessage = (
  type: string,
  amount: string,
  toWhom: string,
  status: MoneyMovementStatus,
) => {
  const paymentType = type === 'ach' ? 'ACH' : type;

  if (status === 'AwaitingApproval') {
    return `Your ${amount} ${paymentType} payment to ${toWhom} is pending approval.`;
  }

  return `Success! You've scheduled ${
    isVowel(paymentType.charAt(0)) ? 'an' : 'a'
  }${' '}${paymentType} payment of ${amount} to ${toWhom}`;
};

const createScheduledMessage = (
  scheduledFor: string,
  status: MoneyMovementStatus,
) => {
  const scheduledDate = DateTime.fromISO(scheduledFor).toFormat('MMM dd, yyyy');

  if (status === 'AwaitingApproval') {
    return 'Payment will be scheduled upon approval.';
  }

  return `Pay once on ${scheduledDate}.`;
};

const getBoxStyles = (isErr: boolean) => ({
  background: isErr
    ? 'initial'
    : 'url(/images/page-top-frame.png) top / contain no-repeat #223F2E',
  backgroundSize: 'contain',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
});

type Props = {
  onGoBack: () => void;
  details: Error | MoneyMovement;
};

const PaymentConfirmation = ({ onGoBack, details }: Props) => {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const isError = details instanceof Error;

  const handleGoToDashboard = () => {
    navigate('/bill-pay');
  };

  const {
    icon,
    title,
    subtitle = undefined,
    actions,
  } = isError
    ? {
        icon: <ErrorIcon />,
        title: 'There was an error in paying this bill.',
        subtitle: 'Your progress has been saved.',
        actions: [
          <Group gap="sm" wrap="nowrap" key="back-to-bill-pay-group">
            <Button size="sm" onClick={onGoBack} variant="outline">
              Back
            </Button>
            <Button size="sm" onClick={handleGoToDashboard}>
              Back To Bill Pay
            </Button>
          </Group>,
        ],
      }
    : {
        icon: (
          <PiCheckCircle
            size={'4.875rem'}
            color={theme.colors.primarySecondarySuccess[2]}
          />
        ),
        title: createSuccessMessage(
          details.type,
          details.payAmount,
          details.payCtrParty,
          details.status,
        ),
        subtitle:
          details.scheduledFor &&
          createScheduledMessage(details.scheduledFor, details.status),
        actions: [
          <Button
            key="back-to-bill-pay-button"
            size="sm"
            onClick={handleGoToDashboard}
            rightSection={<FiArrowRight />}
          >
            Back To Bill Pay
          </Button>,
        ],
      };

  return (
    <Box style={getBoxStyles(isError)}>
      <WizardErrorCard icon={icon} title={title} subtitle={subtitle}>
        {actions}
      </WizardErrorCard>
    </Box>
  );
};

export default PaymentConfirmation;
