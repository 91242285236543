import { Box, Flex, Group, Text, useMantineTheme } from '@mantine/core';
import { PiXCircle } from 'react-icons/pi';

const WizardErrorBanner = ({ message }: { message?: string }) => {
  const theme = useMantineTheme();
  if (!message) {
    return null;
  }
  return (
    <Box
      bg={theme.colors.red[0]}
      p="md"
      mt="sm"
      style={{ border: `1px solid ${theme.colors.red[6]}` }}
    >
      <Group gap="sm" wrap="nowrap" align="center">
        <Flex>
          <PiXCircle size={'1.5rem'} color={theme.colors.red[6]} />
        </Flex>
        <Text size="sm">{message}</Text>
      </Group>
    </Box>
  );
};

export default WizardErrorBanner;
