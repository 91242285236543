import {
  Box,
  Center,
  Divider,
  Loader,
  Paper,
  rem,
  SegmentedControl,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useGetDepositAccounts } from '@queries/use-deposit-accounts';
import { useExternalAccounts } from '@utilities/custom-hooks/use-external-accounts';
import { isHoliday, isWeekend } from '@utilities/dates';
import { DepositAccount, PlaidAccount } from 'types/move-funds.model';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import {
  InvoiceWizard,
  PaymentInfo,
  PaymentSource,
  useInvoiceWizard,
} from '../../invoice-wizard';
import { BankingAccountSource } from './banking-account-source';
import { LineOfCreditSource } from './line-of-credit-source';
import { useCreditLines } from '@queries/use-credit-lines';
import { ACH_COPY, WIRE_COPY } from 'constants/constants';
import { PayMethod } from 'types/payments';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import { currencyToCents } from '@utilities/formatters';
import { useBillPayV2FeatureFlag } from '@utilities/feature-flags';

const MAX_PAYMENT_DATE = DateTime.now().plus({ years: 2 }).toJSDate();
const MIN_PAYMENT_DATE = DateTime.now().toJSDate();

const PAYMENT_SOURCES = [
  {
    value: PaymentSource.ACCOUNT,
    label: 'Banking account',
  },
  {
    value: PaymentSource.LOC,
    label: 'Line of credit [WIP]',
  },
];

const sendOnDateLabel: Partial<Record<PayMethod, string>> = {
  wire: WIRE_COPY,
  ach: ACH_COPY,
};

const usePaymentAccounts = () => {
  const { data: depositAccounts, isLoading: loadingDepositAccounts } =
    useGetDepositAccounts();
  const { data: plaidAccounts, isLoading: loadingPlaidDepositAccounts } =
    useExternalAccounts();

  const paymentAccounts = [
    ...(depositAccounts?.accounts || [])
      .filter((account) => account.status === 'Open')
      .sort((a, b) => b.balance - a.balance)
      .map((account) => ({ ...account, plaidOrDeposit: 'deposit' as const })),
    ...(plaidAccounts?.reduce((accounts, account) => {
      if (
        !account.unlinked &&
        !accounts.some(({ last4 }) => account.last4 === last4)
      ) {
        accounts.push({ ...account, plaidOrDeposit: 'plaid' as const });
      }
      return accounts;
    }, [] as PlaidAccount[]) || []),
  ];

  return {
    paymentAccounts,
    isLoading: loadingDepositAccounts || loadingPlaidDepositAccounts,
  };
};

const AmountSourceStep = () => {
  const { paymentAccounts, isLoading: isLoadingPaymentAccounts } =
    usePaymentAccounts();
  return (
    <AmountSourceStepContent
      paymentAccounts={paymentAccounts}
      isLoadingPaymentAccounts={isLoadingPaymentAccounts}
    />
  );
};

type AmountSourceStepContentProps = {
  paymentAccounts: (PlaidAccount | DepositAccount)[];
  isLoadingPaymentAccounts: boolean;
};

const AmountSourceStepContent = ({
  paymentAccounts,
  isLoadingPaymentAccounts,
}: AmountSourceStepContentProps) => {
  const { businessId } = useRecoilValue(ApplicationState);
  const { state, setState, onNext, onBack, goToNextStep, goToPreviousStep } =
    useInvoiceWizard();
  const theme = useMantineTheme();
  const hasBillPayV2FeatureFlag = useBillPayV2FeatureFlag();

  const [notEnoughFundsError, setNotEnoughFundsError] = useState<
    string | undefined
  >(undefined);
  const [notEnoughCreditError, setNotEnoughCreditError] = useState<
    string | undefined
  >(undefined);

  const { data: lines = [], isLoading: isLoadingLinesOfCredit } =
    useCreditLines(businessId);

  const { isActionDisabled = false, isInvoiceDraft, paymentInfo } = state || {};

  const isDisabled = !isInvoiceDraft || isActionDisabled;

  const initialSendFrom =
    (paymentInfo && paymentInfo.sendFrom) || paymentAccounts[0];
  const initialCreditProgramId =
    (paymentInfo && paymentInfo.creditProgramId) ||
    lines.find((cp) => cp.issuer === 'billpay')?.id;

  const form = useForm<PaymentInfo>({
    initialValues: {
      sendFrom: initialSendFrom,
      sendOn: paymentInfo?.sendOn ?? DateTime.now().toJSDate(),
      paymentSource: PaymentSource.ACCOUNT,
      creditProgramId: initialCreditProgramId,
    },
    validate: {
      sendFrom: (value) => (value ? null : 'Select a source account'),
      sendOn: (value) =>
        value instanceof Date && !isNaN(value.getTime())
          ? null
          : 'Select a valid date',
    },
  });

  useEffect(() => {
    if (initialSendFrom && !form.values.sendFrom) {
      form.setFieldValue('sendFrom', initialSendFrom);
    }
  }, [initialSendFrom, form.values]);

  const onAccountChange = (value: string) => {
    const account = paymentAccounts.find((acc) => acc.id === value);
    form.setFieldValue('sendFrom', account as PlaidAccount | DepositAccount);
    const invoiceTotalCents = state.invoiceTotal?.cents || 0;
    // if the payment source is LOC, we need to validate that the account has enough funds to cover the fees
    const creditProgram = lines.find(
      (cp) => cp.id === form.values.creditProgramId,
    );
    const totalFees =
      (Number(creditProgram?.fee) || 0) -
      (Number(creditProgram?.discount) || 0);
    // we need to convert the available balance from an external account to cents
    const availableAmount =
      (account?.plaidOrDeposit === 'plaid'
        ? currencyToCents(Number(account?.available))
        : account?.available) || 0;
    validateAccountHasEnoughFunds(
      form.values.paymentSource === PaymentSource.LOC
        ? Math.round(invoiceTotalCents * totalFees)
        : invoiceTotalCents,
      availableAmount,
    );
  };

  const onCreditProgramChange = (value: string) => {
    const creditProgram = lines.find((cp) => cp.id === value);
    form.setFieldValue('creditProgramId', creditProgram?.id);
  };

  const clearErrors = () => {
    setNotEnoughCreditError(undefined);
    setNotEnoughFundsError(undefined);
  };

  const onPaymentSourceChange = (value: string | null) => {
    form.setFieldValue('paymentSource', value as PaymentSource);
    if (value === PaymentSource.ACCOUNT) {
      form.setFieldValue('creditProgramId', undefined);
      clearErrors();
    }
    if (value === PaymentSource.LOC) {
      form.setFieldValue('creditProgramId', initialCreditProgramId);
      clearErrors();
    }
  };

  const validateAccountHasEnoughFunds = (
    invoiceTotal: number,
    availableFunds: number,
  ) => {
    if (availableFunds < invoiceTotal) {
      setNotEnoughFundsError('Selected account has insufficient funds.');
      return false;
    }
    setNotEnoughFundsError(undefined);
    return true;
  };

  const validateCreditProgramHasEnoughCreditAndFunds = (
    invoiceTotal: number,
    availableCredit: number,
    availableFunds: number,
    totalFees: number,
  ) => {
    if (availableCredit < invoiceTotal) {
      setNotEnoughCreditError('Insufficient credit');
      return false;
    }
    if (availableFunds < Math.round(invoiceTotal * totalFees)) {
      setNotEnoughFundsError(
        'Selected account has insufficient funds to cover the fee.',
      );
      return false;
    }
    setNotEnoughCreditError(undefined);
    setNotEnoughFundsError(undefined);
    return true;
  };

  onNext(() => {
    form.validate();
    const invoiceTotalCents = state.invoiceTotal?.cents || 0;
    const sendFrom = form.values.sendFrom;
    const availableFunds =
      (sendFrom?.plaidOrDeposit === 'plaid'
        ? currencyToCents(Number(sendFrom?.available))
        : sendFrom?.available) || 0;
    const creditProgram = lines.find(
      (cp) => cp.id === form.values.creditProgramId,
    );
    const availableCredit = Number(creditProgram?.available) || 0;
    const totalFees =
      (Number(creditProgram?.fee) || 0) -
      (Number(creditProgram?.discount) || 0);
    if (
      form.isValid() &&
      (form.values.paymentSource === PaymentSource.ACCOUNT
        ? validateAccountHasEnoughFunds(invoiceTotalCents, availableFunds)
        : validateCreditProgramHasEnoughCreditAndFunds(
            invoiceTotalCents,
            availableCredit,
            availableFunds,
            totalFees,
          ))
    ) {
      setState((prev) => ({
        ...prev,
        paymentInfo: form.values,
      }));
      goToNextStep();
    }
  });

  onBack(() => {
    setState((prev) => ({
      ...prev,
      paymentInfo: form.values,
    }));
    goToPreviousStep();
  });

  if (isLoadingPaymentAccounts || isLoadingLinesOfCredit) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <InvoiceWizard.Step hideBack hideNext>
      <Paper radius="xs" bg="neutral.0">
        <Box w="100%" p="xxl">
          <Text fw="500" size="sm">
            Recipient gets
          </Text>
          <Text fz={rem(40)} lh={1}>
            {state.invoiceTotal?.formatted}
          </Text>
          <Divider />
        </Box>
      </Paper>

      {hasBillPayV2FeatureFlag && (
        <Box mt="xxxl">
          <Text size="sm">Payment source</Text>
          <SegmentedControl
            fullWidth
            radius="md"
            styles={{
              root: {
                backgroundColor: theme.colors.neutral[0],
              },
              label: {
                color: theme.colors.neutral[6],
              },
              indicator: {
                backgroundColor: theme.colors.neutral[2],
                border: `1px solid ${theme.colors.neutral[3]}`,
              },
            }}
            {...form.getInputProps('paymentSource')}
            onChange={onPaymentSourceChange}
            data={PAYMENT_SOURCES}
            disabled={isDisabled}
          />
        </Box>
      )}

      <Box mt="xl">
        {form.values.paymentSource === PaymentSource.ACCOUNT && (
          <BankingAccountSource
            sendFrom={form.values.sendFrom}
            isActionDisabled={isActionDisabled}
            paymentAccounts={paymentAccounts}
            onAccountChange={onAccountChange}
            notEnoughFundsError={notEnoughFundsError}
          />
        )}
        {form.values.paymentSource === PaymentSource.LOC && (
          <LineOfCreditSource
            invoiceTotal={state.invoiceTotal?.cents || 0}
            sendFrom={form.values.sendFrom}
            isActionDisabled={isActionDisabled}
            paymentAccounts={paymentAccounts}
            onAccountChange={onAccountChange}
            creditPrograms={lines}
            creditProgramId={form.values.creditProgramId}
            onCreditProgramChange={onCreditProgramChange}
            notEnoughFundsError={notEnoughFundsError}
            notEnoughCreditError={notEnoughCreditError}
          />
        )}
      </Box>

      <DatePickerInput
        mt="xl"
        label="Send on"
        valueFormat="MMM DD, YYYY"
        minDate={MIN_PAYMENT_DATE}
        maxDate={MAX_PAYMENT_DATE}
        excludeDate={(date) => isWeekend(date) || isHoliday(date)}
        {...form.getInputProps('sendOn')}
        disabled={isActionDisabled}
      />
      {state.recipientAccount?.type && (
        <Text size="xs" color={'neutral.7'}>
          {sendOnDateLabel[state.recipientAccount?.type]}
        </Text>
      )}
    </InvoiceWizard.Step>
  );
};

AmountSourceStep.stepId = 'amount-source';

export default AmountSourceStep;
