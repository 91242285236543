import { SimpleMultiselectFilter } from '@common/utilities/filter/simple-multiselect.filter';
import { useCheckDepositsFilters } from './use-check-deposits-filters';

const STATUS_MULTISELECT_OPTIONS = [
  {
    label: 'Deposited',
    filterCriteria: ['Deposited'],
    key: 'deposited',
  },
  {
    label: 'Processing',
    filterCriteria: ['Processing'],
    key: 'processing',
  },
  {
    label: 'Awaiting Images',
    filterCriteria: ['Awaiting Images'],
    key: 'awaitingImages',
  },
  {
    label: 'Canceled',
    filterCriteria: ['Canceled'],
    key: 'canceled',
  },
  {
    label: 'Rejected',
    filterCriteria: ['Rejected'],
    key: 'rejected',
  },
  {
    label: 'Returned',
    filterCriteria: ['Returned'],
    key: 'returned',
  },
];

export const CheckDepositsStatusFilter = () => {
  const creditTransactionFilters = useCheckDepositsFilters();

  return (
    <SimpleMultiselectFilter
      label="Status"
      filterKey="status"
      options={STATUS_MULTISELECT_OPTIONS}
      filterHook={creditTransactionFilters}
      includesValue={(check) => check.status}
    />
  );
};
