import { DateTime, Interval } from 'luxon';

import { DateRangeFilter } from '@common/composites/date-range.filter';
import { useBillpayInvoicesModalFilters } from '../use-billpay-bills-filters';

export const InvoiceCreateAtFilter = () => {
  const invoiceCreateOnFilters = useBillpayInvoicesModalFilters();

  return (
    <DateRangeFilter
      label="Created on"
      filterHook={invoiceCreateOnFilters}
      filterFn={(startDate, endDate, item) => {
        const dateRange = Interval.fromDateTimes(startDate, endDate);
        return dateRange.contains(DateTime.fromSQL(item.createdAt));
      }}
    />
  );
};
