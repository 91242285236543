import { Box, Button, rem, Text } from '@mantine/core';
import { formatCurrency, getLocaleDate } from 'utilities/formatters';
import { useEffect, useMemo, useState } from 'react';
import { useStyles } from './banking-transactions.styles';
import BankingTransactionsHeader from './banking-transactions-header/banking-transactions-header';
import FlexbaseTable from 'components/table/flexbase-table';
import { formatDepositAccountDetails } from 'utilities/formatters/format-strings';
import { downloadCSV } from '@utilities/csv-export';
import { parseTransactionDisplayValueByType } from 'utilities/formatters/banking-transactions-utilities';
import { useParams } from 'react-router-dom';
import { Transaction } from 'services/flexbase/banking.model';
import { useMediaQuery } from '@mantine/hooks';
import useModal from '@common/composites/modal/modal-hook';
import TransactionDetails from './transaction-details/transaction-details';
import { useGetBankingTransactions } from 'queries/use-banking-transactions';
import { useGetDepositAccounts } from 'queries/use-deposit-accounts';
import { sortDate } from '@utilities/dates';
import { useBankingTransactionsFilters } from './use-banking-transactions-filters';
import { TableColumn } from 'react-data-table-component';
import { TagDelta } from 'areas/banking/delta';
import CheckDepositDetails from '../check-deposits/components/check-deposit-details-modal/check-deposit-details';
import { OverflowTooltip } from '@common/utilities/overflow-tooltip';

const paymentDirection = (direction: string, displaySign?: boolean) => {
  if (displaySign) {
    return direction.toLocaleLowerCase() === 'debit' ? '-' : '+';
  }
  return direction.toLocaleLowerCase() === 'debit' ? '-' : '';
};

const checkDirection = (direction: string) => {
  if (direction === 'Credit') {
    return 'increase';
  }
  return 'decrease';
};

const getTransactionDirection = (direction: string, type: string) => {
  switch (type) {
    case 'Payment canceled':
      return '';
    default:
      return paymentDirection(direction, true);
  }
};

const selectorAmountColumn = (row: Transaction) => {
  const isNegativeTransaction =
    getTransactionDirection(row.direction, row.type) === '-';
  const parsedAmount = isNegativeTransaction ? `-${row.amount}` : row.amount;
  return Number(parsedAmount);
};

const getToFromName = (transaction: Transaction) =>
  transaction.whoFirstName
    ? [transaction.whoFirstName, transaction.whoLastName]
        .filter(Boolean)
        .join(' ')
    : transaction.accountName;

const BankingTransactions = () => {
  const modal = useModal();
  const { classes } = useStyles();
  const { id: accountId, recipientId } = useParams();
  const bankingAccounts = useGetDepositAccounts();
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [selectedRows, setSelectedRows] = useState<Transaction[]>([]);

  const { activeFiltersArray, getFilterByKey, removeAllFilters } =
    useBankingTransactionsFilters();
  const currentDateFilter = getFilterByKey('date')?.filterValue;
  const toDate = currentDateFilter?.endDate?.toISODate();
  const fromDate = currentDateFilter?.startDate?.toISODate();

  const params = {
    ...(accountId && { depositAccountId: accountId }),
    ...(recipientId && { recipientId }),
    ...(fromDate && toDate && { fromDate, toDate }),
  };

  const { data, isLoading, isError, refetch } =
    useGetBankingTransactions(params);

  const transactions = data?.transactions ?? [];
  const depositAccounts = bankingAccounts.data?.accounts || [];

  const columns: TableColumn<Transaction>[] = [
    {
      name: 'Created Date',
      selector: (row) => row.createdAt,
      format: (row) => getLocaleDate(row.createdAt, true),
      sortable: true,
      sortFunction: sortDate,
      id: 'date',
    },
    {
      name: 'Type',
      selector: (row) => row.type,
      sortable: true,
      compact: true,
      grow: 1.5,
    },
    {
      name: 'Summary',
      selector: (row) => row.summary,
      sortable: true,
      compact: true,
      grow: 2,
    },
    {
      name: 'Amount',
      cell: (row) => (
        <TagDelta
          tone={
            checkDirection(row.direction) === 'increase'
              ? 'positive'
              : 'critical'
          }
          change={checkDirection(row.direction)}
        >{`${formatCurrency(row.amount)}`}</TagDelta>
      ),
      selector: selectorAmountColumn,
      sortable: true,
      compact: true,
    },
    {
      name: 'Account',
      cell: (row) => <OverflowTooltip text={row.depositAccount} />,
      selector: (row) => row.depositAccount,
      sortable: true,
      grow: 1.5,
    },
  ];

  const columnsSm: TableColumn<Transaction>[] = [
    {
      sortable: true,
      name: 'Summary',
      cell: (row) => (
        <Box>
          <Text fw={500}>{getToFromName(row)}</Text>
          <Text fw={500} size="sm">
            {row.type}
          </Text>
          <Text size="sm">{getLocaleDate(row.createdAt, true)}</Text>
        </Box>
      ),
      selector: (row) => row.createdAt,
    },
    {
      name: 'Amount',
      cell: (row) => (
        <Text className={classes.amount}>
          {`${getTransactionDirection(row.direction, row.type)}${formatCurrency(
            row.amount,
          )}`}
        </Text>
      ),
      selector: selectorAmountColumn,
      sortable: true,
      compact: true,
      right: true,
      style: {
        padding: `0px ${rem(16)}`,
      },
    },
  ];

  const filteredTransactions: Transaction[] = useMemo(() => {
    const mappedTransactions = transactions.map((transaction) => {
      const parsedPaymentType = parseTransactionDisplayValueByType(
        transaction?.paymentType === 'internationalWire'
          ? 'outgoingACHInternationalWire'
          : transaction.type,
        transaction.direction,
      );

      return {
        ...transaction,
        type: parsedPaymentType,
        amount: (Number(transaction.amount) / 100).toString(), // 'amount' in 'Transaction' type is string by default
        depositAccount: formatDepositAccountDetails(
          depositAccounts,
          transaction.depositAccountId,
        ),
      };
    });

    const filtered = mappedTransactions.filter((t) =>
      activeFiltersArray.every((f) => f.fn(t)),
    );

    const sortedTransactions = filtered.sort((a, b) => sortDate(a, b, false));

    return sortedTransactions;
  }, [transactions, activeFiltersArray, bankingAccounts]);

  /**
   * Prep/format data to be exported
   */

  const selectedData =
    selectedRows.length > 0 ? selectedRows : filteredTransactions;

  const csvData = selectedData.map((item) => {
    const date = getLocaleDate(item.createdAt, true);
    return {
      Date: date,
      Name: getToFromName(item),
      Amount: `${paymentDirection(item.direction, true)}${formatCurrency(
        item.amount,
      )}`,
      Account: item.depositAccount,
      Type: item.type,
    };
  });

  const onRowClicked = (row: Transaction) => {
    const checkDepositId = row.checkDepositId;
    const isCheckTransaction = [
      'Check deposit',
      'Check payment',
      'Returned check deposit',
    ].includes(row.type);
    if (isCheckTransaction && checkDepositId) {
      modal.openRightModal(
        <CheckDepositDetails
          id={checkDepositId}
          account={formatDepositAccountDetails(
            depositAccounts,
            row.depositAccountId,
          )}
        />,
      );
    } else {
      modal.openRightModal(<TransactionDetails transaction={row} />);
    }
  };

  // remove all the filters when we're on 'Account details' page
  useEffect(() => {
    if (accountId) {
      removeAllFilters();
    }
  }, [accountId]);

  return (
    <div className={classes.baseContainer}>
      <div className={classes.widgetContainer}>
        <BankingTransactionsHeader
          downloadCSV={() =>
            downloadCSV({ data: csvData, fileName: 'Banking Transactions' })
          }
          transactions={filteredTransactions || []}
          accountId={accountId}
        />
        {isError ? (
          <div className={classes.errorMessage}>
            <p>An error occurred getting transaction data</p>
            <Button onClick={() => refetch()}>Retry</Button>
          </div>
        ) : (
          <FlexbaseTable
            data={filteredTransactions}
            columns={useMobileView ? columnsSm : columns}
            onSelectedRowsChange={({ selectedRows: selected }) =>
              setSelectedRows(selected)
            }
            isFetchingData={isLoading}
            defaultSortFieldId={'createdAt'}
            onRowClicked={onRowClicked}
            selectableRows={true}
            noDataComponent={
              <Text fz={rem(24)} fw={500} mt="lg">
                No transactions yet
              </Text>
            }
          />
        )}
      </div>
    </div>
  );
};
export default BankingTransactions;
