import { flexbaseBankingClient } from '../flexbase-client';
import { analytics } from '../../app';
import { AnalyticsEvent } from './events';

const env_check = true;

export type Dict = Record<string, any>;
const isBlackList = (key: string) => {
  const blacklistInputs = [
    'ssn',
    'taxId',
    'financialInstitutions',
    'externalAccount',
    'birthDate',
    'ein',
    'password',
    'plaidProcessorToken',
    'routingNumber',
    'accountNumber',
  ];
  return blacklistInputs.includes(key);
};

const properCasing = (key: string) => {
  const result = key.replace(/([A-Z])/g, ' $1');
  const titleCaseKey = result.charAt(0).toUpperCase() + result.slice(1);
  return titleCaseKey;
};

const cleanInputs = (props: Dict) => {
  const reservedProperties = [
    'firstName',
    'lastName',
    'email',
    'city',
    'email',
    'avatar',
  ];

  for (const [key, value] of Object.entries(props)) {
    if (isBlackList(key) || reservedProperties.includes(key)) {
      delete props[key];
    } else {
      const titleCaseKey = properCasing(key);
      props[titleCaseKey] = value;
      delete props[key];
    }
  }
  return props;
};

const cleanInputsArray = (props: Dict[]) => {
  const cleanedResponse: Dict[] = [];
  props.forEach((prop) => {
    cleanedResponse.push(cleanInputs(prop));
  });
  return cleanedResponse;
};

const actions = {
  identify: async (id: string) => {
    if (env_check) {
      await analytics.identify(id);
      await flexbaseBankingClient.marketingSegment();
    }
  },
  /**
   *
   * @param from old id associated with the user
   * @param to new id to be associated with the user
   */
  alias: async (from: string, to?: string) => {
    // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#alias
    if (env_check) {
      // to/from
      const user = await analytics.user();
      if (to) {
        await analytics.alias(to, from);
      } else {
        await analytics.alias(user.anonymousId()!.toString(), from);
      }
    }
  },
  track: (name: AnalyticsEvent, props?: Dict) => {
    if (env_check) {
      if (props) {
        cleanInputs(props);
      }
      analytics.track(name, props);
    }
  },
  pageView: (name: string, props?: Dict) => {
    if (env_check) {
      if (props) {
        cleanInputs(props);
      }
      analytics.page(name, props);
    }
  },
  group: {
    set: (userId: string, props: Dict) => {
      if (env_check) {
        if (props) {
          cleanInputs(props);
        }
        analytics.group(userId, props);
      }
    },
  },
  people: {
    set: (userId: string, props: Dict | Dict[]) => {
      if (env_check) {
        if (Array.isArray(props)) {
          props = cleanInputsArray(props);
        } else {
          props = cleanInputs(props);
        }
        analytics.identify(userId, props);
        flexbaseBankingClient.marketingSegment();
      }
    },
  },
  checkSetReservedProperty: {
    set: (props: Dict) => {
      if (env_check) {
        // dictionary of all special proeprty mappings
        const reservedProperties = {
          firstName: 'first_name',
          lastName: 'last_name',
          phone: 'phone',
          city: 'city',
          email: 'email',
          avatar: 'avatar',
        } as Dict;

        const cleanedProps: Dict = {};
        // loop through properties, see if one matches
        // if match track with reserved keyword
        for (const key in props) {
          if (reservedProperties[key] !== undefined) {
            // use new key name
            const keyName = reservedProperties[key];
            cleanedProps[keyName] = props[key];
            // not a reserved word, append to existing and clean property
          } else if (!isBlackList(key)) {
            cleanedProps[properCasing(key)] = props[key];
          }
        }

        if (props.firstName && props.lastName) {
          cleanedProps['$name'] = `${props.firstName} ${props.lastName}`;
        }
        analytics.identify(cleanedProps);
      }
    },
  },
  submitProductStart: {
    set: async (userId: string, product: string) => {
      const datetime = new Date().toISOString();

      switch (product) {
        case 'BANKING':
          await analytics.identify(userId, {
            startedBankingApplication: datetime,
          });
          await flexbaseBankingClient.marketingSegment();
          break;
        case 'CREDIT':
          await analytics.identify(userId, {
            startedCreditApplication: datetime,
          });
          await flexbaseBankingClient.marketingSegment();
          break;
        case 'ALL':
          await analytics.identify(userId, {
            startedBankingApplication: datetime,
            startedCreditApplication: datetime,
            startedTreasuryApplication: datetime,
          });
          await flexbaseBankingClient.marketingSegment();
          break;
        case 'TREASURY':
          await analytics.identify(userId, {
            startedTreasuryApplication: datetime,
          });
          await flexbaseBankingClient.marketingSegment();
          break;
      }
    },
  },
};

export const Analytics = actions;
