import { InvoiceWizard, useInvoiceWizard } from '../invoice-wizard';
import { Group, rem, Stack, Text, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import FlexbaseInput from '@common/composites/input/flexbase-input';
import { useUpdateBillpayInvoice } from '@queries/use-bill-pay';

type AdditionalInformationForm = {
  invoiceNumber?: string;
  memo: string;
  description?: string;
  notes?: string;
};

const AdditionalInformationStep = () => {
  const { state, setState, onEvent, onNext, goToNextStep } = useInvoiceWizard();
  const { isInvoiceDraft, isActionDisabled, existingInvoiceId } = state;
  const { mutate: updateBillpayInvoice } = useUpdateBillpayInvoice();

  const form = useForm<AdditionalInformationForm>({
    initialValues: {
      invoiceNumber: state.invoiceNumber,
      description: state.description,
      memo: state.memo || '',
      notes: state.notes,
    },
    validate: {
      memo: (value) => {
        if (!isActionDisabled) {
          return value ? null : 'Memo is required';
        }
      },
    },
  });

  onEvent('onSaveEdits', () => {
    setState((prevState) => ({
      ...prevState,
      invoiceNumber: form.values.invoiceNumber,
      memo: form.values.memo,
      description: form.values.description,
      notes: form.values.notes,
    }));
    return {
      invoiceNumber: form.values.invoiceNumber,
      memo: form.values.memo,
      description: form.values.description,
      notes: form.values.notes,
    };
  });

  onNext(() => {
    form.validate();

    if (!form.isValid()) {
      return false;
    }

    updateBillpayInvoice(
      {
        id: existingInvoiceId!,
        invoiceNumber: form.values.invoiceNumber,
        description: form.values.description,
      },
      {
        onSuccess: () => {
          setState({
            invoiceNumber: form.values.invoiceNumber,
            memo: form.values.memo,
            description: form.values.description,
            notes: form.values.notes,
          });
          goToNextStep();
        },
        onError: () => {
          setState({
            error: {
              message:
                'An unexpected error occurred while saving the invoice. Please try again.',
              errorType: 'update',
            },
          });
        },
      },
    );
  });

  const textareaStyles = {
    input: {
      height: rem(90),
    },
  };

  return (
    <InvoiceWizard.Step hideBack hideNext>
      <Stack gap="xxs" mb={rem(24)}>
        <Textarea
          styles={textareaStyles}
          label={<TextInputLabel label="External memo (max 10 characters)" />}
          placeholder="Memo"
          maxLength={10}
          {...form.getInputProps('memo')}
          disabled={isActionDisabled}
        />
        <Text fz="xs" c="neutral.6">
          Sent to recipient&apos;s bank
        </Text>
      </Stack>
      <Stack gap={rem(24)}>
        <FlexbaseInput
          disabled={!isInvoiceDraft || isActionDisabled}
          label={<TextInputLabel label="Invoice number" optional />}
          placeholder="Invoice number"
          autoFocus
          maxLength={50}
          {...form.getInputProps('invoiceNumber')}
        />
        <Textarea
          styles={textareaStyles}
          label={<TextInputLabel label="Invoice description" optional />}
          placeholder="Invoice description"
          {...form.getInputProps('description')}
          disabled={!isInvoiceDraft}
        ></Textarea>
        <Stack gap="xxs">
          <Textarea
            styles={textareaStyles}
            label={<TextInputLabel label="Internal notes" optional />}
            placeholder="Internal notes"
            {...form.getInputProps('notes')}
            disabled={!isInvoiceDraft}
          ></Textarea>
          <Text size="xs" c="neutral.6">
            Not visible to the recipient
          </Text>
        </Stack>
      </Stack>
    </InvoiceWizard.Step>
  );
};

const TextInputLabel = ({
  label,
  optional,
}: {
  label: string;
  optional?: boolean;
}) => {
  return (
    <Group gap="xxs">
      <Text>{label}</Text>
      {optional && <Text c="neutral.6">(optional)</Text>}
    </Group>
  );
};

AdditionalInformationStep.stepId = 'additional-information';

export default AdditionalInformationStep;
