import { useStyles } from './credit-transactions-header.styles';
import {
  Button,
  Group,
  Stack,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { SearchIcon, DownloadIcon } from 'assets/svg';
import { ChangeEventHandler, MouseEventHandler } from 'react';

type Props = {
  filterText: string;
  onFilter: ChangeEventHandler<HTMLInputElement>;
  onDownloadCSVClick?: MouseEventHandler<HTMLButtonElement>;
};

const ACTION_ICON_SIZE = 16;

const CreditTransactionHeaderSm = ({
  filterText,
  onFilter,
  onDownloadCSVClick,
}: Props) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();

  const searchIcon = <SearchIcon height={20} width={20} />;
  const downloadIcon = (
    <DownloadIcon height={ACTION_ICON_SIZE} width={ACTION_ICON_SIZE} />
  );

  return (
    <Stack gap={theme.spacing.xs}>
      <TextInput
        leftSection={searchIcon}
        placeholder={'Search transactions...'}
        value={filterText}
        onChange={onFilter}
      />

      <Group gap={theme.spacing.xs}>
        <Button
          variant="outline"
          className={classes.actionBtn}
          leftSection={downloadIcon}
          onClick={onDownloadCSVClick}
        >
          CSV
        </Button>
      </Group>
    </Stack>
  );
};

export default CreditTransactionHeaderSm;
