import { useEffect, useState } from 'react';
import { ComboboxItem, MultiSelect, rem } from '@mantine/core';

import { Group } from 'constants/card-info';
import { oldCategories, newCategories } from 'constants/mcc-restrictions';
import { createStyles } from '@mantine/emotion';

const detailsCardStyles = createStyles((theme, _, helpers) => ({
  cardDetails: {
    backgroundColor: theme.colors.neutral[2],
    minHeight: '100vh',
    color: theme.colors.primarySecondarySuccess[8],
  },
  contentContainer: {
    padding: `${theme.spacing.xl} ${rem(32)}`,
    [helpers.smallerThan('sm')]: {
      overflowX: 'hidden',
      padding: theme.spacing.sm,
    },
  },
  endContent: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  whiteOutlineButton: {
    backgroundColor: theme.colors.neutral[0],
    '&:not([data-disabled])': {
      '&:hover': {
        backgroundColor: theme.colors.neutral[0],
      },
    },
  },
  last4Input: {
    fontSize: rem(48),
    color: theme.colors.primarySecondarySuccess[8],
    textAlign: 'center',
    height: 'fit-content',
    borderBottom: `3px solid ${theme.primaryColor}`,
    '&:focus': {
      borderBottom: `3px solid ${theme.primaryColor}`,
    },
    [helpers.smallerThan('sm')]: {
      fontSize: rem(48),
    },
  },
  multiselect: {
    span: {
      fontSize: '12px',
    },
  },
}));

type ItemProps = {
  form: any;
  disabled?: boolean;
  filter?: (items: ComboboxItem[]) => ComboboxItem[];
};

const Multiselect = ({ form, disabled, filter }: ItemProps) => {
  const { classes } = detailsCardStyles();
  const [isUnrestricted, setIsUnrestricted] = useState(
    form.values.groups.length === 0,
  );

  const itemsSelected = `${
    form.values?.groups.includes('UNRESTRICTED')
      ? '0'
      : form.values.groups.length
  } categories selected`;

  useEffect(() => {
    if (isUnrestricted) {
      form.setFieldValue('groups', []);
    }
  }, [isUnrestricted]);

  const hasOldCategory = form.values.groups.some((group: string) => {
    return oldCategories.some((category) => category.value === group);
  });

  let data = hasOldCategory
    ? oldCategories.concat(newCategories)
    : newCategories;

  if (filter) {
    data = filter(data);
  }

  return (
    <div style={{ position: 'relative' }}>
      <MultiSelect
        label="Allowed spending categories"
        placeholder={itemsSelected}
        data={data}
        value={form.values?.groups}
        onChange={(value) => {
          setIsUnrestricted(false);
          form.setFieldValue('groups', value as Group[]);
        }}
        className={classes.multiselect}
        styles={{ input: { padding: 3, paddingLeft: 12 } }}
        searchable
        disabled={disabled}
      />
    </div>
  );
};

export default Multiselect;
