import { Select, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  paymentPurposeCodesByCountry,
  requiresPaymentPurposeCodes,
} from './util';
import { AvailableCurrencies, PaymentPurposeFormValues } from './util/types';
import FlexbaseSelect from '@common/composites/flexbase-select';

export const formLabelsAndErrors = {
  purposeDescription: {
    label: 'Describe the purpose of this wire transfer',
    error: 'Provide a description',
  },
  purposeType: {
    label: 'Choose payment purpose type',
    error: 'Select a payment purpose option',
  },
  sourceOfFunds: {
    label: 'Source of funds',
    error: 'Select a source of funds',
  },
};

type LabelValue = {
  label: string;
  value: string;
};

const sortAlphabetically = function (a: LabelValue, b: LabelValue) {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
};

export const SOURCE_OPTIONS = [
  'Salary',
  'Investments',
  'Inheritance',
  'Loan',
  'Other',
];

const getPaymentPurposeFormValues = (
  country: string,
  currency: AvailableCurrencies,
  existingFormValues?: PaymentPurposeFormValues,
) => {
  const formValues: {
    initialValues: PaymentPurposeFormValues;
    validate: Record<string, (value: string) => string | null>;
  } = {
    initialValues: {
      purpose: '',
      source: '',
    },

    validate: {
      purpose: (value: string) =>
        value.length === 0
          ? formLabelsAndErrors.purposeDescription.error
          : null,
      source: (value: string) =>
        value.length === 0 ? formLabelsAndErrors.sourceOfFunds.error : null,
    },
  };

  if (requiresPaymentPurposeCodes(country, currency)) {
    formValues.initialValues.code = '';
    formValues.validate.code = (value: string) =>
      value.length === 0 ? formLabelsAndErrors.purposeType.error : null;
  }

  if (existingFormValues) {
    formValues.initialValues = existingFormValues;
  }

  return formValues;
};

type Props = {
  currency: AvailableCurrencies;
  country: string;
  onSubmit?: (data: PaymentPurposeFormValues) => void;
  existingFormValues?: PaymentPurposeFormValues;
};

const PaymentPurposeForm = ({
  currency,
  country,
  onSubmit,
  existingFormValues,
}: Props) => {
  const form = useForm(
    getPaymentPurposeFormValues(country, currency, existingFormValues),
  );

  const needsPaymentPurposeCodes = requiresPaymentPurposeCodes(
    country,
    currency,
  );

  const handleSubmit = (data: PaymentPurposeFormValues) => {
    if (onSubmit) {
      onSubmit(data);
    }
  };

  return (
    <form
      onSubmit={form.onSubmit(handleSubmit)}
      id="paymentPurposeForm"
      aria-label="form"
    >
      <Textarea
        label={formLabelsAndErrors.purposeDescription.label}
        minLength={1}
        maxLength={255}
        autosize
        minRows={5}
        placeholder="Enter your description here"
        {...form.getInputProps('purpose')}
        data-testid="payment-purpose"
      />
      <FlexbaseSelect
        inputProps={{ my: 'md', id: 'source-funds' }}
        data={SOURCE_OPTIONS.map((s) => ({ label: s, value: s }))}
        label={formLabelsAndErrors.sourceOfFunds.label}
        placeholder="Select an option"
        {...form.getInputProps('source')}
        data-testid="source-funds"
        id="source-funds"
      />
      {needsPaymentPurposeCodes && (
        <Select
          label={formLabelsAndErrors.purposeType.label}
          my="md"
          data={paymentPurposeCodesByCountry[country].sort(sortAlphabetically)}
          placeholder="Select an option"
          searchable
          maxDropdownHeight={400}
          {...form.getInputProps('code')}
          data-testid="payment-purpose-code"
        />
      )}
    </form>
  );
};

export default PaymentPurposeForm;
