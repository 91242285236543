import { MultiSelect } from '@mantine/core';
import { uniq } from 'underscore';
import { usePaymentModalFilters } from '../use-payment-filters';
import { TablePayment } from '@utilities/payments-rows';

export const PaymentAccountNameFilter = ({
  paymentsData,
}: {
  paymentsData: TablePayment[];
}) => {
  const accountNames = uniq(
    paymentsData
      .filter((t) => t.accountName)
      .map((transaction) => transaction.accountName),
  );

  const handleChange = (val: string[]) => {
    if (val.length > 0) {
      addFilter('accountName', {
        key: 'accountName',
        filterValue: val,
        fn: (row) => val.includes(row.accountName),
        label: `Account name: ${val.join(', ')}`,
        showChip: true,
      });
    } else {
      removeFilter('accountName');
    }
  };

  const { addFilter, removeFilter, getFilterByKey } = usePaymentModalFilters();

  return (
    <MultiSelect
      searchable
      data={accountNames}
      onChange={handleChange}
      placeholder="Account name"
      nothingFoundMessage="Nothing found"
      value={getFilterByKey('accountName')?.filterValue || []}
    />
  );
};
