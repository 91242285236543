import FlexNumberInput from '@common/flex-number-input';
import { useForm } from '@mantine/form';
import { formatCurrency } from '@utilities/formatters';
import { RequiredFieldValidator } from '@utilities/validators';
import { useEffect, useState } from 'react';
import { useInvoiceWizard } from '../../invoice-wizard';
import { useParams } from 'react-router-dom';
import {
  useCreateBillpayInvoice,
  useUpdateBillpayInvoice,
} from '@queries/use-bill-pay';
import WizardErrorBanner from 'areas/billpay/wizard-error-banner';
import { DateInput } from '@mantine/dates';
import { PiCalendarLight } from 'react-icons/pi';
import { Stack } from '@mantine/core';

const ConfirmTotal = () => {
  const { state, setState, onNext, goToNextStep } = useInvoiceWizard();
  const [showWireAmountError, setShowWireAmountError] = useState(false);
  const { id: existingInvoiceId } = useParams();
  const form = useForm({
    initialValues: {
      total: state.invoiceTotal?.cents ? state.invoiceTotal?.cents / 100 : 0,
      dueDate: state.invoiceDetails?.dueDate,
    },
    validate: {
      total: RequiredFieldValidator('Total is required'),
      dueDate: RequiredFieldValidator('Due date is required'),
    },
  });

  const { mutate: updateBillpayInvoice, error: updateBillpayInvoiceError } =
    useUpdateBillpayInvoice();
  const { mutate: createBillpayInvoice, error: createBillpayInvoiceError } =
    useCreateBillpayInvoice();

  const wireAmountError = showWireAmountError
    ? 'To send a wire to this recipient, this payment must meet the minimum wire amount of $50.00.'
    : undefined;
  const invoiceCreationError =
    'There was an error saving the invoice, please try again.';
  const { isInvoiceDraft, isActionDisabled } = state;
  const isDisabledDueDate = !isInvoiceDraft || isActionDisabled;
  const showWizardErrorBanner =
    createBillpayInvoiceError || updateBillpayInvoiceError || wireAmountError;

  onNext(async () => {
    form.validate();

    const invoiceTotalError =
      state.recipientAccount?.type === 'wire' &&
      state.invoiceTotal?.cents &&
      state.invoiceTotal.cents < 5000;

    if (!form.isDirty() && !!existingInvoiceId) {
      if (invoiceTotalError) {
        setShowWireAmountError(true);
        return;
      }
      goToNextStep();
      return;
    }

    if (form.isValid() && form.values.dueDate && state.existingDocumentId) {
      if (invoiceTotalError) {
        setShowWireAmountError(true);
        return;
      }

      if (existingInvoiceId) {
        updateBillpayInvoice(
          {
            id: existingInvoiceId,
            recipientId: state.recipient?.id,
            dueDate: form.values?.dueDate,
            documentId: state.existingDocumentId,
            total: state.invoiceTotal?.cents,
          },
          {
            onSuccess: () => {
              goToNextStep();
            },
          },
        );
      } else {
        createBillpayInvoice(
          {
            recipientId: state.recipient?.id,
            dueDate: form.values.dueDate,
            total: form.values.total * 100,
            documentId: state.existingDocumentId,
          },
          {
            onSuccess: (invoiceResponse) => {
              setState({
                invoiceTotal: {
                  cents: form.values.total * 100,
                  formatted: formatCurrency(form.values.total),
                },
                existingInvoiceId: invoiceResponse.invoice.id,
                invoiceDetails: {
                  dueDate: form.values.dueDate,
                  lineItems: [],
                },
              });
              goToNextStep();
            },
          },
        );
      }
    }
  });

  useEffect(() => {
    setState({
      invoiceTotal: {
        cents: form.values.total * 100,
        formatted: formatCurrency(form.values.total),
      },
    });
  }, [form.values.total]);

  return (
    <Stack>
      <FlexNumberInput
        label="Invoice total"
        thousandSeparator
        decimalScale={2}
        fixedDecimalScale
        allowNegative={false}
        leftSection="$"
        placeholder="0.00"
        variant="unstyled"
        {...form.getInputProps('total')}
        onChange={() => null}
        onValueChange={(value) => {
          form.setFieldValue('total', value.floatValue ?? 0);
        }}
      />
      <DateInput
        disabled={isDisabledDueDate}
        label="Due Date"
        leftSection={<PiCalendarLight size={'1.25rem'} />}
        placeholder="Select due date"
        {...form.getInputProps('dueDate')}
        value={form.values.dueDate ? new Date(form.values.dueDate) : null}
        onChange={(date) => form.setFieldValue('dueDate', date?.toISOString())}
      />

      {showWizardErrorBanner && (
        <WizardErrorBanner message={wireAmountError || invoiceCreationError} />
      )}
    </Stack>
  );
};

export default ConfirmTotal;
