import useModal from '@common/composites/modal/modal-hook';
import TitleBar from '@common/composites/details-page/titlebar/title-bar';
import { Button, Menu, useMantineTheme } from '@mantine/core';
import { useUpdateDepositAccountNickname } from '@queries/use-deposit-account';
import { useCloseDepositAccount } from '@queries/use-deposit-accounts';
import { useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { PiPencilSimpleLine, PiTrash } from 'react-icons/pi';

type MenuItemsProps = {
  onCloseAccount: () => void;
  isClosedAccount: boolean;
  onShowForm: () => void;
};

const MenuItems = ({
  onCloseAccount,
  isClosedAccount,
  onShowForm,
}: MenuItemsProps) => {
  const theme = useMantineTheme();
  const { openConfirmationModal } = useModal();

  const handleCloseAccount = () => {
    openConfirmationModal({
      title: `Close account`,
      confirmText: 'Close account',
      cancelText: 'Cancel',
      content: `Are you sure you want to close this account?`,
      onConfirm: onCloseAccount,
      closeOnClickOutside: true,
    });
  };

  return (
    <>
      <Menu.Item
        leftSection={<PiPencilSimpleLine color={theme.colors.neutral[5]} />}
        onClick={onShowForm}
      >
        Edit account name
      </Menu.Item>
      <Menu.Item
        color={theme.colors.critical[6]}
        leftSection={<PiTrash />}
        onClick={handleCloseAccount}
        disabled={isClosedAccount}
        aria-label="close account"
      >
        Close account
      </Menu.Item>
    </>
  );
};

const useShowForm = () => {
  const [isShowingForm, setIsShowingForm] = useState(false);

  const onShowForm = () => {
    setIsShowingForm(true);
  };

  const onHideForm = () => {
    setIsShowingForm(false);
  };

  return { isShowingForm, onShowForm, onHideForm };
};

type Props = {
  companyId: string;
  depositId: string;
  isClosedAccount: boolean;
  title: string;
};

const AccountDetailsTitleBar = ({
  companyId,
  depositId,
  isClosedAccount,
  title,
}: Props) => {
  const navigate = useNavigate();
  const { mutate: closeAccount } = useCloseDepositAccount();
  const { isShowingForm, onShowForm, onHideForm } = useShowForm();
  const { mutate: updateDepositNickname, isPending: isUpdatingNickname } =
    useUpdateDepositAccountNickname();

  const handlePrimaryButtonClick = () => {
    navigate({
      pathname: `/banking/move-funds`,
      search: createSearchParams({
        accountId: depositId,
      }).toString(),
    });
  };

  const onCloseAccount = () => {
    closeAccount(
      {
        companyId,
        depositId,
        status: 'closed',
      },
      {
        onSuccess: () => {
          navigate('/banking/accounts');
        },
      },
    );
  };

  const onSubmit = (nickname: string) => {
    updateDepositNickname(
      {
        accountId: depositId,
        nickname,
      },
      {
        onSuccess: () => {
          onHideForm();
        },
      },
    );
  };

  return (
    <TitleBar
      currentTitle={title}
      primaryButton={
        <Button onClick={handlePrimaryButtonClick} leftSection="+">
          Add Funds
        </Button>
      }
      menuItems={
        <MenuItems
          onCloseAccount={onCloseAccount}
          isClosedAccount={isClosedAccount}
          onShowForm={onShowForm}
        />
      }
      isShowingForm={isShowingForm}
      onHideForm={onHideForm}
      onSubmit={onSubmit}
      isSubmitting={isUpdatingNickname}
    />
  );
};

export default AccountDetailsTitleBar;
