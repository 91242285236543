import { useNet60CreditCards } from '@queries/use-credit-cards';
import {
  useCreditCardsFilters,
  useCreditCardsModalFilters,
} from '@common/utilities/filter/cards-filter/use-cards-filters';
import { CreditCard } from '@services/flexbase/card.model';
import CardDetails from '@common/card-details';
import { CardsTable } from '@common/charge-and-credit-cards/cards-table';
import { CardsTableHeader } from '@common/composites/header/cards-table-header/cards-table-header';
import { CardTableRow } from '@common/utilities/filter/cards-filter/card-table-row.model';
import { CreditCardStatusFilter } from '@common/utilities/filter/cards-filter/filters/card-status.filter';
import { CreditCardTypeFilter } from '@common/utilities/filter/cards-filter/filters/card-type.filter';
import { CreditCardsCardholderFilter } from '@common/utilities/filter/cards-filter/filters/card-holder.filter';
import { CreditCardNumberFilter } from '@common/utilities/filter/cards-filter/filters/card-number.filter';
import { CreditCardNameFilter } from '@common/utilities/filter/cards-filter/filters/card-name.filter';
import { CreditCardsAmountSpentFilter } from '@common/utilities/filter/cards-filter/filters/card-spent-amounts.filter';
import { CreditCardsDateRangeFilter } from '@common/utilities/filter/cards-filter/filters/cards-date-range.filter';
import useModal from '@common/composites/modal/modal-hook';
import IssueCard from '@common/issue-card-header';
import { useRecoilValue } from 'recoil';
import { IsAdmin } from 'recoil-state/application/product-onboarding';

export const Net60CreditCardsTable = () => {
  const isAdmin = useRecoilValue(IsAdmin);
  const filterHook = useCreditCardsFilters();
  const { openFullModal, closeAllModals } = useModal();
  const modalFilterHook = useCreditCardsModalFilters();
  const searchTerm = filterHook.getFilterByKey('search')?.filterValue;

  const dataQuery = useNet60CreditCards({
    full: true,
    searchTerm,
  });

  const handleIssueCard = () => {
    openFullModal(<IssueCard closeModal={closeAllModals} />);
  };

  const getTableHeader = (cards: CreditCard[]) => {
    const cardsData = cards as CardTableRow[];

    const filters = [
      {
        key: 'status',
        header: 'Status',
        component: <CreditCardStatusFilter />,
      },
      {
        key: 'type',
        header: 'Type',
        component: <CreditCardTypeFilter />,
      },
      {
        key: 'cardholder',
        header: 'Cardholder',
        component: <CreditCardsCardholderFilter cards={cardsData} />,
      },
      {
        key: 'number',
        header: 'Number',
        component: <CreditCardNumberFilter cards={cardsData} />,
      },
      {
        key: 'name',
        header: 'Card name',
        component: <CreditCardNameFilter cards={cardsData} />,
      },
      {
        key: 'spent',
        header: 'Monthly usage',
        component: <CreditCardsAmountSpentFilter />,
      },
      {
        key: 'date',
        header: 'Last modified',
        component: <CreditCardsDateRangeFilter />,
      },
    ];

    return (
      <CardsTableHeader
        filters={filters}
        filterHook={filterHook}
        userCanIssueCards={isAdmin}
        onIssueCard={handleIssueCard}
        issueCardLabel="New credit card"
        modalFilterHook={modalFilterHook}
      />
    );
  };

  const getDetailsModal = (card: CreditCard) => {
    return <CardDetails passedCard={card} />;
  };

  return (
    <CardsTable
      dataQuery={dataQuery}
      filterHook={filterHook}
      getTableHeader={getTableHeader}
      getDetailsModal={getDetailsModal}
    />
  );
};
