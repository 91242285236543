import { Grid, LoadingOverlay, rem, Stack, Text } from '@mantine/core';
import { IntegrationCard } from './components/integration-card';
import { INTEGRATION_PLATFORM, IntegrationLink, IntegrationPlatform } from './';
import {
  isQuickbooksBankFeeds,
  useCreateRutterConnection,
  useIntegrationLinks,
  useIntegrationsList,
} from '@utilities/integrations';
import {
  useCreateIntuitFeedConnection,
  useUpdateAccountingSettings,
} from '@queries/use-integrations';
import { notifications } from '@mantine/notifications';
import { useAdsEcommerceIntegrations } from '@utilities/feature-flags';
import { PropsWithChildren, ReactNode } from 'react';
import { IntegrationCardItem } from './list-integrations';
import { useDisconnectIntegration } from './utils/use-disconnect-integration';
import { IntegrationsContextProvider } from './integrations.context';
import { AccountingIntegrationCard } from './components/integration-card.accounting';
import { ExpenseLink } from '@flexbase-eng/types/dist/accounting';

type IntegrationGridItem<T extends IntegrationLink> = {
  integration: IntegrationCardItem;
  link: T | undefined;
};

function buildGridItems<T extends IntegrationLink = IntegrationLink>(
  integrations: IntegrationCardItem[],
  links: T[],
) {
  return integrations.reduce<IntegrationGridItem<T>[]>((acc, integration) => {
    const link = links.find(
      ({ platform }) => platform === integration.platform,
    );

    acc.push({ integration, link });

    return acc;
  }, []);
}

type IntegrationCategoryRowProps = PropsWithChildren<{
  categoryLabel: ReactNode;
}>;

const IntegrationCategoryRow = ({
  children,
  categoryLabel,
}: IntegrationCategoryRowProps) => {
  return (
    <Stack gap="md" pb="xl">
      <Text fz={24} fw={500} lh={rem(32)}>
        {categoryLabel}
      </Text>

      {children}
    </Stack>
  );
};

const Integrations = () => {
  const adsEcommerceFeatureFlagEnabled = useAdsEcommerceIntegrations();
  const { accountingIntegrations, adsIntegrations, commerceIntegrations } =
    useIntegrationsList();
  const {
    isPending: isIntegrationLinksPending,
    accountingLinks,
    adsLinks,
    commerceLinks,
    intuitFeedsLink,
  } = useIntegrationLinks();
  const { openRutterLink, isExchangingToken, isRutterConnecting } =
    useCreateRutterConnection();
  const { mutate: createFeedConnection, isPending: isCreateFeedPending } =
    useCreateIntuitFeedConnection();
  const { mutate: saveSettings, isPending: isSavingSettings } =
    useUpdateAccountingSettings();
  const { disconnectIntegration, isPending: isDisconnecting } =
    useDisconnectIntegration();

  /**
   * Connect should be disabled if RutterLink is open, links did not load properly or there
   * is a pending link for another platform.
   */
  const isConnectDisabled =
    isIntegrationLinksPending ||
    isRutterConnecting ||
    isExchangingToken ||
    isCreateFeedPending ||
    isSavingSettings ||
    isDisconnecting;

  const isDisconnectDisabled = isIntegrationLinksPending || isDisconnecting;

  const connectIntuitFeeds = () => {
    if (intuitFeedsLink) {
      saveSettings(
        {
          connectionId: intuitFeedsLink.connectionId,
          request: {
            bankFeeds: {
              enabled: true,
            },
          },
        },
        {
          onError: () => {
            notifications.show({
              color: 'critical.2',
              message: 'An unexpected error occurred while connecting.',
            });
          },
        },
      );
    } else {
      createFeedConnection(undefined, {
        onError: () => {
          notifications.show({
            color: 'critical.2',
            message: 'An unexpected error occurred while connecting.',
          });
        },
      });
    }
  };

  const handleConnect = (platform: IntegrationPlatform) => {
    if (platform === INTEGRATION_PLATFORM.INTUIT_BANK_FEEDS) {
      connectIntuitFeeds();
    } else {
      openRutterLink(platform);
    }
  };

  const handleDisconnect = (
    integration: IntegrationCardItem,
    link: IntegrationLink,
    onConfirmed?: () => void,
  ) => {
    if (isQuickbooksBankFeeds(link)) {
      saveSettings(
        {
          connectionId: link.connectionId,
          request: {
            bankFeeds: {
              enabled: false,
            },
          },
        },
        {
          onError: () => {
            notifications.show({
              color: 'critical.2',
              message:
                'An unexpected error occurred while disconnecting. Please try again later.',
            });
          },
        },
      );
    } else {
      disconnectIntegration({
        connectionId: link.connectionId,
        platformLabel: integration.title,
        onConfirmed,
      });
    }
  };

  if (isIntegrationLinksPending) {
    return <LoadingOverlay visible={true} />;
  }

  const accountingItems = buildGridItems<ExpenseLink>(
    accountingIntegrations,
    intuitFeedsLink ? [...accountingLinks, intuitFeedsLink] : accountingLinks,
  );
  const adsItems = buildGridItems(adsIntegrations, adsLinks);
  const commerceItems = buildGridItems(commerceIntegrations, commerceLinks);

  return (
    <IntegrationsContextProvider
      value={{
        accountingLinks: accountingLinks,
        intuitFeedsLink: intuitFeedsLink,

        connectIntegration: handleConnect,
        isConnecting: isRutterConnecting || isExchangingToken,
        connectDisabled: isConnectDisabled,

        createFeedConnection: createFeedConnection,
        isCreatingFeed: isCreateFeedPending,

        disconnectIntegration: handleDisconnect,
        isDisconnecting: isDisconnecting,
        disconnectDisabled: isDisconnectDisabled,

        saveAccountingSettings: saveSettings,
        isSavingSettings: isSavingSettings,
        saveSettingsDisabled: isSavingSettings,
      }}
    >
      <Stack gap={'md'}>
        <LoadingOverlay visible={isExchangingToken} />

        <IntegrationCategoryRow categoryLabel="Accounting">
          <Grid align="stretch">
            {accountingItems.map(({ integration, link }) => (
              <Grid.Col key={integration.platform} span={{ xs: 12, md: 6 }}>
                <AccountingIntegrationCard
                  integration={integration}
                  link={link}
                />
              </Grid.Col>
            ))}
          </Grid>
        </IntegrationCategoryRow>

        {adsEcommerceFeatureFlagEnabled && (
          <>
            <IntegrationCategoryRow categoryLabel="eCommerce">
              <Grid align="stretch">
                {commerceItems.map(({ integration, link }) => (
                  <Grid.Col key={integration.platform} span={{ xs: 12, md: 6 }}>
                    <IntegrationCard integration={integration} link={link} />
                  </Grid.Col>
                ))}
              </Grid>
            </IntegrationCategoryRow>

            <IntegrationCategoryRow categoryLabel="Ads">
              <Grid align="stretch">
                {adsItems.map(({ integration, link }) => (
                  <Grid.Col key={integration.platform} span={{ xs: 12, md: 6 }}>
                    <IntegrationCard integration={integration} link={link} />
                  </Grid.Col>
                ))}
              </Grid>
            </IntegrationCategoryRow>
          </>
        )}
      </Stack>
    </IntegrationsContextProvider>
  );
};

export default Integrations;
