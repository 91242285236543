import { useSpendTransactionModalFilters } from '../use-spend-transactions-filter';
import { SimpleSegmentSelectedFilter } from '@common/utilities/filter/simple-segmented.filter';

const STATUS_OPTIONS = [
  {
    label: 'Pending',
    filterCriteria: ['pending', 'initiated'],
    key: 'pending',
  },
  {
    label: 'Settled',
    filterCriteria: ['settled', 'succeeded'],
    key: 'settled',
  },
  { label: 'Declined', filterCriteria: 'declined', key: 'declined' },
  {
    label: 'Failed',
    filterCriteria: ['canceled', 'failed', 'chargeback'],
    key: 'failed',
  },
];

export const SpendStatusFilter = () => {
  const spendTransactionFilters = useSpendTransactionModalFilters();

  return (
    <SimpleSegmentSelectedFilter
      filterHook={spendTransactionFilters}
      filterKey="status"
      options={STATUS_OPTIONS}
      label="Status"
      includesValue={(row) => row.status}
    />
  );
};
