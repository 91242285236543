import { useStyles } from '@common/styles';
import { Center, Loader, Stack, Text } from '@mantine/core';

type Props = {
  loading: boolean;
};

const Authenticated = ({ loading }: Props) => {
  const { theme } = useStyles();

  if (loading)
    return (
      <Stack gap="md">
        <Center mt="xl">
          <Loader color={theme.primaryColor} size={50} />
        </Center>
        <Text ta="center">Verifying authentication...</Text>
      </Stack>
    );
};

export default Authenticated;
