import { capitalizeOnlyFirstLetter } from '@utilities/formatters';
import { useMantineTheme } from '@mantine/core';
import { MoneyMovementStatus } from '@services/flexbase/banking.model';
import { PayMethod } from 'types/payments';

export const getPaymentStatusDisplay = (status: MoneyMovementStatus) => {
  const theme = useMantineTheme();
  let backgroundColor: string, textColor: string, displayStatus: string;

  switch (status) {
    case 'AwaitingApproval':
    case 'AwaitingConfirmation':
    case 'Pending':
    case 'PendingReview':
      displayStatus =
        status === 'AwaitingApproval'
          ? 'Needs approval'
          : status === 'AwaitingConfirmation'
            ? 'Awaiting confirmation'
            : status === 'Pending'
              ? 'Pending'
              : 'Pending review';
      backgroundColor = theme.colors.tertiary[0];
      textColor = theme.colors.tertiary[5];
      break;

    case 'Scheduled':
    case 'Queued':
    case 'Clearing':
    case 'Sent':
    case 'Returned':
      displayStatus = status;
      backgroundColor = theme.colors.flexbaseGrey[0];
      textColor = theme.colors.neutral[7];
      break;

    case 'Rejected':
    case 'Canceled':
      displayStatus = status;
      backgroundColor = theme.colors.critical[0];
      textColor = theme.colors.critical[6];
      break;

    default:
      displayStatus = status;
      backgroundColor = theme.colors.flexbaseGrey[0];
      textColor = theme.colors.neutral[7];
  }

  return { displayStatus, backgroundColor, textColor };
};

export const formatPaymentType = (type: PayMethod) => {
  switch (type) {
    case 'ach':
      return 'ACH';
    case 'wire':
    case 'book':
      return capitalizeOnlyFirstLetter(type);
    case 'internationalWire':
    case 'internationalWireUSD':
      return 'International wire';
    default:
      return type;
  }
};
