import { DateTime } from 'luxon';
import { useState } from 'react';
import { Badge, rem, Text, useMantineTheme } from '@mantine/core';
import {
  MoneyMovement,
  MoneyMovementStatus,
} from '@services/flexbase/banking.model';
import FlexbaseInput from '@common/composites/input/flexbase-input';
import { TableColumn } from 'react-data-table-component';
import FlexbaseTable from 'components/table/flexbase-table';
import TabTableLayout from '@common/layouts/tab-table-layout';
import { useGetBankingPayments } from '@queries/use-payments';
import { useMatchMedia } from '@utilities/url/window-helpers';
import { getMonthDayYear, sortDate } from '@utilities/dates';
import { formatApiStrings } from 'utilities/formatters/format-api-strings';
import { PiMagnifyingGlass } from 'react-icons/pi';

const StatusRow = ({ status }: { status: MoneyMovementStatus }) => {
  const theme = useMantineTheme();
  let statusColor, labelColor;

  switch (status) {
    case 'Sent':
      statusColor = theme.colors.primarySecondarySuccess[0];
      labelColor = theme.colors.primarySecondarySuccess[6];
      break;
    case 'Pending':
    case 'PendingReview':
    case 'AwaitingApproval':
    case 'AwaitingConfirmation':
      statusColor = theme.colors.tertiary[0];
      labelColor = theme.colors.tertiary[7];
      break;
    case 'Canceled':
    case 'Rejected':
    case 'Returned':
      statusColor = theme.colors.critical[0];
      labelColor = theme.colors.critical[6];
      break;
    default:
      statusColor = theme.colors.neutral[1];
      labelColor = theme.colors.neutral[7];
  }
  return (
    <Badge bg={statusColor}>
      <Text c={labelColor}>{formatApiStrings(status)}</Text>
    </Badge>
  );
};

const ChargePaymentsTable = () => {
  const theme = useMantineTheme();
  const [inputText, setInputText] = useState('');
  const useMobileView = useMatchMedia(`(max-width: ${theme.breakpoints.md})`);

  const { data: payments, isLoading } = useGetBankingPayments({
    paymentScope: 'credit',
  });

  const inputHandler = (e: any) => {
    const lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  const columns: TableColumn<MoneyMovement>[] = [
    {
      name: 'Date created',
      selector: (row) => row.createdAt,
      format: (row) => getMonthDayYear(row.createdAt),
      sortable: true,
    },
    {
      name: 'Amount',
      selector: (row) => Number(row.payAmountCents) || 0,
      format: (row) => row.payAmount,
      sortable: true,
    },
    {
      name: 'Payment type',
      selector: (row) => row.origin,
      format: (row) => formatApiStrings(row.origin),
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => formatApiStrings(row.status),
      cell: (row) => <StatusRow status={row.status} />,
      sortable: true,
    },
  ];

  const tableData =
    payments
      ?.filter((item) => item.origin === 'manual' || item.origin === 'autopay')
      .sort((a, b) => sortDate(a, b, false)) ?? [];

  const filteredData =
    tableData?.filter((payment) => {
      if (inputText === '') {
        return payment;
      } else {
        const dateSubmitted = DateTime.fromSQL(payment.createdAt).toFormat(
          'LLL dd, yyyy',
        );
        return (
          dateSubmitted.toLowerCase().includes(inputText) ||
          payment.status.toLowerCase().includes(inputText) ||
          payment.payAmount.toLowerCase().includes(inputText) ||
          formatApiStrings(payment.origin).toLowerCase().includes(inputText)
        );
      }
    }) ?? [];

  return (
    <TabTableLayout
      leftHeaderContent={undefined}
      borderBottomSolid={false}
      rightHeaderContent={
        <FlexbaseInput
          onChange={inputHandler}
          placeholder="Search accounts"
          w={useMobileView ? '100%' : rem(320)}
          leftSection={
            <PiMagnifyingGlass
              size={'1.25rem'}
              color={theme.colors.blackish[0]}
            />
          }
        />
      }
      table={
        <FlexbaseTable
          columns={columns}
          data={filteredData}
          isFetchingData={isLoading}
          pagination={filteredData && filteredData?.length > 8}
        />
      }
    />
  );
};

export default ChargePaymentsTable;
