import { ComponentStep } from '../../../providers/multi-step-form-provider';
import { ReactNode } from 'react';

export type WizardStepConfig<
  TState,
  TStepMeta extends Record<string, unknown> = Record<string, unknown>,
> = {
  /**
   * Unique ID
   */
  id: string;

  /**
   * Step title
   */
  title: ReactNode;

  /**
   * The element that will be rendered when this step is active.
   */
  element: ReactNode;

  /**
   * Optional element for this step that will be rendered into the preview slot.
   */
  previewElement?: ReactNode;

  /**
   * Function to determine if this step should be included in the current step list.
   *
   * Returns true to include the step, false to exclude it.
   */
  condition?: (state: TState) => boolean;

  /**
   * Options to control how the wizard handles this step.
   */
  options?: {
    disableScrollToPreview?: boolean;
  };

  /**
   * Any miscellaneous data to associate with this step. By default this is weakly typed, but you can pass in a defined type to get TS enforcement.
   *
   * @example
   * type MyWizardState = {
   *   name: string;
   *   email: string;
   * };
   *
   * type MyWizardStepMeta = {
   *   something: ReactNode;
   * };
   *
   * type TypedWizardStep = WizardStepConfig<MyWizardState, MyWizardStepMeta>;
   *
   * const typedStep: TypedWizardStep = {
   *   metadata: {
   *     something: 'lorem ipsum', // must be string
   *   },
   * };
   *
   * typedStep.metadata.something; // key enforced by TS
   *
   * type UntypedWizardStep = WizardStepConfig<MyWizardState>;
   *
   * const untypedStep: UntypedWizardStep = {
   *   metadata: {
   *     anything: 'lorem ipsum', // can use any keys but won't be enforced by TS
   *   },
   * };
   *
   * untypedStep.metadata.anythnig; // won't catch typos
   */
  metadata?: TStepMeta;
};

export type WizardFormStep<
  TFormState,
  TStepMeta extends Record<string, unknown>,
> = ComponentStep<TFormState> & {
  metadata: ComponentStep<TFormState>['metadata'] & {
    previewElement?: ReactNode;
    disableScrollToPreview?: boolean;
    wizardStepMeta?: TStepMeta;
  };
};

export type WizardLabelsConfig = {
  back: string;
  next: string;
};

export function wizardStepConfigToFormStep<
  TFormState,
  TStepMeta extends Record<string, unknown>,
>(
  step: WizardStepConfig<TFormState, TStepMeta>,
): WizardFormStep<TFormState, TStepMeta> {
  return {
    element: step.element,
    condition: step.condition,
    metadata: {
      id: step.id,
      title: step.title,
      previewElement: step.previewElement,
      disableScrollToPreview: step.options?.disableScrollToPreview || false,
      wizardStepMeta: step.metadata,
    },
  };
}

export function formStepToWizardStepConfig<
  TFormState,
  TStepMeta extends Record<string, unknown>,
>(
  config?: WizardFormStep<TFormState, TStepMeta> | null,
): WizardStepConfig<TFormState, TStepMeta> | undefined {
  if (!config) {
    return;
  }

  return {
    id: config.metadata.id,
    title: config.metadata.title,
    element: config.element,
    condition: config.condition,
    previewElement: config.metadata.previewElement,
    metadata: config.metadata.wizardStepMeta,
    options:
      typeof config.metadata.disableScrollToPreview === 'undefined'
        ? undefined
        : {
            disableScrollToPreview: config.metadata.disableScrollToPreview,
          },
  };
}
