import { PlaidAccount, DepositAccount } from 'types/move-funds.model';
import { useGetPaymentInfo } from '@queries/use-payments';
import { useGetCardPayment } from '@queries/use-credit-payments';
import { useGetDepositAccount } from '@queries/use-deposit-account';
import { usePlaidCompanyAccounts } from '@queries/use-plaid-company-accounts';

export const useGetCardPaymentReceiptInfo = (paymentId: string) => {
  const {
    data: dataPayment,
    isError: isPaymentError,
    isLoading: isPaymentLoading,
  } = useGetCardPayment(paymentId);

  const payment = dataPayment?.payment;
  const bankingPaymentsId = payment?.bankingPaymentsId;
  const plaidAccountId = payment?.pldPayment?.transfer?.accountId;

  const { data: plaidAccountsData, isLoading: isPlaidAccountsLoading } =
    usePlaidCompanyAccounts(!!plaidAccountId);
  const { data: bankingPayment, isLoading: isBankingPaymentLoading } =
    useGetPaymentInfo(bankingPaymentsId ?? '');
  const { data: depositAccountData, isLoading: isDepositAccountLoading } =
    useGetDepositAccount(bankingPayment?.depositId ?? '');

  const plaidAccounts = plaidAccountsData?.accounts ?? [];

  let account: DepositAccount | PlaidAccount | undefined;
  if (plaidAccountId) {
    account = plaidAccounts.find((acct) => acct.accountId === plaidAccountId);
  } else if (payment?.bankingPaymentsId) {
    account = depositAccountData?.account;
  }

  const isLoading =
    isPaymentLoading ||
    isBankingPaymentLoading ||
    isDepositAccountLoading ||
    isPlaidAccountsLoading;

  return {
    data: { ...payment, account },
    isError: isPaymentError,
    isLoading,
  };
};
