import { Button, ButtonProps } from '@mantine/core';

type Props = { form: string } & Omit<ButtonProps, 'type'>;

export const MultiStepFormSubmitButton = ({
  children,
  form,
  ...buttonProps
}: Props) => {
  return (
    <Button form={form} type="submit" {...buttonProps}>
      {children}
    </Button>
  );
};
