import { useMutation, useQuery } from '@tanstack/react-query';
import flexbaseClient, {
  flexbaseOnboardingClient,
} from '../services/flexbase-client';
import {
  MakePaymentPlaid,
  MakePaymentUnit,
} from '../services/flexbase/flexbase-onboarding-client';

const QUERY_KEY = 'companyBalance';

export const useGetCompanyBalance = (companyId: string) => {
  return useQuery({
    queryKey: [QUERY_KEY, companyId],
    queryFn: async () => {
      return await flexbaseClient.getCompanyBalance(companyId);
    },
    meta: {
      errorMessage: 'Unable to get company balance',
    },
  });
};

export const useMakePaymentPlaid = () => {
  return useMutation({
    mutationFn: async (request: MakePaymentPlaid) => {
      const formatAmount = Number(request.amount);
      const amount = request.lineOfCredit === 'lithic' ? (formatAmount * 100).toFixed(0) : formatAmount.toFixed(2);
      return await flexbaseOnboardingClient.makePaymentPlaid({ ...request, amount });
    },
  });
};

export const useMakePaymentUnit = () => {
  return useMutation({
    mutationFn: async (request: MakePaymentUnit) => {
      const formatAmount = Number(request.amount);
      return await flexbaseOnboardingClient.makePaymentUnit({ ...request,  amount: formatAmount.toFixed(2) });
    },
  });
};
