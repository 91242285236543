import CreditPaymentCard from '../../components/credit-payment/credit-payment';
import PaymentsTable from '../../components/payments-table/payments-table';
import { useStyles } from './credit-payment.styles';
import { Tabs } from '@mantine/core';
import RebateHistoryTable from '@common/charge-and-credit-cards/rebate-history-table';
import { UseGetTenant } from 'queries/use-tenant';
import ScheduleChart from './schedule-chart';
import AutoPay from '@common/utilities/auto-pay-settings/auto-pay';
import { showNotification } from '@mantine/notifications';
import {
  ApplicationState,
  getProductOnboardingStatus,
  IsAdmin,
} from 'recoil-state/application/product-onboarding';
import AutoPaySettings from '@common/utilities/auto-pay-settings/auto-pay-settings';
import useModal from '@common/composites/modal/modal-hook';
import {
  AccountProps,
  DepositAccount,
  PlaidAccount,
} from 'types/move-funds.model';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useState } from 'react';
import { useGetCompanyBalance } from '@queries/use-credit-payment';
import { useGetDepositAccounts } from '@queries/use-deposit-accounts';
import {
  onError,
  usePlaidBankingComponent,
} from '@utilities/custom-hooks/plaid-banking-component';
import { useUpdateCompany } from '@queries/use-company';

const defaultCompanyBalance = {
  success: false,
  totalInvoices: 0,
  totalPayments: 0,
  currentBalance: 0,
  creditLimit: 0,
  availableLimit: 0,
  minimumDue: 0,
  maximumAllowedPayment: 0,
  billDate: '',
  graceDate: '',
  delinquentAmount: 0,
  delinquentDays: 0,
  interestDue: 0,
};

const CreditPaymentTab = () => {
  const { classes } = useStyles();
  const { data } = UseGetTenant();
  const { mutate: updateCompany, isPending } = useUpdateCompany();
  const [
    {
      company: { autopay, ...company },
    },
    setCompany,
  ] = useRecoilState(ApplicationState);
  const isAdmin = useRecoilValue(IsAdmin);
  const [loading, setLoading] = useState(false);
  const { data: companyBalance, isLoading } = useGetCompanyBalance(company.id);
  const { data: depositAccountsData, isLoading: isAccountsLoading } =
    useGetDepositAccounts();
  const [selectedAccount, setSelectedAccount] = useState<AccountProps | null>(
    null,
  );
  const { depositAccountId, plaidTokenId, ...autopayConfig } = autopay;

  const plaidAccounts = company.financialInstitutions
    .filter((fi) => fi.active && !fi.unlinked)
    .map((fi) => {
      return {
        ...fi,
        plaidOrDeposit: 'plaid',
      } as PlaidAccount;
    });

  const depositAccounts =
    depositAccountsData?.accounts
      .filter((acc) => acc.status === 'Open')
      .sort((a, b) => b.balance - a.balance)
      .map((acc) => {
        return {
          ...acc,
          plaidOrDeposit: 'deposit',
        } as DepositAccount;
      }) ?? [];

  const modal = useModal();
  const closeModal = modal.closeAllModals;
  const accountsList = [...plaidAccounts, ...depositAccounts];

  const getSelectedAccount = (id: string) => {
    return accountsList.find((acc) => acc.id === id) ?? null;
  };

  const primaryAccount = plaidAccounts.find(
    ({ id }) => id === company.primaryPlaidAccount,
  );

  const initAccount =
    depositAccountId || plaidTokenId
      ? (getSelectedAccount(depositAccountId ?? '') ??
        getSelectedAccount(plaidTokenId ?? ''))
      : getSelectedAccount(primaryAccount?.id ?? '');

  const currentAccount = selectedAccount
    ? selectedAccount
    : (initAccount ?? accountsList[0]);

  const companyBalanceData = companyBalance || defaultCompanyBalance;

  const handleEditSettings = () => {
    modal.openFullModal(
      <AutoPaySettings
        creditLimit={companyBalance?.creditLimit ?? 0}
        closeModal={closeModal}
      />,
    );
  };

  const handleAccountChange = async (value: string) => {
    setLoading(true);
    try {
      const account = getSelectedAccount(value);

      const autopayForm =
        account?.plaidOrDeposit === 'deposit'
          ? { autopay: { ...autopayConfig, depositAccountId: value } }
          : {
              primaryPlaidAccount: value,
              autopay: { ...autopayConfig, plaidTokenId: value },
            };

      updateCompany(autopayForm, {
        onSuccess: (result) => {
          setSelectedAccount(account);
          setCompany((prev) => ({
            ...prev,
            company: result,
          }));

          showNotification({
            color: 'flexbase-teal',
            title: 'Success',
            message: 'Successfully updated payment account',
          });
        },
      });
    } catch (error) {
      console.error('Unable to update repayment account.', error);
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Unable to update repayment account.',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleLinkPlaidAccount = () => {
    setLoading(true);
    open();
  };

  const onLinkBank = async () => {
    try {
      setLoading(true);
      const {
        company: { financialInstitutions },
      } = await getProductOnboardingStatus();
      setCompany((prev) => ({
        ...prev,
        company: { ...prev.company, financialInstitutions },
      }));
    } catch (error) {
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Unable to refresh linked bank account info',
      });
    } finally {
      setLoading(false);
    }
  };

  const { open } = usePlaidBankingComponent({
    onSuccess: () => onLinkBank(),
    onError: () => {
      onError();
    },
    setLoading,
  });

  return (
    <div className={classes.container}>
      <div className={classes.scheduleAndWidgetContainer}>
        <div className={classes.widgetContainer}>
          <CreditPaymentCard credit={true} />
          <AutoPay
            isAdmin={isAdmin}
            loading={loading || isPending}
            accountLoading={isLoading}
            accountsList={accountsList}
            currentAccount={currentAccount}
            companyBalance={companyBalanceData}
            autopay={autopay}
            onEditSettings={handleEditSettings}
            onAccountChange={handleAccountChange}
            onLinkBankAccount={handleLinkPlaidAccount}
            isAccountsLoading={isAccountsLoading}
          />
        </div>
        <div
          className={classes.scheduleContainer}
          style={{ overflow: 'hidden' }}
        >
          <ScheduleChart />
        </div>
      </div>
      <div>
        {data?.earlypay.dates ? (
          <Tabs defaultValue="payment-history" style={{ marginBottom: '15px' }}>
            <Tabs.List>
              <Tabs.Tab value="payment-history">Payment history</Tabs.Tab>
              <Tabs.Tab value="rebate-history">Rebate history</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="payment-history">
              <PaymentsTable />
            </Tabs.Panel>

            <Tabs.Panel value="rebate-history">
              <RebateHistoryTable />
            </Tabs.Panel>
          </Tabs>
        ) : (
          <PaymentsTable />
        )}
      </div>
    </div>
  );
};

export default CreditPaymentTab;
