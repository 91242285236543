import { createStyles } from '@mantine/emotion';

type Props = {
  searchFocused?: boolean;
};

export const useStyles = createStyles((theme, { searchFocused }: Props) => ({
  baseContainer: {
    display: 'flex',
    paddingBottom: 24,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  baseContainerSm: {
    paddingBottom: 10,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  searchIcon: {
    color: searchFocused ? theme.primaryColor : '#979797',
  },
  actions: {
    opacity: 0.8,
    padding: '10px',
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 500,
    color: '#5F5F5F',
    background: 'white',
    display: 'flex',
    justifyContent: 'flex-start',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(255, 87, 69, 0.08);',
    },
  },
}));
