import { Box, Collapse, Divider, NavLink, Text } from '@mantine/core';
import { ReactNode, useState } from 'react';
import { FilterData } from '@common/utilities/filter/filters';
import { PiXBold } from 'react-icons/pi';

export type FilterConfig = {
  header: ReactNode;
  icon: ReactNode;
  key: string;
  component: ReactNode;
};

type Props = {
  showFilters: boolean;
  filters: FilterConfig[];
  activeFilters: FilterData<any>[];
  onRemoveActiveFilterClick: (key: string) => void;
};

export const ExpandingFilterBar = ({
  showFilters,
  filters,
  onRemoveActiveFilterClick,
  activeFilters,
}: Props) => {
  const [activeFilter, setActiveFilter] = useState(filters[0].key);

  const filterMap = filters.reduce<Record<string, ReactNode>>((p, current) => {
    p[current.key] = current.component;
    return p;
  }, {});

  const filterChips = activeFilters.filter((f) => f.showChip);

  return (
    <>
      <Box>
        <Collapse in={showFilters}>
          <Box sx={{ width: '100%' }} mb="md">
            <Box
              sx={(theme) => ({
                display: 'flex',
                gap: theme.spacing.xs,
                flexWrap: 'wrap',
              })}
            >
              {filters.map((f) => (
                <NavLink
                  key={f.key}
                  label={f.header}
                  leftSection={f.icon}
                  active={f.key === activeFilter}
                  onClick={() => {
                    setActiveFilter(f.key);
                  }}
                  w="fit-content"
                  miw={136}
                  sx={(theme) => ({
                    height: 42,
                    backgroundColor: 'unset',
                    ['&:hover']: {
                      backgroundColor: theme.colors.neutral[2],
                    },
                    '&[data-active]': {
                      backgroundColor: theme.colors.neutral[2],
                      ['&:hover']: {
                        backgroundColor: theme.colors.neutral[2],
                      },
                    },
                  })}
                />
              ))}
            </Box>
            <Divider mt="xs" mb="xl" />
            <Box w="100%" px="md">
              {filterMap[activeFilter]}
            </Box>
          </Box>
        </Collapse>
      </Box>
      {filterChips.length > 0 && (
        <Box
          sx={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}
          mih={30}
          mb="md"
        >
          {filterChips.map((p) => (
            <Box
              sx={(theme, helpers) => ({
                height: 30,
                borderRadius: 100,
                backgroundColor: theme.colors.neutral[2],
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                padding: `0px ${theme.spacing.md}`,
                gap: 4,
                [helpers.smallerThan('md')]: {
                  height: 'auto',
                  padding: `0px ${theme.spacing.xl}`,
                },
              })}
              onClick={() => onRemoveActiveFilterClick(p.key)}
              key={p.key}
            >
              <Text color="black" fz={14} fw={500}>
                {p.label}
              </Text>
              <PiXBold
                size={'0.5rem'}
                color="black"
                style={{ marginLeft: 'auto' }}
              />
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};
