import { useMemo } from 'react';
import { uniq } from 'underscore';
import { MultiSelect } from '@mantine/core';
import { SpendPlanCardsResponse } from '@services/flexbase/spend-plans.model';
import { useSpendCardModalFilters } from '../use-spend-cards-filter';
import { useGetUsers } from '@queries/use-users';

type Props = { cards: SpendPlanCardsResponse[] };
export const SpendCardholderFilter = ({ cards }: Props) => {
  const { addFilter, removeFilter, getFilterByKey } =
    useSpendCardModalFilters();

  const { data: users = [] } = useGetUsers();

  const cardHolders = useMemo(() => {
    const usersMap = new Map(users.map((user) => [user.id, user]));

    const combinedCards = cards.map((card) => ({
      ...card,
      firstName: usersMap.get(card.personId)?.firstName || '',
      lastName: usersMap.get(card.personId)?.lastName || '',
    }));

    return uniq(combinedCards.map((d) => `${d.firstName} ${d.lastName}`));
  }, [cards, users]);

  return (
    <MultiSelect
      data={cardHolders}
      placeholder="Cardholder name"
      searchable
      value={getFilterByKey('cardholder')?.filterValue || []}
      onChange={(val) => {
        if (val.length > 0) {
          addFilter('cardholder', {
            key: 'cardholder',
            filterValue: val,
            fn: (row) => val.includes(`${row.firstName} ${row.lastName}`),
            label: `Cardholder: ${val.join(', ')}`,
            showChip: true,
          });
        } else {
          removeFilter('cardholder');
        }
      }}
    />
  );
};
