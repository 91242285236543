import { Flex, Text, Image, UnstyledButton } from '@mantine/core';
import {
  Dropzone,
  DropzoneProps,
  PDF_MIME_TYPE,
  IMAGE_MIME_TYPE,
} from '@mantine/dropzone';
import { TrashIconNew } from '../../../../assets/svg';
import { FileProps } from './additional-identity';
import { useOnboardingStyles } from '../../onboarding.styles';

type DropzoneSectionProps = {
  dropzoneText: string;
} & Omit<DropzoneProps, 'children'>;

export const DropzoneSection = ({
  dropzoneText,
  ...props
}: DropzoneSectionProps) => {
  const { classes } = useOnboardingStyles();
  const { maxSize } = props;

  return (
    <Dropzone
      {...props}
      multiple={false}
      maxSize={maxSize ?? 3 * 1024 ** 2}
      accept={[...IMAGE_MIME_TYPE, ...PDF_MIME_TYPE]}
      classNames={{
        root: classes.dropZone,
      }}
    >
      <Text ta="center" size="xs" fw={500}>
        {dropzoneText}
      </Text>
      <Text className={classes.dropZonePlaceholder}>
        Drag and drop or browse to add a document.
      </Text>
      <Text size="xs" color="neutral.4" ta="center">
        (PNG/JPEG/PDF)
      </Text>
    </Dropzone>
  );
};

type FilePreviewSectionProps = {
  file: FileProps;
  onRemoveFiles: () => void;
};

export const FilePreviewSection = ({
  file,
  onRemoveFiles,
}: FilePreviewSectionProps) => {
  const { classes, theme } = useOnboardingStyles();

  return (
    <div className={classes.file}>
      <Flex gap="md" align="center">
        <Image
          width={60}
          radius={2}
          height={60}
          src={file.url}
          fit="contain"
          onLoad={() => URL.revokeObjectURL(file.url)}
        />
        <Text>{file.name}</Text>
      </Flex>
      <UnstyledButton onClick={() => onRemoveFiles()}>
        <TrashIconNew width={20} color={theme.colors.neutral[6]} />
      </UnstyledButton>
    </div>
  );
};
