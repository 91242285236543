import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { flexbaseBankingClient as client } from '../services/flexbase-client';
import type { GetMoneyMovementsParams } from '../services/flexbase/banking.model';
import type { FlexbaseBankingClient } from '../services/flexbase/flexbase-banking-client';

const QUERY_KEY = 'bankingPayments';
const PAYMENT_QUERY_KEY = 'currentPayment';

const invalidatePaymentsCache = (queryClient: QueryClient) => () => {
  queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
};

// get the set of all Banking Payments
export const useGetBankingPayments = (params?: GetMoneyMovementsParams) =>
  useQuery({
    queryKey: [QUERY_KEY, params],
    queryFn: async () => {
      const response = await client.getMoneyMovements(params || {});
      return response.payments;
    },
    enabled: params ? Object.values(params).every((v) => !!v) : true,
    meta: {
      errorMessage: 'Unable to retrieve banking payments at this time.',
    },
  });

// get single payment info
export const useGetPaymentInfo = (paymentId: string) =>
  useQuery({
    queryKey: [PAYMENT_QUERY_KEY, paymentId],
    queryFn: async () => {
      const response = await client.getMoneyMovement(paymentId);
      return response.payment;
    },
    meta: {
      errorMessage: 'Unable to retrieve the payment information at this time.',
    },
    enabled: !!paymentId,
  });

// send an SMS code for confirmation of a Payment that needs 2FA
export const useSendCodeConfirmPayment = () =>
  useMutation({
    mutationFn: (id: string) =>
      client.confirmPayment(id).catch((error) => {
        console.error(error);
        throw new Error('Unable to send an SMS confirmation code.');
      }),
  });

// confirm a Payment using a verification code provided by SMS
export const useConfirmPayment = () => {
  const queryClient = useQueryClient();
  const onSuccess = invalidatePaymentsCache(queryClient);
  return useMutation({
    mutationFn: ({ id, code }: { id: string; code: string }) =>
      client.confirmPayment(id, code).catch((error) => {
        console.error(error);
        throw new Error('Payment not confirmed. Retry or request a new code.');
      }),
    onSuccess,
  });
};

// approve a Payment requested by non-Comptrollers
type MakeApproveRequest = Parameters<
  FlexbaseBankingClient['approvePayment']
>[0];
export const useApprovePayment = () => {
  const queryClient = useQueryClient();
  const onSuccess = invalidatePaymentsCache(queryClient);
  return useMutation({
    mutationFn: (request: MakeApproveRequest) =>
      client.approvePayment(request).catch((error) => {
        console.error(error);
        throw new Error('Unable to approve this payment.');
      }),
    onSuccess,
  });
};

// cancel a Payment that is in a cancel-able state
type MakeCancelRequest = Parameters<FlexbaseBankingClient['cancelPayment']>[0];
export const useCancelPayment = () => {
  const queryClient = useQueryClient();
  const onSuccess = invalidatePaymentsCache(queryClient);
  return useMutation({
    mutationFn: (request: MakeCancelRequest) =>
      client.cancelPayment(request).catch((error) => {
        console.error(error);
        throw new Error('Unable to cancel this payment.');
      }),
    onSuccess,
  });
};

// initiate a Payment for later approval, confirmation, or processing
type MakePaymentRequest = Parameters<FlexbaseBankingClient['makePayment']>[0];
export const useMakePayment = () => {
  return useMutation({
    mutationFn: (request: MakePaymentRequest) =>
      client.makePayment(request).catch((error) => {
        console.error(error);
        throw new Error('Unable to initiate this payment.');
      }),
  });
};
