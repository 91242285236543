import { Text, TextInput } from '@mantine/core';
import { useOnboardingStyles } from '../../onboarding.styles';
import FlexbaseInput from '@common/composites/input/flexbase-input';
import { useRecoilValue } from 'recoil';
import { validateRequired } from '../../../../utilities/validators/validate-required';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import { validateUSFormationDate } from '../../../../utilities/validators/validate-dates';
import { isEin } from '../../../../utilities/validators/validate-tax-id';
import { formatEinTaxId } from '../../../../utilities/formatters/format-tax-id';
import OnboardingStep from '../../components/onboarding-step';
import { DateTime } from 'luxon';
import { US_STATES } from '../../../../constants/constants';
import FlexbaseSelect from '../../../../components/composites/flexbase-select';
import {
  formatPhoneForApi,
  formatUSPhoneNumber,
} from '../../../../utilities/formatters/format-phone-number';
import { ApplicationState } from '../../../../recoil-state/application/product-onboarding';
import { formatOnboardingApiDate } from '../../../../utilities/formatters/format-datetime';
import { validateUSPhoneNumber } from '../../../../utilities/validators/validate-phone-number';
import { formatZip } from '../../../../utilities/formatters/format-address';
import { OnboardingCompany } from '../../../../types/onboarding-info';
import {
  formatDateForApi,
  formatDateInput,
} from '../../../../utilities/formatters/format-date-input';
import TagManager from 'react-gtm-module';
import YellowBanner from '../../components/yellow-banner';
import GooglePlacesSuggest from '@common/composites/input/google-places-suggest-input';
import { validateState } from '../../../../utilities/validators/validate-state';
import { validatePostalCode } from '@utilities/validators';
import { useApplicationFlowContext } from '../../onboarding-hooks';
import {
  useCreatePlatformBusinessAddress,
  useCreatePlatformBusinessPhoneMutation,
  usePlatformBusinessAddresses,
  useUpdatePlatformBusinessAddress,
  useUpdatePlatformBusinessMutation,
} from '../../../../queries/use-platform-business';
import {
  CreatePlatformBusinessAddressRequest,
  CreatePlatformPersonPhoneRequest,
  PlatformBusiness,
} from '../../../../services/platform/models/identity.model';
import { Analytics } from '@services/analytics/analytics';
import { ApplicationEvents } from '@services/analytics/events';

export type VerifyBusinessFormValues = {
  taxId: string;
  formationDate: string;
  line1: string;
  line2: string;
  state: string;
  postalCode: string;
  city: string;
  country: string;
  stateOfOperation: string;
  phone: string;
};

const VerifyBusiness = () => {
  const { classes: onboardingClasses } = useOnboardingStyles();
  const {
    company: companyOnboarding,
    accountId,
    businessId,
  } = useRecoilValue(ApplicationState);
  const { createOrUpdateCompany, goBack, navigateToNextProductStep } =
    useApplicationFlowContext();
  const { mutateAsync: updatePlatformBusiness } =
    useUpdatePlatformBusinessMutation();
  const { mutateAsync: createPlatformBusinessPhone } =
    useCreatePlatformBusinessPhoneMutation();
  const { data: platformBusinessAddresses } = usePlatformBusinessAddresses(
    accountId,
    businessId,
  );
  const { mutateAsync: createPlatformBusinessAddress } =
    useCreatePlatformBusinessAddress();
  const { mutateAsync: updatePlatformBusinessAddress } =
    useUpdatePlatformBusinessAddress();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchAddress, setSearchAddress] = useState(
    companyOnboarding.address.line1 || '',
  );

  const setStreet = (street: string) => {
    setSearchAddress(street);
  };

  const verifyBusinessForm = useForm<VerifyBusinessFormValues>({
    initialValues: {
      taxId: companyOnboarding.taxId || '',
      formationDate: formatOnboardingApiDate(companyOnboarding.formationDate),
      phone: companyOnboarding.phone
        ? formatUSPhoneNumber(companyOnboarding.phone)
        : '',
      line1: companyOnboarding.address.line1 || '',
      line2: companyOnboarding.address.line2 ?? '',
      state: companyOnboarding.address.state || '',
      postalCode: companyOnboarding.address.postalCode || '',
      city: companyOnboarding.address.city || '',
      country: companyOnboarding.address.country || '',
      stateOfOperation: companyOnboarding.stateOfOperation || '',
    },
    validate: {
      taxId: (val) => {
        if (companyOnboarding.legalStructure !== 'S-Prop' || val) {
          return isEin('EIN must be valid and in format xx-xxxxxxx')(val);
        }
        return null;
      },
      formationDate: (val) => {
        if (!validateUSFormationDate(val)) {
          return 'Invalid date';
        }
        const dt = DateTime.fromFormat(val, 'MM/dd/yyyy');
        if (dt.year < 578) {
          return 'Your company is not older than the oldest company in the world. Enter a valid date.';
        }
        return null;
      },
      phone: (val) =>
        validateUSPhoneNumber(val) ? null : 'Invalid phone number',
      stateOfOperation: (val) =>
        validateRequired(val) ? null : 'Operation state is required',
      line1: (value) =>
        validateRequired(value) ? null : 'Address is required',
      state: (value) => (validateState(value) ? null : 'State is required'),
      postalCode: (value) =>
        validatePostalCode(value) ? null : 'Zip code is required',
      city: (value) => (validateRequired(value) ? null : 'City is required'),
    },
  });

  const selectAddress = (item: {
    address: string;
    country: string;
    state: string;
    city: string;
    postalCode: string;
  }) => {
    setSearchAddress(item.address.trim());
    verifyBusinessForm.setFieldValue('line1', item.address.trim());
    verifyBusinessForm.setFieldValue('city', item.city);
    verifyBusinessForm.setFieldValue('state', item.state);
    verifyBusinessForm.setFieldValue('postalCode', item.postalCode);
  };

  const setZip = (zip: string) => {
    verifyBusinessForm.setFieldValue('postalCode', formatZip(zip));
  };

  const setTaxId = (value: string) =>
    verifyBusinessForm.setFieldValue('taxId', formatEinTaxId(value));

  const handleNextClick = async () => {
    const validation = verifyBusinessForm.validate();
    Analytics.track(ApplicationEvents.BUSINESS_VERIFY_CLICKED);
    if (!validation.hasErrors) {
      setLoading(true);
      const formValues = verifyBusinessForm.values;
      try {
        const request: Partial<OnboardingCompany> = {
          id: companyOnboarding.id,
          taxId: formValues.taxId,
          address: {
            line1: formValues.line1,
            line2: formValues.line2,
            city: formValues.city,
            postalCode: formValues.postalCode,
            state: formValues.state,
            country: formValues.country,
          },
          phone: formValues.phone,
          formationDate: formValues.formationDate,
          stateOfOperation: formValues.stateOfOperation,
        };

        const results = await updatePlatformBusinessData();
        const platformErrors = results
          .map((r) => (r.status === 'rejected' ? r.reason : undefined))
          .filter(Boolean);

        const { error: apiError } = await createOrUpdateCompany(request);
        const combinedErrors = [...platformErrors, apiError].filter(Boolean);

        if (combinedErrors.some((e) => e === 'ein_conflict')) {
          verifyBusinessForm.setFieldError(
            'taxId',
            'EIN rejected. Please verify for correctness.',
          );
        } else if (combinedErrors.length) {
          setError('An error occurred while updating company data');
        } else {
          TagManager.dataLayer({
            dataLayer: {
              event: 'verifiedBusiness',
            },
          });
          navigateToNextProductStep();
        }
        setLoading(false);
      } catch (e) {
        setError(typeof e === 'string' ? e : 'An unexpected error occurred');
      } finally {
        setLoading(false);
      }
    }
  };

  const updatePlatformBusinessData = async () => {
    const formValues = verifyBusinessForm.values;

    const businessRequest: Partial<PlatformBusiness> = {
      name: companyOnboarding.companyName, // Platform always requires this.
      taxId: formValues.taxId,
      formationDate: formatDateForApi(formValues.formationDate),
      formationLocation: formValues.stateOfOperation,
    };

    const phoneRequest: CreatePlatformPersonPhoneRequest = {
      type: 'work',
      isPrimary: true,
      active: true,
      value: `+1${formatPhoneForApi(formValues.phone)}`,
    };

    const addressRequest: CreatePlatformBusinessAddressRequest = {
      type: 'work',
      isPrimary: true,
      active: true,
      street: [formValues.line1, formValues.line2]
        .map((s) => s.trim())
        .filter(Boolean),
      locality: formValues.city,
      region: formValues.state,
      postalCode: formValues.postalCode,
      country: formValues.country,
    };

    const existingAddress = platformBusinessAddresses?.data.find(
      (a) => a.isPrimary,
    );

    return Promise.allSettled([
      updatePlatformBusiness({
        accountId,
        businessId,
        request: businessRequest,
      }),
      createPlatformBusinessPhone({
        accountId,
        businessId,
        phone: phoneRequest,
      }),
      existingAddress
        ? updatePlatformBusinessAddress({
            accountId,
            businessId,
            addressId: existingAddress.id,
            request: addressRequest,
          })
        : createPlatformBusinessAddress({
            accountId,
            businessId,
            request: addressRequest,
          }),
    ]);
  };

  const onBack = () => {
    goBack();
  };

  const setPhoneNumber = (value: string) =>
    verifyBusinessForm.setFieldValue('phone', formatUSPhoneNumber(value));

  const setDob = (value: string) => {
    verifyBusinessForm.setFieldValue('formationDate', formatDateInput(value));
  };

  return (
    <OnboardingStep
      title="Business information"
      subtitle="Let’s confirm your business information"
      onBackClick={onBack}
      onNextClick={handleNextClick}
      stepId="Verify-business"
      showContinueSpinner={loading}
      error={error}
    >
      <YellowBanner
        text={
          <Text>
            Do you need time to find your EIN? No problem! All of your answers
            have been saved so you can simply log back in when ready.
          </Text>
        }
      />
      <div className={onboardingClasses.containerSearchAddress}>
        <FlexbaseInput
          classNames={{
            label: onboardingClasses.inputLabel,
            root: onboardingClasses.btnSpacingAddressPage,
          }}
          label={
            companyOnboarding.legalStructure === 'S-Prop'
              ? 'EIN (Optional)'
              : 'EIN'
          }
          disabled={!!companyOnboarding.taxId}
          placeholder="EIN"
          {...verifyBusinessForm.getInputProps('taxId')}
          onChange={(e) => setTaxId(e.target.value)}
          data-sardine-id="input-business-employer-identification-number"
          id="input-business-employer-identification-number"
        />

        <FlexbaseInput
          classNames={{
            label: onboardingClasses.inputLabel,
            root: onboardingClasses.btnSpacingAddressPage,
          }}
          type="tel"
          label="Company phone"
          {...verifyBusinessForm.getInputProps('phone')}
          placeholder="(000) 000-0000"
          onChange={(e) => setPhoneNumber(e.target.value)}
          data-sardine-id="input-business-primary-phone"
          id="input-business-primary-phone"
        />
      </div>

      <div className={onboardingClasses.containerSearchAddress}>
        <TextInput
          classNames={{
            label: onboardingClasses.inputLabel,
            root: onboardingClasses.btnSpacingAddressPage,
          }}
          {...verifyBusinessForm.getInputProps('formationDate')}
          label="Date of incorporation"
          placeholder="MM / DD / YYYY"
          onChange={(e) => setDob(e.target.value)}
          data-sardine-id="input-business-formation-date"
          id="input-business-formation-date"
        />
        <FlexbaseSelect
          label="State of operation"
          placeholder="Select a state"
          {...verifyBusinessForm.getInputProps('stateOfOperation')}
          data={US_STATES || []}
          searchable
          comboboxProps={{ position: 'bottom' }}
          inputProps={{
            id: 'input-business-formation-state',
            classNames: {
              label: onboardingClasses.inputLabel,
              root: onboardingClasses.btnSpacingAddressPage,
            },
          }}
          maxDropdownHeight={400}
          data-sardine-id="input-business-formation-state"
          id="input-business-formation-state"
        />
      </div>

      <div className={onboardingClasses.containerSearchAddress}>
        <GooglePlacesSuggest
          label="Business address line 1"
          value={searchAddress}
          onChange={(value) => {
            setStreet(value);
            verifyBusinessForm.setFieldValue('line1', value.trim());
          }}
          onOptionSubmit={selectAddress}
          placeholder="Business address line 1"
          id="input-search-address"
          data-sardine-id="input-search-address"
          classNames={{
            label: onboardingClasses.inputLabel,
            container: onboardingClasses.btnSpacingAddressPage,
          }}
        />
        <FlexbaseInput
          label="Business address line 2 (Optional)"
          placeholder="Business address line 2 (Optional)"
          {...verifyBusinessForm.getInputProps('line2')}
          classNames={{
            label: onboardingClasses.inputLabel,
            root: onboardingClasses.btnSpacingAddressPage,
          }}
          data-sardine-id="input-address-line-2"
          id="input-address-line-2"
        />
      </div>
      <div className={onboardingClasses.containerSearchAddress}>
        <FlexbaseInput
          label="City"
          placeholder="City"
          {...verifyBusinessForm.getInputProps('city')}
          classNames={{
            label: onboardingClasses.inputLabel,
            root: onboardingClasses.btnSpacingAddressPage,
          }}
          data-sardine-id="input-address-city"
          id="input-address-city"
        />
        <div className={onboardingClasses.stateZipContainer}>
          <FlexbaseSelect
            {...verifyBusinessForm.getInputProps('state')}
            label="State"
            placeholder="State"
            data={US_STATES}
            searchable
            comboboxProps={{ position: 'bottom' }}
            inputProps={{
              id: 'input-address-select-state',
              classNames: {
                label: onboardingClasses.inputLabel,
                root: onboardingClasses.btnSpacingAddressPage,
              },
            }}
            maxDropdownHeight={400}
            data-sardine-id="input-address-select-state"
            id="input-address-select-state"
          />
          <FlexbaseInput
            label="Zip code"
            placeholder="Zip code"
            pattern={'[0-9]*'}
            {...verifyBusinessForm.getInputProps('postalCode')}
            onChange={(e) => setZip(e.target.value)}
            classNames={{
              label: onboardingClasses.inputLabel,
              root: onboardingClasses.btnSpacingAddressPage,
            }}
            data-sardine-id="input-address-zip-code"
            id="input-address-zip-code"
          />
        </div>
      </div>
    </OnboardingStep>
  );
};

export default VerifyBusiness;
