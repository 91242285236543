import { Badge, Text, useMantineTheme } from '@mantine/core';
import { MoneyMovementStatus } from '@services/flexbase/banking.model';
import { formatApiStrings } from '@utilities/formatters/format-api-strings';

const BadgeStatus = ({ status }: { status: MoneyMovementStatus }) => {
  const theme = useMantineTheme();
  let statusColor, labelColor;

  switch (status) {
    case 'Sent':
      statusColor = theme.colors.primarySecondarySuccess[0];
      labelColor = theme.colors.primarySecondarySuccess[6];
      break;
    case 'Pending':
    case 'PendingReview':
    case 'AwaitingApproval':
    case 'AwaitingConfirmation':
      statusColor = theme.colors.tertiary[0];
      labelColor = theme.colors.tertiary[7];
      break;
    case 'Canceled':
    case 'Rejected':
      statusColor = theme.colors.critical[0];
      labelColor = theme.colors.critical[6];
      break;
    default:
      statusColor = theme.colors.neutral[1];
      labelColor = theme.colors.neutral[7];
  }
  return (
    <Badge bg={statusColor}>
      <Text c={labelColor}>{formatApiStrings(status)}</Text>
    </Badge>
  );
};

export default BadgeStatus;
