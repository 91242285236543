import { useQuery } from '@tanstack/react-query';
import { flexbaseOnboardingClient } from '@services/flexbase-client';
import { GeneratedTermsKey } from '@services/flexbase/agreements.model';

export const useGetTermsAgreement = (key: GeneratedTermsKey) => {
  return useQuery({
    queryKey: ['agreements', key],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getTermsAgreement(key);
    },
  });
};
