import { ReactNode } from 'react';
import { CardStyleProps } from './cards-style-model';
import { createStyles } from '@mantine/emotion';

type Props = Partial<CardStyleProps> & {
  children: ReactNode;
  className?: string;
};

export const CardBase = ({ children, className, ...styles }: Props) => {
  const { classes, cx } = useStyles(styles);

  return <div className={cx(classes.card, className)}>{children}</div>;
};

const useStyles = createStyles((_, params: Partial<CardStyleProps>) => ({
  card: {
    width: params.width ?? '420px',
    height: params.height ?? '104px',
    minWidth: params.minWidth ?? params.width ?? '420px',
    minHeight: params.minHeight ?? params.height ?? '104px',
    backgroundColor: params.backgroundColor ?? '#FFFFFF',
    borderRadius: params.borderRadius ?? '8px',
    padding: params.padding ?? '24px',
  },
}));
