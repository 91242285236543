import { InvoiceWizard } from '../../invoice-wizard';
import ConfirmTotal from './confirm-total';

const ConfirmTotalStep = () => {
  return (
    <InvoiceWizard.Step hideNext hideBack>
      <ConfirmTotal />
    </InvoiceWizard.Step>
  );
};

ConfirmTotalStep.stepId = 'confirm-total-step';

export default ConfirmTotalStep;
