import { useSettingsSectionStyles } from './settings-section.styles';
import { ChangeEvent, useRef } from 'react';
import { PiCamera } from 'react-icons/pi';

type Props = {
  showDivider: boolean;
  imageUrl: string; // This is not optional (?) because TS complains about null vs undefined
  onFileUpload: (image: File) => void;
};

const ImageSettingsSection = ({
  showDivider,
  imageUrl,
  onFileUpload,
}: Props) => {
  const { classes } = useSettingsSectionStyles();
  const photoInputRef = useRef<HTMLInputElement>(null);

  const onUploadPhotoClick = () => {
    photoInputRef.current?.click();
  };

  const onUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files[0]) {
      onFileUpload(files[0]);
    }
  };

  return (
    <div
      className={`${classes.sectionContainer} ${
        showDivider ? classes.containerBorder : ''
      }`}
      style={{ alignItems: 'center' }}
    >
      <div className={classes.photoPlaceholder}>
        {imageUrl ? (
          <img src={imageUrl} alt="Profile picture" />
        ) : (
          <PiCamera size={'1.375rem'} color="white" />
        )}
        <input
          style={{ display: 'none' }}
          ref={photoInputRef}
          type="file"
          accept=".png, .jpg, .jpeg"
          onChange={onUpload}
        />
      </div>
      <div className={classes.uploadPhotoText} onClick={onUploadPhotoClick}>
        Upload Photo
      </div>
    </div>
  );
};

export default ImageSettingsSection;
