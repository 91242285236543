import {
  Box,
  Flex,
  Text,
  Paper,
  Center,
  Loader,
  Avatar,
  useMantineTheme,
} from '@mantine/core';
import { PiCreditCard } from 'react-icons/pi';
import { useInvoiceWizard } from '../../invoice-wizard';
import { useCreditLines } from '@queries/use-credit-lines';
import { formatCents, formatCurrency } from '@utilities/formatters';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'recoil-state/application/product-onboarding';

type ReviewBodyLocProps = {
  invoiceTotal?: {
    cents: number;
    formatted: string;
  };
};

export default function ReviewBodyLoc({ invoiceTotal }: ReviewBodyLocProps) {
  const theme = useMantineTheme();
  const { state } = useInvoiceWizard();
  const { businessId } = useRecoilValue(ApplicationState);
  const { paymentInfo: { creditProgramId } = {} } = state;
  const { data: lines = [], isLoading: isLoadingLinesOfCredit } =
    useCreditLines(businessId);
  const creditProgram = lines.find((cp) => cp.id === creditProgramId);

  let defaultName: string;
  switch (creditProgram?.issuer) {
    case 'billpay':
      defaultName = 'Billpay Credit';
      break;

    case 'lithic':
      defaultName = 'Flex Credit';
      break;

    case 'unit':
      defaultName = 'Charge Card';
      break;

    default:
      defaultName = 'Flex Credit';
      break;
  }
  const creditProgramName =
    creditProgram?.nickName ?? creditProgram?.name ?? defaultName;
  const creditProgramAvailableBalance =
    Number(creditProgram?.available) / 100 || 0;
  const invoiceTotalCents = invoiceTotal?.cents || 0;
  const fee = creditProgram?.fee
    ? Math.round(creditProgram.fee * invoiceTotalCents)
    : 0;
  const feeLabel = formatCurrency(formatCents(fee));
  const discount = creditProgram?.discount
    ? Math.round(creditProgram.discount * invoiceTotalCents)
    : 0;
  const discountLabel = formatCurrency(formatCents(discount));
  const totalFees = fee - discount;
  const totalFeesLabel = formatCurrency(formatCents(totalFees));

  const feesData = [
    {
      id: 'fee',
      description: feeLabel,
      title: `${fee * 100}% fee`,
    },
    {
      id: 'discount',
      description: discountLabel,
      title: `${discount * 100}% discount`,
    },
    {
      id: 'total',
      title: 'Total fees',
      description: totalFeesLabel,
    },
  ];

  if (isLoadingLinesOfCredit) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }
  return (
    <>
      <Flex justify="space-between">
        <Flex mt="xs" gap="xs" align="center">
          <Avatar bg="primarySecondarySuccess.0">
            <PiCreditCard fill={theme.colors.neutral[9]} size="1.5rem" />
          </Avatar>
          <Box>
            <Text size="sm">{creditProgramName}</Text>
            <Text size="sm">
              {formatCurrency(creditProgramAvailableBalance)} available
            </Text>
          </Box>
        </Flex>

        <Box>
          <Text size="sm" fw={600}>
            30D
          </Text>
          <Text size="sm">1%</Text>
        </Box>
      </Flex>

      <Paper p="md" withBorder mt="xl">
        <Text size="sm" fw={500}>
          Fees
        </Text>
        {feesData.map((item) => (
          <Flex key={item.id} justify="space-between">
            <Text size="sm">{item.title}</Text>
            <Text size="sm">{item.description}</Text>
          </Flex>
        ))}
      </Paper>
    </>
  );
}
