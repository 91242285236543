import { rem, Skeleton, Table } from '@mantine/core';
import { useStyles } from './styles';

type Props = {
  withTabs?: boolean;
  numRows?: number;
};

// TODO: make this dynamic to genereate the num columns and rows to show as being "loaded"
const SkeletonLoading = ({ withTabs }: Props) => {
  const { classes } = useStyles();

  const baseLoader = {
    a: <Skeleton height={rem(8)} maw={rem(150)} radius="xl" />,
    b: <Skeleton height={rem(8)} maw={rem(330)} radius="xl" />,
    c: <Skeleton height={rem(8)} maw={rem(100)} radius="xl" />,
    d: <Skeleton height={rem(8)} maw={rem(100)} radius="xl" />,
    e: <Skeleton height={rem(8)} maw={rem(180)} radius="xl" />,
  };

  const loaders = Array(4)
    .fill(0)
    .map(() => Object.assign({}, baseLoader));

  return (
    <div>
      {withTabs && (
        <div className={classes.tabs}>
          <Skeleton height={rem(8)} maw={rem(80)} radius="xl" mr={rem(50)} />
          <Skeleton height={rem(8)} maw={rem(80)} radius="xl" mr={rem(50)} />
          <Skeleton height={rem(8)} maw={110} radius="xl" mr={rem(50)} />
          <Skeleton height={rem(8)} maw={110} radius="xl" mr={rem(50)} />
          <Skeleton height={rem(8)} maw={rem(80)} radius="xl" />
        </div>
      )}
      <div className={classes.card}>
        <Skeleton height={rem(8)} maw={rem(150)} radius="xl" mb={rem(24)} />
        <Table verticalSpacing="lg">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>
                <Skeleton height={rem(8)} maw={rem(150)} radius="xl" />
              </Table.Th>
              <Table.Th>
                <Skeleton height={rem(8)} maw={rem(150)} radius="xl" />
              </Table.Th>
              <Table.Th>
                <Skeleton height={rem(8)} maw={rem(50)} radius="xl" />
              </Table.Th>
              <Table.Th>
                <Skeleton height={rem(8)} maw={rem(50)} radius="xl" />
              </Table.Th>
              <Table.Th>
                <Skeleton height={rem(8)} maw={rem(80)} radius="xl" />
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {loaders.map((loader, index) => (
              <Table.Tr key={index}>
                <Table.Td>{loader.a}</Table.Td>
                <Table.Td>{loader.b}</Table.Td>
                <Table.Td>{loader.c}</Table.Td>
                <Table.Td>{loader.d}</Table.Td>
                <Table.Td>{loader.e}</Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </div>
    </div>
  );
};

export default SkeletonLoading;
