import { useState } from 'react';
import { rem, useMantineTheme } from '@mantine/core';
import TwoFactorAuth from '@common/composites/two-factor-auth';
import {
  useCreateUnitCoToken,
  useCreateVGSCustToken,
  useGetUnitToken,
} from '@queries/use-unit-token';

type Props = {
  isVGSflow?: boolean;
  onSuccess: (token?: string) => void;
};

const TwoFactorAuthSection = ({ isVGSflow = false, onSuccess }: Props) => {
  const theme = useMantineTheme();
  const [retry, setRetry] = useState(false);
  const { mutate } = useCreateUnitCoToken();
  const { data, isError, refetch } = useGetUnitToken();
  const { mutate: VGSmutate } = useCreateVGSCustToken();
  const [codeError, setCodeError] = useState<string | null>(null);

  const unitToken = data?.attributes?.verificationToken || '';

  const updateUnitToken = async (code: string) => {
    setCodeError(null);
    if (code.length === 6) {
      mutate(
        {
          verificationCode: code,
          verificationToken: unitToken,
        },
        {
          onSuccess: () => onSuccess(),
          onError: () =>
            setCodeError('Invalid code. Please try again or re-send the code'),
        },
      );
    }
  };

  const updateVGSCustomerToken = async (code: string) => {
    setCodeError(null);
    if (code.length === 6) {
      VGSmutate(
        {
          verificationCode: code,
          verificationToken: unitToken,
        },
        {
          onSuccess: (resp) => onSuccess(resp.accessToken),
          onError: () => {
            setCodeError('Invalid code. Please try again or re-send the code');
          },
        },
      );
    }
  };

  const errorMsg = isError
    ? 'An unhandled error occurred. Please, try it again'
    : codeError;

  const resendCode = () => {
    setCodeError(null);
    setRetry(true);
    refetch();
  };

  const handleOnChange = (code: string) => {
    if (isVGSflow) {
      updateVGSCustomerToken(code);
    } else {
      updateUnitToken(code);
    }
  };

  return (
    <div
      style={{
        marginTop: rem(20),
        width: '100%',
        color: theme.primaryColor,
      }}
    >
      <TwoFactorAuth
        hasRetry={retry}
        errorMsg={errorMsg}
        onResendCode={resendCode}
        onChange={handleOnChange}
        label="Verification required"
      />
    </div>
  );
};

export default TwoFactorAuthSection;
