import { Button, Group, TextInput } from '@mantine/core';
import { useTitleFormContext } from './title-form-provider';
import { PiPencilSimpleLine } from 'react-icons/pi';

type TitleFormButtonProps = {
  onClick: () => void;
  currentTitle: string;
  onSubmit: (title: string) => void;
  isSubmitting: boolean;
};

export const TitleFormButtons = ({
  onClick,
  currentTitle,
  onSubmit,
  isSubmitting,
}: TitleFormButtonProps) => {
  const form = useTitleFormContext();

  const handleCancel = () => {
    onClick();
    form.setValues({ title: currentTitle });
  };

  const handleSubmit = () => {
    onSubmit(form.values.title);
  };

  const isDisabledSave =
    form.values.title === currentTitle || isSubmitting || !form.values.title;

  return (
    <Group>
      <Button variant="outline" onClick={handleCancel}>
        Cancel
      </Button>
      <Button
        variant="light"
        loading={isSubmitting}
        onClick={handleSubmit}
        disabled={isDisabledSave}
      >
        Save
      </Button>
    </Group>
  );
};

export const TitleFormInput = () => {
  const form = useTitleFormContext();
  return (
    <TextInput
      {...form.getInputProps('title')}
      placeholder="Edit"
      leftSection={<PiPencilSimpleLine />}
      styles={{
        input: {
          flexGrow: 1,
          marginRight: '1rem',
        },
      }}
      autoFocus
    />
  );
};
