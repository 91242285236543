import { Button, ButtonProps } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { ButtonHTMLAttributes } from 'react';

type DisconnectButtonProps = ButtonProps &
  ButtonHTMLAttributes<HTMLButtonElement>;

export const DisconnectButton = ({
  children,
  ...props
}: DisconnectButtonProps) => {
  const { classes } = useStyles();

  return (
    <Button {...props} variant="subtle" className={classes.disconnect}>
      {children}
    </Button>
  );
};

const useStyles = createStyles((theme) => ({
  disconnect: {
    color: theme.colors.critical[6],
  },
}));
