import { Button, ButtonProps } from '@mantine/core';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import {
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
  usePlaidLink,
} from 'react-plaid-link';
import { PlaidAccounts } from '@services/flexbase/banking.model';
import flexbaseClient, {
  flexbaseBankingClient,
} from '@services/flexbase-client';
import { showNotification } from '@mantine/notifications';
import PlaidContext, { PlaidContextValue } from 'providers/plaid-context';
import { usePlaidCompanyAccounts } from '@queries/use-plaid-company-accounts';
import { useGetDepositAccounts } from '@queries/use-deposit-accounts';
import { useRecoilValue } from 'recoil';
import { UserIdState } from '../../../../recoil-state/application/onboarding-form.state';
import { PiCheck } from 'react-icons/pi';
import { PlaidToken } from 'types/plaid';

type Props = {
  buttonLabel: ReactNode;
  buttonProps?: ButtonProps;
  onLinkingCompleted: () => void;
};
export function PlaidRelinkButton({
  buttonLabel,
  onLinkingCompleted,
  buttonProps,
}: Props) {
  const [tokens, setTokens] = useState<PlaidToken[]>([]);

  const [loading, setLoading] = useState(false);
  const { data, refetch } = usePlaidCompanyAccounts();
  const { refetch: refetchDepositAccounts } = useGetDepositAccounts();
  const userId = useRecoilValue(UserIdState);
  const onSuccess: PlaidLinkOnSuccess = async (
    publicToken: string,
    metadata: PlaidLinkOnSuccessMetadata,
  ) => {
    setLoading(true);
    PlaidContext.clearPlaidContext();
    const result = await flexbaseClient.exchangePlaidPublicToken(
      publicToken,
      metadata,
    );

    // strip the processed token from the queue
    const [processedToken, ...nextTokens] = tokens;
    const bank = processedToken?.bankName || 'Bank';

    // let the user know how it went
    if (result.success) {
      showNotification({
        title: 'Success!',
        message: `${bank} linked.`,
        color: 'flexbase-teal',
        icon: <PiCheck />,
      });
      refetch();
      refetchDepositAccounts();
    } else {
      showNotification({
        title: 'Failure',
        message: `Unable to link ${bank}.`,
        color: 'red',
      });
    }

    // reset the link token queue until it's empty
    setTokens(nextTokens);

    if (nextTokens.length && ready && open) {
      open();
    } else {
      // It may not actually be complete, but since Plaid wasn't ready to be reopened this is now delegated back to the user
      onLinkingCompleted();
    }
    setLoading(false);
  };

  const { ready, open } = usePlaidLink({
    onSuccess: onSuccess,
    token: tokens[0]?.linkToken,
  });

  const getLinkTokens = async (plaidData: PlaidAccounts) => {
    setLoading(true);

    if (plaidData.accounts) {
      const unlinkedAccounts = plaidData.accounts.reduce(
        (unlinkedAccts, { unlinked, id, bankName, userId: _userId }) => {
          if (unlinked && userId === _userId) {
            unlinkedAccts[bankName || ''] = id;
          }

          return unlinkedAccts;
        },
        {} as Record<string, string>,
      );

      if (!plaidData.accounts.length) {
        const linkToken = await flexbaseClient.getPlaidLinkToken();
        if (linkToken) {
          setTokens([{ bankName: '', linkToken }]);
          setLoading(false);
          return;
        }
      }

      // generate plaid link update tokens for each unlinked external account
      const linkTokenRequests = Object.entries(unlinkedAccounts).map(
        ([bankName, id]) =>
          flexbaseBankingClient.relinkPlaidAccount({ plaidTokenId: id }).then(
            (response) => ({
              bankName,
              success: true,
              linkToken: response.response.link_token,
            }),
            () => {
              return { bankName, success: false, linkToken: '' };
            },
          ),
      );

      await Promise.all(linkTokenRequests).then((results) => {
        // divide up responses into successes and failures
        const successes = results.filter(({ success }) => success);
        const failures = results.filter(({ success }) => !success);

        // notify users of failures without blocking
        for (const failure of failures) {
          showNotification({
            title: 'Failure',
            message: `Unable to get link token for ${failure.bankName}. Please contact support if the issue persists.`,
            color: 'red',
          });
        }

        // handle any successes remaining
        if (successes.length) {
          PlaidContext.appContext = PlaidContextValue.STALE;

          const responseTokens = successes.map(({ linkToken, bankName }) => ({
            linkToken: linkToken,
            bankName,
          }));

          setTokens(responseTokens);
        }
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (data) {
      getLinkTokens(data);
    }
  }, [data]);

  const handleRelinkClick = useCallback(() => {
    if (ready && open) {
      open();
    }
  }, [ready, open]);

  return (
    <Button onClick={handleRelinkClick} loading={loading} {...buttonProps}>
      {buttonLabel}
    </Button>
  );
}
