import { CreateSpendPlanFrequencyEnum } from '@flexbase-eng/types/dist/accounting';
import { SelectItem } from '@common/composites/flexbase-select';

export const RecurringSelectItems: SelectItem[] = [
  {
    label: 'Daily',
    value: CreateSpendPlanFrequencyEnum.Daily,
  },
  {
    label: 'Monthly',
    value: CreateSpendPlanFrequencyEnum.Monthly,
  },
  {
    label: 'Annually',
    value: CreateSpendPlanFrequencyEnum.Annually,
  },
] satisfies (SelectItem & {
  value: Exclude<CreateSpendPlanFrequencyEnum, 'onetime'>;
})[];
