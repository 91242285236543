export const ACH_COPY =
  'Same day (under $1M) if sent by 3pm ET, else 1 business day • No fee';
export const WIRE_COPY =
  'Same day if sent by 6pm ET, else 1 business day • No fee';

  export const TRANSACTION_GROUPS = {
    ach: [
      'Incoming ACH',
      'Returned ACH',
      'Dishonored ACH',
      'Originated ACH',
      'Returned received ACH',
      'Outgoing domestic ACH',
      'Incoming domestic ACH',
    ],
    card: [
      'Fee',
      'Fee reversal',
      'Push to card',
      'Debit card refund',
      'Debit card purchase',
      'Debit card transaction',
    ],
    atm: [
      'ATM deposit',
      'ATM withdrawal',
    ],
    wire: [
      'Wire',
      'Returned wire',
      'Outgoing domestic wire',
      'Incoming domestic wire',
    ],
    internationalWire: [
      'Outgoing international wire',
      'Incoming international wire',
    ],
    check: [
      'Check deposit',
      'Check payment',
      'Returned check deposit',
    ],
    payment: [
      'Payment advance',
      'Payment canceled',
      'Repaid payment advance',
    ],
    book: [
      'Flex transfer',
      'Internal transfer',
    ],
    others: [
      'Reward',
      'Release',
      'Dispute',
      'Interest',
      'Adjustment',
      'Bank repayment',
      'Negative balance coverage',
      'Account low balance closure',
    ],
  };

// TODO have the backend return these? Would need to be able to translate the "name"
export const BUSINESS_LEGAL_STRUCTURES = [
  'LLC',
  'C-Corp',
  'S-Corp',
  'Sole Proprietorship',
];

// TODO: these should be stored in the database and be updated as MCC codes update. Add filter to make viewable here (LOB defines filter logic)
export const BUSINESS_MERCHANT_CODES = [
  { value: '8911', label: 'Architectural, engineering and surveying services' },
  { value: '5046', label: 'Commercial equipment' },
  { value: '7349', label: 'Cleaning, maintenance and janitorial services' },
  { value: '1771', label: 'Concrete work contractors' },
  { value: '5039', label: 'Construction materials' },
  {
    value: '7394',
    label: 'Equipment, tool, furniture and appliance rentals and leasing',
  },
  { value: '5713', label: 'Floor covering services' },
  {
    value: '1711',
    label: 'Heating, plumbing and air-conditioning contractors',
  },
  { value: '0780', label: 'Landscaping and horticultural services' },
  { value: '5211', label: 'Lumber and building materials outlets' },
  { value: '8931', label: 'Accounting, auditing and bookkeeping services' },
  { value: '7623', label: 'Air conditioning and refrigeration repair shops' },
  {
    value: '5932',
    label: 'Antique shops — sales, repairs and restoration services',
  },
  { value: '5970', label: 'Artist supply and craft shops' },
  { value: '7531', label: 'Automotive body repair shops' },
  { value: '7535', label: 'Automotive paint shops' },
  { value: '5533', label: 'Automotive parts and accessories outlets' },
  { value: '7538', label: 'Automotive service shops (non-dealer)' },
  { value: '5532', label: 'Automotive tyre outlets' },
  { value: '5462', label: 'Bakeries' },
  { value: '5940', label: 'Bicycle shops — sales and service' },
  { value: '5551', label: 'Boat dealers' },
  { value: '4457', label: 'Boat rentals and leasing' },
  { value: '5942', label: 'Bookshops' },
  { value: '5946', label: 'Camera and photographic supply shops' },
  { value: '5441', label: 'Candy, nut and confectionery shops' },
  { value: '7542', label: 'Car washes' },
  { value: '1750', label: 'Carpentry contractors' },
  { value: '7217', label: 'Carpet and upholstery cleaning' },
  { value: '5811', label: 'Caterers' },
  {
    value: '4215',
    label: 'Courier services — air and ground and freight forwarders',
  },
  { value: '5714', label: 'Drapery, window covering and upholstery shops' },
  { value: '7216', label: 'Dry cleaners' },
  { value: '7629', label: 'Electrical and small appliance repair shops' },
  { value: '1731', label: 'Electrical contractors' },
  { value: '5065', label: 'Electrical parts and equipment' },
  { value: '7622', label: 'Electronics repair shops' },
  { value: '5732', label: 'Electronics shops' },
  { value: '7342', label: 'Exterminating and disinfecting services' },
  { value: '5651', label: 'Family clothing shops' },
  { value: '5193', label: 'Florists supplies, nursery stock and flowers' },
  { value: '7641', label: 'Furniture reupholstery, repair and refinishing' },
  { value: '1520', label: 'General contractors — residential and commercial' },
  { value: '5947', label: 'Gift, card, novelty and souvenir shops' },
  { value: '5231', label: 'Glass, paint and wallpaper shops' },
  { value: '5950', label: 'Glassware and crystal shops' },
  { value: '5072', label: 'Hardware equipment and supplies' },
  { value: '5200', label: 'Home supply warehouse outlets' },
  { value: '5722', label: 'Household appliance shops' },
  { value: '7211', label: 'Laundry services — family and commercial' },
  {
    value: '5261',
    label: 'Lawn and garden supply outlets, including nurseries',
  },
  { value: '8111', label: 'Legal services and attorneys' },
  {
    value: '7392',
    label: 'Management, consulting and public relations services',
  },
  { value: '5691', label: 'Mens and womens clothing shops' },
  { value: '5021', label: 'Office and commercial furniture' },
  { value: '5933', label: 'Pawn shops' },
  { value: '7221', label: 'Photographic studios' },
  { value: '5074', label: 'Plumbing and heating equipment and supplies' },
  { value: '1761', label: 'Roofing, siding and sheet metal work contractors' },
  { value: '5949', label: 'Sewing, needlework, fabric and piece goods shops' },
  {
    value: '7251',
    label: 'Shoe repair shops, shoe shine parlours and hat cleaning shops',
  },
  { value: '5661', label: 'Shoe shops' },
  { value: '1799', label: 'Special trade contractors' },
  {
    value: '2842',
    label: 'Speciality cleaning, polishing and sanitation preparations',
  },
  { value: '5972', label: 'Stamp and coin shops' },
  { value: '5943', label: 'Stationery, office and school supply shops' },
  {
    value: '5111',
    label: 'Stationery, office supplies and printing and writing paper',
  },
  { value: '7339', label: 'Stenographic and secretarial support services' },
  { value: '5996', label: 'Swimming pools — sales, supplies and services' },
  { value: '5697', label: 'Tailors, seamstresses, mending and alterations' },
  { value: '7276', label: 'Tax preparation services' },
  { value: '7549', label: 'Towing services' },
  { value: '4722', label: 'Travel agencies and tour operators' },
  { value: '5931', label: 'Used merchandise and second-hand shops' },
  { value: '7692', label: 'Welding services' },
  { value: '0743', label: 'Wine producers' },
];

export const BUSINESS_MONTHLY_EXPENDITURES = [
  '$1-10K',
  '$10-50K',
  '$50-100K',
  '$100K+',
];

export const BUSINESS_MONTHLY_SPEND = [
  '$0-4K',
  '$4-10K',
  '$10-20K',
  '$20-40K',
  '$40K+',
];

export const BUSINESS_ANNUAL_REVENUE = [
  '$1-50K',
  '$50K-250K',
  '$250K-$1M',
  '$1M-$5M',
  '$5M-$10M',
  '$10M-$50M',
  '$50M+',
];

export const BUSINESS_INSURANCE_TYPES = [
  { value: 'general_liability', label: 'General Liability' },
  { value: 'workers_comp', label: 'Workers Compensation' },
  { value: 'surety_bond', label: 'Surety Bond' },
  { value: 'casualty', label: 'Casualty' },
  { value: 'other', label: 'Other' },
];

export const UNSERVED_CREDIT_STATES = [
  { label: 'California', value: 'CA' },
  { label: 'Nevada', value: 'NV' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Vermont', value: 'VT' },
];

export const US_STATES = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'District of Columbia', value: 'DC' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

export const JOB_TITLE_VALUES = [
  { value: 'Owner', label: 'Owner' },
  { value: 'Member', label: 'Member' },
  { value: 'Manager', label: 'Manager' },
  { value: 'Partner', label: 'Partner' },
  { value: 'VP', label: 'Vice President' },
  { value: 'President', label: 'President' },
  { value: 'Treasurer', label: 'Treasurer' },
  { value: 'Secretary', label: 'Secretary' },
  { value: 'Controller', label: 'Controller' },
  { value: 'CEO', label: 'Chief Executive Officer' },
  { value: 'COO', label: 'Chief Operating Officer' },
  { value: 'CFO', label: 'Chief Financial Officer' },
  { value: 'AVP', label: 'Assistant Vice President' },
  { value: 'CIO', label: 'Chief Information Officer' },
  {
    value: 'BenefitsAdministrationOfficer',
    label: 'Benefits Administration Officer',
  },
];

export const CASH_ON_HAND_VALUES = [
  '$50K-250K',
  '$250K-1M',
  '$1M-$5M',
  '$5M-$10M',
  '$10M-$50M',
  '$50M+',
];

export const NAICS_CODES = [
  { value: '11', label: 'Agriculture, Forestry, Fishing and Hunting' },
  { value: '111', label: 'Crop Production' },
  { value: '1111', label: 'Oilseed and Grain Farming' },
  { value: '11111', label: 'Soybean Farming' },
  { value: '111110', label: 'Soybean Farming' },
  { value: '11112', label: 'Oilseed (except Soybean) Farming' },
  { value: '111120', label: 'Oilseed (except Soybean) Farming' },
  { value: '11113', label: 'Dry Pea and Bean Farming' },
  { value: '111130', label: 'Dry Pea and Bean Farming' },
  { value: '11114', label: 'Wheat Farming' },
  { value: '111140', label: 'Wheat Farming' },
  { value: '11115', label: 'Corn Farming' },
  { value: '111150', label: 'Corn Farming' },
  { value: '11116', label: 'Rice Farming' },
  { value: '111160', label: 'Rice Farming' },
  { value: '11119', label: 'Other Grain Farming' },
  { value: '111191', label: 'Oilseed and Grain Combination Farming' },
  { value: '111199', label: 'All Other Grain Farming' },
  { value: '1112', label: 'Vegetable and Melon Farming' },
  { value: '11121', label: 'Vegetable and Melon Farming' },
  { value: '111211', label: 'Potato Farming' },
  {
    value: '111219',
    label: 'Other Vegetable (except Potato) and Melon Farming',
  },
  { value: '1113', label: 'Fruit and Tree Nut Farming' },
  { value: '11131', label: 'Orange Groves' },
  { value: '111310', label: 'Orange Groves' },
  { value: '11132', label: 'Citrus (except Orange) Groves' },
  { value: '111320', label: 'Citrus (except Orange) Groves' },
  { value: '11133', label: 'Noncitrus Fruit and Tree Nut Farming' },
  { value: '111331', label: 'Apple Orchards' },
  { value: '111332', label: 'Grape Vineyards' },
  { value: '111333', label: 'Strawberry Farming' },
  { value: '111334', label: 'Berry (except Strawberry) Farming' },
  { value: '111335', label: 'Tree Nut Farming' },
  { value: '111336', label: 'Fruit and Tree Nut Combination Farming' },
  { value: '111339', label: 'Other Noncitrus Fruit Farming' },
  {
    value: '1114',
    label: 'Greenhouse, Nursery, and Floriculture Production',
  },
  { value: '11141', label: 'Food Crops Grown Under Cover' },
  { value: '111411', label: 'Mushroom Production' },
  { value: '111419', label: 'Other Food Crops Grown Under Cover' },
  { value: '11142', label: 'Nursery and Floriculture Production' },
  { value: '111421', label: 'Nursery and Tree Production' },
  { value: '111422', label: 'Floriculture Production' },
  { value: '1119', label: 'Other Crop Farming' },
  { value: '11191', label: 'Tobacco Farming' },
  { value: '111910', label: 'Tobacco Farming' },
  { value: '11192', label: 'Cotton Farming' },
  { value: '111920', label: 'Cotton Farming' },
  { value: '11193', label: 'Sugarcane Farming' },
  { value: '111930', label: 'Sugarcane Farming' },
  { value: '11194', label: 'Hay Farming' },
  { value: '111940', label: 'Hay Farming' },
  { value: '11199', label: 'All Other Crop Farming' },
  { value: '111991', label: 'Sugar Beet Farming' },
  { value: '111992', label: 'Peanut Farming' },
  { value: '111998', label: 'All Other Miscellaneous Crop Farming' },
  { value: '112', label: 'Animal Production and Aquaculture' },
  { value: '1121', label: 'Cattle Ranching and Farming' },
  {
    value: '11211',
    label: 'Beef Cattle Ranching and Farming, including Feedlots',
  },
  { value: '112111', label: 'Beef Cattle Ranching and Farming' },
  { value: '112112', label: 'Cattle Feedlots' },
  { value: '11212', label: 'Dairy Cattle and Milk Production' },
  { value: '112120', label: 'Dairy Cattle and Milk Production' },
  { value: '11213', label: 'Dual-Purpose Cattle Ranching and Farming' },
  { value: '112130', label: 'Dual-Purpose Cattle Ranching and Farming' },
  { value: '1122', label: 'Hog and Pig Farming' },
  { value: '11221', label: 'Hog and Pig Farming' },
  { value: '112210', label: 'Hog and Pig Farming' },
  { value: '1123', label: 'Poultry and Egg Production' },
  { value: '11231', label: 'Chicken Egg Production' },
  { value: '112310', label: 'Chicken Egg Production' },
  {
    value: '11232',
    label: 'Broilers and Other Meat Type Chicken Production',
  },
  {
    value: '112320',
    label: 'Broilers and Other Meat Type Chicken Production',
  },
  { value: '11233', label: 'Turkey Production' },
  { value: '112330', label: 'Turkey Production' },
  { value: '11234', label: 'Poultry Hatcheries' },
  { value: '112340', label: 'Poultry Hatcheries' },
  { value: '11239', label: 'Other Poultry Production' },
  { value: '112390', label: 'Other Poultry Production' },
  { value: '1124', label: 'Sheep and Goat Farming' },
  { value: '11241', label: 'Sheep Farming' },
  { value: '112410', label: 'Sheep Farming' },
  { value: '11242', label: 'Goat Farming' },
  { value: '112420', label: 'Goat Farming' },
  { value: '1125', label: 'Aquaculture' },
  { value: '11251', label: 'Aquaculture' },
  { value: '112511', label: 'Finfish Farming and Fish Hatcheries' },
  { value: '112512', label: 'Shellfish Farming' },
  { value: '112519', label: 'Other Aquaculture' },
  { value: '1129', label: 'Other Animal Production' },
  { value: '11291', label: 'Apiculture' },
  { value: '112910', label: 'Apiculture' },
  { value: '11292', label: 'Horses and Other Equine Production' },
  { value: '112920', label: 'Horses and Other Equine Production' },
  { value: '11293', label: 'Fur-Bearing Animal and Rabbit Production' },
  { value: '112930', label: 'Fur-Bearing Animal and Rabbit Production' },
  { value: '11299', label: 'All Other Animal Production' },
  { value: '112990', label: 'All Other Animal Production' },
  { value: '113', label: 'Forestry and Logging' },
  { value: '1131', label: 'Timber Tract Operations' },
  { value: '11311', label: 'Timber Tract Operations' },
  { value: '113110', label: 'Timber Tract Operations' },
  {
    value: '1132',
    label: 'Forest Nurseries and Gathering of Forest Products',
  },
  {
    value: '11321',
    label: 'Forest Nurseries and Gathering of Forest Products',
  },
  {
    value: '113210',
    label: 'Forest Nurseries and Gathering of Forest Products',
  },
  { value: '1133', label: 'Logging' },
  { value: '11331', label: 'Logging' },
  { value: '113310', label: 'Logging' },
  { value: '114', label: 'Fishing, Hunting and Trapping' },
  { value: '1141', label: 'Fishing' },
  { value: '11411', label: 'Fishing' },
  { value: '114111', label: 'Finfish Fishing' },
  { value: '114112', label: 'Shellfish Fishing' },
  { value: '114119', label: 'Other Marine Fishing' },
  { value: '1142', label: 'Hunting and Trapping' },
  { value: '11421', label: 'Hunting and Trapping' },
  { value: '114210', label: 'Hunting and Trapping' },
  {
    value: '115',
    label: 'Support Activities for Agriculture and Forestry',
  },
  { value: '1151', label: 'Support Activities for Crop Production' },
  { value: '11511', label: 'Support Activities for Crop Production' },
  { value: '115111', label: 'Cotton Ginning' },
  {
    value: '115112',
    label: 'Soil Preparation, Planting, and Cultivating',
  },
  { value: '115113', label: 'Crop Harvesting, Primarily by Machine' },
  {
    value: '115114',
    label: 'Postharvest Crop Activities (except Cotton Ginning)',
  },
  { value: '115115', label: 'Farm Labor Contractors and Crew Leaders' },
  { value: '115116', label: 'Farm Management Services' },
  { value: '1152', label: 'Support Activities for Animal Production' },
  { value: '11521', label: 'Support Activities for Animal Production' },
  { value: '115210', label: 'Support Activities for Animal Production' },
  { value: '1153', label: 'Support Activities for Forestry' },
  { value: '11531', label: 'Support Activities for Forestry' },
  { value: '115310', label: 'Support Activities for Forestry' },
  { value: '21', label: 'Mining, Quarrying, and Oil and Gas Extraction' },
  { value: '211', label: 'Oil and Gas Extraction' },
  { value: '2111', label: 'Oil and Gas Extraction' },
  { value: '21112', label: 'Crude Petroleum Extraction�' },
  { value: '211120', label: 'Crude Petroleum Extraction�' },
  { value: '21113', label: 'Natural Gas Extraction�' },
  { value: '211130', label: 'Natural Gas Extraction�' },
  { value: '212', label: 'Mining (except Oil and Gas)' },
  { value: '2121', label: 'Coal Mining' },
  { value: '21211', label: 'Coal Mining' },
  { value: '212114', label: 'Surface Coal Mining' },
  { value: '212115', label: 'Underground Coal Mining' },
  { value: '2122', label: 'Metal Ore Mining' },
  { value: '21221', label: 'Iron Ore Mining' },
  { value: '212210', label: 'Iron Ore Mining' },
  { value: '21222', label: 'Gold Ore and Silver Ore Mining' },
  { value: '212220', label: 'Gold Ore and Silver Ore Mining' },
  { value: '21223', label: 'Copper, Nickel, Lead, and Zinc Mining' },
  { value: '212230', label: 'Copper, Nickel, Lead, and Zinc Mining' },
  { value: '21229', label: 'Other Metal Ore Mining' },
  { value: '212290', label: 'Other Metal Ore Mining' },
  { value: '2123', label: 'Nonmetallic Mineral Mining and Quarrying' },
  { value: '21231', label: 'Stone Mining and Quarrying' },
  { value: '212311', label: 'Dimension Stone Mining and Quarrying' },
  {
    value: '212312',
    label: 'Crushed and Broken Limestone Mining and Quarrying',
  },
  {
    value: '212313',
    label: 'Crushed and Broken Granite Mining and Quarrying',
  },
  {
    value: '212319',
    label: 'Other Crushed and Broken Stone Mining and Quarrying',
  },
  {
    value: '21232',
    label:
      'Sand, Gravel, Clay, and Ceramic and Refractory Minerals Mining and Quarrying',
  },
  { value: '212321', label: 'Construction Sand and Gravel Mining' },
  { value: '212322', label: 'Industrial Sand Mining' },
  {
    value: '212323',
    label: 'Kaolin, Clay, and Ceramic and Refractory Minerals Mining',
  },
  {
    value: '21239',
    label: 'Other Nonmetallic Mineral Mining and Quarrying',
  },
  {
    value: '212390',
    label: 'Other Nonmetallic Mineral Mining and Quarrying',
  },
  { value: '213', label: 'Support Activities for Mining' },
  { value: '2131', label: 'Support Activities for Mining' },
  { value: '21311', label: 'Support Activities for Mining' },
  { value: '213111', label: 'Drilling Oil and Gas Wells' },
  {
    value: '213112',
    label: 'Support Activities for Oil and Gas Operations',
  },
  { value: '213113', label: 'Support Activities for Coal Mining' },
  { value: '213114', label: 'Support Activities for Metal Mining' },
  {
    value: '213115',
    label: 'Support Activities for Nonmetallic Minerals (except Fuels) Mining',
  },
  { value: '22', label: 'Utilities' },
  { value: '221', label: 'Utilities' },
  {
    value: '2211',
    label: 'Electric Power Generation, Transmission and Distribution',
  },
  { value: '22111', label: 'Electric Power Generation' },
  { value: '221111', label: 'Hydroelectric Power Generation' },
  { value: '221112', label: 'Fossil Fuel Electric Power Generation' },
  { value: '221113', label: 'Nuclear Electric Power Generation' },
  { value: '221114', label: 'Solar Electric Power Generation' },
  { value: '221115', label: 'Wind Electric Power Generation' },
  { value: '221116', label: 'Geothermal Electric Power Generation' },
  { value: '221117', label: 'Biomass Electric Power Generation' },
  { value: '221118', label: 'Other Electric Power Generation' },
  {
    value: '22112',
    label: 'Electric Power Transmission, Control, and Distribution',
  },
  {
    value: '221121',
    label: 'Electric Bulk Power Transmission and Control',
  },
  { value: '221122', label: 'Electric Power Distribution' },
  { value: '2212', label: 'Natural Gas Distribution' },
  { value: '22121', label: 'Natural Gas Distribution' },
  { value: '221210', label: 'Natural Gas Distribution' },
  { value: '2213', label: 'Water, Sewage and Other Systems' },
  { value: '22131', label: 'Water Supply and Irrigation Systems' },
  { value: '221310', label: 'Water Supply and Irrigation Systems' },
  { value: '22132', label: 'Sewage Treatment Facilities' },
  { value: '221320', label: 'Sewage Treatment Facilities' },
  { value: '22133', label: 'Steam and Air-Conditioning Supply' },
  { value: '221330', label: 'Steam and Air-Conditioning Supply' },
  { value: '23', label: 'Construction' },
  { value: '236', label: 'Construction of Buildings' },
  { value: '2361', label: 'Residential Building Construction' },
  { value: '23611', label: 'Residential Building Construction' },
  {
    value: '236115',
    label: 'New Single-Family Housing Construction (except For-Sale Builders)',
  },
  {
    value: '236116',
    label: 'New Multifamily Housing Construction (except For-Sale Builders)',
  },
  { value: '236117', label: 'New Housing For-Sale Builders' },
  { value: '236118', label: 'Residential Remodelers' },
  { value: '2362', label: 'Nonresidential Building Construction' },
  { value: '23621', label: 'Industrial Building Construction' },
  { value: '236210', label: 'Industrial Building Construction' },
  {
    value: '23622',
    label: 'Commercial and Institutional Building Construction',
  },
  {
    value: '236220',
    label: 'Commercial and Institutional Building Construction',
  },
  { value: '237', label: 'Heavy and Civil Engineering Construction' },
  { value: '2371', label: 'Utility System Construction' },
  {
    value: '23711',
    label: 'Water and Sewer Line and Related Structures Construction',
  },
  {
    value: '237110',
    label: 'Water and Sewer Line and Related Structures Construction',
  },
  {
    value: '23712',
    label: 'Oil and Gas Pipeline and Related Structures Construction',
  },
  {
    value: '237120',
    label: 'Oil and Gas Pipeline and Related Structures Construction',
  },
  {
    value: '23713',
    label: 'Power and Communication Line and Related Structures Construction',
  },
  {
    value: '237130',
    label: 'Power and Communication Line and Related Structures Construction',
  },
  { value: '2372', label: 'Land Subdivision' },
  { value: '23721', label: 'Land Subdivision' },
  { value: '237210', label: 'Land Subdivision' },
  { value: '2373', label: 'Highway, Street, and Bridge Construction' },
  { value: '23731', label: 'Highway, Street, and Bridge Construction' },
  { value: '237310', label: 'Highway, Street, and Bridge Construction' },
  {
    value: '2379',
    label: 'Other Heavy and Civil Engineering Construction',
  },
  {
    value: '23799',
    label: 'Other Heavy and Civil Engineering Construction',
  },
  {
    value: '237990',
    label: 'Other Heavy and Civil Engineering Construction',
  },
  { value: '238', label: 'Specialty Trade Contractors' },
  {
    value: '2381',
    label: 'Foundation, Structure, and Building Exterior Contractors',
  },
  {
    value: '23811',
    label: 'Poured Concrete Foundation and Structure Contractors',
  },
  {
    value: '238110',
    label: 'Poured Concrete Foundation and Structure Contractors',
  },
  {
    value: '23812',
    label: 'Structural Steel and Precast Concrete Contractors',
  },
  {
    value: '238120',
    label: 'Structural Steel and Precast Concrete Contractors',
  },
  { value: '23813', label: 'Framing Contractors' },
  { value: '238130', label: 'Framing Contractors' },
  { value: '23814', label: 'Masonry Contractors' },
  { value: '238140', label: 'Masonry Contractors' },
  { value: '23815', label: 'Glass and Glazing Contractors' },
  { value: '238150', label: 'Glass and Glazing Contractors' },
  { value: '23816', label: 'Roofing Contractors' },
  { value: '238160', label: 'Roofing Contractors' },
  { value: '23817', label: 'Siding Contractors' },
  { value: '238170', label: 'Siding Contractors' },
  {
    value: '23819',
    label: 'Other Foundation, Structure, and Building Exterior Contractors',
  },
  {
    value: '238190',
    label: 'Other Foundation, Structure, and Building Exterior Contractors',
  },
  { value: '2382', label: 'Building Equipment Contractors' },
  {
    value: '23821',
    label: 'Electrical Contractors and Other Wiring Installation Contractors',
  },
  {
    value: '238210',
    label: 'Electrical Contractors and Other Wiring Installation Contractors',
  },
  {
    value: '23822',
    label: 'Plumbing, Heating, and Air-Conditioning Contractors',
  },
  {
    value: '238220',
    label: 'Plumbing, Heating, and Air-Conditioning Contractors',
  },
  { value: '23829', label: 'Other Building Equipment Contractors' },
  { value: '238290', label: 'Other Building Equipment Contractors' },
  { value: '2383', label: 'Building Finishing Contractors' },
  { value: '23831', label: 'Drywall and Insulation Contractors' },
  { value: '238310', label: 'Drywall and Insulation Contractors' },
  { value: '23832', label: 'Painting and Wall Covering Contractors' },
  { value: '238320', label: 'Painting and Wall Covering Contractors' },
  { value: '23833', label: 'Flooring Contractors' },
  { value: '238330', label: 'Flooring Contractors' },
  { value: '23834', label: 'Tile and Terrazzo Contractors' },
  { value: '238340', label: 'Tile and Terrazzo Contractors' },
  { value: '23835', label: 'Finish Carpentry Contractors' },
  { value: '238350', label: 'Finish Carpentry Contractors' },
  { value: '23839', label: 'Other Building Finishing Contractors' },
  { value: '238390', label: 'Other Building Finishing Contractors' },
  { value: '2389', label: 'Other Specialty Trade Contractors' },
  { value: '23891', label: 'Site Preparation Contractors' },
  { value: '238910', label: 'Site Preparation Contractors' },
  { value: '23899', label: 'All Other Specialty Trade Contractors' },
  { value: '238990', label: 'All Other Specialty Trade Contractors' },
  { value: '31-33', label: 'Manufacturing' },
  { value: '311', label: 'Food Manufacturing' },
  { value: '3111', label: 'Animal Food Manufacturing' },
  { value: '31111', label: 'Animal Food Manufacturing' },
  { value: '311111', label: 'Dog and Cat Food Manufacturing' },
  { value: '311119', label: 'Other Animal Food Manufacturing' },
  { value: '3112', label: 'Grain and Oilseed Milling' },
  { value: '31121', label: 'Flour Milling and Malt Manufacturing' },
  { value: '311211', label: 'Flour Milling' },
  { value: '311212', label: 'Rice Milling' },
  { value: '311213', label: 'Malt Manufacturing' },
  {
    value: '31122',
    label: 'Starch and Vegetable Fats and Oils Manufacturing',
  },
  { value: '311221', label: 'Wet Corn Milling and Starch Manufacturing' },
  { value: '311224', label: 'Soybean and Other Oilseed Processing' },
  { value: '311225', label: 'Fats and Oils Refining and Blending' },
  { value: '31123', label: 'Breakfast Cereal Manufacturing' },
  { value: '311230', label: 'Breakfast Cereal Manufacturing' },
  {
    value: '3113',
    label: 'Sugar and Confectionery Product Manufacturing',
  },
  { value: '31131', label: 'Sugar Manufacturing' },
  { value: '311313', label: 'Beet Sugar Manufacturing' },
  { value: '311314', label: 'Cane Sugar Manufacturing' },
  { value: '31134', label: 'Nonchocolate Confectionery Manufacturing' },
  { value: '311340', label: 'Nonchocolate Confectionery Manufacturing' },
  { value: '31135', label: 'Chocolate and Confectionery Manufacturing' },
  {
    value: '311351',
    label: 'Chocolate and Confectionery Manufacturing from Cacao Beans',
  },
  {
    value: '311352',
    label: 'Confectionery Manufacturing from Purchased Chocolate',
  },
  {
    value: '3114',
    label: 'Fruit and Vegetable Preserving and Specialty Food Manufacturing',
  },
  { value: '31141', label: 'Frozen Food Manufacturing' },
  {
    value: '311411',
    label: 'Frozen Fruit, Juice, and Vegetable Manufacturing',
  },
  { value: '311412', label: 'Frozen Specialty Food Manufacturing' },
  {
    value: '31142',
    label: 'Fruit and Vegetable Canning, Pickling, and Drying',
  },
  { value: '311421', label: 'Fruit and Vegetable Canning' },
  { value: '311422', label: 'Specialty Canning' },
  { value: '311423', label: 'Dried and Dehydrated Food Manufacturing' },
  { value: '3115', label: 'Dairy Product Manufacturing' },
  {
    value: '31151',
    label: 'Dairy Product (except Frozen) Manufacturing',
  },
  { value: '311511', label: 'Fluid Milk Manufacturing' },
  { value: '311512', label: 'Creamery Butter Manufacturing' },
  { value: '311513', label: 'Cheese Manufacturing' },
  {
    value: '311514',
    label: 'Dry, Condensed, and Evaporated Dairy Product Manufacturing',
  },
  { value: '31152', label: 'Ice Cream and Frozen Dessert Manufacturing' },
  {
    value: '311520',
    label: 'Ice Cream and Frozen Dessert Manufacturing',
  },
  { value: '3116', label: 'Animal Slaughtering and Processing' },
  { value: '31161', label: 'Animal Slaughtering and Processing' },
  { value: '311611', label: 'Animal (except Poultry) Slaughtering' },
  { value: '311612', label: 'Meat Processed from Carcasses' },
  { value: '311613', label: 'Rendering and Meat Byproduct Processing' },
  { value: '311615', label: 'Poultry Processing' },
  { value: '3117', label: 'Seafood Product Preparation and Packaging' },
  { value: '31171', label: 'Seafood Product Preparation and Packaging' },
  { value: '311710', label: 'Seafood Product Preparation and Packaging' },
  { value: '3118', label: 'Bakeries and Tortilla Manufacturing' },
  { value: '31181', label: 'Bread and Bakery Product Manufacturing' },
  { value: '311811', label: 'Retail Bakeries' },
  { value: '311812', label: 'Commercial Bakeries' },
  {
    value: '311813',
    label: 'Frozen Cakes, Pies, and Other Pastries Manufacturing',
  },
  { value: '31182', label: 'Cookie, Cracker, and Pasta Manufacturing' },
  { value: '311821', label: 'Cookie and Cracker Manufacturing' },
  {
    value: '311824',
    label:
      'Dry Pasta, Dough, and Flour Mixes Manufacturing from Purchased Flour',
  },
  { value: '31183', label: 'Tortilla Manufacturing' },
  { value: '311830', label: 'Tortilla Manufacturing' },
  { value: '3119', label: 'Other Food Manufacturing' },
  { value: '31191', label: 'Snack Food Manufacturing' },
  {
    value: '311911',
    label: 'Roasted Nuts and Peanut Butter Manufacturing',
  },
  { value: '311919', label: 'Other Snack Food Manufacturing' },
  { value: '31192', label: 'Coffee and Tea Manufacturing' },
  { value: '311920', label: 'Coffee and Tea Manufacturing' },
  {
    value: '31193',
    label: 'Flavoring Syrup and Concentrate Manufacturing',
  },
  {
    value: '311930',
    label: 'Flavoring Syrup and Concentrate Manufacturing',
  },
  { value: '31194', label: 'Seasoning and Dressing Manufacturing' },
  {
    value: '311941',
    label: 'Mayonnaise, Dressing, and Other Prepared Sauce Manufacturing',
  },
  { value: '311942', label: 'Spice and Extract Manufacturing' },
  { value: '31199', label: 'All Other Food Manufacturing' },
  { value: '311991', label: 'Perishable Prepared Food Manufacturing' },
  {
    value: '311999',
    label: 'All Other Miscellaneous Food Manufacturing',
  },
  { value: '312', label: 'Beverage and Tobacco Product Manufacturing' },
  { value: '3121', label: 'Beverage Manufacturing' },
  { value: '31211', label: 'Soft Drink and Ice Manufacturing' },
  { value: '312111', label: 'Soft Drink Manufacturing' },
  { value: '312112', label: 'Bottled Water Manufacturing' },
  { value: '312113', label: 'Ice Manufacturing' },
  { value: '31212', label: 'Breweries' },
  { value: '312120', label: 'Breweries' },
  { value: '31213', label: 'Wineries' },
  { value: '312130', label: 'Wineries' },
  { value: '31214', label: 'Distilleries' },
  { value: '312140', label: 'Distilleries' },
  { value: '3122', label: 'Tobacco Manufacturing' },
  { value: '31223', label: 'Tobacco Manufacturing' },
  { value: '312230', label: 'Tobacco Manufacturing' },
  { value: '313', label: 'Textile Mills' },
  { value: '3131', label: 'Fiber, Yarn, and Thread Mills' },
  { value: '31311', label: 'Fiber, Yarn, and Thread Mills' },
  { value: '313110', label: 'Fiber, Yarn, and Thread Mills' },
  { value: '3132', label: 'Fabric Mills' },
  { value: '31321', label: 'Broadwoven Fabric Mills' },
  { value: '313210', label: 'Broadwoven Fabric Mills' },
  {
    value: '31322',
    label: 'Narrow Fabric Mills and Schiffli Machine Embroidery',
  },
  {
    value: '313220',
    label: 'Narrow Fabric Mills and Schiffli Machine Embroidery',
  },
  { value: '31323', label: 'Nonwoven Fabric Mills' },
  { value: '313230', label: 'Nonwoven Fabric Mills' },
  { value: '31324', label: 'Knit Fabric Mills' },
  { value: '313240', label: 'Knit Fabric Mills' },
  {
    value: '3133',
    label: 'Textile and Fabric Finishing and Fabric Coating Mills',
  },
  { value: '31331', label: 'Textile and Fabric Finishing Mills' },
  { value: '313310', label: 'Textile and Fabric Finishing Mills' },
  { value: '31332', label: 'Fabric Coating Mills' },
  { value: '313320', label: 'Fabric Coating Mills' },
  { value: '314', label: 'Textile Product Mills' },
  { value: '3141', label: 'Textile Furnishings Mills' },
  { value: '31411', label: 'Carpet and Rug Mills' },
  { value: '314110', label: 'Carpet and Rug Mills' },
  { value: '31412', label: 'Curtain and Linen Mills' },
  { value: '314120', label: 'Curtain and Linen Mills' },
  { value: '3149', label: 'Other Textile Product Mills' },
  { value: '31491', label: 'Textile Bag and Canvas Mills' },
  { value: '314910', label: 'Textile Bag and Canvas Mills' },
  { value: '31499', label: 'All Other Textile Product Mills' },
  {
    value: '314994',
    label: 'Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills',
  },
  {
    value: '314999',
    label: 'All Other Miscellaneous Textile Product Mills',
  },
  { value: '315', label: 'Apparel Manufacturing' },
  { value: '3151', label: 'Apparel Knitting Mills' },
  { value: '31512', label: 'Apparel Knitting Mills' },
  { value: '315120', label: 'Apparel Knitting Mills' },
  { value: '3152', label: 'Cut and Sew Apparel Manufacturing' },
  { value: '31521', label: 'Cut and Sew Apparel Contractors' },
  { value: '315210', label: 'Cut and Sew Apparel Contractors' },
  {
    value: '31525',
    label: 'Cut and Sew Apparel Manufacturing (except Contractors)',
  },
  {
    value: '315250',
    label: 'Cut and Sew Apparel Manufacturing (except Contractors)',
  },
  {
    value: '3159',
    label: 'Apparel Accessories and Other Apparel Manufacturing',
  },
  {
    value: '31599',
    label: 'Apparel Accessories and Other Apparel Manufacturing',
  },
  {
    value: '315990',
    label: 'Apparel Accessories and Other Apparel Manufacturing',
  },
  { value: '316', label: 'Leather and Allied Product Manufacturing' },
  { value: '3161', label: 'Leather and Hide Tanning and Finishing' },
  { value: '31611', label: 'Leather and Hide Tanning and Finishing' },
  { value: '316110', label: 'Leather and Hide Tanning and Finishing' },
  { value: '3162', label: 'Footwear Manufacturing' },
  { value: '31621', label: 'Footwear Manufacturing' },
  { value: '316210', label: 'Footwear Manufacturing' },
  {
    value: '3169',
    label: 'Other Leather and Allied Product Manufacturing',
  },
  {
    value: '31699',
    label: 'Other Leather and Allied Product Manufacturing',
  },
  {
    value: '316990',
    label: 'Other Leather and Allied Product Manufacturing',
  },
  { value: '321', label: 'Wood Product Manufacturing' },
  { value: '3211', label: 'Sawmills and Wood Preservation' },
  { value: '32111', label: 'Sawmills and Wood Preservation' },
  { value: '321113', label: 'Sawmills' },
  { value: '321114', label: 'Wood Preservation' },
  {
    value: '3212',
    label: 'Veneer, Plywood, and Engineered Wood Product Manufacturing',
  },
  {
    value: '32121',
    label: 'Veneer, Plywood, and Engineered Wood Product Manufacturing',
  },
  { value: '321211', label: 'Hardwood Veneer and Plywood Manufacturing' },
  { value: '321212', label: 'Softwood Veneer and Plywood Manufacturing' },
  { value: '321215', label: 'Engineered Wood Member Manufacturing' },
  { value: '321219', label: 'Reconstituted Wood Product Manufacturing' },
  { value: '3219', label: 'Other Wood Product Manufacturing' },
  { value: '32191', label: 'Millwork' },
  { value: '321911', label: 'Wood Window and Door Manufacturing' },
  { value: '321912', label: 'Cut Stock, Resawing Lumber, and Planing' },
  { value: '321918', label: 'Other Millwork (including Flooring)' },
  { value: '32192', label: 'Wood Container and Pallet Manufacturing' },
  { value: '321920', label: 'Wood Container and Pallet Manufacturing' },
  { value: '32199', label: 'All Other Wood Product Manufacturing' },
  {
    value: '321991',
    label: 'Manufactured Home (Mobile Home) Manufacturing',
  },
  { value: '321992', label: 'Prefabricated Wood Building Manufacturing' },
  {
    value: '321999',
    label: 'All Other Miscellaneous Wood Product Manufacturing',
  },
  { value: '322', label: 'Paper Manufacturing' },
  { value: '3221', label: 'Pulp, Paper, and Paperboard Mills' },
  { value: '32211', label: 'Pulp Mills' },
  { value: '322110', label: 'Pulp Mills' },
  { value: '32212', label: 'Paper Mills' },
  { value: '322120', label: 'Paper Mills' },
  { value: '32213', label: 'Paperboard Mills' },
  { value: '322130', label: 'Paperboard Mills' },
  { value: '3222', label: 'Converted Paper Product Manufacturing' },
  { value: '32221', label: 'Paperboard Container Manufacturing' },
  {
    value: '322211',
    label: 'Corrugated and Solid Fiber Box Manufacturing',
  },
  { value: '322212', label: 'Folding Paperboard Box Manufacturing' },
  { value: '322219', label: 'Other Paperboard Container Manufacturing' },
  {
    value: '32222',
    label: 'Paper Bag and Coated and Treated Paper Manufacturing',
  },
  {
    value: '322220',
    label: 'Paper Bag and Coated and Treated Paper Manufacturing',
  },
  { value: '32223', label: 'Stationery Product Manufacturing' },
  { value: '322230', label: 'Stationery Product Manufacturing' },
  {
    value: '32229',
    label: 'Other Converted Paper Product Manufacturing',
  },
  { value: '322291', label: 'Sanitary Paper Product Manufacturing' },
  {
    value: '322299',
    label: 'All Other Converted Paper Product Manufacturing',
  },
  { value: '323', label: 'Printing and Related Support Activities' },
  { value: '3231', label: 'Printing and Related Support Activities' },
  { value: '32311', label: 'Printing' },
  {
    value: '323111',
    label: 'Commercial Printing (except Screen and Books)',
  },
  { value: '323113', label: 'Commercial Screen Printing' },
  { value: '323117', label: 'Books Printing' },
  { value: '32312', label: 'Support Activities for Printing' },
  { value: '323120', label: 'Support Activities for Printing' },
  { value: '324', label: 'Petroleum and Coal Products Manufacturing' },
  { value: '3241', label: 'Petroleum and Coal Products Manufacturing' },
  { value: '32411', label: 'Petroleum Refineries' },
  { value: '324110', label: 'Petroleum Refineries' },
  {
    value: '32412',
    label: 'Asphalt Paving, Roofing, and Saturated Materials Manufacturing',
  },
  {
    value: '324121',
    label: 'Asphalt Paving Mixture and Block Manufacturing',
  },
  {
    value: '324122',
    label: 'Asphalt Shingle and Coating Materials Manufacturing',
  },
  {
    value: '32419',
    label: 'Other Petroleum and Coal Products Manufacturing',
  },
  {
    value: '324191',
    label: 'Petroleum Lubricating Oil and Grease Manufacturing',
  },
  {
    value: '324199',
    label: 'All Other Petroleum and Coal Products Manufacturing',
  },
  { value: '325', label: 'Chemical Manufacturing' },
  { value: '3251', label: 'Basic Chemical Manufacturing' },
  { value: '32511', label: 'Petrochemical Manufacturing' },
  { value: '325110', label: 'Petrochemical Manufacturing' },
  { value: '32512', label: 'Industrial Gas Manufacturing' },
  { value: '325120', label: 'Industrial Gas Manufacturing' },
  { value: '32513', label: 'Synthetic Dye and Pigment Manufacturing' },
  { value: '325130', label: 'Synthetic Dye and Pigment Manufacturing' },
  {
    value: '32518',
    label: 'Other Basic Inorganic Chemical Manufacturing',
  },
  {
    value: '325180',
    label: 'Other Basic Inorganic Chemical Manufacturing',
  },
  { value: '32519', label: 'Other Basic Organic Chemical Manufacturing' },
  { value: '325193', label: 'Ethyl Alcohol Manufacturing' },
  {
    value: '325194',
    label:
      'Cyclic Crude, Intermediate, and Gum and Wood Chemical Manufacturing',
  },
  {
    value: '325199',
    label: 'All Other Basic Organic Chemical Manufacturing',
  },
  {
    value: '3252',
    label:
      'Resin, Synthetic Rubber, and Artificial and Synthetic Fibers and Filaments Manufacturing',
  },
  { value: '32521', label: 'Resin and Synthetic Rubber Manufacturing' },
  { value: '325211', label: 'Plastics Material and Resin Manufacturing' },
  { value: '325212', label: 'Synthetic Rubber Manufacturing' },
  {
    value: '32522',
    label: 'Artificial and Synthetic Fibers and Filaments Manufacturing',
  },
  {
    value: '325220',
    label: 'Artificial and Synthetic Fibers and Filaments Manufacturing',
  },
  {
    value: '3253',
    label:
      'Pesticide, Fertilizer, and Other Agricultural Chemical Manufacturing',
  },
  { value: '32531', label: 'Fertilizer and Compost Manufacturing' },
  { value: '325311', label: 'Nitrogenous Fertilizer Manufacturing' },
  { value: '325312', label: 'Phosphatic Fertilizer Manufacturing' },
  { value: '325314', label: 'Fertilizer (Mixing Only) Manufacturing' },
  { value: '325315', label: 'Compost Manufacturing' },
  {
    value: '32532',
    label: 'Pesticide and Other Agricultural Chemical Manufacturing',
  },
  {
    value: '325320',
    label: 'Pesticide and Other Agricultural Chemical Manufacturing',
  },
  { value: '3254', label: 'Pharmaceutical and Medicine Manufacturing' },
  { value: '32541', label: 'Pharmaceutical and Medicine Manufacturing' },
  { value: '325411', label: 'Medicinal and Botanical Manufacturing' },
  { value: '325412', label: 'Pharmaceutical Preparation Manufacturing' },
  {
    value: '325413',
    label: 'In-Vitro Diagnostic Substance Manufacturing',
  },
  {
    value: '325414',
    label: 'Biological Product (except Diagnostic) Manufacturing',
  },
  { value: '3255', label: 'Paint, Coating, and Adhesive Manufacturing' },
  { value: '32551', label: 'Paint and Coating Manufacturing' },
  { value: '325510', label: 'Paint and Coating Manufacturing' },
  { value: '32552', label: 'Adhesive Manufacturing' },
  { value: '325520', label: 'Adhesive Manufacturing' },
  {
    value: '3256',
    label: 'Soap, Cleaning Compound, and Toilet Preparation Manufacturing',
  },
  { value: '32561', label: 'Soap and Cleaning Compound Manufacturing' },
  { value: '325611', label: 'Soap and Other Detergent Manufacturing' },
  {
    value: '325612',
    label: 'Polish and Other Sanitation Good Manufacturing',
  },
  { value: '325613', label: 'Surface Active Agent Manufacturing' },
  { value: '32562', label: 'Toilet Preparation Manufacturing' },
  { value: '325620', label: 'Toilet Preparation Manufacturing' },
  {
    value: '3259',
    label: 'Other Chemical Product and Preparation Manufacturing',
  },
  { value: '32591', label: 'Printing Ink Manufacturing' },
  { value: '325910', label: 'Printing Ink Manufacturing' },
  { value: '32592', label: 'Explosives Manufacturing' },
  { value: '325920', label: 'Explosives Manufacturing' },
  {
    value: '32599',
    label: 'All Other Chemical Product and Preparation Manufacturing',
  },
  { value: '325991', label: 'Custom Compounding of Purchased Resins' },
  {
    value: '325992',
    label:
      'Photographic Film, Paper, Plate, Chemical, and Copy Toner Manufacturing',
  },
  {
    value: '325998',
    label:
      'All Other Miscellaneous Chemical Product and Preparation Manufacturing',
  },
  { value: '326', label: 'Plastics and Rubber Products Manufacturing' },
  { value: '3261', label: 'Plastics Product Manufacturing' },
  {
    value: '32611',
    label:
      'Plastics Packaging Materials and Unlaminated Film and Sheet Manufacturing',
  },
  { value: '326111', label: 'Plastics Bag and Pouch Manufacturing' },
  {
    value: '326112',
    label:
      'Plastics Packaging Film and Sheet (including Laminated) Manufacturing',
  },
  {
    value: '326113',
    label:
      'Unlaminated Plastics Film and Sheet (except Packaging) Manufacturing',
  },
  {
    value: '32612',
    label:
      'Plastics Pipe, Pipe Fitting, and Unlaminated Profile Shape Manufacturing',
  },
  {
    value: '326121',
    label: 'Unlaminated Plastics Profile Shape Manufacturing',
  },
  {
    value: '326122',
    label: 'Plastics Pipe and Pipe Fitting Manufacturing',
  },
  {
    value: '32613',
    label:
      'Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing',
  },
  {
    value: '326130',
    label:
      'Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing',
  },
  { value: '32614', label: 'Polystyrene Foam Product Manufacturing' },
  { value: '326140', label: 'Polystyrene Foam Product Manufacturing' },
  {
    value: '32615',
    label: 'Urethane and Other Foam Product (except Polystyrene) Manufacturing',
  },
  {
    value: '326150',
    label: 'Urethane and Other Foam Product (except Polystyrene) Manufacturing',
  },
  { value: '32616', label: 'Plastics Bottle Manufacturing' },
  { value: '326160', label: 'Plastics Bottle Manufacturing' },
  { value: '32619', label: 'Other Plastics Product Manufacturing' },
  { value: '326191', label: 'Plastics Plumbing Fixture Manufacturing' },
  { value: '326199', label: 'All Other Plastics Product Manufacturing' },
  { value: '3262', label: 'Rubber Product Manufacturing' },
  { value: '32621', label: 'Tire Manufacturing' },
  { value: '326211', label: 'Tire Manufacturing (except Retreading)' },
  { value: '326212', label: 'Tire Retreading' },
  {
    value: '32622',
    label: 'Rubber and Plastics Hoses and Belting Manufacturing',
  },
  {
    value: '326220',
    label: 'Rubber and Plastics Hoses and Belting Manufacturing',
  },
  { value: '32629', label: 'Other Rubber Product Manufacturing' },
  {
    value: '326291',
    label: 'Rubber Product Manufacturing for Mechanical Use',
  },
  { value: '326299', label: 'All Other Rubber Product Manufacturing' },
  { value: '327', label: 'Nonmetallic Mineral Product Manufacturing' },
  { value: '3271', label: 'Clay Product and Refractory Manufacturing' },
  {
    value: '32711',
    label: 'Pottery, Ceramics, and Plumbing Fixture Manufacturing',
  },
  {
    value: '327110',
    label: 'Pottery, Ceramics, and Plumbing Fixture Manufacturing',
  },
  {
    value: '32712',
    label: 'Clay Building Material and Refractories Manufacturing',
  },
  {
    value: '327120',
    label: 'Clay Building Material and Refractories Manufacturing',
  },
  { value: '3272', label: 'Glass and Glass Product Manufacturing' },
  { value: '32721', label: 'Glass and Glass Product Manufacturing' },
  { value: '327211', label: 'Flat Glass Manufacturing' },
  {
    value: '327212',
    label: 'Other Pressed and Blown Glass and Glassware Manufacturing',
  },
  { value: '327213', label: 'Glass Container Manufacturing' },
  {
    value: '327215',
    label: 'Glass Product Manufacturing Made of Purchased Glass',
  },
  { value: '3273', label: 'Cement and Concrete Product Manufacturing' },
  { value: '32731', label: 'Cement Manufacturing' },
  { value: '327310', label: 'Cement Manufacturing' },
  { value: '32732', label: 'Ready-Mix Concrete Manufacturing' },
  { value: '327320', label: 'Ready-Mix Concrete Manufacturing' },
  {
    value: '32733',
    label: 'Concrete Pipe, Brick, and Block Manufacturing',
  },
  { value: '327331', label: 'Concrete Block and Brick Manufacturing' },
  { value: '327332', label: 'Concrete Pipe Manufacturing' },
  { value: '32739', label: 'Other Concrete Product Manufacturing' },
  { value: '327390', label: 'Other Concrete Product Manufacturing' },
  { value: '3274', label: 'Lime and Gypsum Product Manufacturing' },
  { value: '32741', label: 'Lime Manufacturing' },
  { value: '327410', label: 'Lime Manufacturing' },
  { value: '32742', label: 'Gypsum Product Manufacturing' },
  { value: '327420', label: 'Gypsum Product Manufacturing' },
  {
    value: '3279',
    label: 'Other Nonmetallic Mineral Product Manufacturing',
  },
  { value: '32791', label: 'Abrasive Product Manufacturing' },
  { value: '327910', label: 'Abrasive Product Manufacturing' },
  {
    value: '32799',
    label: 'All Other Nonmetallic Mineral Product Manufacturing',
  },
  { value: '327991', label: 'Cut Stone and Stone Product Manufacturing' },
  {
    value: '327992',
    label: 'Ground or Treated Mineral and Earth Manufacturing',
  },
  { value: '327993', label: 'Mineral Wool Manufacturing' },
  {
    value: '327999',
    label: 'All Other Miscellaneous Nonmetallic Mineral Product Manufacturing',
  },
  { value: '331', label: 'Primary Metal Manufacturing' },
  {
    value: '3311',
    label: 'Iron and Steel Mills and Ferroalloy Manufacturing',
  },
  {
    value: '33111',
    label: 'Iron and Steel Mills and Ferroalloy Manufacturing',
  },
  {
    value: '331110',
    label: 'Iron and Steel Mills and Ferroalloy Manufacturing',
  },
  {
    value: '3312',
    label: 'Steel Product Manufacturing from Purchased Steel',
  },
  {
    value: '33121',
    label: 'Iron and Steel Pipe and Tube Manufacturing from Purchased Steel',
  },
  {
    value: '331210',
    label: 'Iron and Steel Pipe and Tube Manufacturing from Purchased Steel',
  },
  { value: '33122', label: 'Rolling and Drawing of Purchased Steel' },
  { value: '331221', label: 'Rolled Steel Shape Manufacturing' },
  { value: '331222', label: 'Steel Wire Drawing' },
  {
    value: '3313',
    label: 'Alumina and Aluminum Production and Processing',
  },
  {
    value: '33131',
    label: 'Alumina and Aluminum Production and Processing',
  },
  {
    value: '331313',
    label: 'Alumina Refining and Primary Aluminum Production',
  },
  {
    value: '331314',
    label: 'Secondary Smelting and Alloying of Aluminum',
  },
  {
    value: '331315',
    label: 'Aluminum Sheet, Plate, and Foil Manufacturing',
  },
  {
    value: '331318',
    label: 'Other Aluminum Rolling, Drawing, and Extruding',
  },
  {
    value: '3314',
    label: 'Nonferrous Metal (except Aluminum) Production and Processing',
  },
  {
    value: '33141',
    label: 'Nonferrous Metal (except Aluminum) Smelting and Refining',
  },
  {
    value: '331410',
    label: 'Nonferrous Metal (except Aluminum) Smelting and Refining',
  },
  {
    value: '33142',
    label: 'Copper Rolling, Drawing, Extruding, and Alloying',
  },
  {
    value: '331420',
    label: 'Copper Rolling, Drawing, Extruding, and Alloying',
  },
  {
    value: '33149',
    label:
      'Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, Extruding, and Alloying',
  },
  {
    value: '331491',
    label:
      'Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, and Extruding',
  },
  {
    value: '331492',
    label:
      'Secondary Smelting, Refining, and Alloying of Nonferrous Metal (except Copper and Aluminum)',
  },
  { value: '3315', label: 'Foundries' },
  { value: '33151', label: 'Ferrous Metal Foundries' },
  { value: '331511', label: 'Iron Foundries' },
  { value: '331512', label: 'Steel Investment Foundries' },
  { value: '331513', label: 'Steel Foundries (except Investment)' },
  { value: '33152', label: 'Nonferrous Metal Foundries' },
  { value: '331523', label: 'Nonferrous Metal Die-Casting Foundries' },
  { value: '331524', label: 'Aluminum Foundries (except Die-Casting)' },
  {
    value: '331529',
    label: 'Other Nonferrous Metal Foundries (except Die-Casting)',
  },
  { value: '332', label: 'Fabricated Metal Product Manufacturing' },
  { value: '3321', label: 'Forging and Stamping' },
  { value: '33211', label: 'Forging and Stamping' },
  { value: '332111', label: 'Iron and Steel Forging' },
  { value: '332112', label: 'Nonferrous Forging' },
  { value: '332114', label: 'Custom Roll Forming' },
  { value: '332117', label: 'Powder Metallurgy Part Manufacturing' },
  {
    value: '332119',
    label: 'Metal Crown, Closure, and Other Metal Stamping (except Automotive)',
  },
  { value: '3322', label: 'Cutlery and Handtool Manufacturing' },
  { value: '33221', label: 'Cutlery and Handtool Manufacturing' },
  {
    value: '332215',
    label:
      'Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (except Precious) Manufacturing',
  },
  { value: '332216', label: 'Saw Blade and Handtool Manufacturing' },
  {
    value: '3323',
    label: 'Architectural and Structural Metals Manufacturing',
  },
  {
    value: '33231',
    label: 'Plate Work and Fabricated Structural Product Manufacturing',
  },
  {
    value: '332311',
    label: 'Prefabricated Metal Building and Component Manufacturing',
  },
  { value: '332312', label: 'Fabricated Structural Metal Manufacturing' },
  { value: '332313', label: 'Plate Work Manufacturing' },
  {
    value: '33232',
    label: 'Ornamental and Architectural Metal Products Manufacturing',
  },
  { value: '332321', label: 'Metal Window and Door Manufacturing' },
  { value: '332322', label: 'Sheet Metal Work Manufacturing' },
  {
    value: '332323',
    label: 'Ornamental and Architectural Metal Work Manufacturing',
  },
  {
    value: '3324',
    label: 'Boiler, Tank, and Shipping Container Manufacturing',
  },
  {
    value: '33241',
    label: 'Power Boiler and Heat Exchanger Manufacturing',
  },
  {
    value: '332410',
    label: 'Power Boiler and Heat Exchanger Manufacturing',
  },
  { value: '33242', label: 'Metal Tank (Heavy Gauge) Manufacturing' },
  { value: '332420', label: 'Metal Tank (Heavy Gauge) Manufacturing' },
  {
    value: '33243',
    label:
      'Metal Can, Box, and Other Metal Container (Light Gauge) Manufacturing',
  },
  { value: '332431', label: 'Metal Can Manufacturing' },
  { value: '332439', label: 'Other Metal Container Manufacturing' },
  { value: '3325', label: 'Hardware Manufacturing' },
  { value: '33251', label: 'Hardware Manufacturing' },
  { value: '332510', label: 'Hardware Manufacturing' },
  { value: '3326', label: 'Spring and Wire Product Manufacturing' },
  { value: '33261', label: 'Spring and Wire Product Manufacturing' },
  { value: '332613', label: 'Spring Manufacturing' },
  {
    value: '332618',
    label: 'Other Fabricated Wire Product Manufacturing',
  },
  {
    value: '3327',
    label:
      'Machine Shops; Turned Product; and Screw, Nut, and Bolt Manufacturing',
  },
  { value: '33271', label: 'Machine Shops' },
  { value: '332710', label: 'Machine Shops' },
  {
    value: '33272',
    label: 'Turned Product and Screw, Nut, and Bolt Manufacturing',
  },
  { value: '332721', label: 'Precision Turned Product Manufacturing' },
  {
    value: '332722',
    label: 'Bolt, Nut, Screw, Rivet, and Washer Manufacturing',
  },
  {
    value: '3328',
    label: 'Coating, Engraving, Heat Treating, and Allied Activities',
  },
  {
    value: '33281',
    label: 'Coating, Engraving, Heat Treating, and Allied Activities',
  },
  { value: '332811', label: 'Metal Heat Treating' },
  {
    value: '332812',
    label:
      'Metal Coating, Engraving (except Jewelry and Silverware), and Allied Services to Manufacturers',
  },
  {
    value: '332813',
    label: 'Electroplating, Plating, Polishing, Anodizing, and Coloring',
  },
  {
    value: '3329',
    label: 'Other Fabricated Metal Product Manufacturing',
  },
  { value: '33291', label: 'Metal Valve Manufacturing' },
  { value: '332911', label: 'Industrial Valve Manufacturing' },
  {
    value: '332912',
    label: 'Fluid Power Valve and Hose Fitting Manufacturing',
  },
  {
    value: '332913',
    label: 'Plumbing Fixture Fitting and Trim Manufacturing',
  },
  {
    value: '332919',
    label: 'Other Metal Valve and Pipe Fitting Manufacturing',
  },
  {
    value: '33299',
    label: 'All Other Fabricated Metal Product Manufacturing',
  },
  { value: '332991', label: 'Ball and Roller Bearing Manufacturing' },
  { value: '332992', label: 'Small Arms Ammunition Manufacturing' },
  {
    value: '332993',
    label: 'Ammunition (except Small Arms) Manufacturing',
  },
  {
    value: '332994',
    label: 'Small Arms, Ordnance, and Ordnance Accessories Manufacturing',
  },
  {
    value: '332996',
    label: 'Fabricated Pipe and Pipe Fitting Manufacturing',
  },
  {
    value: '332999',
    label: 'All Other Miscellaneous Fabricated Metal Product Manufacturing',
  },
  { value: '333', label: 'Machinery Manufacturing' },
  {
    value: '3331',
    label: 'Agriculture, Construction, and Mining Machinery Manufacturing',
  },
  { value: '33311', label: 'Agricultural Implement Manufacturing' },
  {
    value: '333111',
    label: 'Farm Machinery and Equipment Manufacturing',
  },
  {
    value: '333112',
    label:
      'Lawn and Garden Tractor and Home Lawn and Garden Equipment Manufacturing',
  },
  { value: '33312', label: 'Construction Machinery Manufacturing' },
  { value: '333120', label: 'Construction Machinery Manufacturing' },
  {
    value: '33313',
    label: 'Mining and Oil and Gas Field Machinery Manufacturing',
  },
  {
    value: '333131',
    label: 'Mining Machinery and Equipment Manufacturing',
  },
  {
    value: '333132',
    label: 'Oil and Gas Field Machinery and Equipment Manufacturing',
  },
  { value: '3332', label: 'Industrial Machinery Manufacturing' },
  { value: '33324', label: 'Industrial Machinery Manufacturing' },
  { value: '333241', label: 'Food Product Machinery Manufacturing' },
  { value: '333242', label: 'Semiconductor Machinery Manufacturing' },
  {
    value: '333243',
    label: 'Sawmill, Woodworking, and Paper Machinery Manufacturing',
  },
  {
    value: '333248',
    label: 'All Other Industrial Machinery Manufacturing',
  },
  {
    value: '3333',
    label: 'Commercial and Service Industry Machinery Manufacturing',
  },
  {
    value: '33331',
    label: 'Commercial and Service Industry Machinery Manufacturing',
  },
  {
    value: '333310',
    label: 'Commercial and Service Industry Machinery Manufacturing',
  },
  {
    value: '3334',
    label:
      'Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing',
  },
  {
    value: '33341',
    label:
      'Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing',
  },
  {
    value: '333413',
    label:
      'Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing',
  },
  {
    value: '333414',
    label: 'Heating Equipment (except Warm Air Furnaces) Manufacturing',
  },
  {
    value: '333415',
    label:
      'Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Manufacturing',
  },
  { value: '3335', label: 'Metalworking Machinery Manufacturing' },
  { value: '33351', label: 'Metalworking Machinery Manufacturing' },
  { value: '333511', label: 'Industrial Mold Manufacturing' },
  {
    value: '333514',
    label: 'Special Die and Tool, Die Set, Jig, and Fixture Manufacturing',
  },
  {
    value: '333515',
    label: 'Cutting Tool and Machine Tool Accessory Manufacturing',
  },
  { value: '333517', label: 'Machine Tool Manufacturing' },
  {
    value: '333519',
    label: 'Rolling Mill and Other Metalworking Machinery Manufacturing',
  },
  {
    value: '3336',
    label: 'Engine, Turbine, and Power Transmission Equipment Manufacturing',
  },
  {
    value: '33361',
    label: 'Engine, Turbine, and Power Transmission Equipment Manufacturing',
  },
  {
    value: '333611',
    label: 'Turbine and Turbine Generator Set Units Manufacturing',
  },
  {
    value: '333612',
    label: 'Speed Changer, Industrial High-Speed Drive, and Gear Manufacturing',
  },
  {
    value: '333613',
    label: 'Mechanical Power Transmission Equipment Manufacturing',
  },
  { value: '333618', label: 'Other Engine Equipment Manufacturing' },
  {
    value: '3339',
    label: 'Other General Purpose Machinery Manufacturing',
  },
  { value: '33391', label: 'Pump and Compressor Manufacturing' },
  { value: '333912', label: 'Air and Gas Compressor Manufacturing' },
  {
    value: '333914',
    label: 'Measuring, Dispensing, and Other Pumping Equipment Manufacturing',
  },
  { value: '33392', label: 'Material Handling Equipment Manufacturing' },
  {
    value: '333921',
    label: 'Elevator and Moving Stairway Manufacturing',
  },
  {
    value: '333922',
    label: 'Conveyor and Conveying Equipment Manufacturing',
  },
  {
    value: '333923',
    label: 'Overhead Traveling Crane, Hoist, and Monorail System Manufacturing',
  },
  {
    value: '333924',
    label:
      'Industrial Truck, Tractor, Trailer, and Stacker Machinery Manufacturing',
  },
  {
    value: '33399',
    label: 'All Other General Purpose Machinery Manufacturing',
  },
  { value: '333991', label: 'Power-Driven Handtool Manufacturing' },
  {
    value: '333992',
    label: 'Welding and Soldering Equipment Manufacturing',
  },
  { value: '333993', label: 'Packaging Machinery Manufacturing' },
  {
    value: '333994',
    label: 'Industrial Process Furnace and Oven Manufacturing',
  },
  {
    value: '333995',
    label: 'Fluid Power Cylinder and Actuator Manufacturing',
  },
  { value: '333996', label: 'Fluid Power Pump and Motor Manufacturing' },
  {
    value: '333998',
    label: 'All Other Miscellaneous General Purpose Machinery Manufacturing',
  },
  {
    value: '334',
    label: 'Computer and Electronic Product Manufacturing',
  },
  {
    value: '3341',
    label: 'Computer and Peripheral Equipment Manufacturing',
  },
  {
    value: '33411',
    label: 'Computer and Peripheral Equipment Manufacturing',
  },
  { value: '334111', label: 'Electronic Computer Manufacturing' },
  { value: '334112', label: 'Computer Storage Device Manufacturing' },
  {
    value: '334118',
    label:
      'Computer Terminal and Other Computer Peripheral Equipment Manufacturing',
  },
  { value: '3342', label: 'Communications Equipment Manufacturing' },
  { value: '33421', label: 'Telephone Apparatus Manufacturing' },
  { value: '334210', label: 'Telephone Apparatus Manufacturing' },
  {
    value: '33422',
    label:
      'Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing',
  },
  {
    value: '334220',
    label:
      'Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing',
  },
  {
    value: '33429',
    label: 'Other Communications Equipment Manufacturing',
  },
  {
    value: '334290',
    label: 'Other Communications Equipment Manufacturing',
  },
  { value: '3343', label: 'Audio and Video Equipment Manufacturing' },
  { value: '33431', label: 'Audio and Video Equipment Manufacturing' },
  { value: '334310', label: 'Audio and Video Equipment Manufacturing' },
  {
    value: '3344',
    label: 'Semiconductor and Other Electronic Component Manufacturing',
  },
  {
    value: '33441',
    label: 'Semiconductor and Other Electronic Component Manufacturing',
  },
  { value: '334412', label: 'Bare Printed Circuit Board Manufacturing' },
  {
    value: '334413',
    label: 'Semiconductor and Related Device Manufacturing',
  },
  {
    value: '334416',
    label:
      'Capacitor, Resistor, Coil, Transformer, and Other Inductor Manufacturing',
  },
  { value: '334417', label: 'Electronic Connector Manufacturing' },
  {
    value: '334418',
    label: 'Printed Circuit Assembly (Electronic Assembly) Manufacturing',
  },
  { value: '334419', label: 'Other Electronic Component Manufacturing' },
  {
    value: '3345',
    label:
      'Navigational, Measuring, Electromedical, and Control Instruments Manufacturing',
  },
  {
    value: '33451',
    label:
      'Navigational, Measuring, Electromedical, and Control Instruments Manufacturing',
  },
  {
    value: '334510',
    label: 'Electromedical and Electrotherapeutic Apparatus Manufacturing',
  },
  {
    value: '334511',
    label:
      'Search, Detection, Navigation, Guidance, Aeronautical, and Nautical System and Instrument Manufacturing',
  },
  {
    value: '334512',
    label:
      'Automatic Environmental Control Manufacturing for Residential, Commercial, and Appliance Use',
  },
  {
    value: '334513',
    label:
      'Instruments and Related Products Manufacturing for Measuring, Displaying, and Controlling Industrial Process Variables',
  },
  {
    value: '334514',
    label: 'Totalizing Fluid Meter and Counting Device Manufacturing',
  },
  {
    value: '334515',
    label:
      'Instrument Manufacturing for Measuring and Testing Electricity and Electrical Signals',
  },
  {
    value: '334516',
    label: 'Analytical Laboratory Instrument Manufacturing',
  },
  { value: '334517', label: 'Irradiation Apparatus Manufacturing' },
  {
    value: '334519',
    label: 'Other Measuring and Controlling Device Manufacturing',
  },
  {
    value: '3346',
    label: 'Manufacturing and Reproducing Magnetic and Optical Media',
  },
  {
    value: '33461',
    label: 'Manufacturing and Reproducing Magnetic and Optical Media',
  },
  {
    value: '334610',
    label: 'Manufacturing and Reproducing Magnetic and Optical Media',
  },
  {
    value: '335',
    label: 'Electrical Equipment, Appliance, and Component Manufacturing',
  },
  { value: '3351', label: 'Electric Lighting Equipment Manufacturing' },
  { value: '33513', label: 'Electric Lighting Equipment Manufacturing' },
  {
    value: '335131',
    label: 'Residential Electric Lighting Fixture Manufacturing',
  },
  {
    value: '335132',
    label:
      'Commercial, Industrial, and Institutional Electric Lighting Fixture Manufacturing',
  },
  {
    value: '335139',
    label: 'Electric Lamp Bulb and Other Lighting Equipment Manufacturing',
  },
  { value: '3352', label: 'Household Appliance Manufacturing' },
  { value: '33521', label: 'Small Electrical Appliance Manufacturing' },
  { value: '335210', label: 'Small Electrical Appliance Manufacturing' },
  { value: '33522', label: 'Major Household Appliance Manufacturing' },
  { value: '335220', label: 'Major Household Appliance Manufacturing' },
  { value: '3353', label: 'Electrical Equipment Manufacturing' },
  { value: '33531', label: 'Electrical Equipment Manufacturing' },
  {
    value: '335311',
    label: 'Power, Distribution, and Specialty Transformer Manufacturing',
  },
  { value: '335312', label: 'Motor and Generator Manufacturing' },
  {
    value: '335313',
    label: 'Switchgear and Switchboard Apparatus Manufacturing',
  },
  {
    value: '335314',
    label: 'Relay and Industrial Control Manufacturing',
  },
  {
    value: '3359',
    label: 'Other Electrical Equipment and Component Manufacturing',
  },
  { value: '33591', label: 'Battery Manufacturing' },
  { value: '335910', label: 'Battery Manufacturing' },
  {
    value: '33592',
    label: 'Communication and Energy Wire and Cable Manufacturing',
  },
  { value: '335921', label: 'Fiber Optic Cable Manufacturing' },
  {
    value: '335929',
    label: 'Other Communication and Energy Wire Manufacturing',
  },
  { value: '33593', label: 'Wiring Device Manufacturing' },
  {
    value: '335931',
    label: 'Current-Carrying Wiring Device Manufacturing',
  },
  {
    value: '335932',
    label: 'Noncurrent-Carrying Wiring Device Manufacturing',
  },
  {
    value: '33599',
    label: 'All Other Electrical Equipment and Component Manufacturing',
  },
  { value: '335991', label: 'Carbon and Graphite Product Manufacturing' },
  {
    value: '335999',
    label:
      'All Other Miscellaneous Electrical Equipment and Component Manufacturing',
  },
  { value: '336', label: 'Transportation Equipment Manufacturing' },
  { value: '3361', label: 'Motor Vehicle Manufacturing' },
  {
    value: '33611',
    label: 'Automobile and Light Duty Motor Vehicle Manufacturing',
  },
  {
    value: '336110',
    label: 'Automobile and Light Duty Motor Vehicle Manufacturing',
  },
  { value: '33612', label: 'Heavy Duty Truck Manufacturing' },
  { value: '336120', label: 'Heavy Duty Truck Manufacturing' },
  {
    value: '3362',
    label: 'Motor Vehicle Body and Trailer Manufacturing',
  },
  {
    value: '33621',
    label: 'Motor Vehicle Body and Trailer Manufacturing',
  },
  { value: '336211', label: 'Motor Vehicle Body Manufacturing' },
  { value: '336212', label: 'Truck Trailer Manufacturing' },
  { value: '336213', label: 'Motor Home Manufacturing' },
  { value: '336214', label: 'Travel Trailer and Camper Manufacturing' },
  { value: '3363', label: 'Motor Vehicle Parts Manufacturing' },
  {
    value: '33631',
    label: 'Motor Vehicle Gasoline Engine and Engine Parts Manufacturing',
  },
  {
    value: '336310',
    label: 'Motor Vehicle Gasoline Engine and Engine Parts Manufacturing',
  },
  {
    value: '33632',
    label: 'Motor Vehicle Electrical and Electronic Equipment Manufacturing',
  },
  {
    value: '336320',
    label: 'Motor Vehicle Electrical and Electronic Equipment Manufacturing',
  },
  {
    value: '33633',
    label:
      'Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing',
  },
  {
    value: '336330',
    label:
      'Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing',
  },
  { value: '33634', label: 'Motor Vehicle Brake System Manufacturing' },
  { value: '336340', label: 'Motor Vehicle Brake System Manufacturing' },
  {
    value: '33635',
    label: 'Motor Vehicle Transmission and Power Train Parts Manufacturing',
  },
  {
    value: '336350',
    label: 'Motor Vehicle Transmission and Power Train Parts Manufacturing',
  },
  {
    value: '33636',
    label: 'Motor Vehicle Seating and Interior Trim Manufacturing',
  },
  {
    value: '336360',
    label: 'Motor Vehicle Seating and Interior Trim Manufacturing',
  },
  { value: '33637', label: 'Motor Vehicle Metal Stamping' },
  { value: '336370', label: 'Motor Vehicle Metal Stamping' },
  { value: '33639', label: 'Other Motor Vehicle Parts Manufacturing' },
  { value: '336390', label: 'Other Motor Vehicle Parts Manufacturing' },
  { value: '3364', label: 'Aerospace Product and Parts Manufacturing' },
  { value: '33641', label: 'Aerospace Product and Parts Manufacturing' },
  { value: '336411', label: 'Aircraft Manufacturing' },
  {
    value: '336412',
    label: 'Aircraft Engine and Engine Parts Manufacturing',
  },
  {
    value: '336413',
    label: 'Other Aircraft Parts and Auxiliary Equipment Manufacturing',
  },
  {
    value: '336414',
    label: 'Guided Missile and Space Vehicle Manufacturing',
  },
  {
    value: '336415',
    label:
      'Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Manufacturing',
  },
  {
    value: '336419',
    label:
      'Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Manufacturing',
  },
  { value: '3365', label: 'Railroad Rolling Stock Manufacturing' },
  { value: '33651', label: 'Railroad Rolling Stock Manufacturing' },
  { value: '336510', label: 'Railroad Rolling Stock Manufacturing' },
  { value: '3366', label: 'Ship and Boat Building' },
  { value: '33661', label: 'Ship and Boat Building' },
  { value: '336611', label: 'Ship Building and Repairing' },
  { value: '336612', label: 'Boat Building' },
  {
    value: '3369',
    label: 'Other Transportation Equipment Manufacturing',
  },
  {
    value: '33699',
    label: 'Other Transportation Equipment Manufacturing',
  },
  {
    value: '336991',
    label: 'Motorcycle, Bicycle, and Parts Manufacturing',
  },
  {
    value: '336992',
    label: 'Military Armored Vehicle, Tank, and Tank Component Manufacturing',
  },
  {
    value: '336999',
    label: 'All Other Transportation Equipment Manufacturing',
  },
  { value: '337', label: 'Furniture and Related Product Manufacturing' },
  {
    value: '3371',
    label:
      'Household and Institutional Furniture and Kitchen Cabinet Manufacturing',
  },
  {
    value: '33711',
    label: 'Wood Kitchen Cabinet and Countertop Manufacturing',
  },
  {
    value: '337110',
    label: 'Wood Kitchen Cabinet and Countertop Manufacturing',
  },
  {
    value: '33712',
    label: 'Household and Institutional Furniture Manufacturing',
  },
  {
    value: '337121',
    label: 'Upholstered Household Furniture Manufacturing',
  },
  {
    value: '337122',
    label: 'Nonupholstered Wood Household Furniture Manufacturing',
  },
  {
    value: '337126',
    label: 'Household Furniture (except Wood and Upholstered) Manufacturing',
  },
  { value: '337127', label: 'Institutional Furniture Manufacturing' },
  {
    value: '3372',
    label: 'Office Furniture (including Fixtures) Manufacturing',
  },
  {
    value: '33721',
    label: 'Office Furniture (including Fixtures) Manufacturing',
  },
  { value: '337211', label: 'Wood Office Furniture Manufacturing' },
  {
    value: '337212',
    label: 'Custom Architectural Woodwork and Millwork Manufacturing',
  },
  {
    value: '337214',
    label: 'Office Furniture (except Wood) Manufacturing',
  },
  {
    value: '337215',
    label: 'Showcase, Partition, Shelving, and Locker Manufacturing',
  },
  {
    value: '3379',
    label: 'Other Furniture Related Product Manufacturing',
  },
  { value: '33791', label: 'Mattress Manufacturing' },
  { value: '337910', label: 'Mattress Manufacturing' },
  { value: '33792', label: 'Blind and Shade Manufacturing' },
  { value: '337920', label: 'Blind and Shade Manufacturing' },
  { value: '339', label: 'Miscellaneous Manufacturing' },
  {
    value: '3391',
    label: 'Medical Equipment and Supplies Manufacturing',
  },
  {
    value: '33911',
    label: 'Medical Equipment and Supplies Manufacturing',
  },
  {
    value: '339112',
    label: 'Surgical and Medical Instrument Manufacturing',
  },
  {
    value: '339113',
    label: 'Surgical Appliance and Supplies Manufacturing',
  },
  {
    value: '339114',
    label: 'Dental Equipment and Supplies Manufacturing',
  },
  { value: '339115', label: 'Ophthalmic Goods Manufacturing' },
  { value: '339116', label: 'Dental Laboratories' },
  { value: '3399', label: 'Other Miscellaneous Manufacturing' },
  { value: '33991', label: 'Jewelry and Silverware Manufacturing' },
  { value: '339910', label: 'Jewelry and Silverware Manufacturing' },
  { value: '33992', label: 'Sporting and Athletic Goods Manufacturing' },
  { value: '339920', label: 'Sporting and Athletic Goods Manufacturing' },
  { value: '33993', label: 'Doll, Toy, and Game Manufacturing' },
  { value: '339930', label: 'Doll, Toy, and Game Manufacturing' },
  {
    value: '33994',
    label: 'Office Supplies (except Paper) Manufacturing',
  },
  {
    value: '339940',
    label: 'Office Supplies (except Paper) Manufacturing',
  },
  { value: '33995', label: 'Sign Manufacturing' },
  { value: '339950', label: 'Sign Manufacturing' },
  { value: '33999', label: 'All Other Miscellaneous Manufacturing' },
  {
    value: '339991',
    label: 'Gasket, Packing, and Sealing Device Manufacturing',
  },
  { value: '339992', label: 'Musical Instrument Manufacturing' },
  {
    value: '339993',
    label: 'Fastener, Button, Needle, and Pin Manufacturing',
  },
  { value: '339994', label: 'Broom, Brush, and Mop Manufacturing' },
  { value: '339995', label: 'Burial Casket Manufacturing' },
  { value: '339999', label: 'All Other Miscellaneous Manufacturing' },
  { value: '42', label: 'Wholesale Trade' },
  { value: '423', label: 'Merchant Wholesalers, Durable Goods' },
  {
    value: '4231',
    label:
      'Motor Vehicle and Motor Vehicle Parts and Supplies Merchant Wholesalers',
  },
  {
    value: '42311',
    label: 'Automobile and Other Motor Vehicle Merchant Wholesalers',
  },
  {
    value: '423110',
    label: 'Automobile and Other Motor Vehicle Merchant Wholesalers',
  },
  {
    value: '42312',
    label: 'Motor Vehicle Supplies and New Parts Merchant Wholesalers',
  },
  {
    value: '423120',
    label: 'Motor Vehicle Supplies and New Parts Merchant Wholesalers',
  },
  { value: '42313', label: 'Tire and Tube Merchant Wholesalers' },
  { value: '423130', label: 'Tire and Tube Merchant Wholesalers' },
  {
    value: '42314',
    label: 'Motor Vehicle Parts (Used) Merchant Wholesalers',
  },
  {
    value: '423140',
    label: 'Motor Vehicle Parts (Used) Merchant Wholesalers',
  },
  {
    value: '4232',
    label: 'Furniture and Home Furnishing Merchant Wholesalers',
  },
  { value: '42321', label: 'Furniture Merchant Wholesalers' },
  { value: '423210', label: 'Furniture Merchant Wholesalers' },
  { value: '42322', label: 'Home Furnishing Merchant Wholesalers' },
  { value: '423220', label: 'Home Furnishing Merchant Wholesalers' },
  {
    value: '4233',
    label: 'Lumber and Other Construction Materials Merchant Wholesalers',
  },
  {
    value: '42331',
    label: 'Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers',
  },
  {
    value: '423310',
    label: 'Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers',
  },
  {
    value: '42332',
    label:
      'Brick, Stone, and Related Construction Material Merchant Wholesalers',
  },
  {
    value: '423320',
    label:
      'Brick, Stone, and Related Construction Material Merchant Wholesalers',
  },
  {
    value: '42333',
    label: 'Roofing, Siding, and Insulation Material Merchant Wholesalers',
  },
  {
    value: '423330',
    label: 'Roofing, Siding, and Insulation Material Merchant Wholesalers',
  },
  {
    value: '42339',
    label: 'Other Construction Material Merchant Wholesalers',
  },
  {
    value: '423390',
    label: 'Other Construction Material Merchant Wholesalers',
  },
  {
    value: '4234',
    label:
      'Professional and Commercial Equipment and Supplies Merchant Wholesalers',
  },
  {
    value: '42341',
    label: 'Photographic Equipment and Supplies Merchant Wholesalers',
  },
  {
    value: '423410',
    label: 'Photographic Equipment and Supplies Merchant Wholesalers',
  },
  { value: '42342', label: 'Office Equipment Merchant Wholesalers' },
  { value: '423420', label: 'Office Equipment Merchant Wholesalers' },
  {
    value: '42343',
    label:
      'Computer and Computer Peripheral Equipment and Software Merchant Wholesalers',
  },
  {
    value: '423430',
    label:
      'Computer and Computer Peripheral Equipment and Software Merchant Wholesalers',
  },
  {
    value: '42344',
    label: 'Other Commercial Equipment Merchant Wholesalers',
  },
  {
    value: '423440',
    label: 'Other Commercial Equipment Merchant Wholesalers',
  },
  {
    value: '42345',
    label:
      'Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers',
  },
  {
    value: '423450',
    label:
      'Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers',
  },
  { value: '42346', label: 'Ophthalmic Goods Merchant Wholesalers' },
  { value: '423460', label: 'Ophthalmic Goods Merchant Wholesalers' },
  {
    value: '42349',
    label: 'Other Professional Equipment and Supplies Merchant Wholesalers',
  },
  {
    value: '423490',
    label: 'Other Professional Equipment and Supplies Merchant Wholesalers',
  },
  {
    value: '4235',
    label: 'Metal and Mineral (except Petroleum) Merchant Wholesalers',
  },
  {
    value: '42351',
    label: 'Metal Service Centers and Other Metal Merchant Wholesalers',
  },
  {
    value: '423510',
    label: 'Metal Service Centers and Other Metal Merchant Wholesalers',
  },
  {
    value: '42352',
    label: 'Coal and Other Mineral and Ore Merchant Wholesalers',
  },
  {
    value: '423520',
    label: 'Coal and Other Mineral and Ore Merchant Wholesalers',
  },
  {
    value: '4236',
    label:
      'Household Appliances and Electrical and Electronic Goods Merchant Wholesalers',
  },
  {
    value: '42361',
    label:
      'Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers',
  },
  {
    value: '423610',
    label:
      'Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers',
  },
  {
    value: '42362',
    label:
      'Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers',
  },
  {
    value: '423620',
    label:
      'Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers',
  },
  {
    value: '42369',
    label: 'Other Electronic Parts and Equipment Merchant Wholesalers',
  },
  {
    value: '423690',
    label: 'Other Electronic Parts and Equipment Merchant Wholesalers',
  },
  {
    value: '4237',
    label:
      'Hardware, and Plumbing and Heating Equipment and Supplies Merchant Wholesalers',
  },
  { value: '42371', label: 'Hardware Merchant Wholesalers' },
  { value: '423710', label: 'Hardware Merchant Wholesalers' },
  {
    value: '42372',
    label:
      'Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers',
  },
  {
    value: '423720',
    label:
      'Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers',
  },
  {
    value: '42373',
    label:
      'Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers',
  },
  {
    value: '423730',
    label:
      'Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers',
  },
  {
    value: '42374',
    label: 'Refrigeration Equipment and Supplies Merchant Wholesalers',
  },
  {
    value: '423740',
    label: 'Refrigeration Equipment and Supplies Merchant Wholesalers',
  },
  {
    value: '4238',
    label: 'Machinery, Equipment, and Supplies Merchant Wholesalers',
  },
  {
    value: '42381',
    label:
      'Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers',
  },
  {
    value: '423810',
    label:
      'Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers',
  },
  {
    value: '42382',
    label: 'Farm and Garden Machinery and Equipment Merchant Wholesalers',
  },
  {
    value: '423820',
    label: 'Farm and Garden Machinery and Equipment Merchant Wholesalers',
  },
  {
    value: '42383',
    label: 'Industrial Machinery and Equipment Merchant Wholesalers',
  },
  {
    value: '423830',
    label: 'Industrial Machinery and Equipment Merchant Wholesalers',
  },
  { value: '42384', label: 'Industrial Supplies Merchant Wholesalers' },
  { value: '423840', label: 'Industrial Supplies Merchant Wholesalers' },
  {
    value: '42385',
    label: 'Service Establishment Equipment and Supplies Merchant Wholesalers',
  },
  {
    value: '423850',
    label: 'Service Establishment Equipment and Supplies Merchant Wholesalers',
  },
  {
    value: '42386',
    label:
      'Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers',
  },
  {
    value: '423860',
    label:
      'Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers',
  },
  {
    value: '4239',
    label: 'Miscellaneous Durable Goods Merchant Wholesalers',
  },
  {
    value: '42391',
    label: 'Sporting and Recreational Goods and Supplies Merchant Wholesalers',
  },
  {
    value: '423910',
    label: 'Sporting and Recreational Goods and Supplies Merchant Wholesalers',
  },
  {
    value: '42392',
    label: 'Toy and Hobby Goods and Supplies Merchant Wholesalers',
  },
  {
    value: '423920',
    label: 'Toy and Hobby Goods and Supplies Merchant Wholesalers',
  },
  { value: '42393', label: 'Recyclable Material Merchant Wholesalers' },
  { value: '423930', label: 'Recyclable Material Merchant Wholesalers' },
  {
    value: '42394',
    label:
      'Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers',
  },
  {
    value: '423940',
    label:
      'Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers',
  },
  {
    value: '42399',
    label: 'Other Miscellaneous Durable Goods Merchant Wholesalers',
  },
  {
    value: '423990',
    label: 'Other Miscellaneous Durable Goods Merchant Wholesalers',
  },
  { value: '424', label: 'Merchant Wholesalers, Nondurable Goods' },
  {
    value: '4241',
    label: 'Paper and Paper Product Merchant Wholesalers',
  },
  {
    value: '42411',
    label: 'Printing and Writing Paper Merchant Wholesalers',
  },
  {
    value: '424110',
    label: 'Printing and Writing Paper Merchant Wholesalers',
  },
  {
    value: '42412',
    label: 'Stationery and Office Supplies Merchant Wholesalers',
  },
  {
    value: '424120',
    label: 'Stationery and Office Supplies Merchant Wholesalers',
  },
  {
    value: '42413',
    label: 'Industrial and Personal Service Paper Merchant Wholesalers',
  },
  {
    value: '424130',
    label: 'Industrial and Personal Service Paper Merchant Wholesalers',
  },
  {
    value: '4242',
    label: "Drugs and Druggists' Sundries Merchant Wholesalers",
  },
  {
    value: '42421',
    label: "Drugs and Druggists' Sundries Merchant Wholesalers",
  },
  {
    value: '424210',
    label: "Drugs and Druggists' Sundries Merchant Wholesalers",
  },
  {
    value: '4243',
    label: 'Apparel, Piece Goods, and Notions Merchant Wholesalers',
  },
  {
    value: '42431',
    label: 'Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers',
  },
  {
    value: '424310',
    label: 'Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers',
  },
  { value: '42434', label: 'Footwear Merchant Wholesalers' },
  { value: '424340', label: 'Footwear Merchant Wholesalers' },
  {
    value: '42435',
    label: 'Clothing and Clothing Accessories Merchant Wholesalers',
  },
  {
    value: '424350',
    label: 'Clothing and Clothing Accessories Merchant Wholesalers',
  },
  {
    value: '4244',
    label: 'Grocery and Related Product Merchant Wholesalers',
  },
  { value: '42441', label: 'General Line Grocery Merchant Wholesalers' },
  { value: '424410', label: 'General Line Grocery Merchant Wholesalers' },
  { value: '42442', label: 'Packaged Frozen Food Merchant Wholesalers' },
  { value: '424420', label: 'Packaged Frozen Food Merchant Wholesalers' },
  {
    value: '42443',
    label: 'Dairy Product (except Dried or Canned) Merchant Wholesalers',
  },
  {
    value: '424430',
    label: 'Dairy Product (except Dried or Canned) Merchant Wholesalers',
  },
  {
    value: '42444',
    label: 'Poultry and Poultry Product Merchant Wholesalers',
  },
  {
    value: '424440',
    label: 'Poultry and Poultry Product Merchant Wholesalers',
  },
  { value: '42445', label: 'Confectionery Merchant Wholesalers' },
  { value: '424450', label: 'Confectionery Merchant Wholesalers' },
  { value: '42446', label: 'Fish and Seafood Merchant Wholesalers' },
  { value: '424460', label: 'Fish and Seafood Merchant Wholesalers' },
  { value: '42447', label: 'Meat and Meat Product Merchant Wholesalers' },
  {
    value: '424470',
    label: 'Meat and Meat Product Merchant Wholesalers',
  },
  {
    value: '42448',
    label: 'Fresh Fruit and Vegetable Merchant Wholesalers',
  },
  {
    value: '424480',
    label: 'Fresh Fruit and Vegetable Merchant Wholesalers',
  },
  {
    value: '42449',
    label: 'Other Grocery and Related Products Merchant Wholesalers',
  },
  {
    value: '424490',
    label: 'Other Grocery and Related Products Merchant Wholesalers',
  },
  {
    value: '4245',
    label: 'Farm Product Raw Material Merchant Wholesalers',
  },
  { value: '42451', label: 'Grain and Field Bean Merchant Wholesalers' },
  { value: '424510', label: 'Grain and Field Bean Merchant Wholesalers' },
  { value: '42452', label: 'Livestock Merchant Wholesalers' },
  { value: '424520', label: 'Livestock Merchant Wholesalers' },
  {
    value: '42459',
    label: 'Other Farm Product Raw Material Merchant Wholesalers',
  },
  {
    value: '424590',
    label: 'Other Farm Product Raw Material Merchant Wholesalers',
  },
  {
    value: '4246',
    label: 'Chemical and Allied Products Merchant Wholesalers',
  },
  {
    value: '42461',
    label: 'Plastics Materials and Basic Forms and Shapes Merchant Wholesalers',
  },
  {
    value: '424610',
    label: 'Plastics Materials and Basic Forms and Shapes Merchant Wholesalers',
  },
  {
    value: '42469',
    label: 'Other Chemical and Allied Products Merchant Wholesalers',
  },
  {
    value: '424690',
    label: 'Other Chemical and Allied Products Merchant Wholesalers',
  },
  {
    value: '4247',
    label: 'Petroleum and Petroleum Products Merchant Wholesalers',
  },
  { value: '42471', label: 'Petroleum Bulk Stations and Terminals' },
  { value: '424710', label: 'Petroleum Bulk Stations and Terminals' },
  {
    value: '42472',
    label:
      'Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)',
  },
  {
    value: '424720',
    label:
      'Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)',
  },
  {
    value: '4248',
    label: 'Beer, Wine, and Distilled Alcoholic Beverage Merchant Wholesalers',
  },
  { value: '42481', label: 'Beer and Ale Merchant Wholesalers' },
  { value: '424810', label: 'Beer and Ale Merchant Wholesalers' },
  {
    value: '42482',
    label: 'Wine and Distilled Alcoholic Beverage Merchant Wholesalers',
  },
  {
    value: '424820',
    label: 'Wine and Distilled Alcoholic Beverage Merchant Wholesalers',
  },
  {
    value: '4249',
    label: 'Miscellaneous Nondurable Goods Merchant Wholesalers',
  },
  { value: '42491', label: 'Farm Supplies Merchant Wholesalers' },
  { value: '424910', label: 'Farm Supplies Merchant Wholesalers' },
  {
    value: '42492',
    label: 'Book, Periodical, and Newspaper Merchant Wholesalers',
  },
  {
    value: '424920',
    label: 'Book, Periodical, and Newspaper Merchant Wholesalers',
  },
  {
    value: '42493',
    label: "Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers",
  },
  {
    value: '424930',
    label: "Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers",
  },
  {
    value: '42494',
    label: 'Tobacco Product and Electronic Cigarette Merchant Wholesalers',
  },
  {
    value: '424940',
    label: 'Tobacco Product and Electronic Cigarette Merchant Wholesalers',
  },
  {
    value: '42495',
    label: 'Paint, Varnish, and Supplies Merchant Wholesalers',
  },
  {
    value: '424950',
    label: 'Paint, Varnish, and Supplies Merchant Wholesalers',
  },
  {
    value: '42499',
    label: 'Other Miscellaneous Nondurable Goods Merchant Wholesalers',
  },
  {
    value: '424990',
    label: 'Other Miscellaneous Nondurable Goods Merchant Wholesalers',
  },
  { value: '425', label: 'Wholesale Trade Agents and Brokers' },
  { value: '4251', label: 'Wholesale Trade Agents and Brokers' },
  { value: '42512', label: 'Wholesale Trade Agents and Brokers' },
  { value: '425120', label: 'Wholesale Trade Agents and Brokers' },
  { value: '44-45', label: 'Retail Trade' },
  { value: '441', label: 'Motor Vehicle and Parts Dealers' },
  { value: '4411', label: 'Automobile Dealers' },
  { value: '44111', label: 'New Car Dealers' },
  { value: '441110', label: 'New Car Dealers' },
  { value: '44112', label: 'Used Car Dealers' },
  { value: '441120', label: 'Used Car Dealers' },
  { value: '4412', label: 'Other Motor Vehicle Dealers' },
  { value: '44121', label: 'Recreational Vehicle Dealers' },
  { value: '441210', label: 'Recreational Vehicle Dealers' },
  {
    value: '44122',
    label: 'Motorcycle, Boat, and Other Motor Vehicle Dealers',
  },
  { value: '441222', label: 'Boat Dealers' },
  {
    value: '441227',
    label: 'Motorcycle, ATV, and All Other Motor Vehicle Dealers',
  },
  {
    value: '4413',
    label: 'Automotive Parts, Accessories, and Tire Retailers',
  },
  { value: '44133', label: 'Automotive Parts and Accessories Retailers' },
  {
    value: '441330',
    label: 'Automotive Parts and Accessories Retailers',
  },
  { value: '44134', label: 'Tire Dealers' },
  { value: '441340', label: 'Tire Dealers' },
  {
    value: '444',
    label: 'Building Material and Garden Equipment and Supplies Dealers',
  },
  { value: '4441', label: 'Building Material and Supplies Dealers' },
  { value: '44411', label: 'Home Centers' },
  { value: '444110', label: 'Home Centers' },
  { value: '44412', label: 'Paint and Wallpaper Retailers' },
  { value: '444120', label: 'Paint and Wallpaper Retailers' },
  { value: '44414', label: 'Hardware Retailers' },
  { value: '444140', label: 'Hardware Retailers' },
  { value: '44418', label: 'Other Building Material Dealers' },
  { value: '444180', label: 'Other Building Material Dealers' },
  {
    value: '4442',
    label: 'Lawn and Garden Equipment and Supplies Retailers',
  },
  { value: '44423', label: 'Outdoor Power Equipment Retailers' },
  { value: '444230', label: 'Outdoor Power Equipment Retailers' },
  {
    value: '44424',
    label: 'Nursery, Garden Center, and Farm Supply Retailers',
  },
  {
    value: '444240',
    label: 'Nursery, Garden Center, and Farm Supply Retailers',
  },
  { value: '445', label: 'Food and Beverage Retailers' },
  { value: '4451', label: 'Grocery and Convenience Retailers' },
  {
    value: '44511',
    label:
      'Supermarkets and Other Grocery Retailers (except Convenience Retailers)',
  },
  {
    value: '445110',
    label:
      'Supermarkets and Other Grocery Retailers (except Convenience Retailers)',
  },
  {
    value: '44513',
    label: 'Convenience Retailers and Vending Machine Operators',
  },
  { value: '445131', label: 'Convenience Retailers' },
  { value: '445132', label: 'Vending Machine Operators' },
  { value: '4452', label: 'Specialty Food Retailers' },
  { value: '44523', label: 'Fruit and Vegetable Retailers' },
  { value: '445230', label: 'Fruit and Vegetable Retailers' },
  { value: '44524', label: 'Meat Retailers' },
  { value: '445240', label: 'Meat Retailers' },
  { value: '44525', label: 'Fish and Seafood Retailers' },
  { value: '445250', label: 'Fish and Seafood Retailers' },
  { value: '44529', label: 'Other Specialty Food Retailers' },
  { value: '445291', label: 'Baked Goods Retailers' },
  { value: '445292', label: 'Confectionery and Nut Retailers' },
  { value: '445298', label: 'All Other Specialty Food Retailers' },
  { value: '4453', label: 'Beer, Wine, and Liquor Retailers' },
  { value: '44532', label: 'Beer, Wine, and Liquor Retailers' },
  { value: '445320', label: 'Beer, Wine, and Liquor Retailers' },
  {
    value: '449',
    label: 'Furniture, Home Furnishings, Electronics, and Appliance Retailers',
  },
  { value: '4491', label: 'Furniture and Home Furnishings Retailers' },
  { value: '44911', label: 'Furniture Retailers' },
  { value: '449110', label: 'Furniture Retailers' },
  { value: '44912', label: 'Home Furnishings Retailers' },
  { value: '449121', label: 'Floor Covering Retailers' },
  { value: '449122', label: 'Window Treatment Retailers' },
  { value: '449129', label: 'All Other Home Furnishings Retailers' },
  { value: '4492', label: 'Electronics and Appliance Retailers' },
  { value: '44921', label: 'Electronics and Appliance Retailers' },
  { value: '449210', label: 'Electronics and Appliance Retailers' },
  { value: '455', label: 'General Merchandise Retailers' },
  { value: '4551', label: 'Department Stores' },
  { value: '45511', label: 'Department Stores' },
  { value: '455110', label: 'Department Stores' },
  {
    value: '4552',
    label:
      'Warehouse Clubs, Supercenters, and Other General Merchandise Retailers',
  },
  {
    value: '45521',
    label:
      'Warehouse Clubs, Supercenters, and Other General Merchandise Retailers',
  },
  { value: '455211', label: 'Warehouse Clubs and Supercenters' },
  { value: '455219', label: 'All Other General Merchandise Retailers' },
  { value: '456', label: 'Health and Personal Care Retailers' },
  { value: '4561', label: 'Health and Personal Care Retailers' },
  { value: '45611', label: 'Pharmacies and Drug Retailers' },
  { value: '456110', label: 'Pharmacies and Drug Retailers' },
  {
    value: '45612',
    label: 'Cosmetics, Beauty Supplies, and Perfume Retailers',
  },
  {
    value: '456120',
    label: 'Cosmetics, Beauty Supplies, and Perfume Retailers',
  },
  { value: '45613', label: 'Optical Goods Retailers' },
  { value: '456130', label: 'Optical Goods Retailers' },
  { value: '45619', label: 'Other Health and Personal Care Retailers' },
  { value: '456191', label: 'Food (Health) Supplement Retailers' },
  {
    value: '456199',
    label: 'All Other Health and Personal Care Retailers',
  },
  { value: '457', label: 'Gasoline Stations and Fuel Dealers' },
  { value: '4571', label: 'Gasoline Stations' },
  { value: '45711', label: 'Gasoline Stations with Convenience Stores' },
  { value: '457110', label: 'Gasoline Stations with Convenience Stores' },
  { value: '45712', label: 'Other Gasoline Stations' },
  { value: '457120', label: 'Other Gasoline Stations' },
  { value: '4572', label: 'Fuel Dealers' },
  { value: '45721', label: 'Fuel Dealers' },
  { value: '457210', label: 'Fuel Dealers' },
  {
    value: '458',
    label: 'Clothing, Clothing Accessories, Shoe, and Jewelry Retailers',
  },
  { value: '4581', label: 'Clothing and Clothing Accessories Retailers' },
  {
    value: '45811',
    label: 'Clothing and Clothing Accessories Retailers',
  },
  {
    value: '458110',
    label: 'Clothing and Clothing Accessories Retailers',
  },
  { value: '4582', label: 'Shoe Retailers' },
  { value: '45821', label: 'Shoe Retailers' },
  { value: '458210', label: 'Shoe Retailers' },
  {
    value: '4583',
    label: 'Jewelry, Luggage, and Leather Goods Retailers',
  },
  { value: '45831', label: 'Jewelry Retailers' },
  { value: '458310', label: 'Jewelry Retailers' },
  { value: '45832', label: 'Luggage and Leather Goods Retailers' },
  { value: '458320', label: 'Luggage and Leather Goods Retailers' },
  {
    value: '459',
    label:
      'Sporting Goods, Hobby, Musical Instrument, Book, and Miscellaneous Retailers',
  },
  {
    value: '4591',
    label: 'Sporting Goods, Hobby, and Musical Instrument Retailers',
  },
  { value: '45911', label: 'Sporting Goods Retailers' },
  { value: '459110', label: 'Sporting Goods Retailers' },
  { value: '45912', label: 'Hobby, Toy, and Game Retailers' },
  { value: '459120', label: 'Hobby, Toy, and Game Retailers' },
  {
    value: '45913',
    label: 'Sewing, Needlework, and Piece Goods Retailers',
  },
  {
    value: '459130',
    label: 'Sewing, Needlework, and Piece Goods Retailers',
  },
  { value: '45914', label: 'Musical Instrument and Supplies Retailers' },
  { value: '459140', label: 'Musical Instrument and Supplies Retailers' },
  { value: '4592', label: 'Book Retailers and News Dealers' },
  { value: '45921', label: 'Book Retailers and News Dealers' },
  { value: '459210', label: 'Book Retailers and News Dealers' },
  { value: '4593', label: 'Florists' },
  { value: '45931', label: 'Florists' },
  { value: '459310', label: 'Florists' },
  {
    value: '4594',
    label: 'Office Supplies, Stationery, and Gift Retailers',
  },
  { value: '45941', label: 'Office Supplies and Stationery Retailers' },
  { value: '459410', label: 'Office Supplies and Stationery Retailers' },
  { value: '45942', label: 'Gift, Novelty, and Souvenir Retailers' },
  { value: '459420', label: 'Gift, Novelty, and Souvenir Retailers' },
  { value: '4595', label: 'Used Merchandise Retailers' },
  { value: '45951', label: 'Used Merchandise Retailers' },
  { value: '459510', label: 'Used Merchandise Retailers' },
  { value: '4599', label: 'Other Miscellaneous Retailers' },
  { value: '45991', label: 'Pet and Pet Supplies Retailers' },
  { value: '459910', label: 'Pet and Pet Supplies Retailers' },
  { value: '45992', label: 'Art Dealers' },
  { value: '459920', label: 'Art Dealers' },
  { value: '45993', label: 'Manufactured (Mobile) Home Dealers' },
  { value: '459930', label: 'Manufactured (Mobile) Home Dealers' },
  { value: '45999', label: 'All Other Miscellaneous Retailers' },
  {
    value: '459991',
    label:
      'Tobacco, Electronic Cigarette, and Other Smoking Supplies Retailers',
  },
  { value: '459999', label: 'All Other Miscellaneous Retailers' },
  { value: '48-49', label: 'Transportation and Warehousing' },
  { value: '481', label: 'Air Transportation' },
  { value: '4811', label: 'Scheduled Air Transportation' },
  { value: '48111', label: 'Scheduled Air Transportation' },
  { value: '481111', label: 'Scheduled Passenger Air Transportation' },
  { value: '481112', label: 'Scheduled Freight Air Transportation' },
  { value: '4812', label: 'Nonscheduled Air Transportation' },
  { value: '48121', label: 'Nonscheduled Air Transportation' },
  {
    value: '481211',
    label: 'Nonscheduled Chartered Passenger Air Transportation',
  },
  {
    value: '481212',
    label: 'Nonscheduled Chartered Freight Air Transportation',
  },
  { value: '481219', label: 'Other Nonscheduled Air Transportation' },
  { value: '482', label: 'Rail Transportation' },
  { value: '4821', label: 'Rail Transportation' },
  { value: '48211', label: 'Rail Transportation' },
  { value: '482111', label: 'Line-Haul Railroads' },
  { value: '482112', label: 'Short Line Railroads' },
  { value: '483', label: 'Water Transportation' },
  {
    value: '4831',
    label: 'Deep Sea, Coastal, and Great Lakes Water Transportation',
  },
  {
    value: '48311',
    label: 'Deep Sea, Coastal, and Great Lakes Water Transportation',
  },
  { value: '483111', label: 'Deep Sea Freight Transportation' },
  { value: '483112', label: 'Deep Sea Passenger Transportation' },
  {
    value: '483113',
    label: 'Coastal and Great Lakes Freight Transportation',
  },
  {
    value: '483114',
    label: 'Coastal and Great Lakes Passenger Transportation',
  },
  { value: '4832', label: 'Inland Water Transportation' },
  { value: '48321', label: 'Inland Water Transportation' },
  { value: '483211', label: 'Inland Water Freight Transportation' },
  { value: '483212', label: 'Inland Water Passenger Transportation' },
  { value: '484', label: 'Truck Transportation' },
  { value: '4841', label: 'General Freight Trucking' },
  { value: '48411', label: 'General Freight Trucking, Local' },
  { value: '484110', label: 'General Freight Trucking, Local' },
  { value: '48412', label: 'General Freight Trucking, Long-Distance' },
  {
    value: '484121',
    label: 'General Freight Trucking, Long-Distance, Truckload',
  },
  {
    value: '484122',
    label: 'General Freight Trucking, Long-Distance, Less Than Truckload',
  },
  { value: '4842', label: 'Specialized Freight Trucking' },
  { value: '48421', label: 'Used Household and Office Goods Moving' },
  { value: '484210', label: 'Used Household and Office Goods Moving' },
  {
    value: '48422',
    label: 'Specialized Freight (except Used Goods) Trucking, Local',
  },
  {
    value: '484220',
    label: 'Specialized Freight (except Used Goods) Trucking, Local',
  },
  {
    value: '48423',
    label: 'Specialized Freight (except Used Goods) Trucking, Long-Distance',
  },
  {
    value: '484230',
    label: 'Specialized Freight (except Used Goods) Trucking, Long-Distance',
  },
  { value: '485', label: 'Transit and Ground Passenger Transportation' },
  { value: '4851', label: 'Urban Transit Systems' },
  { value: '48511', label: 'Urban Transit Systems' },
  { value: '485111', label: 'Mixed Mode Transit Systems' },
  { value: '485112', label: 'Commuter Rail Systems' },
  {
    value: '485113',
    label: 'Bus and Other Motor Vehicle Transit Systems',
  },
  { value: '485119', label: 'Other Urban Transit Systems' },
  { value: '4852', label: 'Interurban and Rural Bus Transportation' },
  { value: '48521', label: 'Interurban and Rural Bus Transportation' },
  { value: '485210', label: 'Interurban and Rural Bus Transportation' },
  { value: '4853', label: 'Taxi and Limousine Service' },
  { value: '48531', label: 'Taxi and Ridesharing Services' },
  { value: '485310', label: 'Taxi and Ridesharing Services' },
  { value: '48532', label: 'Limousine Service' },
  { value: '485320', label: 'Limousine Service' },
  { value: '4854', label: 'School and Employee Bus Transportation' },
  { value: '48541', label: 'School and Employee Bus Transportation' },
  { value: '485410', label: 'School and Employee Bus Transportation' },
  { value: '4855', label: 'Charter Bus Industry' },
  { value: '48551', label: 'Charter Bus Industry' },
  { value: '485510', label: 'Charter Bus Industry' },
  {
    value: '4859',
    label: 'Other Transit and Ground Passenger Transportation',
  },
  {
    value: '48599',
    label: 'Other Transit and Ground Passenger Transportation',
  },
  { value: '485991', label: 'Special Needs Transportation' },
  {
    value: '485999',
    label: 'All Other Transit and Ground Passenger Transportation',
  },
  { value: '486', label: 'Pipeline Transportation' },
  { value: '4861', label: 'Pipeline Transportation of Crude Oil' },
  { value: '48611', label: 'Pipeline Transportation of Crude Oil' },
  { value: '486110', label: 'Pipeline Transportation of Crude Oil' },
  { value: '4862', label: 'Pipeline Transportation of Natural Gas' },
  { value: '48621', label: 'Pipeline Transportation of Natural Gas' },
  { value: '486210', label: 'Pipeline Transportation of Natural Gas' },
  { value: '4869', label: 'Other Pipeline Transportation' },
  {
    value: '48691',
    label: 'Pipeline Transportation of Refined Petroleum Products',
  },
  {
    value: '486910',
    label: 'Pipeline Transportation of Refined Petroleum Products',
  },
  { value: '48699', label: 'All Other Pipeline Transportation' },
  { value: '486990', label: 'All Other Pipeline Transportation' },
  { value: '487', label: 'Scenic and Sightseeing Transportation' },
  { value: '4871', label: 'Scenic and Sightseeing Transportation, Land' },
  {
    value: '48711',
    label: 'Scenic and Sightseeing Transportation, Land',
  },
  {
    value: '487110',
    label: 'Scenic and Sightseeing Transportation, Land',
  },
  {
    value: '4872',
    label: 'Scenic and Sightseeing Transportation, Water',
  },
  {
    value: '48721',
    label: 'Scenic and Sightseeing Transportation, Water',
  },
  {
    value: '487210',
    label: 'Scenic and Sightseeing Transportation, Water',
  },
  {
    value: '4879',
    label: 'Scenic and Sightseeing Transportation, Other',
  },
  {
    value: '48799',
    label: 'Scenic and Sightseeing Transportation, Other',
  },
  {
    value: '487990',
    label: 'Scenic and Sightseeing Transportation, Other',
  },
  { value: '488', label: 'Support Activities for Transportation' },
  { value: '4881', label: 'Support Activities for Air Transportation' },
  { value: '48811', label: 'Airport Operations' },
  { value: '488111', label: 'Air Traffic Control' },
  { value: '488119', label: 'Other Airport Operations' },
  {
    value: '48819',
    label: 'Other Support Activities for Air Transportation',
  },
  {
    value: '488190',
    label: 'Other Support Activities for Air Transportation',
  },
  { value: '4882', label: 'Support Activities for Rail Transportation' },
  { value: '48821', label: 'Support Activities for Rail Transportation' },
  {
    value: '488210',
    label: 'Support Activities for Rail Transportation',
  },
  { value: '4883', label: 'Support Activities for Water Transportation' },
  { value: '48831', label: 'Port and Harbor Operations' },
  { value: '488310', label: 'Port and Harbor Operations' },
  { value: '48832', label: 'Marine Cargo Handling' },
  { value: '488320', label: 'Marine Cargo Handling' },
  { value: '48833', label: 'Navigational Services to Shipping' },
  { value: '488330', label: 'Navigational Services to Shipping' },
  {
    value: '48839',
    label: 'Other Support Activities for Water Transportation',
  },
  {
    value: '488390',
    label: 'Other Support Activities for Water Transportation',
  },
  { value: '4884', label: 'Support Activities for Road Transportation' },
  { value: '48841', label: 'Motor Vehicle Towing' },
  { value: '488410', label: 'Motor Vehicle Towing' },
  {
    value: '48849',
    label: 'Other Support Activities for Road Transportation',
  },
  {
    value: '488490',
    label: 'Other Support Activities for Road Transportation',
  },
  { value: '4885', label: 'Freight Transportation Arrangement' },
  { value: '48851', label: 'Freight Transportation Arrangement' },
  { value: '488510', label: 'Freight Transportation Arrangement' },
  { value: '4889', label: 'Other Support Activities for Transportation' },
  {
    value: '48899',
    label: 'Other Support Activities for Transportation',
  },
  { value: '488991', label: 'Packing and Crating' },
  {
    value: '488999',
    label: 'All Other Support Activities for Transportation',
  },
  { value: '491', label: 'Postal Service' },
  { value: '4911', label: 'Postal Service' },
  { value: '49111', label: 'Postal Service' },
  { value: '491110', label: 'Postal Service' },
  { value: '492', label: 'Couriers and Messengers' },
  { value: '4921', label: 'Couriers and Express Delivery Services' },
  { value: '49211', label: 'Couriers and Express Delivery Services' },
  { value: '492110', label: 'Couriers and Express Delivery Services' },
  { value: '4922', label: 'Local Messengers and Local Delivery' },
  { value: '49221', label: 'Local Messengers and Local Delivery' },
  { value: '492210', label: 'Local Messengers and Local Delivery' },
  { value: '493', label: 'Warehousing and Storage' },
  { value: '4931', label: 'Warehousing and Storage' },
  { value: '49311', label: 'General Warehousing and Storage' },
  { value: '493110', label: 'General Warehousing and Storage' },
  { value: '49312', label: 'Refrigerated Warehousing and Storage' },
  { value: '493120', label: 'Refrigerated Warehousing and Storage' },
  { value: '49313', label: 'Farm Product Warehousing and Storage' },
  { value: '493130', label: 'Farm Product Warehousing and Storage' },
  { value: '49319', label: 'Other Warehousing and Storage' },
  { value: '493190', label: 'Other Warehousing and Storage' },
  { value: '51', label: 'Information' },
  {
    value: '512',
    label: 'Motion Picture and Sound Recording Industries',
  },
  { value: '5121', label: 'Motion Picture and Video Industries' },
  { value: '51211', label: 'Motion Picture and Video Production' },
  { value: '512110', label: 'Motion Picture and Video Production' },
  { value: '51212', label: 'Motion Picture and Video Distribution' },
  { value: '512120', label: 'Motion Picture and Video Distribution' },
  { value: '51213', label: 'Motion Picture and Video Exhibition' },
  {
    value: '512131',
    label: 'Motion Picture Theaters (except Drive-Ins)',
  },
  { value: '512132', label: 'Drive-In Motion Picture Theaters' },
  {
    value: '51219',
    label:
      'Postproduction Services and Other Motion Picture and Video Industries',
  },
  {
    value: '512191',
    label: 'Teleproduction and Other Postproduction Services',
  },
  { value: '512199', label: 'Other Motion Picture and Video Industries' },
  { value: '5122', label: 'Sound Recording Industries' },
  { value: '51223', label: 'Music Publishers' },
  { value: '512230', label: 'Music Publishers' },
  { value: '51224', label: 'Sound Recording Studios' },
  { value: '512240', label: 'Sound Recording Studios' },
  { value: '51225', label: 'Record Production and Distribution' },
  { value: '512250', label: 'Record Production and Distribution' },
  { value: '51229', label: 'Other Sound Recording Industries' },
  { value: '512290', label: 'Other Sound Recording Industries' },
  { value: '513', label: 'Publishing Industries' },
  {
    value: '5131',
    label: 'Newspaper, Periodical, Book, and Directory Publishers',
  },
  { value: '51311', label: 'Newspaper Publishers' },
  { value: '513110', label: 'Newspaper Publishers' },
  { value: '51312', label: 'Periodical Publishers' },
  { value: '513120', label: 'Periodical Publishers' },
  { value: '51313', label: 'Book Publishers' },
  { value: '513130', label: 'Book Publishers' },
  { value: '51314', label: 'Directory and Mailing List Publishers' },
  { value: '513140', label: 'Directory and Mailing List Publishers' },
  { value: '51319', label: 'Other Publishers' },
  { value: '513191', label: 'Greeting Card Publishers' },
  { value: '513199', label: 'All Other Publishers' },
  { value: '5132', label: 'Software Publishers' },
  { value: '51321', label: 'Software Publishers' },
  { value: '513210', label: 'Software Publishers' },
  { value: '516', label: 'Broadcasting and Content Providers' },
  { value: '5161', label: 'Radio and Television Broadcasting Stations' },
  { value: '51611', label: 'Radio Broadcasting Stations' },
  { value: '516110', label: 'Radio Broadcasting Stations' },
  { value: '51612', label: 'Television Broadcasting Stations' },
  { value: '516120', label: 'Television Broadcasting Stations' },
  {
    value: '5162',
    label:
      'Media Streaming Distribution Services, Social Networks, and Other Media Networks and Content Providers',
  },
  {
    value: '51621',
    label:
      'Media Streaming Distribution Services, Social Networks, and Other Media Networks and Content Providers',
  },
  {
    value: '516210',
    label:
      'Media Streaming Distribution Services, Social Networks, and Other Media Networks and Content Providers',
  },
  { value: '517', label: 'Telecommunications' },
  {
    value: '5171',
    label: 'Wired and Wireless Telecommunications (except Satellite)',
  },
  {
    value: '51711',
    label: 'Wired and Wireless Telecommunications Carriers (except Satellite)',
  },
  { value: '517111', label: 'Wired Telecommunications Carriers' },
  {
    value: '517112',
    label: 'Wireless Telecommunications Carriers (except Satellite)',
  },
  {
    value: '51712',
    label:
      'Telecommunications Resellers and Agents for Wireless Telecommunication Services',
  },
  { value: '517121', label: 'Telecommunications Resellers' },
  {
    value: '517122',
    label: 'Agents for Wireless Telecommunications Services',
  },
  { value: '5174', label: 'Satellite Telecommunications' },
  { value: '51741', label: 'Satellite Telecommunications' },
  { value: '517410', label: 'Satellite Telecommunications' },
  { value: '5178', label: 'All Other Telecommunications' },
  { value: '51781', label: 'All Other Telecommunications' },
  { value: '517810', label: 'All Other Telecommunications' },
  {
    value: '518',
    label:
      'Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services',
  },
  {
    value: '5182',
    label:
      'Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services',
  },
  {
    value: '51821',
    label:
      'Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services',
  },
  {
    value: '518210',
    label:
      'Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services',
  },
  {
    value: '519',
    label:
      'Web Search Portals, Libraries, Archives, and Other Information Services',
  },
  {
    value: '5192',
    label:
      'Web Search Portals, Libraries, Archives, and Other Information Services',
  },
  { value: '51921', label: 'Libraries and Archives' },
  { value: '519210', label: 'Libraries and Archives' },
  {
    value: '51929',
    label: 'Web Search Portals and All Other Information Services',
  },
  {
    value: '519290',
    label: 'Web Search Portals and All Other Information Services',
  },
  { value: '52', label: 'Finance and Insurance' },
  { value: '521', label: 'Monetary Authorities-Central Bank' },
  { value: '5211', label: 'Monetary Authorities-Central Bank' },
  { value: '52111', label: 'Monetary Authorities-Central Bank' },
  { value: '521110', label: 'Monetary Authorities-Central Bank' },
  { value: '522', label: 'Credit Intermediation and Related Activities' },
  { value: '5221', label: 'Depository Credit Intermediation' },
  { value: '52211', label: 'Commercial Banking' },
  { value: '522110', label: 'Commercial Banking' },
  { value: '52213', label: 'Credit Unions' },
  { value: '522130', label: 'Credit Unions' },
  {
    value: '52218',
    label: 'Savings Institutions and Other Depository Credit Intermediation',
  },
  {
    value: '522180',
    label: 'Savings Institutions and Other Depository Credit Intermediation',
  },
  { value: '5222', label: 'Nondepository Credit Intermediation' },
  { value: '52221', label: 'Credit Card Issuing' },
  { value: '522210', label: 'Credit Card Issuing' },
  { value: '52222', label: 'Sales Financing' },
  { value: '522220', label: 'Sales Financing' },
  { value: '52229', label: 'Other Nondepository Credit Intermediation' },
  { value: '522291', label: 'Consumer Lending' },
  { value: '522292', label: 'Real Estate Credit' },
  {
    value: '522299',
    label:
      'International, Secondary Market, and All Other Nondepository Credit Intermediation',
  },
  { value: '5223', label: 'Activities Related to Credit Intermediation' },
  { value: '52231', label: 'Mortgage and Nonmortgage Loan Brokers' },
  { value: '522310', label: 'Mortgage and Nonmortgage Loan Brokers' },
  {
    value: '52232',
    label:
      'Financial Transactions Processing, Reserve, and Clearinghouse Activities',
  },
  {
    value: '522320',
    label:
      'Financial Transactions Processing, Reserve, and Clearinghouse Activities',
  },
  {
    value: '52239',
    label: 'Other Activities Related to Credit Intermediation',
  },
  {
    value: '522390',
    label: 'Other Activities Related to Credit Intermediation',
  },
  {
    value: '523',
    label:
      'Securities, Commodity Contracts, and Other Financial Investments and Related Activities',
  },
  {
    value: '5231',
    label: 'Securities and Commodity Contracts Intermediation and Brokerage',
  },
  {
    value: '52315',
    label: 'Investment Banking and Securities Intermediation',
  },
  {
    value: '523150',
    label: 'Investment Banking and Securities Intermediation',
  },
  { value: '52316', label: 'Commodity Contracts Intermediation' },
  { value: '523160', label: 'Commodity Contracts Intermediation' },
  { value: '5232', label: 'Securities and Commodity Exchanges' },
  { value: '52321', label: 'Securities and Commodity Exchanges' },
  { value: '523210', label: 'Securities and Commodity Exchanges' },
  { value: '5239', label: 'Other Financial Investment Activities' },
  { value: '52391', label: 'Miscellaneous Intermediation' },
  { value: '523910', label: 'Miscellaneous Intermediation' },
  { value: '52394', label: 'Portfolio Management and Investment Advice' },
  {
    value: '523940',
    label: 'Portfolio Management and Investment Advice',
  },
  { value: '52399', label: 'All Other Financial Investment Activities' },
  { value: '523991', label: 'Trust, Fiduciary, and Custody Activities' },
  {
    value: '523999',
    label: 'Miscellaneous Financial Investment Activities',
  },
  { value: '524', label: 'Insurance Carriers and Related Activities' },
  { value: '5241', label: 'Insurance Carriers' },
  {
    value: '52411',
    label: 'Direct Life, Health, and Medical Insurance Carriers',
  },
  { value: '524113', label: 'Direct Life Insurance Carriers' },
  {
    value: '524114',
    label: 'Direct Health and Medical Insurance Carriers',
  },
  {
    value: '52412',
    label: 'Direct Insurance (except Life, Health, and Medical) Carriers',
  },
  {
    value: '524126',
    label: 'Direct Property and Casualty Insurance Carriers',
  },
  { value: '524127', label: 'Direct Title Insurance Carriers' },
  {
    value: '524128',
    label: 'Other Direct Insurance (except Life, Health, and Medical) Carriers',
  },
  { value: '52413', label: 'Reinsurance Carriers' },
  { value: '524130', label: 'Reinsurance Carriers' },
  {
    value: '5242',
    label: 'Agencies, Brokerages, and Other Insurance Related Activities',
  },
  { value: '52421', label: 'Insurance Agencies and Brokerages' },
  { value: '524210', label: 'Insurance Agencies and Brokerages' },
  { value: '52429', label: 'Other Insurance Related Activities' },
  { value: '524291', label: 'Claims Adjusting' },
  {
    value: '524292',
    label:
      'Pharmacy Benefit Management and Other Third Party Administration of Insurance and Pension Funds',
  },
  { value: '524298', label: 'All Other Insurance Related Activities' },
  { value: '525', label: 'Funds, Trusts, and Other Financial Vehicles' },
  { value: '5251', label: 'Insurance and Employee Benefit Funds' },
  { value: '52511', label: 'Pension Funds' },
  { value: '525110', label: 'Pension Funds' },
  { value: '52512', label: 'Health and Welfare Funds' },
  { value: '525120', label: 'Health and Welfare Funds' },
  { value: '52519', label: 'Other Insurance Funds' },
  { value: '525190', label: 'Other Insurance Funds' },
  { value: '5259', label: 'Other Investment Pools and Funds' },
  { value: '52591', label: 'Open-End Investment Funds' },
  { value: '525910', label: 'Open-End Investment Funds' },
  { value: '52592', label: 'Trusts, Estates, and Agency Accounts' },
  { value: '525920', label: 'Trusts, Estates, and Agency Accounts' },
  { value: '52599', label: 'Other Financial Vehicles' },
  { value: '525990', label: 'Other Financial Vehicles' },
  { value: '53', label: 'Real Estate and Rental and Leasing' },
  { value: '531', label: 'Real Estate' },
  { value: '5311', label: 'Lessors of Real Estate' },
  {
    value: '53111',
    label: 'Lessors of Residential Buildings and Dwellings',
  },
  {
    value: '531110',
    label: 'Lessors of Residential Buildings and Dwellings',
  },
  {
    value: '53112',
    label: 'Lessors of Nonresidential Buildings (except Miniwarehouses)',
  },
  {
    value: '531120',
    label: 'Lessors of Nonresidential Buildings (except Miniwarehouses)',
  },
  {
    value: '53113',
    label: 'Lessors of Miniwarehouses and Self-Storage Units',
  },
  {
    value: '531130',
    label: 'Lessors of Miniwarehouses and Self-Storage Units',
  },
  { value: '53119', label: 'Lessors of Other Real Estate Property' },
  { value: '531190', label: 'Lessors of Other Real Estate Property' },
  { value: '5312', label: 'Offices of Real Estate Agents and Brokers' },
  { value: '53121', label: 'Offices of Real Estate Agents and Brokers' },
  { value: '531210', label: 'Offices of Real Estate Agents and Brokers' },
  { value: '5313', label: 'Activities Related to Real Estate' },
  { value: '53131', label: 'Real Estate Property Managers' },
  { value: '531311', label: 'Residential Property Managers' },
  { value: '531312', label: 'Nonresidential Property Managers' },
  { value: '53132', label: 'Offices of Real Estate Appraisers' },
  { value: '531320', label: 'Offices of Real Estate Appraisers' },
  { value: '53139', label: 'Other Activities Related to Real Estate' },
  { value: '531390', label: 'Other Activities Related to Real Estate' },
  { value: '532', label: 'Rental and Leasing Services' },
  { value: '5321', label: 'Automotive Equipment Rental and Leasing' },
  { value: '53211', label: 'Passenger Car Rental and Leasing' },
  { value: '532111', label: 'Passenger Car Rental' },
  { value: '532112', label: 'Passenger Car Leasing' },
  {
    value: '53212',
    label:
      'Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing',
  },
  {
    value: '532120',
    label:
      'Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing',
  },
  { value: '5322', label: 'Consumer Goods Rental' },
  { value: '53221', label: 'Consumer Electronics and Appliances Rental' },
  {
    value: '532210',
    label: 'Consumer Electronics and Appliances Rental',
  },
  { value: '53228', label: 'Other Consumer Goods Rental' },
  { value: '532281', label: 'Formal Wear and Costume Rental' },
  { value: '532282', label: 'Video Tape and Disc Rental' },
  { value: '532283', label: 'Home Health Equipment Rental' },
  { value: '532284', label: 'Recreational Goods Rental' },
  { value: '532289', label: 'All Other Consumer Goods Rental' },
  { value: '5323', label: 'General Rental Centers' },
  { value: '53231', label: 'General Rental Centers' },
  { value: '532310', label: 'General Rental Centers' },
  {
    value: '5324',
    label:
      'Commercial and Industrial Machinery and Equipment Rental and Leasing',
  },
  {
    value: '53241',
    label:
      'Construction, Transportation, Mining, and Forestry Machinery and Equipment Rental and Leasing',
  },
  {
    value: '532411',
    label:
      'Commercial Air, Rail, and Water Transportation Equipment Rental and Leasing',
  },
  {
    value: '532412',
    label:
      'Construction, Mining, and Forestry Machinery and Equipment Rental and Leasing',
  },
  {
    value: '53242',
    label: 'Office Machinery and Equipment Rental and Leasing',
  },
  {
    value: '532420',
    label: 'Office Machinery and Equipment Rental and Leasing',
  },
  {
    value: '53249',
    label:
      'Other Commercial and Industrial Machinery and Equipment Rental and Leasing',
  },
  {
    value: '532490',
    label:
      'Other Commercial and Industrial Machinery and Equipment Rental and Leasing',
  },
  {
    value: '533',
    label:
      'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)',
  },
  {
    value: '5331',
    label:
      'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)',
  },
  {
    value: '53311',
    label:
      'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)',
  },
  {
    value: '533110',
    label:
      'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)',
  },
  {
    value: '54',
    label: 'Professional, Scientific, and Technical Services',
  },
  {
    value: '541',
    label: 'Professional, Scientific, and Technical Services',
  },
  { value: '5411', label: 'Legal Services' },
  { value: '54111', label: 'Offices of Lawyers' },
  { value: '541110', label: 'Offices of Lawyers' },
  { value: '54112', label: 'Offices of Notaries' },
  { value: '541120', label: 'Offices of Notaries' },
  { value: '54119', label: 'Other Legal Services' },
  { value: '541191', label: 'Title Abstract and Settlement Offices' },
  { value: '541199', label: 'All Other Legal Services' },
  {
    value: '5412',
    label: 'Accounting, Tax Preparation, Bookkeeping, and Payroll Services',
  },
  {
    value: '54121',
    label: 'Accounting, Tax Preparation, Bookkeeping, and Payroll Services',
  },
  { value: '541211', label: 'Offices of Certified Public Accountants' },
  { value: '541213', label: 'Tax Preparation Services' },
  { value: '541214', label: 'Payroll Services' },
  { value: '541219', label: 'Other Accounting Services' },
  {
    value: '5413',
    label: 'Architectural, Engineering, and Related Services',
  },
  { value: '54131', label: 'Architectural Services' },
  { value: '541310', label: 'Architectural Services' },
  { value: '54132', label: 'Landscape Architectural Services' },
  { value: '541320', label: 'Landscape Architectural Services' },
  { value: '54133', label: 'Engineering Services' },
  { value: '541330', label: 'Engineering Services' },
  { value: '54134', label: 'Drafting Services' },
  { value: '541340', label: 'Drafting Services' },
  { value: '54135', label: 'Building Inspection Services' },
  { value: '541350', label: 'Building Inspection Services' },
  { value: '54136', label: 'Geophysical Surveying and Mapping Services' },
  {
    value: '541360',
    label: 'Geophysical Surveying and Mapping Services',
  },
  {
    value: '54137',
    label: 'Surveying and Mapping (except Geophysical) Services',
  },
  {
    value: '541370',
    label: 'Surveying and Mapping (except Geophysical) Services',
  },
  { value: '54138', label: 'Testing Laboratories and Services' },
  { value: '541380', label: 'Testing Laboratories and Services' },
  { value: '5414', label: 'Specialized Design Services' },
  { value: '54141', label: 'Interior Design Services' },
  { value: '541410', label: 'Interior Design Services' },
  { value: '54142', label: 'Industrial Design Services' },
  { value: '541420', label: 'Industrial Design Services' },
  { value: '54143', label: 'Graphic Design Services' },
  { value: '541430', label: 'Graphic Design Services' },
  { value: '54149', label: 'Other Specialized Design Services' },
  { value: '541490', label: 'Other Specialized Design Services' },
  {
    value: '5415',
    label: 'Computer Systems Design and Related Services',
  },
  {
    value: '54151',
    label: 'Computer Systems Design and Related Services',
  },
  { value: '541511', label: 'Custom Computer Programming Services' },
  { value: '541512', label: 'Computer Systems Design Services' },
  { value: '541513', label: 'Computer Facilities Management Services' },
  { value: '541519', label: 'Other Computer Related Services' },
  {
    value: '5416',
    label: 'Management, Scientific, and Technical Consulting Services',
  },
  { value: '54161', label: 'Management Consulting Services' },
  {
    value: '541611',
    label:
      'Administrative Management and General Management Consulting Services',
  },
  { value: '541612', label: 'Human Resources Consulting Services' },
  { value: '541613', label: 'Marketing Consulting Services' },
  {
    value: '541614',
    label: 'Process, Physical Distribution, and Logistics Consulting Services',
  },
  { value: '541618', label: 'Other Management Consulting Services' },
  { value: '54162', label: 'Environmental Consulting Services' },
  { value: '541620', label: 'Environmental Consulting Services' },
  {
    value: '54169',
    label: 'Other Scientific and Technical Consulting Services',
  },
  {
    value: '541690',
    label: 'Other Scientific and Technical Consulting Services',
  },
  {
    value: '5417',
    label: 'Scientific Research and Development Services',
  },
  {
    value: '54171',
    label:
      'Research and Development in the Physical, Engineering, and Life Sciences',
  },
  {
    value: '541713',
    label: 'Research and Development in Nanotechnology',
  },
  {
    value: '541714',
    label:
      'Research and Development in Biotechnology (except Nanobiotechnology)',
  },
  {
    value: '541715',
    label:
      'Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology)',
  },
  {
    value: '54172',
    label: 'Research and Development in the Social Sciences and Humanities',
  },
  {
    value: '541720',
    label: 'Research and Development in the Social Sciences and Humanities',
  },
  {
    value: '5418',
    label: 'Advertising, Public Relations, and Related Services',
  },
  { value: '54181', label: 'Advertising Agencies' },
  { value: '541810', label: 'Advertising Agencies' },
  { value: '54182', label: 'Public Relations Agencies' },
  { value: '541820', label: 'Public Relations Agencies' },
  { value: '54183', label: 'Media Buying Agencies' },
  { value: '541830', label: 'Media Buying Agencies' },
  { value: '54184', label: 'Media Representatives' },
  { value: '541840', label: 'Media Representatives' },
  { value: '54185', label: 'Indoor and Outdoor Display Advertising' },
  { value: '541850', label: 'Indoor and Outdoor Display Advertising' },
  { value: '54186', label: 'Direct Mail Advertising' },
  { value: '541860', label: 'Direct Mail Advertising' },
  { value: '54187', label: 'Advertising Material Distribution Services' },
  {
    value: '541870',
    label: 'Advertising Material Distribution Services',
  },
  { value: '54189', label: 'Other Services Related to Advertising' },
  { value: '541890', label: 'Other Services Related to Advertising' },
  {
    value: '5419',
    label: 'Other Professional, Scientific, and Technical Services',
  },
  {
    value: '54191',
    label: 'Marketing Research and Public Opinion Polling',
  },
  {
    value: '541910',
    label: 'Marketing Research and Public Opinion Polling',
  },
  { value: '54192', label: 'Photographic Services' },
  { value: '541921', label: 'Photography Studios, Portrait' },
  { value: '541922', label: 'Commercial Photography' },
  { value: '54193', label: 'Translation and Interpretation Services' },
  { value: '541930', label: 'Translation and Interpretation Services' },
  { value: '54194', label: 'Veterinary Services' },
  { value: '541940', label: 'Veterinary Services' },
  {
    value: '54199',
    label: 'All Other Professional, Scientific, and Technical Services',
  },
  {
    value: '541990',
    label: 'All Other Professional, Scientific, and Technical Services',
  },
  { value: '55', label: 'Management of Companies and Enterprises' },
  { value: '551', label: 'Management of Companies and Enterprises' },
  { value: '5511', label: 'Management of Companies and Enterprises' },
  { value: '55111', label: 'Management of Companies and Enterprises' },
  { value: '551111', label: 'Offices of Bank Holding Companies' },
  { value: '551112', label: 'Offices of Other Holding Companies' },
  {
    value: '551114',
    label: 'Corporate, Subsidiary, and Regional Managing Offices',
  },
  {
    value: '56',
    label:
      'Administrative and Support and Waste Management and Remediation Services',
  },
  { value: '561', label: 'Administrative and Support Services' },
  { value: '5611', label: 'Office Administrative Services' },
  { value: '56111', label: 'Office Administrative Services' },
  { value: '561110', label: 'Office Administrative Services' },
  { value: '5612', label: 'Facilities Support Services' },
  { value: '56121', label: 'Facilities Support Services' },
  { value: '561210', label: 'Facilities Support Services' },
  { value: '5613', label: 'Employment Services' },
  {
    value: '56131',
    label: 'Employment Placement Agencies and Executive Search Services',
  },
  { value: '561311', label: 'Employment Placement Agencies' },
  { value: '561312', label: 'Executive Search Services' },
  { value: '56132', label: 'Temporary Help Services' },
  { value: '561320', label: 'Temporary Help Services' },
  { value: '56133', label: 'Professional Employer Organizations' },
  { value: '561330', label: 'Professional Employer Organizations' },
  { value: '5614', label: 'Business Support Services' },
  { value: '56141', label: 'Document Preparation Services' },
  { value: '561410', label: 'Document Preparation Services' },
  { value: '56142', label: 'Telephone Call Centers' },
  { value: '561421', label: 'Telephone Answering Services' },
  {
    value: '561422',
    label: 'Telemarketing Bureaus and Other Contact Centers',
  },
  { value: '56143', label: 'Business Service Centers' },
  { value: '561431', label: 'Private Mail Centers' },
  {
    value: '561439',
    label: 'Other Business Service Centers (including Copy Shops)',
  },
  { value: '56144', label: 'Collection Agencies' },
  { value: '561440', label: 'Collection Agencies' },
  { value: '56145', label: 'Credit Bureaus' },
  { value: '561450', label: 'Credit Bureaus' },
  { value: '56149', label: 'Other Business Support Services' },
  { value: '561491', label: 'Repossession Services' },
  { value: '561492', label: 'Court Reporting and Stenotype Services' },
  { value: '561499', label: 'All Other Business Support Services' },
  { value: '5615', label: 'Travel Arrangement and Reservation Services' },
  { value: '56151', label: 'Travel Agencies' },
  { value: '561510', label: 'Travel Agencies' },
  { value: '56152', label: 'Tour Operators' },
  { value: '561520', label: 'Tour Operators' },
  {
    value: '56159',
    label: 'Other Travel Arrangement and Reservation Services',
  },
  { value: '561591', label: 'Convention and Visitors Bureaus' },
  {
    value: '561599',
    label: 'All Other Travel Arrangement and Reservation Services',
  },
  { value: '5616', label: 'Investigation and Security Services' },
  {
    value: '56161',
    label: 'Investigation, Guard, and Armored Car Services',
  },
  {
    value: '561611',
    label: 'Investigation and Personal Background Check Services',
  },
  { value: '561612', label: 'Security Guards and Patrol Services' },
  { value: '561613', label: 'Armored Car Services' },
  { value: '56162', label: 'Security Systems Services' },
  {
    value: '561621',
    label: 'Security Systems Services (except Locksmiths)',
  },
  { value: '561622', label: 'Locksmiths' },
  { value: '5617', label: 'Services to Buildings and Dwellings' },
  { value: '56171', label: 'Exterminating and Pest Control Services' },
  { value: '561710', label: 'Exterminating and Pest Control Services' },
  { value: '56172', label: 'Janitorial Services' },
  { value: '561720', label: 'Janitorial Services' },
  { value: '56173', label: 'Landscaping Services' },
  { value: '561730', label: 'Landscaping Services' },
  { value: '56174', label: 'Carpet and Upholstery Cleaning Services' },
  { value: '561740', label: 'Carpet and Upholstery Cleaning Services' },
  { value: '56179', label: 'Other Services to Buildings and Dwellings' },
  { value: '561790', label: 'Other Services to Buildings and Dwellings' },
  { value: '5619', label: 'Other Support Services' },
  { value: '56191', label: 'Packaging and Labeling Services' },
  { value: '561910', label: 'Packaging and Labeling Services' },
  { value: '56192', label: 'Convention and Trade Show Organizers' },
  { value: '561920', label: 'Convention and Trade Show Organizers' },
  { value: '56199', label: 'All Other Support Services' },
  { value: '561990', label: 'All Other Support Services' },
  { value: '562', label: 'Waste Management and Remediation Services' },
  { value: '5621', label: 'Waste Collection' },
  { value: '56211', label: 'Waste Collection' },
  { value: '562111', label: 'Solid Waste Collection' },
  { value: '562112', label: 'Hazardous Waste Collection' },
  { value: '562119', label: 'Other Waste Collection' },
  { value: '5622', label: 'Waste Treatment and Disposal' },
  { value: '56221', label: 'Waste Treatment and Disposal' },
  { value: '562211', label: 'Hazardous Waste Treatment and Disposal' },
  { value: '562212', label: 'Solid Waste Landfill' },
  { value: '562213', label: 'Solid Waste Combustors and Incinerators' },
  {
    value: '562219',
    label: 'Other Nonhazardous Waste Treatment and Disposal',
  },
  {
    value: '5629',
    label: 'Remediation and Other Waste Management Services',
  },
  { value: '56291', label: 'Remediation Services' },
  { value: '562910', label: 'Remediation Services' },
  { value: '56292', label: 'Materials Recovery Facilities' },
  { value: '562920', label: 'Materials Recovery Facilities' },
  { value: '56299', label: 'All Other Waste Management Services' },
  { value: '562991', label: 'Septic Tank and Related Services' },
  {
    value: '562998',
    label: 'All Other Miscellaneous Waste Management Services',
  },
  { value: '61', label: 'Educational Services' },
  { value: '611', label: 'Educational Services' },
  { value: '6111', label: 'Elementary and Secondary Schools' },
  { value: '61111', label: 'Elementary and Secondary Schools' },
  { value: '611110', label: 'Elementary and Secondary Schools' },
  { value: '6112', label: 'Junior Colleges' },
  { value: '61121', label: 'Junior Colleges' },
  { value: '611210', label: 'Junior Colleges' },
  {
    value: '6113',
    label: 'Colleges, Universities, and Professional Schools',
  },
  {
    value: '61131',
    label: 'Colleges, Universities, and Professional Schools',
  },
  {
    value: '611310',
    label: 'Colleges, Universities, and Professional Schools',
  },
  {
    value: '6114',
    label: 'Business Schools and Computer and Management Training',
  },
  { value: '61141', label: 'Business and Secretarial Schools' },
  { value: '611410', label: 'Business and Secretarial Schools' },
  { value: '61142', label: 'Computer Training' },
  { value: '611420', label: 'Computer Training' },
  {
    value: '61143',
    label: 'Professional and Management Development Training',
  },
  {
    value: '611430',
    label: 'Professional and Management Development Training',
  },
  { value: '6115', label: 'Technical and Trade Schools' },
  { value: '61151', label: 'Technical and Trade Schools' },
  { value: '611511', label: 'Cosmetology and Barber Schools' },
  { value: '611512', label: 'Flight Training' },
  { value: '611513', label: 'Apprenticeship Training' },
  { value: '611519', label: 'Other Technical and Trade Schools' },
  { value: '6116', label: 'Other Schools and Instruction' },
  { value: '61161', label: 'Fine Arts Schools' },
  { value: '611610', label: 'Fine Arts Schools' },
  { value: '61162', label: 'Sports and Recreation Instruction' },
  { value: '611620', label: 'Sports and Recreation Instruction' },
  { value: '61163', label: 'Language Schools' },
  { value: '611630', label: 'Language Schools' },
  { value: '61169', label: 'All Other Schools and Instruction' },
  { value: '611691', label: 'Exam Preparation and Tutoring' },
  { value: '611692', label: 'Automobile Driving Schools' },
  {
    value: '611699',
    label: 'All Other Miscellaneous Schools and Instruction',
  },
  { value: '6117', label: 'Educational Support Services' },
  { value: '61171', label: 'Educational Support Services' },
  { value: '611710', label: 'Educational Support Services' },
  { value: '62', label: 'Health Care and Social Assistance' },
  { value: '621', label: 'Ambulatory Health Care Services' },
  { value: '6211', label: 'Offices of Physicians' },
  { value: '62111', label: 'Offices of Physicians' },
  {
    value: '621111',
    label: 'Offices of Physicians (except Mental Health Specialists)',
  },
  {
    value: '621112',
    label: 'Offices of Physicians, Mental Health Specialists',
  },
  { value: '6212', label: 'Offices of Dentists' },
  { value: '62121', label: 'Offices of Dentists' },
  { value: '621210', label: 'Offices of Dentists' },
  { value: '6213', label: 'Offices of Other Health Practitioners' },
  { value: '62131', label: 'Offices of Chiropractors' },
  { value: '621310', label: 'Offices of Chiropractors' },
  { value: '62132', label: 'Offices of Optometrists' },
  { value: '621320', label: 'Offices of Optometrists' },
  {
    value: '62133',
    label: 'Offices of Mental Health Practitioners (except Physicians)',
  },
  {
    value: '621330',
    label: 'Offices of Mental Health Practitioners (except Physicians)',
  },
  {
    value: '62134',
    label:
      'Offices of Physical, Occupational and Speech Therapists, and Audiologists',
  },
  {
    value: '621340',
    label:
      'Offices of Physical, Occupational and Speech Therapists, and Audiologists',
  },
  { value: '62139', label: 'Offices of All Other Health Practitioners' },
  { value: '621391', label: 'Offices of Podiatrists' },
  {
    value: '621399',
    label: 'Offices of All Other Miscellaneous Health Practitioners',
  },
  { value: '6214', label: 'Outpatient Care Centers' },
  { value: '62141', label: 'Family Planning Centers' },
  { value: '621410', label: 'Family Planning Centers' },
  {
    value: '62142',
    label: 'Outpatient Mental Health and Substance Abuse Centers',
  },
  {
    value: '621420',
    label: 'Outpatient Mental Health and Substance Abuse Centers',
  },
  { value: '62149', label: 'Other Outpatient Care Centers' },
  { value: '621491', label: 'HMO Medical Centers' },
  { value: '621492', label: 'Kidney Dialysis Centers' },
  {
    value: '621493',
    label: 'Freestanding Ambulatory Surgical and Emergency Centers',
  },
  { value: '621498', label: 'All Other Outpatient Care Centers' },
  { value: '6215', label: 'Medical and Diagnostic Laboratories' },
  { value: '62151', label: 'Medical and Diagnostic Laboratories' },
  { value: '621511', label: 'Medical Laboratories' },
  { value: '621512', label: 'Diagnostic Imaging Centers' },
  { value: '6216', label: 'Home Health Care Services' },
  { value: '62161', label: 'Home Health Care Services' },
  { value: '621610', label: 'Home Health Care Services' },
  { value: '6219', label: 'Other Ambulatory Health Care Services' },
  { value: '62191', label: 'Ambulance Services' },
  { value: '621910', label: 'Ambulance Services' },
  { value: '62199', label: 'All Other Ambulatory Health Care Services' },
  { value: '621991', label: 'Blood and Organ Banks' },
  {
    value: '621999',
    label: 'All Other Miscellaneous Ambulatory Health Care Services',
  },
  { value: '622', label: 'Hospitals' },
  { value: '6221', label: 'General Medical and Surgical Hospitals' },
  { value: '62211', label: 'General Medical and Surgical Hospitals' },
  { value: '622110', label: 'General Medical and Surgical Hospitals' },
  { value: '6222', label: 'Psychiatric and Substance Abuse Hospitals' },
  { value: '62221', label: 'Psychiatric and Substance Abuse Hospitals' },
  { value: '622210', label: 'Psychiatric and Substance Abuse Hospitals' },
  {
    value: '6223',
    label: 'Specialty (except Psychiatric and Substance Abuse) Hospitals',
  },
  {
    value: '62231',
    label: 'Specialty (except Psychiatric and Substance Abuse) Hospitals',
  },
  {
    value: '622310',
    label: 'Specialty (except Psychiatric and Substance Abuse) Hospitals',
  },
  { value: '623', label: 'Nursing and Residential Care Facilities' },
  {
    value: '6231',
    label: 'Nursing Care Facilities (Skilled Nursing Facilities)',
  },
  {
    value: '62311',
    label: 'Nursing Care Facilities (Skilled Nursing Facilities)',
  },
  {
    value: '623110',
    label: 'Nursing Care Facilities (Skilled Nursing Facilities)',
  },
  {
    value: '6232',
    label:
      'Residential Intellectual and Developmental Disability, Mental Health, and Substance Abuse Facilities',
  },
  {
    value: '62321',
    label: 'Residential Intellectual and Developmental Disability Facilities',
  },
  {
    value: '623210',
    label: 'Residential Intellectual and Developmental Disability Facilities',
  },
  {
    value: '62322',
    label: 'Residential Mental Health and Substance Abuse Facilities',
  },
  {
    value: '623220',
    label: 'Residential Mental Health and Substance Abuse Facilities',
  },
  {
    value: '6233',
    label:
      'Continuing Care Retirement Communities and Assisted Living Facilities for the Elderly',
  },
  {
    value: '62331',
    label:
      'Continuing Care Retirement Communities and Assisted Living Facilities for the Elderly',
  },
  { value: '623311', label: 'Continuing Care Retirement Communities' },
  {
    value: '623312',
    label: 'Assisted Living Facilities for the Elderly',
  },
  { value: '6239', label: 'Other Residential Care Facilities' },
  { value: '62399', label: 'Other Residential Care Facilities' },
  { value: '623990', label: 'Other Residential Care Facilities' },
  { value: '624', label: 'Social Assistance' },
  { value: '6241', label: 'Individual and Family Services' },
  { value: '62411', label: 'Child and Youth Services' },
  { value: '624110', label: 'Child and Youth Services' },
  {
    value: '62412',
    label: 'Services for the Elderly and Persons with Disabilities',
  },
  {
    value: '624120',
    label: 'Services for the Elderly and Persons with Disabilities',
  },
  { value: '62419', label: 'Other Individual and Family Services' },
  { value: '624190', label: 'Other Individual and Family Services' },
  {
    value: '6242',
    label:
      'Community Food and Housing, and Emergency and Other Relief Services',
  },
  { value: '62421', label: 'Community Food Services' },
  { value: '624210', label: 'Community Food Services' },
  { value: '62422', label: 'Community Housing Services' },
  { value: '624221', label: 'Temporary Shelters' },
  { value: '624229', label: 'Other Community Housing Services' },
  { value: '62423', label: 'Emergency and Other Relief Services' },
  { value: '624230', label: 'Emergency and Other Relief Services' },
  { value: '6243', label: 'Vocational Rehabilitation Services' },
  { value: '62431', label: 'Vocational Rehabilitation Services' },
  { value: '624310', label: 'Vocational Rehabilitation Services' },
  { value: '6244', label: 'Child Care Services' },
  { value: '62441', label: 'Child Care Services' },
  { value: '624410', label: 'Child Care Services' },
  { value: '71', label: 'Arts, Entertainment, and Recreation' },
  {
    value: '711',
    label: 'Performing Arts, Spectator Sports, and Related Industries',
  },
  { value: '7111', label: 'Performing Arts Companies' },
  { value: '71111', label: 'Theater Companies and Dinner Theaters' },
  { value: '711110', label: 'Theater Companies and Dinner Theaters' },
  { value: '71112', label: 'Dance Companies' },
  { value: '711120', label: 'Dance Companies' },
  { value: '71113', label: 'Musical Groups and Artists' },
  { value: '711130', label: 'Musical Groups and Artists' },
  { value: '71119', label: 'Other Performing Arts Companies' },
  { value: '711190', label: 'Other Performing Arts Companies' },
  { value: '7112', label: 'Spectator Sports' },
  { value: '71121', label: 'Spectator Sports' },
  { value: '711211', label: 'Sports Teams and Clubs' },
  { value: '711212', label: 'Racetracks' },
  { value: '711219', label: 'Other Spectator Sports' },
  {
    value: '7113',
    label: 'Promoters of Performing Arts, Sports, and Similar Events',
  },
  {
    value: '71131',
    label:
      'Promoters of Performing Arts, Sports, and Similar Events with Facilities',
  },
  {
    value: '711310',
    label:
      'Promoters of Performing Arts, Sports, and Similar Events with Facilities',
  },
  {
    value: '71132',
    label:
      'Promoters of Performing Arts, Sports, and Similar Events without Facilities',
  },
  {
    value: '711320',
    label:
      'Promoters of Performing Arts, Sports, and Similar Events without Facilities',
  },
  {
    value: '7114',
    label:
      'Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures',
  },
  {
    value: '71141',
    label:
      'Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures',
  },
  {
    value: '711410',
    label:
      'Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures',
  },
  {
    value: '7115',
    label: 'Independent Artists, Writers, and Performers',
  },
  {
    value: '71151',
    label: 'Independent Artists, Writers, and Performers',
  },
  {
    value: '711510',
    label: 'Independent Artists, Writers, and Performers',
  },
  {
    value: '712',
    label: 'Museums, Historical Sites, and Similar Institutions',
  },
  {
    value: '7121',
    label: 'Museums, Historical Sites, and Similar Institutions',
  },
  { value: '71211', label: 'Museums' },
  { value: '712110', label: 'Museums' },
  { value: '71212', label: 'Historical Sites' },
  { value: '712120', label: 'Historical Sites' },
  { value: '71213', label: 'Zoos and Botanical Gardens' },
  { value: '712130', label: 'Zoos and Botanical Gardens' },
  {
    value: '71219',
    label: 'Nature Parks and Other Similar Institutions',
  },
  {
    value: '712190',
    label: 'Nature Parks and Other Similar Institutions',
  },
  {
    value: '713',
    label: 'Amusement, Gambling, and Recreation Industries',
  },
  { value: '7131', label: 'Amusement Parks and Arcades' },
  { value: '71311', label: 'Amusement and Theme Parks' },
  { value: '713110', label: 'Amusement and Theme Parks' },
  { value: '71312', label: 'Amusement Arcades' },
  { value: '713120', label: 'Amusement Arcades' },
  { value: '7132', label: 'Gambling Industries' },
  { value: '71321', label: 'Casinos (except Casino Hotels)' },
  { value: '713210', label: 'Casinos (except Casino Hotels)' },
  { value: '71329', label: 'Other Gambling Industries' },
  { value: '713290', label: 'Other Gambling Industries' },
  { value: '7139', label: 'Other Amusement and Recreation Industries' },
  { value: '71391', label: 'Golf Courses and Country Clubs' },
  { value: '713910', label: 'Golf Courses and Country Clubs' },
  { value: '71392', label: 'Skiing Facilities' },
  { value: '713920', label: 'Skiing Facilities' },
  { value: '71393', label: 'Marinas' },
  { value: '713930', label: 'Marinas' },
  { value: '71394', label: 'Fitness and Recreational Sports Centers' },
  { value: '713940', label: 'Fitness and Recreational Sports Centers' },
  { value: '71395', label: 'Bowling Centers' },
  { value: '713950', label: 'Bowling Centers' },
  {
    value: '71399',
    label: 'All Other Amusement and Recreation Industries',
  },
  {
    value: '713990',
    label: 'All Other Amusement and Recreation Industries',
  },
  { value: '72', label: 'Accommodation and Food Services' },
  { value: '721', label: 'Accommodation' },
  { value: '7211', label: 'Traveler Accommodation' },
  { value: '72111', label: 'Hotels (except Casino Hotels) and Motels' },
  { value: '721110', label: 'Hotels (except Casino Hotels) and Motels' },
  { value: '72112', label: 'Casino Hotels' },
  { value: '721120', label: 'Casino Hotels' },
  { value: '72119', label: 'Other Traveler Accommodation' },
  { value: '721191', label: 'Bed-and-Breakfast Inns' },
  { value: '721199', label: 'All Other Traveler Accommodation' },
  {
    value: '7212',
    label: 'RV (Recreational Vehicle) Parks and Recreational Camps',
  },
  {
    value: '72121',
    label: 'RV (Recreational Vehicle) Parks and Recreational Camps',
  },
  {
    value: '721211',
    label: 'RV (Recreational Vehicle) Parks and Campgrounds',
  },
  {
    value: '721214',
    label: 'Recreational and Vacation Camps (except Campgrounds)',
  },
  {
    value: '7213',
    label: "Rooming and Boarding Houses, Dormitories, and Workers' Camps",
  },
  {
    value: '72131',
    label: "Rooming and Boarding Houses, Dormitories, and Workers' Camps",
  },
  {
    value: '721310',
    label: "Rooming and Boarding Houses, Dormitories, and Workers' Camps",
  },
  { value: '722', label: 'Food Services and Drinking Places' },
  { value: '7223', label: 'Special Food Services' },
  { value: '72231', label: 'Food Service Contractors' },
  { value: '722310', label: 'Food Service Contractors' },
  { value: '72232', label: 'Caterers' },
  { value: '722320', label: 'Caterers' },
  { value: '72233', label: 'Mobile Food Services' },
  { value: '722330', label: 'Mobile Food Services' },
  { value: '7224', label: 'Drinking Places (Alcoholic Beverages)' },
  { value: '72241', label: 'Drinking Places (Alcoholic Beverages)' },
  { value: '722410', label: 'Drinking Places (Alcoholic Beverages)' },
  { value: '7225', label: 'Restaurants and Other Eating Places' },
  { value: '72251', label: 'Restaurants and Other Eating Places' },
  { value: '722511', label: 'Full-Service Restaurants' },
  { value: '722513', label: 'Limited-Service Restaurants' },
  { value: '722514', label: 'Cafeterias, Grill Buffets, and Buffets' },
  { value: '722515', label: 'Snack and Nonalcoholic Beverage Bars' },
  { value: '81', label: 'Other Services (except Public Administration)' },
  { value: '811', label: 'Repair and Maintenance' },
  { value: '8111', label: 'Automotive Repair and Maintenance' },
  {
    value: '81111',
    label: 'Automotive Mechanical and Electrical Repair and Maintenance',
  },
  { value: '811111', label: 'General Automotive Repair' },
  { value: '811114', label: 'Specialized Automotive Repair' },
  {
    value: '81112',
    label: 'Automotive Body, Paint, Interior, and Glass Repair',
  },
  {
    value: '811121',
    label: 'Automotive Body, Paint, and Interior Repair and Maintenance',
  },
  { value: '811122', label: 'Automotive Glass Replacement Shops' },
  { value: '81119', label: 'Other Automotive Repair and Maintenance' },
  {
    value: '811191',
    label: 'Automotive Oil Change and Lubrication Shops',
  },
  { value: '811192', label: 'Car Washes' },
  {
    value: '811198',
    label: 'All Other Automotive Repair and Maintenance',
  },
  {
    value: '8112',
    label: 'Electronic and Precision Equipment Repair and Maintenance',
  },
  {
    value: '81121',
    label: 'Electronic and Precision Equipment Repair and Maintenance',
  },
  {
    value: '811210',
    label: 'Electronic and Precision Equipment Repair and Maintenance',
  },
  {
    value: '8113',
    label:
      'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance',
  },
  {
    value: '81131',
    label:
      'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance',
  },
  {
    value: '811310',
    label:
      'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance',
  },
  {
    value: '8114',
    label: 'Personal and Household Goods Repair and Maintenance',
  },
  {
    value: '81141',
    label: 'Home and Garden Equipment and Appliance Repair and Maintenance',
  },
  {
    value: '811411',
    label: 'Home and Garden Equipment Repair and Maintenance',
  },
  { value: '811412', label: 'Appliance Repair and Maintenance' },
  { value: '81142', label: 'Reupholstery and Furniture Repair' },
  { value: '811420', label: 'Reupholstery and Furniture Repair' },
  { value: '81143', label: 'Footwear and Leather Goods Repair' },
  { value: '811430', label: 'Footwear and Leather Goods Repair' },
  {
    value: '81149',
    label: 'Other Personal and Household Goods Repair and Maintenance',
  },
  {
    value: '811490',
    label: 'Other Personal and Household Goods Repair and Maintenance',
  },
  { value: '812', label: 'Personal and Laundry Services' },
  { value: '8121', label: 'Personal Care Services' },
  { value: '81211', label: 'Hair, Nail, and Skin Care Services' },
  { value: '812111', label: 'Barber Shops' },
  { value: '812112', label: 'Beauty Salons' },
  { value: '812113', label: 'Nail Salons' },
  { value: '81219', label: 'Other Personal Care Services' },
  { value: '812191', label: 'Diet and Weight Reducing Centers' },
  { value: '812199', label: 'Other Personal Care Services' },
  { value: '8122', label: 'Death Care Services' },
  { value: '81221', label: 'Funeral Homes and Funeral Services' },
  { value: '812210', label: 'Funeral Homes and Funeral Services' },
  { value: '81222', label: 'Cemeteries and Crematories' },
  { value: '812220', label: 'Cemeteries and Crematories' },
  { value: '8123', label: 'Drycleaning and Laundry Services' },
  { value: '81231', label: 'Coin-Operated Laundries and Drycleaners' },
  { value: '812310', label: 'Coin-Operated Laundries and Drycleaners' },
  {
    value: '81232',
    label: 'Drycleaning and Laundry Services (except Coin-Operated)',
  },
  {
    value: '812320',
    label: 'Drycleaning and Laundry Services (except Coin-Operated)',
  },
  { value: '81233', label: 'Linen and Uniform Supply' },
  { value: '812331', label: 'Linen Supply' },
  { value: '812332', label: 'Industrial Launderers' },
  { value: '8129', label: 'Other Personal Services' },
  { value: '81291', label: 'Pet Care (except Veterinary) Services' },
  { value: '812910', label: 'Pet Care (except Veterinary) Services' },
  { value: '81292', label: 'Photofinishing' },
  {
    value: '812921',
    label: 'Photofinishing Laboratories (except One-Hour)',
  },
  { value: '812922', label: 'One-Hour Photofinishing' },
  { value: '81293', label: 'Parking Lots and Garages' },
  { value: '812930', label: 'Parking Lots and Garages' },
  { value: '81299', label: 'All Other Personal Services' },
  { value: '812990', label: 'All Other Personal Services' },
  {
    value: '813',
    label:
      'Religious, Grantmaking, Civic, Professional, and Similar Organizations',
  },
  { value: '8131', label: 'Religious Organizations' },
  { value: '81311', label: 'Religious Organizations' },
  { value: '813110', label: 'Religious Organizations' },
  { value: '8132', label: 'Grantmaking and Giving Services' },
  { value: '81321', label: 'Grantmaking and Giving Services' },
  { value: '813211', label: 'Grantmaking Foundations' },
  { value: '813212', label: 'Voluntary Health Organizations' },
  { value: '813219', label: 'Other Grantmaking and Giving Services' },
  { value: '8133', label: 'Social Advocacy Organizations' },
  { value: '81331', label: 'Social Advocacy Organizations' },
  { value: '813311', label: 'Human Rights Organizations' },
  {
    value: '813312',
    label: 'Environment, Conservation and Wildlife Organizations',
  },
  { value: '813319', label: 'Other Social Advocacy Organizations' },
  { value: '8134', label: 'Civic and Social Organizations' },
  { value: '81341', label: 'Civic and Social Organizations' },
  { value: '813410', label: 'Civic and Social Organizations' },
  {
    value: '8139',
    label:
      'Business, Professional, Labor, Political, and Similar Organizations',
  },
  { value: '81391', label: 'Business Associations' },
  { value: '813910', label: 'Business Associations' },
  { value: '81392', label: 'Professional Organizations' },
  { value: '813920', label: 'Professional Organizations' },
  {
    value: '81393',
    label: 'Labor Unions and Similar Labor Organizations',
  },
  {
    value: '813930',
    label: 'Labor Unions and Similar Labor Organizations',
  },
  { value: '81394', label: 'Political Organizations' },
  { value: '813940', label: 'Political Organizations' },
  {
    value: '81399',
    label:
      'Other Similar Organizations (except Business, Professional, Labor, and Political Organizations)',
  },
  {
    value: '813990',
    label:
      'Other Similar Organizations (except Business, Professional, Labor, and Political Organizations)',
  },
  { value: '814', label: 'Private Households' },
  { value: '8141', label: 'Private Households' },
  { value: '81411', label: 'Private Households' },
  { value: '814110', label: 'Private Households' },
  { value: '92', label: 'Public Administration' },
  {
    value: '921',
    label: 'Executive, Legislative, and Other General Government Support',
  },
  {
    value: '9211',
    label: 'Executive, Legislative, and Other General Government Support',
  },
  { value: '92111', label: 'Executive Offices' },
  { value: '921110', label: 'Executive Offices' },
  { value: '92112', label: 'Legislative Bodies' },
  { value: '921120', label: 'Legislative Bodies' },
  { value: '92113', label: 'Public Finance Activities' },
  { value: '921130', label: 'Public Finance Activities' },
  {
    value: '92114',
    label: 'Executive and Legislative Offices, Combined',
  },
  {
    value: '921140',
    label: 'Executive and Legislative Offices, Combined',
  },
  {
    value: '92115',
    label: 'American Indian and Alaska Native Tribal Governments',
  },
  {
    value: '921150',
    label: 'American Indian and Alaska Native Tribal Governments',
  },
  { value: '92119', label: 'Other General Government Support' },
  { value: '921190', label: 'Other General Government Support' },
  { value: '922', label: 'Justice, Public Order, and Safety Activities' },
  {
    value: '9221',
    label: 'Justice, Public Order, and Safety Activities',
  },
  { value: '92211', label: 'Courts' },
  { value: '922110', label: 'Courts' },
  { value: '92212', label: 'Police Protection' },
  { value: '922120', label: 'Police Protection' },
  { value: '92213', label: 'Legal Counsel and Prosecution' },
  { value: '922130', label: 'Legal Counsel and Prosecution' },
  { value: '92214', label: 'Correctional Institutions' },
  { value: '922140', label: 'Correctional Institutions' },
  { value: '92215', label: 'Parole Offices and Probation Offices' },
  { value: '922150', label: 'Parole Offices and Probation Offices' },
  { value: '92216', label: 'Fire Protection' },
  { value: '922160', label: 'Fire Protection' },
  {
    value: '92219',
    label: 'Other Justice, Public Order, and Safety Activities',
  },
  {
    value: '922190',
    label: 'Other Justice, Public Order, and Safety Activities',
  },
  { value: '923', label: 'Administration of Human Resource Programs' },
  { value: '9231', label: 'Administration of Human Resource Programs' },
  { value: '92311', label: 'Administration of Education Programs' },
  { value: '923110', label: 'Administration of Education Programs' },
  { value: '92312', label: 'Administration of Public Health Programs' },
  { value: '923120', label: 'Administration of Public Health Programs' },
  {
    value: '92313',
    label:
      "Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs)",
  },
  {
    value: '923130',
    label:
      "Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs)",
  },
  { value: '92314', label: "Administration of Veterans' Affairs" },
  { value: '923140', label: "Administration of Veterans' Affairs" },
  {
    value: '924',
    label: 'Administration of Environmental Quality Programs',
  },
  {
    value: '9241',
    label: 'Administration of Environmental Quality Programs',
  },
  {
    value: '92411',
    label:
      'Administration of Air and Water Resource and Solid Waste Management Programs',
  },
  {
    value: '924110',
    label:
      'Administration of Air and Water Resource and Solid Waste Management Programs',
  },
  { value: '92412', label: 'Administration of Conservation Programs' },
  { value: '924120', label: 'Administration of Conservation Programs' },
  {
    value: '925',
    label:
      'Administration of Housing Programs, Urban Planning, and Community Development',
  },
  {
    value: '9251',
    label:
      'Administration of Housing Programs, Urban Planning, and Community Development',
  },
  { value: '92511', label: 'Administration of Housing Programs' },
  { value: '925110', label: 'Administration of Housing Programs' },
  {
    value: '92512',
    label:
      'Administration of Urban Planning and Community and Rural Development',
  },
  {
    value: '925120',
    label:
      'Administration of Urban Planning and Community and Rural Development',
  },
  { value: '926', label: 'Administration of Economic Programs' },
  { value: '9261', label: 'Administration of Economic Programs' },
  {
    value: '92611',
    label: 'Administration of General Economic Programs',
  },
  {
    value: '926110',
    label: 'Administration of General Economic Programs',
  },
  {
    value: '92612',
    label: 'Regulation and Administration of Transportation Programs',
  },
  {
    value: '926120',
    label: 'Regulation and Administration of Transportation Programs',
  },
  {
    value: '92613',
    label:
      'Regulation and Administration of Communications, Electric, Gas, and Other Utilities',
  },
  {
    value: '926130',
    label:
      'Regulation and Administration of Communications, Electric, Gas, and Other Utilities',
  },
  {
    value: '92614',
    label: 'Regulation of Agricultural Marketing and Commodities',
  },
  {
    value: '926140',
    label: 'Regulation of Agricultural Marketing and Commodities',
  },
  {
    value: '92615',
    label:
      'Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors',
  },
  {
    value: '926150',
    label:
      'Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors',
  },
  { value: '927', label: 'Space Research and Technology' },
  { value: '9271', label: 'Space Research and Technology' },
  { value: '92711', label: 'Space Research and Technology' },
  { value: '927110', label: 'Space Research and Technology' },
  { value: '928', label: 'National Security and International Affairs' },
  { value: '9281', label: 'National Security and International Affairs' },
  { value: '92811', label: 'National Security' },
  { value: '928110', label: 'National Security' },
  { value: '92812', label: 'International Affairs' },
  { value: '928120', label: 'International Affairs' },
];

export const BUSINESS_VERTICALS = [
  {
    value: 'AgricultureForestryFishingOrHunting',
    label: 'Agriculture Forestry Fishing Or Hunting',
  },
  {
    value: 'ArtsEntertainmentAndRecreation',
    label: 'Arts Entertainment And Recreation',
  },
  {
    value: 'BusinessSupportOrBuildingServices',
    label: 'Business Support Or Building Services',
  },
  { value: 'Construction', label: 'Construction' },
  {
    value: 'DirectMarketingOrTelemarketing',
    label: 'Direct Marketing Or Telemarketing',
  },
  { value: 'EducationalServices', label: 'Educational Services' },
  {
    value: 'FinancialServicesCryptocurrency',
    label: 'Financial Services Cryptocurrency',
  },
  {
    value: 'FinancialServicesDebitCollectionOrConsolidation',
    label: 'Financial Services Debt Collection Or Consolidation',
  },
  {
    value: 'FinancialServicesMoneyServicesBusinessOrCurrencyExchange',
    label: 'Financial Services Money Services Business Or Currency Exchange',
  },
  { value: 'FinancialServicesOther', label: 'Financial Services Other' },
  {
    value: 'FinancialServicesPaydayLending',
    label: 'Financial Services Payday Lending',
  },
  { value: 'GamingOrGambling', label: 'Gaming Or Gambling' },
  {
    value: 'HealthCareAndSocialAssistance',
    label: 'Health Care And Social Assistance',
  },
  {
    value: 'HospitalityAccommodationOrFoodServices',
    label: 'Hospitality Accommodation Or Food Services',
  },
  {
    value: 'LegalAccountingConsultingOrComputerProgramming',
    label: 'Legal Accounting Consulting Or Computer Programming',
  },
  { value: 'Manufacturing', label: 'Manufacturing' },
  { value: 'Mining', label: 'Mining' },
  { value: 'Nutraceuticals', label: 'Nutraceuticals' },
  { value: 'PersonalCareServices', label: 'Personal Care Services' },
  { value: 'PublicAdministration', label: 'Public Administration' },
  { value: 'RealEstate', label: 'Real Estate' },
  {
    value: 'ReligiousCivicAndSocialOrganizations',
    label: 'Religious Civic And Social Organizations',
  },
  { value: 'RepairAndMaintenance', label: 'Repair And Maintenance' },
  { value: 'RetailTrade', label: 'Retail Trade' },
  { value: 'TechnologyMediaOrTelecom', label: 'Technology Media Or Telecom' },
  {
    value: 'TransportationOrWarehousing',
    label: 'Transportation Or Warehousing',
  },
  { value: 'Utilities', label: 'Utilities' },
  { value: 'WholesaleTrade', label: 'Wholesale Trade' },
  {
    value: 'AdultEntertainmentDatingOrEscortServices',
    label: 'Adult Entertainment Dating Or Escort Services',
  },
  { value: 'Cannabis', label: 'Cannabis' },
];

export const AUTHORIZATIONS = {
  achDebit: {
    contents:
      'By clicking "Transfer Funds", I authorize Flex to immediately debit the amount above from my bank account. Once I authorize this payment, I understand there cannot be any changes or corrections. Transactions made after 5:00 PM EST or on a weekend or holiday will be processed the next business day. It is recommended that you print a copy of this authorization and maintain it for your records.',
  },
  default: {
    contents:
      'By clicking "Transfer Funds", I authorize Flex to initiate the transaction detailed above.',
  },
};
