import { FilledBadge } from '@common/utilities/self-service-dashboard/filled-badge';
import { ActionItemConstants } from '@common/utilities/self-service-dashboard/action-items/action-item-constants';
import { useSelfServiceStyles } from '@common/utilities/self-service-dashboard/self-service-dashboard.styles';
import { Box, Button, Group, Loader, Text } from '@mantine/core';
import { useUploadCompanyDocumentMutation } from '@queries/use-company-documents';
import { CompanyDocument } from '@services/flexbase/flexbase-onboarding-client';
import { DateTime } from 'luxon';
import { ChangeEvent, useMemo, useRef, useState } from 'react';
import { AlertTriangleIcon, UploadIcon } from 'assets/svg';
import ExpandableFile from './expandable-file';

type Props = {
  docName: string;
  uploadedDocs: CompanyDocument[];
  onUploadError: (error: string) => void;
};

// todo get the proper coloring on the button, move inline sx styles, idk
export const RequestedDocRow = ({
  docName,
  uploadedDocs,
  onUploadError,
}: Props) => {
  const { classes, cx } = useSelfServiceStyles();
  const { mutate, isPending, isError } = useUploadCompanyDocumentMutation();
  const ref = useRef<HTMLInputElement>(null);
  const [sliceValue, setSliceValue] = useState(2); // Only show max 2 by default
  const [stagedFileName, setStagedFileName] = useState('');
  // always with the hacks
  const [nonMutationError, setNonMutationError] = useState(false);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.currentTarget?.files && event.currentTarget.files.length > 0) {
      const file = event.currentTarget.files[0];
      setStagedFileName(file.name);
      const fileSizeInMb = file.size / (1024 * 1024);

      if (fileSizeInMb > 49) {
        setNonMutationError(true);
        onUploadError(ActionItemConstants.FILE_SIZE_ERROR);
        return;
      }

      setNonMutationError(false);

      mutate(
        {
          file: event.currentTarget.files[0],
          description: docName,
          status: 'Pending Review',
        },
        {
          onSuccess: () => {
            onUploadError(ActionItemConstants.CLEAR_ERROR);
            setStagedFileName('');
            setSliceValue((prev) => prev + 1);
          },
          onError: () => {
            onUploadError(ActionItemConstants.FILE_UPLOAD_ERROR);
          },
        },
      );
    }
  };

  const handleShowMoreClick = () => {
    setSliceValue((prev) =>
      prev < uploadedDocs.length ? uploadedDocs.length : 2,
    );
  };

  const badgeVariant = useMemo(() => {
    if (!uploadedDocs.length) {
      return { label: 'No document submitted', variant: 'default' };
    }
    const sorted = uploadedDocs.toSorted((a, b) => {
      const aDate = DateTime.fromSQL(a.uploadedAt);
      const bDate = DateTime.fromSQL(b.uploadedAt);
      return bDate.diff(aDate).milliseconds;
    });

    const firstItemStatus = sorted[0].status;

    switch (firstItemStatus) {
      case 'Requested':
        return { label: 'Rejected', variant: 'warning' };
      case 'Approved':
        return { label: 'Approved', variant: 'success' };
      case 'Pending Review':
      default:
        return { label: 'Pending review', variant: 'secondary' };
    }
  }, [uploadedDocs]);

  const hasMultipleFiles =
    uploadedDocs.length > 1 || (uploadedDocs.length === 1 && !!stagedFileName);

  return (
    <Box
      className={cx(
        classes.gridTable,
        classes.gridTableRow,
        classes.requestedDocumentsTable,
        classes.requestedDocumentsRow,
        hasMultipleFiles && classes.requestedDocumentsRowMultiItem,
      )}
    >
      <Box className={classes.requestedDocName}>{docName}</Box>
      <Box className={classes.requestedDocBadge}>
        <FilledBadge variant={badgeVariant.variant}>
          {badgeVariant.label}
        </FilledBadge>
      </Box>
      <div>
        <Button
          size="xs"
          variant="light"
          className={classes.primaryButton6}
          leftSection={<UploadIcon />}
          onClick={() => {
            if (ref.current) {
              ref.current.click();
            }
          }}
        >
          Upload
        </Button>
      </div>
      <Box className={classes.requestedDocFiles}>
        {stagedFileName && (
          <Group
            justify="space-between"
            c={isError || nonMutationError ? 'critical.2' : 'neutral.8'}
            mt="xxs"
          >
            <Text fz={14} maw="85%">
              {stagedFileName}
            </Text>
            {isPending ? (
              <Loader size={14} />
            ) : isError || nonMutationError ? (
              <AlertTriangleIcon height="14px" width="14px" />
            ) : (
              false
            )}
          </Group>
        )}
        {uploadedDocs
          .sort((a, b) => {
            const aDate = DateTime.fromSQL(a.uploadedAt);
            const bDate = DateTime.fromSQL(b.uploadedAt);
            return bDate.diff(aDate).milliseconds;
          })
          .slice(0, sliceValue)
          .map((d) => (
            <ExpandableFile
              id={d.docId}
              filename={d.metadata.sourceName}
              key={d.docId}
            />
          ))}
        {uploadedDocs.length > 2 && (
          <Text
            mt="xs"
            c="primarySecondarySuccess.2"
            onClick={handleShowMoreClick}
            sx={() => ({
              cursor: 'pointer',
              textAlign: 'right',
              '&:hover': {
                textDecoration: 'underline',
              },
            })}
          >
            {sliceValue < uploadedDocs.length ? 'Show more' : 'Show less'}
          </Text>
        )}
      </Box>

      <input
        type="file"
        hidden
        ref={ref}
        onChange={handleFileChange}
        accept="image/*,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx"
      />
    </Box>
  );
};
