import { Image, Text, rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import WatchBAnking from 'assets/images/watch-banking.png';
import { PropsWithChildren } from 'react';

export const useGenericErrorStyles = createStyles((theme) => ({
  emptyState: {
    height: '60vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',

    '.image-container': {
      display: 'flex',
      marginTop: rem(50),
      justifyContent: 'center',
    },

    p: {
      margin: 0,
      fontSize: rem(14),
      textAlign: 'center',
      color: theme.colors.neutral[6],
    },
  },
}));

const GenericError = ({ children }: PropsWithChildren) => {
  const { classes } = useGenericErrorStyles();
  return (
    <div className={classes.emptyState}>
      <Image
        width={rem(200)}
        src={WatchBAnking}
        alt="Display info error"
        className="image-container"
      />
      <Text ff="redaction" fz={rem(36)} ta="center" mt={rem(30)}>
        Sorry about that...
      </Text>
      {children}
    </div>
  );
};

export default GenericError;
