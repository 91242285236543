import { Stack, Switch } from '@mantine/core';
import { useCreateSpendPlanContext } from '../../create-spend-plan.context';
import { Indent } from '../../components/indent';
import {
  CreateSpendPlanWizard,
  useCreateSpendPlanWizard,
} from '../../create-spend-plan.wizard';
import { SpendPlanReviewStep } from '../review/review-step';
import { SpendPlanCurrencyInput } from '../../components/spend-plan-currency-input';
import { noop } from 'underscore';

const STEP_ID = 'spend-controls-step';

export const SpendPlanSpendControlsStep = () => {
  const { spendControlsForm: form, getInvalidSteps } =
    useCreateSpendPlanContext();
  const {
    goToNextStep,
    goToStep,
    getLastVisitedStep,
    state,
    navigatedFromPreview,
  } = useCreateSpendPlanWizard();

  const isOneTimePlan = state.recurring === 'onetime';

  const isFromReviewPreview =
    navigatedFromPreview &&
    getLastVisitedStep()?.id === SpendPlanReviewStep.stepId;

  const handleNext = form.onSubmit((formValues) => {
    const invalidSteps = getInvalidSteps().filter((s) => s !== STEP_ID);

    if (invalidSteps.length) {
      return goToStep(invalidSteps[0], formValues);
    }

    if (isFromReviewPreview) {
      return goToStep(SpendPlanReviewStep.stepId, formValues);
    }

    goToNextStep(formValues);
  });

  return (
    <CreateSpendPlanWizard.Step
      onNext={handleNext}
      hideBack={isFromReviewPreview}
    >
      <Stack gap={24}>
        <SpendPlanCurrencyInput
          label={isOneTimePlan ? 'Spend limit' : 'Period spend limit'}
          autoFocus
          {...form.getInputProps('currentLimit')}
          onChange={noop}
          onValueChange={(value) => {
            form.setFieldValue('currentLimit', value.floatValue);
          }}
        />

        {!isOneTimePlan ? (
          <>
            <Switch
              label="Set a different limit for future periods"
              labelPosition="left"
              description="The above limit will apply to the current period only."
              {...form.getInputProps('repeatCurrentLimit', {
                type: 'checkbox',
              })}
              onChange={(e) =>
                form.setFieldValue('repeatCurrentLimit', !e.target.checked)
              }
              checked={!state.repeatCurrentLimit}
            />

            <Indent visible={!state.repeatCurrentLimit}>
              <SpendPlanCurrencyInput
                label="Future period spend limit"
                description="After the current period ends, this limit will apply."
                {...form.getInputProps('futureLimit')}
                onChange={noop}
                onValueChange={(value) => {
                  form.setFieldValue('futureLimit', value.floatValue);
                }}
              />
            </Indent>
          </>
        ) : null}
      </Stack>
    </CreateSpendPlanWizard.Step>
  );
};

SpendPlanSpendControlsStep.stepId = STEP_ID;
