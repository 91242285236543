import { Box, Group, Text, rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { FilterData } from '@common/utilities/filter/filters';
import { PiX } from 'react-icons/pi';

type FilterChipsProps<T> = {
  filterArray: FilterData<T>[];
  onRemoveFilter?: (filterKey: string) => void;
  onClearFilters?: () => void;
};

export const FilterChips = function <T>({
  filterArray,
  onRemoveFilter,
  onClearFilters,
}: FilterChipsProps<T>) {
  const { classes } = useStyles();
  const filterChips = filterArray.filter((f) => f.showChip);

  const handleRemove = (filterKey: string) => {
    onRemoveFilter?.(filterKey);
  };

  const handleClear = () => {
    onClearFilters?.();
  };

  return (
    filterChips.length > 0 && (
      <Group gap={rem(16)} mih={rem(30)} mb="md">
        {filterChips.map((p) => (
          <Box
            key={p.key}
            className={classes.filterChip}
            onClick={() => handleRemove(p.key)}
          >
            <Text color="black" fz={rem(14)} fw={500}>
              {p.label}
            </Text>

            <PiX color="black" />
          </Box>
        ))}

        <Text className={classes.clearFilters} onClick={handleClear}>
          Clear filters
        </Text>
      </Group>
    )
  );
};

const useStyles = createStyles((theme, _, helpers) => ({
  filterChip: {
    height: rem(30),
    borderRadius: theme.radius.xs,
    border: `1px solid ${theme.colors.neutral[3]}`,
    backgroundColor: theme.colors.neutral[2],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: `0px ${theme.spacing.md}`,
    gap: 4,
    [helpers.smallerThan('md')]: {
      height: 'auto',
      padding: `0px ${theme.spacing.xl}`,
    },
  },
  clearFilters: {
    cursor: 'pointer',
    color: theme.colors.primarySecondarySuccess[2],
  },
}));
