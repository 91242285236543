import { platformClient } from '../../../../services/platform/platform-client';
import {
  PLATFORM_CLIENT_ID,
  ProductApplicationRoutes,
} from '../../../../constants/onboarding.constants';
import { formatPhoneForApi } from '@utilities/formatters';
import { flexbaseOnboardingClient } from '../../../../services/flexbase-client';
import { ReactNode, useState } from 'react';
import { UseFormReturnType } from '@mantine/form';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import {
  PromoCodeState,
  RegistrationProductsState,
} from '../../../../recoil-state/application/product-onboarding';
import { useAuthToken } from 'providers/auth.provider';
import { useRouteSectionContext } from '../../../../providers/route-context';
import { formatDateForApi } from '../../../../utilities/formatters/format-date-input';
// import { FullStory } from '@fullstory/browser';
// import { getEnvironment } from 'utilities/url/window-helpers';
import { PasswordError } from '@common/password-error';
import { platformSdk } from '../../../../services/platform-sdk';
import { APIError } from '@flexbase-eng/sdk-typescript/models/errors';
import { RFCDate } from '@flexbase-eng/sdk-typescript/types';
import {
  isPhoneNotSupportedError,
  PHONE_NOT_SUPPORTED_ERROR_COPY,
} from 'constants/index';

/**
 * This state represents the current selected account and the corresponding person id & business id, and has
 * a list of all available account ids.
 */
// export const AccountIdState = atom<{
//   selectedAccountId?: string;
//   personId?: string;
//   businessId?: string;
//   availableAccountsIds: string[];
// }>({
//   key: 'platform_account_state',
//   default: { availableAccountsIds: [] },
// });

// The above is meant for multi-account usage
export const PlatformAccountState = atom<
  { accountId: string; personId: string; businessId: string } | undefined
>({
  key: 'platform_account_state',
  default: undefined,
});

export type RegistrationFormReturnType = {
  firstName?: string;
  lastName?: string;
  email: string;
  cellphone: string;
  password: string;
  confirmPassword?: string;
  is18?: boolean;
  termsOfServiceSigned: boolean;
  birthday?: string;
};

export const useRegistration = (
  form: UseFormReturnType<RegistrationFormReturnType>,
  options?: Partial<{ usePrefillApplication: boolean }>,
) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ReactNode>('');
  const { externalId } = useRecoilValue(PromoCodeState);
  const [platformAccount, setPlatformAccount] =
    useRecoilState(PlatformAccountState);
  const product = useRecoilValue(RegistrationProductsState);
  const { signIn } = useAuthToken();
  const { setSectionAndNavigate } = useRouteSectionContext();
  const { code } = useRecoilValue(PromoCodeState);
  const isBankingOnly = product.every((p) => p === 'BANKING');
  const isCreditOnly = product.every((p) => p === 'CREDIT');
  // const hostEnv = getEnvironment(window.location.host);

  const registerNewUser = async () => {
    const validationResult = form.validate();

    if (!validationResult.hasErrors) {
      setLoading(true);
      const formValues = form.values;
      try {
        let personId: string;
        let accountId: string;

        if (!platformAccount) {
          const createAccountResponse =
            await platformSdk.accounts.createAccount({
              userName: formValues.email,
              password: formValues.password,
              clientId: PLATFORM_CLIENT_ID,
              name:
                formValues.firstName && formValues.lastName
                  ? `${formValues.firstName} ${formValues.lastName}`
                  : formValues.email,
              ...(code && { promoCode: code }),
            });

          personId = createAccountResponse.result.personId;
          accountId = createAccountResponse.result.accountId;

          setPlatformAccount({
            accountId: accountId,
            personId: personId,
            businessId: '',
          });
        } else {
          accountId = platformAccount.accountId;
          personId = platformAccount.personId;
        }

        const loginAttempt = await platformSdk.authorize.authorizeToken(
          {
            username: formValues.email,
            password: formValues.password,
            grantType: 'password',
            clientId: PLATFORM_CLIENT_ID,
          },
          { fetchOptions: { headers: { 'x-no-auth': 'no' } } },
        );

        signIn(
          {
            access_token: loginAttempt.accessToken,
            expires_in: loginAttempt.expiresIn,
            scope: loginAttempt.scope,
            refresh_token: loginAttempt.refreshToken,
            token_type: loginAttempt.tokenType,
          },
          {
            email: formValues.email,
            remember: true,
          },
        );

        let businessId: string;

        if (platformAccount?.businessId) {
          businessId = platformAccount.businessId;
        } else {
          const business = await platformClient.createBusiness(accountId, {
            active: true,
            name: 'My Company',
          });
          businessId = business.id;
          setPlatformAccount((prev) => {
            if (prev) {
              return { ...prev, businessId };
            }
          });
        }

        // full story
        /*
        if (hostEnv === 'production') {

          try {
            FullStory('setIdentityAsync', {
              uid: personId,
              properties: {
                companyId: businessId,
                accountId,
                email: formValues?.email,
              },
            });
          } catch (err) {
            console.error(`unable to load full story. ${err}`);
          }
        }
        */

        const hasPersonUpdate =
          !!formValues.birthday ||
          !!formValues.firstName ||
          !!formValues.lastName;

        await Promise.all([
          platformSdk.persons.postPersonsPhones({
            accountId,
            personId,
            phoneYaml: {
              value: '+1' + formatPhoneForApi(formValues.cellphone),
              isPrimary: true,
              type: 'mobile',
              active: true,
            },
          }),
          hasPersonUpdate
            ? platformSdk.persons.patchPerson({
                accountId,
                personId,
                personYaml: {
                  givenName: formValues.firstName,
                  familyName: formValues.lastName,
                  ...(formValues.birthday && {
                    dateOfBirth: new RFCDate(
                      formatDateForApi(formValues.birthday),
                    ),
                  }),
                },
              })
            : Promise.resolve(),
          flexbaseOnboardingClient.updateCompany({
            id: businessId,
            optedProducts: product,
            ...(externalId ? { trinetId: externalId } : {}),
          }),
          flexbaseOnboardingClient.updateUser({
            id: personId,
            termsOfServiceSigned: formValues.termsOfServiceSigned,
          }),
        ]);

        let applicationUrl: string;

        if (options?.usePrefillApplication) {
          applicationUrl = '/prefill';
        } else if (isBankingOnly) {
          applicationUrl = ProductApplicationRoutes.BANKING_ONLY;
        } else if (isCreditOnly) {
          applicationUrl = ProductApplicationRoutes.CREDIT_ONLY;
        } else {
          applicationUrl = ProductApplicationRoutes.COMBINED_CREDIT_BANKING;
        }

        setSectionAndNavigate('application', applicationUrl);
      } catch (e) {
        if (e instanceof APIError) {
          const body: {
            errors: Record<string, any>;
            message: string;
            statusCode: number;
          } = e.body ? JSON.parse(e.body) : null;
          if (e.statusCode === 409) {
            setErrorMessage('An account with this username already exists.');
          } else if (body.errors?.promoCode) {
            setErrorMessage(
              'Invalid referral URL. Referral codes are case sensitive. Please ensure that the URL exactly matches the one provided.',
            );
          } else if (body.errors?.password) {
            const passwordErrors = body.errors?.password;

            if (Array.isArray(passwordErrors)) {
              setErrorMessage(<PasswordError errors={passwordErrors} />);
            } else {
              setErrorMessage(
                'This password you attempted to use is invalid. Please use another password.',
              );
            }
          }
        } else if (isPhoneNotSupportedError(e)) {
          setErrorMessage(PHONE_NOT_SUPPORTED_ERROR_COPY);
        } else {
          setErrorMessage('An error occurred while creating your account.');
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return { loading, errorMessage, registerNewUser, setErrorMessage };
};
