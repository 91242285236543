import { atom } from 'recoil';
import {
  ActiveFiltersReturnType,
  createUseFiltersHook,
  FilterFnMap,
} from '@common/utilities/filter/filters';
import { SpendTransactionsTableRow } from '../spend-transaction-table-helpers';

const SpendTransactionsFilterState = atom<
  FilterFnMap<SpendTransactionsTableRow>
>({
  key: 'spend_transactions_filter_state',
  default: {},
});

const SpendTransactionsFilterStateModal = atom<
  FilterFnMap<SpendTransactionsTableRow>
>({
  key: 'spend_transactions_filter_state_modal',
  default: {},
});

export function useSpendTransactionFilters(): ActiveFiltersReturnType<SpendTransactionsTableRow> {
  return createUseFiltersHook<SpendTransactionsTableRow>(
    SpendTransactionsFilterState,
  );
}

export function useSpendTransactionModalFilters(): ActiveFiltersReturnType<SpendTransactionsTableRow> {
  return createUseFiltersHook<SpendTransactionsTableRow>(
    SpendTransactionsFilterStateModal,
  );
}
