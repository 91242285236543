import { useMediaQuery } from '@mantine/hooks';
import { CloseIconSquareEnds } from 'assets/svg';
import { PropsWithChildren } from 'react';
import { useStyles } from './send-payment.styles';
import FlexIconLink from '@common/composites/flex-icon-link';
import { useKeyPress } from 'utilities/custom-hooks';
import { getPlatform } from '@utilities/platform';

type Props = PropsWithChildren & {
  onClose: () => void;
  stepper: JSX.Element | boolean;
  stepperSmall: JSX.Element | boolean;
};

const SendPaymentLayout = ({
  children,
  onClose,
  stepper,
  stepperSmall,
}: Props) => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const platform = getPlatform();
  const { classes } = useStyles({ platform });

  useKeyPress('Escape', onClose);

  if (isMobile) {
    return (
      <div className={classes.smallModal}>
        <div
          className={classes.escapeIconSm}
          onClick={onClose}
          data-testid={'close'}
        >
          <CloseIconSquareEnds color="#979797" width={18} height={18} />
        </div>
        <div>
          {stepperSmall}
          {children}
        </div>
      </div>
    );
  }

  return (
    <div className={classes.fakeModal}>
      <div className={classes.leftContainer}>
        <FlexIconLink width={90} />
        {stepper}
      </div>
      {children}
      <div className={classes.rightContainer}>
        <div
          className={classes.escapeIcon}
          onClick={onClose}
          data-testid={'close'}
        >
          <CloseIconSquareEnds />
        </div>
      </div>
    </div>
  );
};

export default SendPaymentLayout;
