import { Box, Text } from '@mantine/core';
import { ReactNode } from 'react';
import { useModalStyles } from './modal-styles';
import { useMediaQuery } from '@mantine/hooks';
import { ArrowBackIconWhite } from '../../../assets/svg';
import useModal from './modal-hook';

type Props = {
  title: ReactNode;
  rightTitle: ReactNode;
  subtitle: ReactNode;
  rightSubtitle: ReactNode;
};

export const ModalHeaderBar = ({
  title,
  rightTitle,
  subtitle,
  rightSubtitle,
}: Props) => {
  const { classes } = useModalStyles();
  const isMobileView = useMediaQuery('(max-width: 767px)');
  const modal = useModal();
  return (
    <>
      {isMobileView && (
        <Box className={classes.backButtonBar}>
          <Box
            className={classes.iconContainer}
            onClick={() => modal.closeAllModals()}
          >
            <ArrowBackIconWhite height="16px" width="16px" />
          </Box>
        </Box>
      )}
      <Box className={classes.headerBar}>
        <Box className={classes.headerTextContainer}>
          <Text fz={20} lineClamp={1}>
            {title}
          </Text>
          <Text fz={20}>{rightTitle}</Text>
        </Box>
        <Box mt="md" className={classes.headerTextContainer}>
          <Text fz={14}>{subtitle}</Text>
          <Text fz={14}>{rightSubtitle}</Text>
        </Box>
      </Box>
    </>
  );
};
