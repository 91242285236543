import { Box, Collapse, Group, Radio, Stack, Switch } from '@mantine/core';
import {
  CreateSpendPlanWizard,
  useCreateSpendPlanWizard,
} from '../../create-spend-plan.wizard';
import FlexbaseSelect, { SelectItem } from '@common/composites/flexbase-select';
import {
  useActiveExpenseLink,
  useSyncExpenseAllowed,
} from '@utilities/integrations/accounting';
import { SpendPlanRadioCard } from './components/spend-plan-radio-card';
import { MerchantAllowIcon, MerchantBlockIcon } from 'assets/svg';
import { newCategories as mccCategorySelectItems } from 'constants/mcc-restrictions';
import { useSpendPlanMobile } from 'areas/spend-plans/utils/use-spend-plan-mobile';
import { PreviewLabel } from '../../components/preview-label';
import { SpendPlanChipList } from '../../components/spend-plan-chip';
import { useRestrictionsPreview } from './restrictions-step.preview';
import { SpendPlanReviewStep } from '../review/review-step';
import { useCreateSpendPlanContext } from '../../create-spend-plan.context';

const STEP_ID = 'restrictions';

export const SpendPlanRestrictionsStep = () => {
  const { restrictionsForm: form, getInvalidSteps } =
    useCreateSpendPlanContext();
  const isMobile = useSpendPlanMobile();
  const {
    goToNextStep,
    goToStep,
    getLastVisitedStep,
    state,
    navigatedFromPreview,
  } = useCreateSpendPlanWizard();
  const isSyncExpenseAllowed = useSyncExpenseAllowed();
  const { expenseLink } = useActiveExpenseLink();
  const {
    whitelistedAccountingCategories,
    blacklistedMccCodes,
    whitelistedMccCodes,
  } = useRestrictionsPreview();

  const isFromReviewPreview =
    navigatedFromPreview &&
    getLastVisitedStep()?.id === SpendPlanReviewStep.stepId;

  const isAccountingVisible =
    !!expenseLink?.enabledExpenses && isSyncExpenseAllowed;

  const isMccWhitelist = state.merchantCategoryRestrictionType === 'whitelist';
  const isMccBlacklist = state.merchantCategoryRestrictionType === 'blacklist';

  const accountingCategorySelectItems =
    expenseLink?.accounts
      ?.filter((category) => !state.accountingCategories.has(category.id))
      .map<SelectItem>((category) => {
        return {
          label: category.displayName ?? category.name,
          value: category.id,
        };
      }) || [];

  const mccBlacklistSelectItems = mccCategorySelectItems.filter((item) => {
    return !state.merchantCategoryBlacklist.has(item.value);
  });

  const mccWhitelistSelectItems = mccCategorySelectItems.filter((item) => {
    return !state.merchantCategoryWhitelist.has(item.value);
  });

  const handleNext = form.onSubmit((formValues) => {
    const invalidSteps = getInvalidSteps().filter((s) => s !== STEP_ID);

    if (invalidSteps.length) {
      return goToStep(invalidSteps[0], formValues);
    }

    if (isFromReviewPreview) {
      return goToStep(SpendPlanReviewStep.stepId, formValues);
    }

    goToNextStep(formValues);
  });

  const handleAccountingCategoryChange = (val: string | null) => {
    if (!val || state.accountingCategories.has(val)) {
      return;
    }

    form.setFieldValue(
      'accountingCategories',
      new Set(state.accountingCategories.add(val)),
    );
  };

  const handleRestrictMerchantCategoriesChange = (val: string) => {
    const isValid = val === 'blacklist' || val === 'whitelist';
    form.setFieldValue('merchantCategoryRestrictionType', isValid ? val : null);
  };

  const handleMccBlacklistChange = (val: string | null) => {
    if (!val || state.merchantCategoryBlacklist.has(val)) {
      return;
    }

    form.setFieldValue(
      'merchantCategoryBlacklist',
      new Set(state.merchantCategoryBlacklist.add(val)),
    );
  };

  const handleMccWhitelistChange = (val: string | null) => {
    if (!val || state.merchantCategoryWhitelist.has(val)) {
      return;
    }

    form.setFieldValue(
      'merchantCategoryWhitelist',
      new Set(state.merchantCategoryWhitelist.add(val)),
    );
  };

  return (
    <CreateSpendPlanWizard.Step
      onNext={handleNext}
      hideBack={isFromReviewPreview}
    >
      <Stack gap={24}>
        {isAccountingVisible ? (
          <Box>
            <Switch
              label="Do you want to restrict this plan to specific allowed accounting categories?"
              description="Limit which allowed accounting categories members of this spend plan can code expenses to."
              labelPosition="left"
              {...form.getInputProps('restrictAccountingCategories', {
                type: 'checkbox',
              })}
            />

            <Collapse in={state.restrictAccountingCategories}>
              <FlexbaseSelect
                label="Allowed accounting categories"
                inputProps={{
                  description:
                    'Designate the allowed accounting categories plan members can code expenses to.',
                  error: form.getInputProps('accountingCategories').error,
                  mt: 16,
                }}
                placeholder="Search or select"
                data={accountingCategorySelectItems}
                onChange={handleAccountingCategoryChange}
                value={null}
                keepDropdownOpenOnSelect
                searchable
              />

              {isMobile ? (
                <Stack gap={8} mt={16}>
                  <PreviewLabel>
                    Transactions can be categorized to:
                  </PreviewLabel>

                  {whitelistedAccountingCategories.length ? (
                    <SpendPlanChipList
                      items={whitelistedAccountingCategories}
                    />
                  ) : (
                    <PreviewLabel fs="italic">
                      No categories selected
                    </PreviewLabel>
                  )}
                </Stack>
              ) : null}
            </Collapse>
          </Box>
        ) : null}

        <Box>
          <Switch
            label="Do you want to designate or exclude merchant categories?"
            description="Designate specific merchant categories or exclude specific merchant categories."
            labelPosition="left"
            {...form.getInputProps('restrictMerchantCategories', {
              type: 'checkbox',
            })}
          />

          <Collapse in={state.restrictMerchantCategories}>
            <Radio.Group
              onChange={handleRestrictMerchantCategoriesChange}
              value={state.merchantCategoryRestrictionType || ''}
              error={
                form.getInputProps('merchantCategoryRestrictionType').error
              }
              mt={16}
            >
              <Group gap={16} align="stretch" wrap="nowrap" grow>
                <SpendPlanRadioCard
                  label="Block merchants"
                  description="Transactions from selected merchant categories will not be allowed to be allocated towards this spend plan."
                  value="blacklist"
                  icon={<MerchantBlockIcon />}
                />
                <SpendPlanRadioCard
                  label="Allow merchants"
                  description="Only transactions from specific merchant categories can be allocated to this spend plan."
                  value="whitelist"
                  icon={<MerchantAllowIcon />}
                />
              </Group>
            </Radio.Group>

            {isMccBlacklist ? (
              <Stack gap={16}>
                <FlexbaseSelect
                  label="Blocked merchant categories"
                  inputProps={{
                    error: form.getInputProps('merchantCategoryBlacklist')
                      .error,
                    mt: 16,
                  }}
                  placeholder="Search or select"
                  data={mccBlacklistSelectItems}
                  onChange={handleMccBlacklistChange}
                  value={null}
                  keepDropdownOpenOnSelect
                  searchable
                />

                {isMobile ? (
                  <Stack gap={8}>
                    <PreviewLabel>Blocked merchants</PreviewLabel>

                    {blacklistedMccCodes.length ? (
                      <SpendPlanChipList items={blacklistedMccCodes} />
                    ) : (
                      <PreviewLabel fs="italic">
                        No categories selected
                      </PreviewLabel>
                    )}
                  </Stack>
                ) : null}
              </Stack>
            ) : null}

            {isMccWhitelist ? (
              <Stack>
                <FlexbaseSelect
                  inputProps={{
                    error: form.getInputProps('merchantCategoryWhitelist')
                      .error,
                    mt: 16,
                  }}
                  label="Allowed merchant categories"
                  placeholder="Search or select"
                  data={mccWhitelistSelectItems}
                  onChange={handleMccWhitelistChange}
                  value={null}
                  keepDropdownOpenOnSelect
                  searchable
                />

                {isMobile ? (
                  <Stack gap={8}>
                    <PreviewLabel>Allowed merchants</PreviewLabel>

                    {whitelistedMccCodes.length ? (
                      <SpendPlanChipList items={whitelistedMccCodes} />
                    ) : (
                      <PreviewLabel fs="italic">
                        No categories selected
                      </PreviewLabel>
                    )}
                  </Stack>
                ) : null}
              </Stack>
            ) : null}
          </Collapse>
        </Box>
      </Stack>
    </CreateSpendPlanWizard.Step>
  );
};

SpendPlanRestrictionsStep.stepId = STEP_ID;
