import { CSSProperties, useRef, useState } from 'react';
import { Tooltip } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

type Props = { text: string; className?: string; style?: CSSProperties };

export const OverflowTooltip = ({ text, className, style }: Props) => {
  const { classes, cx } = useStyles();
  const [showTooltip, setShowTooltip] = useState(false);
  const textElementRef = useRef<HTMLDivElement>(null);

  const mouseOverSizeComparer = () => {
    if (textElementRef.current) {
      const compare =
        textElementRef?.current?.scrollWidth >
        textElementRef.current.clientWidth;
      setShowTooltip(compare);
    }
  };

  return (
    <Tooltip label={text} opened={showTooltip}>
      <div
        onMouseEnter={() => mouseOverSizeComparer()}
        onMouseLeave={() => setShowTooltip(false)}
        ref={textElementRef}
        className={cx(classes.textContainer, className)}
        style={style}
        data-testid="overflow-tooltip"
      >
        {text}
      </div>
    </Tooltip>
  );
};

const useStyles = createStyles({
  textContainer: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
