import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { NavTab } from '@common/composites/tabs/nav-tabs';
import FlexbaseMainLayout from 'components/layouts/main';
import { NavTabContainer } from '@common/composites/tabs/nav-tab-container';
import { Anchor, Button, Group, useMantineTheme } from '@mantine/core';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import SpendPlanTiles from '../spend-plan-tiles';
import { useGetSpendPlans } from '../../../queries/use-spend-plans';
import FlexbaseDefaultHeader from '@common/composites/header/flexbase-header';
import useModal from '@common/composites/modal/modal-hook';
import RequestFundsModal from '../components/request-funds-modal/request-funds-modal';
import {
  ApplicationState,
  IsAdmin,
  IsEmployee,
} from '../../../recoil-state/application/product-onboarding';
import { useRecoilValue } from 'recoil';
import DetailsHeader from '../components/details-header/details-header';

import {
  SpendPlanAdminView,
  SpendPlanView,
} from '@flexbase-eng/types/dist/accounting';
import { FiEdit3 } from 'react-icons/fi';
import { SpendPlanDetailsProvider } from './spend-plan-details.context';

type Props = {
  data?: SpendPlanAdminView | SpendPlanView;
  spendPlanId?: string;
};

const RequestFundsButton = ({ data, spendPlanId }: Props) => {
  const modal = useModal();
  const theme = useMantineTheme();

  return (
    <Button
      onClick={() =>
        modal.openRightModal(
          <RequestFundsModal
            {...{ data, spendPlanId }}
            closeModal={() => modal.closeAllModals()}
          />,
          theme.colors.neutral[2],
        )
      }
    >
      Request funds
    </Button>
  );
};

const SpendPlanDetailsView = () => {
  const { id: spendPlanId } = useParams();
  const navigate = useNavigate();
  const isEmployee = useRecoilValue(IsEmployee);
  const isAdmin = useRecoilValue(IsAdmin);
  const { accountId } = useRecoilValue(ApplicationState);

  // any plans that you manage, whether as a flex admin or a plan manager
  const { data: managedPlans, isPending: isManagedPlansPending } =
    useGetSpendPlans({
      accountId: accountId,
      isAdmin: true,
    });

  // plans that you are enrolled in, but not a manager
  const { data: enrolledPlans, isPending: isEnrolledPlansPending } =
    useGetSpendPlans({
      accountId: accountId,
      isAdmin: false,
    });

  const managedPlan = managedPlans?.find((p) => p.id === spendPlanId);
  const enrolledPlan = enrolledPlans?.find((p) => p.id === spendPlanId);
  const isManager = !!managedPlan;
  const isPending = isManagedPlansPending || isEnrolledPlansPending;
  const plan = isPending ? undefined : managedPlan || enrolledPlan;

  const tabBaseUrl = `/spend-plans/${spendPlanId}/details`;
  const editUrl = `/spend-plans/${spendPlanId}/edit`;

  const tabs: NavTab[] = [
    {
      label: 'Team limits',
      route: `${tabBaseUrl}/limits`,
    },
    {
      label: 'Cards',
      route: `${tabBaseUrl}/cards`,
    },
    {
      label: 'Transactions',
      route: `${tabBaseUrl}/transactions`,
    },
  ];

  const headerAction = !plan?.isPlanActive ? (
    false
  ) : isAdmin ? (
    <Button
      variant="outline"
      rightSection={<FiEdit3 />}
      onClick={() => navigate(editUrl)}
    >
      Edit spend plan
    </Button>
  ) : isEmployee ? (
    <RequestFundsButton {...{ spendPlanId }} data={plan} />
  ) : (
    false
  );

  return (
    <SpendPlanDetailsProvider
      value={{
        isManager,
        isAdmin,
        plan,
      }}
    >
      <FlexbaseMainLayout>
        <Anchor
          mt="2rem"
          c="neutral.7"
          display="inline-block"
          component={Link}
          to="/spend-plans"
        >
          <Group gap="xxs">
            <MdOutlineKeyboardArrowLeft />
            Spend plans
          </Group>
        </Anchor>
        <FlexbaseDefaultHeader
          title={<DetailsHeader spendPlanId={spendPlanId || ''} />}
          primaryAction={headerAction}
        />
        <SpendPlanTiles spendPlanId={spendPlanId || ''} />
        <NavTabContainer tabs={tabs}>
          <Outlet />
        </NavTabContainer>
      </FlexbaseMainLayout>
    </SpendPlanDetailsProvider>
  );
};

export default SpendPlanDetailsView;
