import { SimpleSegmentSelectedFilter } from '@common/utilities/filter/simple-segmented.filter';
import { useBillpayPaymentsModalFilters } from 'areas/billpay/payments/use-billpay-payments-filters';

const STATUS_MULTISELECT_OPTIONS = [
  {
    label: 'Pending',
    filterCriteria: ['Pending'],
    key: 'pending',
  },
  {
    label: 'Sent',
    filterCriteria: ['Sent'],
    key: 'sent',
  },
  { label: 'Canceled', filterCriteria: 'Canceled', key: 'canceled' },
  {
    label: 'Completed',
    filterCriteria: ['Completed'],
    key: 'completed',
  },
  {
    label: 'Approval needed',
    filterCriteria: ['AwaitingApproval'],
    key: 'AwaitingApproval',
  },
];

export const PaymentInvoiceStatusFilter = () => {
  const PaymentInvoiceStatusFilters = useBillpayPaymentsModalFilters();

  return (
    <SimpleSegmentSelectedFilter
      filterHook={PaymentInvoiceStatusFilters}
      filterKey="status"
      options={STATUS_MULTISELECT_OPTIONS}
      label="Status"
      includesValue={(row) => row.status}
    />
  );
};
