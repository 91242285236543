import {
  Alert,
  Button,
  Flex,
  Grid,
  Text,
  Anchor,
  AlertProps,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { CSSProperties, ReactNode, useState } from 'react';
import { XCircleIcon, CheckCircleIcon, AlertTriangleIcon } from 'assets/svg';
import { createStyles } from '@mantine/emotion';
import { PiInfo } from 'react-icons/pi';
type AlertType = 'yellow' | 'red' | 'green' | 'info';

type Props = {
  type: AlertType;
  message: string;
  link?: {
    text: string;
    url: string;
  };
  button?: {
    text: string;
    url: string;
  };
  icon?: ReactNode;
  color?: string;
  style?: CSSProperties;
  withCloseButton?: boolean;
  visible?: boolean;
};

const alertTypePicker = (type: AlertType) => {
  switch (type) {
    case 'info':
      return {
        icon: <PiInfo size={'1.375rem'} />,
        color: 'info',
      };
    case 'green':
      return {
        icon: <CheckCircleIcon />,
        color: 'green',
      };
    case 'red':
      return {
        icon: <XCircleIcon />,
        color: 'red',
      };
    case 'yellow':
      return {
        icon: <AlertTriangleIcon />,
        color: 'warning',
      };
  }
};

const FlexAlert = ({
  type,
  message,
  link,
  button,
  style,
  withCloseButton = true,
  visible = true,
  ...alertProps
}: Props & AlertProps) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [closed, setClosed] = useState(false);
  const alertData = alertTypePicker(type);
  if (closed || !visible) {
    return false;
  } else {
    return (
      <Alert
        withCloseButton={withCloseButton}
        icon={alertData.icon}
        style={style}
        color={alertData.color}
        onClose={() => {
          setClosed(true);
        }}
        sx={{
          marginBottom: 16,
          margin: 0,
          padding: 10,
          alignContent: 'center',
        }}
        styles={{
          wrapper: { alignItems: 'center' },
        }}
        {...alertProps}
      >
        <Grid>
          <Grid.Col
            span={10}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Text className={classes.message}>
              {message}{' '}
              {link && (
                <Anchor href={link.url} className={classes.link}>
                  {link.text}
                </Anchor>
              )}
            </Text>
          </Grid.Col>
          <Grid.Col span={2}>
            {button && (
              <Flex justify="flex-end" align="center" direction="row" mr={'md'}>
                <Button
                  size="xs"
                  variant="outline"
                  c="neutral.7"
                  onClick={() => {
                    setClosed(true);
                    navigate(button.url);
                  }}
                >
                  {button.text}
                </Button>
              </Flex>
            )}
          </Grid.Col>
        </Grid>
      </Alert>
    );
  }
};

const useStyles = createStyles((theme) => ({
  link: {
    marginLeft: '.25rem',
    fontWeight: 600,
    textDecoration: 'underline',
    ':hover': {
      cursor: 'pointer',
    },
    justifySelf: 'center',
  },
  message: {
    color: theme.primaryColor,
    fontSize: '.875rem',
    fontWeight: 500,
    lineHeight: '1.25rem',
  },
}));

export default FlexAlert;
