import { FlexbaseTable } from '@common/table';
import { useStyles } from './styles';
import SpendTransactionsTableHeader from './spend-transactions-header/spend-transactions-table-header';
import { useEffect, useMemo } from 'react';
import { SpendTransactionsTableRow } from 'areas/spend-plans/details/tabs/transactions/spend-transaction-table-helpers';
import { useSpendTransactionFilters } from './spend-transactions-header/use-spend-transactions-filter';
import { useParams } from 'react-router-dom';
import { useGetMe } from '@queries/use-get-me';
import { useGetSpendPlanTransactions } from '@queries/use-spend-plans';
import {
  mapTransactionTableRow,
  useSpendPlansTransactionsTableColumns,
} from './transactions-columns';
import { useSyncedTransactionsQuery } from '@queries/use-integrations';
import { showNotification } from '@mantine/notifications';
import {
  useActiveExpenseLink,
  useSyncExpenseAllowed,
} from '@utilities/integrations/accounting';
import { mapTransactionStatusToBusinessStatus } from '@services/flexbase/flexbase-onboarding-client';
import {
  ExpensesStatusEnum,
  Expenses,
} from '@flexbase-eng/types/dist/accounting';
import { useSyncTransactionExpenses } from '@utilities/custom-hooks/use-sync-transaction-expenses';
import { useDisclosure } from '@mantine/hooks';
import {
  SyncTransactionPayload,
  useExpenseCategorySyncState,
} from '@common/charge-and-credit-cards/credit-transactions.reducer';

type SyncTransactionsResult = {
  success: boolean;
  error?: Error;
  data: { transactionId: string; success: boolean }[];
};

const POLL_FOR_SYNC_UPDATES_INTERVAL = 1000 * 5;

/**
 * Returns true if we need to poll for sync updates for this expense.
 */
const isWaitingForUpdates = (expense: Expenses) =>
  [ExpensesStatusEnum.Queued, ExpensesStatusEnum.Failure].includes(
    expense.status,
  );

const TransactionsTable = () => {
  const { classes } = useStyles();
  const { activeFiltersArray } = useSpendTransactionFilters();
  const { id: spendPlanId } = useParams();
  const { data: personInfo } = useGetMe();
  const personAccountId = personInfo?.accountId;
  const isSyncExpenseAllowed = useSyncExpenseAllowed();
  const { expenseLink, connectionId = '' } = useActiveExpenseLink();
  const [
    expenseCategorySyncState,
    {
      removeSyncState,
      removeAndResetAllSyncStatus,
      updateSyncCategory,
      updateSyncStatus,
      resetSyncStatusAll,
    },
  ] = useExpenseCategorySyncState();
  const [clearSelectedRowsFlag, { toggle: clearSelectedRows }] =
    useDisclosure();

  const { data = [], isSuccess } = useGetSpendPlanTransactions({
    accountId: personAccountId || '',
    spendPlanId: spendPlanId || '',
  });

  const syncedTransactionsQueryIds = useMemo(
    () =>
      (data ?? [])
        .filter((tx) => mapTransactionStatusToBusinessStatus(tx) === 'Settled')
        .map((tx) => tx.id) ?? [],
    [data],
  );

  // Fetch sync data for all tx.
  const {
    data: { syncedTransactions, syncingTransactionIds } = {},
    refetch: refetchSyncedTransactions,
  } = useSyncedTransactionsQuery({
    enabled: expenseLink?.enabledExpenses,
    connectionId,
    transactionIds: syncedTransactionsQueryIds,
    select: (result) => ({
      syncedTransactions: result,
      syncingTransactionIds: result.expenses
        .filter(isWaitingForUpdates)
        .map((tx) => tx.transactionId),
    }),
  });

  // Poll for sync updates for any tx that are waiting for updates
  useSyncedTransactionsQuery({
    enabled: expenseLink?.enabledExpenses && !!syncingTransactionIds?.length,
    connectionId,
    transactionIds: syncingTransactionIds ?? [],
    refetchInterval: (query) => {
      const hasIncomplete =
        !!query.state.data?.expenses.some(isWaitingForUpdates);
      return hasIncomplete ? POLL_FOR_SYNC_UPDATES_INTERVAL : false;
    },
    updateCache: true,
  });

  const { syncTransactions } = useSyncTransactionExpenses({
    connectionId,
    syncedExpenses: syncedTransactions,
  });

  const filteredItems = useMemo<SpendTransactionsTableRow[]>(() => {
    if (!isSuccess) {
      return [];
    }

    const asMap =
      syncedTransactions?.expenses.reduce<Partial<Record<string, Expenses>>>(
        (acc, item) => {
          acc[item.transactionId] = item;
          return acc;
        },
        {},
      ) ?? {};
    return (data ?? [])
      .map((transaction, index) => {
        const txSyncData = asMap[transaction.id];
        const txCategoryChange = expenseCategorySyncState[transaction.id];
        return mapTransactionTableRow(
          transaction,
          index,
          {
            syncedExpense: txSyncData,
            expenseCategories: syncedTransactions?.accounts?.items,
          },
          txCategoryChange,
        );
      })
      .filter((t) => activeFiltersArray.every((f) => f.fn(t)));
  }, [data, syncedTransactions, expenseCategorySyncState, activeFiltersArray]);

  useEffect(() => {
    // Table doesn't update the selection if data changes
    // so just clear the selection
    clearSelectedRows();
  }, [filteredItems]);

  const handleSyncSingleClick = async (txId: string) => {
    const existingSyncData = syncedTransactions?.expenses.find(
      (e) => e.transactionId === txId,
    );

    if (!existingSyncData) {
      console.error(`Transaction ${txId} is unable to be synced.`);
      return;
    }

    const txCategoryChange = expenseCategorySyncState[txId];

    if (!txCategoryChange?.data) {
      if (existingSyncData.expense?.account) {
        showNotification({
          title: 'Info',
          message: 'This transaction is already synced',
        });
      } else {
        showNotification({
          title: 'Info',
          message: `Please choose ${syncedTransactions?.accounts?.type} first`,
        });
      }
      return;
    }

    await handleSyncTransactions([txCategoryChange.data]);
  };

  const handleTransactionAccountChange = async (
    transactionId: string,
    accountId: string | null,
  ) => {
    if (!accountId) {
      return;
    }

    const syncedAccountId = syncedTransactions?.expenses.find(
      (tx) => tx.transactionId === transactionId,
    )?.expense?.account?.id;

    const changeUndone = syncedAccountId === accountId;

    if (changeUndone) {
      removeSyncState(transactionId);
      return;
    }

    updateSyncCategory(transactionId, accountId);

    await handleSyncTransactions([{ transactionId, accountId }]);
  };

  const columns = useSpendPlansTransactionsTableColumns({
    syncFeatureEnabled: isSyncExpenseAllowed && expenseLink?.enabledExpenses,
    onTransactionSync: handleSyncSingleClick,
    onTransactionAccountChange: handleTransactionAccountChange,
    syncedTransactions,
    expenseLink,
  });

  const handleSyncTransactions = async (
    transactionsToSync: SyncTransactionPayload[],
    options?: {
      successMessage?: ({
        results,
      }: {
        results: SyncTransactionsResult[];
      }) => string;
      partialSuccessMessage?: ({
        results,
      }: {
        results: SyncTransactionsResult[];
      }) => string;
    },
  ) => {
    updateSyncStatus(
      transactionsToSync.map((tx) => tx.transactionId),
      'loading',
    );

    const syncResults = await syncTransactions(transactionsToSync, options);

    if (syncResults.length && syncResults.every((result) => !result.success)) {
      return resetSyncStatusAll();
    }

    await refetchSyncedTransactions();

    const successfulTxIds = syncResults
      .map((result) => result.data)
      .flat()
      .filter((tx) => tx.success)
      .map((tx) => tx.transactionId);

    removeAndResetAllSyncStatus(successfulTxIds);
  };

  return (
    <div className={classes.card} data-testid="account-info">
      <SpendTransactionsTableHeader {...{ data }} />

      <FlexbaseTable
        selectableRows
        striped={false}
        columns={columns}
        data={filteredItems}
        clearSelectedRows={clearSelectedRowsFlag}
      />
    </div>
  );
};

export default TransactionsTable;
