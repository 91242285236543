import { ActionIcon, Menu, useMantineTheme } from '@mantine/core';
import { PiDotsThreeOutlineVerticalFill } from 'react-icons/pi';

type Props = {
  primaryButton: JSX.Element;
  menuItems: JSX.Element;
};

const TitleBarActions = ({ primaryButton, menuItems }: Props) => {
  const theme = useMantineTheme();

  return (
    <>
      {primaryButton}
      <Menu position="bottom-end">
        <Menu.Target>
          <ActionIcon
            size={'2.5rem'}
            variant="outline"
            sx={{
              borderColor: theme.colors.neutral[3],
            }}
            aria-label="account actions"
          >
            <PiDotsThreeOutlineVerticalFill color={theme.colors.blackish[0]} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>{menuItems}</Menu.Dropdown>
      </Menu>
    </>
  );
};

export default TitleBarActions;
