import OnboardingStep from '../components/onboarding-step';
import FlexbaseSelect from '../../../components/composites/flexbase-select';
import { JOB_TITLE_VALUES } from '../../../constants/constants';
import { useForm } from '@mantine/form';
import {
  ConditionalFieldValidator,
  RequiredFieldValidator,
} from '@utilities/validators';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from '../../../recoil-state/application/product-onboarding';
import { useOnboardingStyles } from '../onboarding.styles';
import { flexbaseOnboardingClient } from '../../../services/flexbase-client';
import { useState } from 'react';
import { useApplicationFlowContext } from '../onboarding-hooks';
import { Analytics } from '@services/analytics/analytics';
import { ApplicationEvents } from '@services/analytics/events';

const JobTitle = () => {
  const { classes } = useOnboardingStyles();
  const { company, user } = useRecoilValue(ApplicationState);
  const { goBack, navigateToNextProductStep } = useApplicationFlowContext();
  // Can't wait for react-query to get rid of this boilerplate!
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const isSoleProp = company.legalStructure === 'S-Prop';

  const form = useForm({
    initialValues: {
      jobTitle: user.jobTitle,
    },
    validate: {
      jobTitle: ConditionalFieldValidator(
        !isSoleProp,
        RequiredFieldValidator('Select an ownership option'),
      ),
    },
  });

  const handleNextClick = async () => {
    const validationResult = form.validate();
    Analytics.track(ApplicationEvents.JOB_TITLE_SUBMIT_CLICKED);

    if (!validationResult.hasErrors) {
      setLoading(true);
      try {
        await flexbaseOnboardingClient.updateUser({
          jobTitle: form.values.jobTitle,
        });
        navigateToNextProductStep();
      } catch (e) {
        setError('Unable to update job title');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <OnboardingStep
      title={'Job title'}
      stepId={'job-title'}
      subtitle="We need to know a little more about your job"
      showContinueSpinner={loading}
      error={error}
      onNextClick={handleNextClick}
      onBackClick={goBack}
    >
      <FlexbaseSelect
        label={isSoleProp ? 'Job Title (Optional)' : 'Job Title'}
        data-sardine-id="input-user-job-title"
        id="input-user-job-title"
        placeholder="Select one"
        data={JOB_TITLE_VALUES}
        {...form.getInputProps('jobTitle')}
        inputProps={{
          id: 'input-user-job-title',
          classNames: {
            label: classes.inputLabel,
            root: classes.btnSpacingAddressPage,
          },
        }}
      />
    </OnboardingStep>
  );
};

export default JobTitle;
