import { Box, Collapse, useMantineTheme } from '@mantine/core';
import { PreviewRow } from '../../components/preview-row';
import { PreviewLabel } from '../../components/preview-label';
import { useCreateSpendPlanContext } from '../../create-spend-plan.context';
import { newCategories } from 'constants/mcc-restrictions';
import { useActiveExpenseLink } from '@utilities/integrations/accounting';
import { useMemo } from 'react';
import { formatCurrency } from '@utilities/formatters';
import {
  SpendPlanChipListItem,
  SpendPlanChipList,
} from '../../components/spend-plan-chip';
import { useCreateSpendPlanWizard } from '../../create-spend-plan.wizard';
import { SpendPlanRestrictionsStep } from './restrictions-step';

const mccLabelMap = newCategories.reduce<Record<string, string | undefined>>(
  (acc, { label, value }) => {
    acc[value] = label;
    return acc;
  },
  {},
);

export const useRestrictionsPreview = () => {
  const { restrictionsForm: form } = useCreateSpendPlanContext();
  const { state } = useCreateSpendPlanWizard();
  const { currentStep } = useCreateSpendPlanWizard();
  const { expenseLink } = useActiveExpenseLink();
  const isCurrentStep = currentStep?.id === SpendPlanRestrictionsStep.stepId;

  const accountingCategoryMap = useMemo(() => {
    if (!expenseLink?.accounts?.length) {
      return {};
    }

    return expenseLink.accounts.reduce<Record<string, string>>((acc, item) => {
      acc[item.id] = item.displayName ?? item.name;
      return acc;
    }, {});
  }, [expenseLink?.accounts]);

  const removeAccountingCategory = (val: string) => {
    if (state.accountingCategories.delete(val)) {
      form.setFieldValue(
        'accountingCategories',
        new Set(state.accountingCategories),
      );
    }
  };

  const removeFromBlacklist = (val: string) => {
    if (state.merchantCategoryBlacklist.delete(val)) {
      form.setFieldValue(
        'merchantCategoryBlacklist',
        new Set(state.merchantCategoryBlacklist),
      );
    }
  };

  const removeFromWhitelist = (val: string) => {
    if (state.merchantCategoryWhitelist.delete(val)) {
      form.setFieldValue(
        'merchantCategoryWhitelist',
        new Set(state.merchantCategoryWhitelist),
      );
    }
  };

  const whitelistedAccountingCategories = [
    ...state.accountingCategories,
  ].map<SpendPlanChipListItem>((id) => {
    return {
      label: accountingCategoryMap[id] || '',
      value: id,
      disabled: !isCurrentStep,
      onClick: () => removeAccountingCategory(id),
    };
  });

  const blacklistedMccCodes = [
    ...state.merchantCategoryBlacklist,
  ].map<SpendPlanChipListItem>((id) => {
    return {
      label: mccLabelMap[id] || '',
      value: id,
      disabled: !isCurrentStep,
      onClick: () => removeFromBlacklist(id),
    };
  });

  const whitelistedMccCodes = [
    ...state.merchantCategoryWhitelist,
  ].map<SpendPlanChipListItem>((id) => {
    return {
      label: mccLabelMap[id] || '',
      value: id,
      disabled: !isCurrentStep,
      onClick: () => removeFromWhitelist(id),
    };
  });

  const getReceiptPolicyValue = () => {
    switch (state.receiptPolicy) {
      case 'all':
        return 'All receipts required';
      case 'none':
        return 'No receipts required';
      case 'threshold':
        return `Require receipts at ${formatCurrency(
          state.receiptThreshold || 0,
        )}`;
      default:
        return 'No receipt restrictions set';
    }
  };

  return {
    whitelistedAccountingCategories,
    blacklistedMccCodes,
    whitelistedMccCodes,
    receiptPolicyValue: getReceiptPolicyValue(),
  };
};

export const SpendPlanRestrictionsStepPreview = () => {
  const theme = useMantineTheme();
  const { state } = useCreateSpendPlanWizard();
  const { expenseLink } = useActiveExpenseLink();
  const isAccountingVisible = !!expenseLink?.enabledExpenses;
  const {
    whitelistedAccountingCategories,
    blacklistedMccCodes,
    whitelistedMccCodes,
  } = useRestrictionsPreview();

  const isWhitelistMcc = state.merchantCategoryRestrictionType === 'whitelist';
  const isBlacklistMcc = state.merchantCategoryRestrictionType === 'blacklist';
  const isRestrictingMcc =
    state.restrictMerchantCategories && (isWhitelistMcc || isBlacklistMcc);

  const noRestrictionsSelected =
    !state.restrictAccountingCategories && !isRestrictingMcc;

  return (
    <Box>
      <PreviewRow>
        <PreviewLabel
          fz={20}
          fw={500}
          lh="2rem"
          color={theme.colors.primarySecondarySuccess[8]}
        >
          Restrictions
        </PreviewLabel>
      </PreviewRow>

      <Collapse in={noRestrictionsSelected}>
        <PreviewRow mt={4}>
          <PreviewLabel fs="italic">No restrictions set.</PreviewLabel>
        </PreviewRow>
      </Collapse>

      {isAccountingVisible ? (
        <Collapse in={state.restrictAccountingCategories}>
          <PreviewRow mt={'sm'}>
            <PreviewLabel>Transactions can be categorized to:</PreviewLabel>

            {whitelistedAccountingCategories.length ? (
              <SpendPlanChipList items={whitelistedAccountingCategories} />
            ) : (
              <PreviewLabel fs="italic">No categories selected</PreviewLabel>
            )}
          </PreviewRow>
        </Collapse>
      ) : null}

      <Collapse
        in={
          state.restrictMerchantCategories &&
          !!state.merchantCategoryRestrictionType
        }
      >
        {state.merchantCategoryRestrictionType === 'whitelist' ? (
          <PreviewRow mt={'sm'}>
            <PreviewLabel>Allowed merchant categories</PreviewLabel>

            {whitelistedMccCodes.length ? (
              <SpendPlanChipList items={whitelistedMccCodes} />
            ) : (
              <PreviewLabel fs="italic">No categories selected</PreviewLabel>
            )}
          </PreviewRow>
        ) : null}

        {state.merchantCategoryRestrictionType === 'blacklist' ? (
          <PreviewRow mt={24}>
            <PreviewLabel>Blocked merchant categories</PreviewLabel>

            {blacklistedMccCodes.length ? (
              <SpendPlanChipList items={blacklistedMccCodes} />
            ) : (
              <PreviewLabel fs="italic">No categories selected</PreviewLabel>
            )}
          </PreviewRow>
        ) : null}
      </Collapse>
    </Box>
  );
};
