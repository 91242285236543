import {
  Box,
  Button,
  Divider,
  Group,
  rem,
  ScrollArea,
  Stack,
  Text,
} from '@mantine/core';
import FlexIconLink from '@common/composites/flex-icon-link';
import {
  CheckCircleProgress,
  LockIcon,
  LogOutIcon,
} from '../../../../assets/svg';
import { useOnboardingStepperStyles } from '@utilities/custom-hooks/use-onboarding-stepper-styles';
import { useLogout } from '../../../../services/logout/logout-hook';
import { Outlet } from 'react-router-dom';
import OnboardingTitleGroup from '../../components/onboarding-title-group';
import { useMatchMedia } from '../../../../utilities/url/window-helpers';
import { MultiStepFormSubmitButton } from '@common/composites/multi-step-form/multi-step-form-submit-button';
import { useEffect } from 'react';
import { usePrefillMultistepFormContext } from './prefill-multi-step-form.context';
import { useOnboardingStatusContext } from '../../../../providers/onboarding-status-context';
import { usePlatformPrefillContext } from '../../../../providers/platform-prefill.context';
import { TermsOfServiceType } from '../../../../services/flexbase/flexbase-onboarding-client';
import { useTermsOfService } from '@utilities/custom-hooks/use-has-terms';

type PrefillFormDisclosureProps = {
  type: TermsOfServiceType;
};
const Disclosure = ({ type }: PrefillFormDisclosureProps) => {
  const { termsContent } = useTermsOfService(type);

  return termsContent.map((terms) => <Box key={`terms-${type}`}>{terms}</Box>);
};

type PrefillFormDisclosuresProps = {
  types: TermsOfServiceType[];
};

const PrefillFormDisclosures = ({ types }: PrefillFormDisclosuresProps) => {
  return (
    <ScrollArea
      h={118}
      sx={(t) => ({
        backgroundColor: t.colors.neutral[0],
        color: t.colors.blackish[0],
        fontSize: rem(14),
        border: `1px solid ${t.colors.neutral[3]}`,
        borderRadius: t.defaultRadius,
        lineHeight: rem(25),
        padding: 10,
      })}
    >
      <Stack gap={10}>
        {types.map((type) => (
          <Disclosure key={type} type={type} />
        ))}
      </Stack>
    </ScrollArea>
  );
};

export const PrefillFormContainer = () => {
  const { classes, cx } = useOnboardingStepperStyles();
  const logout = useLogout();
  const isMobileScreenSize = useMatchMedia('(max-width: 767px)');
  const { currentStep, goToPreviousStep, hasPreviousStep, state, setState } =
    usePrefillMultistepFormContext();
  const { refreshStatus } = useOnboardingStatusContext();
  const { businessPrefillData, businessPrefillError } =
    usePlatformPrefillContext();

  useEffect(() => {
    // Refresh onboarding status on step change.
    refreshStatus();
    window.scroll(0, 0);
    setState({ loading: false, error: null }); // I'd like to abstract this to the MSF hook one day
  }, [currentStep]);

  useEffect(() => {
    if (businessPrefillData?.businesses.length === 0) {
      setState({ businessPrefillAvailable: false });
    } else if (businessPrefillError) {
      setState({ businessPrefillAvailable: false });
    }
  }, [businessPrefillData, businessPrefillError]);

  return (
    <>
      <Box className={classes.header}>
        <FlexIconLink width={68} />
        {!isMobileScreenSize && (
          <Box className={classes.headerActions}>
            <Box className={classes.notices}>
              <Group
                gap="sm"
                className={classes.creditImpactNotice}
                c="primarySecondarySuccess.6"
              >
                <CheckCircleProgress />
                <Box className={cx(classes.headerText, classes.progressSaved)}>
                  Progress saved!
                </Box>
              </Group>
            </Box>
          </Box>
        )}

        <Box className={classes.containerLogout}>
          <>
            <Box className={classes.headerText}>
              <LockIcon /> No impact to credit
            </Box>
            <Divider orientation="vertical" size="0.15rem" />
          </>
          <Button
            onClick={() => logout()}
            leftSection={<LogOutIcon color="black" />}
            variant="outline"
            c="neutral.6"
            size={isMobileScreenSize ? 'xs' : 'md'}
          >
            Log Out
          </Button>
        </Box>
      </Box>
      <Divider />
      <Box
        className={cx(
          classes.webContainer,
          isMobileScreenSize && classes.mobileContainer,
        )}
        sx={(_, helpers) => ({
          width: '100%',
          [helpers.largerThan(767)]: {
            width: '32.8125rem',
          },
        })}
      >
        <OnboardingTitleGroup
          titleText={currentStep!.metadata.title}
          subtitleText={currentStep!.metadata.description}
        />
        <Box mih="100%" py="md">
          <Outlet />
        </Box>
        {state.error && (
          <Text fz={12} c="critical.2">
            {state.error}
          </Text>
        )}
        <Group mt="md">
          {hasPreviousStep && (
            <Button
              variant="outline"
              onClick={() => goToPreviousStep()}
              disabled={state.loading}
            >
              Back
            </Button>
          )}
          <MultiStepFormSubmitButton
            form={currentStep?.metadata.id ?? 'none'}
            variant="light"
            ml="auto"
            data-testid="button-continue"
            loading={state.loading}
          >
            Continue
          </MultiStepFormSubmitButton>
        </Group>

        {!!currentStep?.metadata.disclosures?.length && (
          <Box mt={rem(32)}>
            <PrefillFormDisclosures types={currentStep.metadata.disclosures} />
          </Box>
        )}
      </Box>
    </>
  );
};
