import { useChargeCards } from '@queries/use-credit-cards';
import { CreditCard } from '@services/flexbase/card.model';
import DebitCardDetails from '@common/charge-and-credit-cards/debit-card-details';
import { CardByUser } from '@services/flexbase/banking.model';
import { CardsTable } from '@common/charge-and-credit-cards/cards-table';
import {
  useChargeCardsFilters,
  useChargeCardsModalFilters,
} from '@common/utilities/filter/cards-filter/use-cards-filters';
import { CardsTableHeader } from '@common/composites/header/cards-table-header/cards-table-header';
import { CardTableRow } from '@common/utilities/filter/cards-filter/card-table-row.model';
import { ChargeCardStatusFilter } from '@common/utilities/filter/cards-filter/filters/card-status.filter';
import { ChargeCardTypeFilter } from '@common/utilities/filter/cards-filter/filters/card-type.filter';
import { ChargeCardsCardholderFilter } from '@common/utilities/filter/cards-filter/filters/card-holder.filter';
import { ChargeCardNumberFilter } from '@common/utilities/filter/cards-filter/filters/card-number.filter';
import { ChargeCardNameFilter } from '@common/utilities/filter/cards-filter/filters/card-name.filter';
import { ChargeCardsAmountSpentFilter } from '@common/utilities/filter/cards-filter/filters/card-spent-amounts.filter';
import { ChargeCardsDateRangeFilter } from '@common/utilities/filter/cards-filter/filters/cards-date-range.filter';
import useModal from '@common/composites/modal/modal-hook';
import IssueChargeCard from './issue-charge-cards';
import { useRecoilValue } from 'recoil';
import { IsAdmin } from 'recoil-state/application/product-onboarding';

export const ChargeCardsTable = () => {
  const filterHook = useChargeCardsFilters();
  const isAdmin = useRecoilValue(IsAdmin);
  const modalFilterHook = useChargeCardsModalFilters();
  const { openFullModal, closeAllModals } = useModal();
  const searchTerm = filterHook.getFilterByKey('search')?.filterValue;

  const dataQuery = useChargeCards({
    full: true,
    searchTerm,
  });

  const handleIssueCard = () => {
    openFullModal(<IssueChargeCard closeModal={closeAllModals} />);
  };

  const getTableHeader = (cards: CreditCard[]) => {
    const cardsData = cards as CardTableRow[];

    const filters = [
      {
        key: 'status',
        header: 'Status',
        component: <ChargeCardStatusFilter />,
      },
      {
        key: 'type',
        header: 'Type',
        component: <ChargeCardTypeFilter />,
      },
      {
        key: 'cardholder',
        header: 'Cardholder',
        component: <ChargeCardsCardholderFilter cards={cardsData} />,
      },
      {
        key: 'number',
        header: 'Number',
        component: <ChargeCardNumberFilter cards={cardsData} />,
      },
      {
        key: 'name',
        header: 'Card name',
        component: <ChargeCardNameFilter cards={cardsData} />,
      },
      {
        key: 'spent',
        header: 'Monthly usage',
        component: <ChargeCardsAmountSpentFilter />,
      },
      {
        key: 'date',
        header: 'Last modified',
        component: <ChargeCardsDateRangeFilter />,
      },
    ];

    return (
      <CardsTableHeader
        filters={filters}
        filterHook={filterHook}
        userCanIssueCards={isAdmin}
        onIssueCard={handleIssueCard}
        issueCardLabel="New charge card"
        modalFilterHook={modalFilterHook}
      />
    );
  };

  const getDetailsModal = (card: CreditCard) => {
    return <DebitCardDetails card={card as unknown as CardByUser} />;
  };

  return (
    <CardsTable
      dataQuery={dataQuery}
      filterHook={filterHook}
      getTableHeader={getTableHeader}
      getDetailsModal={getDetailsModal}
    />
  );
};
