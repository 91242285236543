import { Select, TextInput, useMantineTheme } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import {
  parseCreateBeneficiaryParams,
  useCreateInternationalPaymentsBeneficiary,
} from '@queries/use-beneficiaries';
import { validateBirthDateInSpecifiedFormat } from '@utilities/validators/validate-dates';
import FlexPatternFormat from '@common/flex-pattern-format';
import {
  isRestrictedCity,
  formatLabel,
  formatCamelCasedValue,
} from '@utilities/international-payments';
import type {
  FieldRecord,
  InternationalRecipient,
} from 'areas/payments/components/send-payment/international-payments/util/types';
import {
  AddRecipientWizard,
  useAddRecipientWizard,
} from 'areas/payments/pages/add-recipient/add-recipient-wizard';
import { useAddRecipientSubmit } from 'areas/payments/pages/add-recipient/use-add-recipient-submit';
import State from 'country-state-city/lib/state';
import { isIndividual } from '../../../recipient-details/recipient-details-step';
import { submitStepLabels } from '../../../util';
import PaymentDetailsStepError from '../../payment-details-step-error';

const createInitialValues = (fields: FieldRecord, requiresDob: boolean) => {
  const initialValues: FieldRecord = requiresDob ? { dob: '' } : {};

  for (const field in fields) {
    initialValues[field] = '';
  }

  return initialValues;
};

const getStateOrProvinceOptionsByCountryCode = (countryCode: string) => {
  return State.getStatesOfCountry(countryCode).map((state) => ({
    value: state.isoCode,
    label: state.name,
  }));
};

type Props = {
  fields: FieldRecord;
  recipientCountryCode: string;
  requiresDob: boolean;
};

type FieldProperties = Record<
  string,
  {
    isFullWidth: boolean;
    component: React.ComponentType<any>;
    placeholder?: string;
    data?: any;
    searchable?: boolean;
    label?: string;
  }
>;

const BeneficiaryRequirementsForm = ({
  fields,
  recipientCountryCode,
  requiresDob,
}: Props) => {
  const theme = useMantineTheme();
  const isMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const form = useForm({
    initialValues: createInitialValues(fields, requiresDob),
    validate: (values) => {
      const errors: Record<string, string> = {};
      for (const field in fields) {
        const associatedRegex = fields[field];
        if (!values[field] || !values[field].match(associatedRegex)) {
          errors[field] = `Please provide a valid ${formatCamelCasedValue(
            field,
          )} value`;
        }
        if (field === 'city' && isRestrictedCity(values[field])) {
          errors[field] = 'This city is not supported for international wires';
        }
      }

      if (
        requiresDob &&
        !validateBirthDateInSpecifiedFormat('yyyy-MM-dd', values.dob)
      ) {
        errors.dob = 'Please provide a valid date of birth';
      }

      return errors;
    },
  });

  const { state } = useAddRecipientWizard();
  const { beneficiaryRequirementsFormValues, recipientDetailsFormValues } =
    state;

  const { mutate: createBeneficiary, isPending: isPendingBeneficiaryCreation } =
    useCreateInternationalPaymentsBeneficiary();

  const {
    submitRecipient,
    isPending: isPendingRecipientCreation,
    submissionError,
    handleClearSubmissionError,
  } = useAddRecipientSubmit(recipientDetailsFormValues, createBeneficiary);

  const isPending = isPendingRecipientCreation || isPendingBeneficiaryCreation;

  const fieldKeysSet = new Set(Object.keys(fields));

  const fieldProperties: FieldProperties = {
    companyName: { isFullWidth: true, component: TextInput },
    address: { isFullWidth: true, component: TextInput },
    city: { isFullWidth: true, component: TextInput },
    stateOrProvince: {
      isFullWidth: true,
      component: Select,
      placeholder: `Enter a ${formatCamelCasedValue('stateOrProvince')} value`,
      data: getStateOrProvinceOptionsByCountryCode(recipientCountryCode),
      searchable: true,
    },
    postcode: { isFullWidth: true, component: TextInput },
    firstName: {
      isFullWidth: false,
      component: TextInput,
      label: 'Recipient first name',
    },
    lastName: {
      isFullWidth: false,
      component: TextInput,
      label: 'Recipient last name',
    },
    accountNumber: { isFullWidth: true, component: TextInput },
    bicSwift: { isFullWidth: true, component: TextInput },
    iban: { isFullWidth: true, component: TextInput },
  };

  const filteredFieldProperties: FieldProperties = Object.fromEntries(
    Object.entries(fieldProperties).filter(([key]) => fieldKeysSet.has(key)),
  );

  // Add additional fields that may be required by the beneficiary
  for (const field in fields) {
    if (!fieldProperties[field]) {
      fieldProperties[field] = {
        isFullWidth: true,
        component: TextInput,
        placeholder: `Enter ${formatCamelCasedValue(field)}`,
      };
    }
    filteredFieldProperties[field] = fieldProperties[field];
  }

  const handleSubmit = () => {
    form.validate();
    if (form.isValid()) {
      const allValues = {
        ...form.values,
        ...beneficiaryRequirementsFormValues,
        name: recipientDetailsFormValues.name,
      } as InternationalRecipient;

      if (!isIndividual(recipientDetailsFormValues.type)) {
        allValues.companyName = recipientDetailsFormValues.name;
      }

      const params = parseCreateBeneficiaryParams(allValues);
      submitRecipient(params, allValues.dob);
    }
  };

  if (submissionError && submissionError.message.length > 0) {
    return (
      <PaymentDetailsStepError
        submissionError={submissionError}
        onClear={handleClearSubmissionError}
      />
    );
  }

  return (
    <AddRecipientWizard.Step
      onNext={handleSubmit}
      nextLoading={isPending}
      labels={submitStepLabels}
    >
      <form>
        {requiresDob && (
          <FlexPatternFormat
            mt={'md'}
            label={'Recipient date of birth'}
            placeholder="Enter date of birth"
            description="Format: YYYY-MM-DD"
            c={'neutral.8'}
            format="####-##-##"
            mask="_"
            allowEmptyFormatting
            {...form.getInputProps('dob')}
          />
        )}

        {Object.entries(filteredFieldProperties).map(
          ([
            fieldName,
            { isFullWidth, component: Component, label, ...props },
          ]) => {
            const regex = fields[fieldName];

            return (
              <Component
                key={fieldName}
                mt={'md'}
                label={label ? label : formatLabel(fieldName)}
                placeholder={`Enter ${formatCamelCasedValue(fieldName)}`}
                c={'neutral.8'}
                {...form.getInputProps(fieldName)}
                {...props}
                pattern={regex}
                style={{
                  width: isFullWidth || isMobileView ? '100%' : 'auto',
                }}
              />
            );
          },
        )}
      </form>
    </AddRecipientWizard.Step>
  );
};

export default BeneficiaryRequirementsForm;
