import { Outlet } from 'react-router-dom';
import { NavTabContainer } from '@common/composites/tabs/nav-tab-container';
import ActionRequiredBadge from './billpay-action-required-badge';
import TableSummary from '@common/table/summary/table-summary';
import { useGetBillpayBalance } from '@queries/use-bill-pay';
import { formatCents } from '@utilities/formatters';

const BILLPAY_TABS = [
  { label: 'Bills', route: '/bill-pay/bills' },
  {
    label: 'Action required',
    route: '/bill-pay/action-required',
    count: <ActionRequiredBadge />,
  },
  {
    label: 'Payments',
    route: '/bill-pay/payments',
  },
  {
    label: 'Recipients',
    route: '/bill-pay/vendors',
  },
];

const BillpayPage = () => {
  const { data, isLoading, isError } = useGetBillpayBalance();
  const {
    outstanding = { count: 0, balance: 0 },
    overdue = { count: 0, balance: 0 },
    upcoming = { count: 0, balance: 0 },
  } = data?.balances || {};

  const billpayData = [
    {
      title: 'Total outstanding bills',
      balance: formatCents(outstanding.balance),
      footer: `${outstanding.count} bills`,
    },
    {
      title: 'Overdue',
      balance: formatCents(overdue.balance),
      footer: `${overdue.count} bills`,
    },
    {
      title: 'Due in next 7 days',
      balance: formatCents(upcoming.balance),
      footer: `${upcoming.count} bills`,
    },
  ];

  return (
    <>
      <TableSummary
        summaryData={billpayData}
        isError={isError}
        isLoading={isLoading}
      />
      <NavTabContainer tabs={BILLPAY_TABS}>
        <Outlet />
      </NavTabContainer>
    </>
  );
};

export default BillpayPage;
