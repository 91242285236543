import { formatCurrency } from '@utilities/formatters';
import { DateTime } from 'luxon';
import type { Transaction } from 'services/flexbase/banking.model';
import { StatementInfo } from 'services/flexbase/statements.model';
import { downloadCSV } from '@utilities/csv-export';

export const downloadStatementCSV = (
  statement: StatementInfo,
  data: Transaction[],
) => {
  const dateVal = DateTime.fromISO(statement.period, {
    zone: 'utc',
  });
  const beginningOfMonth = dateVal.startOf('month');
  const endofMonth = dateVal.endOf('month');

  // use only the transactions for the month selected
  // use only the transactons for the specified account
  let response = [];

  response = data
    .filter((t) => {
      const { createdAt } = t;
      const parsedCreatedAt = DateTime.fromSQL(createdAt);
      return (
        parsedCreatedAt >= beginningOfMonth && parsedCreatedAt <= endofMonth
      );
    })
    .map(({ createdAt, summary, direction, amount, balance }) => {
      return {
        'Created At': DateTime.fromSQL(createdAt).toFormat('LLL dd, yyyy'),
        Summary: summary,
        Direction: direction,
        Amount: formatCurrency(Number(amount) / 100),
        Balance: formatCurrency(Number(balance) / 100),
      };
    });

  if (response.length === 0) {
    response.push({
      'Created At': '',
      Summary: '',
      Direction: '',
      Amount: '',
      Balance: '',
    });
  }
  downloadCSV({
    data: response,
    fileName: `Banking Transactions ${statement.period}`,
    withDate: false,
  });
};
