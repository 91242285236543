import { createStyles } from '@mantine/emotion';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { Button, Stack, Text } from '@mantine/core';
import {
  ApplicationState,
  getProductOnboardingStatus,
} from 'recoil-state/application/product-onboarding';
import { OnboardingUser } from 'types/onboarding-info';
import { ConditionalFieldValidator } from '@utilities/validators/validate-required';
import { useModals } from '@mantine/modals';
import { useLogout } from '@services/logout/logout-hook';
import { Termsinator } from '../../areas/onboarding-v2/steps/summary/termsinator';
import {
  TermsFormProvider,
  useTermsForm,
} from '../../areas/onboarding-v2/steps/summary/terms-form-context';
import { useHasTerms } from '@utilities/custom-hooks/use-has-terms';

type TermsFormProps = { onSubmit: () => void; onSignOut: () => void };
const TermsForm = ({ onSubmit, onSignOut }: TermsFormProps) => {
  const { classes, cx } = useStyles();
  const setProductOnboardingState = useSetRecoilState(ApplicationState);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    hasFlexbaseTerms,
    hasActiveCredit,
    hasActiveBanking,
    hasActiveTreasury,
    hasActiveIntnlPayments,
    ...terms
  } = useHasTerms();

  const hasBankingTerms = hasActiveBanking && terms.hasBankingTerms;
  const hasInternationalPaymentsTerms =
    hasActiveIntnlPayments && terms.hasInternationalPaymentsTerms;
  const hasTreasuryTerms = hasActiveTreasury && terms.hasTreasuryTerms;
  const hasCreditTerms = hasActiveCredit && terms.hasCreditTerms;
  const hasPersonalG = hasActiveCredit && terms.hasPersonalGuaranty;
  const hasFicoPull = hasActiveCredit && terms.hasFicoPull;
  const hasPatriotAct = hasActiveCredit && terms.hasPatriotAct;
  const hasFlTermsAgreement = hasActiveCredit && terms.hasFlTermsAgreement;
  const hasNyTermsAgreement = hasActiveCredit && terms.hasNyTermsAgreement;

  const form = useTermsForm({
    initialValues: {
      termsOfServiceSigned: false,
      bankingTermsOfServiceSigned: false,
      internationalPaymentsTermsOfServiceSigned: false,
      treasuryTermsOfServiceSigned: false,
      creditTermsOfServiceSigned: false,
      personalGuarantySigned: false,
      flTermsSigned: false,
      nyTermsSigned: false,
      // These values are set to true as they are text prompts agreed to be clicking "continue"
      patriotActSigned: true,
      ficoPullSigned: true,
    },
    validate: {
      termsOfServiceSigned: ConditionalFieldValidator(hasFlexbaseTerms),
      bankingTermsOfServiceSigned: ConditionalFieldValidator(hasBankingTerms),
      internationalPaymentsTermsOfServiceSigned: ConditionalFieldValidator(
        hasInternationalPaymentsTerms,
      ),
      treasuryTermsOfServiceSigned: ConditionalFieldValidator(hasTreasuryTerms),
      creditTermsOfServiceSigned: ConditionalFieldValidator(hasCreditTerms),
      personalGuarantySigned: ConditionalFieldValidator(hasPersonalG),
      patriotActSigned: ConditionalFieldValidator(hasPatriotAct),
      ficoPullSigned: ConditionalFieldValidator(hasFicoPull),
      flTermsSigned: ConditionalFieldValidator(hasFlTermsAgreement),
      nyTermsSigned: ConditionalFieldValidator(hasNyTermsAgreement),
    },
  });

  const agreeToTerms = async () => {
    const validationResult = form.validate();

    if (!validationResult.hasErrors) {
      setLoading(true);
      try {
        const request = Object.entries(form.values).reduce<
          Partial<OnboardingUser>
        >((prev, [termsField, agreed]) => {
          if (agreed) {
            return { ...prev, [termsField]: agreed };
          }
          return prev;
        }, {});
        await flexbaseOnboardingClient.updateUser(request);
        const newStatus = await getProductOnboardingStatus();
        setProductOnboardingState(newStatus);
        onSubmit();
      } catch (e) {
        console.error('terms-modal.tsx', 'Error updating terms agreements', e);
        setErrorMessage(
          'An error occurred. Unable to update terms agreements.',
        );
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <Text mb={32} fz={14}>
        We have updated our terms of service. Please read and agree to all of
        the updated terms to continue using Flex.
      </Text>
      <Stack>
        <TermsFormProvider form={form}>
          <Termsinator
            showPreview={false}
            showTextAgreements={true}
            termsConfig={{
              hasFlexbaseTerms,
              hasBankingTerms,
              hasInternationalPaymentsTerms,
              hasFicoPull,
              hasTreasuryTerms,
              hasPatriotAct,
              hasCreditTerms,
              hasFlTermsAgreement,
              hasNyTermsAgreement,
              hasPersonalGuaranty: hasPersonalG,
            }}
          />
        </TermsFormProvider>
      </Stack>
      {errorMessage && (
        <Text className={classes.errorText}>{errorMessage}</Text>
      )}
      <div
        className={cx(classes.buttonGroup, errorMessage && classes.withError)}
      >
        <Button
          variant="outline"
          onClick={() => onSignOut()}
          disabled={loading}
        >
          Sign Out
        </Button>
        <Button onClick={() => agreeToTerms()} loading={loading}>
          Agree and Continue
        </Button>
      </div>
    </div>
  );
};

const useStyles = createStyles((t) => ({
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 16,
    marginTop: 32,
  },
  withError: {
    marginTop: 8,
  },
  errorText: {
    color: t.colors.failure[1],
    fontSize: 12,
    marginTop: 24,
    textAlign: 'right',
  },
  termsHyperlinks: {
    a: {
      color: t.colors.url[0] + ' !important',
    },
  },
  checkboxGroup: {
    alignItems: 'center',
  },
}));

export const useShowTermsModal = () => {
  const { openModal, closeAll } = useModals();
  const logout = useLogout();

  return async () => {
    openModal({
      title: 'Flex Terms of Service Updated',
      modalId: 'terms-modal',
      closeOnEscape: false,
      closeOnClickOutside: false,
      withCloseButton: false,
      children: (
        <TermsForm
          onSubmit={() => closeAll()}
          onSignOut={() => {
            closeAll();
            logout();
          }}
        />
      ),
    });
  };
};
