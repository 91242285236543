import { filterDirectionToLabel } from '@common/utilities/filter/amount-filter';
import { formatCurrency } from '@utilities/formatters';
import { QuantityFilter } from '@common/utilities/filter/quantity-filter';
import { useBankingAccountsFiltersModal } from '../use-banking-filters';

export const BankingAccountsBalanceFilter = () => {
  const { addFilter, getFilterByKey } = useBankingAccountsFiltersModal();
  return (
    <QuantityFilter
      value={
        getFilterByKey('balance')?.filterValue || {
          amount: '',
          direction: 'equal',
        }
      }
      onChange={(val) => {
        if (!val.amount) return;
        addFilter('balance', {
          label: `Balance - ${filterDirectionToLabel(
            val.direction,
          )} ${formatCurrency(val.amount)}`,
          key: 'balance',
          fn: (row) => {
            const balanceNum = Math.abs(+row.balance);
            const filterNum = +val.amount;
            switch (val.direction) {
              case 'greaterThan':
                return balanceNum > filterNum;
              case 'lessThan':
                return balanceNum < filterNum;
              case 'equal':
              default:
                return balanceNum === filterNum;
            }
          },
          filterValue: val,
          showChip: true,
        });
      }}
    />
  );
};
