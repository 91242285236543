import { Loader, TextInput, UnstyledButton, rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { useEffect, useRef } from 'react';

const useStyles = createStyles((theme) => ({
  mainFont: {
    fontSize: rem(14),
    color: theme.colors.primarySecondarySuccess[2],
    fontWeight: 400,
  },
  container: {
    minWidth: rem(400),
    '@media(max-width: 767px)': {
      padding: `0 ${rem(32)}`,
    },
  },
  rightSectionInput: {
    width: rem(96),
  },
}));

type Props = {
  hasRetry: boolean;
  errorMsg: string | null;
  onChange: (code: string) => void;
  onResendCode: () => void;
  loading?: boolean;
  label?: string;
  containerStyles?: string;
};

const TwoFactorAuth = ({
  hasRetry,
  errorMsg,
  onChange,
  onResendCode,
  loading = false,
  label,
  containerStyles = '',
}: Props) => {
  const AuthInputRef = useRef<HTMLInputElement>(null);
  const { classes } = useStyles();

  useEffect(() => {
    if (hasRetry && AuthInputRef.current) {
      AuthInputRef.current.value = '';
    }
  }, [hasRetry]);

  const placeholderText = 'Enter 6 digit SMS';
  const containerStyling =
    containerStyles.length > 0 ? containerStyles : classes.container;
  return (
    <div className={containerStyling}>
      <TextInput
        autoFocus
        label={label}
        aria-label={label || placeholderText}
        type="number"
        placeholder={placeholderText}
        ref={AuthInputRef}
        onChange={(e) => onChange(e.currentTarget.value)}
        data-testid="input-two-factor-code"
        classNames={{
          section: loading ? '' : classes.rightSectionInput,
        }}
        rightSection={
          loading ? (
            <Loader size="xs" />
          ) : (
            <UnstyledButton
              className={classes.mainFont}
              onClick={onResendCode}
              disabled={loading}
            >
              Resend code
            </UnstyledButton>
          )
        }
        error={errorMsg && errorMsg}
      />
    </div>
  );
};

export default TwoFactorAuth;
