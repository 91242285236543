import { Box, Button, Flex, rem, Text } from '@mantine/core';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import type { DepositAccount, PlaidAccount } from 'types/move-funds.model';
import AccountIcon from '@common/acount-icon';
import AccountBox from '@common/composites/account-box/account-box';
import { formatCurrency } from '@utilities/formatters';
import { capitalizeOnlyFirstLetter } from '@utilities/formatters/format-strings';

// all acceptable Payment Account types
type PaymentAccount = PlaidAccount | DepositAccount;

// derive an account name from the Payment Account
const getName = (account: PaymentAccount) => {
  switch (account.plaidOrDeposit) {
    case 'deposit': {
      return account.nickName || account.name;
    }
    case 'plaid': {
      return (account.bankName || account.accountName) ?? 'Plaid Account';
    }
  }
};

// derive a processing time from the Payment Account
const getProcessingMessage = (account: PaymentAccount) => {
  if (account.plaidOrDeposit === 'deposit') {
    return 'Payments to Flex from Flex Bank accounts will be processed immediately.';
  }

  return 'Payments to Flex initiated after 11:59 p.m. UTC will be processed the next business day.';
};

const accountSubheaderText = (account: PaymentAccount) => {
  switch (account.plaidOrDeposit) {
    case 'deposit': {
      return `${formatCurrency(
        account.balance / 100,
      )} / ${capitalizeOnlyFirstLetter(
        account.accountType,
      )} ${getPaddedAccountMask(account.accountNumber, 4)}`;
    }
    case 'plaid': {
      return `${capitalizeOnlyFirstLetter(
        account.accountType || '',
      )} ${getPaddedAccountMask(account.last4 ?? account.account, 4)}`;
    }
  }
};

type Props = {
  frozen?: boolean;
  paymentAmount: string;
  loadingRequest: boolean;
  paymentAccount: PaymentAccount;
  onConfirmClick: () => void;
  onGoBackClick: () => void;
};

const CardPaymentReview = ({
  frozen = false,
  paymentAmount,
  paymentAccount,
  loadingRequest,
  onConfirmClick,
  onGoBackClick,
}: Props) => (
  <>
    <Text fw={500}>
      {frozen
        ? `Once confirmed, your payment will be applied to your Flex card and
        your account will be unfrozen.`
        : `Once confirmed, your payment will be applied to your Flex card.`}
    </Text>

    <Box
      mt="md"
      p="md"
      bg="neutral.0"
      sx={(theme) => ({ borderRadius: theme.defaultRadius })}
    >
      <Text c="neutral.7" fw={500}>
        Payment Amount
      </Text>
      <Text fz={rem(40)} data-testid="text-payment-amount">
        {paymentAmount}
      </Text>
      <Text c="neutral.7" size="xs">
        {getProcessingMessage(paymentAccount)}
      </Text>
      <Text mt="sm" size="sm">
        Pay From:
      </Text>
      <AccountBox
        headerText={getName(paymentAccount)}
        subheaderText={accountSubheaderText(paymentAccount) || ''}
        showArrow={false}
        isListItem={false}
        showBorder
        icon={
          <AccountIcon
            externalName={
              paymentAccount.plaidOrDeposit === 'plaid'
                ? getName(paymentAccount)
                : null
            }
          />
        }
      />
    </Box>
    <Text mt="md" c="neutral.7" size="xs">
      By clicking Confirm Payment, I authorize Flex to initiate the transaction
      detailed above. Flex is powered by Flexbase Technologies, Inc
    </Text>
    <Flex justify="space-between" mt="xxxl">
      <Button
        variant="outline"
        onClick={() => onGoBackClick()}
        size="lg"
        data-testid="go-back-button"
      >
        Go back
      </Button>
      <Button
        loading={loadingRequest}
        onClick={() => onConfirmClick()}
        size="lg"
        data-testid="confirm-payment-button"
      >
        Confirm Payment
      </Button>
    </Flex>
  </>
);

export default CardPaymentReview;
