import { OptedProduct } from '../recoil-state/application/product-onboarding';
import { IAddress, UserRole } from './user-info';

export type UnderwritingResponse = {
  success: boolean;
  company: OnboardingCompany;
  approved: boolean;
  checks?: [
    {
      level: number;
      name: string;
      reason?: string;
      status: string;
      success: boolean;
    },
  ];
};

export type PromoCode = {
  id: string;
  userId: string;
  code: string;
  level: string;
  validFrom: string;
  validUntil: any;
  title: string;
  description: string;
  uwReject: boolean;
};

export type OnboardingUser = {
  id: string;
  firstName: string;
  lastName: string;
  taxId: string;
  birthDate: string;
  jobTitle: string;
  email: string;
  phone: string;
  cellPhone: string;
  address: IAddress;
  authorizedSignatory: boolean;
  roles: UserRole[];
  status: string;
  plaidLinked: boolean;
  termsOfServiceSigned: boolean;
  bankingTermsOfServiceSigned: boolean;
  internationalPaymentsTermsOfServiceSigned: boolean;
  treasuryTermsOfServiceSigned: boolean;
  creditTermsOfServiceSigned: boolean;
  patriotActSigned: boolean;
  ficoPullSigned: boolean;
  hasBusinessCard: boolean;
  businessCardTypes: string[];
  employeeCount: string;
  promoCode?: PromoCode;
  tracking?: Tracking;
  personalGuarantySigned: boolean;
  identification?: { type: string; number: string };
  allPlaidAccountsLinked?: boolean;
  nyTermsSigned: boolean;
  flTermsSigned: boolean;
};

type Tracking = {
  session_id?: string;
  client_id?: string;
  timestamp_micros?: number;
};

export type OverviewUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  roles: string[];
  required: string[];
  requiredBanking: string[];
  requiredCredit: string[];
  requiredTreasury: string[];
  isComplete: boolean;
};

export function IsFullCompanyOwner(
  owner: Partial<CompanyOwner> | CompanyOwner,
): owner is CompanyOwner {
  return !!owner.email;
}

export type CompanyOwner = {
  id: string;
  address: string;
  addressLine2: string;
  asOf: string;
  authorizedSignatory: boolean;
  byUser: string;
  cellPhone: string;
  city: string;
  companyId: string;
  completedInviteNewUsers: boolean;
  completedOffboarding: boolean;
  completedOnboarding: boolean;
  country: string;
  displayName: string;
  email: string;
  firstName: string;
  jobTitle: string;
  lastName: string;
  mqUserToken: string;
  phone: string;
  postalCode: string;
  profileDocId: string;
  pushNotifTokens: string;
  roles: string[];
  state: string;
  status: string;
  stpCardholderId: string;
  stpPersonId: string;
  username: string;
  version: number;
  tenantId: string;
  ownershipPct: number;
};

export type FinancialInstitution = {
  id: string;
  accountId: string;
  account: string;
  routing: string;
  accountName: string;
  accountType: string;
  bankName: string;
  officialName: string;
  last4: string;
  active: boolean;
  unlinked: boolean;
  institutionId: string;
};

export type OwnerType = Partial<CompanyOwner> | CompanyOwner;

export type FrozenReason =
  | 'plaidUnlinked'
  | 'plaidDropped'
  | 'accountDelinquent'
  | 'deniedBanking'
  | 'byServicer'
  | 'unknown';

export type AutoPay = {
  when: string;
  plaidTokenId: string;
  depositAccountId: string;
  utilizationPct: number;
  payBalancePct: number;
};

type EarlyPayLongWeekdaysConfig = string[]; // eg ["Monday", "Wednesday", "Saturday"]
type EarlyPayDaysOfMonthConfig = number[]; // eg [1, 15, 30]
type PaymentDaysOfMonthOrWeekdays =
  | EarlyPayLongWeekdaysConfig
  | EarlyPayDaysOfMonthConfig;

type RebateCardConfiguration = {
  issuer: 'manual';
};

type EarlyPayConfigBase = {
  rebatePct: string;
  rebateDate: number;
  depositAccountId?: string;
  rebateCounterpartyId?: string;
  plaidTokenId?: string;
  rebateCardConfiguration?: RebateCardConfiguration;
};

export type UtilizationEarlyPayConfig = {
  utilizationPct: number;
} & EarlyPayConfigBase;

export type GraceDayEarlyPayConfig = {
  dates: PaymentDaysOfMonthOrWeekdays;
  graceDays: number;
} & EarlyPayConfigBase;

export function isGraceDayEarlyPayConfig(
  value: unknown,
): value is GraceDayEarlyPayConfig {
  return !!(value as GraceDayEarlyPayConfig)?.graceDays;
}

export type OnboardingCompany = {
  id: string;
  companyName: string;
  doingBusinessAs: string;
  taxId: string;
  formationDate: string;
  legalStructure: string;
  website: string;
  monthlyExpenditure: string;
  phone: string;
  address: IAddress;
  owners: OwnerType[];
  financialInstitutions: FinancialInstitution[];
  category: string;
  annualRevenue: string;
  averageMonthlyPayroll: string;
  insuranceTypes: string[];
  hasInsurance: boolean;
  optedProducts: OptedProduct[];
  creditLimit?: string;
  autopay: Partial<AutoPay>;
  formationState: string;
  businessPurpose: string;
  cashOnHand: string;
  naicsCode: string;
  reinvestTreasuryInterest: boolean;
  primaryPlaidAccount: string;
  businessVertical: string;
  requestedCreditLimit: string;
  stateOfOperation: string;
  onboardedBy: string;
  createdBy: string;
  businessStage: string;
  trinetId?: string;
  createdAt: string;
  frozenReason?: FrozenReason;
  controlPerson?: string;
  //creditUsedPct is used to upgrade
  creditUsedPct?: number;
  intendedCountries?: string[];
  //creditUsedPct is used to consult
  additionalInfo?: { creditUsedPct?: number };
  requestedDocuments: string[];
  officers: OwnerType[];
  excludedProducts: OptedProduct[];
  earlypay?: GraceDayEarlyPayConfig | UtilizationEarlyPayConfig;
};

export type OnboardingStatusResponse = {
  success: boolean;
  required: string[];
  requiredBanking: string[];
  requiredCredit: string[];
  requiredTreasury: string[];
  user: OnboardingUser;
  company: OnboardingCompany;
  completedOnboarding?: boolean;
};

export type OnboardingOverviewResponse = {
  success: boolean;
  users: OverviewUser[];
  company: OnboardingCompany;
};

export type ShipCardAddress = {
  address: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
};

export type ShipCardRequest = {
  cardType: 'virtual' | 'physical';
  shipTo: ShipCardAddress;
  service: string;
};

export type SubmitUnderwritingResponse = {
  userId: string;
  companyId: string;
  level: number;
  success: boolean;
  incompleteOwners: unknown[];
  ownerKYCRuns: unknown[];
  incompleteOfficers: unknown[];
  officerKYCRuns: any[];
  approved: boolean;
};

export type Employees = {
  firstName?: string;
  lastName?: string;
  taxId?: string;
  birthDate?: string;
  jobTitle?: string;
  email?: string;
  address?: string;
  addressLine2?: string | null;
  city?: string;
  country?: string;
  state?: string;
  postalCode?: string;
  cellPhone?: string;
  authorizedSignatory?: boolean;
  id?: string;
  roles?: UserRole[];
  completedOnboarding?: string;
  completedOffboarding?: string;
  promoCodeId: string | null;
  status: 'active' | 'suspended' | 'removed';
  preferences?: {
    notifications: {
      BILLING: {
        default: string[];
      };
      CARDS: {
        default: string[];
      };
      COMPANY: {
        default: string[];
      };
      PAYMENTS: {
        default: string[];
      };
      PURCHASES: {
        default: string[];
      };
    };
  };
};
