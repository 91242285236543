import { Avatar, Badge, Button, rem, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import useModal from '@common/composites/modal/modal-hook';
import FlexbaseTable from 'components/table/flexbase-table';
import { useStyles } from '../styles';
import { UserIdState } from 'recoil-state/application/onboarding-form.state';
import SkeletonLoading from '@common/composites/loading/skeleton-loading';
import { CardByUser } from 'services/flexbase/banking.model';
import { getInitialsOfNames } from 'utilities/object';
import { formatCurrency } from 'utilities/formatters';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import GenericError from '../../../components/generic-error';
import DebitCardDetails from '@common/charge-and-credit-cards/debit-card-details';
import Header from './debit-cards-header/debit-cards-header';
import { useGetDebitCards } from '@queries/use-debit-cards';
import { TableColumn } from 'react-data-table-component';
import { useParams } from 'react-router-dom';
import { cardIsChargeCard, FlexCard } from '@services/flexbase/card.model';
import { useDebitCardsFilters } from '@common/utilities/filter/cards-filter/use-cards-filters';

const DebitCards = () => {
  const modal = useModal();
  const { classes, theme } = useStyles();
  const { id: accountId } = useParams();
  const userId = useRecoilValue(UserIdState);
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const {
    data: debitCardsData,
    isPending,
    isError,
    refetch,
  } = useGetDebitCards(accountId);
  const { activeFiltersArray } = useDebitCardsFilters();
  const debitCards =
    debitCardsData?.filter(
      (c) => !cardIsChargeCard(c as unknown as FlexCard),
    ) || [];

  const cardType: Record<string, string> = {
    VIRTUAL: 'Virtual',
    PHYSICAL: 'Physical',
  };

  const cardStatus: Record<string, string> = {
    active: 'Active',
    suspended: 'Frozen',
    issued: 'Unactivated',
    terminated: 'Terminated',
  };

  const columns: TableColumn<CardByUser>[] = [
    {
      name: 'Cardholder',
      cell: (row) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar>{getInitialsOfNames(row.holder)}</Avatar>
          <div style={{ margin: `0px ${rem(12)}` }}>{row.holder}</div>
        </div>
      ),
      selector: (row) => row.holder,
      sortable: true,
    },
    {
      name: 'Card name',
      cell: (row) => row.cardName,
      selector: (row) => row.cardName,
      sortable: true,
    },
    {
      name: 'Type',
      selector: (row) => cardType[row.cardType],
      sortable: true,
      compact: true,
    },
    {
      name: 'Debit card number',
      cell: (row) => getPaddedAccountMask(row.cardNumber, 4),
      compact: true,
    },
    {
      name: 'Spent this month',
      compact: true,
      sortable: true,
      selector: (row) => formatCurrency(row.monthToDateSpends?.mtdSpend || 0),
    },
    {
      name: 'Status',
      compact: true,
      sortable: true,
      selector: (row) => row.status,
      cell: (row) => {
        let backgroundColor, textColor;

        switch (row.status) {
          case 'suspended':
            backgroundColor = theme.colors.promote[0];
            textColor = theme.colors.promote[5];
            break;
          case 'active':
            backgroundColor = theme.colors.positive[0];
            textColor = theme.colors.positive[6];
            break;
          case 'terminated':
            backgroundColor = theme.colors.critical[0];
            textColor = theme.colors.critical[4];
            break;
          default:
            backgroundColor = theme.colors.neutral[1];
            textColor = theme.colors.neutral[9];
        }

        return (
          <Badge
            styles={{
              root: {
                backgroundColor,
              },
            }}
          >
            <Text color={textColor}>{cardStatus[row.status]}</Text>
          </Badge>
        );
      },
    },
  ];

  const columnsSm: TableColumn<CardByUser>[] = [
    {
      grow: 5,
      sortable: true,
      name: 'Cardholder',
      selector: (row) => row.holder,
      cell: (row) => (
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>{row.holder}</div>
            <Badge
              className="badge"
              styles={{
                root: {
                  backgroundColor:
                    row.status === 'suspended'
                      ? 'rgba(48, 44, 255, 0.1)'
                      : '#EEEEF3',
                  display:
                    row.status === 'active' && userId !== row.userId
                      ? 'none'
                      : 'inline-flex',
                },
                inner: { color: '#000 !important' },
              }}
            >
              {userId === row.userId && row.status === 'active'
                ? 'You'
                : cardStatus[row.status]}
            </Badge>
          </div>
          <div>{getPaddedAccountMask(row.cardNumber, 4)}</div>
        </div>
      ),
    },
    {
      name: 'Type',
      compact: true,
      sortable: true,
      selector: (row) => row.cardType,
      cell: (row) => cardType[row.cardType],
    },
  ];

  const handleRowClicked = (card: CardByUser) => {
    useMobileView
      ? modal.openFullModal(<DebitCardDetails card={card} />)
      : modal.openRightModalNoOpacity(<DebitCardDetails card={card} />);
  };

  const sortDebitCards = () => {
    const terminatedCards = debitCards.filter(
      (card) => card.status === 'terminated',
    );
    const nonTerminatedCards = debitCards.filter(
      (card) => card.status !== 'terminated',
    );

    return [...nonTerminatedCards, ...terminatedCards];
  };

  const filteredTableData = useMemo(() => {
    return sortDebitCards().filter((cc) =>
      activeFiltersArray.every((filter) => filter.fn(cc)),
    );
  }, [debitCards, activeFiltersArray]);

  if (isPending) {
    return <SkeletonLoading />;
  }

  if (isError) {
    return (
      <GenericError>
        <p>An unhandled error occurred getting the debit cards.</p>
        <Button variant="light" onClick={() => refetch()} mt={rem(20)}>
          Please try again
        </Button>
      </GenericError>
    );
  }

  return (
    <div className={classes.debitCardsContent}>
      <div className={classes.card}>
        <Header accountId={accountId} debitCards={debitCards} />
        <FlexbaseTable
          onRowClicked={handleRowClicked}
          isFetchingData={isPending}
          data={filteredTableData}
          columns={useMobileView ? columnsSm : columns}
          pagination={filteredTableData.length > 8}
          noDataComponent={
            <Text fz={rem(24)} fw={500}>
              No debit cards yet
            </Text>
          }
        />
      </div>
    </div>
  );
};

export default DebitCards;
