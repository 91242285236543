import { createStyles } from '@mantine/emotion';
import { rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  baseContainer: {
    width: '100%',
  },
  widgetContainer: {
    borderRadius: theme.defaultRadius,
    flexDirection: 'row',
    backgroundColor: 'white',
    padding: rem(24),
    '@media(max-width: 767px)': {
      width: '100%',
    },
  },
  storeName: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    wordWrap: 'normal',
  },
  storeIcon: {
    height: rem(32),
    width: rem(32),
    color: `${theme.primaryColor}`,
    '> div:first-of-type': {
      color: `${theme.primaryColor} !important`,
      backgroundColor: '#FFEEEC !important',
    },
    marginRight: rem(10),
  },
  date: {
    fontSize: rem(14),
    fontWeight: 400,
    color: theme.colors.blackish[0],
    lineHeight: rem(21),
  },
  badge: {
    color: '#000',
    fontWeight: 500,
    fontSize: rem(14),
    marginTop: rem(25),
    padding: `${rem(15)} ${rem(20)}`,
    backgroundColor: '#F5F6F8',
    textTransform: 'capitalize',
  },
  amount: {
    color: theme.colors.neutral[9],
    fontSize: rem(14),
    fontWeight: 400,
    lineHeight: rem(21),
  },
  errorMessage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
