import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  header: {
    color: theme.colors.contentBackground[2],
    padding: rem(45),
    marginBottom: rem(40),
    backgroundColor: theme.primaryColor,
    '@media(max-width: 767px)': {
      padding: rem(20),
      marginBottom: rem(20),
    },
  },
  rowContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  padding: {
    padding: `0px ${rem(50)}`,
    '@media(max-width: 767px)': {
      padding: `0px ${rem(20)}`,
    },
  },
  cursor: {
    cursor: 'pointer',
  },
  alingEnd: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  switch: {
    marginTop: rem(20),
    marginBottom: rem(20),
  },
  transactionWidget: {
    width: 'auto',
    minWidth: 'auto',
    minHeight: rem(200),
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.colors.neutral[1]}`,
    '@media(max-width: 767px)': {
      width: '100%',
      minWidth: 'auto',
    },
  },

  accountDetailsContainer: {
    paddingBottom: rem(50),
    minHeight: '100vh',
    backgroundColor: theme.colors.neutral[2],
    '@media(max-width: 767px)': {
      paddingBottom: rem(20),
    },
  },

  accountDetailsName: {
    fontSize: rem(20),
    fontWeight: 400,
    lineHeight: rem(32),
    color: theme.colors.neutral[0],
  },

  accountDetailsType: {
    fontSize: rem(14),
    lineHeight: rem(21),
    fontWeight: 400,
    color: theme.colors.neutral[0],
  },

  accountDetailsLabel: {
    fontWeight: 500,
    fontSize: rem(14),
    marginTop: rem(20),
    lineHeight: rem(20),
    marginBottom: rem(5),
  },

  accountDetailsInput: {
    fontSize: rem(14),
    padding: `${rem(10)} ${rem(20)}`,
    borderRadius: theme.defaultRadius,
    backgroundColor: theme.colors.neutral[0],
    border: `1px solid ${theme.colors.neutral[1]}`,
  },

  buttonsContent: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: rem(20),
    gap: 10,
  },

  actionContent: {
    color: theme.primaryColor,
    fontSize: rem(14),
    fontWeight: 500,
    cursor: 'pointer',
    svg: {
      marginRight: rem(10),
    },
    '&:hover': {
      color: theme.colors.primarySecondarySuccess[2],
      svg: {
        color: theme.colors.primarySecondarySuccess[2],
      },
    },
  },

  menuItem: {
    fontSize: rem(14),
    color: theme.colors.blackish[0],
    '&:hover': {
      backgroundColor: 'rgba(255, 87, 69, 0.08)',
    },
  },

  menuDropdown: {
    cursor: 'pointer',
  },

  menuLabel: {
    gap: rem(10),
    display: 'flex',
    fontWeight: 400,
    fontSize: rem(14),
    cursor: 'pointer',
    alignItems: 'center',
    color: theme.primaryColor,
  },

  editIcon: {
    cursor: 'pointer',
    marginRight: rem(2),
  },
}));

export const useCardCTAStyles = createStyles(
  (theme, context: Record<string, any>, helpers) => ({
    card: {
      display: 'flex',
      padding: theme.spacing.xl,
      height: rem(200),
      borderRadius: theme.defaultRadius,
      border: `1px solid ${theme.colors.neutral[1]}`,
      backgroundColor: theme.colors.neutral[0],
      alignContent: 'space-between',
      justifyContent: 'space-between',
      [helpers.smallerThan('sm')]: {
        width: '100%',
      },
    },

    statusBadge: {
      '&.mantine-Badge-root': {
        background: context.isAwaitingDocumentsStatus
          ? theme.colors.critical[0]
          : theme.colors.tertiary[0],
      },
      '.mantine-Badge-label': {
        fontSize: theme.spacing.md,
        color: context.isAwaitingDocumentsStatus
          ? theme.colors.critical[6]
          : theme.colors.tertiary[6],
      },
    },

    contentCard: {
      display: 'flex',
      fontWeight: 500,
      flexWrap: 'wrap',
      alignContent: 'center',
      p: {
        color: '#757575',
        fontSize: rem(14),
        lineHeight: rem(16),
      },
    },

    titleCard: {
      fontWeight: 500,
      fontSize: rem(16),
      lineHeight: rem(19),
      color: theme.colors.blackish[1],
    },
  }),
);
