import AccountBox from '@common/composites/account-box/account-box';
import { Box } from '@mantine/core';
import { capitalizeOnlyFirstLetter } from '@utilities/formatters/format-strings';
import { Icon, SubheaderText } from '@common/payment-method';
import { ParsedAccount } from 'types/parsed-account';
import { useState } from 'react';
import { supportedCurrenciesData } from '@utilities/international-payments';

type RecipientAccountsSelectProps = {
  accounts: ParsedAccount[];
  onSelectAccount: (account: ParsedAccount) => void;
  currentRecipientAccount?: ParsedAccount;
  country: string;
  disabled: boolean;
};

const RecipientAccountsSelect = ({
  accounts,
  onSelectAccount,
  currentRecipientAccount,
  country,
  disabled,
}: RecipientAccountsSelectProps) => {
  const [showSelect, setShowSelect] = useState(false);

  const filteredAccounts = accounts.filter((account) =>
    currentRecipientAccount ? account.id !== currentRecipientAccount.id : true,
  );

  const derivedHeaderText = (c: ParsedAccount) =>
    c?.nickName || c?.bank || c?.name;

  const getCurrencySymbol = (currency: string) =>
    supportedCurrenciesData.find((cc) => cc.value === currency)?.symbol;

  const handleAccountBoxClick = () => {
    if (filteredAccounts.length > 0 && !disabled) {
      setShowSelect((prev) => !prev);
    }
  };

  const handleAccountSelect = (account: ParsedAccount) => {
    onSelectAccount(account);
    setShowSelect(false);
  };

  return (
    <Box>
      <AccountBox
        headerText={
          currentRecipientAccount
            ? derivedHeaderText(currentRecipientAccount)
            : ''
        }
        subheaderText={
          currentRecipientAccount && (
            <SubheaderText
              method={currentRecipientAccount.type}
              currency={currentRecipientAccount.currency}
              accountNumber={currentRecipientAccount.accountNumber}
              routingNumber={currentRecipientAccount.routingNumber}
              currencySymbol={getCurrencySymbol(
                currentRecipientAccount.currency ?? '',
              )}
              paymentType={capitalizeOnlyFirstLetter(
                currentRecipientAccount.type,
              )}
            />
          )
        }
        style={{
          opacity: disabled ? 0.5 : 1,
          pointerEvents: disabled ? 'none' : 'auto',
        }}
        onClick={handleAccountBoxClick}
        showArrow={filteredAccounts.length > 0}
        rotateArrow={showSelect}
        icon={
          <Icon
            method={currentRecipientAccount?.type ?? 'ach'}
            flag={country}
          />
        }
      />

      {showSelect && filteredAccounts.length > 0 && (
        <Box mt="0.2rem">
          {filteredAccounts.map((account) => (
            <AccountBox
              key={account.id}
              headerText={derivedHeaderText(account)}
              subheaderText={
                <SubheaderText
                  method={account.type}
                  currency={account.currency}
                  accountNumber={account.accountNumber}
                  routingNumber={account.routingNumber}
                  currencySymbol={getCurrencySymbol(account.currency ?? '')}
                  paymentType={capitalizeOnlyFirstLetter(account.type)}
                />
              }
              onClick={() => handleAccountSelect(account)}
              icon={<Icon method={account.type} flag={account.country} />}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default RecipientAccountsSelect;
