import { useBankingAccountsFiltersModal } from '../use-banking-filters';
import { SimpleSegmentSelectedFilter } from '@common/utilities/filter/simple-segmented.filter';

const STATUS_MULTISELECT_OPTIONS = [
  {
    label: 'Open',
    filterCriteria: ['Open'],
    key: 'Open',
  },
  {
    label: 'Closed',
    filterCriteria: ['Closed'],
    key: 'Closed',
  },
];

export const BankingAccountsStatusFilter = () => {
  const bankingAccountsStatusFilter = useBankingAccountsFiltersModal();

  return (
    <SimpleSegmentSelectedFilter
      filterHook={bankingAccountsStatusFilter}
      filterKey="status"
      options={STATUS_MULTISELECT_OPTIONS}
      label="Status"
      includesValue={(row) => row.status}
    />
  );
};
