import { Button } from '@mantine/core';
import { CreditSalesBoxes } from './credit-sales-boxes';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from '../../../recoil-state/application/product-onboarding';
import { showNotification } from '@mantine/notifications';
import { useRouteSectionContext } from 'providers/route-context';
import { flexbaseOnboardingClient } from '@services/flexbase-client';
import * as Sentry from '@sentry/react';
import { ArrowRight } from '../../../assets/svg';
import { usePageBackground } from '../../../providers/background-provider';
import { SalesPageContent } from 'areas/credit/sales/sales-page.content';
import { CreditCardGraphic } from './credit-card-graphic';
import { ProductApplicationRoutes } from '../../../constants/onboarding.constants';

const CreditSalesPage = () => {
  const { productStatus, company, user, userIsApplicant } =
    useRecoilValue(ApplicationState);
  const { setSectionAndNavigate } = useRouteSectionContext();
  usePageBackground(
    (t) =>
      `url("/images/page-top-frame.png") top/contain no-repeat ${t.colors.primarySecondarySuccess[7]}`,
  );

  const likelyApplicant =
    userIsApplicant ||
    user.id === company.controlPerson ||
    user.roles.includes('OWNER') ||
    user.roles.includes('OFFICER'); // Including this here since an OFFICER can start a credit application

  const showApplyNowButton =
    productStatus.credit.status === 'unused' && likelyApplicant;

  const handleApplyNowClick = async () => {
    try {
      await flexbaseOnboardingClient.updateCompany({
        id: company.id,
        optedProducts: [...company.optedProducts, 'CREDIT'],
      });
      setSectionAndNavigate(
        'application',
        ProductApplicationRoutes.CREDIT_ONLY,
      );
    } catch (e) {
      Sentry.captureException(e);
      showNotification({
        color: 'critical.2',
        message: 'Unable to begin credit application. Please try again.',
      });
    }
  };

  const actionButton = (
    <Button
      onClick={handleApplyNowClick}
      variant="outline"
      rightSection={<ArrowRight />}
      c="primarySecondarySuccess.8"
      sx={(theme) => ({
        backgroundColor: theme.colors.neutral[0],
        '&:not([data-disabled])&:hover': {
          backgroundColor: theme.colors.neutral[0],
        },
      })}
    >
      Apply Now
    </Button>
  );

  return (
    <SalesPageContent
      topGraphic={
        <CreditCardGraphic
          firstName={user.firstName}
          lastName={user.lastName}
        />
      }
      salesCards={CreditSalesBoxes}
      title="Apply for a Flex Credit Card"
      subtitle="For the savvy business owner: Leverage our net-60 payment terms*, unlimited employee cards, smart spend controls and simple receipt capture to scale your business hassle-free."
      actionButton={showApplyNowButton ? actionButton : undefined}
    />
  );
};

export default CreditSalesPage;
