import { createStyles, keyframes } from '@mantine/emotion';
import { spinCounterClockwise } from 'areas/credit/components/credit-transactions/utils/keyframes';

const syncIcon = keyframes({
  '0%': { transform: 'scale(1)' },
  '33%': { transform: 'scale(1.2)' },
  '100%': { transform: 'scale(1)' },
});

type ExpenseManagementStylesProps = {
  timing?: {
    syncIcon?: number;
  };
};

export const useStyles = createStyles(
  (_theme, props: ExpenseManagementStylesProps | void) => ({
    baseContainer: {
      display: 'flex',
    },
    button: {
      width: 32,
      height: 32,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      svg: {
        // svg picks up styling from react-data-table-component style overrides, sadly need to use important
        margin: `0 !important`,
      },
    },
    spin: {
      animation: `${spinCounterClockwise} 1000ms infinite linear`,
    },
    syncIcon: {
      animation: `${syncIcon} ${props?.timing?.syncIcon ?? 250}ms ease-in`,
    },
  }),
);
